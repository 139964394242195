import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class TriggerLearningChart extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		// console.log("this.props.series ", this.props.series)
        var chartSeries = [
            {
                type: 'areasplinerange',
               showInLegend: true,
               visible: true,
               color:"#D0E4FF",
               // enableMouseTracking: true,
               name:"Confidence Interval",
                data: this.props.confAreaData,
                
                // pointInterval: 2592000000,
                marker: {
                    radius: 4,
                
                    enabled: false
                },
                tooltip:{
                    enabled:false
                }
            },
            {
                type: 'spline',
                showInLegend: true,
                name: 'Predicted Quantity',
                color:"#260CFF",
                data: this.props.confAreaYHat,
                marker: {
                    enabled: false
                },
            },
            {
                type: 'scatter',
                showInLegend: true,
                // enableMouseTracking: false,
                color:"#444444",
                name: 'Actual Quantity Consumed',
                data: this.props.confScatter,
                marker: {
                    radius: 3
                }
            }
        
        ]
        if(this.props.dataType == 2)
        {
            chartSeries = [
                {
                    type: 'spline',
                    showInLegend: true,
                    name: 'Cost ($)',
                    color:"#d12440",
                    data: this.props.trendingcost,
                    marker: {
                        enabled: false
                    },
                },
            
            ]
        }
		const highchartoptions = {
            chart: {
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
            },
			title: {
                text: this.props.title,
            },
            subtitle:{
                text:this.props.subtitle
            },
            xAxis: {
                title: {
                    text: this.props.xAxisTitle
                },
                categories: this.props.categories,
            },
            
            yAxis: {
                title: {
                    text: this.props.yAxisTitle
                }
            },
            tooltip: {
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.0f}'
			},
            legend: {
                enabled: true
            },
            plotOptions: {
                series: {
                    dataGrouping:true
                }
              },
            series: chartSeries,
			credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '75%', padding: "0px", fontSize: "13px", fontWeight: "bold", color: "black" } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}