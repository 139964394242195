import React, { Component, startTransition } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import highchartsMore  from 'highcharts/highcharts-more';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
highchartsMore(Highcharts);
drilldown(Highcharts);
export default class AreaRangeChart extends Component {

    constructor(props) {
        super(props);
        // console.log(props,'seson prop')
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
        var a = 2022;
        var b = 2022;
        var pointStart = Date.UTC(2016,0,1);
        // console.log("this.state.chartseries[0].allfymonthwise[0] ", this.props.predictseries[0])
		const highchartoptions = {
	      		 
            chart: {
                type: 'spline',
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
                // events: {
                //     load: function () {
        
                //         // set up the updating of the chart each second
                //         var series = this.series[0];
                //         setInterval(function () {
                //             var x = (new Date()).getTime(), // current time
                //                 y = Math.random();
                //             series.addPoint([x, y], true, true);
                //         }, 1000);
                //     }
                // }
            },
            title: {
                text: this.props.title,
                align: 'center'
            },
            subtitle: {
                text: this.props.subtitle,
                align: 'center'
            },
            
            // legend: {
            //     layout: 'vertical',
            //     align: 'left',
            //     verticalAlign: 'top',
            //     // x: 120,
            //     // y: 70,
            //     floating: true,
            //     borderWidth: 1,
            //     backgroundColor:
            //         Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
            // },
            xAxis: {
                // plotBands: [{ // Highlight the two last years
                //     from: 2019,
                //     to: 2020,
                //     color: 'rgba(68, 170, 213, .2)'
                // }]
                type:"datetime",
                // labels: {
                //     // staggerLines: 1,
                //     formatter: function (ev) {
                //         return Highcharts.dateFormat('%b', new Date(this.value));
                //     },
                //     unitName:"month"
                // },
                // categories:["Jan","Feb","Mar", "Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
                // min: Date.UTC(a, 0, 1),
                // max: Date.UTC(b, 11, 0),
                labels: {
                    staggerLines: 1,
                    formatter: function () {
                        return Highcharts.dateFormat('%b', this.value);
                    }
                },
            },
            yAxis: {
                title: {
                    text: 'Quantity'
                }
            },
            tooltip: {
				pointFormat: '<span style="color:{point.color}">Orders</span>: <b>{point.lbl}'
			},
            credits: {
                enabled: false
            },
            plotOptions : {
                series  : {
                    pointStart      : pointStart,
                    pointInterval   : 24 * 3600 * 1000*32
                }
            },
            series: [{
                name:"Seasonal Data",
                data:this.props.confLineSeasonal,
                tooltip: {
                    xDateFormat: 'Seasonality Data'
                },
                // units: [
                //     ['month', null]
                // ]
                // pointInterval: 2592000000,
                // pointInterval: 24 * 3600 * 1000 * 31 * 2,
                // pointIntervalUnit: 'month',
                // grouped:true,
                // pointStart:Date.UTC(2016, 0, 1),
                // dataGrouping: {
                //     approximation: 'open',
                //     // enabled: true,
                //     // forced: true,
                //     units: [[ 'millisecond',[1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ['second',[1, 2, 5, 10, 15, 30]], ['minute',[1, 2, 5, 10, 15, 30]], ['hour',[1, 2, 3, 4, 6, 8, 12]], ['day',[1] ], ['week', [1]], ['month',[1, 3, 6]], ['year',null ]]
                //     // units: undefined
                // }
            }],
			credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%', fontWeight: "bold", fontSize: "15px", color: "black" } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












