import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM} from '../common/utils'; 
import {verifyext} from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import AddLocation from "./addLocation";
import ViewItems from "./viewItems";
import Mapping from "./mappingbutton";

var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class ForecastSuppliers extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
                AddLocation:AddLocation,
                ViewItems:ViewItems,
                Mapping:Mapping
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            showSlideServiceLocation:"",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showrunprocessbtn:"show-m",
            showanimate:"show-n",
            locationNames:[],
            loadFile:'',
            material_configurations:0,
            location:{label:"Select Location",value:""},
            loadshow:'show-m',
            overly:'show-m',
            processErrMsg : "",
            processErrShow : "",
            transactionId : "",
            droppedList : [],
            suppliers:[],
            supplier:{"value":"","label":"Select Supplier"},
            material:{"value":"","label":"Select Material"},
            supplier_rownode:"",
            mappingRow:"",
            service_address:"",
            service_city:"",
            service_state:"",
            service_person:"",
            service_person_email:"",
            service_person_contact:"",
            showSlideMapping:"",
            materials:[],
            service_locations:[],
            service_location:{"value":"","label":"Select Location"},
            lead_time:"",
            maprowData:[],
            showSlideBulkMap:"",
            uploadBulkFile:"",
        }
        this.loadMaterials = this.loadMaterials.bind(this);
        this.changeBulMapFileHandler = this.changeBulMapFileHandler.bind(this);
        
    }
    async componentDidMount() {
        let url_type = window.location.href
        let path_type = url_type.split('?')
        let data_type
        let path = path_type[path_type.length-1]

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
        var material_configurations = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        this.setState({
            material_configurations : material_configurations
        });
        await this.boundTypeLocations(params)
        await this.loadCustomers()
        this.loadMaterials();
    }

    loadCustomers = () =>{
        var params = {
           
        }
        redirectURL.post('/forecast/suppliers',params)
        .then(response =>{
            var suppliers = [];
            var service_locations  = [];
            if(response.data.length > 0)
            {
                response.data.map((item) => {
                    suppliers.push({value:item.supplier_code,label:item.supplier_name})
                    if(item.locations.length > 0)
                    {
                        item.locations.map((l) => {
                            service_locations.push({value:l.service_city,label:l.service_city});
                        })
                    }
                })
            }
            var newArray = [];
            let totuniqueObject = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in service_locations) { 

                // Extract the title
                if(service_locations[i]['value'] !== "" && service_locations[i]['value'] !== undefined)
                {
                    totobjTitle = service_locations[i]['value']; 

                    // Use the title as the index 
                    totuniqueObject[totobjTitle] = service_locations[i];
                } 
                 
            } 
            // Loop to push unique object into array 
            for (var a in totuniqueObject) { 
                newArray.push(totuniqueObject[a]); 
            } 
            this.setState({
                rowData:response.data,
                suppliers:suppliers,
                service_locations:newArray,
                loadshow:'show-n',
                overly:'show-n',
                overlayBlock:"show-n"
            })
        })
    }
    loadMaterials = () =>{
        var params = {
           
        }
        redirectURL.post('/forecast/allmaterials',params)
        .then(response =>{
            var materials = [];
            if(response.data.length > 0)
            {
                response.data.map((item) => {
                    materials.push({value:item.item_code+"___"+item.item_name,label:item.item_code})
                })
            }
            this.setState({
                materials:materials
            })
        })
    }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
    onClickShowUpload = () =>{
        this.setState({
            showSlideBlockUpload:"slide25",
            overlayBlock:"show-m"
        })
    }

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
            showSlideMapping:"",
            showSlideServiceLocation:"",
            showSlideBulkMap:"",
            loadFile:'',
            loadshow:'show-n',
            overly:'show-n',
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        redirectURL.post("dispatch/generateLoadByTransactionId",params).then((response)=>{
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if(statusCode == 0 || statusCode == 1)
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    processErrMsg : "",
                    processErrShow : 0,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            //window.location.reload();
        });
    }
    
    changeFileHandler = async (e) => {
        // console.log(e);
        this.setState({
            loadFile : e.target.files[0]
        });  
        
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    changeBulMapFileHandler = async (e) => {
        // console.log(e);
        this.setState({
            uploadBulkFile : e.target.files[0]
        });  
        
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadBulkFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadBulkFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("file", this.state.loadFile);
           
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            redirectURL.post("/forecast/readXLSData",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                var uploadxlsdata = response.data.records;
                //   console.log("uploadxlsdata ", uploadxlsdata)
                  if(uploadxlsdata.length > 0)
                  {
                    var p = {
                      xlsdata:uploadxlsdata
                    }
                    // console.log("p ", p)
                    redirectURL.post("/forecast/saveBulkSuppliers",p)
                    .then((resp) => {
                    //   console.log(resp.data)
                      if(resp.data.message == "success")
                      {
                        this.setState({
                          uploadfile:"",
                          showSlideBlockUpload:"",
                          overlayBlock:"show-n",
                          show:true,
                          basicTitle:"Successfully upload data",
                          basicType:"success",
                          showloader:"show-n"
                        });
                        // window.location.reload();
                      }
                      else{
                        this.setState({
                          show:true,
                          basicTitle:"Failed to upload data",
                          basicType:"danger",
                          showloader:"show-n"
                        });
                      }
                    })
                  }
            });
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        redirectURL.post("/dispatch/checkTransactionStatus",query).then((response)=>{
            console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode == 0)
            {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount)+1;
                this.setState({
                    checkCount : updatedCnt
                });
                // console.log(updatedCnt,"checkCount")
                if(updatedCnt < 3)
                {
                    this.chkStatus(response.data.transactionId);
                }
                else
                {
                    this.setState({                    
                        basicTitle : "File is processing",
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                        loadFile:''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");
                }
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
                this.getDispatchPlanData()
            }
        });
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }

    viewPlanDetails = async(propdata) =>{
        var data = propdata.data;
        let basestring = data.transaction_id;
        let encryptedstring = window.btoa(basestring);
        window.location.href = '/viewdispatchplandetails?'+encryptedstring;
    }


    locationChanged = async(location) =>{
        await this.setState({
            location : location
        })
        await this.getDispatchPlanData()
    }
    saveSupplierServiceLocation(event){
        event.preventDefault();
        var supplierrow = this.state.supplier_rownode;
        var params = {
            supplier_code : supplierrow.supplier_code,
            supplier_code_1 : supplierrow.supplier_code_1,
            supplier_code_2 : supplierrow.supplier_code_2,
            service_address: this.state.service_address,
            service_city: this.state.service_city,
            service_state: this.state.service_state,
            service_person:this.state.service_person,
            service_person_email:this.state.service_person_email,
            service_person_contact:this.state.service_person_contact,
        }
        // console.log("Params ", params)
        redirectURL.post("/forecast/savesupplierlocation",params)
        .then((response) =>{
            if(response.data.status === "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully added item",
                    basicType:"success",
                    supplier_rownode:"",
                    service_address:"",
                    service_city:"",
                    service_person:"",
                    service_person_contact:"",
                    service_person_email:"",
                    service_state:"",
                    showSlideServiceLocation:"",
                    overlayBlock:"show-n"
                })
                this.loadCustomers()
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Something went wrong",
                    basicType:"danger"
                })
            }
        })
    }
    onChangeSupplier(supplier){
        this.setState({supplier})
    }
    changeHandler(event){
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    onClickShowServiceLocation(rownode){
        // console.log("rownode ", rownode)
        this.setState({
            showSlideServiceLocation:"slide30",
            overlayBlock:"show-m",
            supplier_rownode:rownode.data
        })
    }
    onClickViewItems(rownode){
        // console.log("rownode ", rownode);
        this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs:[
                    {
                        headerName:"Address",
                        field:"service_address",
                        editable:false,
                        width:300
                    },
                    {
                        headerName:"Service Location / City",
                        field:"service_city",
                        editable:false,
                        width:180
                    },
                    {
                        headerName:"Service State",
                        field:"service_state",
                        editable:false,
                        width:150
                    },
                    {
                        headerName: "Contact Person",
                        field: "service_person",
                        width:160,
                        filter: true,
                        resizable: true,
                        editable:false,
                    },
                    {
                        headerName: "Email Id",
                        field: "service_person_email",
                        width:180,
                        filter: true,
                        resizable: true,
                        editable:false,
                    },
                    {
                        headerName: "Contact No",
                        field: "service_person_contact",
                        width:160,
                        filter: true,
                        resizable: true,
                        editable:false,
                    },
                    ],
                        defaultColDef :{
                            // flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable:true,
                            initialWidth: 200,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                            sortable:true
                        },
                    context: this,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                // components: { datePicker: getDatePicker() },
                },
                getDetailRowData:async function(param) {
                    try {
                        param.successCallback([]);
                        var rowitems = param.data.locations;
                        param.successCallback(rowitems);
                            
                    } catch (error) {
                        
                    }
                        
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'viewitems')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
    onClickMappItems(rownode){
        console.log("rownode ", rownode)
        this.setState({
            mappingRow:rownode.data,
            maprowData:rownode.data.materials,
            showSlideMapping:"slide60",
            overlayBlock:"show-m"
        })
    }
    onChangeMaterial(material)
    {
        this.setState({material},()=>{
            if(this.state.material.value !== "")
            {
                var ml = this.state.material.value.split("___");
                this.setState({
                    material_name:ml[1],

                })
            }
        })
    }
    onChangeLocation(service_location)
    {
        this.setState({service_location},()=>{})
    }
    saveSupplierMaterialMapping(event){
        event.preventDefault()
        var material_name = this.state.material_name;
        var item_code = this.state.material.label;
        var item_name = this.state.material.value;
        var service_location = this.state.service_location.value;
        var supplier_code_1 = this.state.mappingRow.supplier_code_1;
        var supplier_code_2 = this.state.mappingRow.supplier_code_2;
        var supplier_code = this.state.mappingRow.supplier_code;
        var supplier_name = this.state.mappingRow.supplier_name;
        var lead_time = this.state.lead_time;
        var params = {
            supplier_name:supplier_name,
            supplier_code:supplier_code,
            supplier_code_1:supplier_code_1,
            supplier_code_2:supplier_code_2,
            material_name:material_name,
            material_code:item_code,
            material:item_name,
            service_location:service_location,
            lead_time:lead_time
        }
        // console.log("params ", params)
        redirectURL.post("/forecast/savesuppliermaterisl",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully mapped item",
                    basicType:"success",
                    service_location:{value:"",label:"Select Location"},
                    material:{value:"",label:"Select Material"},
                    material_name:"",
                    lead_time:"",
                    mappingRow:"",
                    showSlideMapping:"",
                    overlayBlock:"show-n"
                })
                this.loadCustomers()
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Something went wrong",
                    basicType:"danger"
                })
            }
        })
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    onGridReady1 = (params) => {
		this.gridApiNew = params.api;
		this.gridColumnApiNew = params.columnApi;

    };
    onClickShowBulkMapForm(){
        this.setState({
            showSlideBulkMap:"slide25",
            overlayBlock:"show-m"
        })
    }
    
    uploadBulkMapData(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("file", this.state.uploadBulkFile);
           
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            redirectURL.post("/forecast/readXLSSupplierData",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                var uploadxlsdata = response.data.records;
                var notexists = response.data.notexists;
                  console.log("uploadxlsdata ", uploadxlsdata)
                  console.log("notexists ", notexists)
                  if(notexists === undefined)
                  {
                    if(uploadxlsdata.length > 0)
                    {
                        var p = {
                        xlsdata:uploadxlsdata
                        }
                        // console.log("p ", p)
                        redirectURL.post("/forecast/saveBulkSupplierMapping",p)
                        .then((resp) => {
                        //   console.log(resp.data)
                        if(resp.data.message == "success")
                        {
                            this.setState({
                                uploadBulkFile:"",
                                showSlideBulkMap:"",
                                overlayBlock:"show-n",
                                show:true,
                                basicTitle:"Successfully upload data",
                                basicType:"success",
                                showloader:"show-n",
                                overly:"show-n"
                            });
                            window.location.reload();
                        }
                        else{
                            this.setState({
                                show:true,  
                                basicTitle:"Failed to upload data",
                                basicType:"danger",
                                showloader:"show-n",
                                overly:"show-n"
                            });
                        }
                        })
                    }
                  }
                  else{
                    this.setState({
                        uploadBulkFile:"",
                        showSlideBulkMap:"",
                        overlayBlock:"show-n",
                        show:true,
                        basicTitle:"Suppliers codes '"+notexists+"' are not exists, Please add supplier(s) before mapping",
                        basicType:"info",
                        showloader:"show-n",
                        overly:"show-n"
                    });
                  }
            });
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };
    render() {

        var columnwithDefs = [
            {
                headerName: "",
                field: "_id",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "AddLocation"
                    };
                    return rendComponent
                
                },
            }, 
            {
                headerName: "",
                field: "viewitems",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewItems",
                        buttonText:"Locations"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName: "",
                field: "supplier_code",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "Mapping"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName: "Supplier Code",
                field: "supplier_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Supplier Name",
                field: "supplier_name",
                width:170,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Supplier Address",
                field: "supplier_address",
                width:300,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Supplier City",
                field: "supplier_city",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Supplier State",
                field: "supplier_state",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Contact Person",
                field: "contact_person",
                width:160,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Email Id",
                field: "contact_email",
                width:180,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Contact No",
                field: "contact_no",
                width:160,
                filter: true,
                resizable: true,
                editable:false,
            },
            
        ]
       
        
        var columnwithMaterialDefs = [
         
            {
                headerName: "Material Code",
                field: "item_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Material Name",
                field: "item_name",
                width:340,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Lead Time (Days)",
                field: "lead_time",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            
        ]
        return (
            <div class="container-fluid">
                <div className={"animatenow "+(this.state.showanimate)} style={{textAlign:"center"}}>
                    <span className="pp-h">Processing Plan. Please wait.....</span>
                    <img src={require("../../assets/images/r.gif")} style={{width:"85vh"}} />
                </div>
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            Suppliers
                            <button type="button" className="btn btn-info float-right font-white" onClick={this.onClickShowBulkMapForm.bind(this)}>Bulk Supplier Mapping</button> 
                            <button type="button" className="btn btn-warning float-right font-white" onClick={this.onClickShowUpload}>Upload Suppliers</button>
                            {/* <button type="button" className="btn btn-info float-right font-white" onClick={this.onClickShowServiceLocation.bind(this)}>Add Service Location</button> */}
                            
                        </h5>
                    </div>
                </div>
               
                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this, buttons:{viewItemBtn:"Locations"} }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">Upload Suppliers</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            {this.state.processErrShow == 0 ?
                            <form onSubmit={this.uploadBulkFormHandler.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Upload File</label> 
                                        <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                                    </div>                        
                                    <div className="form-group col-sm-12 mb-20p">
                                        <button type="submit" className="btn btn-info">SAVE</button>
                                        <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                            :
                            ""
                            }
                        </div>
                    </div>
                </div>

                <div className={"sliderBlock2 "+(this.state.showSlideBulkMap)}>
                    <h5 className="crd-bg p-10p">Supplier Mapping</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            {this.state.processErrShow == 0 ?
                            <form onSubmit={this.uploadBulkMapData.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Upload File</label> 
                                        <input type="file" name="uploadBulkFile" id="uploadBulkFile" onChange={this.changeBulMapFileHandler}  className="form-control" required  /><br />
                                        <br /> 
                                        <span style={{color:"#ff0000",fontSize:"12px;"}}>*** Allows only CSV file, and file size should be below 20KB.</span>
                                    </div>                        
                                    <div className="form-group col-sm-12 mb-20p">
                                        <button type="submit" className="btn btn-info">SAVE</button>
                                        <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>                       
                                    <div className="form-group col-sm-12 mb-20p">
                                        <a href={require("./forecast-supplier-mapp.csv")} style={{color:"#333"}} target="_blank" className="btn btn-warning">Download Sample File</a>
                                    </div>
                                </div>
                            </form>
                            :
                            ""
                            }
                        </div>
                    </div>
                </div>

                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                <div className={"loader "+this.state.loadshow}></div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>

                <div className={"sliderBlock2 "+(this.state.showSlideServiceLocation)}>
                    <h5 className="crd-bg p-10p">Add Service Location - <span style={{fontSize:"12px"}}>{this.state.supplier_rownode.supplier_name} ({this.state.supplier_rownode.supplier_code})</span></h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <form onSubmit={this.saveSupplierServiceLocation.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Address</label> 
                                        <input type="text" name="service_address" id="service_address" value={this.state.service_address} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                                    </div>    
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Service Location / City</label> 
                                        <input type="text" name="service_city" id="service_city" value={this.state.service_city} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                                    </div>               
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Service State</label> 
                                        <input type="text" name="service_state" id="service_state" value={this.state.service_state} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                                    </div>               
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Service Contact Person</label> 
                                        <input type="text" name="service_person" id="service_person" value={this.state.service_person} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                                    </div>                       
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Contact Email</label> 
                                        <input type="text" name="service_person_email" id="service_person_email" value={this.state.service_person_email} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                                    </div>                       
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Contact No</label> 
                                        <input type="text" name="service_person_contact" id="service_person_contact" value={this.state.service_person_contact} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                                    </div>                       
                                    <div className="form-group col-sm-12 mb-20p">
                                        <button type="submit" className="btn btn-info">SAVE</button>
                                        <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                <div className={"sliderBlock2 "+(this.state.showSlideMapping)}>
                    <h5 className="crd-bg p-10p">Mapping <span style={{fontSize:"12px"}}>Supplier: {this.state.mappingRow.supplier_name} ({this.state.mappingRow.supplier_code})</span> </h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <form onSubmit={this.saveSupplierMaterialMapping.bind(this)}>
                                <div className="row p-20p">
                                      
                                    <div className="form-group col-sm-3 mb-20p">
                                        <label style={{color:"#000"}}>Service Location</label> 
                                        <Select
                                            options={this.state.service_locations}
                                            onChange={this.onChangeLocation.bind(this)}
                                            value={this.state.service_location}
                                        />
                                    </div>  
                                    <div className="form-group col-sm-3 mb-20p">
                                        <label style={{color:"#000"}}>Select Material</label> 
                                        <Select
                                            options={this.state.materials}
                                            onChange={this.onChangeMaterial.bind(this)}
                                            value={this.state.material}
                                        />
                                    </div>     
                                    <div className="form-group col-sm-3 mb-20p">
                                        <label style={{color:"#000"}}>Material Name</label> 
                                        <input type="text" name="material_name" id="material_name" readOnly={true} value={this.state.material_name} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                                    </div>    
                                    <div className="form-group col-sm-3 mb-20p">
                                        <label style={{color:"#000"}}>Lead Time (Days)</label> 
                                        <input type="text" name="lead_time" id="lead_time" value={this.state.lead_time} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                                    </div>                 
                                    <div className="form-group col-sm-4 mb-20p">
                                        <button type="submit" className="btn btn-info">SAVE</button>
                                        <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        
                        <div className="col-sm-12" style={{padding:"20px"}}>
                            <h4>Mapped Materials List</h4>
                            
                            <div id="myGrid2" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithMaterialDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.maprowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady1}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this, buttons:{viewItemBtn:"Locations"} }
                                    }}
                                    // components={this.state.components}
                                    // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                    enableRangeSelection= {true}
                                    masterDetail={true}
                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                        
                                    />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}