import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";

export default class AutoClustering extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleAvailabilityModel:false,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            showAvailablityTable:false,
            data_type:'',
            location:'',
            proximity_radius_kms:'',
            cluster_min_demand_cmt:'',
            rowId:'',
            locationNames:[],
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        await this.boundTypeLocations(params)
        await this.getAutoCluteringData(params)
    }

    getAutoCluteringData = (params) =>{
        redirectURL.post('master/getAutoCluteringData',params)
        .then(response =>{
            this.setState({
                rowData:response.data
            })
        })
    }

    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            proximity_radius_kms:'',
            cluster_min_demand_cmt:'',
            rowId:'',
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    
    vehicleConfigurationInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    saveAutoClutering = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveAutoClutering',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getAutoCluteringData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        proximity_radius_kms:'',
                        cluster_min_demand_cmt:'',
                        rowId:'',
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    formVehicleConfiguration = (e)=>{
        e.preventDefault();
        let proximity_radius_kms = this.state.proximity_radius_kms;
        let cluster_min_demand_cmt = this.state.cluster_min_demand_cmt;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let rowId = this.state.rowId;
        let params = {
            proximity_radius_kms : parseInt(proximity_radius_kms),
            cluster_min_demand_cmt : parseInt(cluster_min_demand_cmt),
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            rowId : rowId         
        }

        if(location.value != null){
            this.saveAutoClutering(params)
        }else{
            this.setState({
                show:true,
                basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                basicType:"danger"
            })
        }
    }

    editMaterialConfiguration = async(propdata) =>{
        let data = propdata.data
        await this.setState({
            "proximity_radius_kms" : data.proximity_radius_kms,
            "cluster_min_demand_cmt" : data.cluster_min_demand_cmt,
            "rowId" : data._id
        })
        this.openModelForMaterialConfiguration()
    }

    deleteAutoClutering = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteAutoClutering',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getAutoCluteringData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }

    render(){
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        var auto_clustering_type = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                var auto_clustering_type = toolConfig[0].auto_clustering_type;
            }
        }
        var colsDefs = [
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Edit',
                        onClickFunction:this.editMaterialConfiguration},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteAutoClutering},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
        ]
        if(auto_clustering_type == 1)
        {
            colsDefs.push(
                {
                    headerName:"Proximity Radius (kms)",
                    field:"proximity_radius_kms",
                    width:"250"
                },
            );
        }
        if(auto_clustering_type == 2)
        {
            colsDefs.push(
                {
                    headerName:"Cluster Min Demand (cmt)",
                    field:"cluster_min_demand_cmt",
                    width:"250"
                },
            );
        }
        if(auto_clustering_type == 3)
        {
            colsDefs.push(
                {
                    headerName:"Proximity Radius (kms)",
                    field:"proximity_radius_kms",
                    width:"250"
                },
                {
                    headerName:"Cluster Min Demand (cmt)",
                    field:"cluster_min_demand_cmt",
                    width:"250"
                },
            );
        }
        let data = this.state.rowData
        let locValue = this.state.location.value
         
        let filteredData = data.filter(item => item.location_code == locValue)

        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12 d-flex">
                <div className= {this.state.showAvailablityTable ? "col-sm-7 " : "col-sm-12 " + "  d-inline"}>
                        {(filteredData.length == 0)?
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Auto Clustering</span>
                                <div className='float-right'>
                                    <a href="javascript:;" onClick={this.openModelForMaterialConfiguration} className="f18 float-right themefont fbold mr-10p mb-10p">Add Auto Clustering</a>
                                </div> 

                        </h5>
                        :""}
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.locationChanged}
                                        name="location"
                                        value={this.state.location}
                                        options={this.state.locationNames} />

                                </div>
                            </div>

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                        
                    </div>

                    {/* { this.state.showAvailablityTable ?
                    <div className='col-sm-5 d-inline'>
                    
                    <h5 className='fbold white d-flex justify-content-between'>
                            <span>{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_HEADER_NAME}</span>
                            <div className='float-right'>
                            <a href="javascript:;" onClick={this.openModelForVehicleAvailability} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                             */}
                            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
                            {/* </div>    
                        </h5>
                        
                        <VehiclesAvailability
                        availabilityData = {this.state.availabilityData}/>
                        <span className="float-right">
                            <button onClick={this.hideVehicleAvailabilityTable} className="btn btn-warning f12 mr-15p mt-5p">Close</button>
                            </span>
                    </div>
                    :<div/>} */}
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openMaterialConfigurationModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>Auto Clustering</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formVehicleConfiguration}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>
                            {(auto_clustering_type == 1 || auto_clustering_type == 3)?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Proximity Radius (kms) </label>
                                    <input required type="text" className="forminp form-control" id="proximity_radius_kms" name="proximity_radius_kms" value={this.state.proximity_radius_kms} 
                                    onChange={this.vehicleConfigurationInputFields}
                                    placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                            :""}
                            {(auto_clustering_type == 2 || auto_clustering_type == 3)?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Cluster Min Demand (cmt)</label>
                                    <input required type="text" className="forminp form-control" id="cluster_min_demand_cmt" name="cluster_min_demand_cmt" value={this.state.cluster_min_demand_cmt} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                            :""}
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                    </Modal>
            </>
        )
    }
}