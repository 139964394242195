
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

 
const ViewStacking = (props) => {
    
  
    return (
        <div>
            <a href={"/packing/"+props.data._id} target="_blank" className="label label-warning"  style={{color:"#000",border:"0px"}}>
                <i className="fa fa-sitemap"></i>View Packing
            </a>
            
        </div>
    );
};

export default ViewStacking;
