import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import  draggable from '../../../node_modules/jquery-ui/ui/widgets/draggable';
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";

import redirectURL from '../redirectURL';
var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;

export default class LoadPlanRoute extends Component {
    constructor(props){
        super(props);
        this.state = {
            showSlideBlockUpload:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n"
        }
        this.onClickShowUpload=this.onClickShowUpload.bind(this);
        this.hideSlideBlock = this.hideSlideBlock.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.loadPlanData = this.loadPlanData.bind(this);
        this.handleChangeFilterDate = this.handleChangeFilterDate.bind(this);
		this.plotMultiColorPolyline = this.plotMultiColorPolyline.bind(this);
        this.extractDeliveryDates = this.extractDeliveryDates.bind(this);
        
        // window.dragIn = this.dragIn.bind(this);
        // window.dragOut = this.dragOut.bind(this);
        // window.updateMarkerPosition = this.updateMarkerPosition.bind(this);
        // window.generatePageMarkers = this.generatePageMarkers.bind(this);
        // window.generateMarkers = this.generateMarkers.bind(this);
        // window.getPixelPosition = this.getPixelPosition.bind(this);
        this.changeDeliveryDate = this.changeDeliveryDate.bind(this);
        this.changeCluster = this.changeCluster.bind(this);
        this.changeVehicleType = this.changeVehicleType.bind(this);
      }
    componentDidMount() {
        
        this.loadPlanData();
  
    }
    loadPlanData(){
        this.setState({
            showloader:"show-m"
        })
        redirectURL.post("/dealers/loadplan")
        .then(async (response) => {
            var records = response.data;
            // console.log("records ", records)
            var deliverydates = [];
            records = records.sort(GetSortAscOrder("cluster"));
                
            var recordsarr = groupBy(records, rdata => rdata.cluster);
            // console.log("recordsarr ", recordsarr)
            var loaddata = [];
            var allLoadPlan = [];
            try{
                var sn=0;
              recordsarr.forEach((item,key) => {
                    
                item = item.sort(GetSortAscOrder("sequence_no"));
                var bb = [];
                var capacity = 0;
                var distance = 0;
                if(item.length > 0)
                {
                    item.map((i,n) => {
                        capacity = parseFloat(capacity)+i.vehicle_capacity;
                        distance = parseFloat(distance)+i.distance;
                        bb.push(i)
                    })
                }
                
                var rw1 = {
                    cluster:key,
                    items: bb,
                    distance:distance.toFixed(2),
                    capacity:capacity.toFixed(2)
                }
                allLoadPlan.push(rw1);
                loaddata.push(rw1);
                
              })
            }
            catch(e){
    
            }
            // console.log("loaddata ", loaddata)
            // console.log("allLoadPlan ", allLoadPlan)
            this.setState({
                loadplan:loaddata,
                allLoadPlan:allLoadPlan,
                showloader:"show-n"
            })
            
        })
      }
      renderMap = () => {   
            
            loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing,geometry&callback=initMap");
            // loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }
      logout()
         {
            
               localStorage.removeItem('tn');
               localStorage.removeItem('s');
               localStorage.removeItem('sidebar');
               localStorage.clear();
               sessionStorage.removeItem('tn');
               sessionStorage.removeItem('s');
               sessionStorage.clear();
       
               sessionStorage.setItem('ref', null);
               //localStorage.setItem('menuText',[]);
               delete axios.defaults.headers.common['Authorization'];
               this.setState({
                   redirectToReferrer: ''
               });
         }
      
     
        hideSlideBlock(){
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:""
        })
      }
      initMap = async () => 
        {

		    var currentwindow;
            var arr=[];
            var arr1=[];
            var locInfo = this.props.locInfo;
            var arr = this.state.routeData;
            // console.log("coords",arr)
            if( arr == undefined)
            {
                var lt=28.4519751;
                var ln=77.0310713;
            }
          
            // console.log("arr ", arr)
            var routeinfo ;
            
            try{
                var mapOptions = {
                    zoom: 12,
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    streetViewControl: true,
                    rotateControl: true,
                    fullscreenControl: true,
                    labels:true,
                    mapTypeControlOptions: {
                        mapTypeIds: ['satellite', 'roadmap'],
                    },
                    center: new window.google.maps.LatLng(lt,ln),
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    styles: [
                    
                        {
                            "featureType": "administrative",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#444444"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 45
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                    ],
                };
                        
            }
            catch(e){
                var mapOptions = {
                    zoom: 13,
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    streetViewControl: true,
                    rotateControl: true,
                    fullscreenControl: true,
                    labels:true,
                    mapTypeControlOptions: {
                        mapTypeIds: ['satellite', 'roadmap'],
                    },
                    center: new window.google.maps.LatLng(28.4519751,77.0310713),
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    styles: [
                    
                        {
                            "featureType": "administrative",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#444444"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 45
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                    ],
                };
                        
            }
            try{
                map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
            }catch(e){}
            
            var bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(lt,ln));
                // Create our info window content
            var currentinfowindow = null;
            var line=new window.google.maps.Polyline(
            {
                map:map,
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [{
                        icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                strokeColor:'#ff8c52',
                                fillColor:'#ff8c52',
                                fillOpacity:1,
                                strokeWeight: 2
                        },
                        repeat:'100px',
                        path:[]
                    }]
            });
            // var colors = ["#21BC5C","#2152BC","#21B7BC","#8BBC21", "#BC4921","#BCA221"]
            var alphas = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL","AM","AN","AO","AP","AQ","AR","AS","AT","AU","AV","AW","AX","AY","AZ",
            "BA","BB","BC","BD","BE","BF","BG","BH","BI","BJ","BK","BL","BM","BN","BO","BP","BQ","BR","BS","BT","BU","BV","BW","BX","BY","BZ",
            "CA","CB","CC","CD","CE","CF","CG","CH","CI","CJ","CK","CL","CM","CN","CO","CP","CQ","CR","CS","CT","CU","CV","CW","CX","CY","CZ"]
            var alldata = [];
            if(arr == undefined){
    
            }
            else{
                var allplants=[]
                try{
                    if(arr.length > 0)
                    {
                        var wapoints = [];
                        var waypaths = [];
                        // console.log(arr,"arr")
                        await arr.map(function(er,clr){
                        
                        var datapoints = [];
                        var datapointslist = [];
                        datapointslist = er.items;
                        
                        if(datapointslist.length > 0)
                        {
                            var darr = datapointslist.filter(function (value, index, arr) {
                                //console.log("Vale ",value)
                                return value;
                            } );
                            if(darr.length < 26)
                            {
                                var ratio = 1;
                            }
                            else if(darr.length < 50)
                            {
                                var ratio = 2;
                            }
                            else if(darr.length < 100)
                            {
                                var ratio = 4;
                            }
                            
                            else if(darr.length < 200)
                            {
                                var ratio = 8;
                            }
                            else if(darr.length < 300)
                            {
                                var ratio = 12;
                            }
                            else if(darr.length < 400)
                            {
                                var ratio = 15;
                            }
                            else if(darr.length < 500)
                            {
                                var ratio = 20;
                            }
                            // else if(darr.length > 26 && darr.length <500)
                            // {
                            //     var ration = 200;
                            // }
                            
                            // else if(darr.length > 500 && darr.length <2000)
                            // {
                            //     var ration = 300;
                            // }
                            
                            // else if(darr.length > 2000 && darr.length <5000)
                            // {
                            //     var ration = 400;
                            // }
                            // else if(darr.length > 5000 && darr.length <15000)
                            // {
                            //     var ration = 500;
                            // }
                            else{
                                //  changed value 20 to 1 as per the 24th august 2022 change 
                                var ratio = 150;
                            }
                            //console.log("darr.length ", darr.length)
                            datapoints = darr.filter(function (value, index, darr) {
                                return (index % ratio == 0);
                            } );
                        }
                        
                        // console.log("datapoints ", datapoints)

                        //Hi VENKATESH based on waypoints we need to fran route linepath try this once.
            
                        var bounds = new window.google.maps.LatLngBounds();
                        var markers = [];
                                
                        //this.plotMultiColorPolyline(arr,map,bounds);
                        var directionsService = new window.google.maps.DirectionsService;
                        var directionsDisplay = new window.google.maps.DirectionsRenderer();
                        directionsDisplay.setMap(map);
                        var slant = datapoints[0];
                        // console.log("slant.lat",slant.lat)
                        // console.log("slant.lng",slant.lng)
                        var startlng= new window.google.maps.LatLng( parseFloat(slant.lat),parseFloat(slant.lng));
                        var endlng= "";
                        var waypts = [];
                        datapoints.map(function(e,index){
                            if(e.type == "PLANT")
                            {
                                allplants.push(e);
                            }
                            // console.log("eitem lat ", e.lat)
                            // console.log("eitem lng ", e.lng)
                            var latLng = new window.google.maps.LatLng( parseFloat(e.lat),parseFloat(e.lng));
                            waypts.push({
                                location: latLng,
                                stopover: true
                            });
                            if(index == (datapoints.length - 1))
                            {
                                // console.log("e.lat",e.lat)
                                // console.log("e.lng",e.lng)
                                endlng = new window.google.maps.LatLng(parseFloat(e.lat),parseFloat(e.lng));
                            }
            
                            bounds.extend(latLng);
                            addMarker(latLng,(e.sequence_no),e);
                        });
                        if (waypts.length > 0)
                        {
                            waypts.pop();
                        }
                        // console.log("waypts ", waypts)
                        // console.log("startlng ", startlng)
                        // console.log("endlng ", endlng)
                        var ff = {
                            origin: startlng,
                            destination: endlng,
                            waypoints: waypts,
                            travelMode: 'DRIVING'
                        };
                        var colors=generateRandomColor();
                        // console.log("colors FFF ", colors)
                        directionsService.route({
                            origin: startlng,
                            destination: endlng,
                            waypoints: waypts,
                            travelMode: 'DRIVING'
                        }, function(response, status) {
                            // console.log("status ", status)
                            // console.log("response status ", response)
                            // OVER_QUERY_LIMIT
                            if (status === 'OK') {
                                const route = response.routes[0];
                                alldata.push(response);
                                var routepathss = route.overview_path;
                                for(var r=0;r<routepathss.length;r++)
                                {
                                    // console.log("asasadsadsadsdasd ",routepathss[r].lat())
                                    waypaths.push({
                                        // item:e,
                                        lat:routepathss[r].lat(),
                                        lng:routepathss[r].lng(),
                                        color:colors
                                    })
                                }
                            var summaryPanel = "";

                            // For each route, display summary information.
                            for (let i = 0; i < route.legs.length; i++) {
                                var rw = {
                                    lat:route.legs[i].steps[0].lat_lngs[0].lat(),
                                    lng:route.legs[i].steps[0].lat_lngs[0].lng(),
                                    distance:route.legs[i].distance,
                                    duration:route.legs[i].duration,
                                    start_address:route.legs[i].start_address,
                                    end_address:route.legs[i].end_address,
                                    start_location:route.legs[i].start_location,
                                    end_location:route.legs[i].end_location,
                                    label:alphas[i]
                                }
                                
                                wapoints.push(rw)
                            }
                            // if(status == window.google.maps.GeocoderStatus.OVER_QUERY_LIMIT)
                            // {
                            //     setTimeout(3000);
                            // }
                            // else{

                                directionsDisplay.setDirections(response);
                                directionsDisplay.setOptions({
                                    polylineOptions: {
                                    strokeColor: colors,
                                    icons: [{
                                        icon: {
                                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                strokeColor:'#ff8c52',
                                                fillColor:'#ff8c52',
                                                fillOpacity:1,
                                                strokeWeight: 2
                                        },
                                        repeat:'100px',
                                        path:[]
                                    }]
                                    }
                                });

                                
                                // console.log("summaryPanel ", summaryPanel)

                            // }

                            
                        var totalDistance = 0;
                        var totalDuration = 0;
                        var legs = response.routes[0].legs;

                            for(var i=0; i<legs.length; ++i) {
                                totalDistance += legs[i].distance.value;
                                totalDuration += legs[i].duration.value;
                            }
                        
                                            
                        $('.ttlDuration').html("  Total Duration : " + Math.round(totalDuration/60/60,0) + " Hrs");
            
                        for (var x=0;x < markers.length;x++)
                        {
                            markers[x].set('labelContent', x+1);
                            // console.log("markers[x] ", markers[x])
                        }
                        
                        // var route = response.routes[0];
                        } else {
                            window.alert('Directions request failed due to ' + status);
                        }
                    });


                    var mapIcon = require('../../assets/images/truck_3_32.png');              
                    function addMarker(location,lbl,item) {
                        // console.log("lbl" , lbl)
                        var marker = new window.google.maps.Marker({
                            position: location,
                            label:lbl,
                            icon: mapIcon,
                            map: map
                        });
                        
                        markers.push(marker);
                        if(item.type == "PLANT"){
                            var locInfo = "<h5 style='color:#082e19;padding:10px;'>"+item.dealer_name+"</h5><p style='margin-top:5px;margin-bottom:5px;padding:0 10px;font-weight:bold;'>Location: <b>"+item.location+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Start Time: <b>"+item.vehicle_start_time+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Start Time: <b>"+item.vehicle_end_time+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Distance (kms): <b>"+item.distance+"</b></p>";
                        }
                        else{
                            var locInfo = "<h5 style='color:#082e19;padding:10px;'>"+item.dealer_name+"</h5><p style='margin-bottom:5px;margin-top:5px;padding:0 10px;font-weight:bold;'>Location: <b>"+item.location+"here</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Start Time: <b>"+item.vehicle_start_time+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Start Time: <b>"+item.vehicle_end_time+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Distance (kms): <b>"+item.distance+"</b></p>";
                        }
                        var infowindow = new window.google.maps.InfoWindow({
                            //content: this.props.locInfo,
                            content: locInfo,
                            maxWidth: 350
                        });
            
                        marker.addListener('click', function() {
                            infowindow.open(map, marker);
                        });
                        //oms.addMarker(marker);
                    }
                    })
                    setTimeout(()=>{
                    // this.plotMultiColorPolyline(waypaths,map,wapoints);
                    },2000)
                    
                //   console.log("waypaths ", waypaths)
                //   console.log("waypoints ", wapoints)
        
                
                }

                // console.log("allplants ", allplants)
                var infowindow = new window.google.maps.InfoWindow();
                if(allplants.length > 0)
                {
                    var markers= []
    		  	for (let i = 0; i < allplants.length; i++) {
				  //if(arr[i].speed > 0)
				 // {
                    var markerLatlng = new window.google.maps.LatLng(parseFloat(allplants[i].lat), parseFloat(allplants[i].lng));
                    var image =  require('../../assets/images/business.png');

                    var mark = new window.google.maps.Marker({
                        position: markerLatlng,
                        map: map,
                        title:allplants[i].dealer_name,
                        icon: image
					});
					markers.push(mark)
                    mark.addListener('click', function() {
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "PLANT"
						contentarr.push({"key":"Plant Name", "value":allplants[i].dealer_name});
						var contentString = infoBox(mark.icon, header, contentarr,'')
							
						infowindow.setContent(contentString);	
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, mark);
					  });
                    }
                }
            }
            catch(e){

            }
        }
    }

    plotMultiColorPolyline(arr2,map,waypoints, baseroute=false)
    {
        // console.log("waypoints ", waypoints)
        var lt = arr2[0].lat;
        var ln = arr2[0].lng;
        var mapOptions = {
            zoom: 13,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels:true,
            mapTypeControlOptions: {
                mapTypeIds: ['satellite', 'roadmap'],
            },
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP
        };
                
        
        
        try{
            map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        }catch(e){}
        // console.log("arr2 ", arr2)
        
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(lt,ln));

        var icon_cnt = 1;
        var icon_divisible = 1;
        
        for(var i=0;i< arr2.length-1;i++)
        {
            var locationLatLng = [];
            locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
            locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
            if(icon_cnt % icon_divisible == 0)
            {
                var lineicon = [{
                            // icon: {
                            //         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            //         strokeColor:'#FF0000',
                            //         fillColor:'#FF0000',
                            //         fillOpacity:1,
                            //         strokeWeight: 2
                            // },
                            repeat:'100px',
                            path:[]
                        }];
            }else{lineicon=[]}
            icon_cnt = icon_cnt + 1
            var consignments_missing_route_line = new window.google.maps.Polyline({
                map:map,
                path : locationLatLng,
                strokeColor: arr2[i].color,
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: lineicon
            });
            
            bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
        }
        var marker;
        for(var w=0;w< waypoints.length-1;w++)
        {
            marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(waypoints[w].lat, waypoints[w].lng),
                icon:require('../../assets/images/pin.png'),
                map: map,	
                label:waypoints[w].label,
            });
            window.google.maps.event.addListener(marker, 'click', (function(marker) {
                return function() {
                    //	console.log(clusters[0])
                    
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                // var contentarr = []
                // //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                // var header = "Starting Point"
                // contentarr.push({"key":"Route - "+(index+1).toString(), "value":"Starting Point"})
                
                // var contentString = infoBox(marker.icon, header, contentarr,'')
                    
                // infowindow.setContent(contentString);
                // currentwindow = infowindow;
                // infowindow.open(map, marker);
                // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                }
            })(marker));
            
            bounds.extend(new window.google.maps.LatLng(waypoints[w].lat,waypoints[w].lng));
        }
    }
        onClickShowUpload(){
            this.setState({
                showSlideBlockUpload:"slide25",
                overlayBlock:"show-m"
              })
        }
    
    changeFileHandler = async (e) => {
        console.log("e.target.files[0] ", e.target.files[0])
        this.setState({
            uploadfile:e.target.files[0]
        });
    }
    
    formUpload(event){
        event.preventDefault();
        var reqparams = new FormData()
        reqparams.append("file", this.state.uploadfile)
        // console.log("reqparams ", reqparams);
        redirectURL.post("/dealers/readXLSData",reqparams)
        .then((response) => {
          // console.log(response.data)
          var uploadxlsdata = response.data.records;
          console.log("uploadxlsdata ", uploadxlsdata)
          if(uploadxlsdata.length > 0)
          {
            var p = {
              xlsdata:uploadxlsdata
            }
            redirectURL.post("/dealers/uploadLoadDealersPlan",p)
            .then((resp) => {
              console.log(resp.data)
              if(resp.data.message == "success")
              {
                this.setState({
                  uploadfile:"",
                  showSlideBlockUpload:"",
                  overlayBlock:"show-n",
                  show:true,
                  basicTitle:"Successfully upload data",
                  basicType:"success"
                });
                window.location.reload();
              }
              else{
                this.setState({
                  show:true,
                  basicTitle:"Failed to upload data",
                  basicType:"danger"
                });
              }
            })
          }
        })
    }

    extractDeliveryDates(){
        var allLoadPlan = this.state.allLoadPlan;
        // console.log("allLoadPlan ", allLoadPlan)
        if(allLoadPlan.length > 0)
        {
            var deliverdates = [];
            var deliveryopts = [];
            allLoadPlan.map((i,k) => {
                var r = {
                    delivery_date:i.delivery_date,
                    delivery_dateformat:i.delivery_dateformat
                }

                deliveryopts.push({
                    value:i.delivery_date,
                    label:i.delivery_dateformat
                })
                deliverdates.push(r)
            })
            this.setState({
                deliverydates:deliverdates,
                deliveryopts:deliveryopts
            })
            // window.generatePageMarkers()
        }
    }
    changeDeliveryDate = (filterDeliveryDate) =>{
        this.setState({filterDeliveryDate},() => {
            // console.log(filterDeliveryDate)
            var p ={
                delivery_date:this.state.filterDeliveryDate.value
            }
            redirectURL.post("/dealers/clusterbydate",p)
            .then((response) => {
                var records = response.data.records;
                // console.log("records ,",records)
                var clusterlist = [];
                if(records.length > 0)
                {
                    records.map((itm) => {
                        clusterlist.push({
                            value:itm,
                            label:"Cluster "+itm
                        })
                    })
                }
                this.setState({
                    clusterlist:clusterlist
                })
            })
        })
    }
    changeCluster = (filtercluster) => {
        this.setState({filtercluster},() => {
            // console.log("filtercluster ",filtercluster)

        var p ={
            cluster:this.state.filtercluster.value,
            delivery_date:this.state.filterDeliveryDate.value
        }
        redirectURL.post("/dealers/vehicletypesbycluster",p)
        .then((response) => {
            var records = response.data.records;
            // console.log("records ,",records)
            var vehicletypelist = [];
            vehicletypelist.push({
                value:"",
                label:"All"
            })
            if(records.length > 0)
            {
                records.map((itm) => {
                    vehicletypelist.push({
                        value:itm,
                        label:itm
                    });
                })
            }
            this.setState({
                vehicletypelist:vehicletypelist,
            })
        })
            
        });
    }
    changeVehicleType = (filterVehicleType) => {
        this.setState({filterVehicleType},()=>{
            // var p ={
            //     cluster:this.state.selectedCluster,
            //     delivery_date:this.state.filter_date,
            //     vehicle_type:this.state.filterVehicleType.value
            // }
            // redirectURL.post("/dealers/supplierbyvehicletype",p)
            // .then((response) => {
            //     var records = response.data.records;
            //     // console.log("records ,",records)
            //     var dealerlist = [];
            //     dealerlist.push({
            //         value:"",
            //         label:"All"
            //     })
            //     if(records.length > 0)
            //     {
            //         records.map((itm) => {
            //             dealerlist.push({
            //                 value:itm.dealer_code,
            //                 label:itm.dealer_name
            //             });
            //         })
            //     }
            //     this.setState({
            //         fdealerlist:dealerlist,
            //         vsupplierdiv:"show-m"
            //     })
            // })
    
        });
        
    }
    changeDealers = (filterdealer) => {
        this.setState({filterdealer});
    }

//     dragIn(e, icon, index) {
//         var x = e.pageX - $('#appyFilter').offset().left;
//         var y = e.pageY;
//         var deliverydates = this.state.deliverydates;
//         if(x > 0) {
//             console.log("* ", e.target.id)
          
//             window.generateMarkers([deliverydates[index]]);
//         }
//     }
//     dragOut(e, marker, index) {
//         var deliverydates = this.state.deliverydates;
//         deliverydates[index].marker = null;
//         // extra display things
//         var content = deliverydates[index].delivery_dateformat;
//         $('#dropzone li').eq(index).find('.message').html('marker taken off map');
//         // TODO: add extra stuff here
//     }
     
    
//     updateMarkerPosition(e, marker) {
//         var deliverydates = this.state.deliverydates;
//         var index = deliverydates.itemIndex('marker', marker);
//         var position = e.latLng;
//         // update position
//         // $('#dropzone li').eq(index).find('.message').html('on map: ' + position.lat().toFixed(6) +','+ position.lng().toFixed(6));
//   }
  
//   generatePageMarkers(data) {
//     var deliverydates = this.state.deliverydates;
    
//     $('.dragicon').draggable({
//         containment: "#containment-wrapper", scroll: false ,
//         stop: function(e, ui) {
//         var index = deliverydates.itemIndex('id', $(this).data('id'));
//         console.log("index ", index)
//         window.dragIn(e, this, index);
//       }
//     });
//   }
  
//   generateMarkers(data) {
//         console.log(data)
//     }
//     getPixelPosition (marker) {
       
//     }

    onClickUpdateMap(){
        this.setState({
            routeData:[],
            showloader:"show-m"
        })
        var params = {
            delivery_date:this.state.filter_date,
            cluster:this.state.selectedCluster,
            truck_no:this.state.filterVehicleType.value,
            dealer_code:this.state.filterdealer.value
        }
        // console.log("params ", params);
        redirectURL.post("/dealers/filterloadplan",params)
        .then(async (response) => {
            var records = response.data;
            // console.log("records ", records)
            var deliverydates = [];
            records = records.sort(GetSortAscOrder("cluster"));
                
            // var recordsarr = groupBy(records, rdata => rdata.cluster);
            // console.log("recordsarr ", recordsarr)
            var loaddata = [];
            var allLoadPlan = [];
            try{
                var sn=0;
            //   recordsarr.forEach((items,key) => {
                    
                records = records.sort(GetSortAscOrder("sequence_no"));
                
                    var trucks = groupBy(records, rdata => rdata.truck_no);
                    // console.log("trucks",trucks);
                    var sn=0;
                    trucks.forEach((ti,k) => {

    
                    ti = ti.sort(GetSortAscOrder("sequence_no"));
                        if(sn < 10)
                        {
                        
                        // var dlrs = groupBy(ti, rdata => rdata.dealer_code);
                        // dlrs.forEach((item,dr) => {
                        //     var bb = [];
                        //     var capacity = 0;
                        //     var distance = 0;
                        //     if(item.length > 0)
                        //     {
                        //         item.map((i,n) => {
                        //             // console.log("IIII ", i)
                        //             capacity = parseFloat(capacity)+i.vehicle_capacity;
                        //             distance = parseFloat(distance)+i.distance;
                        //         })
                        //         bb.push(item[0])
                        //     }
                            
                            var rw1 = {
                                truck_no:k,
                                items: ti,
                                // distance:distance.toFixed(2),
                                // capacity:capacity.toFixed(2)
                            }
                            allLoadPlan.push(rw1);
                            loaddata.push(rw1);
                            
                        // })
                        }
                        sn=sn+1;
                    })
                
                
                
            //   })
            }
            catch(e){
    
            }
            console.log("loaddata ", loaddata)
            this.setState({
                routeData:loaddata,
                showloader:"show-n"
            });
            this.renderMap();
        })
    }
    onClickShowRoute(cluster){
        this.setState({
            showloader:"show-m"
        })
        // console.log("cluster ", cluster)
        var routeData =[];
        var alldata = this.state.allLoadPlan;
        var vtypes = [];
        var rowdata=[];
        if(alldata.length > 0)
        {
            // console.log("alldata ", alldata)
            alldata.map((item) => {
                if(item.cluster == cluster)
                {
                    // if(item.items.length > 0)
                    // {
                    //     item.items.map((im) => {
                    //         vtypes.push({"vehicle_type":im.vehicle_type})
                    //     })
                    // }
                    rowdata.push(item);
                }
            })
        }

        // console.log("rowdata ", rowdata)
        if(rowdata.length > 0)
        {
            rowdata.map((item) => {
                var data = item.items.sort(GetSortAscOrder("sequence_no"));
              
                        var trucks = groupBy(data, rdata => rdata.truck_no);
                        // console.log("trucks",trucks);
                        var sn =0;
                        trucks.forEach((ti,k) => {
                            if(sn < 10)
                            {
                                ti = ti.sort(GetSortAscOrder("sequence_no"));
                                
                                var rw1 = {
                                    cluster:cluster,
                                    items: ti,
                                }
                                routeData.push(rw1)
                            }
                            sn = sn+1;
                            
                        });
                    
            })
        }
        
// console.log("routeData ", routeData)
        // var vehtypes = [];
        // var newArray = [];
        // let totuniqueObject = {}; 
        // var totobjTitle;
        // // Loop for the array elements 
        // for (let i in vtypes) { 

        //     // Extract the title 
        //     totobjTitle = vtypes[i]['vehicle_type']; 

        //     // Use the title as the index 
        //     totuniqueObject[totobjTitle] = vtypes[i]; 
        // } 

        // // Loop to push unique object into array 
        // for (var a in totuniqueObject) { 
        //     newArray.push(totuniqueObject[a]); 
        // } 
        // if(newArray.length > 0)
        // {
        //     newArray.map((tp) => {
        //         vehtypes.push({
        //             value:tp.vehicle_type,
        //             label:tp.vehicle_type
        //         })
        //     })
        // }
        
        this.setState({
            routeData:routeData,
            mapcol:"col-sm-7",
            selectedCluster:cluster,
            gridocl:"col-sm-5",
            filter_date:"",
            filterVehicleType:{"value":"","label":"All"},
            filterdealer:{"value":"","label":"All"},
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            showloader:"show-n"
        })
        $("#filter_date").val("");

        this.renderMap();
    }
    onClickCloseMap(){
        this.setState({
            routeData:[],
            mapcol:"show-n",
            gridocl:"col-sm-12"
        })
    }
    handleChangeFilterDate(date) {
        // console.log("date ", date)
        if(date != "")
        {
            var dd = new Date(date).toString();
            // console.log('data ',moment.parseZone(dd).format("HH:mm"))
            var dt = moment.parseZone(dd).format("DD-MM-YYYY");
        // console.log("dt ", dt);
            $("#filter_date").val(dt);
            var d = dt.split("-");
            if(d[0].length == 4)
            {
                var dte = d[0]+""+d[1]+""+d[2];
            }
            else{
                var dte = d[2]+""+d[1]+""+d[0];
            }
            var p ={
                cluster:this.state.selectedCluster,
                delivery_date:dte
            }
            // console.log("asdsadasda ", dte)
            redirectURL.post("/dealers/trucksbycluster",p)
            .then((response) => {
                var records = response.data.records;
                // console.log("records ,",records)
                var vehicletypelist = [];
                vehicletypelist.push({
                    value:"",
                    label:"All"
                })
                if(records.length > 0)
                {
                    records.map((itm) => {
                        vehicletypelist.push({
                            value:itm,
                            label:itm
                        })
                    })
                }
                this.setState({
                    vehicletypelist:vehicletypelist,
                    filter_date: dte,
                    vtypediv:"show-m"
                })
            })
            
        }
      }
    render() {
        if(localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined )
        {
          return <Navigate to={"/login"} push={true} />
        }
        var loadplan = this.state.loadplan;
        return (
            <div class="container-fluid">
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            <i className="fa fa-users"></i> Load Plan Route
                        </h5>
                    </div>
                </div>
                <div class="row mb-20p">
                    <div className={"mxheit "+this.state.gridocl} >
                       
                {
                    (loadplan.length > 0)?
                        loadplan.map(item => 
                        <div className="card fcls-grid">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-1 col-md-2 white fmedium">
                                        <div className="hwhite flight">Cluster</div>
                                        {item.cluster}
                                    </div>
                                    <div className="col-sm-2 col-md-2 white fmedium">
                                        <div className="hwhite flight">Type</div>
                                        {item.items[0].type}
                                    </div>
                                    <div className="col-sm-2 col-md-5 white fmedium">
                                        <div className="hwhite flight">Supplier / Plant</div>
                                        {item.items[0].dealer_name}
                                    </div>
                                    
                                    <div className="col-sm-2 col-md-2 white fmedium">
                                        <div className="hwhite flight">Total Load (CMT)</div>
                                        {item.capacity}
                                    </div>
                                    
                                    <div className="col-sm-2 col-md-2 mt-20p white fmedium">
                                        <div className="hwhite flight">Transporter Mode</div>
                                        {item.items[0].vehicle_type}
                                    </div>
                                    
                                    <div className="col-sm-2 col-md-2 mt-20p white fmedium">
                                        <div className="hwhite flight">Distance (KM)</div>
                                        {item.distance}
                                    </div>
                                    <div className="col-sm-1 col-md-2 mt-20p">
                                        <button type="button" onClick={this.onClickShowRoute.bind(this,item.cluster)} className="label label-warning p-10p" style={{color:"#000"}}>View Route</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        )
                        
                    :""
                }
                    </div>
                    <div className={""+(this.state.mapcol)}>
                                <button className="btn btn-default psabbtn" onClick={this.onClickCloseMap.bind(this)}>Close Map</button>
                        <div className="card fcls-a npimp">
                           <div className="card-body npimp">
                                <div id="map" className="card" style={{width:"100%",height:"85vh"}}></div>
                                <div className="mpinf">
                                    <div className="mpinfbg"></div>
                                    <div className="row">
                                        <div className="col-sm-12 fbold f20 mb-10p">
                                            Cluster - {this.state.selectedCluster}
                                        </div>
                                        <div className="col-sm-3 fmedium">
                                            <label>Select Date</label>
                                            <Datetime  
                                                value = {this.state.filter_date} 
                                                onChange = {this.handleChangeFilterDate}
                                                id="filter_date"
                                                name="filter_date"
                                                closeOnSelect
                                                dateFormat="DD-MM-YYYY"
                                                timeFormat={false}
                                            />
                                        </div>
                                        <div className={"col-sm-3 fmedium "+(this.state.vtypediv)}>
                                            <label>Select Truck</label>
                                            <Select 
                                                placeholder={"Select Truck"}
                                                // closeMenuOnSelect={true}
                                                onChange={this.changeVehicleType} 
                                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                                style={{borderRadius:"0px"}}
                                                name="filterVehicleType"
                                                value={this.state.filterVehicleType}
                                                options={this.state.vehicletypelist} 
                                            /> 
                                        </div>
                                        <div className={"col-sm-4 fmedium "+(this.state.vsupplierdiv)}>
                                            <label>Select Supplier</label>
                                            <Select 
                                                placeholder={"Select Supplier"}
                                                // closeMenuOnSelect={true}
                                                onChange={this.changeDealers} 
                                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                                style={{borderRadius:"0px"}}
                                                name="filterdealer"
                                                value={this.state.filterdealer}
                                                options={this.state.fdealerlist} 
                                            /> 
                                        </div>
                                        <div className={"col-sm-2 fmedium "}>
                                            <button type="button" className="btn btn-warning mt-30p" onClick={this.onClickUpdateMap.bind(this)}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD}</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        <form onSubmit={this.formUpload.bind(this)}>
                        <div className="row p-20p">
                            <div className="form-group col-sm-12 mb-20p">
                                <label>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label>
                                
                                <input type="file" name="upload_file" id="upload_file" className="form-control" onChange={this.changeFileHandler} required />
                            </div>
                            <div className="form-group col-sm-12 mb-20p">
                            <button type="submit" className="btn btn-danger">SAVE</button>
                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                            </div>
                        </div>
                        </form>
                        </div>
                    </div>
                    </div>
                    
            </div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  

