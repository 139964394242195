
import React, { Component } from 'react';
import Constant from "../common/constants";

var infoBox = require('../common/google-infowindow');

var map
var overlay;
var infowindows=[];
var markersArraylist=[];



export default class ViewMap extends Component {
    constructor(props){
        super(props);
    this.state = {
        previousData : []
    }
    
    }
    
    componentDidMount(){
    	this.renderMap();

    }

    renderMap = () => {

        //   loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}


    initMap = () => 
    {
        this.setState({previousData:this.props.locationsData})

        var lt=17.432143; 
        var ln=78.374427;
        
        var mapOptions = {
            zoom: 11,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels:true,
            mapTypeControlOptions: {
                mapTypeIds: ['satellite', 'roadmap'],
            },
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            styles: [
                    
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 65
						},
						{
							"visibility": "on"
						}
					]
				},
				
				{
					"featureType": "landscape.man_made",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#c1c2c2"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
			],
        };
        try{
            map = new window.google.maps.Map(document.getElementById('map_plantWarehouseLocations'), mapOptions);
        }catch(e){}
        
        
        var bounds = new window.google.maps.LatLngBounds();
        this.maploadData();
        
        
        overlay = new window.google.maps.OverlayView();
        overlay.draw = function() {};
        overlay.setMap(map);
	 
        window.google.maps.event.addListener(map, 'click', (function() {
            closeAllInfoWindows()
        }))
    }

    maploadData = () =>{
        for (let i = 0; i < markersArraylist.length; i++) {
            markersArraylist[i].setMap(null);
        }
        
        var locations = this.props.locationsData;

        var lt=17.432143; 
        var ln=78.374427;
        
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng((lt),(ln)));
    
        try{
            var infowindow = new window.google.maps.InfoWindow();
                
            if(locations.length > 0)
            {

                var image =  require('../../assets/images/business.png');
                    
                locations.map(function(e,index){
                    
                    var image =  require('../../assets/images/business.png');
                   
                    bounds.extend(new window.google.maps.LatLng((e.latitude),(e.longitude)));
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng((e.latitude), (e.longitude)),
                        icon: image,
                        map: map,	
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            
                            var contentarr = []
                        
                        var header = e.location_name
                        contentarr.push({"key":"Location_Address", "value":e.location_address})
                        contentarr.push({"key":"Location_Code", "value":e.location_code})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        infowindows.push(infowindow);
                        
                        infowindow.open(map, marker);
                        
                        }
                    })(marker));
                })  
                
            }
            
            map.fitBounds(bounds)
        }catch(e){}
    
    }

    updateMap = () =>{
        let previousData = this.state.previousData
        if(previousData == this.props.locationsData){
            
        }else{
            
            this.renderMap()
        }
        
    }

  render(){
    
    this.updateMap()
    return (
        
            <div id="map_plantWarehouseLocations" className="col-xl-12 col-lg-12 ml-0p" style={{width:'100%',height:"70vh", borderRadius:"4px"}}></div>

    );
  }
};

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function closeAllInfoWindows() {
  for (var i=0;i<infowindows.length;i++) {
      infowindows[i].close();
  }
}
