import redirectURL from '../redirectURL';

const getToolConfiguration = async (params) =>{
   await redirectURL.post('master/getToolconfigurations',params)
            .then(response =>{
                if(response.data.status == 'success'){
                    let toolConfigData = response.data.toolConfigData
                    localStorage.setItem("tlcfg",JSON.stringify(toolConfigData))
                }
            })
}

export default getToolConfiguration
