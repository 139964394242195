import React,{Component} from 'react';
import Switch from "react-switch";
import redirectURL from '../redirectURL';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
var moment = require("moment");

export default class ForecastingConfiguration extends Component{
    constructor(props){
        super(props);
        this.state = {
            stackingConfigs : "",
            stackingChecked : true,
            itemStackingChecked : true,
            itemStackingConfigs : "",
            data_pattern_type:3,
            learning_frequency:7,
            data_pattern_periodic:1,
            data_analysis_pattern:1,
            material_analysis_type:1,
            material_consumption_analysis:1,
            time_window:null,
            start_date:null,
            end_date:null,
            arima:1,
            sarima:1,
            sarimax:1,
            prophet:1,
            random_forest:1,
            ses:1,
            deep_learning_lstm:1,
            deep_learning_neural_network:1,
            time_series_algorithms:1,
            holt_winter:1,
            holt_winter_type:1,
            config_id : ''

        }
    }

    componentDidMount(){

        this.getConfigurations()
    }

        getConfigurations = () =>{
            redirectURL.post('forecast/getConfigurations')
            .then(async response =>{
                let stackingConfigs = "";
                let stackingChecked = true;
                let itemStackingChecked = true;
                let itemStackingConfigs = "";
                var configData = response.data;

                let data_pattern_type = 3;
                let learning_frequency = 7;
                let data_pattern_periodic = 1;
                let data_analysis_pattern = 1;
                let material_analysis_type = 1;
                let material_consumption_analysis = 1;
                let start_date = null;
                let end_date = null;
                let arima = 1;
                let sarima = 1;
                let sarimax = 1;
                let prophet = 1;
                let random_forest = 1;
                let ses = 1;
                let deep_learning_lstm = 1;
                let deep_learning_neural_network = 1;
                let time_series_algorithms = 1;
                let holt_winter = 1;
                let holt_winter_type = 1;
                let config_id = '';

            if(configData.length > 0)
            {
                var itemStacking = configData
                if(itemStacking.length > 0)
                {
                    stackingConfigs = "";
                    stackingChecked = true;
                    itemStackingChecked = true;
                    itemStackingConfigs = "";
                    

                    data_pattern_type = itemStacking[0].data_pattern_type;
                    learning_frequency = itemStacking[0].learning_frequency;
                    data_pattern_periodic = itemStacking[0].data_pattern_periodic;
                    data_analysis_pattern = itemStacking[0].data_analysis_pattern;
                    material_analysis_type = itemStacking[0].material_analysis_type;
                    material_consumption_analysis = itemStacking[0].material_consumption_analysis;
                    start_date = moment.parseZone(itemStacking[0].start_date).utcOffset("+00:00")._d;
                    end_date = moment.parseZone(itemStacking[0].end_date).utcOffset("+00:00")._d;
                    arima = itemStacking[0].arima;
                    sarima = itemStacking[0].sarima;
                    sarimax = itemStacking[0].sarimax;
                    prophet = itemStacking[0].prophet;
                    random_forest = itemStacking[0].random_forest;
                    ses = itemStacking[0].ses;
                    deep_learning_lstm = itemStacking[0].deep_learning_lstm;
                    deep_learning_neural_network = itemStacking[0].deep_learning_neural_network;
                    time_series_algorithms = itemStacking[0].time_series_algorithms;
                    holt_winter = itemStacking[0].holt_winter;
                    holt_winter_type = itemStacking[0].holt_winter_type;
                    config_id = itemStacking[0]._id

                }

            }
            this.setState({
                stackingConfigs : stackingConfigs,
                stackingChecked : stackingChecked,
                itemStackingChecked : itemStackingChecked,
                itemStackingConfigs : itemStackingConfigs,
                learning_frequency : learning_frequency,
                data_pattern_periodic : data_pattern_periodic,
                data_pattern_type : data_pattern_type,
                data_analysis_pattern : data_analysis_pattern,
                start_date:start_date,
                end_date:end_date,
                arima:arima,
                sarima:sarima,
                sarimax:sarimax,
                prophet:prophet,
                random_forest:random_forest,
                ses:ses,
                deep_learning_lstm:deep_learning_lstm,
                deep_learning_neural_network:deep_learning_neural_network,
                time_series_algorithms:time_series_algorithms,
                holt_winter:holt_winter,
                holt_winter_type:holt_winter_type,
                material_analysis_type:material_analysis_type,
                material_consumption_analysis:material_consumption_analysis,
                config_id:config_id
            })
            })
        }


    handleStackingChange = (checked) => {
        var stackingConfigs = "show-n";
        if(checked == true)
        {
            stackingConfigs = "";
        }
        this.setState({ stackingChecked : checked, stackingConfigs:stackingConfigs});
    }

    handleItemStackingChange = (checked) => {
        var itemStackingConfigs = "show-n";
        if(checked == true)
        {
            itemStackingConfigs = "";
        }
        this.setState({ itemStackingChecked : checked, itemStackingConfigs:itemStackingConfigs});
    }

    changeHandler = (event) => {

        let name = event.target.name;
		let value = event.target.value;
    	
        if(value == 1){
            this.setState({[name]:0});
        }else{
            this.setState({[name]:1});
        }
    }

    updateDataPatternType = (e) =>{
        let name = e.target.name
        
        if(name == "auto_learn" ){
            this.setState({data_pattern_type:1})
        }else if(name == "periodic_learn"){
            this.setState({data_pattern_type:2})
        }else if(name == "manual_learn"){
            this.setState({data_pattern_type:3})
        }
        else if(name == "from_starting"){
            this.setState({material_analysis_type:1})
        }else if(name == "date_range"){
            this.setState({material_analysis_type:2})
        }else if(name == "trend_analysis"){
            this.setState({holt_winter_type:0})
        }else if(name == "trend_and_seasonal"){
            this.setState({holt_winter_type:1})
        }
        
    }

    updateTimePeriod = (e) =>{
        let name = e.target.name
        let value = e.target.value

        this.setState({ [name] : value })
    }

    handleDate = (date) =>{
        
        this.setState({
            start_date : date[0],
            end_date:date[1]
          })
      }

    updateLearningFrequency = (event) =>{
        let name = event.target.name;
		let value = event.target.value;
        
        this.setState({[name]:value});
    }

    saveForecastingConfigs = () => {
       
        let data_analysis_pattern = this.state.data_analysis_pattern;
        let learning_frequency = this.state.learning_frequency;
        let data_pattern_periodic = this.state.data_pattern_periodic;
        let data_pattern_type = this.state.data_pattern_type;
        let material_analysis_type = this.state.material_analysis_type;
        let material_consumption_analysis = this.state.material_consumption_analysis;
        let start_date = this.state.start_date;
        let end_date = this.state.end_date;
        let arima = this.state.arima;
        let sarima = this.state.sarima;
        let sarimax = this.state.sarimax;
        let prophet = this.state.prophet;
        let random_forest = this.state.random_forest;
        let ses = this.state.ses;
        let deep_learning_lstm = this.state.deep_learning_lstm;
        let deep_learning_neural_network = this.state.deep_learning_neural_network;
        let time_series_algorithms = this.state.time_series_algorithms;
        let holt_winter = this.state.holt_winter;
        let holt_winter_type = this.state.holt_winter_type;
        let config_id = this.state.config_id;

        let params = {

            data_analysis_pattern : data_analysis_pattern,
            data_pattern_type : data_pattern_type,
            data_pattern_periodic : parseInt(data_pattern_periodic),
            learning_frequency : parseInt(learning_frequency),
            material_consumption_analysis:material_consumption_analysis,
            material_analysis_type:material_analysis_type,
            start_date:start_date,
            end_date:end_date,
            time_series_algorithms:time_series_algorithms,
            arima:arima,
            sarima:sarima,
            sarimax:sarimax,
            prophet:prophet,
            random_forest:random_forest,
            ses:ses,
            deep_learning_lstm:deep_learning_lstm,
            deep_learning_neural_network:deep_learning_neural_network,
            holt_winter:holt_winter,
            holt_winter_type:holt_winter_type,
            config_id:config_id
        }

        redirectURL.post('forecast/saveforecastingconfigurations',params)
        .then(response =>{
            
            if(response.data.status == 'success'){
                this.getConfigurations()
                this.setState({
                    show:true,
                    basicTitle:"Data Saved successfully",
                    basicType:"success"
                })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Data Not Saved successfully",
                    basicType:"danger"
                })
            }

        })

    }

    render(){
        const{stackingChecked,stackingConfigs,itemStackingChecked,itemStackingConfigs,
              learning_frequency,data_analysis_pattern,data_pattern_periodic,data_pattern_type,material_analysis_type,material_consumption_analysis,start_date,end_date,
            arima,sarima,sarimax,prophet,random_forest,ses,deep_learning_lstm,deep_learning_neural_network,time_series_algorithms,holt_winter,holt_winter_type}=this.state
        return(
            <div className="">
            {/* <div class={"row col-sm-12 mt-30p"}>
                <div className="col-sm-4">
                    <label className="fw-800 mt-20per theme-clr">Enable/Disable Forecasting Module</label>    
                </div>
                <div className="col-sm-2">
                    <Switch
                        checked={stackingChecked}
                        onChange={this.handleStackingChange}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#20749b"
                        onHandleColor="#d3dbdf"
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                </div> */}
                <div class={"row col-sm-12 "+stackingConfigs}>
                    <div className="col-sm-12">
                        {/* <div class={"row mt-20p"}>
                            <div className="col-sm-7 txt-center">
                                <h5>Forecasting Options </h5>
                            </div>
                            <div className="col-sm-5">
                                <label htmlFor="material-switch">
                                    <span style={{"marginRight":"10px"}}>Enable/Disable</span>
                                    <Switch
                                        checked={itemStackingChecked}
                                        onChange={this.handleItemStackingChange}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={15}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={10}
                                        width={28}
                                        className="react-switch"
                                        id="material-switch"
                                    />                                                    
                                </label>
                            </div>
                        </div> */}
                        
                        <div class={itemStackingConfigs}>

                            <div className="row">
                                <label className="fw-600 col-sm-12"><input name="data_analysis_pattern" id="data_analysis_pattern" value={data_analysis_pattern} type="checkbox" checked disabled/>&nbsp;&nbsp;Data Analysis Pattern*</label>
                                <div className="row ml-3per">
                                <label className="col-sm-12 fw-600"><input name="auto_learn" id="auto_learn" checked = {data_pattern_type == 1 ? true : false} value={data_pattern_type} type="radio" onClick={this.updateDataPatternType}  />&nbsp;&nbsp;Auto Learn Data Pattern</label>
                
                                <label className="fw-600 col-sm-12"><input name="periodic_learn" id="periodic_learn" checked = {data_pattern_type == 2 ? true : false} value={data_pattern_type} type="radio" onClick={this.updateDataPatternType}  />&nbsp;&nbsp;Periodic Learn Data Pattern</label>
                                <div className={data_pattern_type == 2 ? "show-m d-flex" : "show-n"}>
                                    <p className='fw-600'>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select Period : &nbsp;&nbsp;</p>
                                    <div className='form-group'>
                                    <select name="data_pattern_periodic" id="data_pattern_periodic" value = {data_pattern_periodic}
                                        className= "forminp" onChange={this.updateTimePeriod}>
                                        
                                        <option value="1">Weekly</option>
                                        <option value="2">Biweekly</option>
                                        <option value="3">Monthly</option>
                                        <option value="4">Quarterly</option>
                                    </select>
                                    </div>

                                </div>

                                <label className="fw-600 col-sm-12"><input name="manual_learn" id="manual_learn" checked = {data_pattern_type == 3 ? true : false} value={data_pattern_type} type="radio" onClick={this.updateDataPatternType} />&nbsp;&nbsp;Manual Learn Data Pattern</label>
                                <div className={data_pattern_type == 3 ? "show-m" : "show-n"}>
                                    <label className="fw-600">&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; Learning Frequency (Days)&nbsp;&nbsp; <input name="learning_frequency" className="d-inline num-cls" id="learning_frequency" value={learning_frequency} onChange={this.updateLearningFrequency} type="number" min={2} style={{width:"40px"}}/></label>
                                </div>
                                </div>
                            </div>

                            <div className="row">
                                <label className="fw-600 col-sm-12"><input name="material_consumption_analysis" id="material_consumption_analysis" value={material_consumption_analysis} type="checkbox" checked disabled/>&nbsp;&nbsp;Material Consumption Analysis Window*</label>
                                <div className="row ml-3per">
                                <label className="col-sm-12 fw-600"><input name="from_starting" id="from_starting" checked = {material_analysis_type == 1 ? true : false} value={material_analysis_type} type="radio" onClick={this.updateDataPatternType}  />&nbsp;&nbsp;Learn From Start</label>

                                &nbsp;<label className="fw-600 ml-2per"><input name="date_range" id="date_range" checked = {material_analysis_type == 2 ? true : false} value={material_analysis_type} type="radio" onClick={this.updateDataPatternType} />&nbsp;&nbsp;Learn From Time Window&nbsp;&nbsp;</label>
                                <div className={material_analysis_type == 2 ? "show-m " : "show-n"}>
                                    
                                     <DatePicker 
                                    todayButton="Today"
                                    onChange={this.handleDate} 
                                    selectsRange={true}
                                    className="forminp form-control"
                                    startDate={start_date}
                                    endDate={end_date}
                                    required
                                    />
                                    
                                </div>
                                </div>
                            </div>
                                
                            <div className="row">
                            <label className="fw-600 col-sm-12"><input name="time_series_algorithms" id="time_series_algorithms" value={time_series_algorithms} type="checkbox" checked disabled/>&nbsp;&nbsp;Time Series Analysis Algorithms</label>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='col-sm-9 d-flex justify-content-between'>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="arima" id="arima" value={arima} checked={(arima == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;ARIMA</label>
                                </div>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="sarima" id="sarima" value={sarima} checked={(sarima == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;SARIMA</label>
                                </div>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="sarimax" id="sarimax" value={sarimax} checked={(sarimax == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;SARIMAX</label>
                                </div>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="holt_winter" id="holt_winter" value={holt_winter} checked={(holt_winter == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;Holt Winter</label>
                                </div>
                                </div>
                                <div className='col-sm-3'>
                                <div className={holt_winter == 1 ? "show-m" : "show-n"}>
                                <div className="row ml-6per">
                                    <label className="fw-600"><input name="trend_analysis" id="trend_analysis" value={holt_winter_type} checked={(holt_winter_type == 0)?true:false} type="radio" onChange={this.updateDataPatternType} />&nbsp;&nbsp;Only Trend Analysis</label>
                                </div>
                                <div className="row ml-6per">
                                    <label className="fw-600"><input name="trend_and_seasonal" id="trend_and_seasonal" value={holt_winter_type} checked={(holt_winter_type == 1)?true:false} type="radio" onChange={this.updateDataPatternType} />&nbsp;&nbsp;Trend + Seasonality Analysis</label>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between mt-10p'>
                            <div className='col-sm-9 d-flex justify-content-between'>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="prophet" id="prophet" value={prophet} checked={(prophet == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;Prophet</label>
                                </div>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="random_forest" id="random_forest" value={random_forest} checked={(random_forest == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;Random Forest</label>
                                </div>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="ses" id="ses" value={ses} checked={(ses == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;SES</label>
                                </div>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="deep_learning_lstm" id="deep_learning_lstm" value={deep_learning_lstm} checked={(deep_learning_lstm == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;Deep Learning Using LSTM</label>
                                </div>
                                </div>
                                <div className='col-sm-3'>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="deep_learning_neural_network" id="deep_learning_neural_network" value={deep_learning_neural_network} checked={(deep_learning_neural_network == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;Deep Learning Using Neural Networks</label>
                                </div>
                                </div>
                            </div>
                            <div className="row mt-30p">
                                <div className="col-sm-12">
                                    <button type="button" className="btn btn-warning float-right" onClick={this.saveForecastingConfigs}>Save Forecasting Configurations</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                                        
        )
    }

}