import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const LineChart = ({ data, title, headerName, categorySign }) => {
    if (data.length===0){
        return (
            <div className="loader-container" style={{height: "430px"}}>
        <div className="loader-spinner"></div>
        <div className="loader-label">Loading...</div>
    </div>
        )
    }
  const [isLoading, setIsLoading] = useState(true);
  const [chartOptions, setChartOptions] = useState(null);


  var yAxisTitle = ""
  var newPointFormat = ""
  var newName = ""
  var newHeaderName = headerName
  switch (newHeaderName){
    case "Count of Material Codes":
      yAxisTitle = "Material Codes Count"
      newPointFormat = '{series.name}: <b>{point.y}</b>'
      newName = "Count of Material Codes"
      break;
    case "Sum of Predicted Cost ( Mn'$ )":
      yAxisTitle = "Cost ( Mn'USD)"
      newPointFormat = '{series.name}: <b>{point.y} Mn&apos;USD</b>'
      newName = "Predicted Cost"
      break;
    case "Sum of Actual Cost ( Mn'$ )":
      yAxisTitle = "Cost ( Mn'USD)"
      newPointFormat = '{series.name}: <b>{point.y} Mn&apos;USD</b>'
      newName = "Actual Cost"
      break;
    case "Sum of Cost Difference ( Mn'$ )":
      yAxisTitle = "Cost ( Mn'USD)"
      newPointFormat = '{series.name}: <b>{point.y} Mn&apos;USD</b>'
      newName = "Cost Difference"
      break;
    case "Validation Summary Plot":
      yAxisTitle = `${categorySign} Materials Percentage ( % )`
      newPointFormat = '{series.name}: <b>{point.y} %</b>'
      newName = "Percentage"
      break;
    default:
      yAxisTitle = "Percentage ( % )"
      newPointFormat = '{series.name}: <b>{point.y} %</b>'
      newName = "Percentage"
  }
  useEffect(() => {
    // Simulate loading and chart generation
    setTimeout(() => {
      setChartOptions({
        chart: {
          type: 'line',
        },
        title: {
          text: title,
        },
        xAxis: {
          categories: data.map(item => item.phase),
          title: {
            text: 'Phase',
          },
        },
        yAxis: {
          title: {
            text: yAxisTitle,
          },
        },
        legend: {
          enabled: false, // Hide the legend
        },
        credits: {
            enabled: false
          },
          tooltip: {
            pointFormat: newPointFormat, // Display series name and percentage value with % symbol
          },
        series: [
          {
            name: newName,
            data: data.map(item => parseFloat(item.percentage)),
            showInLegend: false, // Hide the series from the legend
          },
        ],
      });
      setIsLoading(false);
    });
  }, [data]);
  console.log(headerName)
  return (
    <div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{style: {fontSize: "13px", fontWeight: "bold", color: "black"}}}/>
    </div>
  );
};

export default LineChart;
