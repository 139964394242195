import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CountUp from "react-countup";
import SweetAlert from 'react-bootstrap-sweetalert';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';


import redirectURL from '../redirectURL';
import withRouter from '../withRouter';
import AnomalyColumnChart from './anomalycolumnchart';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
class NewMaterialAnalysis extends Component {
    constructor(props){
        super(props);
        this.state = {
            show:false,
            basicTitle:"",
            basicType:"",
            anomalyYear:{value:1,label:"1+ Year"},
            chartseries:[],
            yearlychartseries:[],
            yearlychartcountseries:[],
            loadshow1:"show-n",
            s1:"btn-default",
            s2:"btn-success",
            s1tab:"show-n",
            s2tab:"show-m",
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            newmaterial_count:0,
            newmaterial_actual_count:0,
            newmaterial_cost:0,
            newmaterial_actual_cost:0,
            newmaterial_period:{value:"2020 Jan-Jun", label:"2020 Jan-Jun"}
        }
    }
    componentDidMount() {
        var params ={
            anomalyYear:this.state.anomalyYear.value
        }
        this.loadAnomaylyChartData(params);
        this.loadYearlyAnomaylyChartData();
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    anomalyYearsList(){
        var items = [{value:"",label:"Select"}];
        items.push({
            value:1,label:"1+ Year"
        },{
            value:2,label:"2+ Years"
        },{
            value:3,label:"3+ Years"
        })
        return items;
    }
    onChangeAnomalyYear(anomalyYear){
        this.setState({anomalyYear})
    }
    onClickGetData(event){
        event.preventDefault();
        if(this.state.anomalyYear.value !== "")
        {
            var params ={
                anomalyYear:this.state.anomalyYear.value
            }
            this.loadAnomaylyChartData(params);
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select anomaly year item",
                basicType:"info"
            })
        }
    }

    loadAnomaylyChartData(params){
        this.setState({
            loadshow1:"show-m"
        })
        redirectURL.post("/forecast/getNewMaterialsData",params)
        .then((response) => {
            // console.log("resonse", response.data)
            try {
                var records = JSON.parse(response.data.new_materials_data);
                
                this.setState({
                    rowData:records,
                    // loadshow1:"show-n"
                })
                
            } catch (error) {
                    
            }
        })
    }
    
    loadYearlyAnomaylyChartData(){
        this.setState({
            loadshow1:"show-m"
        })
        
        redirectURL.post("/forecast/getYearWiseNewMaterialsData")
        .then((response) => {
            // console.log("resonse", response.data)
            try {
                
                var chartactualdata = JSON.parse(response.data.new_material_info);
                var chartpredicteddata = JSON.parse(response.data.new_material_pred_info);
                // var chartpredicteddata = [];
                // console.log("chartactualdata ", chartactualdata)
                var actualSeries = [];
                var actualCountSeries = [];
                var predSeries = [];
                var predCountSeries = [];
                if(chartactualdata.length > 0)
                {
                    chartactualdata.map((itm) => {
                        actualSeries.push({
                            name:itm.year,
                            y:itm.new_cost
                        })
                        actualCountSeries.push({
                            name:itm.year,
                            y:itm.new_material_count
                        })
                    })
                }
               
                if(chartpredicteddata.length > 0)
                {
                    chartpredicteddata.map((itm) => {
                        predSeries.push({
                            name:itm.year,
                            y:itm.pred_cost
                        })
                        predCountSeries.push({
                            name:itm.year,
                            y:itm.pred_material_count
                        })
                    })
                }
                var series=[
                    {
                        name:"Actual New Material Cost",
                        data:actualSeries
                    },
                    {
                        name:"Predicted New Material Cost",
                        data:predSeries,
                        color:"#ff0000"
                    },
                    // {
                    //     name:"Prediction Material Count",
                    //     data:predCountSeries
                    // }
                ]
                var series2=[
                    {
                        name:"Actual New Material Count",
                        data:actualCountSeries
                    },
                    {
                        name:"Predicted New Material Count",
                        data:predCountSeries,
                        color:"#ff0000"
                    },
                    // {
                    //     name:"Prediction Material Count",
                    //     data:predCountSeries
                    // }
                ]
                this.setState({
                    yearlychartseries:series,
                    yearlychartcountseries:series2,
                    loadshow1:"show-n"
                })
                // console.log("series ", series)
                setTimeout(() => {
                    try {
                        var params = {
                            predicted_count:series2[1].data[series2[1].data.length-1].y,
                            predicted_cost:series[1].data[series[1].data.length-1].y,
                            actual_count:series2[0].data[series2[0].data.length-1].y,
                            actual_cost:series[0].data[series[0].data.length-1].y

                        }
                        this.getPageCounterFromChildComponent(params);
                    
                    } catch (error) {
                        
                    }
                },500)
                
            } catch (error) {
                    
            }
        })
    }
    onChangePeriodData(anomalyYear){
        this.setState({anomalyYear},()=>{
            if(this.state.anomalyYear.value !== "")
            {
                var params ={
                    anomalyYear:this.state.anomalyYear.value
                }
                this.loadAnomaylyChartData(params);
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Please select outlier analysis period option",
                    basicType:"info"
                })
            }
        })
    }
    showTab(oVal){
        
        this.setState({
            loadshow:"show-m"
        })
        if(oVal === 1)
        {
            this.setState({
                //rowData:this.state.anamolyRowData,
                loadshow:"show-n",
                s1:"btn-success",
                s2:"btn-default",
                s3:"btn-default",
                s1tab:"show-m",
                s2tab:"show-n"
            })
        }
        if(oVal === 2)
        {
            this.setState({
                // rowData:this.state.rowDataPred,
                loadshow:"show-n",
                s1:"btn-default",
                s2:"btn-success",
                s3:"btn-default",
                s1tab:"show-n",
                s2tab:"show-m"
            })
           
        }
    }
    
    getPageCounterFromChildComponent(counterData){
        // console.log("counterData ", counterData)
        this.setState({
            newmaterial_cost:counterData.predicted_cost,
            newmaterial_count:counterData.predicted_count,
            newmaterial_actual_cost:counterData.actual_cost,
            newmaterial_actual_count:counterData.actual_count,
        })
    }
    
    onChangeNewMaterialPeriod(newmaterial_period){
        this.setState({
            newmaterial_period
        })
    }
    
    render() {
        var columnwithDefs =[
            {
                headerName:"Material Code",
                field:"item_code"
            },
            {
                headerName:"Material Code",
                field:"item_description"
            },
            {
                headerName:"Material Cost ($)",
                field:"cost_in_USD_per_item"
            },
            {
                headerName:"Material Inducted Date",
                field:"posting_date",
                valueGetter:function(params){
                    try {
                        if(params.data.posting_date !== "" && params.data.posting_date !== undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.posting_date);
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                }
            }
        ]
        return(
            <div class="container-fluid">
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
              <div className="row mb-20p">
                    <div className="col-sm-9">
                        <h5 className='fbold'>
                                New Material Analysis
                        </h5>
                       
                    </div> 
                    <div className="col-sm-3">
                        {/* <div className="form-group row">
                            <label className="col-sm-6" style={{marginTop:"10px", textAlign:"right"}}>Select Period:</label>
                            <div className="col-sm-6">
                                <Select
                                    value={this.state.newmaterial_period}
                                    onChange={this.onChangeNewMaterialPeriod.bind(this)}
                                    options={[
                                        {value:"", label:"Select"},
                                        {value:"2020 Jan-Jun", label:"2020 Jan-Jun"}
                                    ]}
                                    className={"f12"}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>

                <div class="row mb-20p">
                    <div className="col-sm-2 cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <span className="f13 countertxt">New Material Count - Predicted</span>
                                    <h4 className="txt-danger-dark f30"><span className="counter">
									<CountUp end={this.state.newmaterial_count} delay={0} />
									</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-sm-2 cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <span className="f13 countertxt">New Material Count - Actual</span>
                                    <h4 className="txt-info-dark f30"><span className="counter">
									<CountUp end={this.state.newmaterial_actual_count} delay={0} />
									</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2 cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <span className="f13 countertxt">New Material Cost - Predicted</span>
                                    <h4 className="txt-danger-dark f30"><span className="counter">
									$ {this.state.newmaterial_cost}M
									</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-sm-2 cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <span className="f13 countertxt">New Material Cost - Actual</span>
                                    <h4 className="txt-info-dark f30"><span className="counter">
									$ <CountUp end={this.state.newmaterial_actual_cost} delay={0} />
									</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row mb-20p">
                    <div className="col-sm-12">
                        
                        {/* <button type="button" className={"btn "+(this.state.s1)} onClick={this.showTab.bind(this,1)}>New Material Summary</button> */}
                        {/* <button type="button" className={"btn "+(this.state.s2)} onClick={this.showTab.bind(this,2)}>New Material Analysis</button> */}
                    </div>
                </div>
                
                <div className={"row mb-20p "+(this.state.s1tab)}>
                    <div className={"mxheit col-sm-12 "} >
                        <div className={"chartLoading "+(this.state.loadshow1)}>
                            <div className="loaderpos">
                                <img src={require("../../assets/images/loading.gif")} />
                            </div>
                        </div>
                        <div id="myGrid1" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady1}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                rowClassRules={this.state.rowClassRules}
									
                                />
                        </div>
                    </div>
                    
                    
                </div>
                
                <div className={"row mb-20p "+(this.state.s2tab)}>
                    <div className="col-sm-12 mt-30p">
                            
                        <div className={"chartLoading "+(this.state.loadshow1)}>
                            <div className="loaderpos">
                                <img src={require("../../assets/images/loading.gif")} />
                            </div>
                        </div>
                        <AnomalyColumnChart
                            series={this.state.yearlychartcountseries}
                            title={"New Material Count Analysis: Trend and Prediction"}
                            yAxisTitle={"Count"}
                        />
                    </div>
                </div>
                <div className={"row mb-20p "+(this.state.s2tab)}>
                    <div className="col-sm-12 mt-30p">
                            
                        <div className={"chartLoading "+(this.state.loadshow1)}>
                            <div className="loaderpos">
                                <img src={require("../../assets/images/loading.gif")} />
                            </div>
                        </div>
                        <AnomalyColumnChart
                            series={this.state.yearlychartseries}
                            title={"New Material Cost Analysis: Trend and Prediction"}
                            yAxisTitle={"Cost ($)"}
                        />
                    </div>
                </div>
                
            </div>
        );
    }
}
export default withRouter(NewMaterialAnalysis);