import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import  draggable from '../../../node_modules/jquery-ui/ui/widgets/draggable';
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import ViewTruckData from "./viewtruckaction";
import ViewClusterRoute from "./viewclusterroute";
import ViewRoute from "./viewroute";
import ViewStackingData from "./viewstackingaction";
import ViewStack from "./viewstack3dprevious";
// import BinPacking from "./packingComponent";

import redirectURL from '../redirectURL';

var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class PreviousPlans extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            frameworkComponents:{
                ViewTruckData:ViewTruckData,
                ViewRoute:ViewRoute,
                ViewClusterRoute:ViewClusterRoute,
                ViewStackingData:ViewStackingData,
                ViewStack:ViewStack
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            transactionid:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showanimate:"show-n",
            preiousplans:[],
            overlayBlock:"show-n"
        }
        this.hideSlideBlock = this.hideSlideBlock.bind(this);
        this.loadPlanData = this.loadPlanData.bind(this);
        this.onClickShowStackingData = this.onClickShowStackingData.bind(this);
        this.changeTransaction = this.changeTransaction.bind(this);
      }
    componentDidMount() {     
        this.setState({
            overlayBlock:"show-m"
        })
        redirectURL.post("/plan/previousplans")
        .then((response) => {
            var preiousplans = [];
            var records = response.data.records;
            if(records.length > 0)
            {
                records.map((itm) => {
                    preiousplans.push({
                        value:itm.transaction_id,
                        label:itm.transaction_id
                    })
                })
            }
            this.setState({
                preiousplans:preiousplans,
                overlayBlock:"show-n"
            })
        })
    }
   
    loadPlanData(){
        var transactionid = this.state.transactionid.value;
        if(transactionid != "")
        {
            var parameters = {
                transaction_id:transactionid
            }
            redirectURL.post("/plan/transactiondata", parameters)
            .then(async (response) => {
                var records = response.data.records;
                
                records = records.sort(GetSortAscOrder("vehicle_no"));
                var loaddata = [];
                var allLoadPlan = [];
                try{

                    var rowdata = [];
                    var plandaygrp = groupBy(records, rdata => rdata.vehicle_no);
                    // console.log("plandaygrp ", plandaygrp)
                    plandaygrp.forEach((plangrp,pkey) => {
                        // console.log("pkey ", pkey)
                        var plangrp = plangrp.sort(GetSortAscOrder("vehicle_type"));
                        var clustergrp = groupBy(plangrp, rdata => rdata.vehicle_type);
                        // console.log("clustergrp ",clustergrp)
                        clustergrp.forEach((cluster,clusterkey) => {
                            rowdata.push(cluster[0])
                                
                        })
                        
                    });

                    // if(loaddata.length > 0)
                    // {
                    //     loaddata.map((item) => {
                            
                    //         var data = item.items;
                            
                    //         data = data.sort(GetSortAscOrder("trip_no"));
                    //         data = data.sort(GetSortAscOrder("delivery_date"));
                    //         if(data.length > 0)
                    //         {
                    //             data.map((item) => {
                    //                 rowdata.push(item);
                    //             })
                    //         }
                    //     })
                    // }
                    // console.log("rowdata ", rowdata)
                    this.setState({
                        loadplan:records,
                        allLoadPlan:records,
                        rowData:rowdata,
                        showloader:"show-n",
                        overlayBlock:"show-n"
                    })
                }
                catch(e){

                }
            })
        }
      }
      
      logout()
         {
            
               localStorage.removeItem('tn');
               localStorage.removeItem('s');
               localStorage.removeItem('sidebar');
               localStorage.clear();
               sessionStorage.removeItem('tn');
               sessionStorage.removeItem('s');
               sessionStorage.clear();
       
               sessionStorage.setItem('ref', null);
               //localStorage.setItem('menuText',[]);
               delete axios.defaults.headers.common['Authorization'];
               this.setState({
                   redirectToReferrer: ''
               });
         }
      
     
    hideSlideBlock(){
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
        })
      }
      
    onClickShowTruckLegs = async (rownode) =>{
        // console.log("rownode ", rownode);
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: [
                    {
                        headerName:"Unique No",
                        field:"unique_no",
                        width:"100"
                    },
                    {
                        headerName:"Vehicle No",
                        field:"vehicle_no",
                        width:"100"
                    },
                    
                    {
                        headerName:"Vehicle Type",
                        field:"vehicle_type",
                        width:"100"
                    },
                    {
                        headerName:"Order No",
                        field:"order_no",
                        width:"100"
                    },
                    {
                        headerName:"Part No",
                        field:"part_no",
                        width:"100",
                    },
                    {
                        headerName:"Description",
                        field:"description",
                        width:"100",
                    },
                    {
                        headerName:"Vehicle Length",
                        field:"vehicle_length",
                        width:"100"
                    },
                    
                    {
                        headerName:"Vehicle Width",
                        field:"vehicle_width",
                        width:"120"
                    },
                    {
                        headerName:"Vehicle Height",
                        field:"vehicle_height",
                        width:"120"
                    },
                    
                    {
                        headerName:"Vehicle Weight",
                        field:"vehicle_weight",
                        width:"120"
                    },
                    
                    {
                        headerName:"Vehicle Volume",
                        field:"vehicle_volume",
                        width:"100"
                    },
                    {
                        headerName:"Part Qty",
                        field:"part_qty",
                        width:"100"
                    },
                    {
                        headerName:"Part Length",
                        field:"part_length",
                        width:"100"
                    },
                    
                    {
                        headerName:"Part Width",
                        field:"part_width",
                        width:"100"
                    },
                    
                    {
                        headerName:"Part Height",
                        field:"part_height",
                        width:"100"
                    },
                    
                    {
                        headerName:"Part Weight",
                        field:"part_weight",
                        width:"100"
                    },
                    {
                        headerName:"Fragility Index",
                        field:"fragility_index",
                        width:"100"
                    }
                ],
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    // console.log("Step 4 ",param)
                    // console.log("reqparams",reqparams);
                    await redirectURL.post("/dealers/filterstackplan",{
                        vehicle_no : param.data.vehicle_no,
                        vehicle_type : param.data.vehicle_type
                    }).then(async (response) =>{
                        // console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var records=response.data;
                        records = records.sort(GetSortAscOrder("sequence_no"));

                        // console.log("Step 2 ",records)
                        param.successCallback(records);
                        
                        
                        
                    }).catch(function(error){
                        console.log(error);
                    })
                     
                },
                masterDetail: true
            }
        });
        // console.log(rownode.column.colDef.field);
        if(rownode.column.colDef.field == 'truck_no')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
        
    }
    
    onClickShowStackingData(rownode){
        // console.log(rownode);
        var params = {rownode:rownode.data}
        redirectURL.post("/dealers/showPrevious3DStackingData",params)
        .then((response) => {
            console.log("response stacking ", response.data)
            var tblcontent = [];
            var bottomcontent = [];
            var totvols = 0;
            var totweights = 0;
            var stackOriginalData = "";
            if(response.data.status == "Success")
            {
                if(response.data.records[0].content != "" && response.data.records[0].content != undefined)
                {
                    var cnt = response.data.records[0].content;
                    try{
                        var splittext = cnt.split("#@");
                        console.log("splittext", splittext)
                    
                        var splitsc = splittext[0].split("#");
                        // console.log("splitsc", splitsc)
                        
                        if(splitsc.length > 0)
                        {
                            splitsc.map((itm) => {
                                var delim = itm.split(",");
                                if(delim.length > 0)
                                {
                                    try{

                                        if(delim[4] != "" && delim[4] != undefined)
                                        {
                                            var dvol = parseFloat(delim[4])/(1000*1000*1000);
                                            var vol = dvol.toFixed(3);
                                        }
                                        else{
                                            var dvol = "";
                                            var vol = 0;
                                        }
                                    }
                                    catch(e){
                                        
                                    }
                                    var sp = delim[0].split("_");
                                    tblcontent.push({
                                        sku:delim[0],
                                        sku_desc:sp[1],
                                        stack_length:delim[1],
                                        stack_width:delim[2],
                                        stack_height:delim[3],
                                        stack_volume:vol,
                                        stack_weight:delim[5],
                                        // stack_color:delim[6]
                                        fragility_index:parseInt(delim[6]),
                                        stack_color:delim[7],
                                    })
                                   
                                    if(delim[5] != "" && delim[5] != undefined)
                                    {
                                        var weig = delim[5];
                                    }
                                    else{
                                        var weig = 0;
                                    }
                                    // console.log("Weig", weig);
                                    totvols=parseFloat(totvols)+parseFloat(vol);
                                    totweights=parseFloat(totweights)+parseFloat(weig);
                                }
                            })
                        }
                        if(splittext.length > 0)
                        {
                            var btm = splittext[1].split("#");
                            var btnc = btm[0].split(",");
                            if(btnc.length > 0){
                                try{
                                    if(btnc[4] != "" && btnc[4] != undefined)
                                    {
                                        var dvol = parseFloat(btnc[4])/(1000*1000*1000);
                                        var vol = dvol.toFixed(3);
                                    }
                                    else{
                                        var dvol = "";
                                        var vol = 0;
                                    }
                                }
                                catch(e){
                                    
                                }
                                
                                bottomcontent.push({
                                    truck_type:btnc[0],
                                    truck_length:btnc[1],
                                    truck_width:btnc[2],
                                    truck_height:btnc[3],
                                    truck_volume:vol,
                                    truck_weight:btnc[5],
                                    truck_pallets:btnc[6]
                                })
                            }
                        }
                    }
                    catch(e){

                    }
                }
                else{
                    var cnt = "";
                }
                // console.log("totweights ", totweights)
                try{
                    tblcontent.push({
                        sku:"Total",
                        stack_length:"",
                        stack_width:"",
                        stack_height:"",
                        stack_volume:totvols.toFixed(3),
                        stack_weight:totweights.toFixed(3),
                        stack_color:""
                    })
                }
                catch(e){}
                
                this.setState({
                    stackImage : response.data.records[0].image,
                    stackOriginalData:response.data.records[0].content,
                    stacktbl:tblcontent,
                    stacktblbottom:bottomcontent,
                    showSlideBlockStack:"slide90",
                    overlayBlock:"show-m"
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Data not found.",
                    basicType:"danger"
                })
            }
            
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    changeTransaction(transactionid){
        this.setState({transactionid},()=>{
            this.setState({
                overlayBlock:"show-m"
            })
            this.loadPlanData();
        })
    }
    render() {
        if(localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined )
        {
          return <Navigate to={"/login"} push={true} />
        }
        var columnwithDefs = [
            
            {
                headerName:"",
                field:"truck_no",
                width:"80",
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewTruckData"
                    };
                    return rendComponent
                
                },
            },
            
            // {
            //     headerName:"",
            //     field:"cluster",
            //     width:"80",
            //     cellRendererSelector:function(params){
                    
            //         var rendComponent = {
            //             component: "ViewRoute"
            //         };
            //         return rendComponent
                
            //     },
            // },
             
            // {
            //     headerName:"",
            //     field:"cluster",
            //     width:"80",
            //     cellRendererSelector:function(params){
                    
            //         var rendComponent = {
            //             component: "ViewClusterRoute"
            //         };
            //         return rendComponent
                
            //     },
            // },
           
            {
                headerName:"",
                field:"vehicle_no",
                width:"80",
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewStackingData"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"vehicle_no",
                width:"110",
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewStack"
                    };
                    return rendComponent
                
                },
            },
            
            
          
            {
                headerName:"Vehicle No",
                field:"vehicle_no",
                width:"100"
            },
            
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"100"
            },
            {
                headerName:"Vehicle Length",
                field:"vehicle_length",
                width:"100"
            },
            
            {
                headerName:"Vehicle Width",
                field:"vehicle_width",
                width:"120"
            },
            {
                headerName:"Vehicle Height",
                field:"vehicle_height",
                width:"120"
            },
            
            {
                headerName:"Vehicle Weight",
                field:"vehicle_weight",
                width:"120"
            },
            
            {
                headerName:"Vehicle Volume",
                field:"vehicle_volume",
                width:"100"
            },
           
            
        ]
        var loadplan = this.state.loadplan;
        return (
            <div class="container-fluid">
                
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            <i className="fa fa-users"></i> Previous Load Plan{(this.state.rowData.length > 0)?" - "+this.state.rowData[0].transaction_id:""}
                            <div className="col-sm-2 float-right">
                                <div className="form-group f14" style={{color:"#000"}}>
                                    <label>Select Transaction ID</label>
                                    <Select 
                                        placeholder={"Select Transaction ID"}
                                        onChange={this.changeTransaction} 
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.transactionid}
                                        options={this.state.preiousplans} 
                                    />
                                </div>
                            </div>
                        </h5>
                    </div>
                </div>
                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockRoute)}>
                    <h5 className="crd-bg p-10p">{this.state.mpfor != "cluster"?"View Route":"Cluster - "+this.state.scluster+" suppliers in the plan"}</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <div className={this.state.nmap} id="map" style={{width:"100%",height:"90vh"}}></div>
                            <div className={this.state.nmapcluster} id="mapCluster" style={{width:"100%",height:"90vh"}}></div>
                            {(this.state.mpnode.length > 0 && this.state.mpfor != "cluster")?
                                <div className="mpinf" style={{height:"100px"}}>
                                    <div className="mpinfbg"></div>
                                    <div className="row">
                                        <div className="col-sm-3 fbold">
                                            Cluster : {this.state.mpnode[0].cluster}
                                        </div>
                                        <div className="col-sm-3 fbold">
                                        Delivery Date:  {moment.parseZone(this.state.mpnode[0].delivery_dateformat).format("DD-MM-YYYY")}
                                        </div>
                                        {this.state.mpfor != "cluster"?
                                            <div className={"col-sm-3 fbold "}>
                                                Truck No : {this.state.mpnode[0].truck_no}
                                            </div>
                                        :""}
                                        
                                        <div className={"col-sm-3 fbold "}>
                                            Distance (kms) : {this.state.mpnode[0].cummilative_distance}
                                        </div>
                                        {this.state.mpfor != "cluster"?
                                            <div className={"col-sm-3 fbold mt-20p "}>
                                                Supplier:{this.state.mpnode[0].dealer_name}
                                            </div>
                                        :""}
                                        {this.state.mpfor != "cluster"?
                                            <div className={"col-sm-3 fbold mt-20p "}>
                                                Vehicle Name: {this.state.mpnode[0].vehicle_name}
                                            </div>
                                        :""}
                                    </div>
                                </div>
                            :""}
                            
                        </div>
                    </div>
                    </div>
                    


                    <div className={"sliderBlock2 "+(this.state.showSlideBlockStack)}>
                    <h5 className="crd-bg p-10p">Stacking Data</h5>
                    <div className="row">
                        
                        <div className="col-sm-12" style={{textAlign:"center"}}>
                                {this.state.stackImage != ''?
                                    <img src={"data:image/png;base64,"+this.state.stackImage} />
                                :""}
                            {/* <BinPacking 
                                stackOriginalData={this.state.stackOriginalData}
                            /> */}
                        </div>
                        <div className="col-sm-12 mt-20p p-35p">
                            <div className="row mb-40p">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-10 ctbl">
                                    <table className="table" cellpadding="0" cellSpacing="0">
                                        <tr>
                                            <th>SKU</th>
                                            <th>Length (cm)</th>
                                            <th>Width (cm)</th>
                                            <th>Height (cm)</th>
                                            <th>Volume (CMT)</th>
                                            <th>Weight (Kg)</th>
                                            <th>Fragility Index</th>
                                            <th>Color</th>
                                        </tr>
                                {this.state.stacktbl.length > 0?
                                    (this.state.stacktbl.map(itm=>
                                        (itm.sku != "Total")?
                                        <tr>
                                            <td>{itm.sku}</td>
                                            <td>{itm.stack_length}</td>
                                            <td>{itm.stack_width}</td>
                                            <td>{itm.stack_height}</td>
                                            <td>{itm.stack_volume}</td>
                                            <td>{itm.stack_weight}</td>
                                            <td>{itm.fragility_index}</td>
                                            <td>{itm.stack_color}</td>
                                        </tr>
                                        :
                                        <tr>
                                            <th>{itm.sku}</th>
                                            <td>{itm.stack_length}</td>
                                            <td>{itm.stack_width}</td>
                                            <td>{itm.stack_height}</td>
                                            <th>{itm.stack_volume}</th>
                                            <th>{itm.stack_weight}</th>
                                            <td>{itm.stack_color}</td>
                                        </tr>
                                    ))
                                :""}

                                {this.state.stacktblbottom.length > 0?
                                    <tr>
                                        <th>Truck Type</th>
                                        <th>Length (cm)</th>
                                        <th>Width (cm)</th>
                                        <th>Height (cm)</th>
                                        <th>Volume (CMT)</th>
                                        <th>Weight (Kg)</th>
                                        <th></th>
                                        <th>Total Pallets</th>
                                    </tr>
                                :""}
                                {this.state.stacktblbottom.length > 0?

                                    (this.state.stacktblbottom.map(itm=>
                                        <tr>
                                            <td>{itm.truck_type}</td>
                                            <td>{itm.truck_length}</td>
                                            <td>{itm.truck_width}</td>
                                            <td>{itm.truck_height}</td>
                                            <td>{itm.truck_volume}</td>
                                            <td>{itm.truck_weight}</td>
                                            <td></td>
                                            <td>{itm.truck_pallets}</td>
                                        </tr>
                                    ))
                                :""}
                                    </table>
                                </div>
                                
                                <div className="col-sm-1"></div>
                            </div>
                                
                                
                            
                        </div>
                    </div>
                    </div>
                    
            </div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function closeAllInfoWindows() {
    for (var i=0;i<infowindows.length;i++) {
        infowindows[i].close();
    }
}  

