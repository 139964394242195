import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import Select from 'react-select';
import * as $ from 'jquery';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import Constant from "../common/constants";
import ColumnChart from "./columnChartComponent";
import ColumnGroupoChart  from "./columnGroupChartComponent";
import StackedChartComponent from "./stackedColumnChart";
// import LineChartComponent from "./lineChartComponent";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import redirectURL from '../redirectURL';
window['jQuery'] = window['$'] = $;
var moment = require("moment");

export default class SummaryDashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            clusters:[],
            // cluster:{"value":"","label":"Select Cluster"},
            datelist:[],
            alldelivedates:[],            
            alldeliveydates:[],
            start_time:"",
            end_time:"",
            cluster:{"value":"","label":"All"},
            overallclustervehicles:[],
            datewisetrips:[],
            suppliertrips:[],
            suppliervols:[],
            supplierweights:[],
            vehicledistance:[],
            supplierdistance:[],
            datewisedistance:[],
            modewise:[],
            modewisegrp:[],
            datewisemodetypes:[],
            showloader:"show-n",
            deliverydate:{"value":"","label":"All"},
            datewisedirectmilkrunseries:[],
            datewisetruckseries:[],
            datewisetrucktripseries:[]
          
        }
        this.changeCluster = this.changeCluster.bind(this);
        this.changeDelivery = this.changeDelivery.bind(this);
        this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
        this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
    }
    componentDidMount = async() => {
        var sdate = moment.parseZone().subtract(1,"days").format("DD-MM-YYYY");
        var edate = moment.parseZone().format("DD-MM-YYYY");
        this.setState({
            // start_time:sdate,
            // end_time:edate
            showloader:"show-m"
        });
        await redirectURL.post("/dealers/clusters")
        .then((response) => {
            var records = response.data.records;
            
            var recordsarr = [];
            if(records.length > 0)
            {
                records.map((item) => {
                    recordsarr.push({cluster:item})
                })
            }
            // console.log(recordsarr,",recordsarr")
            this.setState({
                clusters:recordsarr,
                cluster:{value:recordsarr[0].cluster,label:recordsarr[0].cluster}
            })
            
        });
        var parameters = {
            // start_time:sdate,
            // end_time:edate,
        }
        console.log("parameters ", parameters)
        await this.loadPlanData(parameters);
    }
    loadPlanData(parameters){
        parameters.cluster = this.state.cluster.value;
        redirectURL.post("/dealers/loadplanparams",parameters)
        .then((response) => {
            var records = response.data;
            // console.log("records ", records)
            records = records.sort(GetSortAscOrder("delivery_date"));
            var clusteraa = [];
            var recordsarr = groupBy(records, rdata => rdata.cluster);
            var devdates = groupBy(records, rdata => rdata.delivery_date);
            var daterecs = groupBy(records, rdata => rdata.delivery_dateformat);
            var suppliers = groupBy(records, rdata => rdata.dealer_name);
            var vehicelnames = groupBy(records, rdata => rdata.vehicle_name);
            var modewise = groupBy(records, rdata => rdata.mode);
            // console.log("recordsarr ", modewise)
            var loaddata = [];
            var datelist=[];
            let clusters = [];
            var vehicleser = [];            
            var clusterseries = [];
            var clusterseriestrips = [];
            var datewise=[];
            var datewisedis = [];
            var supplierwise = [];
            var supplierwisevols = [];
            var supplierwiseweights  = [];
            var supdistance=[];
            var vehdistance=[];
            var directmilkrun=[];
            var cluscats = [];
            var modetrucks = [];
            var modetrips = [];
            var dateseriestrucks=[];
            var dateseriestruckstrips=[];
            var alldeldates = [];
            try{

            devdates.forEach((m1,k1) => {
                // datewsiects.push(moment.parseZone(m1[0].delivery_dateformat).format("DD-MM-YYYY"));
                var kfrm1 = moment.parseZone(k1).format("DD-MM-YYYY");
                // alldelivedates.push(kfrm1);
                alldeldates.push(k1);
            })
            vehicelnames.forEach((item,key) => {
                var vehdis = 0;
                    
                item = item.sort(GetSortAscOrder("sequence_no"));
                var rw = {
                    delivery_date:key,
                    items: item
                }
                if(item.length > 0)
                {
                    item.map((ii) => {
                        clusteraa.push({
                            cluster:ii.cluster
                        })
                        vehdis =parseFloat(vehdis)+parseFloat(ii.distance);
                    })
                }

                vehdistance.push({
                    name:key,
                    y:vehdis
                })
                // console.log("dealer ", key)
                var cnt = 0;
                var trips=[];
                var truckss=[];
                // var suppliermodewisedd = groupBy(item, rdata => rdata.delivery_date);
                // suppliermodewisedd.forEach((a,b) => {
                    // console.log("date ", key+""+b)
                    
                    item = item.sort(GetSortAscOrder("truck_no"));
                    var suppliertrucks = groupBy(item, rdata => rdata.truck_no);
                    var suppliertrips = groupBy(item, rdata => rdata.trip_no);
                    // console.log("suppliertrucks ", suppliertrucks)
                    suppliertrucks.forEach((c,d) => {
                        truckss.push(d);
                        // console.log("TRUCK ", key+"  "+d)
                        var suppliermodewiseseq = groupBy(c, rdata => rdata.sequence_no);
                        suppliermodewiseseq.forEach((e,g) => {
                            // console.log("g ", key+" "+b+" "+d+" "+g)
                            cnt= parseInt(cnt)+1;
                        })
                        
                    })
                    suppliertrips.forEach((troip,tk) => {
                        trips.push(tk);
                    })
                    
                // })
                cluscats.push(key);
                var serie = {
                    name: key,
                    y: truckss.length
                }
                var serietrips = {
                    name: key,
                    y: trips.length
                }
                // clusterseries.push({
                //     name: "Total Trucks",
                //     y: cnt
                // })
                
                // clusterseries.push({
                //     name: "Total Trips",
                //     y: trips
                // })
                loaddata.push(rw);
                datelist.push(key);
                // var vehs = groupBy(item,rdata => rdata.vehicle_name);
                // vehs.forEach((im,ky) => {
                //     // console.log("ky ", ky)
                //     var vehdis = 0;
                //     if(im.length > 0)
                //     {
                //         im.map((em) => {
                //             vehdis=parseFloat(vehdis)+parseFloat(em.distance);
                //         })
                //     }
                //     vehdistance.push({
                //         name:ky,
                //         y:vehdis
                //     })
                //     var serie = {
                //         name: ky,
                //         y: im.length
                //     }
                    clusterseries.push(serie);
                    clusterseriestrips.push(serietrips);
                // })

                var delivery_dateitems = item.sort(GetSortAscOrder("delivery_date"));
                var deliverydatetrucks = groupBy(delivery_dateitems, rdata => rdata.delivery_date); 
                var datewisetrucksitms=[]
                var datewisetripitms = []
                if(alldeldates.length > 0)
                {
                    alldeldates.map((item) => {
                        datewisetrucksitms.push(0);
                        datewisetripitms.push(0);
                    })
                }
                // console.log("datewisetrucksitms ", datewisetrucksitms)
                deliverydatetrucks.forEach((datetrucks,dtruckkey) => {
                    var truckcnts = 0;
                    var trktripscnts = 0;
                    // console.log('dtruckkey ', dtruckkey)
                    datetrucks = datetrucks.sort(GetSortAscOrder("truck_no"));
                    var dwisetrucks = groupBy(datetrucks, rdata => rdata.truck_no); 
                    // console.log("dwisetrucks ", dwisetrucks)
                    var indPos = alldeldates.indexOf(dtruckkey);
                    dwisetrucks.forEach((dwisetruck,dwisetruckey) => {
                        // console.log("dwisetruck.length ", dwisetruck.length)
                        var cntrk=0;
                        if(dwisetruck.length > 0)
                        {
                            // console.log("alldeldates ", alldeldates)
                            if(alldeldates.indexOf(dwisetruck[0].delivery_date) >= 0)
                            {
                                if(dtruckkey == dwisetruck[0].delivery_date)
                                {
                                    
                                // console.log(dtruckkey," Yes")
                                    cntrk=1
                                }
                                else{
                                    
                                // console.log(dtruckkey," No")
                                    cntrk = 0;
                                }
                            }
                            else{
                                
                                // console.log(dtruckkey," No")
                                cntrk = 0;
                            }
                        }
                        else{
                            // console.log(dtruckkey," No")
                            cntrk=0
                        }
                        truckcnts=parseInt(truckcnts)+cntrk;
                        // console.log("truckcnts ", truckcnts)
                    })
                    // var dddd = dtruckkey+"   "+truckcnts;
                    // console.log("ddddd ", dddd)
                    datewisetrucksitms[indPos] = truckcnts;


                    var datetruckstrips = datetrucks.sort(GetSortAscOrder("trip_no"));
                    var datetruckstripsdata = groupBy(datetruckstrips, rdata => rdata.trip_no); 
                    // console.log("dwisetrucks ", dwisetrucks)
                    var indPos = alldeldates.indexOf(dtruckkey);
                    datetruckstripsdata.forEach((dwisetrip,dwisetripkey) => {
                        var cntrktrp=0;
                        if(dwisetrip.length > 0)
                        {
                            // console.log("alldeldates ", alldeldates)
                            if(alldeldates.indexOf(dwisetrip[0].delivery_date) >= 0)
                            {
                                if(dtruckkey == dwisetrip[0].delivery_date)
                                {
                                    
                                // console.log(dtruckkey," Yes")
                                cntrktrp=1
                                }
                                else{
                                    
                                // console.log(dtruckkey," No")
                                cntrktrp = 0;
                                }
                            }
                            else{
                                
                                // console.log(dtruckkey," No")
                                cntrktrp = 0;
                            }
                        }
                        else{
                            // console.log(dtruckkey," No")
                            cntrktrp=0
                        }
                        trktripscnts=parseInt(trktripscnts)+cntrktrp;
                        // console.log("truckcnts ", truckcnts)
                    })
                    
                    datewisetripitms[indPos] = trktripscnts;


                })
                dateseriestrucks.push({
                    name:key,
                    data:datewisetrucksitms
                })       
                
                dateseriestruckstrips.push({
                    name:key,
                    data:datewisetripitms
                })
              });

            //   console.log("dateseriestruckstrips here ", dateseriestruckstrips)
              vehdistance = vehdistance.sort(GetSortDescOrder("y"));
            //   console.log("clusterseries ", clusterseries)
            // clusterseries = clusterseries.sort(GetSortDescOrder("y"));
            var datewisediremilk = []
            var datewisedirerun = []
            var datewsiects = [];
            var datewiedirmilkcats=[]
            var alldelivedates = [];
            var datewsiemilkrun = [];
            var datewsiedirect = [];
            // console.log("daterecs ", daterecs)
            // var dateseriestrucks = [];
              daterecs.forEach((m,k) => {
                datewsiects.push(moment.parseZone(m[0].delivery_dateformat).format("DD-MM-YYYY"));
                var kfrm = moment.parseZone(k).format("DD-MM-YYYY");
                alldelivedates.push(kfrm);
                // alldeldates.push(k);
                var d = 0;
                if(m.length > 0)
                {
                    m.map((w) => {
                        d=parseFloat(d)+parseFloat(w.distance)
                    })
                }
                datewisedis.push({
                    name:kfrm,
                    y:d
                })
                // console.log("date is ", k)
                var datewisemodemilk = 0;
                var datewisemodedirect = 0;
                var supplierwise = groupBy(m, rdata => rdata.dealer_code);
                // console.log("supplierwise ", m)
                supplierwise.forEach((md,nm) => {
                    // console.log("supplierwise md ", md)
                    if(nm == "DICVPLANT")
                    {
                        var suppliermodewiseseq = groupBy(md, rdata => rdata.truck_no);
                        suppliermodewiseseq.forEach((ss,v) => {
                            var suppliermodewiseseq = groupBy(ss, rdata => rdata.sequence_no);
                            suppliermodewiseseq.forEach((s,v) => {
                                var suppliermodewise = groupBy(s, rdata => rdata.mode);
                                // console.log("suppliermodewise ", suppliermodewise)
                                suppliermodewise.forEach((mwitems,k) => {
                                    if(k == "Milk Run")
                                    {
                                        datewisemodemilk= parseInt(datewisemodemilk)+1;
                                    }
                                    if(k == "Direct")
                                    {
                                        datewisemodedirect= parseInt(datewisemodedirect)+1;
                                    }
                                })
                                
                            })
                        })
                    }
                    
                })
                datewisediremilk.push(datewisemodemilk)
                datewisedirerun.push(datewisemodedirect)
                datewise.push({
                    name:kfrm,
                    y:datewisemodemilk+datewisemodedirect
                })

                var svehlist = groupBy(m, rdata => rdata.vehicle_name);
                var z=0;
                var dtlist=[]
                svehlist.forEach((s,sk) => {
                
                    var dttrucks=0
                    datewiedirmilkcats.push(sk);
                    // console.log("vehiclename ", k)
                    
                        var ddmilk = 0;
                        var ddirct=0;
                    // var vehctruc = groupBy(s, rdata => rdata.truck_no);
                    // vehctruc.forEach((g,p) => {
                        
                    //     var vehsqsw = groupBy(g, rdata => rdata.sequence_no);
                    //     vehsqsw.forEach((sw,sk) => {
                    //         var modesl = groupBy(sw, rdata => rdata.mode);
                    //         // console.log("modesl", modesl)
                    //         modesl.forEach((ds,j) => {
                    //             if(j == "Milk Run")
                    //    k         {
                    //                 ddmilk=parseInt(ddmilk)+1
                    //             }
                    //             if(j == "Direct")
                    //             {
                    //                 ddirct=parseInt(ddirct)+1
                    //             }
                    //         })    
                    //     })
                        

                    // })
                    var vehctruc = groupBy(s, rdata => rdata.truck_no);
                    vehctruc.forEach((g,p) => {                        
                        var vehctructrips = groupBy(g, rdata => rdata.trip_no);
                        vehctructrips.forEach((gtrip,p) => {
                            var vehctruc = groupBy(gtrip, rdata => rdata.dealer_code);
                            vehctruc.forEach((ga,p) => {
                                if(p == "DICVPLANT")
                                {
                                    // var vehsqsw = groupBy(g, rdata => rdata.sequence_no);
                                    // vehsqsw.forEach((sw,sk) => {
                                        var modesl = groupBy(ga, rdata => rdata.mode);
                                        // console.log("modesl", modesl)
                                        modesl.forEach((ds,j) => {
                                            if(j == "Milk Run")
                                            {
                                                ddmilk=parseInt(ddmilk)+1
                                            }
                                            if(j == "Direct")
                                            {
                                                ddirct=parseInt(ddirct)+1
                                            }
                                        })    
                                    // })
                                    
                                }
                                
                            });
                        })
                        // dttrucks=dttrucks+1;

                    })

                    // console.log("dttruckeries ", dttruckeries)
                    datewsiemilkrun.push(ddmilk)
                    datewsiedirect.push(ddirct)
                    z=z+1;
                    // var dttruckeries = {
                    //     name:sk,
                    //     y:dttrucks
                    // }
                    // dtlist.push(dttruckeries);
                })
                
               
              });
// console.log("dateseriestrucks ", dateseriestrucks)
              var datewisemodetypes = [
                {
                    name:"Milk Run",
                    data:datewisediremilk
                },
                {
                    name:"Direct",
                    data:datewisedirerun
                }
              ]

              var datewisemilkdirtypes = [
                {
                    name:"Milk Run",
                    data:datewsiemilkrun
                },
                {
                    name:"Direct",
                    data:datewsiedirect
                }
              ];
              var datewisemodeseries={
                series:datewisemodetypes,
                categories:datewsiects
              }
              var datewisedirectmilkseries ={
                series:datewisemilkdirtypes,
                categories:datewiedirmilkcats
              }
              var mdcategories = [];
              var modemilkrun = 0;
              var modedirect = 0;
            //   console.log("modewise ", modewise)
              modewise.forEach((f,k) => {
                // directmilkrun.push({
                //     name:k,
                //     y:f.length
                // })
                // console.log("kkk ", k)
                if(k == "Milk Run")
                {
                    mdcategories.push("Milk Run");
                    // var suppliermodewisedealr = groupBy(f, rdata => rdata.dealer_name);
                    var modtripn = [];
                    var mdtrucks = [];
                    // console.log("suppliermodewisedealr ", suppliermodewisedealr)
                    // suppliermodewisedealr.forEach((s,k) => {
                    //     // console.log("suppliermodewisedealr K ", k)
                    //     // console.log("suppliermodewisedealr ", s)
                    //     var suppliermodewisedd = groupBy(s, rdata => rdata.delivery_date);
                    //     // console.log("suppliermodewisedealr ", suppliermodewiseseq)
                    //     suppliermodewisedd.forEach((a,b) => {
                            
                            var suppliermodewiseddtr = groupBy(f, rdata => rdata.truck_no);
                            // console.log("suppliermodewisedealr ", suppliermodewiseseq)
                            suppliermodewiseddtr.forEach((aa,bb) => {
                                var suppliermodewiseseq = groupBy(aa, rdata => rdata.dealer_code);
                                // mdtrucks.push(bb);
                                suppliermodewiseseq.forEach((c,d) => {
                                    if(d == "DICVPLANT")
                                    {
                                        modemilkrun= parseInt(modemilkrun)+1;
                                    }
                                    
                                })
                                
                            })
                            
                    //     })
                    // })
                    var modtripncnt = 0;
                    var suppliertrips = groupBy(f, rdata => rdata.trip_no);
                    suppliertrips.forEach((modtirp,modkey) => {
                        var suppliermodewised = groupBy(modtirp, rdata => rdata.dealer_code);
                        suppliermodewised.forEach((c,d) => {
                            if(d == "DICVPLANT")
                            {
                                modtripncnt= parseInt(modtripncnt)+1;
                            }
                            
                        })
                        // modtripn.push(modkey);
                    })
                    modetrucks.push({
                        name:"Milk Run",
                        y:modemilkrun
                    })
                    modetrips.push({
                        name:"Milk Run",
                        y:modtripncnt
                    })
                }
                if(k == "Direct")
                { 
                    mdcategories.push("Direct");
                    var suppliermodewisedealr = groupBy(f, rdata => rdata.dealer_name);
                    var modtripndirect=[];
                    var modtruckli=[];
                    // console.log("suppliermodewisedealr ", suppliermodewisedealr)
                    suppliermodewisedealr.forEach((s,k) => {
                        // console.log("suppliermodewisedealr K ", k)
                        // console.log("suppliermodewisedealr ", s)
                        var suppliermodewisedd = groupBy(s, rdata => rdata.delivery_date);
                        // console.log("suppliermodewisedealr ", suppliermodewiseseq)
                        suppliermodewisedd.forEach((a,b) => {
                            
                            var suppliermodewiseddtr = groupBy(a, rdata => rdata.truck_no);
                            // console.log("suppliermodewisedealr ", suppliermodewiseseq)
                            suppliermodewiseddtr.forEach((aa,bb) => {
                                // modtruckli.push(bb);
                                var suppliermodewiseseq = groupBy(aa, rdata => rdata.dealer_code);
                                suppliermodewiseseq.forEach((c,d) => {
                                    
                                    if(d == "DICVPLANT")
                                    {
                                        // modedirect= parseInt(modedirect)+1;
                                    }
                                })
                                
                            })
                            
                            
                        })

                    })
                    var suppliertrips = groupBy(f, rdata => rdata.trip_no);
                    var nn=0;
                    var modedirectdd=0;
                    suppliertrips.forEach((modtirp,modkey) => {
                        // console.log("nn ", nn)
                        // console.log("modkey ", modkey)
                        // modtripndirect.push(modkey);
                        nn=nn+1;

                        var suppliermodewiseseq = groupBy(modtirp, rdata => rdata.dealer_code);
                        suppliermodewiseseq.forEach((c,d) => {
                            
                            if(d == "DICVPLANT")
                            {
                                modedirectdd= parseInt(modedirectdd)+1;
                            }
                        })

                    })
                    var suppliermodewiseddtrli = groupBy(f, rdata => rdata.truck_no);
                        // console.log("suppliermodewisedealr ", suppliermodewiseseq)
                        suppliermodewiseddtrli.forEach((aa,bb) => {
                            var suppliermodewiseseq = groupBy(aa, rdata => rdata.dealer_code);
                            suppliermodewiseseq.forEach((c,d) => {
                                if(d == "DICVPLANT")
                                {
                                    modedirect= parseInt(modedirect)+1;
                                }
                                // modtruckli.push(bb);
                            })
                            
                            
                        })
                        
                    
                    modetrucks.push({
                        name:"Direct",
                        y:modedirect
                    });
                    
                    modetrips.push({
                        name:"Direct",
                        y:modedirectdd
                    });
                }
              });

              directmilkrun.push({
                name:"Milk Run",
                y:modemilkrun
            },{
                name:"Direct",
                y:modedirect
            })
            // console.log("directmilkrun ",directmilkrun)
              
              suppliers.forEach((m,k) => {
                var vols = 0;
                var weights  = 0;
                var supdis = 0;
                if(m.length > 0)
                {
                    m.map((a) => {
                       
                        vols = parseFloat(vols)+parseFloat(a.part_volume);
                        weights = parseFloat(weights)+parseFloat(a.part_weight);
                        supdis=parseFloat(supdis)+parseFloat(a.distance);
                    
                    })
                    // console.log("DN ", k)
                    
                    
                }
                
                supplierwise.push({
                    name:k,
                    y:m.length
                })
                
                supplierwisevols.push({
                    name:k,
                    y:vols
                })
                supplierwiseweights.push({
                    name:k,
                    y:weights
                })
                
                supdistance.push({
                    name:k,
                    y:supdis
                })
              });


              supplierwisevols = supplierwisevols.sort(GetSortDescOrder("y"));
              supplierwiseweights = supplierwiseweights.sort(GetSortDescOrder("y"));
              supdistance = supdistance.sort(GetSortDescOrder("y"));

              let totuniqueObject = {}; 
              var totobjTitle;
              // Loop for the array elements 
              for (let i in clusteraa) { 
                    // console.log('clusteraa[i] ', clusteraa[i])
                  // Extract the title 
                  totobjTitle = clusteraa[i]['cluster']; 
  
                  // Use the title as the index 
                  totuniqueObject[totobjTitle] = clusteraa[i]; 
              } 
  
              // Loop to push unique object into array 
              for (var a in totuniqueObject) {
                if(totuniqueObject[a].cluster != undefined)
                {
                    clusters.push(totuniqueObject[a]);
                } 
                 
              }
            }
            catch(e){
    
            }
             
            // console.log("clusteraa ", clusters)  
            
            if(clusters.length > 0)
            {
                // clusters.map((item) => {
                //     cluscats.push(item.cluster);
                // })
            }
            // console.log("loaddata ", loaddata)
            var clustervehicles = {
                categories: cluscats,
                series:[{
                    name:"Total Trucks",
                    data:clusterseries
                },{
                    name:"Total Trips",
                    data:clusterseriestrips
                }]
            }
            // console.log("supplierwisevols ", supplierwisevols)
            // console.log("clustervehicles ", clustervehicles)
            var datewisetrips = {
                series:datewise,
                categories:[]
            }
            var datewisedistance = {
                series:datewisedis,
                categories:[]
            }
            var supplierwisearr = [];
            if(supplierwise.length > 0)
            {
                supplierwise.map((item,index) => {
                    if(index<10)
                    {
                        supplierwisearr.push(item)
                    }
                })
            }
            var suppliertrips = {
                series:supplierwisearr,
                categories:[]
            }
            
            var supplierwisevolsarr = [];
            if(supplierwisevols.length > 0)
            {
                supplierwisevols.map((item,index) => {
                    if(index < 10)
                    {
                        supplierwisevolsarr.push(item)
                    }
                })
            }

            var suppliertripsvols = {
                series:supplierwisevolsarr,
                categories:[]
            }
            var supplierwiseweightsarr = [];
            if(supplierwiseweights.length > 0)
            {
                supplierwiseweights.map((item,index)=> {
                    if(index<10)
                    {
                        supplierwiseweightsarr.push(item);
                    }
                })
            }
            var suppliertripsweighs = {
                series:supplierwiseweightsarr,
                categories:[]
            }
            var vehdistancearr = [];
            if(vehdistance.length > 0)
            {
                vehdistance.map((item,index)=> {
                    if(index<10){
                        vehdistancearr.push(item);
                    }
                })
            }
            var vehicledistance = {
                series:vehdistancearr,
                categories:[]
            }
            var supdistancearr = [];
            if(supdistance.length > 0)
            {
                supdistance.map((item,inex) => {
                    if(inex<10)
                    {
                        supdistancearr.push(item);
                    }
                })
            }
            var supplierdistance = {
                series:supdistancearr,
                categories:[]
            }
            var modewisedata = {
                series:directmilkrun,
                categories:["Milk Run","Direct"]
            }
            var modewisedatagrp = {
                series:[{
                    name:"Total Trucks",
                    data:modetrucks
                },{
                    name:"Total Trips",
                    data:modetrips
                }],
                categories:mdcategories
            };
            // console.log("modewisedatagrp ", modewisedatagrp)
            var datewisetruckseries={
                series:dateseriestrucks,
                categories:alldelivedates
            }

            var datewisetrucktripseries={
                series:dateseriestruckstrips,
                categories:alldelivedates
            }

            
            // console.log("datewisetruckseries ", datewisetruckseries)
            
            this.setState({
                loadplan:loaddata,
                // clusters:clusters,
                alldelivedates:alldelivedates,
                datelist:datelist,
                overallclustervehicles:[clustervehicles],
                datewisetrips:[datewisetrips],
                datewisedistance:[datewisedistance],
                suppliertrips:[suppliertrips],
                suppliervols:[suppliertripsvols],
                supplierweights:[suppliertripsweighs],
                supplierdistance:[supplierdistance],
                vehicledistance:[vehicledistance],
                modewise:[modewisedata],
                modewisegrp:[modewisedatagrp],
                datewisemodetypes:[datewisemodeseries],
                showloader:"show-n",
                datewisedirectmilkrunseries:[datewisedirectmilkseries],
                datewisetruckseries:[datewisetruckseries],
                datewisetrucktripseries:[datewisetrucktripseries]
            })
        })
      }
    changeCluster(cluster){
        this.setState({cluster})
    }
    changeDelivery(deliverydate){
        this.setState({deliverydate})
    }
      
    clusterlist(){
        var items = [];
        var clusters = this.state.clusters;
        // console.log("clusters ",clusters)
        items.push({
            value:"",
            label:"All"
        })
        if(clusters.length > 0)
        {
            clusters.map((item) => {
                items.push({
                    value:item.cluster,
                    label:item.cluster
                })
            })
        }
        return items;
    }
    deliverdatelist(){
        var items = [];
        try{
            var alldeliveydates = this.state.alldeliveydates;
            if(alldeliveydates.length > 0)
            {
                var dates = alldeliveydates;
            }
            else{
                var dates = this.state.alldelivedates;
                this.setState({
                    alldeliveydates:dates
                })
            }
        }
        catch(e){
            var dates = this.state.alldelivedates;
                
        }
        
        // console.log("clusters ",clusters)
        items.push({
            value:"",
            label:"All"
        })
        if(dates.length > 0)
        {
            dates.map((item) => {
                items.push({
                    value:item,
                    label:item
                })
            })
        }
        return items;
    }
    handleChangeStartTime(date) {
        // console.log("date ", date)
        var dd = new Date(date).toString();
        // console.log('data ',moment.parseZone(dd).format("HH:mm"))
        var dt = moment.parseZone(dd).format("DD-MM-YYYY");
       // console.log("dt ", dt);
        $("#start_time").val(dt)
        this.setState({
            // vehicle_start_time: dt,
            start_time: dt,
            // start_time: date
        })
      }
    
      handleChangeEndTime(date) {
        var dd = new Date(date).toString();
        // console.log('data ',moment.parseZone(dd).format("HH:mm"))
        var dt = moment.parseZone(dd).format("DD-MM-YYYY");
       // console.log("dt ", dt);
        $("#start_time").val(dt)
        this.setState({
            // vehicle_start_time: dt,
            end_time: dt,
            // start_time: date
        })
      }
    getData(event)
    {
        event.preventDefault();
        this.setState({
            showloader:"show-m"
        })
        var parameters = {
            start_time:this.state.deliverydate.value,
            end_time:this.state.deliverydate.value,
            cluster:this.state.cluster.value
        }
        this.loadPlanData(parameters);
    } 
    render() {
        if(localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined )
        {
          return <Navigate to={"/login"} push={true} />
        }
        return (
            <div>
                {/*Container-fluid starts*/}
                <div className="container-fluid mb-40p pr-35p pl-35p pb-35p">
                    <div className={'lodr '+(this.state.showloader)}>
                        <div className="loaderdiv">
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                        </div>
                        <div className="ldrtext">Loading....</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={this.getData.bind(this)}>
                                <div className="row">
                                    <div className="form-group col-sm-3 mb-20p">
                                        <label>{Constant.FORM_SUMMARY_CLUSTER} <span className="rclr fbold">*</span></label>
                                        <Select 
                                            placeholder={"Select Cluster"}
                                            // closeMenuOnSelect={true}
                                            dateFormat={"dd-MM-yyyy"}
                                            onChange={this.changeCluster} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            value={this.state.cluster}
                                            options={this.clusterlist()} 
                                        /> 
                                    </div>
                                    <div className="form-group col-sm-3 mb-20p">
                                        <label>{Constant.FORM_SUMMARY_DELIVERY_DATE} <span className="rclr fbold">*</span></label>
                                        {/* <input type="text" name="start_time" id="start_time" className="form-control datepicker" onChange={this.changeHandler} value={this.state.start_time} required /> */}
{/*                                         
                                       <Datetime  
                                            value = {this.state.start_time} 
                                            onChange = {this.handleChangeStartTime}
                                            closeOnSelect
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            inputProps={{ placeholder: 'Start Date', name: 'start_time', autoComplete:'off' }} 
                                        /> */}

                                        <Select 
                                            placeholder={"Select Delivery Date"}
                                            onChange={this.changeDelivery} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            value={this.state.deliverydate}
                                            options={this.deliverdatelist()} 
                                        /> 
                                    </div>
                                    {/* <div className="form-group col-sm-3 mb-20p">
                                        <label>{Constant.FORM_SUMMARY_END_TIME} <span className="rclr fbold">*</span></label>
                                      
                                        <Datetime  
                                            value = {this.state.end_time} 
                                            onChange = {this.handleChangeEndTime}
                                            closeOnSelect
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            inputProps={{ placeholder: 'End Date', name: 'end_time', autoComplete:'off' }} 
                                            
                                        />
                                    </div> */}
                                   
                                    <div className="form-group col-sm-3 mb-20p">
                                    <button type="submit" className="btn btn-warning mt-30p">Submit</button>
                                    {/* <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button> */}
                                    </div>
                                </div>
                                </form>
                        </div>
                    </div>
                    <div className="row mt-40p">
                    {(this.state.overallclustervehicles.length > 0)?
                            <div className="col-sm-6 ">
                                <div className="card fcls-a">
                                    <div className="card-body">
                                    <ColumnGroupoChart
                                        chartType = {"column"}
                                        title={"Overall Cluster Wise Vehicles"}
                                        categories={this.state.overallclustervehicles[0].categories}
                                        series={this.state.overallclustervehicles[0].series}
                                        color={['#2804d3', '#2bd304','#d3b804', '#d35504','#04d3cd', '#8f04d3', '#d304b4', '#d30404', '#c4d304', '#04d3a4']}

                                    />
                                    </div>
                                </div>
                            </div>
                    :""}
                   
                    {/* {(this.state.modewise.length > 0)?
                            <div className="col-sm-6  ">
                                <div className="card fcls-a">
                                    <div className="card-body">
                                        <ColumnChart
                                            chartType = {"column"}
                                            title={"Overall Tranportation Mode"}
                                            categories={this.state.modewise[0].categories}
                                            series={this.state.modewise[0].series}
                                            color={['#2804d3', '#2bd304','#d3b804', '#d35504','#04d3cd', '#8f04d3', '#d304b4', '#d30404', '#c4d304', '#04d3a4']}

                                        />
                                    </div>
                                </div>
                            </div>
                    :""} */}
                     {(this.state.modewisegrp.length > 0)?
                            <div className="col-sm-6 ">
                                <div className="card fcls-a">
                                    <div className="card-body">
                                    <ColumnGroupoChart
                                        chartType = {"column"}
                                        title={"Overall Tranportation Mode"}
                                        categories={this.state.modewisegrp[0].categories}
                                        series={this.state.modewisegrp[0].series}
                                        color={[ '#c4d304', '#FF7F27','#2804d3', '#2bd304','#d3b804', '#d35504','#04d3cd', '#8f04d3', '#d304b4', '#d30404', '#c4d304', '#04d3a4']}

                                    />
                                    </div>
                                </div>
                            </div>
                    :""}

                    </div>
                    <div className="row">
                   
                    </div>
                    <div className="row mt-40p">
                    
                        {(this.state.datewisemodetypes.length > 0 && this.state.deliverydate.value == "")?
                            
                            <div className="col-sm-12 ">
                                
                                <div className="card fcls-a">
                                    <div className="card-body">
                                        <ColumnGroupoChart
                                            chartType = {"column"}
                                            title={"Date Wise Tranportation Mode"}
                                            categories={this.state.datewisemodetypes[0].categories}
                                            series={this.state.datewisemodetypes[0].series}
                                            color={['#2804d3', '#2bd304']}

                                        />
                                    </div>

                                </div>
                            </div>
                    :""}
                    </div>
                    <div className="row mt-40p">
                    
                    {(this.state.datewisedirectmilkrunseries.length > 0 && this.state.deliverydate.value != "")?
                        
                        <div className="col-sm-12 ">
                            
                            <div className="card fcls-a">
                                <div className="card-body">
                                    <ColumnGroupoChart
                                        chartType = {"column"}
                                        title={"Vehicle Wise Tranportation Mode"}
                                        categories={this.state.datewisedirectmilkrunseries[0].categories}
                                        series={this.state.datewisedirectmilkrunseries[0].series}
                                        color={['#2804d3', '#2bd304']}

                                    />
                                </div>

                            </div>
                        </div>
                    :""}
                    </div>
                    <div className="row mt-40p">
                    
                    {(this.state.datewisetruckseries.length > 0)?
                        
                        <div className="col-sm-12 ">
                            
                            <div className="card fcls-a">
                                <div className="card-body">
                                    <StackedChartComponent
                                        chartType = {"column"}
                                        title={"Date Wise Trucks"}
                                        categories={this.state.datewisetruckseries[0].categories}
                                        series={this.state.datewisetruckseries[0].series}
                                        color={['#c4d304', '#FF7F27','#2804d3', '#2bd304','#d3b804', '#d35504','#04d3cd', '#8f04d3', '#d304b4', '#d30404', '#c4d304', '#04d3a4']}

                                    />
                                </div>

                            </div>
                        </div>
                    :""}
                    </div>
                    <div className="row mt-40p">
                    
                    {(this.state.datewisetrucktripseries.length > 0)?
                        
                        <div className="col-sm-12 ">
                            
                            <div className="card fcls-a">
                                <div className="card-body">
                                    <StackedChartComponent
                                        chartType = {"column"}
                                        title={"Date Wise Trips"}
                                        categories={this.state.datewisetrucktripseries[0].categories}
                                        series={this.state.datewisetrucktripseries[0].series}
                                        color={['#2bd304','#c4d304','#04d3cd', '#8f04d3', '#d304b4', '#d30404', '#c4d304','#d3b804', '#d35504', '#FF7F27','#2804d3',  '#04d3a4']}

                                    />
                                </div>

                            </div>
                        </div>
                    :""}
                    </div>
                    
                    <div className="row mt-40p">
                     {/*(this.state.datewisetrips.length > 0)?
                        
                            <div className="col-sm-6 bxs15p">
                                <LineChartComponent
                                    chartType = {"spline"}
                                    title={"Date Wise  Vehicle Trips"}
                                    categories={this.state.datewisetrips[0].categories}
                                    series={this.state.datewisetrips[0].series}
                                    color={['#d35504','#04d3cd', '#8f04d3', '#d304b4', '#2804d3', '#2bd304','#d3b804', '#d30404', '#c4d304', '#04d3a4']}

                                />
                            </div>
                    :""*/}
                    
                    {/*(this.state.datewisedistance.length > 0)?
                            <div className="col-sm-6 bxs15p">
                                <LineChartComponent
                                    chartType = {"spline"}
                                    title={"Date Wise Vehicle Distance Travelled (Kms)"}
                                    categories={this.state.datewisedistance[0].categories}
                                    series={this.state.datewisedistance[0].series}
                                    color={['#d35504','#04d3cd','#2bd304','#d3b804', '#d30404', '#c4d304',  '#8f04d3', '#d304b4', '#2804d3', '#04d3a4']}

                                />
                            </div>
                    :""*/}
                    </div>

                    <div className="row mt-40p">
                    
                    {/* {(this.state.suppliervols.length > 0)?
                        
                            <div className="col-sm-6">
                                
                                <div className="card fcls-a">
                                    <div className="card-body">
                                        <ColumnChart
                                            chartType = {"column"}
                                            title={"Top 10 Supplier Wise Vehicles Volumes"}
                                            categories={this.state.suppliervols[0].categories}
                                            series={this.state.suppliervols[0].series}
                                            color={['#2bd304','#d3b804','#d30404', '#c4d304', '#d35504','#04d3cd','#2804d3',  '#8f04d3', '#d304b4',  '#04d3a4']}

                                        />
                                    </div>
                                </div>
                            </div>
                    :""}
                     */}
                    {/* {(this.state.supplierweights.length > 0)?
                            <div className="col-sm-6">
                                
                                <div className="card fcls-a">
                                    <div className="card-body">
                                        <ColumnChart
                                            chartType = {"column"}
                                            title={"Top 10 Supplier Wise Vehicles Weights"}
                                            categories={this.state.supplierweights[0].categories}
                                            series={this.state.supplierweights[0].series}
                                            color={[ '#d35504','#04d3cd', '#2804d3', '#c4d304', '#04d3a4', '#2bd304','#d3b804','#8f04d3', '#d304b4', '#d30404']}

                                        />
                                    </div>
                                </div>
                            </div>
                    :""} */}
                    </div>
                    
                    {/*(this.state.suppliertrips.length > 0)?
                        <div className="row mt-40p">
                            <div className="col-sm-12 bxs15p">
                                <LineChartComponent
                                    chartType = {"spline"}
                                    title={"Top 10 Supplier Wise Vehicles Trips"}
                                    categories={this.state.suppliertrips[0].categories}
                                    series={this.state.suppliertrips[0].series}
                                    color={['#d304b4', '#d30404', '#c4d304', '#2804d3', '#2bd304','#d3b804', '#d35504','#04d3cd', '#8f04d3', '#04d3a4']}

                                />
                            </div>
                        </div>
                    :""*/}
                   <div className="row mt-40p">
                    
                    {/* {(this.state.supplierdistance.length > 0)?
                        
                            <div className="col-sm-6">
                                
                                <div className="card fcls-a">
                                    <div className="card-body">
                                        <ColumnChart
                                            chartType = {"column"}
                                            title={"Top 10 Supplier Wise Distance Travelled (Kms)"}
                                            categories={this.state.supplierdistance[0].categories}
                                            series={this.state.supplierdistance[0].series}
                                            color={['#2bd304','#d3b804','#d30404', '#c4d304', '#d35504','#04d3cd','#2804d3',  '#8f04d3', '#d304b4',  '#04d3a4']}

                                        />
                                    </div>
                                </div>
                            </div>
                    :""} */}
                    
                    {/* {(this.state.vehicledistance.length > 0)?
                            <div className="col-sm-6 ">
                                
                                <div className="card fcls-a">
                                    <div className="card-body">
                                        <ColumnChart
                                            chartType = {"column"}
                                            title={"Top 10 Vehicle Wise Distance Travelled (Kms)"}
                                            categories={this.state.vehicledistance[0].categories}
                                            series={this.state.vehicledistance[0].series}
                                            color={[ '#d35504','#04d3cd', '#2804d3', '#c4d304', '#04d3a4', '#2bd304','#d3b804','#8f04d3', '#d304b4', '#d30404']}

                                        />
                                    </div>
                                </div>
                            </div>
                    :""} */}

                       
                    </div>
                    
                </div>
                {/*Container-fluid Ends*/}
            </div>
        )
    }
}

function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 

function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
// $( function() {
//     $( ".datepicker" ).datepicker();
//   } );