import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'

import CountUp from "react-countup";
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import { verifyext } from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import ViewTransData from "./viewtransactiondata";
import LearningMaterialListForm from "./learningmateriallist";
import NAButton from './naButton';
import StaticButton from './static-button';
import AreaRangeChart from "./areaRangeChart";
import ConfidenceChart from "./confidenceChart";
import LineChart from "./lineChart";
import NewLineChart from "./newlinechart"
import MaterialList from './materialList';
import CSVFileValidator from 'csv-file-validator';
import ComparisonCharts from './funcCompMaterialComparisonCharts';
import AnomalyAnalysis from './anomalyAnalysisComponent';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
var infoBox = require('../common/google-infowindow');
const scaleanimation = {
    transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows = [];
var markersArraylist = [];

export default class AnamolyForecastOutputParams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            frameworkComponents: {
                GridButton: GridButton,
                ViewTransData: ViewTransData
            },
            rowData: [],
            underPredictedOutliersData: [],
            excessPredictedOutliersData: [],
            underSummaryData: [],
            excessSummaryData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            detailCellRendererParams: {},
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 1000,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            showSlideBlockRoute: "sidebarcls",
            showSlideBlockUpload: "sidebarcls",
            showSlideBlockStack: "sidebarcls",
            overlayBlock: "show-n",
            routeData: [],
            rfreshmap: 1,
            deliverpickupslist: [],
            dealers: [],
            plants: [],
            uploadfile: '',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            loadplan: [],
            allLoadPlan: [],
            deliverydates: [],
            deliveryopts: [],
            filterDeliveryDate: { "value": "", "label": "Select Delivery Date" },
            clusterlist: [],
            filtercluster: { "value": "", "label": "Select Cluster" },
            vehicletypelist: [],
            filterVehicleType: { "value": "", "label": "All" },
            mapcol: "show-n",
            gridocl: "col-sm-12",
            filter_date: "",
            vtypediv: "show-n",
            vsupplierdiv: "show-n",
            selectedCluster: "",
            fdealerlist: [],
            filterdealer: { "value": "", "label": "All" },
            showloader: "show-n",
            mpnode: [],
            mpfor: "",
            clusterpoints: [],
            nmapcluster: "show-n",
            nmap: "show-m",
            scluster: "",
            stackContent: "",
            stackImage: "",
            stacktbl: [],
            stacktblbottom: [],
            stackOriginalData: [],
            showrunprocessbtn: "show-m",
            showanimate: "show-n",
            locationNames: [],
            loadFile: '',
            material_configurations: 0,
            location: { label: "Select Location", value: "" },
            loadshow: 'show-m',
            overly: 'show-m',
            processErrMsg: "",
            processErrShow: "",
            transactionId: "",
            droppedList: [],
            customer_code: [{ "value": "ALL", "label": "All" }],
            material_code: [{ "value": "ALL", "label": "All" }],
            duration: { "value": "", "label": "Select Option" },
            customerlist: [],
            materiallist: [],
            durationlist: [],
            chartdata: "show-n",
            datagrid: 'show-m',
            datagridTab: "activet",
            chartdataTab: "btn-default",
            chartseries: [],
            rowForecastData: [],
            forecastitemslist: [],
            forecastcustomerlist: [],
            allforecastcustomerlist: [],
            forecastitem: { "value": "", label: "Select" },
            forecastCustomer: [{ "value": "", label: "All" }],
            predRecords: [],
            forecastingCustomersData: [],
            dataShow: "show-m",
            forecastData: "show-n",
            dayspred: 0,
            inpShow: "show-n",
            sliderTitle: "",
            sliderType: 1,
            materialInfo: [],
            transactionOutput: [],
            forecastingAllData: [],
            chartseries: [],
            confidenceTrain: [],
            confidenceTrend: [],
            confidenceTestPoints: [],
            confidencePredictPoint: [],
            seasonality_train_plot: [],
            seasonality_pred_plot: [],
            seasonality_train_plotdata: [],
            allseason: [],
            chartCusts: [],
            chartItems: [],
            chartCustomer: { label: "Select", value: "" },
            chartItem: { label: "Select", value: "" },
            customerwisedata: [],
            allChartData: [],
            confAreaData: [],
            confTrendplot: [],
            confScatter: [],
            confAreaYHat: [],
            confLineSeasonal: [],
            confAreaDataDemo: [],
            trendingcost: [],
            foreCast_period: '',
            mat_itm_code: { "value": "", label: "Select" },
            scenarioAndItemCodes: [],
            csvfiledata: [],
            scenario: '',
            completedScenariolist: [],
            phaseList: [],
            phases: [],
            anamolyRowData: [],
            rowDataPred: [],
            s1: "btn-success",
            s2: "btn-default",
            s3: "btn-default",
            s4: "btn-default",
            s5: "btn-default",
            s6: "btn-default",
            loadshow1: "show-n",
            phase: { "value": "2023-Apr-2024-Mar", label: "2023-Apr-2024-Mar" },
            predictionValidationPhases: [],
            predictionValidationPhase: { "value": "2023-Apr-2024-Mar", "label": "2023-Apr-2024-Mar" },
            materialTypes: [{ "label": "HIGH CRITICAL", "value": "HIGH CRITICAL" }],
            materialType: { "label": "HIGH CRITICAL", "value": "HIGH CRITICAL" },
            chartPhase: { "value": "2023-Apr-2024-Mar", label: "2023-Apr-2024-Mar" },
            phaseList: [],
            duration: { "value": 12, label: "12" },
            durationlist: [],
            startDate: new Date("2020-01-01"),
            endDate: new Date("2020-06-30"),
            category: { "value": "", label: "All" },
            categorylist: [],
            matCodesList: [],
            s3tab: "show-n",
            outlier_count: 0,
            outlier_cost: 0,
            outlier_actual_count: 0,
            outlier_actual_cost: 0,
            outlier_period: { value: "2020 Jan-Jun", label: "2020 Jan-Jun" },
            loadshow2: "show-n",
            showChart: false,
            chartItemCode: "",
            initialPhase: { "value": "2023-Apr-2024-Mar", label: "2023-Apr-2024-Mar" },
            initial_prediction_summary: [],
            complete_data: [],
            validation_summary: [],
            plot_data: [],
            category_sign: "",
            materialPatternPhase: "",
            materialPatternPhases: []

        }
        this.getPageCounterFromChildComponent = this.getPageCounterFromChildComponent.bind(this);

    }
    async componentDidMount() {
        var categorylist = [
            {
                value: "",
                label: "All"
            },
            {
                value: "Exact",
                label: "Exact"
            }, {
                value: "Excess",
                label: "Excess"
            }, {
                value: "Under",
                label: "Under"
            }
        ]

        this.setState({
            categorylist: categorylist
        })
        this.setState({
            loadshow: 'show-n',
            loadshow1: 'show-m',
            overly: 'show-n',
            overlayBlock: "show-n"
        })
        var durationlist = [
            // {
            //     value:3,
            //     label:"3 onths"
            // },{
            //     value:6,
            //     label:"6 Months"
            // },{
            //     value:12,
            //     label:"1 Year"
            // }
            {
                value: 90,
                label: "90 Days"
            }, {
                value: 180,
                label: "180 Days"
            }, {
                value: 365,
                label: "365 Days"
            }
        ]
        this.setState({
            durationlist: durationlist
        })
        await this.learningMaterialList()
        await this.loadPageData()
        this.getForecastCompletedScenariosList()
        await this.getForecastingPredictionResults()

        redirectURL.post("/dashboard/phaselists")
            .then((response) => {
                var records = response.data.records;
                var recordsarr = [];
                if (records.length > 0) {
                    records.map((itm) => {
                        recordsarr.push({ value: itm, label: itm });
                    })
                }
                this.setState({
                    phases: recordsarr.slice(0, recordsarr.length).reverse(),
                    predictionValidationPhases: recordsarr.slice(0, recordsarr.length).reverse(),
                    materialPatternPhases: recordsarr.slice(0, recordsarr.length - 1).reverse()

                }, () => { this.showPrePredictionResultsData() })
            })
        this.setState({
            loadshow: "show-n",
            overly: 'show-n',
            overlayBlock: "show-n"
        })

    }

    getForecastCompletedScenariosList = () => {
        let completedScenariolist = [];
        let phaseList = [];
        redirectURL.post('forecast/getForecastCompletedScenariosList')
            .then(async (response) => {
                var records = response.data.scenarios;
                let distinctPhases = response.data.distinctPhases

                if (records.length > 0) {
                    records.map((item) => {
                        completedScenariolist.push({ value: item, label: item })
                    })
                }
                await distinctPhases.map(item => { phaseList.push({ label: item, value: item }) })
                this.setState({
                    completedScenariolist: completedScenariolist,
                    phaseList: phaseList
                })
            })
    }

    getForecastingPredictionResults = () => {
        let params = {
            start_date: "2020-01-01",
            end_date: "2020-06-30"
        }
        let phase = this.state.phase.value
        // this.setState({loadshow:'show-m'})
        let dictMatCodes = [{ "value": "", label: "All" }];
        let phaseList = [];
        let durationlist = [];
        redirectURL.post("/forecast/getForecastingPredictionResults", params)
            .then(async response => {
                if (response.data.status == 'success') {
                    let records = response.data.predictionResults
                    let matCodes = response.data.matCode
                    let distinctPhases = response.data.distinctPhases
                    let distinctFcs = response.data.distinctFcs
                    await matCodes.map(item => { dictMatCodes.push({ label: item, value: item }) })
                    await distinctPhases.map(item => { phaseList.push({ label: item, value: item }) })
                    await distinctFcs.map(item => { durationlist.push({ label: item, value: item }) })

                    await this.setState({
                        // rowData:records,
                        //matCodesList:dictMatCodes,
                        phaseList: phaseList,
                        durationlist: durationlist
                        // matCode:{"value":"",label:"All"},
                        // showCharts:false,
                        // loadshow:'show-n'
                    })
                }
            })

    }

    loadPageData() {
        this.setState({
            loadshow: 'show-m',
            overlayBlock: 'show-m',
        })
        var params = {}
        if (this.state.mat_itm_code != undefined && this.state.mat_itm_code != "") {
            if (this.state.mat_itm_code.value != undefined && this.state.mat_itm_code.value != "") {
                params.item_code = this.state.mat_itm_code.value
            }
        }
        // redirectURL.post('/forecast/forecastoutputdata',params)
        redirectURL.post('/forecast/anamolyForecastMaterialRequests', params)
            .then(response => {
                var records = response.data;

                if (records.length > 0) {
                    var params = {
                    }
                    redirectURL.post('/forecast/getAnamolyDataFromMaterialsMaster', params)
                        .then(async response => {
                            let rec = response.data;
                            await records.map(item => {
                                rec.map(recItem => {
                                    if (recItem.item_code == item.item_code) {
                                        item.item_description = recItem.item_description;
                                        item.cost_in_USD_per_item = recItem.cost_in_USD_per_item;
                                    }
                                })
                            })
                            console.log(records, "new_records")
                            await this.setState({
                                // rowData:records,
                                anamolyRowData: records,
                                loadshow: 'show-n',
                                overlayBlock: 'show-n',
                            })
                        })
                }


            })
    }
    learningMaterialList = async () => {
        var params = {}
        redirectURL.post('/forecast/anamolylearningMaterialData', params)
            .then(async response => {
                var records = response.data;
                var mList = [{ "label": "ALL", "value": "all" }];
                await records.map((rec) => {
                    mList.push({ "label": rec, "value": rec });
                });
                await this.setState({
                    materiallist: mList
                })
            })

        let scenarioAndItemCodes = [];
        redirectURL.post('/forecast/forecastlearningdata', params)
            .then(async response => {
                var records = response.data;
                let scenarioItemsList = groupBy(records, rdata => rdata.scenario_type);

                scenarioItemsList.forEach((values, key) => {
                    let matCodes = [];
                    if (key != undefined && key != null && key != '') {
                        matCodes = values.map(item => item.item_code)
                        scenarioAndItemCodes.push({
                            value: key,
                            label: key,
                            code: matCodes
                        })
                    }
                })
                this.setState({ scenarioAndItemCodes: scenarioAndItemCodes })
            })
    }

    updateMaterialCode = (newValue) => {
        this.setState({ mat_itm_code: newValue })
    }

    // onChangeMaterialType(materialType){
    //     this.setState({materialType})
    // }

    updateMaterialCode1 = (newValue) => {
        this.setState({ mat_itm_code: newValue })
        setTimeout(() => {
            console.log("this.state.mat_itm_code ", this.state.mat_itm_code)
            let forecastitemVal = this.state.mat_itm_code.value

            var category = this.state.category.value;
            if (this.state.rowDataPred.length > 0) {
                let filteredData = this.state.rowDataPred.filter(item => {
                    console.log("item", item)

                    // if(category != ""){
                    //     if(item.item_code == forecastitemVal && item.category == category){
                    //         return true
                    //     }else{
                    //         if(item.category == category){
                    //             return true
                    //         }
                    //         else{
                    //             return false;
                    //         }
                    //     }
                    // }
                    // else{
                    //     if(item.item_code == forecastitemVal){
                    //         return true
                    //     }else{

                    //         return true;


                    //     }
                    // }
                    if (forecastitemVal === "") {
                        return true;
                    }
                    else {
                        if (item.item_code == forecastitemVal) {
                            return true
                        } else {
                            return false
                        }
                    }

                })
                this.setState({
                    rowData: filteredData
                })
            }
        }, 500)
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickShowUpload = () => {
        this.setState({
            showSlideBlockUpload: "slide25",
            overlayBlock: "show-m"
        })
    }

    hideSlideBlock = () => {
        this.setState({
            overlayBlock: "show-n",
            showSlideBlock: "",
            showSlideBlockUpload: "",
            showSlideBlockRoute: "",
            showSlideBlockStack: "",
            loadFile: '',
            loadshow: 'show-n',
            overly: 'show-n',
            duration: { "value": "", "label": "Select Option" },
            material_code: [{ "value": "ALL", "label": "All" }],
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    onChangeCustomer(customer_code) {
        this.setState({ customer_code })
    }
    onChangeMaterial(material_code) {
        this.setState({ material_code })
    }
    // onChangeMaterialPatternPhase = (materialPatternPhase) => {
    //     this.setState({ materialPatternPhase })
    // }
    onChangeDuration(duration) {
        this.setState({ duration }, () => {
            if (this.state.duration.value === 0) {
                this.setState({
                    inpShow: "show-m"
                })
            }
            else {
                this.setState({
                    inpShow: "show-n"
                })
            }
        })
    }

    onChangeScenario = async (scenario) => {

        await redirectURL.post("/forecast/getAllForecastingData", { scenario_type: scenario }).then(async (response) => {
            if (response.data.length > 0) {
                var downloadInfo = response.data;
                if (typeof downloadInfo == "string") {
                    downloadInfo = JSON.parse(response.data);

                }
                this.setState({
                    forecastingAllData: downloadInfo,
                })
            }
        }).catch(function (error) {
            console.log(error);
        })
        this.setState({ scenario })
    }
    onSubmitGetData = (event) => {
        event.preventDefault();
        var material_code = this.state.material_code;
        var materialCodes = material_code.map((mc) => mc.value);
        var duration = this.state.duration.value;
        if (duration != undefined && duration != "") {
            var params = {
                item_code: materialCodes,
                fc_period: duration
            }
            redirectURL.post("/forecast/predictdata", params)
                .then((response) => {
                    if (response.data.status === "Success") {
                        this.setState({
                            loadshow: 'show-n',
                            overly: 'show-n',
                            overlayBlock: "show-n",
                            showSlideBlockUpload: "",
                            duration: { "value": "", "label": "Select Option" },
                            material_code: [{ "value": "ALL", "label": "All" }],
                            show: true,
                            basicTitle: response.data.message,
                            basicType: "success"
                        })
                        this.loadPageData()
                    }
                    else {
                        this.setState({
                            loadshow: 'show-n',
                            overly: 'show-n',
                            overlayBlock: "show-n",
                            showSlideBlockUpload: "",
                            duration: { "value": "", "label": "Select Option" },
                            material_code: [{ "value": "ALL", "label": "All" }],
                            show: true,
                            basicTitle: "Something went wrong!!!",
                            basicType: "danger"
                        })
                    }
                })
        }
    }
    onClickTab(oVal) {
        if (oVal == "1") {
            this.setState({
                datagrid: "show-m",
                chartdata: "show-n",
                datagridTab: "activet",
                chartdataTab: "btn-default"
            })
        }
        if (oVal == "2") {
            this.setState({
                datagrid: "show-n",
                chartdata: "show-m",
                datagridTab: "btn-default",
                chartdataTab: "activet"
            })
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    onGridReady1 = (params) => {
        this.gridApiNew = params.api;
        this.gridColumnApiNew = params.columnApi;
    };
    onGridReady2 = (params) => {
        this.gridApiNew2 = params.api;
        this.gridColumnApiNew2 = params.columnApi;
    };
    onGridReady3 = (params) => {
        this.gridApiNew3 = params.api;
        this.gridColumnApiNew3 = params.columnApi;
    };
    onBtnExport = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApi.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcel = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApi.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onClickShowValidationSummaryChartData = async (header, cell) => {

        if (this.state.s5 === "btn-success") {
            // if ((header === "Vital(%)" || header === "Essential(%)" || header === "Desirable(%)") && (cell !== "Total Materials for Prediction")) {
            var prediction_category = cell.substring(cell.lastIndexOf('-') + 1).trim();
            var material_group = header.slice(0, header.length - 3).toLowerCase()
            var category_name = cell.slice(0, cell.lastIndexOf('-') - 1)
            this.setState({
                showSlideBlock: "slide45",
                overlayBlock: 'show-m',
                //overly: "show-m",
                sliderTitle: "Phase Wise Comparison Plot",
                plot_data: [],
                category_sign: prediction_category,
                // headerName: rownode.colDef.headerName,
                title: `${category_name}(${prediction_category}) - ${header.charAt(0).toUpperCase() + header.slice(1, header.length - 3)} Materials Comparison`
            })
            var params = {
                prediction_category: prediction_category,
                material_group: material_group,
                phase: this.state.predictionValidationPhase.value
            }
            await redirectURL.post("/forecast/getPhaseWisePlots", params)
                .then((response) => {
                    // console.log(JSON.parse(response.data.plot_data))
                    this.setState({ plot_data: JSON.parse(response.data.plot_data) })
                }).catch((error) => {
                    console.log(error)
                })
            // }
        }

    }

    // hideSlideBlock = () => {
    //     this.setState({
    //         overlayBlock: "show-n",
    //         showSlideBlock: "",
    //         loadshow: 'show-n',
    //         overly: 'show-n',
    //     });
    // }

    onBtnExportF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcelF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onBtnExportG = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew2.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcelG = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew2.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onBtnExportH = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew3.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcelH = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew3.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onChangeCustomers(forecastCustomer) {
        this.setState({ forecastCustomer }, () => {
            var sct = this.state.forecastCustomer;
            console.log("sct ", sct)
            if (sct.length > 1) {
                var list = [];
                sct.map((item) => {
                    if (item.value !== "") {
                        list.push(item);
                    }
                })
                this.setState({
                    forecastCustomer: list
                })
            }
            else {
                this.setState({
                    forecastCustomer: this.state.forecastCustomer
                })
            }
        })
    }
    onChangeItems(forecastitem) {
        this.setState({ forecastitem }, () => {
            var selectedItem = this.state.forecastitem.value;
            this.setState({
                forecastCustomer: [{ "value": "", label: "All" }]
            })
            setTimeout(() => {
                var predRecords = this.state.predRecords;
                var selectItemCustomers = [];
                if (predRecords.length > 0) {

                    var customerlist = predRecords.filter((f) => f.item_code === selectedItem);
                    if (customerlist.length > 0) {
                        var customerCodes = groupBy(customerlist, rdata => rdata.customer_code);
                        try {
                            // console.log("customerCodes ", customerCodes)
                            customerCodes.forEach((values, key) => {
                                selectItemCustomers.push({
                                    value: key,
                                    label: key
                                })
                            })
                        } catch (error) {

                        }
                    }
                }
                // console.log("selectItemCustomers ", selectItemCustomers)
                this.setState({
                    forecastcustomerlist: selectItemCustomers
                })
            }, 300)

        })
    }
    onClickGetItemCustomerData() {
        var itemcode = this.state.forecastitem.value;
        var selectedcustomers = [];
        if (itemcode === "all") {
            var forecastcustomerlist = this.state.allforecastcustomerlist;
            // console.log("forecastcustomerlist ", forecastcustomerlist)
            if (forecastcustomerlist.length > 0) {
                forecastcustomerlist.map((item) => {
                    selectedcustomers.push(item.value);
                })
            }
        }
        var customercodes = this.state.forecastCustomer;
        var sct = []
        // console.log("customercodes ", customercodes)
        if (customercodes.length > 0) {
            customercodes.map((item) => {
                if (item.value !== "") {
                    selectedcustomers.push(item.value)

                }
                else {
                    sct.push("all")
                }

            })
            // forecastcustomerlist
        }
        if (sct.includes("all") === true) {
            var sforecastcustomerlist = this.state.forecastcustomerlist;
            if (sforecastcustomerlist.length > 0) {
                sforecastcustomerlist.map((item) => {
                    selectedcustomers.push(item.value);
                })
            }
        }
        // console.log("itemcode ", itemcode)
        // console.log("selectedcustomers ", selectedcustomers)

        var forecastingCustomersData = []
        var predRecords = this.state.predRecords;
        var selectItemCustomers = [];
        if (predRecords.length > 0) {
            predRecords = predRecords.sort(GetSortDescOrder("transaction_id"));
            // console.log("predRecords ", predRecords)
            var trans = []
            if (selectedcustomers.length > 0) {
                selectedcustomers.map((ti) => {
                    predRecords.filter((f) => {
                        if (itemcode !== "all") {
                            if (f.customer_code === ti && f.item_code === itemcode) {
                                trans.push({ customer_code: f.customer_code, item_code: f.item_code, transaction_id: f.transaction_id })
                            }
                        }
                        else {

                            if (f.customer_code === ti) {
                                trans.push({ customer_code: f.customer_code, item_code: f.item_code, transaction_id: f.transaction_id })
                            }
                        }

                    })
                })
            }
            // console.log("transs ", trans)
            if (trans.length > 0) {
                var newArrayItems = [];
                let totuniqueObjectItem = {};
                var totobjTitleItem;
                // Loop for the array elements 
                for (let i in trans) {

                    // Extract the title
                    if (trans[i]['transaction_id'] != "" && trans[i]['transaction_id'] != undefined) {
                        totobjTitleItem = trans[i]['transaction_id'];

                        // Use the title as the index 
                        totuniqueObjectItem[totobjTitleItem] = trans[i];
                    }

                }

                // Loop to push unique object into array 
                for (var a in totuniqueObjectItem) {
                    newArrayItems.push(totuniqueObjectItem[a]);
                }
                // console.log("newArrayItems ", newArrayItems)
                newArrayItems = newArrayItems.sort(GetSortDescOrder("transaction_id"));
                var customegroup = groupBy(newArrayItems, rdata => rdata.customer_code);
                try {
                    customegroup.forEach((cit) => {
                        if (cit.length > 0) {
                            var otm = groupBy(cit, rdata => rdata.item_code);
                            otm.forEach((mm) => {
                                // console.log("mmmm ", mm)
                                mm.sort(GetSortDescOrder("transaction_id"));
                                forecastingCustomersData.push(mm[0])
                            })
                        }
                    })
                } catch (error) {

                }

            }
            // groupBy(customerlist, rdata => rdata.customer_code);
        }
        // console.log("forecastingCustomersData ", forecastingCustomersData)
        this.setState({
            forecastingCustomersData: forecastingCustomersData,
            dataShow: "show-n",
            forecastData: 'show-m'
        })

    }
    onClickForecastGrid() {
        this.setState({
            forecastingCustomersData: [],
            dataShow: "show-m",
            forecastData: 'show-n',
            forecastitem: { "value": "", label: "Select" },
            forecastCustomer: [{ "value": "", label: "All" }],
        })
    }
    showMaterialForecastHistory = async (row) => {
        var historycolsDefs = [
            {
                headerName: "Forecast Summary",
                field: "view_trans_data",
                width: 150,
                cellRenderer: function (params) {
                    if (params.data.status === "success") {
                        return <StaticButton />
                    }
                    else {
                        return <NAButton />
                    }
                },
            },
            {
                headerName: "Data Visualization",
                field: "data_visualization",
                width: 150,
                cellRenderer: function (params) {
                    if (params.data.status === "success") {
                        return <StaticButton />
                    }
                    else {
                        return <NAButton />
                    }
                },
            },
            {
                headerName: "Forecast Run Date",
                field: "created_on",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.created_on !== "" && params.data.created_on !== undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.created_on);
                        }
                        else {
                            return "";
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "Duration(days)",
                field: "fc_period",
                width: 150,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Status",
                field: "status",
                width: 150,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Best Model",
                field: "best_model",
                width: 150,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Phase",
                field: "phase",
                width: 150,
                filter: true,
                resizable: true,
                editable: false
            },
        ]
        await this.setState({
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: historycolsDefs,
                    overlayNoRowsTemplate: 'No rows to show',
                    onCellClicked: this.viewForeCastDetails,
                    height: 200,
                },
                getDetailRowData: async function (param) {
                    param.successCallback([]);
                    await redirectURL.post("/forecast/getForecastingMaterialHistory", {
                        item_code: row.data.item_code
                    }).then(async (response) => {
                        param.successCallback(response.data);
                    }).catch(function (error) {
                        console.log(error);
                    })
                },
                masterDetail: true
            }
        });
        if (row.colDef.field == "viewdata") {
            row.node.setExpanded(!row.node.expanded);
        }
        else {
            row.node.setExpanded(false);
        }
    }
    runNewForeCast = async (row) => {
        this.setState({
            showSlideBlockUpload: "slide25",
            sliderType: 1,
            sliderTitle: "Run Forecast For Material-" + row.data.item_code,
            overlayBlock: "show-m",
            material_code: [{ "label": row.data.item_code, "value": row.data.item_code }]
        })
    }

    compareActualVsPredict = async (row) => {
        await this.setState({
            showSlideBlockUpload: "slide60",
            sliderType: 9,
            sliderTitle: "Comparison of Actual Vs Predict For Material-" + row.data.item_code,
            overlayBlock: "show-m",
            material_code: [{ "label": row.data.item_code, "value": row.data.item_code }],
            duration: { "label": '180 Days', "value": 180 }
        })
    }

    showMaterialInfo = async (row) => {
        this.setState({
            materialInfo: [],
            loadshow: 'show-m',
            overlayBlock: 'show-m',
        })
        await redirectURL.post("/forecast/getDataFromMaterialsMaster", {
            item_code: row.data.item_code
        }).then(async (response) => {
            if (response.data.length > 0) {
                this.setState({
                    showSlideBlockUpload: "slide25",
                    sliderType: 2,
                    sliderTitle: "Material Info",
                    loadshow: 'show-n',
                    overlayBlock: "show-m",
                    materialInfo: response.data
                })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
    downloadPredictionDaa = async (row) => {
        this.setState({
            downloadInfo: [],
            loadshow: 'show-m',
            overlayBlock: 'show-m',
        })
        await redirectURL.post("/forecast/getMaterialLatestTransactionDetails", {
            item_code: row.data.item_code
        }).then(async (response) => {
            if (response.data.length > 0) {
                var downloadInfo = [];
                // var months = ["Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
                var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                try {
                    var valuesinf = await groupBy(response.data, rdata => rdata.item_code);
                    // console.log("valuesinf ", valuesinf)
                    await valuesinf.forEach(async (values, vkeys) => {
                        var rw = {}
                        var exmons = [];
                        if (values.length > 0) {
                            rw["_id"] = values[0]._id;
                            rw["transaction_id"] = values[0].transaction_id;
                            rw["fy"] = values[0].fy;
                            rw["fy_start_year"] = values[0].fy_start_year;
                            rw["fy_end_year"] = values[0].fy_end_year;
                            rw["item_code"] = values[0].item_code;

                            values.map((i, n) => {
                                // rw[months[parseInt(i.month)-4]] = i.item_quantity;       
                                rw[months[parseInt(i.month) - 1]] = i.item_quantity;
                            })
                        }

                        if (exmons.length > 0) {
                            var diffkeys = arr_diff(exmons, months);
                            // console.log("diffkeys ", diffkeys)
                            if (diffkeys.length > 0) {
                                diffkeys.map((id) => {
                                    if (id !== "itemIndex") {
                                        rw[id] = 0;
                                    }
                                })
                            }
                        }
                        downloadInfo.push(rw);
                    })
                } catch (error) {

                }
                this.setState({
                    showSlideBlockUpload: "slide45",
                    sliderType: 3,
                    sliderTitle: "Download Material-" + row.data.item_code + " Data",
                    loadshow: 'show-n',
                    overlayBlock: "show-m",
                    transactionOutput: downloadInfo
                })
            } else {
                this.setState({
                    loadshow: 'show-n',
                    overlayBlock: 'show-n',
                    show: true,
                    basicTitle: 'No Prediction Data',
                    basicType: "warning",
                })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
    viewForeCastDetails = async (row) => {
        if (row.colDef.field === "view_trans_data") {
            if (row.data.status === "success") {
                // window.location.href="/forecast-output?"+params.data.transaction_id;
                // var months = ["Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
                var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                await redirectURL.post("/forecast/getforecastoutputdata", {
                    transaction_id: row.data.transaction_id,
                    item_code: row.data.item_code
                }).then(async (response) => {
                    var records = response.data;
                    var rowData = [];
                    // console.log("records ", records)
                    if (records.length > 0) {
                        try {
                            var valuesinf = await groupBy(records, rdata => rdata.item_code);
                            // console.log("valuesinf ", valuesinf)
                            await valuesinf.forEach(async (values, vkeys) => {
                                var rw = {}
                                var exmons = [];
                                if (values.length > 0) {
                                    rw["_id"] = values[0]._id;
                                    rw["transaction_id"] = values[0].transaction_id;
                                    rw["fy"] = values[0].fy;
                                    rw["fy_start_year"] = values[0].fy_start_year;
                                    rw["fy_end_year"] = values[0].fy_end_year;
                                    rw["item_code"] = values[0].item_code;

                                    values.map((i, n) => {
                                        // rw[months[parseInt(i.month)-4]] = i.item_quantity;                                        
                                        rw[months[parseInt(i.month) - 1]] = i.item_quantity;
                                    })
                                }

                                if (exmons.length > 0) {
                                    var diffkeys = arr_diff(exmons, months);
                                    // console.log("diffkeys ", diffkeys)
                                    if (diffkeys.length > 0) {
                                        diffkeys.map((id) => {
                                            if (id !== "itemIndex") {
                                                rw[id] = 0;
                                            }
                                        })
                                    }
                                }
                                rowData.push(rw);
                            })
                        } catch (error) {

                        }
                    }
                    this.setState({
                        showSlideBlockUpload: "slide45",
                        sliderType: 3,
                        sliderTitle: "Forecasting Summary",
                        loadshow: 'show-n',
                        overlayBlock: "show-m",
                        transactionOutput: rowData
                    })
                }).catch(function (error) {
                    console.log(error);
                })
            }
        }
        if (row.colDef.field === "data_visualization") {
            if (row.data.status === "success") {
                // window.location.href="/forecast-output?"+params.data.transaction_id;
                var months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
                await redirectURL.post("/forecast/getforecastoutputchartdata", {
                    transaction_id: row.data.transaction_id,
                    item_code: row.data.item_code
                }).then(async (response) => {
                    var records = response.data.predict;
                    var history = response.data.history;
                    var chartDataaa = response.data.chartData;

                    // chartDataaa = JSON.parse(mchartDataaa);
                    // console.log("chartDataaa ", chartDataaa)
                    var chartDatalist = [];
                    var chartCusts = [];
                    var chartItems = [];
                    var areaplot = [];
                    var areaYHat = [];
                    let foreCast_period = '';
                    var trendplot = [];
                    var areaScatter = [];
                    var confLineSeasonal = []
                    var trendingcost = []
                    if (chartDataaa.length > 0) {
                        chartDataaa.map((itm) => {
                            itm["chart_data"] = JSON.parse(itm.chart_data);
                            itm["month_data"] = JSON.parse(itm.month_data);

                            // if(itm.confidence_test_points !== undefined && itm.confidence_test_points !== "")
                            // {

                            // console.log("itm ", itm)
                            chartCusts.push({ customer: itm.customer_code });
                            chartItems.push({ itemcode: itm.item_code });
                            chartDatalist.push(itm)
                            // }

                        })
                    }
                    //    console.log("chartDatalist", chartDatalist)
                    if (chartDatalist.length > 0) {
                        var chartData = [chartDatalist[0]];
                        // console.log("chartData ", chartData)
                        if (chartData.length > 0) {
                            chartData.map((mm) => {
                                var chartSeries = mm.chart_data;
                                var motnhSeries = mm.month_data;
                                foreCast_period = mm.fc_period
                                if (chartSeries.length > 0) {
                                    chartSeries.map((mt) => {
                                        var tms = moment.parseZone(mt.ds).format("x");
                                        // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                                        areaplot.push(mt.area_plot);
                                        areaYHat.push((mt.yhat < 0) ? 0 : mt.yhat);
                                        trendingcost.push((mt.cost < 0) ? 0 : mt.cost)
                                        if (mt.ds !== "" && mt.ds !== undefined) {
                                            var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                            var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                            // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                            // console.log("mt.trend ", mt.trend )
                                            if (mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "") {
                                                if (parseInt(trnedns) < 2021) {
                                                    trendplot.push({ name: trnedns, y: mt.trend, lbl: Math.round((mt.trend < 0) ? 0 : mt.trend).toLocaleString() });
                                                }
                                            }
                                            confLineSeasonal.push({ name: tms / 1000, y: Math.round(mt.month_sum), lbl: Math.round(mt.month_sum).toLocaleString() });
                                        }
                                        if (mt.actual !== "" && mt.actual !== undefined && mt.actual !== null) {
                                            // if(mt.actual > 0)
                                            // {
                                            areaScatter.push(mt.actual);
                                            // }

                                        }
                                    })

                                }
                                if (motnhSeries.length > 0) {
                                    motnhSeries.map((mt) => {
                                        // confLineSeasonal.push({name:mt.month,y:Math.round(mt.month_sum),lbl:Math.round(mt.month_sum).toLocaleString()});


                                    })
                                }
                            })
                        }

                        this.setState({
                            chartCustomer: { value: chartData[0].customer_code, label: chartData[0].customer_code },
                            chartItem: { value: chartData[0].item_code, label: chartData[0].item_code }
                        })
                    }
                    else {
                        var chartData = [];
                    }
                    // console.log("confLineSeasonal ", confLineSeasonal)
                    // console.log("areaplot ", areaplot)
                    var monthsChart = ["04", "05", "06", "07", "08", "09", "10", "11", "12", "01", "02", "03"];
                    var monChart = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                    var confidenceTrain = [];
                    var confidenceTrend = [];
                    var confidenceTestPoints = [];
                    var confidencePredictPoint = [];
                    var seasonality_pred_plot = []
                    var seasonality_train_plot = [];
                    var allseason = [];

                    var rowdata = [];
                    var months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
                    // console.log("records ", records)
                    if (records.length > 0) {
                        var itmdata = groupBy(records, rdata => rdata.customer_code);
                        // console.log("itmdata ", itmdata)
                        try {
                            itmdata.forEach((valuesar, keys) => {
                                if (valuesar.length > 0) {
                                    var valuesinf = groupBy(valuesar, rdata => rdata.item_code);
                                    // console.log("valuesinf ", valuesinf)
                                    valuesinf.forEach((values, vkeys) => {
                                        var rw = {}
                                        var exmons = [];
                                        if (values.length > 0) {
                                            rw["_id"] = values[0]._id;
                                            rw["transaction_id"] = values[0].transaction_id;
                                            rw["fy"] = values[0].fy;
                                            rw["fy_start_year"] = values[0].fy_start_year;
                                            rw["fy_end_year"] = values[0].fy_end_year;
                                            rw["item_code"] = values[0].item_code;
                                            rw["customer_code"] = values[0].customer_code;
                                            rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
                                            rw["item_cost"] = values[0].iteminfo[0].item_cost

                                            values.map((i, n) => {
                                                rw[months[parseInt(i.month) - 1]] = i.item_quantity;
                                                rw[months[parseInt(i.month) - 1] + "_cost"] = parseInt(i.item_quantity) * (i.iteminfo[0].item_cost);
                                                exmons.push(months[parseInt(i.month) - 1]);

                                            })
                                            // console.log("RWW ", rw)

                                        }

                                        if (exmons.length > 0) {
                                            var diffkeys = arr_diff(exmons, months);
                                            // console.log("diffkeys ", diffkeys)
                                            if (diffkeys.length > 0) {
                                                diffkeys.map((id) => {
                                                    if (id !== "itemIndex") {
                                                        rw[id] = 0;
                                                        rw[id + "_cost"] = 0;
                                                    }
                                                })
                                            }
                                        }
                                        rowdata.push(rw);
                                    })
                                }

                            })
                        } catch (error) {

                        }
                    }

                    var chartseries = {};
                    if (rowdata.length > 0) {

                        var fywisedata = groupBy(rowdata, rdata => rdata.fy);
                        try {
                            var overallordersfy = [];
                            var overallcostfy = [];
                            var fynonths = []
                            var fynonthsCost = []
                            var historify = [];
                            fywisedata.forEach((values, keys) => {
                                var totlOrders = 0;
                                var totlCosts = 0;
                                // console.log("values",values)
                                if (values.length > 0) {
                                    var monthfy = [];
                                    var monthfyCost = [];
                                    var hmonthfy = []
                                    var pmonthfy = []
                                    var apr = 0;
                                    var may = 0;
                                    var jun = 0;
                                    var jul = 0;
                                    var aug = 0;
                                    var sep = 0;
                                    var oct = 0;
                                    var nov = 0;
                                    var dec = 0;
                                    var jan = 0;
                                    var feb = 0;
                                    var mar = 0;

                                    var apr_cost = 0;
                                    var may_cost = 0;
                                    var jun_cost = 0;
                                    var jul_cost = 0;
                                    var aug_cost = 0;
                                    var sep_cost = 0;
                                    var oct_cost = 0;
                                    var nov_cost = 0;
                                    var dec_cost = 0;
                                    var jan_cost = 0;
                                    var feb_cost = 0;
                                    var mar_cost = 0;

                                    values.map((item) => {
                                        totlOrders = parseInt(totlOrders) + parseInt(item.Apr) + parseInt(item.May) + parseInt(item.Jun) + parseInt(item.Jul) + parseInt(item.Aug) + parseInt(item.Sep) + parseInt(item.Oct) + parseInt(item.Nov) + parseInt(item.Dec) + parseInt(item.Jan) + parseInt(item.Feb) + parseInt(item.Mar);

                                        totlCosts = parseInt(totlCosts) + parseInt(item.Apr_cost) + parseInt(item.May_cost) + parseInt(item.Jun_cost) + parseInt(item.Jul_cost) + parseInt(item.Aug_cost) + parseInt(item.Sep_cost) + parseInt(item.Oct_cost) + parseInt(item.Nov_cost) + parseInt(item.Dec_cost) + parseInt(item.Jan_cost) + parseInt(item.Feb_cost) + parseInt(item.Mar_cost);
                                        apr = parseInt(apr) + parseInt(item.Apr);
                                        may = parseInt(may) + parseInt(item.May);
                                        jun = parseInt(jun) + parseInt(item.Jun);
                                        jul = parseInt(jul) + parseInt(item.Jul);
                                        aug = parseInt(aug) + parseInt(item.Aug);
                                        sep = parseInt(sep) + parseInt(item.Sep);
                                        oct = parseInt(oct) + parseInt(item.Oct);
                                        nov = parseInt(nov) + parseInt(item.Nov);
                                        dec = parseInt(dec) + parseInt(item.Dec);
                                        jan = parseInt(jan) + parseInt(item.Jan);
                                        feb = parseInt(feb) + parseInt(item.Feb);
                                        mar = parseInt(mar) + parseInt(item.Mar);


                                        apr_cost = parseInt(apr_cost) + parseInt(item.Apr_cost);
                                        may_cost = parseInt(may_cost) + parseInt(item.May_cost);
                                        jun_cost = parseInt(jun_cost) + parseInt(item.Jun_cost);
                                        jul_cost = parseInt(jul_cost) + parseInt(item.Jul_cost);
                                        aug_cost = parseInt(aug_cost) + parseInt(item.Aug_cost);
                                        sep_cost = parseInt(sep_cost) + parseInt(item.Sep_cost);
                                        oct_cost = parseInt(oct_cost) + parseInt(item.Oct_cost);
                                        nov_cost = parseInt(nov_cost) + parseInt(item.Nov_cost);
                                        dec_cost = parseInt(dec_cost) + parseInt(item.Dec_cost);
                                        jan_cost = parseInt(jan_cost) + parseInt(item.Jan_cost);
                                        feb_cost = parseInt(feb_cost) + parseInt(item.Feb_cost);
                                        mar_cost = parseInt(mar_cost) + parseInt(item.Mar_cost);

                                    })
                                    hmonthfy.push(apr);
                                    hmonthfy.push(may);
                                    hmonthfy.push(jun);
                                    hmonthfy.push(jul);
                                    hmonthfy.push(aug);
                                    hmonthfy.push(sep);
                                    hmonthfy.push(oct);
                                    hmonthfy.push(nov);
                                    hmonthfy.push(dec);
                                    hmonthfy.push(jan);
                                    hmonthfy.push(feb);
                                    hmonthfy.push(mar);
                                    monthfy.push({
                                        name: "Apr", y: apr
                                    }, {
                                        name: "May", y: may
                                    }, {
                                        name: "Jun", y: jun
                                    }, {
                                        name: "Jul", y: jul
                                    }, {
                                        name: "Aug", y: aug
                                    }, {
                                        name: "Sep", y: sep
                                    }, {
                                        name: "Oct", y: oct
                                    }, {
                                        name: "Nov", y: nov
                                    }, {
                                        name: "Dec", y: dec
                                    }, {
                                        name: "Jan", y: jan
                                    }, {
                                        name: "Feb", y: feb
                                    }, {
                                        name: "Mar", y: mar
                                    });

                                    monthfyCost.push({
                                        name: "Apr", y: apr_cost
                                    }, {
                                        name: "May", y: may_cost
                                    }, {
                                        name: "Jun", y: jun_cost
                                    }, {
                                        name: "Jul", y: jul_cost
                                    }, {
                                        name: "Aug", y: aug_cost
                                    }, {
                                        name: "Sep", y: sep_cost
                                    }, {
                                        name: "Oct", y: oct_cost
                                    }, {
                                        name: "Nov", y: nov_cost
                                    }, {
                                        name: "Dec", y: dec_cost
                                    }, {
                                        name: "Jan", y: jan_cost
                                    }, {
                                        name: "Feb", y: feb_cost
                                    }, {
                                        name: "Mar", y: mar_cost
                                    });

                                    overallordersfy.push({
                                        name: "FY - " + keys,
                                        y: totlOrders
                                    });
                                    overallcostfy.push({
                                        name: "FY - " + keys,
                                        y: totlCosts
                                    });
                                    fynonths.push({
                                        name: keys,
                                        data: monthfy,
                                        color: "#ff0000"
                                    });
                                    fynonthsCost.push({
                                        name: keys,
                                        data: monthfyCost,
                                        color: "#ff0000"
                                    });
                                    historify.push(hmonthfy)
                                }
                            })
                            // console.log("fynonths ", fynonths)
                            chartseries["poverallfinancialorders"] = { name: "Predict Orders", color: "#1ABC9C", data: overallordersfy }
                            chartseries["poverallfinancialcost"] = { name: "Predict Cost", color: "#1ABC9C", data: overallcostfy }
                            var selectedfymonths = fynonths.filter((f) => this.state.fyChart.value === f.name);
                            chartseries["pallfymonthwise"] = { name: "Predict Orders", color: "#1ABC9C", data: fynonths }
                            chartseries["pallfymonthwisecost"] = { name: "Predict Cost", color: "#1ABC9C", data: fynonthsCost }
                            chartseries["pselectedfymonths"] = selectedfymonths
                            chartseries["phistoric"] = historify
                        } catch (error) {

                        }
                        // item = item.sort(GetSortAscOrder("sequence_no"));
                    }

                    if (history.length > 0) {
                        var prowdata = [];
                        var hitmdata = groupBy(history, rdata => rdata.fy);
                        // console.log("hitmdata ", hitmdata)
                        try {
                            hitmdata.forEach((valuears, akeys) => {
                                if (valuears.length > 0) {
                                    var vData = groupBy(valuears, rdata => rdata.item_code);
                                    vData.forEach((values, keys) => {
                                        var rw = {}
                                        var exmons = [];
                                        if (values.length > 0) {
                                            rw["_id"] = values[0]._id;
                                            rw["transaction_id"] = values[0].transaction_id;
                                            rw["fy"] = values[0].fy;
                                            rw["fy_start_year"] = values[0].fy_start_year;
                                            rw["fy_end_year"] = values[0].fy_end_year;
                                            rw["item_code"] = values[0].item_code;
                                            rw["customer_code"] = values[0].customer_code;
                                            rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
                                            rw["item_cost"] = values[0].iteminfo[0].item_cost

                                            values.map((i, n) => {

                                                rw[months[parseInt(i.month) - 1]] = i.item_qty;
                                                rw[months[parseInt(i.month) - 1] + "_cost"] = parseInt(i.item_qty) * (i.item_cost);
                                                exmons.push(months[parseInt(i.month) - 1]);

                                            })
                                            // console.log("RWW ", rw)

                                        }

                                        if (exmons.length > 0) {
                                            var diffkeys = arr_diff(exmons, months);
                                            // console.log("diffkeys ", diffkeys)
                                            if (diffkeys.length > 0) {
                                                diffkeys.map((id) => {
                                                    if (id !== "itemIndex") {
                                                        rw[id] = 0;
                                                        rw[id + "_cost"] = 0;
                                                    }
                                                })
                                            }
                                        }
                                        prowdata.push(rw);
                                    })

                                }
                            })
                        } catch (error) {

                        }
                        // console.log('prowdata ', prowdata)
                        var hfywisedata = groupBy(prowdata, rdata => rdata.fy);
                        // console.log("hfywisedata ", hfywisedata)
                        try {
                            var hoverallordersfy = [];
                            var hoverallcostfy = [];
                            var hfynonths = []
                            var hfynonthsCost = [];
                            var hConfarea = [];
                            var hConfLine = [];
                            var hhistorify = [];
                            hfywisedata.forEach((values, keys) => {
                                var totlOrders = 0;
                                var totlCosts = 0;
                                // console.log("values",values)
                                if (values.length > 0) {
                                    var monthfy = [];
                                    var monthfyCost = [];
                                    var hmonthfy = []
                                    var pmonthfy = []
                                    var apr = 0;
                                    var may = 0;
                                    var jun = 0;
                                    var jul = 0;
                                    var aug = 0;
                                    var sep = 0;
                                    var oct = 0;
                                    var nov = 0;
                                    var dec = 0;
                                    var jan = 0;
                                    var feb = 0;
                                    var mar = 0;

                                    var apr_cost = 0;
                                    var may_cost = 0;
                                    var jun_cost = 0;
                                    var jul_cost = 0;
                                    var aug_cost = 0;
                                    var sep_cost = 0;
                                    var oct_cost = 0;
                                    var nov_cost = 0;
                                    var dec_cost = 0;
                                    var jan_cost = 0;
                                    var feb_cost = 0;
                                    var mar_cost = 0;

                                    values.map((item) => {
                                        totlOrders = parseInt(totlOrders) + parseInt(item.Apr) + parseInt(item.May) + parseInt(item.Jun) + parseInt(item.Jul) + parseInt(item.Aug) + parseInt(item.Sep) + parseInt(item.Oct) + parseInt(item.Nov) + parseInt(item.Dec) + parseInt(item.Jan) + parseInt(item.Feb) + parseInt(item.Mar);

                                        totlCosts = parseInt(totlCosts) + parseInt(item.Apr_cost) + parseInt(item.May_cost) + parseInt(item.Jun_cost) + parseInt(item.Jul_cost) + parseInt(item.Aug_cost) + parseInt(item.Sep_cost) + parseInt(item.Oct_cost) + parseInt(item.Nov_cost) + parseInt(item.Dec_cost) + parseInt(item.Jan_cost) + parseInt(item.Feb_cost) + parseInt(item.Mar_cost);
                                        apr = parseInt(apr) + parseInt(item.Apr);
                                        may = parseInt(may) + parseInt(item.May);
                                        jun = parseInt(jun) + parseInt(item.Jun);
                                        jul = parseInt(jul) + parseInt(item.Jul);
                                        aug = parseInt(aug) + parseInt(item.Aug);
                                        sep = parseInt(sep) + parseInt(item.Sep);
                                        oct = parseInt(oct) + parseInt(item.Oct);
                                        nov = parseInt(nov) + parseInt(item.Nov);
                                        dec = parseInt(dec) + parseInt(item.Dec);
                                        jan = parseInt(jan) + parseInt(item.Jan);
                                        feb = parseInt(feb) + parseInt(item.Feb);
                                        mar = parseInt(mar) + parseInt(item.Mar);

                                        apr_cost = parseInt(apr_cost) + parseInt(item.Apr_cost);
                                        may_cost = parseInt(may_cost) + parseInt(item.May_cost);
                                        jun_cost = parseInt(jun_cost) + parseInt(item.Jun_cost);
                                        jul_cost = parseInt(jul_cost) + parseInt(item.Jul_cost);
                                        aug_cost = parseInt(aug_cost) + parseInt(item.Aug_cost);
                                        sep_cost = parseInt(sep_cost) + parseInt(item.Sep_cost);
                                        oct_cost = parseInt(oct_cost) + parseInt(item.Oct_cost);
                                        nov_cost = parseInt(nov_cost) + parseInt(item.Nov_cost);
                                        dec_cost = parseInt(dec_cost) + parseInt(item.Dec_cost);
                                        jan_cost = parseInt(jan_cost) + parseInt(item.Jan_cost);
                                        feb_cost = parseInt(feb_cost) + parseInt(item.Feb_cost);
                                        mar_cost = parseInt(mar_cost) + parseInt(item.Mar_cost);

                                    })
                                    hmonthfy.push(apr);
                                    hmonthfy.push(may);
                                    hmonthfy.push(jun);
                                    hmonthfy.push(jul);
                                    hmonthfy.push(aug);
                                    hmonthfy.push(sep);
                                    hmonthfy.push(oct);
                                    hmonthfy.push(nov);
                                    hmonthfy.push(dec);
                                    hmonthfy.push(jan);
                                    hmonthfy.push(feb);
                                    hmonthfy.push(mar);
                                    monthfy.push({
                                        name: "Apr", y: apr
                                    }, {
                                        name: "May", y: may
                                    }, {
                                        name: "Jun", y: jun
                                    }, {
                                        name: "Jul", y: jul
                                    }, {
                                        name: "Aug", y: aug
                                    }, {
                                        name: "Sep", y: sep
                                    }, {
                                        name: "Oct", y: oct
                                    }, {
                                        name: "Nov", y: nov
                                    }, {
                                        name: "Dec", y: dec
                                    }, {
                                        name: "Jan", y: jan
                                    }, {
                                        name: "Feb", y: feb
                                    }, {
                                        name: "Mar", y: mar
                                    });

                                    monthfyCost.push({
                                        name: "Apr", y: apr_cost
                                    }, {
                                        name: "May", y: may_cost
                                    }, {
                                        name: "Jun", y: jun_cost
                                    }, {
                                        name: "Jul", y: jul_cost
                                    }, {
                                        name: "Aug", y: aug_cost
                                    }, {
                                        name: "Sep", y: sep_cost
                                    }, {
                                        name: "Oct", y: oct_cost
                                    }, {
                                        name: "Nov", y: nov_cost
                                    }, {
                                        name: "Dec", y: dec_cost
                                    }, {
                                        name: "Jan", y: jan_cost
                                    }, {
                                        name: "Feb", y: feb_cost
                                    }, {
                                        name: "Mar", y: mar_cost
                                    });

                                    hConfarea.push({
                                        name: "FY - " + keys,
                                        // data:[[apr_cost,may_cost],[may_cost,jun_cost],[jun_cost,jul_cost],[jul_cost,aug_cost],[aug_cost,sep_cost],[sep_cost,oct_cost],[oct_cost,nov_cost],[nov_cost,dec_cost],[dec_cost,jan_cost],[jan_cost,feb_cost],[feb_cost,mar_cost],[mar_cost,0]]
                                        data: [[apr_cost, mar_cost]]
                                    });
                                    hConfLine.push({
                                        name: "FY - " + keys,
                                        data: [apr_cost, may_cost, jun_cost, jul_cost, aug_cost, sep_cost, oct_cost, nov_cost, dec_cost, jan_cost, feb_cost, mar_cost]
                                    })
                                    // hConfarea.push({
                                    //     name:"FY - "+keys,
                                    //     data:[[apr,may],[may,jun],[jun,jul],[jul,aug],[aug,sep],[sep,oct],[oct,nov],[nov,dec],[dec,jan],[jan,feb],[feb,mar]]
                                    // });
                                    hoverallordersfy.push({
                                        name: "FY - " + keys,
                                        y: totlOrders
                                    });
                                    hoverallcostfy.push({
                                        name: "FY - " + keys,
                                        y: totlCosts
                                    });
                                    hfynonths.push({
                                        name: keys,
                                        data: monthfy
                                    });
                                    hfynonthsCost.push({
                                        name: keys,
                                        data: monthfyCost
                                    });
                                    hhistorify.push(hmonthfy)
                                }
                            })
                            // console.log("fynonths ", fynonths)
                            chartseries["hoverallfinancialorders"] = { name: "History", colorByPoint: true, data: hoverallordersfy }
                            chartseries["hoverallfinancialcost"] = { name: "History", colorByPoint: true, data: hoverallcostfy }
                            chartseries["hallfymonthwise"] = { name: "History", colorByPoint: true, data: hfynonths }
                            chartseries["hallfymonthwisecost"] = { name: "History", colorByPoint: true, data: hfynonthsCost }
                            chartseries["hhistoric"] = hhistorify
                        } catch (error) {

                        }
                        // item = item.sort(GetSortAscOrder("sequence_no"));
                    }

                    var newArray = [];
                    let totuniqueObject = {};
                    var totobjTitle;
                    // Loop for the array elements 
                    for (let i in chartCusts) {

                        // Extract the title
                        if (chartCusts[i]['customer'] !== "" && chartCusts[i]['customer'] !== undefined) {
                            totobjTitle = chartCusts[i]['customer'];

                            // Use the title as the index 
                            totuniqueObject[totobjTitle] = chartCusts[i];
                        }

                    }

                    // Loop to push unique object into array 
                    for (var a in totuniqueObject) {
                        newArray.push(totuniqueObject[a]);
                    }

                    var chartCustsarr = [];
                    if (newArray.length > 0) {
                        newArray.map((item) => {
                            chartCustsarr.push({
                                value: item.customer,
                                label: item.customer
                            })
                        })
                    }


                    var newArrayItem = [];
                    let totuniqueObjectItem = {};
                    var totobjTitle;
                    // Loop for the array elements 
                    for (let i in chartItems) {

                        // Extract the title
                        if (chartItems[i]['itemcode'] !== "" && chartItems[i]['itemcode'] !== undefined) {
                            totobjTitle = chartItems[i]['itemcode'];

                            // Use the title as the index 
                            totuniqueObjectItem[totobjTitle] = chartItems[i];
                        }

                    }

                    // Loop to push unique object into array 
                    for (var a in totuniqueObjectItem) {
                        newArrayItem.push(totuniqueObjectItem[a]);
                    }


                    var chartItemarr = [];
                    if (newArrayItem.length > 0) {
                        newArrayItem.map((item) => {
                            chartItemarr.push({
                                value: item.itemcode,
                                label: item.itemcode
                            })
                        })
                    }
                    // console.log("trendplot ", trendplot)
                    this.setState({
                        // chartseries:[chartseries],
                        confidenceTrain: confidenceTrain,
                        confidenceTrend: confidenceTrend,
                        confidenceTestPoints: confidenceTestPoints,
                        confidencePredictPoint: confidencePredictPoint,
                        seasonality_train_plot: seasonality_train_plot[seasonality_train_plot.length - 1],
                        seasonality_pred_plot: seasonality_pred_plot,
                        seasonality_train_plotdata: seasonality_train_plot,
                        allseason: allseason,
                        chartCusts: chartCustsarr,
                        chartItems: chartItemarr,
                        allChartData: chartDatalist,
                        confAreaData: areaplot,
                        foreCast_period: foreCast_period,
                        confAreaYHat: areaYHat,
                        confTrendplot: trendplot,
                        confScatter: areaScatter,
                        trendingcost: trendingcost,
                        confLineSeasonal: confLineSeasonal,
                        showSlideBlockUpload: "slide60",
                        sliderType: 4,
                        sliderTitle: "Data Visualization",
                        loadshow: 'show-n',
                        overlayBlock: 'show-m',
                    })
                    setTimeout(() => {
                        this.onClickShowCustomerData();
                    }, 500)
                }).catch(function (error) {
                    console.log(error);
                })
            }
        }
    }
    onChangeChartItem(chartItem) {
        this.setState({ chartItem })
    }
    onClickShowCustomerData() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });
        var customer = this.state.chartCustomer.value;
        var itemCode = this.state.chartItem.value;
        // console.log("customer ", customer)

        var chartDataarr = this.state.allChartData;
        var chartData = chartDataarr.filter((f) => (f.customer_code === customer && f.item_code === itemCode));
        // console.log("chartData ", chartDataarr)
        // console.log("chartDatachartData ", chartData)
        var chartDatalist = [];
        var chartCusts = [];
        var chartItems = [];
        var areaplot = [];
        var areaYHat = [];
        let foreCast_period = '';
        var trendplot = [];
        var areaScatter = [];
        var confLineSeasonal = []
        var trendingcost = []

        var monthsChart = ["04", "05", "06", "07", "08", "09", "10", "11", "12", "01", "02", "03"];
        var monChart = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        try {

            // if(chartData.length > 0)
            // {
            //     var confidence_train_points = [];
            //     var cn=0;
            //     if(chartData[0].confidence_train_points !== undefined)
            //     {
            //         if(chartData[0].confidence_train_points.length > 0)
            //         {
            //             chartData[0].confidence_train_points.map((itm,ind) => {
            //                 var d = itm[0]+"-"+monthsChart[ind]+"01 00:00";
            //                 var dt = moment.parseZone(new Date(d+":00")).utcOffset("+05:30")._d;
            //                 var timefrm = moment.parseZone(dt).format("x");
            //                 confidence_train_points.push([ind,parseInt(itm[1])]);

            //             })
            //         }
            //     }

            //     var trend_existing_points = [];
            //     if(chartData[0].trend_existing_points !== undefined)
            //     {
            //         chartData[0].trend_existing_points.map((itm,ind) => {
            //             trend_existing_points.push({
            //                 name: itm[0],
            //                 y:parseInt(itm[1])
            //             })
            //         })
            //     }


            //     var confidence_test_points = [];
            //     if(confidence_train_points.length > 0)
            //     {
            //         var ed =confidence_train_points[confidence_train_points.length-1][0];
            //         if(chartData[0].confidence_test_points !== undefined)
            //         {
            //             chartData[0].confidence_test_points.map((itm,ind) => {
            //                 confidence_test_points.push([parseInt(ed),parseInt(itm[1])]);
            //                 ed=ed+1;
            //             })
            //         }

            //     }

            //     // console.log("confidence_test_points ", confidence_test_points)
            //     var trend_predicted_points = [];
            //     if(trend_existing_points.length > 0)
            //     {
            //         // console.log("trend_existing_points[trend_existing_points.length-1] ", trend_existing_points[trend_existing_points.length-1])
            //         trend_predicted_points.push({name:trend_existing_points[trend_existing_points.length-1].name,y:trend_existing_points[trend_existing_points.length-1].y})

            //         trend_predicted_points.push({name:chartData[0].trend_predicted_points[0],y:parseInt(chartData[0].trend_predicted_points[1])});
            //     }
            //     // console.log("HEre trend_predicted_points", trend_predicted_points)
            //     var seasonality_train_plotar = [];
            //     // console.log('chartData[0].seasonality_train_plot ', chartData[0].seasonality_train_plot)
            //     if(chartData[0].seasonality_train_plot !== undefined)
            //     {
            //         chartData[0].seasonality_train_plot.map((itm) => {

            //             seasonality_train_plotar.push(JSON.parse(itm));
            //         })
            //     }

            //     // console.log("seasonality_train_plotar ", seasonality_train_plotar)
            //     var seasonality_train_plot = [];

            //     if(seasonality_train_plotar.length > 0)
            //     {
            //         var sdatatrne = groupBy(seasonality_train_plotar, rdata => rdata.year);
            //         try {
            //             // console.log("sdatatrne ", sdatatrne)

            //             sdatatrne.forEach((values,key) => {

            //                 var seasonality_train_plotd = [];
            //                 if(values.length > 0)
            //                 {
            //                     values.map((im) => {
            //                         // console.log("itm ", key+"===="+JSON.stringify(im))
            //                         // console.log("monChart[im.month-1] ", monChart[parseInt(im.month)-1])
            //                         var nm = monChart[parseInt(im.month)-1];
            //                         // console.log("nm ", nm)
            //                         seasonality_train_plotd.push({
            //                             name:monChart[parseInt(im.month)-1],
            //                             y:parseInt(im.item_qty)
            //                         })
            //                     })
            //                 }
            //                 // console.log("seasonality_train_plotd ",seasonality_train_plotd)
            //                 seasonality_train_plot.push({
            //                     name:key,
            //                     data:seasonality_train_plotd
            //                 })
            //             });
            //         } catch (error) {

            //         }
            //     }

            //     var seasonality_pred_plot = [];
            //     if(chartData[0].seasonality_pred_plot !== undefined)
            //     {
            //         var seasonality_pred_plotar = chartData[0].seasonality_pred_plot;
            //         if(seasonality_pred_plotar.length > 0){
            //             seasonality_pred_plotar.map((itm) => {
            //                 seasonality_pred_plot.push({
            //                     name:monChart[parseInt(itm.month)-1],
            //                     y:parseInt(itm.item_qty)
            //                 })
            //             })
            //         }

            //     }

            //     var confidenceTrain = confidence_train_points; 
            //     var confidenceTrend = [{name:"Trend Points",data:trend_existing_points},{name:"Predict",data:trend_predicted_points,color:"#FF0000"}];
            //     var confidenceTestPoints = confidence_test_points; 
            //     var confidencePredictPoint = trend_predicted_points; 
            //     var seasonality_pred_plot = {name:seasonality_pred_plotar[0].year,data:seasonality_pred_plot};
            //     // seasonality_train_plot = seasonality_train_plot;
            //     seasonality_train_plot.push(seasonality_pred_plot);


            //     // console.log("confidenceTrend ", confidenceTrend)
            // }
            // else{
            //     var confidenceTrain = []; 
            //     var confidenceTrend = [];
            //     var confidenceTestPoints = []; 
            //     var confidencePredictPoint = [];
            //     var seasonality_pred_plot = ""
            //     var seasonality_train_plot = "";
            // }

            if (chartData.length > 0) {
                chartData.map((mm) => {
                    var chartSeries = mm.chart_data;
                    var motnhSeries = mm.month_data;
                    foreCast_period = mm.fc_period
                    if (chartSeries.length > 0) {
                        chartSeries.map((mt) => {
                            var tms = moment.parseZone(mt.ds).format("x");
                            const originalDate = new Date(mt.ds);
                            originalDate.setDate(1);
                            originalDate.setHours(0, 0, 0, 0);
                            const modifiedDate = originalDate.toISOString()
                            // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                            areaplot.push([new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), ...mt.area_plot]);
                            areaYHat.push((mt.yhat < 0) ? [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), 0] : [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), parseInt(mt.yhat)]);
                            trendingcost.push((mt.cost < 0) ? [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), 0] : [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), mt.cost]);
                            if (mt.ds !== "" && mt.ds !== undefined) {
                                var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                if (mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "") {
                                    if (parseInt(trnedns) < 2022) {
                                        trendplot.push({ name: trnedns, y: mt.trend, lbl: Math.round((mt.trend < 0) ? 0 : mt.trend).toLocaleString() });
                                    }
                                }
                                // confLineSeasonal.push({name:tms/1000,y:Math.round(mt.yearly),lbl:Math.round(mt.yearly).toLocaleString()});
                                // confLineSeasonal.push([tms/1000,mt.yearly]);
                            }
                            if (mt.actual !== "" && mt.actual !== undefined && mt.actual !== null) {
                                // if(mt.actual > 0)
                                // {
                                areaScatter.push([new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), mt.actual]);
                                // }

                            }
                        })

                    }
                    if (motnhSeries.length > 0) {
                        motnhSeries.map((mt) => {
                            confLineSeasonal.push({ name: mt.month, y: Math.round(mt.month_sum), lbl: Math.round(mt.month_sum).toLocaleString() });


                        })
                    }
                })
            }
            // if(seasonality_train_plot.length > 0)
            // {
            //     var ss = seasonality_train_plot[seasonality_train_plot.length-1]
            // }
            // else{
            //     var ss = [];
            // }
        }
        catch (e) { }
        // console.log("confLineSeasonal ", confLineSeasonal)
        this.setState({
            // chartCusts:chartCustsarr,
            // chartItems:chartItemarr,
            // allChartData:chartDatalist,
            confAreaData: areaplot,
            confAreaYHat: areaYHat,
            confTrendplot: trendplot,
            confScatter: areaScatter,
            confLineSeasonal: confLineSeasonal,
            trendingcost: trendingcost,
            foreCast_period: foreCast_period
        })
    }
    dowloadAllForecastingData = async () => {
        this.setState({
            downloadInfo: [],
            loadshow: 'show-m',
            overlayBlock: 'show-m',
        })
        await redirectURL.post("/forecast/getAllForecastingData", {}).then(async (response) => {
            if (response.data.length > 0) {
                var downloadInfo = response.data;
                if (typeof downloadInfo == "string") {
                    downloadInfo = JSON.parse(response.data);
                }
                // console.log(downloadInfo,'down')
                this.setState({
                    showSlideBlockUpload: "slide45",
                    sliderType: 5,
                    sliderTitle: "Download Prediction Vs Actual Data",
                    loadshow: 'show-n',
                    overlayBlock: "show-m",
                    forecastingAllData: downloadInfo
                })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    showOnlyPredictionData = async () => {
        this.setState({
            loadshow: 'show-m',
            overlayBlock: 'show-m',
        })
        let downloadInfo = this.state.forecastingAllData

        if (downloadInfo.length > 0) {
            this.setState({
                showSlideBlockUpload: "slide45",
                sliderType: 6,
                sliderTitle: "Download Forecasting Data",
                loadshow: 'show-n',
                overlayBlock: "show-m",
                forecastingAllData: downloadInfo
            })
        } else {
            await redirectURL.post("/forecast/getAllForecastingData", {}).then(async (response) => {
                if (response.data.length > 0) {
                    var downloadInfo = response.data;
                    if (typeof downloadInfo == "string") {
                        downloadInfo = JSON.parse(response.data);
                    }
                    this.setState({
                        showSlideBlockUpload: "slide45",
                        sliderType: 6,
                        sliderTitle: "Download Forecasting Data",
                        loadshow: 'show-n',
                        overlayBlock: "show-m",
                        forecastingAllData: downloadInfo
                    })
                }
            }).catch(function (error) {
                console.log(error);
            })
        }

    }

    handleTriggerForecast = () => {
        this.setState({
            showSlideBlockUpload: "slide45",
            sliderType: 7,
            sliderTitle: "Forecast",
            overlayBlock: "show-m",
        })
    }


    openUploadOptionForAvailability = () => {
        // document.getElementById("bulkUploadForm").reset()
        // document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlockUpload: "slide25",
            sliderType: 8,
            sliderTitle: "Actual Data",
            overlayBlock: "show-m",
        })
    }

    hideSlideBlock = () => {

        if (document.getElementById("bulkUploadForm") != null) {
            document.getElementById("bulkUploadForm").reset()
            document.getElementById("inValidDataInfo").innerHTML = "";
        }

        if (document.getElementById('csvfile') != null) {
            document.getElementById("csvfile").value = ''
            document.getElementById("inValidDataInfo").innerHTML = "";
        }

        this.setState({
            overlayBlock: "show-n",
            showSlideBlockUpload: "",
            showSlideBlock: "",
            overly: "show-n",
            scenario: { "value": "", "label": "Select" }
        })
    }

    formCSVUpload = (event) => {

        event.preventDefault();
        var csvfiledata = this.state.csvfiledata;
        var p = {
            filedata: csvfiledata,

        }
        redirectURL.post("/forecast/uploadPredictMaterialData", p)
            .then(async (resp) => {

                if (resp.data.status == 'success') {
                    await this.setState({
                        // show:true,
                        // basicTitle:resp.data.message,
                        // basicType:"success",
                        overlayBlock: "show-n",
                        showSlideBlock: "",
                        showSlideBlockUpload: ''
                    })
                    // window.location.reload();
                    alert(resp.data.message)

                } else {
                    // this.setState({
                    //     show:true,
                    //     basicTitle:resp.data.message,
                    //     basicType:"danger"
                    // })
                    alert(resp.data.message)
                }
            })
    }


    changeCSVFileHandler = async (e) => {

        const config = {
            headers: [

                {
                    name: 'Item Code',
                    inputName: 'item_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'Quantity',
                    inputName: 'quantity',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'Actual Date',
                    inputName: 'posting_date',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

            ]
        }



        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                document.getElementById("inValidDataInfo").innerHTML = "";
                if (csvData.inValidData.length > 0) {
                    document.getElementById("bulkUploadBtn").type = "button"
                    document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                    document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                    let invalidData = csvData.inValidData;
                    let element = document.getElementById("inValidDataInfo")
                    invalidData.map(item => {
                        let row, column
                        if (item.rowIndex == undefined) {
                            row = 'NA'
                        } else {
                            row = item.rowIndex
                        }
                        if (item.columnIndex == undefined) {
                            column = 'NA'
                        } else {
                            column = item.columnIndex
                        }

                        element.innerHTML += "Column : " + column + "," + " Row : " + row + "--" + item.message + "<br></br>"
                    })

                } else {
                    document.getElementById("bulkUploadBtn").type = "submit"
                    document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                    document.getElementById("bulkUploadBtn").classList.add("btn-danger");
                    this.setState({
                        csvfiledata: csvData.data
                    });
                }
            })
            .catch(err => { })
    }

    onChangePredictionPhase = (phase) => {

        this.setState({ phase: phase })
    }
    onChangePredictionValidationPhase = (phase) => {

        this.setState({ predictionValidationPhase: phase })
    }

    predictionData = () => {
        this.showOnlyPredictionData()

        //     this.setState({
        //         showSlideBlockUpload:"slide25",
        //         sliderType:10,
        //         sliderTitle:"Download Prediction Data",
        //         loadshow:'show-n',
        //         overlayBlock:"show-m",
        //     })   
    }

    async showPredictionResultsData() {
        let params = {
            // start_date : "2020-01-01",
            // end_date : "2020-06-30"
            phase: this.state.phase.value,
            type: "ALL",
            phaseHalf: "ALL"
        }
        this.setState({ loadshow1: 'show-m', loadshow: "show-m", overlayBlock: "show-m" })
        await redirectURL.post("/forecast/getCompletePredictionData", params)
            .then(async response => {
                // if (response.data.length > 0) {
                let data = response.data
                console.log(data, "hyu")
                var prediction_data = JSON.parse(data.prediction_data)
                console.log(prediction_data, 'respo')
                var dataarr = []
                var underPredictedOutliersData = []
                var excessPredictedOutliersData = []
                var mcodes = [{ value: "", label: "All" }]
                if (prediction_data.length > 0) {
                    prediction_data.map((itm) => {
                        // if (itm.is_anamoly === 1) {
                        dataarr.push(itm);
                        mcodes.push({ value: itm.item_code, label: itm.item_code });
                        // console.log("itm ", itm);
                        // }
                        // else {

                        // }
                    })
                }
                console.log(dataarr, "hey show")
                dataarr = dataarr.filter((each) => (
                    each["material_classification"] !== "NON VED"
                ))
                dataarr = dataarr.map((each) => ({
                    ...each, popupText: "Double click to see"
                }))
                underPredictedOutliersData = dataarr.filter((each) => (
                    each.label === "Under"
                ))
                excessPredictedOutliersData = dataarr.filter((each) => (
                    each.label === "Excess"
                ))
                var newRowData = this.state.s2 === "btn-success" ? underPredictedOutliersData : excessPredictedOutliersData
                await this.setState({
                    rowData: newRowData,
                    underPredictedOutliersData: underPredictedOutliersData,
                    excessPredictedOutliersData: excessPredictedOutliersData,
                    rowDataPred: newRowData,
                    underSummaryData: JSON.parse(data.under_summary_data),
                    excessSummaryData: JSON.parse(data.excess_summary_data),
                    matCodesList: mcodes,
                    loadshow: "show-n",
                    loadshow1: 'show-n',
                    overly: 'show-n',
                    overlayBlock: "show-n"


                })
                // } else {
                //     // alert("Invalid Date Range/No Data Found")
                //     this.setState({
                //         loadshow1: 'show-n'
                //     })
                // }

            })
    }
    async showPrePredictionResultsData() {
        let analysis_type = this.state.s1 === "btn-success" ? "pre" : "post"
        let params = {
            phase: this.state.predictionValidationPhase.value,
            type: "ALL",
            analysis_type: analysis_type
        }
        this.setState({ loadshow1: 'show-m', loadshow: "show-m", overlayBlock: "show-m" })
        await redirectURL.post("/forecast/getCompletePrePredictionData", params)
            .then(async response => {
                if (response.status === 200) {
                    var data = response.data
                    // console.log(JSON.parse(data.complete_data), "new possible outlier")
                    if (analysis_type === "pre") {
                        this.setState({
                            complete_data: JSON.parse(data.complete_data),
                            initial_prediction_summary: JSON.parse(data.initial_prediction_summary),
                            loadshow: "show-n",
                            loadshow1: 'show-n',
                            overly: 'show-n',
                            overlayBlock: "show-n"
                        })
                    }
                    else {
                        this.setState({
                            complete_data: JSON.parse(data.complete_data),
                            validation_summary: JSON.parse(data.validation_summary),
                            loadshow: "show-n",
                            loadshow1: 'show-n',
                            overly: 'show-n',
                            overlayBlock: "show-n"
                        })
                    }
                }
                else {
                    // alert("Invalid Date Range/No Data Found")
                    this.setState({
                        loadshow1: 'show-n'
                    })
                }

            })
    }

    async getMaterialPatternChangeData() {
        this.setState({
            loadshow: "show-m", loadshow1: "show-m",
            overlayBlock: "show-m"
        })
        await redirectURL.post("/forecast/getMaterialPatternChangeData")
            .then(async response => {
                if (response.status === 200) {
                    var data = response.data
                    console.log(JSON.parse(data.material_moment_data), "wer")
                    this.setState({
                        materialPatternChangeData: JSON.parse(data.material_moment_data),
                        loadshow: "show-n",
                        loadshow1: "show-n",
                        overlayBlock: "show-n"
                    })

                }
                else {
                    // alert("Invalid Date Range/No Data Found")
                    this.setState({
                        loadshow1: 'show-n'
                    })
                }

            })
    }

    showTab(oVal) {

        this.setState({
            loadshow: "show-m"
        })
        if (oVal === 1) {
            this.setState({
                // rowData: this.state.anamolyRowData,
                loadshow: "show-n",
                s1: "btn-success",
                s2: "btn-default",
                s3: "btn-default",
                s4: "btn-default",
                s5: "btn-default",
                s6: "btn-default",
                s3tab: "show-n",
                // rowData: this.state.underPredictedOutliersData,
                // rowDataPred: this.state.underPredictedOutliersData
            }, this.showPrePredictionResultsData)
        }
        if (oVal === 2) {
            this.setState({
                // rowData: this.state.anamolyRowData,
                loadshow: "show-n",
                s1: "btn-default",
                s2: "btn-success",
                s3: "btn-default",
                s4: "btn-default",
                s5: "btn-default",
                s6: "btn-default",
                s3tab: "show-n",
                rowData: this.state.underPredictedOutliersData,
                rowDataPred: this.state.underPredictedOutliersData
            }, this.showPredictionResultsData)
        }
        if (oVal === 3) {
            this.setState({
                //rowData: this.state.rowDataPred,
                loadshow: "show-n",
                s1: "btn-default",
                s2: "btn-default",
                s3: "btn-success",
                s4: "btn-default",
                s5: "btn-default",
                s6: "btn-default",
                s3tab: "show-n",
                rowData: this.state.excessPredictedOutliersData,
                rowDataPred: this.state.excessPredictedOutliersData
            }, this.showPredictionResultsData)

        }
        if (oVal === 4) {
            this.setState({
                //rowData: this.state.rowDataPred,
                loadshow: "show-n",
                s1: "btn-default",
                s2: "btn-default",
                s3: "btn-default",
                s4: "btn-success",
                s5: "btn-default",
                s6: "btn-default",
                s3tab: "show-m"
            })

        }
        if (oVal === 5) {
            this.setState({
                //rowData: this.state.rowDataPred,
                loadshow: "show-n",
                s1: "btn-default",
                s2: "btn-default",
                s3: "btn-default",
                s4: "btn-default",
                s5: "btn-success",
                s6: "btn-default",
                s3tab: "show-n"
            }, this.showPrePredictionResultsData)

        }
        if (oVal === 6) {
            this.setState({
                //rowData: this.state.rowDataPred,
                loadshow: "show-n",
                s1: "btn-default",
                s2: "btn-default",
                s3: "btn-default",
                s4: "btn-default",
                s5: "btn-default",
                s6: "btn-success",
                s3tab: "show-n"
            }, this.getMaterialPatternChangeData)

        }
    }

    onChangePredictionPhase = (phase) => {

        this.setState({ phase: phase })
    }


    onChangeDuration(duration) {
        this.setState({ duration })
    }

    setStartDate = (date) => {
        this.setState({ startDate: date })
    }
    setEndDate = (date) => {
        this.setState({ endDate: date })
    }

    onChangeCategory(category) {
        this.setState({ category }, () => {
            // console.log("this.state.category.value", this.state.category)
            let forecastitemVal = this.state.mat_itm_code.value

            var category = this.state.category.value;
            if (this.state.rowDataPred.length > 0) {
                let filteredData = this.state.rowDataPred.filter(item => {

                    if (category != "") {
                        if (item.item_code == forecastitemVal && item.category == category) {
                            return true
                        } else {
                            if (item.category == category) {
                                return true
                            }
                            else {
                                return false;
                            }
                        }
                    }
                    else {
                        return true;
                    }
                })
                this.setState({
                    rowData: filteredData
                })
            }


        })
    }
    // getCompletePredictionData = (params) => {

    //     this.setState({ loadshow: 'show-m' })
    //     redirectURL.post("/forecast/getCompletePredictionData", params)
    //         .then(async response => {
    //             if (response.data.length > 0) {
    //                 let data = response.data
    //                 console.log(response.data, 'respo')
    //                 var dataarr = []
    //                 var underPredictedOutliersData = []
    //                 var excessPredictedOutliersData = []
    //                 if (data.length > 0) {
    //                     data.map((itm) => {
    //                         // console.log("itm.is_anamoly ",itm.is_anamoly)
    //                         // if(itm.is_anamoly !== undefined && itm.is_anamoly !== "" && itm.is_anamoly !== null && parseInt(itm.is_anamoly) !== 1)
    //                         if (parseInt(itm.is_anamoly) === 1) {

    //                             dataarr.push(itm);
    //                             // console.log("itm ", itm);
    //                         }
    //                         else {
    //                         }
    //                     })
    //                 }
    //                 underPredictedOutliersData = dataarr.filter((each) => (
    //                     each.category === "Under"
    //                 ))
    //                 excessPredictedOutliersData = dataarr.filter((each) => (
    //                     each.category === "Excess"
    //                 ))
    //                 await this.setState({
    //                     rowData: dataarr,
    //                     loadshow: 'show-n'
    //                 })
    //             } else {
    //                 alert("Invalid Date Range/No Data Found")
    //                 this.setState({
    //                     loadshow: 'show-n'
    //                 })
    //             }

    //         })
    // }


    getPhaseWiseData = () => {
        let start_date = moment.parseZone(new Date((this.state.startDate))).format("YYYY-MM-DD")
        let end_date = moment.parseZone(new Date((this.state.endDate))).format("YYYY-MM-DD")
        let duration = this.state.duration.value

        let params = {
            // start_date : start_date,
            // end_date : end_date
            phase: this.state.phase.value,
            type: "ALL"
        }
        this.showPredictionResultsData()

    }

    getPredictionValidationData = () => {
        this.showPrePredictionResultsData()

    }

    getPageCounterFromChildComponent(counterData) {

        this.setState({
            outlier_actual_cost: counterData.actual_cost,
            outlier_cost: counterData.predicted_cost,
            outlier_actual_count: counterData.actual_count,
            outlier_count: counterData.predicted_count,
            loadshow1: 'show-n'
        })
    }
    onChangeOutlierPeriod(outlier_period) {
        this.setState({
            outlier_period
        })
    }

    onChangePhase(phase) {
        this.setState({ phase })
        this.setState({ chartPhase: phase })
    }
    onChangeChartPhase(chartPhase) {
        this.setState({ chartPhase })
    }
    onClickShowClusterRouteOnMap(rownode) {
        // console.log("row ", rownode)
        if (rownode.colDef.field === "item_code") {
            this.setState({
                chartItemCode: rownode.data.item_code,
                showChart: true
            })
        }
    }
    onClickingTableView = () => {
        this.setState({ showChart: false, chartPhase: this.state.initialPhase })
    }
    render() {
        var templatePath = require('../../assets/json/material_data_template.csv');
        let transactionMonth = this.state.transactionOutput

        let showJan = false;
        let showFeb = false;
        let showMar = false;
        let showApr = false;
        let showMay = false;
        let showJun = false;
        let showJul = false;
        let showAug = false;
        let showSep = false;
        let showOct = false;
        let showNov = false;
        let showDec = false;

        var columnwithPredDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                tooltipField: "popupText",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Description",
                field: "item_description",
                width: 260,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
              headerName: "Material Part Type",
              field: "material_part_type",
              width: 198,
              filter: true,
              resizable: true,
              editable: false,
              headerClass: ["column-header-style"],
            },
            {
            headerName: "Material Department",
            field: "material_department",
            width: 198,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"],
            },
            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Prediction Category",
                field: "label",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Predicted Quantity",
                field: "predicted_quantity",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
                // valueGetter: function (params) {
                //     try {
                //         if (params.data.predicted_quantity !== "" && params.data.predicted_quantity !== undefined) {
                //             return params.data.predicted_quantity + " ( " + params.data.predicted_cost + " )"
                //         }
                //     }
                //     catch (e) {

                //     }
                // },
            },
            {
                headerName: "Predicted Cost ( $ )",
                field: "predicted_cost",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Actual Quantity",
                field: "actual_quantity",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
                // valueGetter: function (params) {
                //     try {
                //         if (params.data.actual_quantity !== "" && params.data.actual_quantity !== undefined) {
                //             if (params.data.actual_quantity == null) {
                //                 return ''
                //             } else {
                //                 return params.data.actual_quantity + " ( " + params.data.actual_cost + " )"
                //             }

                //         }
                //     }
                //     catch (e) {

                //     }
                // },
            },
            {
                headerName: "Actual Cost ( $ )",
                field: "actual_cost",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Unit Cost ( $ )",
                field: "unit_cost",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Forecast Horizon(months)",
                field: "fc_period",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Cost Difference (Predicted - Actual)",
                field: "cost_difference",
                width: 200,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },

        ]

        if (transactionMonth.length > 0) {
            if (transactionMonth[0].Jan === "" || transactionMonth[0].Jan === undefined) {
                showJan = true
            }
            if (transactionMonth[0].Feb === "" || transactionMonth[0].Feb === undefined) {
                showFeb = true
            }
            if (transactionMonth[0].Mar === "" || transactionMonth[0].Mar === undefined) {
                showMar = true
            }
            if (transactionMonth[0].Apr === "" || transactionMonth[0].Apr === undefined) {
                showApr = true
            }
            if (transactionMonth[0].May === "" || transactionMonth[0].May === undefined) {
                showMay = true
            }
            if (transactionMonth[0].Jun === "" || transactionMonth[0].Jun === undefined) {
                showJun = true
            }
            if (transactionMonth[0].Jul === "" || transactionMonth[0].Jul === undefined) {
                showJul = true
            }
            if (transactionMonth[0].Aug === "" || transactionMonth[0].Aug === undefined) {
                showAug = true
            }
            if (transactionMonth[0].Sep === "" || transactionMonth[0].Sep === undefined) {
                showSep = true
            }
            if (transactionMonth[0].Oct === "" || transactionMonth[0].Oct === undefined) {
                showOct = true
            }
            if (transactionMonth[0].Nov === "" || transactionMonth[0].Nov === undefined) {
                showNov = true
            }
            if (transactionMonth[0].Dec === "" || transactionMonth[0].Dec === undefined) {
                showDec = true
            }
        }
        var columnwithmaterialpatterndata = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Description",
                field: "material_description",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Part Type",
                field: "material_part_type",
                width: 198,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
              },
              {
              headerName: "Material Department",
              field: "material_department",
              width: 198,
              filter: true,
              resizable: true,
              editable: false,
              headerClass: ["column-header-style"],
              },
            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "2020-Jan-Jun",
                field: "2020-Jan-Jun",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellClass: function (params) {
                    try {
                        if (params.data["2020-Jan-Jun"] != undefined || params.data["2020-Jan-Jun"] != "") {
                            if (params.data["2020-Jan-Jun"] === "Never Consumed - NC") {
                                return ["nsaibg"]
                            } else if (params.data["2020-Jan-Jun"] === "No Consumption for past 3 years - NC3Y") {
                                return ["pobg"]
                            } else if (params.data["2020-Jan-Jun"] === "AI Predicted items - S-AI") {
                                return ["s-aibg"]
                            }
                            else if (params.data["2020-Jan-Jun"] === "Exact prediction - ETP") {
                                return ["etpbg"]
                            } else if (params.data["2020-Jan-Jun"] === "Excess prediction - ESP") {
                                return ["espbg"]
                            }
                            else {
                                return ["upbg"]
                            }
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "2020-Jul-Dec",
                field: "2020-Jul-Dec",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellClass: function (params) {
                    try {
                        if (params.data["2020-Jul-Dec"] != undefined || params.data["2020-Jul-Dec"] != "") {
                            if (params.data["2020-Jul-Dec"] === "Never Consumed - NC") {
                                return ["nsaibg"]
                            } else if (params.data["2020-Jul-Dec"] === "No Consumption for past 3 years - NC3Y") {
                                return ["pobg"]
                            } else if (params.data["2020-Jul-Dec"] === "AI Predicted items - S-AI") {
                                return ["s-aibg"]
                            }
                            else if (params.data["2020-Jul-Dec"] === "Exact prediction - ETP") {
                                return ["etpbg"]
                            } else if (params.data["2020-Jul-Dec"] === "Excess prediction - ESP") {
                                return ["espbg"]
                            }
                            else {
                                return ["upbg"]
                            }
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "2021-Jan-Jun",
                field: "2021-Jan-Jun",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellClass: function (params) {
                    try {
                        if (params.data["2021-Jan-Jun"] != undefined || params.data["2021-Jan-Jun"] != "") {
                            if (params.data["2021-Jan-Jun"] === "Never Consumed - NC") {
                                return ["nsaibg"]
                            } else if (params.data["2021-Jan-Jun"] === "No Consumption for past 3 years - NC3Y") {
                                return ["pobg"]
                            } else if (params.data["2021-Jan-Jun"] === "AI Predicted items - S-AI") {
                                return ["s-aibg"]
                            }
                            else if (params.data["2021-Jan-Jun"] === "Exact prediction - ETP") {
                                return ["etpbg"]
                            } else if (params.data["2021-Jan-Jun"] === "Excess prediction - ESP") {
                                return ["espbg"]
                            }
                            else {
                                return ["upbg"]
                            }
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "2021-Jul-Dec",
                field: "2021-Jul-Dec",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellClass: function (params) {
                    try {
                        if (params.data["2021-Jul-Dec"] != undefined || params.data["2021-Jul-Dec"] != "") {
                            if (params.data["2021-Jul-Dec"] === "Never Consumed - NC") {
                                return ["nsaibg"]
                            } else if (params.data["2021-Jul-Dec"] === "No Consumption for past 3 years - NC3Y") {
                                return ["pobg"]
                            } else if (params.data["2021-Jul-Dec"] === "AI Predicted items - S-AI") {
                                return ["s-aibg"]
                            }
                            else if (params.data["2021-Jul-Dec"] === "Exact prediction - ETP") {
                                return ["etpbg"]
                            } else if (params.data["2021-Jul-Dec"] === "Excess prediction - ESP") {
                                return ["espbg"]
                            }
                            else {
                                return ["upbg"]
                            }
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "2022-Jan-Jun",
                field: "2022-Jan-Jun",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellClass: function (params) {
                    try {
                        if (params.data["2022-Jan-Jun"] != undefined || params.data["2022-Jan-Jun"] != "") {
                            if (params.data["2022-Jan-Jun"] === "Never Consumed - NC") {
                                return ["nsaibg"]
                            } else if (params.data["2022-Jan-Jun"] === "No Consumption for past 3 years - NC3Y") {
                                return ["pobg"]
                            } else if (params.data["2022-Jan-Jun"] === "AI Predicted items - S-AI") {
                                return ["s-aibg"]
                            }
                            else if (params.data["2022-Jan-Jun"] === "Exact prediction - ETP") {
                                return ["etpbg"]
                            } else if (params.data["2022-Jan-Jun"] === "Excess prediction - ESP") {
                                return ["espbg"]
                            }
                            else {
                                return ["upbg"]
                            }
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "2022-Jul-Dec",
                field: "2022-Jul-Dec",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellClass: function (params) {
                    try {
                        if (params.data["2022-Jul-Dec"] != undefined || params.data["2022-Jul-Dec"] != "") {
                            if (params.data["2022-Jul-Dec"] === "Never Consumed - NC") {
                                return ["nsaibg"]
                            } else if (params.data["2022-Jul-Dec"] === "No Consumption for past 3 years - NC3Y") {
                                return ["pobg"]
                            } else if (params.data["2022-Jul-Dec"] === "AI Predicted items - S-AI") {
                                return ["s-aibg"]
                            }
                            else if (params.data["2022-Jul-Dec"] === "Exact prediction - ETP") {
                                return ["etpbg"]
                            } else if (params.data["2022-Jul-Dec"] === "Excess prediction - ESP") {
                                return ["espbg"]
                            }
                            else {
                                return ["upbg"]
                            }
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "2023-Apr-Sep",
                field: "2023-Apr-Sep",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellClass: function (params) {
                    try {
                        if (params.data["2023-Apr-Sep"] != undefined || params.data["2023-Apr-Sep"] != "") {
                            if (params.data["2023-Apr-Sep"] === "Never Consumed - NC") {
                                return ["nsaibg"]
                            } else if (params.data["2023-Apr-Sep"] === "No Consumption for past 3 years - NC3Y") {
                                return ["pobg"]
                            } else if (params.data["2023-Apr-Sep"] === "AI Predicted items - S-AI") {
                                return ["s-aibg"]
                            }
                            else if (params.data["2023-Apr-Sep"] === "Exact prediction - ETP") {
                                return ["etpbg"]
                            } else if (params.data["2023-Apr-Sep"] === "Excess prediction - ESP") {
                                return ["espbg"]
                            }
                            else {
                                return ["upbg"]
                            }
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "2023-Jan-Jun",
                field: "2023-Jan-Jun",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellClass: function (params) {
                    try {
                        if (params.data["2023-Jan-Jun"] != undefined || params.data["2023-Jan-Jun"] != "") {
                            if (params.data["2023-Jan-Jun"] === "Never Consumed - NC") {
                                return ["nsaibg"]
                            } else if (params.data["2023-Jan-Jun"] === "No Consumption for past 3 years - NC3Y") {
                                return ["pobg"]
                            } else if (params.data["2023-Jan-Jun"] === "AI Predicted items - S-AI") {
                                return ["s-aibg"]
                            }
                            else if (params.data["2023-Jan-Jun"] === "Exact prediction - ETP") {
                                return ["etpbg"]
                            } else if (params.data["2023-Jan-Jun"] === "Excess prediction - ESP") {
                                return ["espbg"]
                            }
                            else {
                                return ["upbg"]
                            }
                        }
                    } catch (error) {

                    }
                }
            }
        ]

        var columnwithDefsAnmoly = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 140,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Material Description",
                field: "item_description",
                width: 260,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Part Type",
                field: "material_part_type",
                width: 198,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
            },
            {
              headerName: "Material Department",
              field: "material_department",
              width: 198,
              filter: true,
              resizable: true,
              editable: false,
              headerClass: ["column-header-style"],
            },
            {
                headerName: "Material Cost ( $ ) ",
                field: "cost_in_USD_per_item",
                width: 140,
                filter: true,
                resizable: true,
                editable: false
            },

            /* {
                 headerName: "Scenario",
                 field: "scenario_type",
                 width:140,
                 filter: true,
                 resizable: true,
                 editable:false
             },
             {
                 headerName: "Scenario Description",
                 field: "scenario_description",
                 width:140,
                 filter: true,
                 resizable: true,
                 editable:false
             },*/
            // {
            //     headerName: "No. of Predictions Performed",
            //     field: "no_of_predicts",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Recent Best Model",
            //     field: "best_model",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Created Date",
            //     field: "created_date",
            //     width:140,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         try {
            //             if(params.data.created_date !== "" && params.data.created_date !== undefined)
            //             {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.created_date);
            //             }
            //             else{
            //                 return "";
            //             }
            //         } catch (error) {

            //         }
            //     }
            // },
            {
                headerName: "Forecasting History",
                field: "viewdata",
                width: 150,
                params: { buttonName: 'View History', onClickFunction: this.showMaterialForecastHistory, iconName: "fa fa-eye", btnClass: "btn btn-secondary" },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                },
            },
            // {
            //     headerName: "New Forecast",
            //     field: "runnewforecast",
            //     width:130,
            //     params:{buttonName:'Run',onClickFunction:this.runNewForeCast,iconName:"fa fa-line-chart",btnClass:"btn btn-info",btnColor:{color:"#fff",border:"0px"}},
            //     cellRendererSelector:function(params){
            //         var rendComponent = {
            //             component: "GridButton"
            //         };
            //         return rendComponent
            //     },
            // },
            {
                headerName: "Compare Actual Vs Predict",
                field: "compareactualvspredict",
                width: 130,
                params: { buttonName: 'Compare', onClickFunction: this.compareActualVsPredict, iconName: "fa fa-line-chart", btnClass: "btn btn-info", btnColor: { color: "#fff", border: "0px" } },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                },
            },
            // {
            //     headerName: "Material Info",
            //     field: "materialinfo",
            //     width:130,
            //     params:{buttonName:'View Info',onClickFunction:this.showMaterialInfo,iconName:"fa fa-eye",btnClass:"btn btn-primary",btnColor:{color:"#fff",border:"0px"}},
            //     cellRendererSelector:function(params){
            //         var rendComponent = {
            //             component: "GridButton"
            //         };
            //         return rendComponent
            //     },
            // },
            {
                headerName: "Prediction Data",
                field: "predictiondata",
                width: 140,
                params: { buttonName: 'Download', onClickFunction: this.downloadPredictionDaa, iconName: "fa fa-download", btnClass: "btn btn-success", btnColor: { color: "#fff", border: "0px" } },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                },
            },
        ]

        var columnwithcompletedata = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                tooltipField: "popupText",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Description",
                field: "item_description",
                width: 260,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]

            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Part Type",
                field: "material_part_type",
                width: 198,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
            },
            {
              headerName: "Material Department",
              field: "material_department",
              width: 198,
              filter: true,
              resizable: true,
              editable: false,
              headerClass: ["column-header-style"],
            },
            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]

            },
            {
                headerName: "Predicted Quantity",
                field: "predicted_quantity",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]

                // valueGetter:function(params){
                //     try {
                //         if(params.data.predicted_quantity !== "" && params.data.predicted_quantity !== undefined)
                //         {
                //             return params.data.predicted_quantity +" ( "+params.data.predicted_cost+" )"
                //         }
                //     }
                //     catch(e){

                //     }
                // },
            },
            {
                headerName: "Predicted Cost ( $ )",
                field: "predicted_cost",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]

                // valueGetter:function(params){
                //     try {
                //         if(params.data.actual_quantity !== "" && params.data.actual_quantity !== undefined)
                //         {
                //             if(params.data.actual_quantity == null){
                //                 return ''
                //             }else{
                //                 return params.data.actual_quantity +" ( "+params.data.actual_cost+" )"
                //             }

                //         }
                //     }
                //     catch(e){

                //     }
                // },
            },
            {
                headerName: "Prediction Category",
                field: "label",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]

            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]

            },
            {
                headerName: "Unit Cost ( $ )",
                field: "unit_cost",
                width: 160,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]

            },
            {
                headerName: "Forecast Horizon(months)",
                field: "fc_period",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]

            },
            {
                headerName: "Actual Quantity",
                field: "actual_quantity",
                width: 240,
                filter: true,
                resizable: true,
                editable: false,
                hide: true,
                headerClass: ["column-header-style"]
                // valueGetter:function(params){
                //     try {
                //         if(params.data.predicted_quantity !== "" && params.data.predicted_quantity !== undefined)
                //         {
                //             return params.data.predicted_quantity +" ( "+params.data.predicted_cost+" )"
                //         }
                //     }
                //     catch(e){

                //     }
                // },
            },
            {
                headerName: "Actual Cost  ( $ )",
                field: "actual_cost",
                width: 200,
                filter: true,
                resizable: true,
                editable: false,
                hide: true,
                headerClass: ["column-header-style"]
                // valueGetter:function(params){
                //     try {
                //         if(params.data.actual_quantity !== "" && params.data.actual_quantity !== undefined)
                //         {
                //             if(params.data.actual_quantity == null){
                //                 return ''
                //             }else{
                //                 return params.data.actual_quantity +" ( "+params.data.actual_cost+" )"
                //             }

                //         }
                //     }
                //     catch(e){

                //     }
                // },
            },
            {
                headerName: "Cost Difference (Predicted - Actual)",
                field: "cost_difference",
                width: 200,
                filter: true,
                resizable: true,
                editable: false,
                hide: true,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Quantity Difference",
                field: "quantity_difference",
                width: 200,
                filter: true,
                resizable: true,
                editable: false,
                hide: true,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Quantity Difference Range",
                field: "quantity_difference_range",
                width: 200,
                filter: true,
                resizable: true,
                editable: false,
                hide: true,
                headerClass: ["column-header-style"]
            },
            // {
            //     headerName: "Category",
            //     field: "category",
            //     width: 160,
            //     filter: true,
            //     resizable: true,
            //     editable: false,
            // },
            // {
            //     headerName: "Insufficient Data for Prediction",
            //     field: "less_consumption",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            // },
        ]

        if (this.state.s1 === "btn-success" || this.state.s5 === "btn-success") {
            var columnwithDefs = columnwithcompletedata;
        }
        else {
            var columnwithDefs = columnwithPredDefs
        }
        var columnwithDefsForTransactionOutput = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            // {
            //     headerName: "Financial Year",
            //     field: "fy",
            //     width:120,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "Period-1",
                field: "Jan",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showJan
            },
            {
                headerName: "Period-2",
                field: "Feb",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showFeb
            },
            {
                headerName: "Period-3",
                field: "Mar",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showMar
            },
            {
                headerName: "Period-4",
                field: "Apr",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showApr
            },
            {
                headerName: "Period-5",
                field: "May",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showMay
            },
            {
                headerName: "Period-6",
                field: "Jun",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showJun
            },
            {
                headerName: "Period-7",
                field: "Jul",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showJul
            },
            {
                headerName: "Period-8",
                field: "Aug",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showAug
            },
            {
                headerName: "Period-9",
                field: "Sep",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showSep
            },
            {
                headerName: "Period-10",
                field: "Oct",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showOct
            },
            {
                headerName: "Period-11",
                field: "Nov",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showNov
            },
            {
                headerName: "Period-12",
                field: "Dec",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                hide: showDec
            },
        ]
        var columnwithDefsForAllForecastData = [
            {
                headerName: "Material Code",
                field: "Material Code",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Forecast Horizon",
                field: "Forecast Horizon",
                width: 140,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Best Model",
                field: "Best Model",
                width: 140,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-1",
                field: "Period-1",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-2",
                field: "Period-2",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-3",
                field: "Period-3",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-4",
                field: "Period-4",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-5",
                field: "Period-5",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-6",
                field: "Period-6",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-7",
                field: "Period-7",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-8",
                field: "Period-8",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-9",
                field: "Period-9",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-10",
                field: "Period-10",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-11",
                field: "Period-11",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-12",
                field: "Period-12",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Cost per Unit ( $ )",
                field: "Cost per Unit($)",
                width: 180,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Actual Cost ( $ )",
                field: "Actual Cost($)",
                width: 180,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Total Cost ( $ )",
                field: "Total Cost($)",
                width: 180,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Cost Difference ( $ )",
                field: "Cost Difference",
                width: 180,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Actual Consumption Quantity",
                field: "Actual Consumption Quantity",
                width: 180,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Total Predictions",
                field: "Total Predictions",
                width: 180,
                filter: true,
                resizable: true,
                editable: false
            },
        ]

        var columnwithDefsForAllForecastDataOnlyPredicted = [
            {
                headerName: "Material Code",
                field: "Material Code",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Forecast Horizon",
                field: "Forecast Horizon",
                width: 140,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Best Model",
                field: "Best Model",
                width: 140,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-1",
                field: "Period-1",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-2",
                field: "Period-2",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-3",
                field: "Period-3",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-4",
                field: "Period-4",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-5",
                field: "Period-5",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-6",
                field: "Period-6",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-7",
                field: "Period-7",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-8",
                field: "Period-8",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-9",
                field: "Period-9",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-10",
                field: "Period-10",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-11",
                field: "Period-11",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Period-12",
                field: "Period-12",
                width: 120,
                filter: true,
                resizable: true,
                editable: false
            },
        ]
        const checkData = this.state.s5 === "btn-success"
        console.log(checkData, "checkData")
        if (checkData) {
            columnwithcompletedata[9].hide = false;
            columnwithcompletedata[10].hide = false;
            columnwithcompletedata[11].hide = false;
            columnwithcompletedata[12].hide = false;
            columnwithcompletedata[13].hide = false;
        }
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                fontWeight: 'bold',
                fontSize: "14px",
                color: "black" // Set font-weight to bold for selected options
            }),
            singleValue: (provided, state) => ({
                ...provided,
                fontSize: "14px",
                fontWeight: 'bold', // Always display the selected option in bold
                color: "black"
            }),
        };
        const applyMediaQueryStyles = () => {
            if (window.innerWidth >= 1920) {
                customStyles.option = (provided, state) => ({
                    ...provided,
                    fontSize: "17px",
                    fontWeight: 'bold', // Change font size for 1920px screens
                    color: "black",
                });

                customStyles.singleValue = (provided, state) => ({
                    ...provided,
                    fontSize: "17px",
                    fontWeight: 'bold', // Change font size for 1920px screens
                    color: "black",
                });
                customStyles.control = (provided) => ({
                    ...provided,
                    height: '40px', // Set the desired height
                });
            }
        };

        // Call the media query function to apply styles initially and when the window is resized
        applyMediaQueryStyles();

        // Listen for window resize events and reapply styles as needed
        window.addEventListener("resize", applyMediaQueryStyles);

        var gridOptions1 = {
            getRowStyle: (params) => {
                try {
                    if (this.state.s1 == "btn-success") {
                        if (params.data.label == "AI-Sensitive") {
                            return { backgroundColor: "#809fff", fontWeight: "500" }
                        } else if (params.data.label == "Non-AI-Sensitive") {
                            return { backgroundColor: "#00cccc", fontWeight: "500" }
                        } 
                        else if (params.data.category == "Exact") {
                            return { backgroundColor: "#06d6a0", fontWeight: "500" }
                        } else if (params.data.category == "Excess") {
                            return { backgroundColor: "#ff8fa3", fontWeight: "500" }
                        }
                        else {
                            return { backgroundColor: "#fdc958", fontWeight: "500" }
                        }
                    }else{
                        if (params.data.category == "Exact") {
                            return { backgroundColor: "#06d6a0", fontWeight: "500" }
                        } else if (params.data.category == "Excess") {
                            return { backgroundColor: "#ff8fa3", fontWeight: "500" }
                        }
                        else {
                            return { backgroundColor: "#fdc958", fontWeight: "500" }
                        }
                    }
                } catch (error) {

                }
            }
        }
        var gridOptions5 = {
            getRowStyle: function (params) {
                try {
                    if (params.data.category != undefined || params.data.category != "") {
                        if (params.data.category == "Exact") {
                            return { backgroundColor: "#06d6a0", fontWeight: "500" }
                        } else if (params.data.category == "Excess") {
                            return { backgroundColor: "#ff8fa3", fontWeight: "500" }
                        }
                        else {
                            return { backgroundColor: "#fdc958", fontWeight: "500" }
                        }
                    }
                } catch (error) {

                }
            }
        }

        var outlierCost = this.state.rowData.reduce((accumulator, currentValue) => {
            return accumulator + Math.abs(currentValue.cost_difference);
        }, 0);
        outlierCost = (outlierCost / 1000000).toFixed(2)
        console.log(this.state.underSummaryData, this.state.excessSummaryData)
        const headers = ["Material Classification", "Total Materials", "1-2", "3-4", "5-6", "7-8", "9-10", ">10"];
        const initialPredictionsSummaryHeader = ["Material Group", "Total", "Total(%)", "Vital", "Essential", "Desirable", "Vital(%)", "Essential(%)", "Desirable(%)"]
        var tableData = this.state.s2 === "btn-success" ? this.state.underSummaryData : this.state.excessSummaryData
        var predictionTableData = this.state.s1 == "btn-success" ? this.state.initial_prediction_summary : this.state.validation_summary
        console.log(this.state.possible_oultier_data, "hi how are u")
        const newPerson = {
            headerName: "Category",
            field: "category",
            width: 150,
            filter: true,
            resizable: true,
            editable: false,
            tooltipField: "popupText",
            headerClass: ["column-header-style"]
        };

        if (this.state.s5 === "btn-success") {
            columnwithcompletedata.push(newPerson);
        }
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className={this.state.showChart ? "show-n" : "show-m"}>
                    <div class="row mb-20p">
                        <div className="col-sm-12">
                            <h3 className='fbold row f23'>
                                <div className="col-sm-3" style={{ color: "black" }}>
                                    Prediction Analysis
                                    {/* <span className="xatooltip"><i className="fa fa-info-circle"></i>
                                        <span className="xtooltiptext">Outliers are materials with sustained non-consumption for extended period before an unexpected spurt of demand in the current forecast horizon.</span>
                                    </span> */}
                                </div>
                                <span className="float-right">
                                    {/* <a href="javascript:;"  onClick={this.openUploadOptionForAvailability} className="btn btn-info f12 mr-10p p-9p">Upload Actual Data</a> */}
                                </span>
                                {/* <button type="button" className="btn btn-success float-right font-white" onClick={this.handleTriggerForecast}>Trigger Forecast</button> */}
                                <div className="col-sm-9 f12">
                                    <div className="row">
                                        <div className="col-sm-9"></div>
                                        <div className="col-sm-3">
                                            {/* <div className="form-group row">
                                            <label className="col-sm-6" style={{marginTop:"10px", textAlign:"right"}}>Select Period:</label>
                                            <div className="col-sm-6">
                                                <Select
                                                    value={this.state.outlier_period}
                                                    onChange={this.onChangeOutlierPeriod.bind(this)}
                                                    options={[
                                                        {value:"", label:"Select"},
                                                        {value:"2020 Jan-Jun", label:"2020 Jan-Jun"}
                                                    ]}
                                                    className={"f12"}
                                                />
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </h3>

                        </div>
                    </div>
                    {(this.state.s2 === "btn-success" || this.state.s3 === "btn-success") && (
                        <div class="row mb-20p">
                            {/* <div className="col-sm-2 cirlce-d cursorPointer">
                                <div className="crm-numbers pb-0">
                                    <div className="card">
                                        <div className="card-body" id="counterCardBody">
                                            <span className="f15 fbold" style={{ color: "black" }}>{this.state.s2 === "btn-success" ? "Under" : "Excess"} Prediction Materials Count</span>
                                            <h4 className="txt-danger-dark f30 mt-10p"><span className="counter">
                                                {this.state.rowData.length}
                                            </span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2 cirlce-d cursorPointer">
                                <div className="crm-numbers pb-0">
                                    <div className="card">
                                        <div className="card-body" id="counterCardBody">
                                            <span className="f15 fbold" style={{ color: "black" }}>{this.state.s2 === "btn-success" ? "Under" : "Excess"} Prediction Materials Cost</span>
                                            <h4 className="txt-danger-dark f30 mt-10p"><span className="counter">
                                                $ {outlierCost}M
                                            </span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    )}
                    {/* {(this.state.s1 === "btn-success") && (<div class="row mb-20p">
                        <div className="col-sm-2 cirlce-d cursorPointer">
                            <div className="crm-numbers pb-0">
                                <div className="card">
                                    <div className="card-body" id="counterCardBody">
                                        <span className="f13 countertxt">Possible Outlier Materials Count</span>
                                        <h4 className="txt-danger-dark f30 mt-5p"><span className="counter">
                                            {this.state.possible_outlier_count}
                                        </span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2 cirlce-d cursorPointer">
                            <div className="crm-numbers pb-0">
                                <div className="card">
                                    <div className="card-body" id="counterCardBody">
                                        <span className="f13 countertxt">Possible Outlier Materials Cost</span>
                                        <h4 className="txt-danger-dark f30 mt-5p"><span className="counter">
                                            $ {this.state.possible_outlier_cost}M
                                        </span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)} */}
                    {this.state.s4 === "btn-success" && (<div class="row mb-20p">
                        <div className="col-sm-2 cirlce-d cursorPointer">
                            <div className="crm-numbers pb-0">
                                <div className="card">
                                    <div className="card-body" id="counterCardBody">
                                        <span className="f13 countertxt">Outlier Count - Predicted</span>
                                        <h4 className="txt-danger-dark f30"><span className="counter">
                                            {this.state.outlier_cost}
                                        </span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2 cirlce-d cursorPointer">
                            <div className="crm-numbers pb-0">
                                <div className="card">
                                    <div className="card-body" id="counterCardBody">
                                        <span className="f13 countertxt">Outlier Count - Actual</span>
                                        <h4 className="txt-info-dark f30"><span className="counter">
                                            {this.state.outlier_actual_count}
                                        </span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2 cirlce-d cursorPointer">
                            <div className="crm-numbers pb-0">
                                <div className="card">
                                    <div className="card-body" id="counterCardBody">
                                        <span className="f13 countertxt">Outlier Cost - Predicted</span>
                                        <h4 className="txt-danger-dark f30"><span className="counter">
                                            $ {this.state.outlier_cost}M
                                        </span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2 cirlce-d cursorPointer">
                            <div className="crm-numbers pb-0">
                                <div className="card">
                                    <div className="card-body" id="counterCardBody">
                                        <span className="f13 countertxt">Outlier Cost - Actual</span>
                                        <h4 className="txt-info-dark f30"><span className="counter">
                                            $ {this.state.outlier_actual_cost}M
                                        </span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                    <div className="row mb-20p">
                        <div className="col-sm-12">
                            <button type="button" className={"f17 btn " + (this.state.s1)} style={{ fontWeight: "bold", fontSize: "14px" }} onClick={this.showTab.bind(this, 1)}>Prediction Category</button>
                            <button type="button" className={"f17 btn " + (this.state.s5)} style={{ fontWeight: "bold", fontSize: "14px" }} onClick={this.showTab.bind(this, 5)}>Prediction Validation WRT Actuals</button>
                            {/* <button type="button" className={"f17 btn " + (this.state.s6)} style={{ fontWeight: "bold", fontSize: "14px" }} onClick={this.showTab.bind(this, 6)}>Material Pattern Change Details</button> */}
                            <button type="button" className={"f17 btn " + (this.state.s2)} style={{ fontWeight: "bold", fontSize: "14px" }} onClick={this.showTab.bind(this, 2)}>Actual Under Predictions</button>
                            <button type="button" className={"f17 btn " + (this.state.s3)} style={{ fontWeight: "bold", fontSize: "14px" }} onClick={this.showTab.bind(this, 3)}>Actual Excess Predictions</button>
                            {/* <button type="button" className={"btn " + (this.state.s4)} onClick={this.showTab.bind(this, 4)}>Outlier Analysis</button> */}
                        </div>
                    </div>
                    {(this.state.s2 === "btn-success" || this.state.s3 === "btn-success") && (
                        <div className="row mb-20p d-flex align-items-end">
                            {/* <div className={"col-sm-12 mb-20p " + (this.state.s1 === "btn-success" ? "show-m" : "show-n")} style={{ zIndex: "9" }}> */}
                            {/* <div className='col-sm-2'>
                            <label className="col-form-label f12">Material</label>
                            <MaterialList options={this.state.materiallist} updateMaterialCode={this.updateMaterialCode}></MaterialList>
                            {/* <LearningMaterialListForm options={this.state.materiallist} durationlist={this.state.durationlist}></LearningMaterialListForm>
                        </div>
                        <div className="col-sm-2 ">
                            <div className="form-group ">
                                <button type="button" onClick={this.loadPageData.bind(this)} className="btn btn-success mt-30p">Get Data</button>
                            </div>
                        </div>
                    </div>  */}

                            {/* <div className={"col-sm-12 mb-3p "} style={{ zIndex: "9" }}> */}
                            {/* <div className='col-sm-2'>
                                <label className="col-form-label f17 fw-600" style={{color: "black"}}>Material</label>

                                <MaterialList options={this.state.matCodesList} updateMaterialCode={this.updateMaterialCode1}></MaterialList>

                            </div> */}
                            <div className="col-sm-2 no-margin">
                                <label className="col-form-label f17 fw-600" style={{ color: "black" }}>Phase </label>
                                <Select
                                    onChange={this.onChangePhase.bind(this)}
                                    options={this.state.phases}
                                    value={this.state.phase}
                                    styles={customStyles}
                                />
                            </div>
                            {/* {(this.state.s1 === "btn-success"|| this.state.s5 === "btn-success"|| this.state.s6 === "btn-success") && (<div className="col-sm-2">
                            <label className="col-form-label f12 fbold">Material Type</label>
                            <Select
                                onChange={this.onChangeMaterialType.bind(this)}
                                options={this.state.materialTypes}
                                value={this.state.materialType}
                            />
                        </div>)} */}
                            {/* <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">Prediction From</label>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.setStartDate}
                                selectsStart
                                showMonthDropdown
                                useShortMonthInDropdown
                                className="forminp form-control"
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                dateFormat={"yyyy-MM-dd"}
                                
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">Prediction To</label>
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.setEndDate}
                                selectsEnd
                                showMonthDropdown
                                useShortMonthInDropdown
                                className="forminp form-control"
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                minDate={this.state.startDate}
                                dateFormat={"yyyy-MM-dd"}
                            />
                        </div>
                    </div> */}
                            {/* <div className="col-md-2">
                                <div className="form-group ">
                                    <label className="col-form-label f15 fw-600 mb-5p" style={{color: "black"}}>Forecast Horizon(months)</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.onChangeDuration.bind(this)}
                                        name="duration"
                                        value={this.state.duration}
                                        options={this.state.durationlist}
                                        styles={customStyles}
                                    />
                                    <input type='text' className="form-inputbox forminp" value={"180"} /> 
                                </div>
                            </div> */}
                            <div className="col-md-2 p-0p m-0p">
                                <div className="form-group p-0p m-0p">
                                    <button type="button" className="btn btn-success m-0p" onClick={this.getPhaseWiseData}> Apply</button>
                                </div>
                            </div>
                            {/* <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">Category</label>
                            <Select
                                        placeholder={"Select"}
                                        onChange={this.onChangeCategory.bind(this)}
                                        name="category"
                                        value={this.state.category}
                                        options={this.state.categorylist}
                                    />
                        </div>
                    </div>  */}
                        </div>
                        // </div>
                    )}
                    {(this.state.s1 === "btn-success" || this.state.s5 === "btn-success") && (
                        <div className="row mb-20p d-flex  align-items-end" style={{ paddingLeft: "0px" }}>
                            {/* <div className={"col-sm-8 p-0p mb-3p"} > */}
                            <div className="col-2 no-margin">
                                <label className="col-form-label f19 fw-600" style={{ color: "black" }}>Phase </label>
                                <Select
                                    onChange={this.onChangePredictionValidationPhase}
                                    options={this.state.predictionValidationPhases}
                                    value={this.state.predictionValidationPhase}
                                    styles={customStyles}
                                />
                            </div>
                            <div className="col-2 m-0p p-0p">
                                <div className="form-group p-0p m-0p">
                                    <button type="button" className="btn btn-success mt-40p mb-0p" onClick={this.getPredictionValidationData}> Apply</button>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    )}
                    <div className="row" style={{ marginLeft: "5px" }}>
                        {/* {this.state.s1 === "btn-success" && (
                            <div className="col-7 d-flex  bg-light p-10p" style={{ borderRadius: "10px" }}>
                                <h6 className="fbold mb-0p mr-10p f17 ml-10p" style={{ color: "#809fff" }}>NC - Never Consumed</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#00cccc" }}>NC3Y - No Consumption for past 3 years</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#06d6a0" }}>S-AI - AI Predicted items</h6>
                            </div>
                        )} */}
                        {this.state.s1 === "btn-success" && (
                            <div className="col-4 d-flex  bg-light p-10p" style={{ borderRadius: "10px" }}>
                                <h6 className="fbold mb-0p mr-10p f17 ml-10p" style={{ color: "#809fff" }}>AI - Sensitive</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#00cccc" }}>Non - AI Sensitive</h6>
                            </div>
                        )}
                        {this.state.s5 === "btn-success" && (
                            <div className="col-7 d-flex  bg-light p-10p" style={{ borderRadius: "10px" }}>
                                {/* <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#809fff" }}>NC - Never Consumed</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#00cccc" }}>NC3Y - No Consumption for past 3 years</h6>
                                <span className="fbold"> | </span> */}
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#06d6a0" }}>ETP - Exact Prediction</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#ff8fa3" }}>ESP - Excess Prediction</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#fdc958" }}>UP - Under Prediction</h6>

                            </div>
                        )}
                    </div>
                    {(this.state.s1 === "btn-success" || this.state.s5 === "btn-success") && (
                        <>
                            <div className="row mt-20p ml-0p">
                                <div className="col-12 p-0p d-flex justify-content-between align-items-end">
                                    <h5 className="fw-600 f20" style={{ color: "black" }}>{this.state.s1 === "btn-success" ? "Initial Predictions Summary" : "Validation Summary"}</h5>
                                    {/* <div className="col-4" style={{fontSize: "13px", fontWeight: "bold", color: "black", margin: "0px"}}>
                                <p className="mb-10p" style={{ textDecoration: 'underline', marginBottom: '5px'}}>Not Sensitive AI pattern Items:</p>
                                <p className="m-0p">NC3Y: &nbsp;No Consumptions in Past Three Years</p>
                                <p className="mt-5p mb-5p">NC: &nbsp;Never Consumed</p>
                            </div> */}
                                </div>
                                <div className="col-12 no-margin p-0p">
                                    <table className="table table-bordered mb-20p f14" onCellClick>
                                        <thead style={{ backgroundColor: "#beddeb", textAlign: "center" }}>
                                            <tr>
                                                {initialPredictionsSummaryHeader.map((header, index) => (
                                                    <th key={index}>{header}</th>
                                                ))}
                                                {/* <th>Material Classification</th>
                                                    <th>Total Materials</th>
                                                    <th >Possible Outliers</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(predictionTableData.length === 1) ? (<tr className="text-center bg-light"><td colspan="9" rowspan="2" style={{ fontWeight: "bold", fontSize: "17px", height: "100px", verticalAlign: "middle" }}>Actuals to be Updated</td></tr>) : (
                                                predictionTableData.map((row, rowIndex) => {

                                                    var color = ""
                                                    // if (predictionTableData.length === 4) {
                                                    //     color = rowIndex === 0 ? '#809fff' : rowIndex === 1 ? '#00cccc' : rowIndex === 2 ? '#06d6a0' : "#ccffeb";
                                                    // }
                                                    // else {
                                                    //     color = rowIndex === 0 ? '#809fff' : rowIndex === 1 ? '#00cccc' : rowIndex === 2 ? '#06d6a0' : rowIndex === 3 ? '#ff8fa3' : rowIndex === 4 ? '#fdc958' : "#ccffeb";
                                                    // }
                                                    if (this.state.s1 === "btn-success") {
                                                        color = rowIndex === 0 ? '#809fff' : rowIndex === 1 ? '#00cccc' : rowIndex === 2 ? '#fdc958' : "#ccffeb";
                                                    }
                                                    else {
                                                        color = rowIndex === 0 ? '#06d6a0' : rowIndex === 1 ? '#ff8fa3' : rowIndex === 2 ? '#fdc958' : rowIndex === 3 ? '#ff8fa3' : rowIndex === 4 ? '#fdc958' : "#ccffeb";
                                                    }
                                                    return (
                                                        <tr key={rowIndex} className="text-center" style={{ backgroundColor: color }}>
                                                            {initialPredictionsSummaryHeader.map((header, cellIndex) => {

                                                                if (predictionTableData.length === 4) {
                                                                    return (
                                                                        <td key={cellIndex} style={{ fontWeight: "600" }} >
                                                                            {row[header]}
                                                                        </td>
                                                                    )
                                                                }
                                                                else {
                                                                    if ((rowIndex < 5 && cellIndex > 5) || (rowIndex < 5 && cellIndex === 2)) {
                                                                        console.log(rowIndex, cellIndex, "ghy")
                                                                        return (
                                                                            <td key={cellIndex} style={{ fontWeight: "600", cursor: "pointer" }} title="Click for Charts" onClick={() => this.onClickShowValidationSummaryChartData(header, row["Material Group"])}>
                                                                                {row[header]}
                                                                            </td>
                                                                        )
                                                                    }
                                                                    else {
                                                                        console.log(rowIndex, cellIndex)
                                                                        return (
                                                                            <td key={cellIndex} style={{ fontWeight: "600" }}  >
                                                                                {row[header]}
                                                                            </td>
                                                                        )
                                                                    }
                                                                }

                                                            })}
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-sm-12 mb-10p p-0p">
                                    <h5 className="fw-600 f20" style={{ color: "black" }}>{this.state.s1 === "btn-success" ? "Initial Predictions Complete Data" : "Prediction vs Actual Complete Data"}</h5>
                                </div>
                            </div>
                            {/* <div className={"chartLoading " + (this.state.loadshow1)}>
                                <div className="loaderpos">
                                    <img src={require("../../assets/images/loading.gif")} />
                                </div>
                               </div> */}
                            <div id="myGrid1" style={{ width: "100%", height: "70vh", marginBottom: "100px" }} className={"ag-theme-balham"}>
                                <AgGridReact
                                    // modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.complete_data}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady1}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={this.state.s1 == "btn-success" ? {
                                        context: { componentParent: this },
                                        rowClass: 'your-row-class', // Set the row class for styling
                                        ...gridOptions1
                                    } : {
                                        context: { componentParent: this },
                                        rowClass: 'your-row-class', // Set the row class for styling
                                        ...gridOptions5
                                    }}
                                    // components={this.state.components}
                                    // onCellDoubleClicked={this.onClickShowVaidationSummaryChartData.bind(this)}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                    rowClassRules={this.state.rowClassRules}
                                    suppressRowHoverHighlight={true}
                                />
                            </div>
                            <div className={"sliderBlock2 " + (this.state.showSlideBlock)}>
                                <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                                <div className="col-sm-12">
                                    <NewLineChart
                                        data={this.state.plot_data}
                                        title={this.state.title}
                                        headerName="Validation Summary Plot"
                                        categorySign={this.state.category_sign}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {this.state.s6 === "btn-success" && (
                        <>
                            {/* <div className="row mb-20p d-flex  align-items-end" style={{ paddingLeft: "0px" }}>
                            // <div className={"col-sm-8 p-0p mb-3p"} >
                                <div className="col-2 no-margin">
                                    <label className="col-form-label f19 fw-600" style={{ color: "black" }}>Phase </label>
                                    <Select
                                        onChange={this.onChangeMaterialPatternPhase}
                                        options={this.state.materialPatternPhases}
                                        value={this.state.materialPatternPhase}
                                        styles={customStyles}
                                    />
                                </div>
                                <div className="col-2 m-0p p-0p">
                                    <div className="form-group p-0p m-0p">
                                        <button type="button" className="btn btn-danger mt-40p mb-0p" onClick={this.getPredictionValidationData}> Apply</button>
                                    </div>
                                </div>
                            // </div>
                        </div> */}
                            <div className="col-10 d-flex bg-light p-10p" style={{ borderRadius: "10px" }}>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#809fff" }}>NC - Never Consumed</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#00cccc" }}>NC3Y - No Consumption for past 3 years</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#06d6a0" }}>ETP - Exact Prediction</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#ff8fa3" }}>ESP - Excess Prediction</h6>
                                <span className="fbold"> | </span>
                                <h6 className="fbold mb-0p mr-10p ml-10p f17" style={{ color: "#fdc958" }}>UP - Under Prediction</h6>

                            </div>
                            <div className="col-12 mt-20p p-0p">
                                <h5 className="fw-600 f20" style={{ color: "black" }}>Material Pattern Change Details</h5>
                            </div>
                        </>
                    )}
                    {this.state.s6 === "btn-success" && (
                        <div id="myGrid1" style={{ width: "100%", height: "70vh", marginBottom: "100px", fontWeight: "600", color: "black" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                // modules={this.state.modules}
                                columnDefs={columnwithmaterialpatterndata}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.materialPatternChangeData}
                                enableCharts={false}
                                onGridReady={this.onGridReady1}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this },
                                    rowClass: 'your-row-class', // Set the row class for styling
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection={true}
                                masterDetail={true}
                                rowClassRules={this.state.rowClassRules}
                                suppressRowHoverHighlight={true}
                            />
                        </div>
                    )}


                    <div className={"row mb-20p " + ((this.state.s2 === "btn-success" || this.state.s3 === "btn-success") ? "show-m" : "show-n")}>

                        {/* <div className="col-sm-12">
                        
                        <button type="button" className="btn btn-default float-right" onClick={this.onBtnExportF.bind(this)}>Export to CSV</button>
                        <button type="button" className="btn btn-default float-right" onClick={this.onBtExportExcelF.bind(this)}>Export to Excel</button>
                    </div> */}
                        <div className="col-sm-12">
                            {/* <button type="button" className="btn btn-info float-right" onClick={this.predictionData.bind(this)}><i className="fa fa-download"></i> Prediction Data</button> */}
                            {/* <button type="button" className="btn btn-info float-right" onClick={this.dowloadAllForecastingData.bind(this)}><i className="fa fa-download"></i> Download Prediction vs Actual Data</button>
                        <button type="button" className="btn btn-info float-right" onClick={this.showOnlyPredictionData}><i className="fa fa-download"></i> Download Prediction Data</button> */}
                        </div>
                        <div className={"mxheit col-sm-12 "} >
                            {(this.state.s2 === "btn-success" || this.state.s3 === "btn-success") ? (
                                <div className="row mt-20p">
                                    <div className="col-sm-12 mb-10p">
                                        <h5 className="fw-600 f19" style={{ color: "black" }}>Actual vs Predicted {this.state.s2 === "btn-success" ? "Under" : "Excess"} Summary</h5>
                                    </div>
                                    <div className="col-sm-12 ">
                                        <table className="table table-bordered mb-20p f14" style={{ fontWeight: "600" }}>
                                            <thead style={{ backgroundColor: "#8cb3d9", textAlign: "center" }}>
                                                <tr>
                                                    {/* {headers.map((header, index) => (
                                            <th key={index}>{header}</th>
                                        ))} */}
                                                    <th rowspan="2" style={{ verticalAlign: "middle" }}>Material Classification</th>
                                                    <th rowspan="2" style={{ verticalAlign: "middle" }}>Total Materials</th>
                                                    <th colspan="6">Quantity {this.state.s2 === "btn-success" ? "Under" : "Excess"} Range</th>
                                                </tr>
                                                <tr>
                                                    <th>1-2</th>
                                                    <th>3-4</th>
                                                    <th>5-6</th>
                                                    <th>7-8</th>
                                                    <th>9-10</th>
                                                    <th>&gt;10</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map((row, rowIndex) => {
                                                    var color = ""
                                                    if (tableData.length === 4) {
                                                        color = rowIndex === 0 ? '#90EE90' : rowIndex === 1 ? '#00CED1' : rowIndex === 2 ? '#FFB6C1' : "#D8BFD8";
                                                    }
                                                    else {
                                                        color = rowIndex === 0 ? '#809fff' : rowIndex === 1 ? '#00cccc' : rowIndex === 2 ? '#03C04A' : rowIndex === 3 ? '#ff8fa3' : rowIndex === 4 ? '#fdc958' : "#ccffeb";
                                                    }
                                                    return (
                                                        <tr key={rowIndex} className="text-center" style={{ backgroundColor: color }}>
                                                            {headers.map((header, cellIndex) => (
                                                                <td key={cellIndex}>{row[header]}</td>
                                                            ))}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : ""}
                            <div className="col-sm-12 mb-10p ml-0p p-0p">
                                <h5 className="fw-600 f19" style={{ color: "black" }}>{this.state.s2 === "btn-success" ? "Under Predicted Data" : "Excess Predicted Data"}</h5>
                            </div>
                            {/* <div className={"chartLoading " + (this.state.loadshow1)}>
                                <div className="loaderpos">
                                    <img src={require("../../assets/images/loading.gif")} />
                                </div>
                               </div> */}
                            <div id="myGrid1" style={{ width: "100%", height: "70vh", marginBottom: "100px", fontWeight: "500" }} className={"ag-theme-balham"}>
                                <AgGridReact
                                    // modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady1}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        rowClass: 'your-row-class', // Set the row class for styling
                                    }}
                                    // components={this.state.components}
                                    // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                    rowClassRules={this.state.rowClassRules}
                                    onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                    suppressRowHoverHighlight={true}
                                />
                            </div>
                        </div>


                    </div>
                    <div className={"row mb-20p " + this.state.s3tab}>
                        <AnomalyAnalysis
                            context={this}
                        />
                    </div>

                    <div className={"overlay-block " + (this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                    <div className={"sliderBlock2 " + (this.state.showSlideBlockUpload)}>
                        <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                        {(this.state.sliderType == 1) ?
                            <form method="POST" onSubmit={this.onSubmitGetData}>
                                <div className="col-md-12">
                                    <div className="form-group ">
                                        <label className="col-form-label f12">Prediction Horizon</label>
                                        <Select
                                            placeholder={"Select"}
                                            onChange={this.onChangeDuration.bind(this)}
                                            name="duration"
                                            value={this.state.duration}
                                            options={this.state.durationlist}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group ">
                                        <button type="submit" className="btn btn-success mt-30p">Predict Now</button>
                                    </div>
                                </div>
                            </form>
                            : ""}
                        {(this.state.sliderType == 2) ?
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="col-form-label f18">Code</label>
                                    <h6 style={{ color: "#000" }}>{this.state.materialInfo[0].item_code}</h6>
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label f18">Description</label>
                                    <h6 style={{ color: "#000" }}>{this.state.materialInfo[0].item_description}</h6>
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label f18">Cost</label>
                                    <h6 style={{ color: "#000" }}>{this.state.materialInfo[0].item_cost}</h6>
                                </div>
                            </div>
                            : ""}
                        {(this.state.sliderType == 3) ?
                            <div>
                                <div className="col-sm-12">
                                    <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportG.bind(this)}>Export to CSV</button>
                                    <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelG.bind(this)}>Export to Excel</button>
                                </div>
                                <div className={"col-sm-12"} >
                                    <div id="myGrid" style={{ width: "100%", height: "70vh" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefsForTransactionOutput}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.transactionOutput}
                                            enableCharts={false}
                                            onGridReady={this.onGridReady2}
                                            onGridState={this.onGridState}
                                            frameworkComponents={this.state.frameworkComponents}
                                            statusBar={""}
                                            sideBar={""}
                                            stopEditingWhenGridLosesFocus={true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            enableRangeSelection={true}

                                        />
                                    </div>
                                </div>

                            </div>
                            : ""}
                        {(this.state.sliderType == 4) ?
                            <div>
                                <div className={"row mt-20p"}>
                                    <div className={"col-sm-12 "} >
                                        &nbsp;
                                    </div>
                                </div>
                                <div className={"row mb-20p"}>
                                    {/* {(this.state.chartseries.length > 0)?
                            <div className={"mxheit col-sm-12 mt-20p "} >
                                <div className="card">
                                    <div className="card-body">
                                        
                                        <ColumnChart
                                        title={"FY wise Orders"}
                                        series={this.state.chartseries[0].overallfinancialorders}
                                        />
                                    
                                    </div>
                                </div>
                            </div> :""}
                            
                            {(this.state.chartseries.length > 0)?
                            <div className={"mxheit col-sm-12  mt-20p"} >
                                <div className="card">
                                    <div className="card-body">
                                        <ColumnChart
                                        title={"FY wise Costs"}
                                        series={this.state.chartseries[0].overallfinancialcost}
                                        />
                                    
                                    </div>
                                </div>
                            </div> :""} */}

                                    {/* {(this.state.confidenceTrain.length > 0 && this.state.confidenceTestPoints.length > 0)? */}
                                    <div className={"mxheit col-sm-12 mt-20p"}>
                                        <ConfidenceChart
                                            title={"Inventory Forecast"}
                                            subtitle={"Material Code: " + (this.state.chartItem.value)}
                                            xAxisTitle={""}
                                            yAxisTitle={"Inventory Quantity Consumption"}
                                            confLine={this.state.confidenceTrain}
                                            confLineTrend={this.state.confidenceTrend}
                                            confLinePredictTest={this.state.confidenceTestPoints}
                                            confLinePredictTrend={this.state.trend_predicted_points}
                                            confAreaData={this.state.confAreaData}
                                            confAreaYHat={this.state.confAreaYHat}
                                            confScatter={this.state.confScatter}
                                            dataType={1}
                                            foreCast_period={this.state.foreCast_period}
                                        // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                        // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                        />
                                    </div>
                                    <div className={"mxheit col-sm-12 mt-20p"}>
                                        <ConfidenceChart
                                            title={"Inventory Cost"}
                                            subtitle={"Material Code: " + (this.state.chartItem.value)}
                                            xAxisTitle={""}
                                            yAxisTitle={"Cost"}
                                            confLine={this.state.confidenceTrain}
                                            confLineTrend={this.state.confidenceTrend}
                                            confLinePredictTest={this.state.confidenceTestPoints}
                                            confLinePredictTrend={this.state.trend_predicted_points}
                                            confAreaData={this.state.confAreaData}
                                            confAreaYHat={this.state.confAreaYHat}
                                            confScatter={this.state.confScatter}
                                            trendingcost={this.state.trendingcost}
                                            dataType={2}
                                        // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                        // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                        />
                                    </div>
                                    {/* :""} */}

                                    {/* {( this.state.confidenceTrend.length > 0)? */}
                                    <div className={"mxheit col-sm-12 mt-20p"}>
                                        <LineChart
                                            title={"Yearly Trend"}
                                            subtitle={"Material Code: " + (this.state.chartItem.value)}
                                            confLineTrend={this.state.confTrendplot}

                                        />
                                    </div>
                                    {/* :""} */}

                                    {/* {( this.state.seasonality_train_plot !== undefined && this.state.seasonality_pred_plot !== "" && this.state.seasonality_pred_plot !== undefined)? */}
                                    <div className={"mxheit col-sm-12 mt-20p"}>
                                        <div className="card">
                                            {/*
                                        <div className="card-header">
                                            <div style={{width:"36%",zIndex:"9"}}>
                                                <div className="row">
                                                    <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Select FY: </label>
                                                    <div className="col-sm-8">
                                                        <Select
                                                            value={this.state.fyChart}
                                                            options={this.fyitems()}
                                                            onChange={this.onChangeFYData.bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                            <div className="card-body">
                                                <AreaRangeChart
                                                    title={"Seasonality Data"}
                                                    subtitle={"Material Code: " + (this.state.chartItem.value)}
                                                    confLineSeasonal={this.state.confLineSeasonal}
                                                // predictseries={this.state.seasonality_pred_plot}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* :""} */}

                                    <br /><br />
                                </div>
                            </div>
                            : ""}
                        {(this.state.sliderType == 5) ?
                            <div>

                                <button type="button" className="btn btn-info float-right" onClick={this.showOnlyPredictionData}><i className="fa fa-download"></i> Show Prediction Data</button>
                                <div className="col-md-4">
                                    <div className="form-group ">
                                        <label className="col-form-label f12">Scenario</label>
                                        <Select
                                            placeholder={"Select"}
                                            onChange={this.onChangeScenario}
                                            name="scenario"
                                            value={this.state.scenario}
                                            options={this.state.completedScenariolist}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportH.bind(this)}>Export to CSV</button>
                                    <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelH.bind(this)}>Export to Excel</button>
                                </div>
                                <div className={"col-sm-12"} >
                                    <div id="myGrid" style={{ width: "100%", height: "70vh" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefsForAllForecastData}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.forecastingAllData}
                                            enableCharts={false}
                                            onGridReady={this.onGridReady3}
                                            onGridState={this.onGridState}
                                            frameworkComponents={this.state.frameworkComponents}
                                            statusBar={""}
                                            sideBar={""}
                                            stopEditingWhenGridLosesFocus={true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            enableRangeSelection={true}
                                        />
                                    </div>
                                </div>

                            </div>
                            : ""}
                        {(this.state.sliderType == 6) ?
                            <div>
                                <button type="button" className="btn btn-info float-right" onClick={this.dowloadAllForecastingData.bind(this)}><i className="fa fa-download"></i> Show Prediction vs Actual Data</button>
                                <div className="col-md-4">
                                    <div className="form-group ">
                                        <label className="col-form-label f12">Scenario</label>
                                        <Select
                                            placeholder={"Select"}
                                            onChange={this.onChangeScenario}
                                            name="scenario"
                                            value={this.state.scenario}
                                            options={this.state.completedScenariolist}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportH.bind(this)}>Export to CSV</button>
                                    <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelH.bind(this)}>Export to Excel</button>
                                </div>
                                <div className={"col-sm-12"} >
                                    <div id="myGrid" style={{ width: "100%", height: "70vh" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefsForAllForecastDataOnlyPredicted}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.forecastingAllData}
                                            enableCharts={false}
                                            onGridReady={this.onGridReady3}
                                            onGridState={this.onGridState}
                                            frameworkComponents={this.state.frameworkComponents}
                                            statusBar={""}
                                            sideBar={""}
                                            stopEditingWhenGridLosesFocus={true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            enableRangeSelection={true}
                                        />
                                    </div>
                                </div>

                            </div>
                            : ""}

                        {(this.state.sliderType == 7) ?
                            <div className="col-md-12">
                                <LearningMaterialListForm options={this.state.materiallist} durationlist={this.state.durationlist} scenarioAndItemCodes={this.state.scenarioAndItemCodes}></LearningMaterialListForm>
                                {/* <Mlist options={this.state.forecastitemslist} durationlist={this.state.durationlist}></Mlist> */}
                            </div>
                            : ""}

                        {(this.state.sliderType == 8) ?
                            <div className="col-sm-12">
                                <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                                    <div className="row p-20p">
                                        <div className="form-group col-sm-12">
                                            <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <button type="submit" id="bulkUploadBtn" className="btn btn-danger">SAVE</button>
                                            {/* <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button> */}
                                        </div>
                                        <div id="inValidDataInfo" style={{ color: "red" }}></div>
                                    </div>
                                </form>
                            </div>
                            : ""}

                        {(this.state.sliderType == 9) ?
                            <>
                                <div className="col-md-4">
                                    <div className="form-group ">
                                        <label className="col-form-label f12">Prediction Horizon</label>
                                        <Select
                                            placeholder={"Select"}
                                            onChange={this.onChangeDuration.bind(this)}
                                            name="duration"
                                            value={this.state.duration}
                                            options={this.state.durationlist}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group ">
                                        <label className="col-form-label f12">Prediction Period</label>
                                        <Select
                                            placeholder={"Select"}
                                            onChange={this.onChangePredictionPhase}
                                            name="phase"
                                            value={this.state.phase}
                                            options={this.state.phaseList}
                                        />
                                    </div>
                                </div>
                                <ComparisonCharts phase={this.state.phase.value} materialCode={this.state.material_code[0].value} period={this.state.duration.value != '' ? this.state.duration.value : 6} />
                            </>
                            : ""}

                        {/* {(this.state.sliderType == 10)?
                    <div className="row p-20p">
                        <div className="col-sm-12">
                    
                        <button type="button" className="btn btn-info " onClick={this.dowloadAllForecastingData.bind(this)}><i className="fa fa-download"></i> Download Prediction vs Actual Data</button>
                        <button type="button" className="btn btn-info " onClick={this.showOnlyPredictionData}><i className="fa fa-download"></i> Download Prediction Data</button>
                    </div>
                    </div>
                        :""} */}
                    </div>
                </div>
                {this.state.showChart && (<>
                    <div className="row mb-5p">
                        <div className="col-sm-12">
                            <h5 className='fbold row f23' style={{ color: "black" }}>
                                <div className="col-sm-3">
                                    Prediction Analysis
                                    {/* <span className="xatooltip"><i className="fa fa-info-circle"></i>
                                        <span className="xtooltiptext">Outliers are materials with sustained non-consumption for extended period before an unexpected spurt of demand in the current forecast horizon.</span>
                                    </span> */}
                                </div>
                                <span className="float-right">
                                    {/* <a href="javascript:;"  onClick={this.openUploadOptionForAvailability} className="btn btn-info f12 mr-10p p-9p">Upload Actual Data</a> */}
                                </span>
                                {/* <button type="button" className="btn btn-success float-right font-white" onClick={this.handleTriggerForecast}>Trigger Forecast</button> */}
                                <div className="col-sm-9 f12">
                                    <div className="row">
                                        <div className="col-sm-9"></div>
                                        <div className="col-sm-3">
                                            {/* <div className="form-group row">
                                        <label className="col-sm-6" style={{marginTop:"10px", textAlign:"right"}}>Select Period:</label>
                                        <div className="col-sm-6">
                                            <Select
                                                value={this.state.outlier_period}
                                                onChange={this.onChangeOutlierPeriod.bind(this)}
                                                options={[
                                                    {value:"", label:"Select"},
                                                    {value:"2020 Jan-Jun", label:"2020 Jan-Jun"}
                                                ]}
                                                className={"f12"}
                                            />
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                            </h5>

                        </div>
                    </div>
                    <div className="row mb-5p">
                        <div className="col-sm-12">
                            <button className="btn btn-danger float-right" onClick={this.onClickingTableView}>Go Back</button>
                        </div>
                    </div>
                    <div class="row mb-20p">

                        <div className={"col-sm-12 mb-3p"}>
                            {/* <div className='col-sm-2'>
                                <label className="col-form-label f12">Material</label>

                                <MaterialList options={this.state.matCodesList} updateMaterialCode={this.updateMaterialCode1}></MaterialList>

                            </div> */}
                            <div className="col-sm-2">
                                <label className="col-form-label f17 fw-600" style={{ color: "black" }}>Phase </label>
                                <Select
                                    onChange={this.onChangeChartPhase.bind(this)}
                                    options={this.state.phases}
                                    value={this.state.chartPhase}
                                    styles={customStyles}
                                />
                            </div>

                            <div className="col-md-2">
                                <div className="form-group ">
                                    <label className="col-form-label f15 fw-600" style={{ color: "black" }}>Forecast Horizon(months)</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.onChangeDuration.bind(this)}
                                        name="duration"
                                        value={this.state.duration}
                                        options={this.state.durationlist}
                                        styles={customStyles}
                                    />
                                    {/* <input type='text' className="form-inputbox forminp" value={"180"} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5p">
                        <div className="col-sm-12 f20 fw-600" style={{ color: "black" }}>
                            <b>Material Code: {this.state.chartItemCode}</b>
                        </div>
                    </div>
                    <ComparisonCharts phase={this.state.chartPhase.value} materialCode={this.state.chartItemCode} period={this.state.duration.value != '' ? this.state.duration.value : 6} />
                </>
                )}

                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                {/* <div className={"loader " + this.state.loadshow} style={{ zIndex: 9999 }}></div>
                <div className={"loader " + this.state.loadshow2} style={{ zIndex: 9999 }}></div> */}
                {this.state.loadshow === "show-m" && (
                    <>
                        <div className="new-loader" style={{ zIndex: "9999" }}>
                            <div className="bar bar1"></div>
                            <div className="bar bar2"></div>
                            <div className="bar bar3"></div>
                            <div className="bar bar4"></div>
                            <div className="bar bar5"></div>
                            <div className="bar bar6"></div>
                            <div className="bar bar7"></div>
                            <div className="bar bar8"></div>
                        </div>
                        <div className="col-12 fw-600 f20" style={{ color: "black", position: "absolute", top: "54%", left: "47%", zIndex: "9999" }}>
                            <p>Loading...</p>
                        </div>
                    </>
                )}
                <div className={"overlay-part " + (this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>
                {/* <div className={"overlay-block " + (this.state.overlayBlock)} onClick={this.hideSlideBlock.bind(this)}></div> */}
            </div>
        )
    }
}

function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

function arr_diff(a1, a2) {
    var a = [], diff = [];
    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }
    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }
    for (var k in a) {
        diff.push(k);
    }
    return diff;
}
