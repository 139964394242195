import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class TimeSeriesChartComponent extends Component {

    constructor(props) {
        super(props);
		this.state={
			seriesData:[]
		}
		// console.log(props,'prop')
    }

	componentWillReceiveProps(newprops){
		// console.log(newprops,'newprops')
		setTimeout(()=>{

		if(newprops.series != undefined && newprops.showMaterialCount == 2){
			this.setState({
				seriesData : newprops.series
			})
		}
	},500);
		
	}

    render(){
		// let categories = ["2010-2011","2011-2012","2012-2013","2013-2014","2014-2015","2015-2016","2016-2017","2017-2018","2018-2019","2019-2020"]
		let categories = ["2011","2012","2013","2014","2015","2016","2017","2018","2019"]
		
		// console.log(this.props)
		
		let apiOrderData = []
		let countMaterial = []
		let qtyOrder = []
		let predQtyOrder = []
		let predCountMaterial = []
		if (this.props.apiOrderData.length===0 || this.props.predApiOrderData.length===0){
			return (
				<div className="loader-container" style={{height: "430px"}}>
            <div className="loader-spinner"></div>
            <div className="loader-label">Loading</div>
        </div>
			)
		}
		if(this.props.apiOrderData.length>0){
			 countMaterial = this.props.apiOrderData[1]
			 qtyOrder = this.props.apiOrderData[0]
		}

		if(this.props.predApiOrderData.length>0){
			predQtyOrder = this.props.predApiOrderData[0]
			predCountMaterial = this.props.predApiOrderData[1]
		}
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		let highchartoptions

		let twoLines = [
			{
                type: 'spline',
                showInLegend: true,
                name: 'Actual Order Quantity',
                data: qtyOrder,
				color:'#457b9d',
                marker: {
                    enabled: true,
                    radius:3
                },
				yAxis:1
            },
			
            {
                type: 'spline',
                showInLegend: true,
                name: 'Predicted Order Quantity',
                data: predQtyOrder,
				color:'red',
                marker: {
                    enabled: true,
                    radius:3
                },
				yAxis:1,
				drilldown:'2020'
            },
			
		]
		
		
		highchartoptions = {
	      		 
				chart: {
					type: 'line',
                    zoomType:'x',
					
				},
				title: {
					align: 'left',
					text: this.props.title
				},
				subtitle: {
					align: 'left',
					text: this.props.subtitle
				},
				accessibility: {
					announceNewData: {
						enabled: true
					}
				},
				xAxis: {
					type: 'datetime'
					// categories:["2011-2012","2012-2013","2013-2014","2014-2015","2015-2016","2016-2017","2017-2018","2018-2019","2019-2020","2020-2021","2021-2022"]
					// categories:categories
				},
				yAxis: [{ // Primary yAxis
                    labels: {
                        format: '',
                        style: {
                            color: '#52b788'
                        }
                    },
                    title: {
						
                        text: '',
                        style: {
                            color: "#52b788"
                        }
                    },
                    opposite: true
            
                }, { // Secondary yAxis
                    gridLineWidth: 0,
					type: 'logarithmic',
                    title: {
                        text: 'Order Quantity',
                        style: {
                            color: '#457b9d'
                        }
                    },
                    labels: {
                        format: '',
                        style: {
                            color: '#457b9d'
                        }
                    }
            
                }],
				legend: {
					enabled: true
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							format: '{point.lbl}'
						},
						// events: {
						// 	mouseOver: function () {
						// 	  // Fade out the other Y-axis
						// 	  const otherYAxis = this.chart.yAxis.filter(yAxis => yAxis !== this.yAxis);
						// 	  otherYAxis[0].update({
						// 		labels: {
						// 		  style: {
						// 			opacity: 0.3
						// 		  }
						// 		}
						// 	  });
						// 	},
						// 	mouseOut: function () {
						// 	  // Restore the opacity of the Y-axis
						// 	  const otherYAxis = this.chart.yAxis.filter(yAxis => yAxis !== this.yAxis);
						// 	  otherYAxis[0].update({
						// 		labels: {
						// 		  style: {
						// 			opacity: 1
						// 		  }
						// 		}
						// 	  });
						// 	}   
						// }
					},
                    
                    
				},
			
				// tooltip: {
				// 	headerFormat: '<span style="font-size:11px">{point.x}</span><br>',
				// 	pointFormat: '<span style="color:{point.color}">{point.y}</span>: <b>{point.y}'
				// },
				tooltip: {
					xDateFormat: '%Y %B ',
					shared: true
				},
				series: twoLines,
				drilldown: {breadcrumbs: {
                    position: {
                        align: 'right'
                    }
                },
                // categories:["Jan","Feb","March","April","May","June"],
                series: [				
                    // {
                    //     name: 'Actual Material Count',
                    //     id: '2020',
                    //     data: this.props.drillDownData[0],
                    //     color:"#41ead4",
                        
                        
                    // },
                    // {
                    //     name: 'Predicted Material Count',
                    //     id: '2020',
                    //     data: this.props.drillDownData[1],
                    //     color:"#ffae49"
                    // }
                ]},
				credits:false
				};
		

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












