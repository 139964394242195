import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";
import $ from "jquery";

export default class PlanNewDispatch extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:'',
            material_code:'',
            material_name:'',
            material_type:'',
            material_weight:'',
            material_dimensions_width_mm:'',
            material_dimensions_depth_mm:'',
            material_dimensions_height_mm:'',
            rowId:'',
            locationNames:[],
            showSlideBlock:"",
            overlayBlock:"show-n",
            csvfiledata:[],
            droppedList:[],
            order_id:"",
            inputDetails:[],
            config:"activet",
            inputInfo:"",
            configData:"show-m",
            inputInfoData:"show-n",
        }        
    }

    async componentDidMount(){
        var curl = window.location.search;
        var ul= curl.split("?");
        let url_type = ul[parseInt(ul.length)-1];
        var order_id =url_type
        if(order_id !=undefined && order_id !="undefined" && order_id !="")
        {
            order_id = window.atob(order_id);
        }
        await this.setState({order_id : order_id})
        await this.getPlanDetails()
        await this.getPlanInputDetails()
    }

    getPlanDetails = () =>{
        var params = {
            order_id : this.state.order_id
        }
        redirectURL.post('dispatch/getDispatchOrderDetails',params)
        .then(async response =>{
            if(response.data.length > 0)
            {
                var toolParams = {
                    data_type : response.data[0].data_type
                }
                await getToolConfiguration(toolParams)
                await this.boundTypeLocations(toolParams)
                await this.getVehiclesData(toolParams)
                this.setState({
                    data_type : response.data[0].data_type,
                    transactionDetails:response.data,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                })
            }
        })
    }

    getPlanInputDetails = () =>{
        var params = {
            order_id : this.state.order_id
        }
        redirectURL.post('dispatch/getTransactionInputDetails',params)
        .then(async response =>{

            var inputDetails = response.data;
            this.setState({
                inputDetails:inputDetails,
            })
        })
    }

    getVehiclesData = (params) =>{
        redirectURL.post('master/getVehiclesData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                await vehiclesData.map(item => {
                    if(item.vehicle_capacity){
                    item.vehicle_capacity= Math.round((item.vehicle_capacity/1000000000)*100)/100
                    }
                })
                this.setState({
                    rowData:vehiclesData
                })
            }
        })
    }

    closeAlert = async() => {
        if(this.state.basicTitle == "File is processing")
        {
            var data_type = this.state.data_type;
            var path = "outbound";
            if(data_type == 1)
            {
                path = "inbound";
            }
            window.location.href="dipatchplanhistory?"+path;
        }
        else
        {
            this.setState({
                show: false
            });
        }
	}

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
        })
    }

    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("loadFile", this.state.loadFile);
            var notify_email = $("#notify_email").val();
            saveData.append("notify_email", notify_email);
            saveData.append("module_type", 1);
            saveData.append("data_type", this.state.data_type);
            saveData.append("material_configurations", this.state.material_configurations)
            let location = this.state.location;
            saveData.append("location_code", location.value)
            saveData.append("location_name", location.label)
            saveData.append("user_name", localStorage.getItem('username'))
            saveData.append("email", localStorage.getItem('email'))
            console.log(saveData,saveData);
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            redirectURL.post("/dispatch/generateLoad",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                console.log(response,"responseFromProcess")
                var statusCode = response.data.status;
                if(statusCode == 8)
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        droppedList : response.data.droppedList,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else if(statusCode > 3 && statusCode != 8)
                {
                    if(statusCode == 11)
                    {
                        var processMsg = "Use the standard input data file template for uploading loads";
                    }
                    else
                    {
                        var processMsg = response.data.message;
                    }
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else
                {
                    this.checkTransactionStatus(response.data.transactionId);
                    // this.setState({                    
                    //     basicTitle : response.data.message,
                    //     basicType : "success",
                    //     show : true,
                    //     loadshow:'show-n',
                    //     overly:'show-n',
                    //     showSlideBlockUpload:"",
                    // });
                }
                //window.location.reload();
            });
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        redirectURL.post("/dispatch/checkTransactionStatus",query).then((response)=>{
            console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    basicTitle : response.data.message,
                    basicType : "warning",
                    show : true,
                });
            }
            else if(statusCode == 0)
            {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount)+1;
                this.setState({
                    checkCount : updatedCnt
                });
                console.log(updatedCnt,"checkCount")
                if(updatedCnt < 3)
                {
                    this.chkStatus(response.data.transactionId);
                }
                else
                {
                    this.setState({                    
                        basicTitle : "File is processing",
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                        loadFile:''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");

                }
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
                // this.getDispatchPlanData()
            }
        });
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }
    onGridReady = params => {
        this.gridApi = params.api;  
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onGridReady1 = params => {
		this.gridApi1 = params.api;
	    this.gridColumnApi1 = params.columnApi;
	};
	
	onGridState1 = () =>{
        this.colState1 = this.gridColumnApi1.getColumnState();
        this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();
        this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
        window.filterState1 = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState1);
	}
    deleteNode = (propData) => {
        var nodeToDelete = propData.data;
        this.gridApi.updateRowData({ remove: [nodeToDelete] }, { deltaRowDataMode: true });
        var updatedNodes = this.gridApi.getRenderedNodes().map((node) => node.data);
        this.setState({
            rowData:updatedNodes
        });
    };
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        // console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        redirectURL.post("dispatch/generateLoadByTransactionId",params).then((response)=>{
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if(statusCode == 0 || statusCode == 1)
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    processErrMsg : "",
                    processErrShow : 0,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            //window.location.reload();
        });
    }
    planNewDispatchWithConfig = () => {
        var vehicleData = [];
        this.gridApi.forEachNode(function (rowNode, index) {
            var data = rowNode.data;
            vehicleData.push(data);
        });
        console.log(vehicleData,"vehicleData");
        var params = {
            module_type : localStorage.getItem("module_type"),
            data_type : this.state.data_type,
            location_code : this.state.transactionDetails[0].location_code,
            no_of_runs : parseInt(this.state.transactionDetails[0].no_of_runs)+1,
            order_id : this.state.order_id,
            vehicleData : vehicleData
        }
        redirectURL.post("/dispatch/generateLoadByConfiguration",params).then((response)=>{
            console.log(response,"responseFromProcess")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                if(statusCode == 11)
                {
                    var processMsg = "Use the standard input data file template for uploading loads";
                }
                else
                {
                    var processMsg = response.data.message;
                }
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else
            {
                this.checkTransactionStatus(response.data.transactionId);
            }
        });
    }
    onClickTab = (pageType) => {
        if(pageType == "config")
        {
            var config = "activet";
            var inputInfo = "";
            var configData = "show-m";
            var inputInfoData = "show-n";
        }
        else if(pageType == "inputInfo")
        {
            var config = "";
            var inputInfo = "activet";
            var configData = "show-n";
            var inputInfoData = "show-m";
        }
        this.setState({
            config:config,
            inputInfo:inputInfo,
            configData:configData,
            inputInfoData:inputInfoData,
        })
    }
    render(){
        var material_configuration_chk = 0;
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                material_configuration_chk = toolConfig[0].material_configuration;
            }
        }
        var inputDetailscolsDefs = [
            {
                headerName:"Pick Up Location (Source)",
                field:"pickup_location_code",
                width:"250",
            },
            {
                headerName:"Drop Location (Destination)",
                field:"drop_location_code",
                width:"250"
            },
        ]
        if(material_configuration_chk == 1)
        {
            inputDetailscolsDefs.push(
                {
                    headerName:"Material",
                    field:"material_code",
                    width:"250",
                },
                {
                    headerName:"Quantity",
                    field:"quantity",
                    width:"250",
                }
            );
        }
        else
        {
            inputDetailscolsDefs.push(
                {
                    headerName:"Demand (CMT)",
                    field:"demand_cmt",
                    width:"250",
                }
            );
        }
        var colsDefs = [
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"100"
            },
            {
                headerName:"Vehicle Name",
                field:"vehicle_name",
                width:"100"
            },
            {
                headerName:"Vehicle Priority",
                field:"vehicle_priority",
                width:"100"
            },
            {
                headerName:"Vehicle Operational(from)",
                field:"vehicle_availability_from",
                width:"150",
            },
            {
                headerName:"Vehicle Operational(to)",
                field:"vehicle_availability_to",
                width:"140"
            },
            {
                headerName:"Break Time(from)",
                field:"break_time_from",
                width:"110",
            },
            {
                headerName:"Break Time(to) ",
                field:"break_time_to",
                width:"100",
            },
            {
                headerName:"Min Volume (CMT)",
                field:"min_volume_cmt",
                width:"120",
                editable:true
            },
            {
                headerName:"Max Volume (CMT)",
                field:"max_volume_cmt",
                width:"120",
                editable:true
            },
            {
                headerName:"Vehicle Width (mm)",
                field:"vehicle_dimensions_width_mm",
                width:"120",
                editable:true
            },
            {
                headerName:"Vehicle Height (mm)",
                field:"vehicle_dimensions_height_mm",
                width:"125",
                editable:true
            },
            {
                headerName:"Vehicle Depth (mm)",
                field:"vehicle_dimensions_depth_mm",
                width:"120",
                editable:true
            },
            {
                headerName:"Vehicle Min Weight (Kgs)",
                field:"vehicle_min_weight",
                width:"145",
                editable:true
            },
            {
                headerName:"Vehicle Max Weight (Kgs)",
                field:"vehicle_max_weight",
                width:"145",
                editable:true
            },
            {
                headerName:"Vehicle Capacity (CMT)",
                field:"vehicle_capacity",
                width:"135",
                editable:true
            },
            {
                headerName:"No Of Deliveries",
                field:"max_no_of_nodes",
                width:"100",
                editable:true
            },
            {
                headerName:"Min Load Per Delivery",
                field:"min_load_per_delivery",
                width:"140",
                editable:true
            },
            {
                headerName:"Max Distance (Kms)",
                field:"max_distance",
                width:"120",
                editable:true
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                onClickFunction:this.deleteNode},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
        ]

        let data = this.state.rowData
        let locValue = this.state.location.value   
        let filteredData = data.filter(item => item.location_code == locValue)

        let vehicleTypeCnt = 4;
        let truck_min_max = 0;
        let truckPriorities = 0;
        let distancePreferences = 0;
        let operationalTimes = 0;
        let operationalTimesType = 0;
        let clubbingLimits = 0;
        let minLoad = 0;
        let vehicleAvailability = 0;
        var material_configurations = 0;

        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                vehicleTypeCnt = toolConfig[0].max_no_of_trucks;
                truck_min_max = toolConfig[0].truck_min_max_configuration_preferences;
                truckPriorities = toolConfig[0].truck_priorities;
                distancePreferences = toolConfig[0].distance_preferences;
                operationalTimes = toolConfig[0].vehicle_operational_times;
                operationalTimesType = toolConfig[0].vehicle_operational_times_type;
                clubbingLimits = toolConfig[0].clubbing_limits;
                minLoad = toolConfig[0].min_load_preferences
                vehicleAvailability = toolConfig[0].vehicle_availability
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        var columnwithDefsForDropped = [      
            {
                headerName: "Dealer",
                field: "dealer_code",
                width:130,
                filter: true,
                resizable: true,
                editable:false,            
            },
            {
                headerName: "Demand",
                field: "demand",
                width:130,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Remarks",
                field: "remarks",
                width:200,
                filter: true,
                resizable: true,
                editable:false
            },
        ]
        if(material_configurations == 1)
        {
            var templatePath = require('../../assets/json/input_data_with_material_config_template.csv');
        }
        else
        {
            var templatePath = require('../../assets/json/input_data_template.csv');
        }
        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12">                   
                        <h5 className='fbold'>
                            <span>Configuration</span>
                            <div className='float-right col-sm-3'>
                                <span className="float-right">
                                <a href="javascript:;"  onClick={this.planNewDispatchWithConfig} className="btn btn-warning f12 mr-10p">Run Plan</a>
                                </span>
                            </div>
                        </h5>
                        
                        
                        <div className="row mt-20p">
                            <div className="col-md-12 col-sm-12">
                                <ul className="d-tabs">
                                    <li onClick={this.onClickTab.bind(this,"config")} className={"lttabs "+(this.state.config)}>
                                        <button type="button" className="btn">Configurations</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"inputInfo")} className={"lttabs "+(this.state.inputInfo)}>
                                        <button type="button" className="btn">Input Data</button>
                                    </li>                                    
                                </ul>
                            </div>
                        </div>

                        <div className={"row mt-20p "+this.state.configData}>
                            <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                            <AgGridReact
                            modules={this.state.modules} 
                                rowData={filteredData}
                                columnDefs={colsDefs}
                                gridOptions={{context:{componentParent:this}}}
                                defaultColDef={this.state.defaultColDef}
                                frameworkComponents={this.state.frameworkComponents}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                            />
                            </div>
                        </div>

                        {/* Input Data */}
                        <div className={"row mt-20p "+this.state.inputInfoData}>
                            <div id="myGrid" style={{width:"100%",height:"80vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                <AgGridReact
                                modules={this.state.modules} 
                                    rowData={this.state.inputDetails}
                                    columnDefs={inputDetailscolsDefs}
                                    gridOptions={{context:{componentParent:this}}}
                                    defaultColDef={this.state.defaultColDef}
                                    frameworkComponents={this.state.frameworkComponents}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection= {true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">Upload Input File</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            {this.state.processErrShow == 0 ?
                            <form onSubmit={this.uploadBulkFormHandler.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12">
                                        <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>                            
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Upload File</label> 
                                        <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                                    </div>                        
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Notify Email Users After Process (comma seperated)</label> 
                                        <textarea name="notify_email" id="notify_email" className="form-control"></textarea>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <button type="submit" className="btn btn-info">SAVE</button>
                                        <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                            :
                            <div className="" style={{marginTop:"1em"}}>
                                <p style={{marginBottom:"1em"}}>{this.state.processErrMsg}</p>
                                {this.state.droppedList.length > 0 ? 
                                    <div id="myGrid" style={{width:"100%",height:"300px",marginBottom:"1em"}} className={"ag-theme-balham "+this.state.showGridData}>    
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefsForDropped}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.droppedList}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection= {true}
                                        //onCellClicked={this.onCellClicked}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                                </div>
                                :
                                ""
                                }
                                <button type="button"  onClick={this.fixProcess.bind(this)} className="btn btn-success">Fix and Reupload</button>
                                {this.state.statusCode == 11 ?
                                    <button type="button" onClick={this.hideSlideBlock.bind(this)} className="btn btn-default">Cancel</button>
                                :
                                    <button type="button" onClick={this.ignoreProcessError.bind(this)} className="btn btn-default">Ignore</button>
                                }
                                
                            </div>
                            }
                        </div>
                    </div>
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                <div className={"loader "+this.state.loadshow}></div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>
            </>
        )
    }
}