import React, { useEffect,useState } from 'react';
import ColumnChart from "./predictionAnalysisColumnChart";
import ConfidenceChart from "./predictionAnalysisConfidenceChart";
import redirectURL from '../redirectURL';

const  ComparisonCharts = (props) => {
    
        const {materialCode,period,phase} = props

        const [confAreaData, setConfAreaData] = useState([]);
        const [foreCast_period, setForeCast_period] = useState([]);
        const [confAreaYHat, setConfAreaYHat] = useState([]);
        const [newConfAreaYHat, setNewConfAreaYHat] = useState([]);
        const [confScatter, setConfScatter] = useState([]);
        const [actualValues, setActualValues] = useState([]);
        // const [actualChartData, setActualChartData] = useState([]);
        // const [predictedChartData, setPredictedChartData] = useState([]);
        const [all_months, setAll_months]  = useState([]);
        const [chartdata, setChartdata] = useState([]);
        // const [item_code, setItem_code]  = useState('');
        
    

    useEffect (() =>{
        
        showData(materialCode,period,phase)
        
    },[materialCode,period,phase]);
   
    const showData = (material_code,durationVal,phase) => {
    const params = {
        item_code: material_code,
        fc_period: durationVal,
        phase:phase
    }
    redirectURL.post('/forecast/getpredicteddatamaterialwise', params)
    .then(response => {
        if (response.data.status === "Success") {

            let actualRecords = response.data.actualRecords
            let predictedRecords = response.data.predictedRecords
            console.log(predictedRecords, "predictedRecords")
            console.log(actualRecords, "actualRecords")
            compareData(actualRecords,predictedRecords,material_code,durationVal,phase)

        }
    })
    }

    const getMaterialChartDataLatest = async(mat_code,fcPeriod,phase, predictedRecords) =>{
        
        if(fcPeriod == 180){
            fcPeriod = 6
        }else if(fcPeriod == 90){
            fcPeriod = 3
        }

        let item_code = mat_code
                await redirectURL.post("/forecast/getmaterialforecastoutputchartdata",{
                    item_code : item_code,fc_period : fcPeriod,phase:phase
                }).then(async (response) =>{
                    var chartDataaa = response.data.latestRecords;
                    
                    // chartDataaa = JSON.parse(mchartDataaa);
                    var chartDatalist = [];
                    
                    var areaplot=[];
                    var areaYHat=[];
                    var newAreaYHat = [];
                    let foreCast_period='';
                    
                    var areaScatter=[];
                   
                    if(chartDataaa.length > 0)
                    {
                        chartDataaa.map((itm) =>{
                            itm["chart_data"] = JSON.parse(itm.chart_data);
                            itm["month_data"] = JSON.parse(itm.month_data);
                            
                            chartDatalist.push(itm)
                            
                        })
                    }

                    if(chartDatalist.length > 0)
                        {
                            var chartData = [chartDatalist[0]];
                            if(chartData.length > 0)
                            {
                                chartData.map((mm) => {
                                    var chartSeries = mm.chart_data;
                                    console.log(chartSeries, "chartSeries")
                                    // chartSeries.forEach(obj => {
                                    //     // Check if 'actual' property is null
                                    //     if (obj.actual == null) {
                                    //         // If null, replace it with 0
                                    //         obj.actual = 0;
                                    //     }
                                    // });
                                    foreCast_period = mm.fc_period
                                    if(chartSeries.length > 0)
                                    {
                                        chartSeries.map((mt) => {
                                            const originalDate = new Date(mt.ds);
                                            originalDate.setDate(1);
                                            originalDate.setHours(0, 0, 0, 0);
                                            const modifiedDate = originalDate.toISOString()
            
                                            areaplot.push([new Date(modifiedDate).getTime()+(60*60*24*1000),...mt.area_plot]);
                                            areaYHat.push((mt.yhat < 0)?[new Date(modifiedDate).getTime()+(60*60*24*1000),0]:[new Date(modifiedDate).getTime()+(60*60*24*1000),parseInt(mt.yhat)]);
                                            
                                            if(mt.actual !== "" && mt.actual !== undefined && mt.actual !== null)
                                            {    
                                                    areaScatter.push([new Date(modifiedDate).getTime()+(60*60*24*1000),mt.actual]);
                                            }
                                        })
                                        
                                    }
                                    if(predictedRecords.length > 0)
                                    {
                                        predictedRecords.map((mt) => {
                                            const originalDate = new Date(mt.prediction_date);
                                            originalDate.setDate(1);
                                            originalDate.setHours(0, 0, 0, 0);
                                            const modifiedDate = originalDate.toISOString()
            
                                            newAreaYHat.push((mt.item_quantity < 0)?[new Date(modifiedDate).getTime()+(60*60*24*1000),0]:[new Date(modifiedDate).getTime()+(60*60*24*1000),parseInt(mt.item_quantity)]);
                                            
                                        })
                                        
                                    }
                                })
                            }
                        }

                setConfAreaData(areaplot);
                setForeCast_period(foreCast_period);
                setConfAreaYHat(areaYHat);
                setNewConfAreaYHat(newAreaYHat);
                // setConfAreaYHat(predictedRecords)
                setConfScatter(areaScatter);

                })

                
      }


    const compareData = async(actualRecords,predictedRecords,mat_code,fcPeriod,phase) =>{

        getMaterialChartDataLatest(mat_code,fcPeriod,phase, predictedRecords)
        
        let all_months =[];
        let datamonths =[];
        let chartdata =[];
        let data=[]
        let actualValues = [];

        var months = ["Jan","Feb","Mar","Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        actualRecords.sort((a, b) => new Date(a.date) - new Date(b.date));
        predictedRecords.sort((a, b) => new Date(a.prediction_date) - new Date(b.prediction_date));
        {actualRecords.length === 0 ? (predictedRecords.map((i) => {

            let key = months[parseInt(i.month-1)];
            datamonths.push(key+"-"+new Date(i.prediction_date).getFullYear())
            let value = 0;  
            data.push({"name":key,"y":value,'lbl':value,color:'#006789'})
            
        })) : (actualRecords.map((i) => {

            let key = months[parseInt(i.month-1)];
            datamonths.push(key+"-"+new Date(i.date).getFullYear())
            let value = i.quantity;  
            actualValues.push([new Date(i.date).getTime()+(60*60*24*1000),value])
            data.push({"name":key,"y":value,'lbl':value,color:'#006789'})
            
        }))}

        all_months.push(datamonths)
        if(data.length>0){
            chartdata.push({name:"Actual Data",data:data,color:'#006789'})
        }

        data=[]
        predictedRecords.sort((a, b) => new Date(a.prediction_date) - new Date(b.prediction_date));
        {predictedRecords.length === 0 ? (actualRecords.map((itm) => {
            let key = months[parseInt(itm.month-1)];
            let value = 0
            data.push({"name":key,"y":value,'lbl':value,color:'#deaa00'})
        })) : (
        predictedRecords.map((itm) => {
            let key = months[parseInt(itm.month-1)];
            let value = itm.item_quantity;
            data.push({"name":key,"y":value,'lbl':value,color:'#deaa00'})
        }))}
        if(data.length>0){
            chartdata.push({name:"Predicted Data",data:data,color:'#deaa00'})
        }

        // setActualChartData(actualRecords);
        // setPredictedChartData(predictedRecords);
        setChartdata(chartdata);
        setAll_months(all_months);
        // setItem_code(mat_code);
        setActualValues(actualValues);
        
    }
    return(
        <>
                <div className={"mxheit col-sm-12 mt-20p"}>
                <div className="card">
                        <div className="card-body">                                        
                    {confAreaData.length === 0 ? (<div className="col-12 fbold f14 d-flex justify-content-center align-items-center" style={{color: "black", width: "100%", height: "300px"}}><h3>No Data to show Charts</h3></div>) : (<div className="col-12"><ConfidenceChart
                        // xAxisTitle={"Data Samples"}
                        yAxisTitle={"Inventory Quantity Consumption"}
                        confAreaData={confAreaData}
                        confAreaYHat={confAreaYHat}
                        newConfAreaYHat={newConfAreaYHat}
                        confScatter={confScatter}
                        foreCast_period={foreCast_period}
                        actualValues={actualValues}
                    /></div>)}
                </div>
                </div>
                </div>

                <div className={"mxheit col-sm-12 mt-20p"} >
                    <div className="card">
                        <div className="card-body">
                        {chartdata.length === 0 ? (<div className="col-12 fbold f14 d-flex justify-content-center align-items-center" style={{color: "black", width: "100%", height: "300px"}}><h3>No Data to show Charts</h3></div>) : (
                        <div className="col-12">
                        <ColumnChart
                        subtitle={"Bar chart"}
                        yaxisTitle={"Quantity"}
                        series={chartdata}
                        categories={all_months}
                        chartType={'column'}
                    /></div>)}

                        </div>
                    </div>
                </div>
        </>
    )
}

export default ComparisonCharts;