import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import  draggable from '../../../node_modules/jquery-ui/ui/widgets/draggable';
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import ViewTruckData from "./viewtruckaction";
import ViewClusterRoute from "./viewclusterroute";
import ViewRoute from "./viewroute";
import ViewStackingData from "./viewstackingaction";
import ViewStack from "./viewstack";
// import BinPacking from "./packingComponent";

import redirectURL from '../redirectURL';
var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class InputPlanSummary extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                ViewTruckData:ViewTruckData,
                ViewRoute:ViewRoute,
                ViewClusterRoute:ViewClusterRoute,
                ViewStackingData:ViewStackingData,
                ViewStack:ViewStack
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showrunprocessbtn:"show-m",
            showanimate:"show-n"
        }
        this.onClickShowUpload=this.onClickShowUpload.bind(this);
        this.hideSlideBlock = this.hideSlideBlock.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.loadPlanData = this.loadPlanData.bind(this);
        this.handleChangeFilterDate = this.handleChangeFilterDate.bind(this);
		 this.extractDeliveryDates = this.extractDeliveryDates.bind(this);
        this.changeDeliveryDate = this.changeDeliveryDate.bind(this);
        this.changeCluster = this.changeCluster.bind(this);
        this.changeVehicleType = this.changeVehicleType.bind(this);
        this.onClickShowStackingData = this.onClickShowStackingData.bind(this);
        this.onClickRunProcess = this.onClickRunProcess.bind(this);
        this.isLoadPlanRun = this.isLoadPlanRun.bind(this);
      }
    componentDidMount() {
        
        this.loadPlanData();
        this.isLoadPlanRun();
    }
    isLoadPlanRun(){
        
        redirectURL.post("/plan/planstatus")
        .then((response) => {
            if(response.data.records.length > 0)
            {
                
                var records = response.data.records;
                if(records[0].status == "pending")
                {
                    this.setState({
                        showanimate:"show-m",
                        overlayBlock:"show-n"
                    })
                       this.intervalFn();
                   
                }
                else{
                    setTimeout(() => {
                        this.setState({
                            
                            showanimate:"show-n",
                            overlayBlock:"show-n"
                        })
                    },3000);
                    clearInterval();
                }
                
                this.setState({
                    showrunprocessbtn:"show-n",
                    overlayBlock:"show-n"
                })
            }
            else{
                this.setState({
                    showrunprocessbtn:"show-m",
                    showanimate:"show-n",
                })
            }
        })
    }
    intervalFn(){
        
        setInterval(()=>{
            this.isLoadPlanRun()
        },61000);
    }
    loadPlanData(){
        redirectURL.post("/plants/list")
        .then((response) => {
          var records = response.data;
          this.setState({
            plants:records
          })
        });
        this.setState({
            showloader:"show-m"
        })

       
        redirectURL.post("/dealers/loadinputplan")
        .then(async (response) => {
            var records = response.data;
            // records = records.sort(GetSortAscOrder("plan_day"));
            // var loaddata = [];
            // var allLoadPlan = [];
            // try{

            //     var plandaygrp = groupBy(records, rdata => rdata.plan_day);
            //     // console.log("plandaygrp ", plandaygrp)
            //     plandaygrp.forEach((plangrp,pkey) => {
            //         // console.log("pkey ", pkey)
            //         var plangrp = plangrp.sort(GetSortAscOrder("cluster"));
            //         var clustergrp = groupBy(plangrp, rdata => rdata.cluster);
            //         // console.log("clustergrp ",clustergrp)
            //         clustergrp.forEach((cluster,clusterkey) => {
            //             cluster = cluster.sort(GetSortAscOrder("truck_nocluster"));
            //             var dealergrp = groupBy(cluster, rdata => rdata.dealer_code);
            //             dealergrp.forEach((dealer,dealerkey) => {
            //                 if(dealerkey == "DICVPLANT")
            //                 {
            //                     if(dealer.length > 0)
            //                     {
            //                         var  rw = {
            //                             cluster:clusterkey,
            //                             items:dealer
            //                         }
            //                         loaddata.push(rw);
                        
            //                     }
                                
            //                 }
            //             })
            //         })
                    
            //     });

            //     var rowdata = [];
            //     if(loaddata.length > 0)
            //     {
            //         loaddata.map((item) => {
                        
            //             var data = item.items;
                        
            //             data = data.sort(GetSortAscOrder("trip_no"));
            //             data = data.sort(GetSortAscOrder("delivery_date"));
            //             if(data.length > 0)
            //             {
            //                 data.map((item) => {
            //                     rowdata.push(item);
            //                 })
            //             }
            //         })
            //     }
            //     // console.log("rowdata ", rowdata)
            this.setState({
                loadplan:records,
                allLoadPlan:records,
                rowData:records,
                showloader:"show-n"
            })
        
        })
      }
    
      logout()
         {
            
               localStorage.removeItem('tn');
               localStorage.removeItem('s');
               localStorage.removeItem('sidebar');
               localStorage.clear();
               sessionStorage.removeItem('tn');
               sessionStorage.removeItem('s');
               sessionStorage.clear();
       
               sessionStorage.setItem('ref', null);
               //localStorage.setItem('menuText',[]);
               delete axios.defaults.headers.common['Authorization'];
               this.setState({
                   redirectToReferrer: ''
               });
         }
      
     
    hideSlideBlock(){
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
        })
      }
    onClickShowUpload(){
        this.setState({
            showSlideBlockUpload:"slide25",
            overlayBlock:"show-m"
        })
    }
    
    changeFileHandler = async (e) => {
        console.log("e.target.files[0] ", e.target.files[0])
        this.setState({
            uploadfile:e.target.files[0]
        });
    }
    
    formUpload(event){
        event.preventDefault();
        
        this.setState({
            showloader:"show-m"
        })
        var reqparams = new FormData()
        reqparams.append("file", this.state.uploadfile)
        // console.log("reqparams ", reqparams);
        redirectURL.post("/dealers/readXLSData",reqparams)
        .then((response) => {
          // console.log(response.data)
          var uploadxlsdata = response.data.records;
        //   console.log("uploadxlsdata ", uploadxlsdata)
          if(uploadxlsdata.length > 0)
          {
            var p = {
              xlsdata:uploadxlsdata
            }
            redirectURL.post("/dealers/uploadLoadInputPlan",p)
            .then((resp) => {
            //   console.log(resp.data)
              if(resp.data.message == "success")
              {
                this.setState({
                  uploadfile:"",
                  showSlideBlockUpload:"",
                  overlayBlock:"show-n",
                  show:true,
                  basicTitle:"Successfully upload data",
                  basicType:"success",
                  showloader:"show-n"
                });
                window.location.reload();
              }
              else{
                this.setState({
                  show:true,
                  basicTitle:"Failed to upload data",
                  basicType:"danger",
                  showloader:"show-n"
                });
              }
            })
          }
        })
    }

    extractDeliveryDates(){
        var allLoadPlan = this.state.allLoadPlan;
        // console.log("allLoadPlan ", allLoadPlan)
        if(allLoadPlan.length > 0)
        {
            var deliverdates = [];
            var deliveryopts = [];
            allLoadPlan.map((i,k) => {
                var r = {
                    delivery_date:i.delivery_date,
                    delivery_dateformat:i.delivery_dateformat
                }

                deliveryopts.push({
                    value:i.delivery_date,
                    label:i.delivery_dateformat
                })
                deliverdates.push(r)
            })
            this.setState({
                deliverydates:deliverdates,
                deliveryopts:deliveryopts
            })
            // window.generatePageMarkers()
        }
    }
    changeDeliveryDate = (filterDeliveryDate) =>{
        this.setState({filterDeliveryDate},() => {
            // console.log(filterDeliveryDate)
            var p ={
                delivery_date:this.state.filterDeliveryDate.value
            }
            redirectURL.post("/dealers/clusterbydate",p)
            .then((response) => {
                var records = response.data.records;
                // console.log("records ,",records)
                var clusterlist = [];
                if(records.length > 0)
                {
                    records.map((itm) => {
                        clusterlist.push({
                            value:itm,
                            label:"Cluster "+itm
                        })
                    })
                }
                this.setState({
                    clusterlist:clusterlist
                })
            })
        })
    }
    changeCluster = (filtercluster) => {
        this.setState({filtercluster},() => {
            // console.log("filtercluster ",filtercluster)

        var p ={
            cluster:this.state.filtercluster.value,
            delivery_date:this.state.filterDeliveryDate.value
        }
        redirectURL.post("/dealers/vehicletypesbycluster",p)
        .then((response) => {
            var records = response.data.records;
            // console.log("records ,",records)
            var vehicletypelist = [];
            vehicletypelist.push({
                value:"",
                label:"All"
            })
            if(records.length > 0)
            {
                records.map((itm) => {
                    vehicletypelist.push({
                        value:itm,
                        label:itm
                    });
                })
            }
            this.setState({
                vehicletypelist:vehicletypelist,
            })
        })
            
        });
    }
    changeVehicleType = (filterVehicleType) => {
        this.setState({filterVehicleType},()=>{
         
    
        });
        
    }
    changeDealers = (filterdealer) => {
        this.setState({filterdealer});
    }

    onClickCloseMap(){
        this.setState({
            routeData:[],
            mapcol:"show-n",
            gridocl:"col-sm-12"
        })
    }
    handleChangeFilterDate(date) {
        // console.log("date ", date)
        if(date != "")
        {
            var dd = new Date(date).toString();
            // console.log('data ',moment.parseZone(dd).format("HH:mm"))
            var dt = moment.parseZone(dd).format("DD-MM-YYYY");
        // console.log("dt ", dt);
            $("#filter_date").val(dt);
            var d = dt.split("-");
            if(d[0].length == 4)
            {
                var dte = d[0]+""+d[1]+""+d[2];
            }
            else{
                var dte = d[2]+""+d[1]+""+d[0];
            }
            var p ={
                cluster:this.state.selectedCluster,
                delivery_date:dte
            }
            // console.log("asdsadasda ", dte)
            redirectURL.post("/dealers/trucksbycluster",p)
            .then((response) => {
                var records = response.data.records;
                // console.log("records ,",records)
                var vehicletypelist = [];
                vehicletypelist.push({
                    value:"",
                    label:"All"
                })
                if(records.length > 0)
                {
                    records.map((itm) => {
                        vehicletypelist.push({
                            value:itm,
                            label:itm
                        })
                    })
                }
                this.setState({
                    vehicletypelist:vehicletypelist,
                    filter_date: dte,
                    vtypediv:"show-m"
                })
            })
            
        }
      }
    onClickShowTruckLegs = async (rownode) =>{
        // console.log("rownode ", rownode);
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: [
                    {
                        headerName:"Unique Part No",
                        field:"unique_part_no"
                    },
                    {
                        headerName:"Part No",
                        field:"part_no",
                        width:"120"
                    },{
                        headerName:"Part Name",
                        field:"part_name"
                    },
                    {
                        headerName:"Part Qty",
                        field:"part_qty",
                        width:"100"
                    },
                    {
                        headerName:"Part Volume",
                        field:"part_volume",
                        width:"100"
                    },{
                        headerName:"Part Weight",
                        field:"part_weight",
                        width:"100"
                    },
                    {
                        headerName:"Supplier Code",
                        field:"dealer_code",
                        width:"100"
                    },{
                        headerName:"Supplier Name",
                        field:"dealer_name"
                    },{
                        headerName:"Supplier Location",
                        field:"location"
                    },
                    
                    {
                        headerName:"Vehicle Start Time",
                        field:"vehicle_start_time",
                        valueGetter:function(parmas){
                            try{
                                if(parmas.data.vehicle_start_time != "" && parmas.data.vehicle_start_time != undefined)
                                {
                                    return moment.parseZone(parmas.data.vehicle_start_time).format("DD-MM-YYYY HH:mm");
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){

                            }
                        }
                    },{
                        headerName:"Vehicle End Time",
                        field:"vehicle_end_time",
                        valueGetter:function(parmas){
                            try{
                                if(parmas.data.vehicle_end_time != "" && parmas.data.vehicle_end_time != undefined)
                                {
                                    return moment.parseZone(parmas.data.vehicle_end_time).format("DD-MM-YYYY HH:mm");
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){

                            }
                        }
                    },
                    {
                        headerName:"Distance (Kms)",
                        field:"distance"
                    }
                ],
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    // console.log("Step 4 ",param)
                    // console.log("reqparams",reqparams);
                    await redirectURL.post("/dealers/filterloadplan",{
                        cluster : param.data.cluster,
                        truck_no : param.data.truck_no,
                        trip_no : param.data.trip_no,
                        delivery_date : param.data.delivery_date
                    }).then(async (response) =>{
                        // console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var records=response.data;
                        records = records.sort(GetSortAscOrder("sequence_no"));

                        // console.log("Step 2 ",records)
                        param.successCallback(records);
                        
                        
                        
                    }).catch(function(error){
                        console.log(error);
                    })
                     
                },
                masterDetail: true
            }
        });
        // console.log(rownode.column.colDef.field);
        if(rownode.column.colDef.field == 'truck_no')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
        
    }
    
    onClickShowStackingData(rownode){
        // console.log(rownode);
        var params = {rownode:rownode.data}
        redirectURL.post("/dealers/showStackingData",params)
        .then((response) => {
            // console.log("response stacking ", response.data)
            var tblcontent = [];
            var bottomcontent = [];
            var totvols = 0;
            var totweights = 0;
            var stackOriginalData = "";
            if(response.data.status == "Success")
            {
                if(response.data.content != "" && response.data.content != undefined)
                {
                    var cnt = response.data.content;
                    try{
                        var splittext = cnt.split("#@");
                        console.log("splittext", splittext)
                    
                        var splitsc = splittext[0].split("#");
                        // console.log("splitsc", splitsc)
                        
                        if(splitsc.length > 0)
                        {
                            splitsc.map((itm) => {
                                var delim = itm.split(",");
                                if(delim.length > 0)
                                {
                                    try{

                                        if(delim[4] != "" && delim[4] != undefined)
                                        {
                                            var dvol = parseFloat(delim[4])/(1000*1000*1000);
                                            var vol = dvol.toFixed(3);
                                        }
                                        else{
                                            var dvol = "";
                                            var vol = 0;
                                        }
                                    }
                                    catch(e){
                                        
                                    }
                                    
                                    tblcontent.push({
                                        sku:delim[0],
                                        stack_length:delim[1],
                                        stack_width:delim[2],
                                        stack_height:delim[3],
                                        stack_volume:vol,
                                        stack_weight:delim[5],
                                        stack_color:delim[6]
                                    })
                                   
                                    if(delim[5] != "" && delim[5] != undefined)
                                    {
                                        var weig = delim[5];
                                    }
                                    else{
                                        var weig = 0;
                                    }
                                    // console.log("Weig", weig);
                                    totvols=parseFloat(totvols)+parseFloat(vol);
                                    totweights=parseFloat(totweights)+parseFloat(weig);
                                }
                            })
                        }
                        if(splittext.length > 0)
                        {
                            var btm = splittext[1].split("#");
                            var btnc = btm[0].split(",");
                            if(btnc.length > 0){
                                try{
                                    if(btnc[4] != "" && btnc[4] != undefined)
                                    {
                                        var dvol = parseFloat(btnc[4])/(1000*1000*1000);
                                        var vol = dvol.toFixed(3);
                                    }
                                    else{
                                        var dvol = "";
                                        var vol = 0;
                                    }
                                }
                                catch(e){
                                    
                                }
                                
                                bottomcontent.push({
                                    truck_type:btnc[0],
                                    truck_length:btnc[1],
                                    truck_width:btnc[2],
                                    truck_height:btnc[3],
                                    truck_volume:vol,
                                    truck_weight:btnc[5],
                                    truck_pallets:btnc[6]
                                })
                            }
                        }
                    }
                    catch(e){

                    }
                }
                else{
                    var cnt = "";
                }
                // console.log("totweights ", totweights)
                try{
                    tblcontent.push({
                        sku:"Total",
                        stack_length:"",
                        stack_width:"",
                        stack_height:"",
                        stack_volume:totvols.toFixed(3),
                        stack_weight:totweights.toFixed(3),
                        stack_color:""
                    })
                }
                catch(e){}
                
                this.setState({
                    stackImage : response.data.image,
                    stackOriginalData:response.data.content,
                    stacktbl:tblcontent,
                    stacktblbottom:bottomcontent,
                    showSlideBlockStack:"slide90",
                    overlayBlock:"show-m"
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Data not found.",
                    basicType:"danger"
                })
            }
            
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickRunProcess(){
        this.setState({
            overlayBlock:"show-m"
        })
        var inputdata = this.state.rowData;
        var params = {
            transaction_id:inputdata[0].transaction_id
        }
        redirectURL.post("/plan/runPlannow",params)
        setTimeout(() => {
            // this.isLoadPlanRun();
            reloadToOutputScreen()
        },4000)
    }
    
    render() {
        if(localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined )
        {
          return <Navigate to={"/login"} push={true} />
        }

        var columnwithDefs = [
            {
                headerName:"Order No",
                field:"order_no",
                width:"100",
                resizable:true
            },
            {
                headerName:"Supply Plant Name",
                field:"supply_plant_name",
                width:"100",
                resizable:true
            },
            {
                headerName:"Origin",
                field:"origin",
                width:"100",
                resizable:true
            },
            {
                headerName:"Sold To Party",
                field:"sold_to_party_name",
                width:"100",
                resizable:true
            },
            {
                headerName:"Material",
                field:"material",
                width:"160",
                resizable:true
            },
            {
                headerName:"Material Description",
                field:"material_description",
                width:"190",
                resizable:true
            },
            {
                headerName:"Billed Quantiy",
                field:"billed_quantity",
                width:"100",
                resizable:true
            },
            {
                headerName:"Truck type",
                field:"truck_type",
                width:"120",
                resizable:true
            },
            {
                headerName:"Length",
                field:"part_length",
                width:"120",
                resizable:true
            },
            
            {
                headerName:"Width",
                field:"width",
                width:"100",
                resizable:true
            },
            {
                headerName:"Height",
                field:"height",
                width:"100",
                resizable:true
            },
            
            {
                headerName:"Weight",
                field:"weight",
                width:"100",
                resizable:true
            },
            
            {
                headerName:"Stacking Norms",
                field:"stacking_norms",
                width:"100",
                resizable:true
            },
            
            {
                headerName:"Quantity final",
                field:"quantity_final",
                width:"100",
                resizable:true
            },
            
            {
                headerName:"Fragility Index",
                field:"fragility_index",
                width:"100",
                resizable:true
            },
            
        ]
        var loadplan = this.state.loadplan;
        return (
            <div class="">
                <div className={"animatenow "+(this.state.showanimate)} style={{textAlign:"center"}}>
                    <span className="pp-h">Processing Plan. Please wait.....</span>
                    <img src={require("../../assets/images/r.gif")} style={{width:"85vh"}} />
                </div>
                
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p pt-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold '>
                            <i className="fa fa-users"></i> Input Plan Summary
                            <button type="button" className="btn btn-warning float-right" onClick={this.onClickShowUpload}>Upload Input Plan</button>
                            <button type="button" className={"btn btn-danger float-right mr-10p "+(this.state.showrunprocessbtn)} onClick={this.onClickRunProcess}>Run Process</button>
                        </h5>
                    </div>
                </div>
                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockRoute)}>
                    <h5 className="crd-bg p-10p">{this.state.mpfor != "cluster"?"View Route":"Cluster - "+this.state.scluster+" suppliers in the plan"}</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <div className={this.state.nmap} id="map" style={{width:"100%",height:"90vh"}}></div>
                            <div className={this.state.nmapcluster} id="mapCluster" style={{width:"100%",height:"90vh"}}></div>
                            {(this.state.mpnode.length > 0 && this.state.mpfor != "cluster")?
                                <div className="mpinf" style={{height:"100px"}}>
                                    <div className="mpinfbg"></div>
                                    <div className="row">
                                        <div className="col-sm-3 fbold">
                                            Cluster : {this.state.mpnode[0].cluster}
                                        </div>
                                        <div className="col-sm-3 fbold">
                                        Delivery Date:  {moment.parseZone(this.state.mpnode[0].delivery_dateformat).format("DD-MM-YYYY")}
                                        </div>
                                        {this.state.mpfor != "cluster"?
                                            <div className={"col-sm-3 fbold "}>
                                                Truck No : {this.state.mpnode[0].truck_no}
                                            </div>
                                        :""}
                                        
                                        <div className={"col-sm-3 fbold "}>
                                            Distance (kms) : {this.state.mpnode[0].cummilative_distance}
                                        </div>
                                        {this.state.mpfor != "cluster"?
                                            <div className={"col-sm-3 fbold mt-20p "}>
                                                Supplier:{this.state.mpnode[0].dealer_name}
                                            </div>
                                        :""}
                                        {this.state.mpfor != "cluster"?
                                            <div className={"col-sm-3 fbold mt-20p "}>
                                                Vehicle Name: {this.state.mpnode[0].vehicle_name}
                                            </div>
                                        :""}
                                    </div>
                                </div>
                            :""}
                            
                        </div>
                    </div>
                    </div>
                    

                    <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                        <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD}</h5>
                        <div className="row">
                            
                            <div className="col-sm-12">
                            <form onSubmit={this.formUpload.bind(this)}>
                            <div className="row p-20p">
                                <div className="form-group col-sm-12 mb-20p">
                                    <label style={{color:"#000"}}>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label>
                                    
                                    <input type="file" name="upload_file" id="upload_file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="form-control" onChange={this.changeFileHandler} required /><br />
                                    <div style={{color:"#FF0000", fontSize:"12px"}}>*** Accepts only .csv files</div>
                                </div>
                                <div className="form-group col-sm-12 mb-20p">
                                <button type="submit" className="btn btn-danger">SAVE</button>
                                <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                </div>
                            </div>
                            </form>
                            </div>
                        </div>
                    </div>

                    <div className={"sliderBlock2 "+(this.state.showSlideBlockStack)}>
                    <h5 className="crd-bg p-10p">Stacking Data</h5>
                    <div className="row">
                        
                        <div className="col-sm-12" style={{textAlign:"center"}}>
                                {this.state.stackImage != ''?
                                    <img src={"data:image/png;base64,"+this.state.stackImage} />
                                :""}
                            {/* <BinPacking 
                                stackOriginalData={this.state.stackOriginalData}
                            /> */}
                        </div>
                        <div className="col-sm-12 mt-20p p-35p">
                            <div className="row mb-40p">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-10 ctbl">
                                    <table className="table" cellpadding="0" cellSpacing="0">
                                        <tr>
                                            <th>SKU</th>
                                            <th>Length (cm)</th>
                                            <th>Width (cm)</th>
                                            <th>Height (cm)</th>
                                            <th>Volume (CMT)</th>
                                            <th>Weight (Kg)</th>
                                            <th>Color</th>
                                        </tr>
                                {this.state.stacktbl.length > 0?
                                    (this.state.stacktbl.map(itm=>
                                        (itm.sku != "Total")?
                                        <tr>
                                            <td>{itm.sku}</td>
                                            <td>{itm.stack_length}</td>
                                            <td>{itm.stack_width}</td>
                                            <td>{itm.stack_height}</td>
                                            <td>{itm.stack_volume}</td>
                                            <td>{itm.stack_weight}</td>
                                            <td>{itm.stack_color}</td>
                                        </tr>
                                        :
                                        <tr>
                                            <th>{itm.sku}</th>
                                            <td>{itm.stack_length}</td>
                                            <td>{itm.stack_width}</td>
                                            <td>{itm.stack_height}</td>
                                            <th>{itm.stack_volume}</th>
                                            <th>{itm.stack_weight}</th>
                                            <td>{itm.stack_color}</td>
                                        </tr>
                                    ))
                                :""}

                                {this.state.stacktblbottom.length > 0?
                                    <tr>
                                        <th>Truck Type</th>
                                        <th>Length (cm)</th>
                                        <th>Width (cm)</th>
                                        <th>Height (cm)</th>
                                        <th>Volume (CMT)</th>
                                        <th>Weight (Kg)</th>
                                        <th>Total Pallets</th>
                                    </tr>
                                :""}
                                {this.state.stacktblbottom.length > 0?

                                    (this.state.stacktblbottom.map(itm=>
                                        <tr>
                                            <td>{itm.truck_type}</td>
                                            <td>{itm.truck_length}</td>
                                            <td>{itm.truck_width}</td>
                                            <td>{itm.truck_height}</td>
                                            <td>{itm.truck_volume}</td>
                                            <td>{itm.truck_weight}</td>
                                            <td>{itm.truck_pallets}</td>
                                        </tr>
                                    ))
                                :""}
                                    </table>
                                </div>
                                
                                <div className="col-sm-1"></div>
                            </div>
                                
                                
                            
                        </div>
                    </div>
                    </div>
            </div>
        )
    }
}

function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function closeAllInfoWindows() {
    for (var i=0;i<infowindows.length;i++) {
        infowindows[i].close();
    }
}  

function reloadToOutputScreen(){
    window.location.href = "/stackplan";
}