import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MaterialCharts from './piechart.js';

const NewChart = () => {

  const dataArrays = [
    [
      { underPredictionQty: 'UNDER PREDICTION QTY 1', percentage: 38, label: "2020-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >1 TO <=5', percentage: 35, label: "2020-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >5 TO <=10', percentage: 8, label: "2020-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >10 TO <=50', percentage: 14, label: "2020-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >50 TO <=70', percentage: 1, label: "2020-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION >70 TO <=100', percentage: 0, label: "2020-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION >100 TO <=500', percentage: 2, label: "2020-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION GREATER THAN 500 QTY', percentage: 1, label: "2020-Jan-Jun" }
    ],
    [
      { underPredictionQty: 'UNDER PREDICTION QTY 1', percentage: 37, label: "2020-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >1 TO <=5', percentage: 43, label: "2020-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >5 TO <=10', percentage: 9, label: "2020-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >10 TO <=50', percentage: 9, label: "2020-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >50 TO <=70', percentage: 0, label: "2020-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION >70 TO <=100', percentage: 0, label: "2020-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION >100 TO <=500', percentage: 0, label: "2020-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION GREATER THAN 500 QTY', percentage: 1, label: "2020-Jul-Dec" }
    ],
    [
      { underPredictionQty: 'UNDER PREDICTION QTY 1', percentage: 39, label: "2021-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >1 TO <=5', percentage: 39, label: "2021-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >5 TO <=10', percentage: 9, label: "2021-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >10 TO <=50', percentage: 9, label: "2021-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >50 TO <=70', percentage: 0, label: "2021-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION >70 TO <=100', percentage: 1, label: "2021-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION >100 TO <=500', percentage: 2, label: "2021-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION GREATER THAN 500 QTY', percentage: 0, label: "2021-Jan-Jun" }
    ],
    [
      { underPredictionQty: 'UNDER PREDICTION QTY 1', percentage: 29, label: "2021-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >1 TO <=5', percentage: 45, label: "2021-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >5 TO <=10', percentage: 10, label: "2021-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >10 TO <=50', percentage: 8, label: "2021-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >50 TO <=70', percentage: 1, label: "2021-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION >70 TO <=100', percentage: 2, label: "2021-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION >100 TO <=500', percentage: 3, label: "2021-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION GREATER THAN 500 QTY', percentage: 2, label: "2021-Jul-Dec" }
    ],
    [
      { underPredictionQty: 'UNDER PREDICTION QTY 1', percentage: 38, label: "2022-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >1 TO <=5', percentage: 42, label: "2022-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >5 TO <=10', percentage: 11, label: "2022-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >10 TO <=50', percentage: 7, label: "2022-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >50 TO <=70', percentage: 0, label: "2022-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION >70 TO <=100', percentage: 1, label: "2022-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION >100 TO <=500', percentage: 2, label: "2022-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION GREATER THAN 500 QTY', percentage: 0, label: "2022-Jan-Jun" }
    ],
    [
      { underPredictionQty: 'UNDER PREDICTION QTY 1', percentage: 32, label: "2022-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >1 TO <=5', percentage: 43, label: "2022-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >5 TO <=10', percentage: 9, label: "2022-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >10 TO <=50', percentage: 10, label: "2022-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >50 TO <=70', percentage: 0, label: "2022-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION >70 TO <=100', percentage: 1, label: "2022-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION >100 TO <=500', percentage: 3, label: "2022-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION GREATER THAN 500 QTY', percentage: 2, label: "2022-Jul-Dec" }
    ],
    [
      { underPredictionQty: 'UNDER PREDICTION QTY 1', percentage: 37, label: "2023-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >1 TO <=5', percentage: 37, label: "2023-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >5 TO <=10', percentage: 13, label: "2023-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >10 TO <=50', percentage: 11, label: "2023-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION QTY >50 TO <=70', percentage: 0, label: "2023-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION >70 TO <=100', percentage: 0, label: "2023-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION >100 TO <=500', percentage: 1, label: "2023-Jan-Jun" },
      { underPredictionQty: 'UNDER PREDICTION GREATER THAN 500 QTY', percentage: 1, label: "2023-Jan-Jun" }
    ],
    [
      { underPredictionQty: 'UNDER PREDICTION QTY 1', percentage: 39, label: "2023-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >1 TO <=5', percentage: 40, label: "2023-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >5 TO <=10', percentage: 12, label: "2023-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >10 TO <=50', percentage: 6, label: "2023-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION QTY >50 TO <=70', percentage: 0, label: "2023-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION >70 TO <=100', percentage: 0, label: "2023-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION >100 TO <=500', percentage: 1, label: "2023-Jul-Dec" },
      { underPredictionQty: 'UNDER PREDICTION GREATER THAN 500 QTY', percentage: 3, label: "2023-Jul-Dec" }
    ]
  ]
  const categories = dataArrays[0].map(item => item.underPredictionQty);

  const series = dataArrays.map((dataArray, index) => ({
    name: dataArray[0].label, // Using the label property as the series name
    data: dataArray.map(item => parseFloat(item.percentage)),
  }));

  const options = {
    chart: {
      type: 'spline',
      height: 630, // Increase the chart height
    },
    title: {
      text: "Under Prediction Quantity Variation Plot",
      style: {
        fontWeight: 'bold', // Make the title text bold
      },
    },
    tooltip: {
      shared: true,
      useHTML: true, // Allows using HTML in the tooltip
      backgroundColor: 'rgba(255, 255, 255, 0.85)', // Background color
      borderWidth: 1, // Border width
      padding: 10, // Padding for the whole container
      formatter: function () {
        const points = this.points;
        const tooltipLines = points.map(point => `<div style="margin-top: 8px; color:${point.color}">\u25CF ${point.series.name}: <strong>${point.y}%</strong></div>`);
        return `<div><strong>${this.x}</strong>${tooltipLines.join('')}</div>`;
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          fontWeight: 'bold', // Make the x-axis labels bold
          fontSize: "13px"
        },
      },
    },
    credits: {
      enabled: false  // Disable the Highcharts.com label
    },
    yAxis: {
      title: {
        text: 'Percentage ( % )',
        style: {
          fontWeight: 'bold', // Make the y-axis title bold
        },
      },
      labels: {
        style: {
          fontWeight: 'bold', // Make the y-axis labels bold
          fontWeight: "13px"
        },
      },
    },
    series: series,
  };
  var newArray = [
    {
      "Phase": "2020-Jan-Jun",
      "New Materials": [
        {
          "Material Count": 15
        },
        {
          "Material Cost K'USD (Vedanta)": 11
        },
        {
          "Material Cost K'USD (Enmovil)": 0
        }
      ],
      "No Consumption in Last 3 Years NC3Y": [
        {
          "Material Count": 26
        },
        {
          "Material Cost K'USD (Vedanta)": 5
        },
        {
          "Material Cost K'USD (Enmovil)": 1
        }
      ],
      "Consumption Started in Last 6 Months": [
        {
          "Material Count": 7
        },
        {
          "Material Cost K'USD (Vedanta)": 21
        },
        {
          "Material Cost K'USD (Enmovil)": 2
        }
      ],
      "AI-Sensitive Materials": [
        {
          "Material Count": 166
        },
        {
          "Material Cost K'USD (Vedanta)": 508
        },
        {
          "Material Cost K'USD (Enmovil)": 103
        }
      ]
    },
    {
      "Phase": "2020-Jul-Dec",
      "New Materials": [
        {
          "Material Count": 229
        },
        {
          "Material Cost K'USD (Vedanta)": 357
        },
        {
          "Material Cost K'USD (Enmovil)": 0
        }
      ],
      "No Consumption in Last 3 Years NC3Y": [
        {
          "Material Count": 100
        },
        {
          "Material Cost K'USD (Vedanta)": 131
        },
        {
          "Material Cost K'USD (Enmovil)": 8
        }
      ],
      "Consumption Started in Last 6 Months": [
        {
          "Material Count": 17
        },
        {
          "Material Cost K'USD (Vedanta)": 14
        },
        {
          "Material Cost K'USD (Enmovil)": 2
        }
      ],
      "AI-Sensitive Materials": [
        {
          "Material Count": 411
        },
        {
          "Material Cost K'USD (Vedanta)": 1156
        },
        {
          "Material Cost K'USD (Enmovil)": 120
        }
      ]
    },
    {
      "Phase": "2021-Jan-Jun",
      "New Materials": [
        {
          "Material Count": 22
        },
        {
          "Material Cost K'USD (Vedanta)": 85
        },
        {
          "Material Cost K'USD (Enmovil)": 0
        }
      ],
      "No Consumption in Last 3 Years NC3Y": [
        {
          "Material Count": 8
        },
        {
          "Material Cost K'USD (Vedanta)": 3
        },
        {
          "Material Cost K'USD (Enmovil)": 0
        }
      ],
      "Consumption Started in Last 6 Months": [
        {
          "Material Count": 7
        },
        {
          "Material Cost K'USD (Vedanta)": 4
        },
        {
          "Material Cost K'USD (Enmovil)": 4
        }
      ],
      "AI-Sensitive Materials": [
        {
          "Material Count": 147
        },
        {
          "Material Cost K'USD (Vedanta)": 477
        },
        {
          "Material Cost K'USD (Enmovil)": 84
        }
      ]
    },
    {
      "Phase": "2021-Jul-Dec",
      "New Materials": [
        {
          "Material Count": 248
        },
        {
          "Material Cost K'USD (Vedanta)": 408
        },
        {
          "Material Cost K'USD (Enmovil)": 0
        }
      ],
      "No Consumption in Last 3 Years NC3Y": [
        {
          "Material Count": 77
        },
        {
          "Material Cost K'USD (Vedanta)": 58
        },
        {
          "Material Cost K'USD (Enmovil)": 5
        }
      ],
      "Consumption Started in Last 6 Months": [
        {
          "Material Count": 22
        },
        {
          "Material Cost K'USD (Vedanta)": 169
        },
        {
          "Material Cost K'USD (Enmovil)": 1
        }
      ],
      "AI-Sensitive Materials": [
        {
          "Material Count": 334
        },
        {
          "Material Cost K'USD (Vedanta)": 928
        },
        {
          "Material Cost K'USD (Enmovil)": 93
        }
      ]
    },
    {
      "Phase": "2022-Jan-Jun",
      "New Materials": [
        {
          "Material Count": 77
        },
        {
          "Material Cost K'USD (Vedanta)": 394
        },
        {
          "Material Cost K'USD (Enmovil)": 0
        }
      ],
      "No Consumption in Last 3 Years NC3Y": [
        {
          "Material Count": 22
        },
        {
          "Material Cost K'USD (Vedanta)": 46
        },
        {
          "Material Cost K'USD (Enmovil)": 1
        }
      ],
      "Consumption Started in Last 6 Months": [
        {
          "Material Count": 4
        },
        {
          "Material Cost K'USD (Vedanta)": 17
        },
        {
          "Material Cost K'USD (Enmovil)": 2
        }
      ],
      "AI-Sensitive Materials": [
        {
          "Material Count": 148
        },
        {
          "Material Cost K'USD (Vedanta)": 355
        },
        {
          "Material Cost K'USD (Enmovil)": 152
        }
      ]
    },
    {
      "Phase": "2022-Jul-Dec",
      "New Materials": [
        {
          "Material Count": 177
        },
        {
          "Material Cost K'USD (Vedanta)": 456
        },
        {
          "Material Cost K'USD (Enmovil)": 0
        }
      ],
      "No Consumption in Last 3 Years NC3Y": [
        {
          "Material Count": 51
        },
        {
          "Material Cost K'USD (Vedanta)": 61
        },
        {
          "Material Cost K'USD (Enmovil)": 1
        }
      ],
      "Consumption Started in Last 6 Months": [
        {
          "Material Count": 43
        },
        {
          "Material Cost K'USD (Vedanta)": 350
        },
        {
          "Material Cost K'USD (Enmovil)": 4
        }
      ],
      "AI-Sensitive Materials": [
        {
          "Material Count": 220
        },
        {
          "Material Cost K'USD (Vedanta)": 744
        },
        {
          "Material Cost K'USD (Enmovil)": 134
        }
      ]
    },
    {
      "Phase": "2023-Jan-Jun",
      "New Materials": [
        {
          "Material Count": 87
        },
        {
          "Material Cost K'USD (Vedanta)": 96
        },
        {
          "Material Cost K'USD (Enmovil)": 0
        }
      ],
      "No Consumption in Last 3 Years NC3Y": [
        {
          "Material Count": 29
        },
        {
          "Material Cost K'USD (Vedanta)": 35
        },
        {
          "Material Cost K'USD (Enmovil)": 1
        }
      ],
      "Consumption Started in Last 6 Months": [
        {
          "Material Count": 11
        },
        {
          "Material Cost K'USD (Vedanta)": 23
        },
        {
          "Material Cost K'USD (Enmovil)": 1
        }
      ],
      "AI-Sensitive Materials": [
        {
          "Material Count": 203
        },
        {
          "Material Cost K'USD (Vedanta)": 568
        },
        {
          "Material Cost K'USD (Enmovil)": 116
        }
      ]
    },
    {
      "Phase": "2023-Jul-Oct",
      "New Materials": [
        {
          "Material Count": 111
        },
        {
          "Material Cost K'USD (Vedanta)": 135
        },
        {
          "Material Cost K'USD (Enmovil)": 0
        }
      ],
      "No Consumption in Last 3 Years NC3Y": [
        {
          "Material Count": 86
        },
        {
          "Material Cost K'USD (Vedanta)": 53
        },
        {
          "Material Cost K'USD (Enmovil)": 5
        }
      ],
      "Consumption Started in Last 6 Months": [
        {
          "Material Count": 22
        },
        {
          "Material Cost K'USD (Vedanta)": 22
        },
        {
          "Material Cost K'USD (Enmovil)": 12
        }
      ],
      "AI-Sensitive Materials": [
        {
          "Material Count": 359
        },
        {
          "Material Cost K'USD (Vedanta)": 939
        },
        {
          "Material Cost K'USD (Enmovil)": 136
        }
      ]
    }
  ];


  return (
    <div>
      {/* <HighchartsReact highcharts={Highcharts} options={options} style={{ width: "100%", height: "700px", backgroundColor: "blue" }} /> */}
      <div style={{ width: "100%", marginTop: "0px" }}>
        <MaterialCharts dataArray={newArray} />
      </div>
    </div>
  );
};


export default NewChart;

// class NewChart extends Component {
//     constructor(props) {
//       super(props);

//       this.state = {
//         chartOptions: this.getInitialChartOptions(),
//         drilldownActive: false,
//       };
//     }

//     getInitialChartOptions() {
//       return {
//         chart: {
//           type: 'column',
//         },
//         title: {
//           text: 'Drilldown Chart Example',
//         },
//         xAxis: {
//           type: 'category',
//         },
//         yAxis: {
//           title: {
//             text: 'Value',
//           },
//         },
//         plotOptions: {
//           series: {
//             cursor: 'pointer',
//             point: {
//               events: {
//                 click: this.handlePointClick.bind(this),
//               },
//             },
//           },
//         },
//         series: [
//           {
//             name: 'Initial Data',
//             colorByPoint: true,
//             data: [
//               {
//                 name: 'Category A',
//                 y: 10,
//                 drilldown: 'categoryA',
//               },
//               {
//                 name: 'Category B',
//                 y: 20,
//                 drilldown: 'categoryB',
//               },
//               {
//                 name: 'Category C',
//                 y: 15,
//                 drilldown: 'categoryC',
//               },
//             ],
//           },
//         ],
//         drilldown: {
//           series: [],
//         },
//       };
//     }

//     handlePointClick(event) {
//       if (this.state.drilldownActive) {
//         // Drilldown is already active, so return early
//         return;
//       }

//       if (event.point.drilldown) {
//         const drilldownData = this.getDrilldownData(event.point.drilldown);

//         const chartOptions = {
//           ...this.state.chartOptions,
//           series: [
//             {
//               name: event.point.name,
//               data: drilldownData,
//               type: 'column',
//             },
//           ],
//         };

//         this.setState({
//           chartOptions,
//           drilldownActive: true,
//         });
//       }
//     }

//     handleBackClick() {
//       const chartOptions = {
//         ...this.state.chartOptions,
//         series: this.getInitialChartOptions().series,
//       };

//       this.setState({
//         chartOptions,
//         drilldownActive: false,
//       });
//     }

//     getDrilldownData(drilldownId) {
//       switch (drilldownId) {
//         case 'categoryA':
//           return [
//             ['Subcategory 1', 5],
//             ['Subcategory 2', 3],
//             ['Subcategory 3', 2],
//           ];
//         case 'categoryB':
//           return [
//             ['Subcategory 1', 8],
//             ['Subcategory 2', 7],
//             ['Subcategory 3', 5],
//           ];
//         case 'categoryC':
//           return [
//             ['Subcategory 1', 4],
//             ['Subcategory 2', 6],
//             ['Subcategory 3', 5],
//           ];
//         default:
//           return [];
//       }
//     }


//   render() {
//     return (
//       <div className="row">
//         <div className="col-sm-12">
//         {this.state.drilldownActive ? (
//           <button className="btn btn-primary" onClick={this.handleBackClick.bind(this)} style={{position: "absolute", left: "85%", top: "5%", zIndex: "1"}}>Back to Main Chart</button>
//         ) : null}
//         <HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} />
//         </div>
//       </div>
//     );
//   }
// }

// export default NewChart;