import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";
import ColumnChart from "./columnChart"
import PieChart from "./pieChart";
import $ from "jquery";
import LoadSummaryDrawmap from './loadSummaryDrawmap.js';

export default class ViewDispatchPlanDetails extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleAvailabilityModel:false,
            openFreightConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            showAvailablityTable:false,
            data_type:'',
            location:'',
            vehicle_type:{"label":"Select Vehicle Type",value:""},
            state_name:{"label":"Select State",value:""},
            city:'',
            fixed_vehicle_cost:'',
            driver_cost:'',
            maintenance_cost:'',
            per_cmt_cost:'',
            per_km_cost:'',
            rowId:'',
            locationNames:[],
            vehicle_types:[],
            state_names:[],
            transactionDetails:[],
            outputDetails:[],
            inputDetails:[],
            config:"",
            plannedTrucks : "",
            unPlannedTrucks : "",
            loadsDropped : "",
            inputInfo : "",
            configData:"show-n",
            plannedTrucksData:"show-n",
            unPlannedTrucksData:"show-n",
            loadsDroppedData:"show-n",
            inputInfoData:"show-n",
            vehicle_type_config:[],
            material_configuration:[],
            freight_configuration:[],
            vehicle_availability_config:[],
            plannedData:[],
            unPlannedData:[],
            loadDroppedDetails:[],
            planningColumnChart:[],
            planningPieChart:[],
            summary:'activet',
            summaryData:"show-m",
            total_demand_cmt:0,
            total_dropped_loads:0,
            tool_config : "",
            overlayBlock:"show-n",
            showSlideBlockUpload:"sidebarcls",
            sliderTitle:"",
            loadingLocation : [],
            mapType : 1,
            latLngArray : [],
            orderId:'',
            transactionId:''
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        let url_type = ul[parseInt(ul.length)-1];
        var transactionId =url_type
        let orderId
        if(transactionId !=undefined && transactionId !="undefined" && transactionId !="")
        {
            transactionId = window.atob(transactionId);
            let order = transactionId.split('-')
            orderId = order[0]
        }
        await this.setState({transactionId : transactionId,
                            orderId:orderId})
        let params = {
            transactionId : transactionId
        }
        await this.getChartsData(transactionId,orderId)
        await this.getPlanDetails()
        await this.getPlanOutputDetails()
        await this.getPlanInputDetails()
    }

    getChartsData = (transactionId,orderId) =>{
        var transactionId = transactionId
        let qry = JSON.parse(localStorage.getItem('qry'));

        try{
            var reqparams = new FormData()
            reqparams.append("transaction_id", transactionId)
            reqparams.append("order_id", orderId)
            reqparams.append("plant_location_code", qry.plant_location_code)
            reqparams.append("dispatch_planning_type", qry.dispatch_planning_type)

            
            redirectURL.post("http://216.48.191.229:8002/dispatch_planning/transactionLevelChartsData",reqparams)
            .then(async(response) => {
                
                    let planData = JSON.parse(response.data.planning_chart_data)
                    let dropData = JSON.parse(response.data.dropped_reason_chart_data)
                    let total_dropped_loads = dropData[0].total_dropped_loads;
                    let total_demand_cmt = planData[0].total_demand_cmt;
                   let planningColumnChart = [{
                    name:"Planning",
                    colorByPoint: true,
                    data :  [{
                        name:"Total Demand CMT",
                        y: (planData[0].total_demand_cmt)
                    },{
                        name:"Planned Demand CMT",
                        y: (planData[0].planned_demand_cmt)
                    },{
                        name:"Dropped Demand CMT",
                        y: (planData[0].dropped_demand_cmt)
                    },{
                        name:"Unplanned Demand CMT",
                        y: (planData[0].unplanned_demand_cmt)
                    }]
                   }]
                   let planningPieChart = [{
                    name:"Dropped",
                    colorByPoint: true,
                    data :  [
                        {
                         name:"Total Dropped Loads",
                         y: (dropData[0].total_dropped_loads)
                        },{
                         name:"Invalid Locations",
                         y: (dropData[0].invalid_locations)
                        },{
                        name:"Invalid Materials",
                        y: (dropData[0].invalid_materials)
                    }]
                   }]
                    
                    await this.setState({
                        planningColumnChart:planningColumnChart,
                        planningPieChart:planningPieChart,
                        total_dropped_loads:total_dropped_loads,
                        total_demand_cmt:total_demand_cmt
                       })

            })
            }catch(e){
                
            }
    }

    getPlanDetails = () =>{
        var params = {
            transactionId : this.state.transactionId
        }
        redirectURL.post('dispatch/getTransactionDetails',params)
        .then(async response =>{
            if(response.data.length > 0)
            {
                var toolParams = {
                    data_type : response.data[0].data_type
                }
                await getToolConfiguration(toolParams)
                await this.boundTypeLocations(toolParams)
                var tool_config = "";
                if(response.data[0].tool_config !=undefined && response.data[0].tool_config !="undefined" && response.data[0].tool_config !="")
                {
                    tool_config = response.data[0].tool_config;
                }
                var vehicle_type_config = [];
                if(response.data[0].vehicle_type_config !=undefined && response.data[0].vehicle_type_config !="undefined" && response.data[0].vehicle_type_config !="")
                {
                    if(typeof(response.data[0].vehicle_type_config) == "string")
                    {
                        vehicle_type_config = JSON.parse(response.data[0].vehicle_type_config);
                    }
                    else
                    {
                        vehicle_type_config = response.data[0].vehicle_type_config;
                    }
                }
                var material_configuration = [];
                if(response.data[0].material_configuration !=undefined && response.data[0].material_configuration !="undefined" && response.data[0].material_configuration !="")
                {
                    if(typeof(response.data[0].material_configuration) == "string")
                    {
                        material_configuration = JSON.parse(response.data[0].material_configuration);
                    }
                    else
                    {
                        material_configuration = response.data[0].material_configuration;
                    }
                }
                var freight_configuration = [];
                if(response.data[0].freight_configuration !=undefined && response.data[0].freight_configuration !="undefined" && response.data[0].freight_configuration !="")
                {
                    if(typeof(response.data[0].freight_configuration) == "string")
                    {
                        freight_configuration = JSON.parse(response.data[0].freight_configuration);
                    }
                    else
                    {
                        freight_configuration = response.data[0].freight_configuration;
                    }
                }
                var vehicle_availability_config = [];
                if(response.data[0].vehicle_availability_config !=undefined && response.data[0].vehicle_availability_config !="undefined" && response.data[0].vehicle_availability_config !="")
                {
                    if(typeof(response.data[0].vehicle_availability_config) == "string")
                    {
                        vehicle_availability_config = JSON.parse(response.data[0].vehicle_availability_config);
                    }
                    else
                    {
                        vehicle_availability_config = response.data[0].vehicle_availability_config;
                    }
                }
                this.setState({
                    transactionDetails:response.data,
                    tool_config:tool_config,
                    vehicle_type_config:vehicle_type_config,
                    material_configuration:material_configuration,
                    freight_configuration:freight_configuration,
                    vehicle_availability_config:vehicle_availability_config,
                })
            }
        })
    }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name,latitude:item.latitude,longitude:item.longitude })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    getPlanOutputDetails = () =>{
        var params = {
            transactionId : this.state.transactionId
        }
        redirectURL.post('dispatch/getTransactionOutputDetails',params)
        .then(async response =>{
            var outputDetails = response.data;
            var plannedData = [];
            var unPlannedData = [];
            if(outputDetails.length > 0)
            {
                var plannedIndividualData = await outputDetails.filter((od) => od.is_planned == 1);
                if(plannedIndividualData.length > 0)
                {
                    var groupPlannedData = groupBy(plannedIndividualData, rdata => rdata.vehicle_no);
                    groupPlannedData.forEach((plannedVehicleData, vehicleNo) =>{
                        var demand = 0;
                        var distance = 0;
                        var latLngArray = [];
                        plannedVehicleData.map((pvd)=>{
                            demand = parseInt(demand)+parseInt(pvd.demand);
                            distance = parseInt(distance)+parseInt(pvd.distance);
                            latLngArray.push({
                                lat : pvd.demand_node_latitude,
                                lng : pvd.demand_node_longitude,
                                drop_node : pvd.drop_node,
                                vehicle_type : pvd.vehicle_type,
                                demand : pvd.demand,
                            })
                        });
                        plannedData.push({
                            vehicle_no:plannedVehicleData[0].vehicle_no,
                            cluster:plannedVehicleData[0].cluster,
                            zone:plannedVehicleData[0].zone,
                            pickup_node:plannedVehicleData[0].pickup_node,
                            drop_node:plannedVehicleData[0].drop_node,
                            demand:demand,
                            distance:distance,
                            route_no:plannedVehicleData[0].route_no,
                            vehicle_type:plannedVehicleData[0].vehicle_type,
                            vehicle_volume:plannedVehicleData[0].vehicle_volume,
                            vehicle_priority:plannedVehicleData[0].vehicle_priority,
                            transporter_code:plannedVehicleData[0].transporter_code,
                            transporter_name:plannedVehicleData[0].transporter_name,
                            route_mode:plannedVehicleData[0].route_mode,
                            no_of_drops:plannedVehicleData.length,
                            legs_data:plannedVehicleData,
                            latLngArray:latLngArray,
                            trip_no:plannedVehicleData[0].trip_no
                        })
                    });
                }
                unPlannedData = await outputDetails.filter((od) => od.is_planned != 1);
            }
            this.setState({
                outputDetails:response.data,
                plannedData:plannedData,
                unPlannedData:unPlannedData
            })
        })
    }

    getPlanInputDetails = () =>{
        var params = {
            // transactionId : this.state.transactionId
            order_id:this.state.orderId
        }
        redirectURL.post('dispatch/getTransactionInputDetails',params)
        .then(async response =>{

            var inputDetails = response.data;
            var validInputDetails = [];
            var invalidInputDetails = [];
            if(inputDetails.length > 0)
            {
                validInputDetails = await inputDetails.filter((od) => od.invalid_data != 1);
                invalidInputDetails = await inputDetails.filter((od) => od.invalid_data == 1);
            }
            
            this.setState({
                inputDetails:validInputDetails,
                loadDroppedDetails:invalidInputDetails
            })
        })
    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    onClickTab = (pageType) => {
        if(pageType == "config")
        {
            var config = "activet";
            var plannedTrucks = "";
            var unPlannedTrucks = "";
            var loadsDropped = "";
            var inputInfo = "";
            var summary = "";
            var configData = "show-m";
            var plannedTrucksData = "show-n";
            var unPlannedTrucksData = "show-n";
            var loadsDroppedData = "show-n";
            var inputInfoData = "show-n";
            var summaryData = "show-n";
        }
        else if(pageType == "plannedTrucks")
        {

            var config = "";
            var plannedTrucks = "activet";
            var unPlannedTrucks = "";
            var loadsDropped = "";
            var inputInfo = "";
            var summary = "";
            var configData = "show-n";
            var plannedTrucksData = "show-m";
            var unPlannedTrucksData = "show-n";
            var loadsDroppedData = "show-n";
            var inputInfoData = "show-n";
            var summaryData = "show-n";
            
        }
        else if(pageType == "unPlannedTrucks")
        {
            var config = "";
            var plannedTrucks = "";
            var unPlannedTrucks = "activet";
            var loadsDropped = "";
            var inputInfo = "";
            var summary = "";
            var configData = "show-n";
            var plannedTrucksData = "show-n";
            var unPlannedTrucksData = "show-m";
            var loadsDroppedData = "show-n";
            var inputInfoData = "show-n";
            var summaryData = "show-n";
        }
        else if(pageType == "loadsDropped")
        {
            var config = "";
            var plannedTrucks = "";
            var unPlannedTrucks = "";
            var loadsDropped = "activet";
            var inputInfo = "";
            var summary = "";
            var configData = "show-n";
            var plannedTrucksData = "show-n";
            var unPlannedTrucksData = "show-n";
            var loadsDroppedData = "show-m";
            var inputInfoData = "show-n";
            var summaryData = "show-n";
        }
        else if(pageType == "inputInfo")
        {
            var config = "";
            var plannedTrucks = "";
            var unPlannedTrucks = "";
            var loadsDropped = "";
            var inputInfo = "activet";
            var summary = "";
            var configData = "show-n";
            var plannedTrucksData = "show-n";
            var unPlannedTrucksData = "show-n";
            var loadsDroppedData = "show-n";
            var inputInfoData = "show-m";
            var summaryData = "show-n";
        }
        else if(pageType == "summary")
        {
            var config = "";
            var plannedTrucks = "";
            var unPlannedTrucks = "";
            var loadsDropped = "";
            var inputInfo = "";
            var summary = "activet";
            var configData = "show-n";
            var plannedTrucksData = "show-n";
            var unPlannedTrucksData = "show-n";
            var loadsDroppedData = "show-n";
            var inputInfoData = "show-n";
            var summaryData = "show-m";
        }
        this.setState({
            config:config,
            plannedTrucks:plannedTrucks,
            unPlannedTrucks:unPlannedTrucks,
            loadsDropped:loadsDropped,
            inputInfo:inputInfo,
            configData:configData,
            summary:summary,
            plannedTrucksData:plannedTrucksData,
            unPlannedTrucksData:unPlannedTrucksData,
            loadsDroppedData:loadsDroppedData,
            inputInfoData:inputInfoData,
            summaryData:summaryData
        })
    }
    

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlockUpload:"",
            loadshow:'show-n',
            overly:'show-n',
        })
    }
    onRouteData = async(row) =>{
        var loadingLocation = [];
        if(this.state.location !=undefined && this.state.location !="")
        {
            loadingLocation.push({
                lat : this.state.location.latitude,
                lng : this.state.location.longitude,
            })
        }
        this.setState({
            loadingLocation : loadingLocation,
            mapType : 1,
            latLngArray : row.data.latLngArray,
            showSlideBlockUpload:"slide45",
            overlayBlock:"show-m",
            sliderTitle : "Trip : "+row.data.trip_no+" Total Distance : "+row.data.distance+" Km"
        })
    }
    onShowLegsData = async(row) => { 
        var plannedcolsDefs1 = [
            {
                headerName:"Trip No",
                field:"trip_no",
                width:"100"
            },
            {
                headerName:"Vehicle No",
                field:"vehicle_no",
                width:"100"
            },
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"120"
            },
            {
                headerName:"Transaporter Code",
                field:"transporter_code",
                width:"150"
            },
            {
                headerName:"Transaporter Name",
                field:"transporter_name",
                width:"150"
            },
            {
                headerName:"Route Mode",
                field:"route_mode",
                width:"120"
            },
            {
                headerName:"Pick Up Location (Source)",
                field:"pickup_node",
                width:"150",
            },
            {
                headerName:"Load (MM)",
                field:"demand",
                width:"110",
            },
            {
                headerName:"Distance (KM)",
                field:"distance",
                width:"120",
            },
            {
                headerName:"Zone",
                field:"zone",
                width:"100"
            },
            {
                headerName:"Drop Location (Destination)",
                field:"drop_node",
                width:"150"
            },
            {
                headerName:"Vehicle Volume",
                field:"vehicle_volume",
                width:"120"
            },
            {
                headerName:"Vehicle Priority",
                field:"vehicle_priority",
                width:"120"
            },
        ]
        if(row.data.legs_data != 0)
        {
            await this.setState({                        
                detailCellRendererParams:{
                    detailGridOptions: {
                        columnDefs: plannedcolsDefs1,
                        overlayNoRowsTemplate: 'No rows to show',
                                                        
                    },
                    getDetailRowData:async function(param) {
                        param.successCallback(row.data.legs_data);                             
                    },
                    masterDetail: true
                }
            });
            // console.log(propsdata);
            if(row.colDef.field == "details")
            {
                row.node.setExpanded(!row.node.expanded);
            }
            else{    
                row.node.setExpanded(false);
            }                
        }
        else{
            this.setState({
            
                detailCellRendererParams:{
                    detailGridOptions: {
                      columnDefs: plannedcolsDefs1
                      
                    },
                    getDetailRowData: function(params) {
                        //console.log("Child ", params);
                      params.successCallback([]);
                    }
                  }
            });
        }
    }
    render(){

        var vehicleTypecolsDefs = [
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"100"
            },
            {
                headerName:"Vehicle Name",
                field:"vehicle_name",
                width:"100"
            },
            {
                headerName:"Vehicle Priority",
                field:"vehicle_priority",
                width:"100"
            },
            {
                headerName:"Vehicle Availability(from)",
                field:"vehicle_availability_from",
                width:"150"
            },
            {
                headerName:"Vehicle Availability(to)",
                field:"vehicle_availability_to",
                width:"140"
            },
            {
                headerName:"Break Time(from)",
                field:"break_time_from",
                width:"110"
            },
            {
                headerName:"Break Time(to) ",
                field:"break_time_to",
                width:"100"
            },
            {
                headerName:"Min Volume (CMT)",
                field:"min_volume_cmt",
                width:"120"
            },
            {
                headerName:"Max Volume (CMT)",
                field:"max_volume_cmt",
                width:"120"
            },
            {
                headerName:"Vehicle Width (mm)",
                field:"vehicle_dimensions_width_mm",
                width:"120"
            },
            {
                headerName:"Vehicle Height (mm)",
                field:"vehicle_dimensions_height_mm",
                width:"125"
            },
            {
                headerName:"Vehicle Depth (mm)",
                field:"vehicle_dimensions_depth_mm",
                width:"120"
            },
            {
                headerName:"Vehicle Min Weight (Kgs)",
                field:"vehicle_min_weight",
                width:"145"
            },
            {
                headerName:"Vehicle Max Weight (Kgs)",
                field:"vehicle_max_weight",
                width:"145"
            },
            {
                headerName:"Vehicle Capacity (CMT)",
                field:"vehicle_capacity",
                width:"135"
            },
            {
                headerName:"No Of Deliveries",
                field:"max_no_of_nodes",
                width:"100"
            },
            {
                headerName:"Min Load Per Delivery",
                field:"min_load_per_delivery",
                width:"140"
            },
            {
                headerName:"Max Distance (Kms)",
                field:"max_distance",
                width:"120"
            }
        ]
        var materialConfigcolsDefs = [
            {
                headerName:Constant.MATERIAL_CODE,
                field:"material_code",
                width:"120"
            },
            {
                headerName:Constant.MATERIAL_NAME,
                field:"material_name",
                width:"130"
            },
            {
                headerName:Constant.MATERIAL_TYPE,
                field:"material_type",
                width:"130"
            },
            {
                headerName:Constant.MATERIAL_WEIGHT,
                field:"material_weight",
                width:"150",
            },
            {
                headerName:Constant.MATERIAL_DIMENSIONS_DEPTH,
                field:"material_dimensions_depth_mm",
                width:"200"
            },
            {
                headerName:Constant.MATERIAL_DIMENSIONS_WIDTH,
                field:"material_dimensions_width_mm",
                width:"200"
            },
            {
                headerName:Constant.MATERIAL_DIMENSIONS_HEIGHT,
                field:"material_dimensions_height_mm",
                width:"200"
            }
        ]
        var freightConfigcolsDefs = [
            {
                headerName:Constant.FORM_VEHICLE_TYPES,
                field:"vehicle_type",
                width:"120"
            },
            {
                headerName:Constant.DEALER_FORM_STATE,
                field:"state",
                width:"130"
            },
            {
                headerName:Constant.DEALER_FORM_CITY,
                field:"city",
                width:"130"
            },
            {
                headerName:Constant.FREIGHT_FIXED_VEHICLE_COST,
                field:"fixed_vehicle_cost",
                width:"130"
            },
            {
                headerName:Constant.FREIGHT_DRIVER_COST,
                field:"driver_cost",
                width:"150",
            },
            {
                headerName:Constant.FREIGHT_MAINTENANCE_COST,
                field:"maintenance_cost",
                width:"200"
            },
            {
                headerName:Constant.FREIGHT_PER_CMT_COST,
                field:"per_cmt_cost",
                width:"200"
            },
            {
                headerName:Constant.FREIGHT_PER_KM_COST,
                field:"per_km_cost",
                width:"200"
            }
        ]
        var vehicleAvailabilityConfigcolsDefs = [
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"100"
            },
            {
                headerName:"Date",
                field:"vehicle_date",
                width:"100"
            },
            {
                headerName:"Vehicle Number",
                field:"vehicle_no",
                width:"100"
            },
            {
                headerName:"Vehicle Availability(from)",
                field:"vehicle_availability_from",
                width:"150",
            },
            {
                headerName:"Vehicle Availability(to)",
                field:"vehicle_availability_to",
                width:"140"
            },
            {
                headerName:"Break Time(from)",
                field:"break_time_from",
                width:"110",
            },
            {
                headerName:"Break Time(to) ",
                field:"break_time_to",
                width:"100",
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:"120",
            },
            {
                headerName:"Vehicle Priority",
                field:"vehicle_priority",
                width:"100"
            },
        ]
        
        var plannedcolsDefs = [
            {
                headerName: "",
                field: "_id",
                width:50,
                filter: true,
                resizable: true,
                editable:false,                
                params:{buttonName:'Route',iconName:'fa fa-map-marker',
                onClickFunction:this.onRouteData},
                cellRendererSelector:function(params){
                    var rendComponent = {
                        component: 'GridButton'
                    };
                    return rendComponent
                },
            },
            {
                headerName: "Details",
                field: "details",
                width:80,
                filter: true,
                resizable: true,
                editable:false,
                params:{buttonName:'View',iconName:'fa fa-eye',
                onClickFunction:this.onShowLegsData},
                cellRendererSelector:function(params){
                    var rendComponent = {
                        component: 'GridButton'
                    };
                    return rendComponent
                },
            },
            {
                headerName:"Trip No",
                field:"trip_no",
                width:"100"
            },
            {
                headerName:"Vehicle No",
                field:"vehicle_no",
                width:"100"
            },
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"120"
            },
            {
                headerName:"Transaporter Code",
                field:"transporter_code",
                width:"150"
            },
            {
                headerName:"Transaporter Name",
                field:"transporter_name",
                width:"150"
            },
            {
                headerName:"Route Mode",
                field:"route_mode",
                width:"120"
            },
            {
                headerName:"No Of Drops",
                field:"no_of_drops",
                width:"120",
            },
            {
                headerName:"Pick Up Location (Source)",
                field:"pickup_node",
                width:"150",
            },
            {
                headerName:"Trip Load (MM)",
                field:"demand",
                width:"110",
            },
            {
                headerName:"Trip Distance (KM)",
                field:"distance",
                width:"120",
            },
            {
                headerName:"Zone",
                field:"zone",
                width:"100"
            },
            {
                headerName:"Drop Location (Destination)",
                field:"drop_node",
                width:"150"
            },
            // {
            //     headerName:"Route No",
            //     field:"route_no",
            //     width:"120",
            // },
            {
                headerName:"Vehicle Volume",
                field:"vehicle_volume",
                width:"120"
            },
            {
                headerName:"Vehicle Priority",
                field:"vehicle_priority",
                width:"120"
            },
        ]

        var unPlannedcolsDefs = [
            // {
            //     headerName:"Vehicle No",
            //     field:"vehicle_no",
            //     width:"100"
            // },
            // {
            //     headerName:"Cluster",
            //     field:"cluster",
            //     width:"100"
            // },
            // {
            //     headerName:"Zone",
            //     field:"zone",
            //     width:"100"
            // },
            // {
            //     headerName:"Pick Up Location (Source)",
            //     field:"pickup_node",
            //     width:"150",
            // },
            // {
            //     headerName:"Drop Location (Destination)",
            //     field:"drop_node",
            //     width:"150"
            // },
            {
                headerName:"Demand (CMT)",
                field:"demand",
                width:"110",
            },
            {
                headerName:"Distance (KM)",
                field:"distance",
                width:"100",
            },
            // {
            //     headerName:"Route No",
            //     field:"route_no",
            //     width:"120",
            // },
            // {
            //     headerName:"Vehicle Type",
            //     field:"vehicle_type",
            //     width:"120"
            // },
            // {
            //     headerName:"Vehicle Volume",
            //     field:"vehicle_volume",
            //     width:"120"
            // },
            // {
            //     headerName:"Vehicle Priority",
            //     field:"vehicle_priority",
            //     width:"120"
            // },
            // {
            //     headerName:"Transaporter Code",
            //     field:"transporter_code",
            //     width:"120"
            // },
            // {
            //     headerName:"Transaporter Name",
            //     field:"transporter_name",
            //     width:"120"
            // },
            // {
            //     headerName:"Route Mode",
            //     field:"route_mode",
            //     width:"120"
            // },
        ]
        var tool_config = this.state.tool_config;
        var material_configuration_chk = 0;
        if(tool_config !=undefined && tool_config !="")
        {
            material_configuration_chk = tool_config[0].material_configuration;
        }
        var inputDetailscolsDefs = [
            {
                headerName:"Pick Up Location (Source)",
                field:"pickup_location_code",
                width:"250",
            },
            {
                headerName:"Drop Location (Destination)",
                field:"drop_location_code",
                width:"250"
            },
        ]
        if(material_configuration_chk == 1)
        {
            inputDetailscolsDefs.push(
                {
                    headerName:"Material",
                    field:"material_code",
                    width:"250",
                },
                {
                    headerName:"Quantity",
                    field:"quantity",
                    width:"250",
                }
            );
        }
        else
        {
            inputDetailscolsDefs.push(
                {
                    headerName:"Demand (CMT)",
                    field:"demand_cmt",
                    width:"250",
                }
            );
        }
        var loadsDroppedcolsDefs = [            
            {
                headerName:"Drop Location (Destination)",
                field:"drop_location_code",
                width:"250"
            },
            {
                headerName:"Dropped Reason",
                field:"invalid_locations",
                width:"250",
            },           
        ]
        if(material_configuration_chk == 1)
        {
            loadsDroppedcolsDefs.push(
                {
                    headerName:"Material",
                    field:"material_code",
                    width:"250",
                },
                {
                    headerName:"Invalid Material",
                    field:"invalid_material",
                    width:"250",
                }, 
            );
        }
        else
        {
            
        }

        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12 d-flex">
                <div className= {this.state.showAvailablityTable ? "col-sm-7 " : "col-sm-12 " + "  d-inline"}>
                        <h5 className='fbold d-flex justify-content-between'>
                            <span>Dispatch Plan Details - {this.state.transactionId}</span>
                            {/* <div className='float-right'>
                                <a href="javascript:;" onClick={this.openModelForFreightConfiguration} className="f18 float-right themefont fbold mr-10p mb-10p">Add Freight Info</a>
                            </div>  */}

                        </h5>
                        <div className="row mt-20p">
                            <div className="col-md-12 col-sm-12">
                                <ul className="d-tabs">

                                    <li onClick={this.onClickTab.bind(this,"inputInfo")} className={"lttabs "+(this.state.inputInfo)}>
                                        <button type="button" className="btn">Input Data</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"summary")} className={"lttabs "+(this.state.summary)}>
                                        <button type="button" className="btn">Summary</button>
                                    </li>

                                    {/* <li onClick={this.onClickTab.bind(this,"config")} className={"lttabs "+(this.state.config)}>
                                        <button type="button" className="btn">Configurations</button>
                                    </li> */}

                                    <li onClick={this.onClickTab.bind(this,"plannedTrucks")} className={"lttabs "+(this.state.plannedTrucks)}>
                                        <button type="button" className="btn">Planned Vehicles</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"unPlannedTrucks")} className={"lttabs "+(this.state.unPlannedTrucks)}>
                                        <button type="button" className="btn">Unplanned Vehicles</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"loadsDropped")} className={"lttabs "+(this.state.loadsDropped)}>
                                        <button type="button" className="btn">Loads Dropped</button>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>

                        <div className={"row mt-20p "+this.state.configData}>
                            {(this.state.vehicle_type_config.length > 0)?
                            <div className="col-md-6 col-sm-6">
                                <div className="col-md-12 col-sm-12">
                                    <h5 className="summaryDetailsTitle">Vehicle Type Configurations</h5>
                                </div>
                                <div id="myGrid" style={{width:"100%",height:"40vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                    <AgGridReact
                                    modules={this.state.modules} 
                                        rowData={this.state.vehicle_type_config}
                                        columnDefs={vehicleTypecolsDefs}
                                        gridOptions={{context:{componentParent:this}}}
                                        defaultColDef={this.state.defaultColDef}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection= {true}
                                    />
                                </div>
                            </div>
                            :""}
                            {(this.state.material_configuration.length > 0)?
                            <div className="col-md-6 col-sm-6">
                                <div className="col-md-12 col-sm-12">
                                    <h5 className="summaryDetailsTitle">Material Configurations</h5>
                                </div>
                                <div id="myGrid" style={{width:"100%",height:"40vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                    <AgGridReact
                                    modules={this.state.modules} 
                                        rowData={this.state.material_configuration}
                                        columnDefs={materialConfigcolsDefs}
                                        gridOptions={{context:{componentParent:this}}}
                                        defaultColDef={this.state.defaultColDef}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection= {true}
                                    />
                                </div>
                            </div>
                            :""}
                            {(this.state.freight_configuration.length > 0)?
                            <div className="col-md-6 col-sm-6">
                                <div className="col-md-12 col-sm-12 mt-20p">
                                    <h5 className="summaryDetailsTitle">Freight Configurations</h5>
                                </div>
                                <div id="myGrid" style={{width:"100%",height:"40vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                    <AgGridReact
                                    modules={this.state.modules} 
                                        rowData={this.state.freight_configuration}
                                        columnDefs={freightConfigcolsDefs}
                                        gridOptions={{context:{componentParent:this}}}
                                        defaultColDef={this.state.defaultColDef}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection= {true}
                                    />
                                </div>
                            </div>
                            :""}
                            {(this.state.vehicle_availability_config.length > 0)?
                            <div className="col-md-6 col-sm-6">
                                <div className="col-md-12 col-sm-12 mt-20p">
                                    <h5 className="summaryDetailsTitle">Vehicle Availability Configurations</h5>
                                </div>
                                <div id="myGrid" style={{width:"100%",height:"40vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                    <AgGridReact
                                    modules={this.state.modules} 
                                        rowData={this.state.vehicle_availability_config}
                                        columnDefs={vehicleAvailabilityConfigcolsDefs}
                                        gridOptions={{context:{componentParent:this}}}
                                        defaultColDef={this.state.defaultColDef}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection= {true}
                                    />
                                </div>
                            </div>
                            :""}
                        </div>
                        
                        {/* Planned Trucks Data */}
                        <div className={"row mt-20p "+this.state.plannedTrucksData}>
                            <div id="myGrid" style={{width:"100%",height:"80vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                <AgGridReact
                                modules={this.state.modules} 
                                    rowData={this.state.plannedData}
                                    columnDefs={plannedcolsDefs}
                                    gridOptions={{context:{componentParent:this}}}
                                    defaultColDef={this.state.defaultColDef}
                                    frameworkComponents={this.state.frameworkComponents}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection= {true}
                                    // onCellClicked={this.onCellClickedGrid.bind(this)}
									masterDetail={true}
									detailCellRendererParams={this.state.detailCellRendererParams}
									rowClassRules={this.state.rowClassRules}
                                />
                            </div>
                        </div>

                        {/*Unplanned Trucks Data */}
                        <div className={"row mt-20p "+this.state.unPlannedTrucksData}>
                            <div id="myGrid" style={{width:"100%",height:"80vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                <AgGridReact
                                modules={this.state.modules} 
                                    rowData={this.state.unPlannedData}
                                    columnDefs={unPlannedcolsDefs}
                                    gridOptions={{context:{componentParent:this}}}
                                    defaultColDef={this.state.defaultColDef}
                                    frameworkComponents={this.state.frameworkComponents}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                />
                            </div>
                        </div>


                            {/* Load Dropped Data */}
                            <div className={"row mt-20p "+this.state.loadsDroppedData}>
                            <div id="myGrid" style={{width:"100%",height:"80vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                <AgGridReact
                                modules={this.state.modules} 
                                    rowData={this.state.loadDroppedDetails}
                                    columnDefs={loadsDroppedcolsDefs}
                                    gridOptions={{context:{componentParent:this}}}
                                    defaultColDef={this.state.defaultColDef}
                                    frameworkComponents={this.state.frameworkComponents}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection= {true}
                                />
                            </div>
                        </div>
                        {/* Input Data */}
                        <div className={"row mt-20p "+this.state.inputInfoData}>
                            <div id="myGrid" style={{width:"100%",height:"80vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                <AgGridReact
                                modules={this.state.modules} 
                                    rowData={this.state.inputDetails}
                                    columnDefs={inputDetailscolsDefs}
                                    gridOptions={{context:{componentParent:this}}}
                                    defaultColDef={this.state.defaultColDef}
                                    frameworkComponents={this.state.frameworkComponents}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection= {true}
                                />
                            </div>
                        </div>

                        <div class={"row mb-20p "+(this.state.summaryData)}>
                            {this.state.total_demand_cmt == 0 ? <div/> :
                                <div className={"mxheit col-sm-6 "} >
                                    <div className="card">
                                        <div className="card-body">
                                            <ColumnChart
                                            title={"Planning Data"}
                                            series={this.state.planningColumnChart}
                                            />
                                        </div>
                                    </div>
                                </div>}
                            {this.state.total_dropped_loads == 0 ? <div/> :
                            <div className={"mxheit col-sm-6 "} >
                                <div className="card">
                                    <div className="card-body">
                                        <PieChart
                                        title={"Dropped Data"}
                                        series={this.state.planningPieChart}
                                        />
                                    </div>
                                </div>
                            </div>}       
                        </div>
                        <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                        <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                            <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                            <div className="row">
                                <div className="col-sm-12">
                                    <LoadSummaryDrawmap context={this} coords={this.state.latLngArray} loadingLocation={this.state.loadingLocation} stepcoords={1} mapType={this.state.mapType} mapFor={"load_management"}  /> 
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </>
        )
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}