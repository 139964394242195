import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import Select from 'react-select';
import NewLineChart from "./newlinechart"
import redirectURL from '../redirectURL';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';

// window['jQuery'] = window['$'] = $;
var moment = require("moment");

class ResultsSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showloader: "show-n",
            phase: { "value": "2023-Apr-2024-Mar", "label": "2023-Apr-2024-Mar" },
            phaseHalf:{label: "ALL", value: "ALL"},
            phases: [],
            s1: "btn-success",
            s2: "btn-default",
            type: "VED",
            showPhase: "",
            showSlideBlock: "",
            overly: "",
            overlayBlock: "",
            sliderTitle: "Trigger Learining",
            title: "",
            plotData: [],
            headerName: "",
            overall_excess_cost: 0,
            overall_under_prediction_percentage: 0,
            pred_per_ved_wrt_all_df: [],
            pred_percentage_wrt_ved_df: [],
            pred_wrt_all_cost_df: [],
            pred_wrt_ved_df: [],
            is_tab_1_active: true,
            is_tab_2_active: false,
            msg: "",
            showmsg: false,
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            frameworkComponents: {
                // GridButton:GridButton,
                // ViewItems:ViewItems
            },
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            detailCellRendererParams: {},
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },

        }
    }


    componentDidMount = async () => {
        this.setState({
            showloader: "show-m",
            overly: 'show-n',
            overlayBlock: "show-n"
        })
        redirectURL.post("/dashboard/phaselists")
            .then((response) => {
                var records = response.data.records;
                var recordsarr = [];
                if (records.length > 0) {
                    records.map((itm) => {
                        recordsarr.push({ value: itm, label: itm });
                    })
                }
                this.setState({
                    phases: recordsarr.slice(0, recordsarr.length - 1).reverse(),
                    // phase: recordsarr[1]
                }, () => { this.loadData() })
            })
    }



    loadData() {
        var params = {
            phase: this.state.phase.value,
            type: this.state.type,
            phaseHalf: this.state.phaseHalf.value
        }
        this.setState({ showmsg: false })

        redirectURL.post("forecast/getResultsSummaryData", params)
            .then((response) => {
                console.log(response.data, "results-summary")
                try {
                    if (response.status === 200) {
                        if (response.data.message === undefined || response.data.message === "") {
                            var overall_excess_cost = 0
                            var overall_under_prediction_percentage = 0
                            var pred_per_ved_wrt_all_dfarr = []
                            var pred_percentage_wrt_ved_dfarr = []
                            var pred_wrt_all_cost_dfarr = []
                            var pred_wrt_ved_dfarr = []

                            var resultSummaryData = response.data;
                            console.log(resultSummaryData)
                            overall_excess_cost = resultSummaryData.overall_excess_cost
                            overall_under_prediction_percentage = resultSummaryData.overall_under_prediction_percentage
                            // pred_per_ved_wrt_all_dfarr = JSON.parse(resultSummaryData.pred_per_ved_wrt_all_df)
                            // pred_percentage_wrt_ved_dfarr = JSON.parse(resultSummaryData.pred_percentage_wrt_ved_df)
                            pred_wrt_all_cost_dfarr = JSON.parse(resultSummaryData.pred_wrt_all_cost_df)
                            // pred_wrt_ved_dfarr = JSON.parse(resultSummaryData.pred_wrt_ved_df)
                            // pred_wrt_ved_dfarr = pred_wrt_ved_dfarr.map((each) => {
                            //     if (each.category == "Under Predicted Outliers" || each.category == "Grand Total"){
                            //     return each
                            //     }
                            //     else{
                            //         return {...each, popupText: "Double click to see"}
                            //     }
                            // })
                            // pred_per_ved_wrt_all_dfarr = pred_per_ved_wrt_all_dfarr.map((each) => {
                            //     if (each.category == "Under Predicted Outliers" || each.category == "Grand Total"){
                            //     return each
                            //     }
                            //     else{
                            //         return {...each, popupText: "Double click to see"}
                            //     }
                            // })
                            pred_wrt_all_cost_dfarr = pred_wrt_all_cost_dfarr.map((each) => {
                                if (each["Prediction Category"] == "Total Materials for Prediction") {
                                    return each
                                }
                                else {
                                    return { ...each, popupText: "Double click to see" }
                                }
                            })

                            this.setState({
                                showPhase: params.phase,
                                overall_excess_cost: parseFloat(overall_excess_cost),
                                overall_under_prediction_percentage: parseFloat(overall_under_prediction_percentage),
                                pred_per_ved_wrt_all_df: pred_per_ved_wrt_all_dfarr,
                                pred_percentage_wrt_ved_df: pred_percentage_wrt_ved_dfarr,
                                pred_wrt_all_cost_df: pred_wrt_all_cost_dfarr,
                                pred_wrt_ved_df: pred_wrt_ved_dfarr,
                                showloader: "show-n",
                                overly: "show-n"
                            })
                        }
                        else {
                            this.setState({
                                msg: response.data.message,
                                showmsg: true,
                                showloader: "show-n",
                                overly: "show-n"
                            })
                        }
                    }
                    else {
                        this.setState({
                            showloader: "show-n",
                            overly: "show-n"
                        })
                    }
                } catch (error) {

                }
            })
    }


    onClickGetData() {
        this.setState({
            showloader: "show-m",
        })
        var params = {
            phase: this.state.phase.value,
            type: this.state.type,
        }
        this.loadData(params)
    }


    onChangePhase(phase) {
        this.setState({ phase })
    }
    onChangePhaseHalf(phaseHalf) {
        this.setState({ phaseHalf })
    }


    onClickTab(type) {
        if (type == "VED") {
            this.setState({
                s1: "btn-success",
                s2: "btn-default",
                showloader: "show-m",
                is_tab_1_active: true,
                is_tab_2_active: false,
                type
                ,
            }, () => { this.loadData() })
        }
        else {
            this.setState({
                s1: "btn-default",
                s2: "btn-success",
                showloader: "show-m",
                is_tab_1_active: false,
                is_tab_2_active: true,
                type
            }, () => { this.loadData() })
        }
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

    };

    async getPredictionPercentofVEDwrtAllMaterials(rownode) {
        if (rownode.colDef.headerName != "Prediction Category" && rownode.data.category !== "Grand Total" && rownode.data.category !== "Under Predicted Outliers") {
            this.setState({
                showSlideBlock: "slide45",
                overlayBlock: 'show-m',
                overly: "show-m",
                sliderTitle: "Phase Wise Comparison Plot",
                plotData: [],
                headerName: rownode.colDef.headerName,
                title: `${rownode.colDef.headerName === "Grand Total" ? "Overall" : rownode.colDef.headerName} - ${rownode.data.category} Predictions Percentage Comparison`
            })
            var params = {
                phase: this.state.phase.value,
                fieldName: rownode.colDef.headerName,
                categoryName: rownode.data.category,
                requestType: this.state.type
            }
            await redirectURL.post("/forecast/getPredictionPercentofVEDwrtAllMaterials", params)
                .then((response) => {
                    console.log(JSON.parse(response.data.plot_data))
                    this.setState({ plotData: JSON.parse(response.data.plot_data) })
                }).catch((error) => {
                    console.log(error)
                })
        }
    }

    async getResultsSummaryDataForCharts(rownode) {
        if (rownode.colDef.headerName != "Prediction Category" && rownode.data.category !== "Grand Total" && rownode.data.category !== "Under Predicted Outliers") {
            this.setState({
                showSlideBlock: "slide45",
                overlayBlock: 'show-m',
                overly: "show-m",
                sliderTitle: "Phase Wise Comparison Plot",
                plotData: [],
                headerName: rownode.colDef.headerName,
                title: `${rownode.colDef.headerName === "Grand Total" ? "Overall" : rownode.colDef.headerName} - ${rownode.data.category} Predictions Percentage Comparison`
            })
            var params = {
                phase: this.state.phase.value,
                fieldName: rownode.colDef.headerName,
                categoryName: rownode.data.category,
            }
            await redirectURL.post("/forecast/getResultsSummaryDataForCharts", params)
                .then((response) => {
                    console.log(JSON.parse(response.data.plot_data))
                    this.setState({ plotData: JSON.parse(response.data.plot_data) })
                }).catch((error) => {
                    console.log(error)
                })
        }

    }

    async getPredictionwrtAllMaterialsCost(rownode) {
        console.log(rownode)
        if (rownode.colDef.headerName != "Prediction Category" && rownode.data["Prediction Category"] !== "Total Materials for Prediction") {
            if ((rownode.colDef.headerName !== "Sum of Cost Difference ( Mn'$ )" || rownode.value !== "-") && (rownode.colDef.headerName !== "Under Predicted %" || rownode.value !== null)) {
                var partTitle = ""
                var KPI = ""
                var category = ""
                switch (rownode.colDef.headerName) {
                    case "Count of Material Codes":
                        partTitle = "Predictions"
                        category = "Count of Material Codes"
                        break;
                    case "Sum of Predicted Cost ( Mn'$ )":
                        partTitle = "Predictions Cost"
                        category = "Sum of Predicted Cost"
                        break;
                    case "Sum of Actual Cost ( Mn'$ )":
                        partTitle = "Predictions Cost"
                        category = "Sum of Actual Cost"
                        break;
                    case "Sum of Cost Difference ( Mn'$ )":
                        partTitle = "Cost"
                        category = "Sum of Cost Difference"
                        KPI = "(KPI-1)"
                        break;
                    default:
                        partTitle = "Predictions Percentage"
                        category = "Under Predicted %"
                        KPI = "(KPI-2)"
                }
                if ((rownode.data["Prediction Category"] === "Never Consumed - NC" || rownode.data["Prediction Category"] === "No Consumption for past 3 years - NC3Y") && rownode.colDef.headerName === "Count of Material Codes") {
                    partTitle = "Materials"
                }
                this.setState({
                    showSlideBlock: "slide45",
                    overlayBlock: 'show-m',
                    overly: "show-m",
                    sliderTitle: "Phase Wise Comparison Plot",
                    plotData: [],
                    headerName: rownode.colDef.headerName,
                    title: `${rownode.data["Prediction Category"]} ${partTitle} Comparison ${KPI}`
                })
                var params = {
                    phase: this.state.phase.value,
                    fieldName: category,
                    categoryName: rownode.data["Prediction Category"],
                    // requestType: this.state.type
                }
                console.log(params)
                await redirectURL.post("/forecast/getPredictionwrtAllMaterialsCost", params)
                    .then((response) => {
                        console.log(JSON.parse(response.data.plot_data))
                        this.setState({ plotData: JSON.parse(response.data.plot_data) })
                    }).catch((error) => {
                        console.log(error)
                    })
            }


        }
    }


    hideSlideBlock = () => {
        this.setState({
            overlayBlock: "show-n",
            showSlideBlock: "",
            loadshow: 'show-n',
            overly: 'show-n',
        });
    }

    CustomCellRenderer = ({ value }) => {
        // Define your custom cell rendering logic here
        const cellStyle = {
            backgroundColor: (value != "" && value != "-") ? '#ed4f47' : 'transparent', // Set background color based on a condition
            fontWeight: (value != "" && value != "-") && "bold",
            color: (value != "" && value != "-") && "#f9f9f9"
        };

        return (
            <div style={cellStyle}>
                {value}
            </div>
        );
    };

    customTooltipValueGetter(params) {
        // Check if the row index is within the last two rows
        if (params.node.data.category == "Under Predicted Outliers" && params.node.data.category == "Grand Total") {
            // Suppress tooltip for the last two rows by returning an empty string
            return '';
        }

        // Return the tooltip content based on your logic
        return 'Double click to see details: ' + params.value;
    }

    onClickingCreateUser = async () => {
          await redirectURL.post('users/saveuser')
          .then(async (response) => {
             console.log(response.data)
           })
    }

    render() {

        if (localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined) {
            return <Navigate to={"/login"} push={true} />
        }
        console.log(this.state.pred_wrt_ved_df, this.state.overall_under_prediction_percentage)

        // Column definitions
        var hideforVED = this.state.is_tab_1_active == true ? true : false
        var vedDataColumnWidth = this.state.is_tab_1_active === true ? "278px" : "234px"
        var columnDefs1 = [
            { headerName: 'Prediction Category', field: 'category', resizable: true, width: vedDataColumnWidth, headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, cellClass: 'no-pointer' },
            { headerName: 'Desirable', field: 'Desirable', resizable: true, width: vedDataColumnWidth, headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, tooltipField: "popupText" },
            { headerName: 'Essential', field: 'Essential', resizable: true, width: vedDataColumnWidth, headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, tooltipField: "popupText" },
            { headerName: 'Vital', field: 'Vital', resizable: true, width: vedDataColumnWidth, headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, tooltipField: "popupText" },
            { headerName: 'NON VED', field: 'NON VED', resizable: true, hide: hideforVED, width: vedDataColumnWidth, headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, tooltipField: "popupText" },
            { headerName: 'Grand Total', field: 'Grand Total', resizable: true, width: vedDataColumnWidth, headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, tooltipField: "popupText" },
        ];
        var columnDefs2 = [
            { headerName: 'Prediction Category', field: 'Prediction Category', resizable: true, width: "234px", headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, cellClass: 'no-pointer' },
            { headerName: 'Count of Material Codes', field: 'Count of Material Codes', resizable: true, width: "234px", headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, tooltipField: "popupText" },
            { headerName: "Sum of Predicted Cost ( Mn'$ )", field: 'Sum of Predicted Cost', resizable: true, width: "234px", headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, tooltipField: "popupText" },
            { headerName: "Sum of Actual Cost ( Mn'$ )", field: 'Sum of Actual Cost', resizable: true, width: "234px", headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, tooltipField: "popupText" },

            {
                headerName: "Sum of Cost Difference ( Mn'$ )", field: 'Sum of Cost Difference', resizable: true, width: "234px", headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, cellRendererFramework:
                    this.CustomCellRenderer, tooltipField: "popupText"
            },

            { headerName: 'Under Predicted %', field: 'Under Predicted %', resizable: true, width: "234px", headerClass: "ag-center-header headerColorForGrid", cellStyle: { textAlign: 'center' }, cellRendererFramework: this.CustomCellRenderer, tooltipField: "popupText" }
        ]
        const defaultWidths = columnDefs2.map((col) => col.width);
        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              fontWeight: '500',
              fontSize: "14px",
              color: "black" // Set font-weight to bold for selected options
            }),
            singleValue: (provided, state) => ({
                ...provided,
                fontSize: "14px",
                fontWeight: '500', // Always display the selected option in bold
                color: "black"
              }),
          };


        const applyMediaQueryStyles = () => {
            if (window.innerWidth >= 1920) {
                columnDefs2.forEach((col, index) => {
                    // Adjust the width for screens >= 1920px
                    col.width = "295px"; // Adjust widths as needed
                });
            } else {
                columnDefs2.forEach((col, index) => {
                    // Reset to the default width for smaller screens
                    col.width = defaultWidths[index];
                });
            }
            if (window.innerWidth <= 1920) {
                customStyles.option = (provided, state) => ({
                  ...provided,
                  fontSize: "15px",
                  fontWeight: '500', // Change font size for 1920px screens
                  color: "black",
                });
                
                customStyles.singleValue = (provided, state) => ({
                  ...provided,
                  fontSize: "15px",
                  fontWeight: '500', // Change font size for 1920px screens
                  color: "black",
                });
                customStyles.control = (provided) => ({
                  ...provided,
                  height: '40px', // Set the desired height
                });
              }
        };

        applyMediaQueryStyles();

        window.addEventListener("resize", applyMediaQueryStyles);
        const gridOptions1 = {
            getRowStyle: function (params) {
                if (params.node.data.category === "Not AI sensitive items - NS-AI" || params.node.data["Prediction Category"] === "Not AI sensitive items - NS-AI") {
                    return { backgroundColor: '#a4e88e' };
                } else if (params.node.data.category === "Potential Outliers - PO" || params.node.data["Prediction Category"] === "Potential Outliers - PO") {
                    return { backgroundColor: '#f7f088' };
                } else if (params.node.data.category === "Exact prediction - ETP" || params.node.data["Prediction Category"] === "Exact prediction - ETP") {
                    return { backgroundColor: '#fab361' };
                }
                else if (params.node.data.category === "Excess prediction - ESP" || params.node.data["Prediction Category"] === "Excess prediction - ESP") {
                    return { backgroundColor: '#def8fa' };
                }
                else if (params.node.data.category === "Under prediction - UP" || params.node.data["Prediction Category"] === "Under prediction - UP") {
                    return { backgroundColor: '#b3b3ff' };
                }
                else if (params.node.data.category === "Total Material for Prediction" || params.node.data["Prediction Category"] === "Total Material for Prediction") {
                    return { backgroundColor: '#d1e0e0' };
                }
                return null; // Return null for other rows
            },
        };
        const gridOptions2 = {
            getRowStyle: function (params) {
                if (params.node.data.category === "Exact" || params.node.data["Prediction Category"] === "Exact") {
                    return { backgroundColor: '#a4e88e' };
                } else if (params.node.data.category === "Excess" || params.node.data["Prediction Category"] === "Excess") {
                    return { backgroundColor: '#f7f088' };
                } else if (params.node.data.category === "Under" || params.node.data["Prediction Category"] === "Under") {
                    return { backgroundColor: '#fab361' };
                }
                else if (params.node.data.category === "Grand Total" || params.node.data["Prediction Category"] === "Grand Total") {
                    return { backgroundColor: '#def8fa' };
                }
                else if (params.node.data.category === "Under Predicted Outliers" || params.node.data["Prediction Category"] === "Under Predicted Outliers") {
                    return { backgroundColor: '#b3b3ff' };
                }
                else if (params.node.data.category === "Not Possible Predictions" || params.node.data["Prediction Category"] === "Not Possible Predictions") {
                    return { backgroundColor: '#d1e0e0' };
                }
                return null; // Return null for other rows
            },
        };
        const gridOptions3 = {
            getRowStyle: function (params) {
                if (params.node.data.category === "Exact" || params.node.data["Prediction Category"] === "Exact") {
                    return { backgroundColor: '#a4e88e' };
                } else if (params.node.data.category === "Excess" || params.node.data["Prediction Category"] === "Excess") {
                    return { backgroundColor: '#f7f088' };
                } else if (params.node.data.category === "Under" || params.node.data["Prediction Category"] === "Under") {
                    return { backgroundColor: '#fab361' };
                }
                else if (params.node.data.category === "Grand Total" || params.node.data["Prediction Category"] === "Grand Total") {
                    return { backgroundColor: '#def8fa' };
                }
                else if (params.node.data.category === "Under Predicted Outliers" || params.node.data["Prediction Category"] === "Under Predicted Outliers") {
                    return { backgroundColor: '#b3b3ff' };
                }
                else if (params.node.data.category === "Not Possible Predictions" || params.node.data["Prediction Category"] === "Not Possible Predictions") {
                    return { backgroundColor: '#d1e0e0' };
                }
                return null; // Return null for other rows
            },
        };
        const gridOptions4 = {
            getRowStyle: function (params) {
                if (params.node.data.category === "Never Consumed - NC" || params.node.data["Prediction Category"] === "Never Consumed - NC") {
                    return { backgroundColor: '#b3b3ff' };
                } else if (params.node.data.category === "No Consumption for past 3 years - NC3Y" || params.node.data["Prediction Category"] === "No Consumption for past 3 years - NC3Y") {
                    return { backgroundColor: '#def8fa' };
                } else if (params.node.data.category === "Exact prediction - ETP" || params.node.data["Prediction Category"] === "Exact prediction - ETP") {
                    return { backgroundColor: '#a4e88e' };
                }
                else if (params.node.data.category === "Excess prediction - ESP" || params.node.data["Prediction Category"] === "Excess prediction - ESP") {
                    return { backgroundColor: '#f7f088' };
                }
                else if (params.node.data.category === "Under prediction - UP" || params.node.data["Prediction Category"] === "Under prediction - UP") {
                    return { backgroundColor: '#fab361' };
                }
                else if (params.node.data.category === "Total Material for Prediction" || params.node.data["Prediction Category"] === "Total Material for Prediction") {
                    return { backgroundColor: '#d1e0e0' };
                }
                return null; // Return null for other rows
            },
        };
         

        const customCellRenderer = (params) => {
            if (params.value) {
                // Render the cell value with a tooltip
                return `<div title="Double click to see">${params.value}</div>`;
            } else {
                // Render an empty cell
                return '';
            }
        };
        console.log(window.screen.width, "screen width")

        return (
            <div>
                <div className={'lodr ' + (this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                <div className="row mb-10p pt-20p">
                    <div className="col-sm-12 d-flex">
                        <h5 className='fbold f23 d-flex justify-content-between' style={{ color: "black" }}>
                            <span>Results Summary</span>
                        </h5>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-sm-12 d-flex">
                        <button type="button" className={"btn " + (this.state.s1)} >VED Materials Data</button>
                        <button type="button" className={"btn " + (this.state.s2)} onClick={this.onClickTab.bind(this, "ALL")}>All Materials Data</button>
                    </div>
                </div> */}
                <div className="row align-items-end mb-20p">

                    <div className="col-sm-2 mt-5p custom-col">
                        <label className="fw-600 f17" style={{ color: "black" }}>Phase</label>
                        <Select
                            onChange={this.onChangePhase.bind(this)}
                            options={this.state.phases}
                            value={this.state.phase}
                            styles={customStyles}
                        />
                    </div>
                    <div className="col-sm-2 mt-5p custom-col">
                        <label className="fw-600 f17" style={{ color: "black" }}>Phase Half</label>
                        <Select
                            onChange={this.onChangePhaseHalf.bind(this)}
                            options={[
                                { value: "first", label: "First Half" },
                                { value: "second", label: "Second Half" },
                                { value: "ALL", label: "ALL" },
                              ]}
                            value={this.state.phaseHalf}
                            styles={customStyles}
                        />
                    </div>

                    <div className="col-sm-2 d-flex">
                        <button type="button" className="btn btn-warning getBtn mt-25p fw-600" style={{ color: "black" }} onClick={this.onClickGetData.bind(this)}>Apply</button>
                    </div>
                    {/* <div className="col-sm-2 d-flex">
                    <button type="button" className="btn btn-success" onClick={this.onClickingCreateUser}>Create User</button> 
                    </div> */}
                </div>
                {this.state.showmsg && (<div className="col-sm-12 d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                    <h5 className='f20 fbold  d-flex justify-content-between'>
                        <span style={{ color: "black" }}>{this.state.msg}</span>
                    </h5>
                </div>)}
                {(this.state.showmsg === false) && (
                    <>
                        {/* <div className="row mb-20p pt-20p">
                    <div className="col-sm-12 d-flex">
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Prediction W.R.T. VED Category &nbsp;{this.state.showPhase}</span>
                        </h5>
                    </div>
                </div>

                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        <div id="testGrid" style={{ width: "100%", height: "270px" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                // modules={this.state.modules}
                                rowData={this.state.pred_wrt_ved_df}
                                columnDefs={columnDefs1}
                                defaultColDef={this.state.defaultColDef}
                                enableCharts={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={gridOptions1}
                                enableRangeSelection={true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                onCellDoubleClicked={this.getResultsSummaryDataForCharts.bind(this)}
                                suppressRowHoverHighlight={true}
                                suppressHorizontalScroll={true}
                            />
                        </div>
                    </div>

                </div> */}

                        {/* 
                <div className="row mb-20p pt-20p">
                    <div className="col-sm-12 d-flex">
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Prediction % W.R.T. VED Category &nbsp;{this.state.showPhase}</span>
                        </h5>
                    </div>
                </div>

                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{ width: "100%", height: "230px" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.pred_percentage_wrt_ved_df}
                                columnDefs={columnDefs1}
                                defaultColDef={this.state.defaultColDef}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={gridOptions2}
                                enableRangeSelection={true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                            />
                        </div>
                    </div>

                </div>
                */}

                        {/* <div className="row mb-20p pt-20p">
                    <div className="col-sm-12 d-flex">
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Prediction % of VED W.R.T. Total Material &nbsp;{this.state.showPhase}</span>
                        </h5>
                    </div>
                </div>

                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        <div id="testGrid" style={{ width: "100%", height: "270px" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                // modules={this.state.modules}
                                rowData={this.state.pred_per_ved_wrt_all_df}
                                columnDefs={columnDefs1}
                                defaultColDef={this.state.defaultColDef}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={gridOptions3}
                                enableRangeSelection={true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                onCellDoubleClicked={this.getPredictionPercentofVEDwrtAllMaterials.bind(this)}
                                suppressRowHoverHighlight={true}
                                suppressHorizontalScroll={true}
                            />
                        </div>
                    </div>

                </div> */}

                        <div className="row mb-20p pt-20p">
                            <div className="col-sm-12 d-flex">
                                <h5 className='fw-600 f19 d-flex justify-content-between' style={{ color: "black" }}>
                                    <span>Prediction W.R.T. All material & Cost &nbsp;{this.state.showPhase}</span>
                                </h5>
                            </div>
                        </div>

                        <div className="row mb-20p">
                            <div className={"mxheit col-sm-12 "} >
                                <div id="testGrid" className={"ag-theme-balham result-summary-table"}>
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        rowData={this.state.pred_wrt_all_cost_df}
                                        columnDefs={columnDefs2}
                                        defaultColDef={this.state.defaultColDef}
                                        enableCharts={false}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={gridOptions4}
                                        enableRangeSelection={true}
                                        masterDetail={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        onCellDoubleClicked={this.getPredictionwrtAllMaterialsCost.bind(this)}
                                        suppressRowHoverHighlight={true}
                                        suppressHorizontalScroll={true}
                                        enableBrowserTooltips={true}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className={"sliderBlock2 " + (this.state.showSlideBlock)}>
                            <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                            <div className="col-sm-12">
                                <NewLineChart
                                    data={this.state.plotData}
                                    title={this.state.title}
                                    headerName={this.state.headerName}
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-sm-12">
                                <h1 className='fw-600 f19 d-flex justify-content-between' style={{ color: "black" }}>Payout [ {this.state.showPhase} ]</h1>
                                <table className="table table-bordered result-summary-html-table">
                                    <thead style={{ backgroundColor: "#beddeb"}}>
                                        <tr>
                                            <th colspan="4" className="text-center">Payout as a % of variable component</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">Excess Inventory</th>
                                            <th colspan="3" className="text-center">Under-predicted inventory count error[ % ]</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">Spend Forecast Error Mn$</th>
                                            <th className="text-center text-danger">&gt;&nbsp;10%</th>
                                            <th className="text-center text-danger">&lt;=&nbsp;10%&nbsp;&&nbsp; &gt;=&nbsp;5%</th>
                                            <th className="text-center text-danger">&lt;&nbsp;5%</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-600" style={{ color: "black" }}>
                                        <tr className="text-center bg-light">
                                            <td className={this.state.overall_excess_cost > 0.3 ? "bg-success" : ""}>&gt; 0.3Mn$</td>
                                            <td className={this.state.overall_excess_cost > 0.3 && this.state.overall_under_prediction_percentage > 10 ? "bg-success" : ""}>0%</td>
                                            <td className={this.state.overall_excess_cost > 0.3 && (this.state.overall_under_prediction_percentage <= 10 && this.state.overall_under_prediction_percentage >= 5) ? "bg-success" : ""}>20%</td>
                                            <td className={this.state.overall_excess_cost > 0.3 && this.state.overall_under_prediction_percentage < 5 ? "bg-success" : ""}>60%</td>
                                        </tr>
                                        <tr className="text-center bg-light">
                                            <td className={this.state.overall_excess_cost <= 0.3 && this.state.overall_excess_cost >= 0.25 ? "bg-success" : ""}>&lt;= 0.3Mn$  &nbsp;&&nbsp;  &gt;= 0.25Mn$</td>
                                            <td className={(this.state.overall_excess_cost <= 0.3 && this.state.overall_excess_cost >= 0.25) && this.state.overall_under_prediction_percentage > 10 ? "bg-success" : ""}>20%</td>
                                            <td className={(this.state.overall_excess_cost <= 0.3 && this.state.overall_excess_cost >= 0.25) && (this.state.overall_under_prediction_percentage <= 10 && this.state.overall_under_prediction_percentage >= 5) ? "bg-success" : ""}>40%</td>
                                            <td className={(this.state.overall_excess_cost <= 0.3 && this.state.overall_excess_cost >= 0.25) && this.state.overall_under_prediction_percentage < 5 ? "bg-success" : ""}>80% (Condition 1)</td>
                                        </tr>
                                        <tr className="text-center bg-light">
                                            <td className={this.state.overall_excess_cost < 0.25 ? "bg-success" : ""}>&lt; 0.25Mn$</td>
                                            <td className={this.state.overall_excess_cost < 0.25 && this.state.overall_under_prediction_percentage > 10 ? "bg-success" : ""}>60%</td>
                                            <td className={this.state.overall_excess_cost < 0.25 && (this.state.overall_under_prediction_percentage <= 10 && this.state.overall_under_prediction_percentage >= 5) ? "bg-success" : ""}>80% (Condition 2)</td>
                                            <td className={this.state.overall_excess_cost < 0.25 && this.state.overall_under_prediction_percentage < 5 ? "bg-success" : ""}>100%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"overlay-block " + (this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>

                            {/* <div className={"loader "+this.state.loadshow}></div> */}
                            {/* <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div> */}
                        </div>
                    </>
                )}
            </div>
        )
    }


}

export default ResultsSummary
