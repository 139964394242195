import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM} from '../common/utils'; 
import {verifyext} from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import ColumnChart from "./columnChartComponent";
import PolarChart from "./polarChartComponent";
import AreaRangeChart from "./areaRangeChart";
import ConfidenceChart from "./confidenceChart";
import LineChart from "./lineChart";


var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class ForecastOutputData extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            fyChart:{label:"Select",value:""},
            fyCostChart:{label:"Select",value:""},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showrunprocessbtn:"show-m",
            showanimate:"show-n",
            locationNames:[],
            loadFile:'',
            material_configurations:0,
            location:{label:"Select Location",value:""},
            loadshow:'show-m',
            overly:'show-m',
            processErrMsg : "",
            processErrShow : "",
            transactionId : "",
            droppedList : [],
            customer_code:{"value":"all","label":"All"},
            material_code:{"value":"all","label":"All"},
            duration:{"value":"all","label":"All"},
            customerlist:[],
            materiallist:[],
            durationlist:[],
            chartdata:"show-n",
            datagrid:'show-m',
            datagridTab:"activet",
            chartdataTab:"btn-default",
            chartseries:[],
            confidenceTrain:[],
            confidenceTrend:[],
            confidenceTestPoints : [], 
            confidencePredictPoint : [],
            seasonality_train_plot:[],
            seasonality_pred_plot:[],
            seasonality_train_plotdata:[],
            allseason:[],
            chartCusts:[],
            chartItems:[],
            chartCustomer:{label:"Select",value:""},
            chartItem:{label:"Select",value:""},
            customerwisedata:[],
            allChartData:[],
            confAreaData:[],
            confTrendplot:[],
            confScatter:[],
            confAreaYHat:[],
            confLineSeasonal:[],
            confAreaDataDemo:[]
        }
        
    }
    async componentDidMount() {
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            overlayBlock:"show-n"
        })
        let url_type = window.location.href
        let path_type = url_type.split('?')
        let data_type
        let path = path_type[path_type.length-1]

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
        var material_configurations = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        this.setState({
            material_configurations : material_configurations
        });
        var durationlist = [{
            value:3,
            label:"3 Months"
        },{
            value:6,
            label:"6 Months"
        },{
            value:12,
            label:"12 Months"
        }]
        this.setState({
            durationlist:durationlist
        })

        await this.boundTypeLocations(params)
        // await this.getDispatchPlanData()
        await this.customers()
        await this.loadOutPlan()
    }
    loadOutPlan(){
        var s = window.location.search;
        var st = s.split("?");
        var transactionID = st[1];
        var params = {
            transaction_id:transactionID
        }
        if(window.location.pathname === "/forecast-output")
        {
            var urlpath = "/forecast/transactionoutputdata";
        }
        else{
            var urlpath = "/forecast/prevtransactionoutputdata";
        }
        redirectURL.post(urlpath,params)
        .then(response =>{
            var records = response.data.predict;
            var history = response.data.history;
            var chartDataaa = response.data.chartData;
            
            // chartDataaa = JSON.parse(mchartDataaa);
            // console.log("chartDataaa ", chartDataaa)
           var chartDatalist = [];
           var chartCusts=[];
           var chartItems=[];
           var areaplot=[];
           var areaYHat=[];
           var trendplot=[];
           var areaScatter=[];
           var confLineSeasonal=[]
           if(chartDataaa.length > 0)
           {
            chartDataaa.map((itm) =>{
                itm["chart_data"] = JSON.parse(itm.chart_data);
                itm["month_data"] = JSON.parse(itm.month_data);
                
                // if(itm.confidence_test_points !== undefined && itm.confidence_test_points !== "")
                // {

                    // console.log("itm ", itm)
                    chartCusts.push({customer:itm.customer_code});
                    chartItems.push({itemcode:itm.item_code});
                    chartDatalist.push(itm)
                // }
                
            })
           }
        //    console.log("chartDatalist", chartDatalist)
            if(chartDatalist.length > 0)
            {
                var chartData = [chartDatalist[0]];
                // console.log("chartData ", chartData)
                if(chartData.length > 0)
                {
                    chartData.map((mm) => {
                        var chartSeries = mm.chart_data;
                        var motnhSeries = mm.month_data;
                        if(chartSeries.length > 0)
                        {
                            chartSeries.map((mt) => {
                                var tms = moment.parseZone(mt.ds).format("x");
                                // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                                areaplot.push(mt.area_plot);
                                areaYHat.push((mt.yhat < 0)?0:mt.yhat);
                                if(mt.ds !== "" && mt.ds !== undefined)
                                {
                                    var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                    var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                    // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                    // console.log("mt.trend ", mt.trend )
                                    if(mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "")
                                    {
                                        if(parseInt(trnedns) < 2021)
                                        {
                                            trendplot.push({name:trnedns,y:mt.trend,lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                        }
                                    }
                                    confLineSeasonal.push({name:tms/1000,y:Math.round(mt.month_sum),lbl:Math.round(mt.month_sum).toLocaleString()});
                                }
                                if(mt.actual !== "" && mt.actual !== undefined && mt.actual !== null)
                                {
                                    // if(mt.actual > 0)
                                    // {
                                        areaScatter.push(mt.actual);
                                    // }
                                    
                                }
                            })
                            
                        }
                        if(motnhSeries.length > 0)
                        {
                            motnhSeries.map((mt) => {
                                // confLineSeasonal.push({name:mt.month,y:Math.round(mt.month_sum),lbl:Math.round(mt.month_sum).toLocaleString()});
                                
                                
                            })
                        }
                    })
                }
               
                this.setState({
                    chartCustomer:{value:chartData[0].customer_code,label:chartData[0].customer_code},
                    chartItem:{value:chartData[0].item_code,label:chartData[0].item_code}
                })
            }
            else{
                var chartData = [];
            }
            // console.log("confLineSeasonal ", confLineSeasonal)
            // console.log("areaplot ", areaplot)
            var monthsChart = ["04", "05","06","07","08","09","10","11","12","01","02","03"];
            var monChart = ["Jan","Feb","Mar", "Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            
                var confidenceTrain = []; 
                var confidenceTrend = [];
                var confidenceTestPoints = []; 
                var confidencePredictPoint = [];
                var seasonality_pred_plot = []
                var seasonality_train_plot = [];
                var allseason = [];
            
            var rowdata  = [];
            var months = ["Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
            // console.log("records ", records)
            if(records.length > 0)
            {
                var itmdata = groupBy(records, rdata => rdata.customer_code);
                // console.log("itmdata ", itmdata)
                try {
                    itmdata.forEach((valuesar,keys)=>{
                        if(valuesar.length > 0)
                        {
                            var valuesinf = groupBy(valuesar, rdata => rdata.item_code);
                            // console.log("valuesinf ", valuesinf)
                            valuesinf.forEach((values,vkeys)=>{
                                var rw = {}
                                var exmons = [];
                                if(values.length > 0)
                                {
                                    rw["_id"] = values[0]._id;
                                    rw["transaction_id"] = values[0].transaction_id;
                                    rw["fy"] = values[0].fy;
                                    rw["fy_start_year"] = values[0].fy_start_year;
                                    rw["fy_end_year"] = values[0].fy_end_year;
                                    rw["item_code"] = values[0].item_code;
                                    rw["customer_code"] = values[0].customer_code;
                                    rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
                                    rw["item_cost"] = values[0].iteminfo[0].item_cost

                                    values.map((i,n) => {
                                        rw[months[parseInt(i.month)-1]] = i.item_quantity;
                                        rw[months[parseInt(i.month)-1]+"_cost"] = parseInt(i.item_quantity)*(i.iteminfo[0].item_cost);
                                        exmons.push(months[parseInt(i.month)-1]);
                                        
                                    })
                                    // console.log("RWW ", rw)
                                
                                }
                                
                                if(exmons.length > 0){
                                    var diffkeys = arr_diff(exmons,months);
                                    // console.log("diffkeys ", diffkeys)
                                    if(diffkeys.length > 0)
                                    {
                                        diffkeys.map((id) => {
                                            if(id !== "itemIndex")
                                            {
                                                rw[id] = 0;
                                                rw[id+"_cost"] = 0;
                                            }
                                        })
                                    }
                                }
                                rowdata.push(rw);
                            })
                        }
                        
                    })
                } catch (error) {
                    
                }
            }
            
            var chartseries = {};
            if(rowdata.length > 0)
            {

                var fywisedata = groupBy(rowdata, rdata => rdata.fy);
                try {
                    var overallordersfy=[];
                    var overallcostfy=[];
                    var fynonths=[]
                    var fynonthsCost=[]
                    var historify=[];
                    fywisedata.forEach((values,keys) => {
                        var totlOrders = 0;
                        var totlCosts = 0;
                        // console.log("values",values)
                        if(values.length > 0)
                        {
                            var monthfy = [];
                            var monthfyCost = [];
                            var hmonthfy = []
                            var pmonthfy = []
                            var apr=0;
                            var may=0;
                            var jun=0;
                            var jul=0;
                            var aug=0;
                            var sep=0;
                            var oct=0;
                            var nov=0;
                            var dec=0;
                            var jan=0;
                            var feb=0;
                            var mar=0;

                            var apr_cost=0;
                            var may_cost=0;
                            var jun_cost=0;
                            var jul_cost=0;
                            var aug_cost=0;
                            var sep_cost=0;
                            var oct_cost=0;
                            var nov_cost=0;
                            var dec_cost=0;
                            var jan_cost=0;
                            var feb_cost=0;
                            var mar_cost=0;

                            values.map((item) => {
                                totlOrders = parseInt(totlOrders)+parseInt(item.Apr)+parseInt(item.May)+parseInt(item.Jun)+parseInt(item.Jul)+parseInt(item.Aug)+parseInt(item.Sep)+parseInt(item.Oct)+parseInt(item.Nov)+parseInt(item.Dec)+parseInt(item.Jan)+parseInt(item.Feb)+parseInt(item.Mar);

                                totlCosts = parseInt(totlCosts)+parseInt(item.Apr_cost)+parseInt(item.May_cost)+parseInt(item.Jun_cost)+parseInt(item.Jul_cost)+parseInt(item.Aug_cost)+parseInt(item.Sep_cost)+parseInt(item.Oct_cost)+parseInt(item.Nov_cost)+parseInt(item.Dec_cost)+parseInt(item.Jan_cost)+parseInt(item.Feb_cost)+parseInt(item.Mar_cost);
                                apr = parseInt(apr)+parseInt(item.Apr);
                                may = parseInt(may)+parseInt(item.May);
                                jun = parseInt(jun)+parseInt(item.Jun);
                                jul = parseInt(jul)+parseInt(item.Jul);
                                aug = parseInt(aug)+parseInt(item.Aug);
                                sep = parseInt(sep)+parseInt(item.Sep);
                                oct = parseInt(oct)+parseInt(item.Oct);
                                nov = parseInt(nov)+parseInt(item.Nov);
                                dec = parseInt(dec)+parseInt(item.Dec);
                                jan = parseInt(jan)+parseInt(item.Jan);
                                feb = parseInt(feb)+parseInt(item.Feb);
                                mar = parseInt(mar)+parseInt(item.Mar);

                                
                                apr_cost = parseInt(apr_cost)+parseInt(item.Apr_cost);
                                may_cost = parseInt(may_cost)+parseInt(item.May_cost);
                                jun_cost = parseInt(jun_cost)+parseInt(item.Jun_cost);
                                jul_cost = parseInt(jul_cost)+parseInt(item.Jul_cost);
                                aug_cost = parseInt(aug_cost)+parseInt(item.Aug_cost);
                                sep_cost = parseInt(sep_cost)+parseInt(item.Sep_cost);
                                oct_cost = parseInt(oct_cost)+parseInt(item.Oct_cost);
                                nov_cost = parseInt(nov_cost)+parseInt(item.Nov_cost);
                                dec_cost = parseInt(dec_cost)+parseInt(item.Dec_cost);
                                jan_cost= parseInt(jan_cost)+parseInt(item.Jan_cost);
                                feb_cost = parseInt(feb_cost)+parseInt(item.Feb_cost);
                                mar_cost = parseInt(mar_cost)+parseInt(item.Mar_cost);

                            })
                            hmonthfy.push(apr);
                            hmonthfy.push(may);
                            hmonthfy.push(jun);
                            hmonthfy.push(jul);
                            hmonthfy.push(aug);
                            hmonthfy.push(sep);
                            hmonthfy.push(oct);
                            hmonthfy.push(nov);
                            hmonthfy.push(dec);
                            hmonthfy.push(jan);
                            hmonthfy.push(feb);
                            hmonthfy.push(mar);
                            monthfy.push({
                                name:"Apr",y:apr
                            },{
                                name:"May",y:may
                            },{
                                name:"Jun",y:jun
                            },{
                                name:"Jul",y:jul
                            },{
                                name:"Aug",y:aug
                            },{
                                name:"Sep",y:sep
                            },{
                                name:"Oct",y:oct
                            },{
                                name:"Nov",y:nov
                            },{
                                name:"Dec",y:dec
                            },{
                                name:"Jan",y:jan
                            },{
                                name:"Feb",y:feb
                            },{
                                name:"Mar",y:mar
                            });

                            monthfyCost.push({
                                name:"Apr",y:apr_cost
                            },{
                                name:"May",y:may_cost
                            },{
                                name:"Jun",y:jun_cost
                            },{
                                name:"Jul",y:jul_cost
                            },{
                                name:"Aug",y:aug_cost
                            },{
                                name:"Sep",y:sep_cost
                            },{
                                name:"Oct",y:oct_cost
                            },{
                                name:"Nov",y:nov_cost
                            },{
                                name:"Dec",y:dec_cost
                            },{
                                name:"Jan",y:jan_cost
                            },{
                                name:"Feb",y:feb_cost
                            },{
                                name:"Mar",y:mar_cost
                            });
                            
                            overallordersfy.push({
                                name:"FY - "+keys,
                                y:totlOrders
                            });
                            overallcostfy.push({
                                name:"FY - "+keys,
                                y:totlCosts
                            });
                            fynonths.push({
                                name:keys,
                                data:monthfy,
                                color:"#ff0000"
                            });
                            fynonthsCost.push({
                                name:keys,
                                data:monthfyCost,
                                color:"#ff0000"
                            });
                            historify.push(hmonthfy)
                        }
                    })
                    // console.log("fynonths ", fynonths)
                    chartseries["poverallfinancialorders"] = {name:"Predict Orders",color:"#1ABC9C",data:overallordersfy}
                    chartseries["poverallfinancialcost"] = {name:"Predict Cost",color:"#1ABC9C",data:overallcostfy}
                    var selectedfymonths = fynonths.filter((f) => this.state.fyChart.value === f.name);
                    chartseries["pallfymonthwise"] = {name:"Predict Orders",color:"#1ABC9C",data:fynonths}
                    chartseries["pallfymonthwisecost"] = {name:"Predict Cost",color:"#1ABC9C",data:fynonthsCost}
                    chartseries["pselectedfymonths"] = selectedfymonths
                    chartseries["phistoric"] = historify
                } catch (error) {
                    
                }
                // item = item.sort(GetSortAscOrder("sequence_no"));
            }

            if(history.length > 0)
            {
                var prowdata = [];
                var hitmdata = groupBy(history, rdata => rdata.fy);
                // console.log("hitmdata ", hitmdata)
                try {
                    hitmdata.forEach((valuears,akeys)=>{
                        if(valuears.length > 0)
                        {
                            var vData = groupBy(valuears, rdata => rdata.item_code); 
                            vData.forEach((values,keys)=>{
                                var rw = {}
                                var exmons = [];
                                if(values.length > 0)
                                {
                                    rw["_id"] = values[0]._id;
                                    rw["transaction_id"] = values[0].transaction_id;
                                    rw["fy"] = values[0].fy;
                                    rw["fy_start_year"] = values[0].fy_start_year;
                                    rw["fy_end_year"] = values[0].fy_end_year;
                                    rw["item_code"] = values[0].item_code;
                                    rw["customer_code"] = values[0].customer_code;
                                    rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
                                    rw["item_cost"] = values[0].iteminfo[0].item_cost
    
                                    values.map((i,n) => {
                                        
                                        rw[months[parseInt(i.month)-1]] = i.item_qty;
                                        rw[months[parseInt(i.month)-1]+"_cost"] = parseInt(i.item_qty)*(i.item_cost);
                                        exmons.push(months[parseInt(i.month)-1]);
                                        
                                    })
                                    // console.log("RWW ", rw)
                                
                                }
                                
                                if(exmons.length > 0){
                                    var diffkeys = arr_diff(exmons,months);
                                    // console.log("diffkeys ", diffkeys)
                                    if(diffkeys.length > 0)
                                    {
                                        diffkeys.map((id) => {
                                            if(id !== "itemIndex")
                                            {
                                                rw[id] = 0;
                                                rw[id+"_cost"] = 0;
                                            }
                                        })
                                    }
                                }
                                prowdata.push(rw);
                            })
                            
                        }
                    })
                } catch (error) {
                    
                }
                // console.log('prowdata ', prowdata)
                var hfywisedata = groupBy(prowdata, rdata => rdata.fy);
                // console.log("hfywisedata ", hfywisedata)
                try {
                    var hoverallordersfy=[];
                    var hoverallcostfy=[];
                    var hfynonths=[]
                    var hfynonthsCost = [];
                    var hConfarea = [];
                    var hConfLine = [];
                    var hhistorify=[];
                    hfywisedata.forEach((values,keys) => {
                        var totlOrders = 0;
                        var totlCosts = 0;
                        // console.log("values",values)
                        if(values.length > 0)
                        {
                            var monthfy = [];
                            var monthfyCost = [];
                            var hmonthfy = []
                            var pmonthfy = []
                            var apr=0;
                            var may=0;
                            var jun=0;
                            var jul=0;
                            var aug=0;
                            var sep=0;
                            var oct=0;
                            var nov=0;
                            var dec=0;
                            var jan=0;
                            var feb=0;
                            var mar=0;
                            
                            var apr_cost=0;
                            var may_cost=0;
                            var jun_cost=0;
                            var jul_cost=0;
                            var aug_cost=0;
                            var sep_cost=0;
                            var oct_cost=0;
                            var nov_cost=0;
                            var dec_cost=0;
                            var jan_cost=0;
                            var feb_cost=0;
                            var mar_cost=0;

                            values.map((item) => {
                                totlOrders = parseInt(totlOrders)+parseInt(item.Apr)+parseInt(item.May)+parseInt(item.Jun)+parseInt(item.Jul)+parseInt(item.Aug)+parseInt(item.Sep)+parseInt(item.Oct)+parseInt(item.Nov)+parseInt(item.Dec)+parseInt(item.Jan)+parseInt(item.Feb)+parseInt(item.Mar);

                                totlCosts = parseInt(totlCosts)+parseInt(item.Apr_cost)+parseInt(item.May_cost)+parseInt(item.Jun_cost)+parseInt(item.Jul_cost)+parseInt(item.Aug_cost)+parseInt(item.Sep_cost)+parseInt(item.Oct_cost)+parseInt(item.Nov_cost)+parseInt(item.Dec_cost)+parseInt(item.Jan_cost)+parseInt(item.Feb_cost)+parseInt(item.Mar_cost);
                                apr = parseInt(apr)+parseInt(item.Apr);
                                may = parseInt(may)+parseInt(item.May);
                                jun = parseInt(jun)+parseInt(item.Jun);
                                jul = parseInt(jul)+parseInt(item.Jul);
                                aug = parseInt(aug)+parseInt(item.Aug);
                                sep = parseInt(sep)+parseInt(item.Sep);
                                oct = parseInt(oct)+parseInt(item.Oct);
                                nov = parseInt(nov)+parseInt(item.Nov);
                                dec = parseInt(dec)+parseInt(item.Dec);
                                jan = parseInt(jan)+parseInt(item.Jan);
                                feb = parseInt(feb)+parseInt(item.Feb);
                                mar = parseInt(mar)+parseInt(item.Mar);
                                
                                apr_cost = parseInt(apr_cost)+parseInt(item.Apr_cost);
                                may_cost = parseInt(may_cost)+parseInt(item.May_cost);
                                jun_cost = parseInt(jun_cost)+parseInt(item.Jun_cost);
                                jul_cost = parseInt(jul_cost)+parseInt(item.Jul_cost);
                                aug_cost = parseInt(aug_cost)+parseInt(item.Aug_cost);
                                sep_cost = parseInt(sep_cost)+parseInt(item.Sep_cost);
                                oct_cost = parseInt(oct_cost)+parseInt(item.Oct_cost);
                                nov_cost = parseInt(nov_cost)+parseInt(item.Nov_cost);
                                dec_cost = parseInt(dec_cost)+parseInt(item.Dec_cost);
                                jan_cost = parseInt(jan_cost)+parseInt(item.Jan_cost);
                                feb_cost = parseInt(feb_cost)+parseInt(item.Feb_cost);
                                mar_cost = parseInt(mar_cost)+parseInt(item.Mar_cost);

                            })
                            hmonthfy.push(apr);
                            hmonthfy.push(may);
                            hmonthfy.push(jun);
                            hmonthfy.push(jul);
                            hmonthfy.push(aug);
                            hmonthfy.push(sep);
                            hmonthfy.push(oct);
                            hmonthfy.push(nov);
                            hmonthfy.push(dec);
                            hmonthfy.push(jan);
                            hmonthfy.push(feb);
                            hmonthfy.push(mar);
                            monthfy.push({
                                name:"Apr",y:apr
                            },{
                                name:"May",y:may
                            },{
                                name:"Jun",y:jun
                            },{
                                name:"Jul",y:jul
                            },{
                                name:"Aug",y:aug
                            },{
                                name:"Sep",y:sep
                            },{
                                name:"Oct",y:oct
                            },{
                                name:"Nov",y:nov
                            },{
                                name:"Dec",y:dec
                            },{
                                name:"Jan",y:jan
                            },{
                                name:"Feb",y:feb
                            },{
                                name:"Mar",y:mar
                            });

                            monthfyCost.push({
                                name:"Apr",y:apr_cost
                            },{
                                name:"May",y:may_cost
                            },{
                                name:"Jun",y:jun_cost
                            },{
                                name:"Jul",y:jul_cost
                            },{
                                name:"Aug",y:aug_cost
                            },{
                                name:"Sep",y:sep_cost
                            },{
                                name:"Oct",y:oct_cost
                            },{
                                name:"Nov",y:nov_cost
                            },{
                                name:"Dec",y:dec_cost
                            },{
                                name:"Jan",y:jan_cost
                            },{
                                name:"Feb",y:feb_cost
                            },{
                                name:"Mar",y:mar_cost
                            });

                            hConfarea.push({
                                name:"FY - "+keys,
                                // data:[[apr_cost,may_cost],[may_cost,jun_cost],[jun_cost,jul_cost],[jul_cost,aug_cost],[aug_cost,sep_cost],[sep_cost,oct_cost],[oct_cost,nov_cost],[nov_cost,dec_cost],[dec_cost,jan_cost],[jan_cost,feb_cost],[feb_cost,mar_cost],[mar_cost,0]]
                                data:[[apr_cost,mar_cost]]
                            });
                            hConfLine.push({
                                name:"FY - "+keys,
                                data:[apr_cost,may_cost,jun_cost,jul_cost,aug_cost,sep_cost,oct_cost,nov_cost,dec_cost,jan_cost,feb_cost,mar_cost]
                            })
                            // hConfarea.push({
                            //     name:"FY - "+keys,
                            //     data:[[apr,may],[may,jun],[jun,jul],[jul,aug],[aug,sep],[sep,oct],[oct,nov],[nov,dec],[dec,jan],[jan,feb],[feb,mar]]
                            // });
                            hoverallordersfy.push({
                                name:"FY - "+keys,
                                y:totlOrders
                            });
                            hoverallcostfy.push({
                                name:"FY - "+keys,
                                y:totlCosts
                            });
                            hfynonths.push({
                                name:keys,
                                data:monthfy
                            });
                            hfynonthsCost.push({
                                name:keys,
                                data:monthfyCost
                            });
                            hhistorify.push(hmonthfy)
                        }
                    })
                    // console.log("fynonths ", fynonths)
                    chartseries["hoverallfinancialorders"] = {name:"History",colorByPoint: true,data:hoverallordersfy}
                    chartseries["hoverallfinancialcost"] = {name:"History",colorByPoint: true,data:hoverallcostfy}
                    chartseries["hallfymonthwise"] = {name:"History",colorByPoint: true,data:hfynonths}
                    chartseries["hallfymonthwisecost"] = {name:"History",colorByPoint: true,data:hfynonthsCost}
                    chartseries["hhistoric"] = hhistorify
                } catch (error) {
                    
                }
                // item = item.sort(GetSortAscOrder("sequence_no"));
            }
            
            var newArray = [];
            let totuniqueObject = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in chartCusts) { 

                // Extract the title
                if(chartCusts[i]['customer'] !== "" && chartCusts[i]['customer'] !== undefined)
                {
                    totobjTitle = chartCusts[i]['customer']; 

                    // Use the title as the index 
                    totuniqueObject[totobjTitle] = chartCusts[i];
                } 
                 
            } 

            // Loop to push unique object into array 
            for (var a in totuniqueObject) { 
                newArray.push(totuniqueObject[a]); 
            } 

            var chartCustsarr = [];
            if(newArray.length > 0)
            {
                newArray.map((item) => {
                    chartCustsarr.push({
                        value:item.customer,
                        label:item.customer
                    })
                })
            }

            
            var newArrayItem = [];
            let totuniqueObjectItem = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in chartItems) { 

                // Extract the title
                if(chartItems[i]['itemcode'] !== "" && chartItems[i]['itemcode'] !== undefined)
                {
                    totobjTitle = chartItems[i]['itemcode']; 

                    // Use the title as the index 
                    totuniqueObjectItem[totobjTitle] = chartItems[i];
                } 
                 
            } 

            // Loop to push unique object into array 
            for (var a in totuniqueObjectItem) { 
                newArrayItem.push(totuniqueObjectItem[a]); 
            } 


            var chartItemarr = [];
            if(newArrayItem.length > 0)
            {
                newArrayItem.map((item) => {
                    chartItemarr.push({
                        value:item.itemcode,
                        label:item.itemcode
                    })
                })
            }
            // console.log("trendplot ", trendplot)
            this.setState({
                rowData:rowdata,
                loadshow:'show-n',
                overly:'show-n',
                overlayBlock:"show-n",
                // chartseries:[chartseries],
                confidenceTrain:confidenceTrain,
                confidenceTrend:confidenceTrend,
                confidenceTestPoints : confidenceTestPoints, 
                confidencePredictPoint : confidencePredictPoint,
                seasonality_train_plot:seasonality_train_plot[seasonality_train_plot.length-1],
                seasonality_pred_plot:seasonality_pred_plot,
                seasonality_train_plotdata:seasonality_train_plot,
                allseason:allseason,
                chartCusts:chartCustsarr,
                chartItems:chartItemarr,
                allChartData:chartDatalist,
                confAreaData:areaplot,
                confAreaYHat:areaYHat,
                confTrendplot:trendplot,
                confScatter:areaScatter,
                confLineSeasonal:confLineSeasonal
            })
            setTimeout(() => {
                this.onClickShowCustomerData();
            },500)
            
        })
    }
    customers = () =>{
        var params = {
           
        }
        redirectURL.post('/forecast/customers',params)
        .then(response =>{
            var rec = response.data;
            var records = [];
            if(rec.length > 0)
            {
                rec.map((item) => {
                    records.push({
                        value:item.customer_code,
                        label:item.customer_code_1,
                    });
                })
            }
            this.setState({
                customerlist:records
            })
        })
        redirectURL.post('/forecast/materials',params)
        .then(response =>{
            var rec = response.data;
            var records = [];
            if(rec.length > 0)
            {
                rec.map((item) => {
                    records.push({
                        value:item.item_code,
                        label:item.item_code,
                    });
                })
            }
            this.setState({
                materiallist:records
            })
        })
    }

    // getDispatchPlanData = () =>{
    //     var params = {
    //         data_type : this.state.data_type,
    //         location_code : this.state.location.value,
    //     }
    //     redirectURL.post('dispatch/getDispatchPlanData',params)
    //     .then(response =>{
    //         this.setState({
    //             rowData:response.data,
    //             loadshow:'show-n',
    //             overly:'show-n',
    //             overlayBlock:"show-n"
    //         })
    //     })
    // }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
    onClickShowUpload = () =>{
        this.setState({
            showSlideBlockUpload:"slide25",
            overlayBlock:"show-m"
        })
    }

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
            loadFile:'',
            loadshow:'show-n',
            overly:'show-n',
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        redirectURL.post("dispatch/generateLoadByTransactionId",params).then((response)=>{
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if(statusCode == 0 || statusCode == 1)
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    processErrMsg : "",
                    processErrShow : 0,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            //window.location.reload();
        });
    }
    
    changeFileHandler = async (e) => {
        console.log(e);
        this.setState({
            loadFile : e.target.files[0]
        });  
        
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("loadFile", this.state.loadFile);
            var notify_email = $("#notify_email").val();
            saveData.append("notify_email", notify_email);
            saveData.append("module_type", 1);
            saveData.append("data_type", this.state.data_type);
            saveData.append("material_configurations", this.state.material_configurations)
            let location = this.state.location;
            saveData.append("location_code", location.value)
            saveData.append("location_name", location.label)
            saveData.append("user_name", localStorage.getItem('username'))
            saveData.append("email", localStorage.getItem('email'))
            console.log(saveData,saveData);
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            redirectURL.post("/dispatch/generateLoad",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                console.log(response,"responseFromProcess")
                var statusCode = response.data.status;
                if(statusCode == 8)
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        droppedList : response.data.droppedList,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else if(statusCode > 3 && statusCode != 8)
                {
                    if(statusCode == 11)
                    {
                        var processMsg = "Use the standard input data file template for uploading loads";
                    }
                    else
                    {
                        var processMsg = response.data.message;
                    }
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else
                {
                    this.checkTransactionStatus(response.data.transactionId);
                    // this.setState({                    
                    //     basicTitle : response.data.message,
                    //     basicType : "success",
                    //     show : true,
                    //     loadshow:'show-n',
                    //     overly:'show-n',
                    //     showSlideBlockUpload:"",
                    // });
                }
                //window.location.reload();
            });
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        redirectURL.post("/dispatch/checkTransactionStatus",query).then((response)=>{
            console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode == 0)
            {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount)+1;
                this.setState({
                    checkCount : updatedCnt
                });
                // console.log(updatedCnt,"checkCount")
                if(updatedCnt < 3)
                {
                    this.chkStatus(response.data.transactionId);
                }
                else
                {
                    this.setState({                    
                        basicTitle : "File is processing",
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                        loadFile:''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");
                }
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
                this.getDispatchPlanData()
            }
        });
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }

    viewPlanDetails = async(propdata) =>{
        var data = propdata.data;
        let basestring = data.transaction_id;
        let encryptedstring = window.btoa(basestring);
        window.location.href = '/viewdispatchplandetails?'+encryptedstring;
    }


    locationChanged = async(location) =>{
        await this.setState({
            location : location
        })
        await this.getDispatchPlanData()
    }
    onChangeCustomer(customer_code){
        this.setState({customer_code})
    }
    onChangeMaterial(material_code){
        this.setState({material_code})
    }
    onChangeDuration(duration){
        this.setState({duration})
    }
    onSubmitGetData(event){
        event.preventDefault();
        var customer_code = this.state.customer_code.value;
        var material_code = this.state.material_code.value;
        var duration = this.state.duration.value;
        var params = {
            customer_code:customer_code,
            item_code:material_code,
            fc_period:duration
        }
        redirectURL.post("/forecast/outputdata",params)
        .then((response) => {
            var rowdata = response.data;
            
            this.setState({
                rowData:rowdata
            })
        })
    }
    onClickTab(oVal){
        if(oVal == "1")
        {
            this.setState({
                datagrid:"show-m",
                chartdata:"show-n",
                datagridTab:"activet",
                chartdataTab:"btn-default"
            })
        }
        if(oVal == "2")
        {
            this.setState({
                datagrid:"show-n",
                chartdata:"show-m",
                datagridTab:"btn-default",
                chartdataTab:"activet"
            })
        }
    }

    fyitems(){
        var items = [];
        var c = moment.parseZone().format("YYYY");
        for(var n = 2016;n<parseInt(c)-1;n++)
        {
            items.push({value:n+"-"+(parseInt(n)+1),label:n+"-"+(parseInt(n)+1)})
        }
        return items;
    }

    onChangeFYDataOld(fyChart){
        this.setState({fyChart},()=>{
            // console.log("fyChart ", this.state.fyChart)
           var chartseries = this.state.chartseries[0].historic;
           var pchartseries = this.state.chartseries[0].predictData;
        //    var selectedfymonthsaa = this.state.chartseries.selectedfymonths;
        //    console.log("selectedfymonthsaa ", selectedfymonthsaa)
           console.log("chartseries ", chartseries)
           console.log("pchartseries ", pchartseries)
            var selectedfymonths = chartseries.filter((f) => this.state.fyChart.value === f.name);
            console.log("selectedfymonths ", selectedfymonths)
            var newchartseries = this.state.chartseries;
        //    newchartseries["selectedfymonths"] = selectedfymonths
        newchartseries[0]["allfymonthwise"]=[pchartseries,selectedfymonths[0]]
        console.log("newchartseries ", newchartseries)
            this.setState({
                chartseries:newchartseries
            })
        })
    }
    onChangeFYData(fyChart){
        this.setState({fyChart},()=>{
            // console.log("fyChart ", this.state.fyChart)
           var chartseries = this.state.allseason
        //    console.log("chartseries ", chartseries)
           var sp = this.state.fyChart.value.split("-");
        //    console.log(sp)
            var selectedfymonths = chartseries.filter((f) => parseInt(sp[0]) === f.name);
            // console.log("selectedfymonths ", selectedfymonths)

            this.setState({
                seasonality_train_plot:selectedfymonths[0]
            })
        })
    }


    onChangeFYCostData(fyCostChart){
        this.setState({fyCostChart},()=>{
            console.log("fyChart ", this.state.fyChart)
           var chartseries = this.state.chartseries[0].historicCost;
           var pchartseries = this.state.chartseries[0].predictCostData;
        //    var selectedfymonthsaa = this.state.chartseries.selectedfymonths;
        //    console.log("selectedfymonthsaa ", selectedfymonthsaa)
        //    console.log("chartseries ", chartseries)
        //    console.log("pchartseries ", pchartseries)
            var selectedfymonths = chartseries.filter((f) => this.state.fyCostChart.value === f.name);
            // console.log("selectedfymonths ", selectedfymonths)
            var newchartseries = this.state.chartseries;
        //    newchartseries["selectedfymonths"] = selectedfymonths
        newchartseries[0]["allfymonthwisecost"]=[pchartseries,selectedfymonths[0]]
        console.log("newchartseries ", newchartseries)
            this.setState({
                chartseries:newchartseries
            })
        })
    }
    onChangeChartCustomer(chartCustomer){
        this.setState({chartCustomer})
    }
    onChangeChartItem(chartItem){
        this.setState({chartItem})
    }
    onClickShowCustomerData(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            overlayBlock:"show-n"
        });
        var customer = this.state.chartCustomer.value;
        var itemCode = this.state.chartItem.value;
        // console.log("customer ", customer)

        var chartDataarr = this.state.allChartData;
        var chartData = chartDataarr.filter((f) => (f.customer_code === customer && f.item_code === itemCode));
        // console.log("chartData ", chartDataarr)
        // console.log("chartDatachartData ", chartData)
        var chartDatalist = [];
        var chartCusts=[];
        var chartItems=[];
        var areaplot=[];
        var areaYHat=[];
        var trendplot=[];
        var areaScatter=[];
        var confLineSeasonal=[]

        var monthsChart = ["04", "05","06","07","08","09","10","11","12","01","02","03"];
        var monChart = ["Jan","Feb","Mar", "Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        try{
            
            // if(chartData.length > 0)
            // {
            //     var confidence_train_points = [];
            //     var cn=0;
            //     if(chartData[0].confidence_train_points !== undefined)
            //     {
            //         if(chartData[0].confidence_train_points.length > 0)
            //         {
            //             chartData[0].confidence_train_points.map((itm,ind) => {
            //                 var d = itm[0]+"-"+monthsChart[ind]+"01 00:00";
            //                 var dt = moment.parseZone(new Date(d+":00")).utcOffset("+05:30")._d;
            //                 var timefrm = moment.parseZone(dt).format("x");
            //                 confidence_train_points.push([ind,parseInt(itm[1])]);
                            
            //             })
            //         }
            //     }
                
            //     var trend_existing_points = [];
            //     if(chartData[0].trend_existing_points !== undefined)
            //     {
            //         chartData[0].trend_existing_points.map((itm,ind) => {
            //             trend_existing_points.push({
            //                 name: itm[0],
            //                 y:parseInt(itm[1])
            //             })
            //         })
            //     }
                
                
            //     var confidence_test_points = [];
            //     if(confidence_train_points.length > 0)
            //     {
            //         var ed =confidence_train_points[confidence_train_points.length-1][0];
            //         if(chartData[0].confidence_test_points !== undefined)
            //         {
            //             chartData[0].confidence_test_points.map((itm,ind) => {
            //                 confidence_test_points.push([parseInt(ed),parseInt(itm[1])]);
            //                 ed=ed+1;
            //             })
            //         }
                    
            //     }
            
            //     // console.log("confidence_test_points ", confidence_test_points)
            //     var trend_predicted_points = [];
            //     if(trend_existing_points.length > 0)
            //     {
            //         // console.log("trend_existing_points[trend_existing_points.length-1] ", trend_existing_points[trend_existing_points.length-1])
            //         trend_predicted_points.push({name:trend_existing_points[trend_existing_points.length-1].name,y:trend_existing_points[trend_existing_points.length-1].y})
                    
            //         trend_predicted_points.push({name:chartData[0].trend_predicted_points[0],y:parseInt(chartData[0].trend_predicted_points[1])});
            //     }
            //     // console.log("HEre trend_predicted_points", trend_predicted_points)
            //     var seasonality_train_plotar = [];
            //     // console.log('chartData[0].seasonality_train_plot ', chartData[0].seasonality_train_plot)
            //     if(chartData[0].seasonality_train_plot !== undefined)
            //     {
            //         chartData[0].seasonality_train_plot.map((itm) => {
                    
            //             seasonality_train_plotar.push(JSON.parse(itm));
            //         })
            //     }
                
            //     // console.log("seasonality_train_plotar ", seasonality_train_plotar)
            //     var seasonality_train_plot = [];

            //     if(seasonality_train_plotar.length > 0)
            //     {
            //         var sdatatrne = groupBy(seasonality_train_plotar, rdata => rdata.year);
            //         try {
            //             // console.log("sdatatrne ", sdatatrne)
                        
            //             sdatatrne.forEach((values,key) => {
                        
            //                 var seasonality_train_plotd = [];
            //                 if(values.length > 0)
            //                 {
            //                     values.map((im) => {
            //                         // console.log("itm ", key+"===="+JSON.stringify(im))
            //                         // console.log("monChart[im.month-1] ", monChart[parseInt(im.month)-1])
            //                         var nm = monChart[parseInt(im.month)-1];
            //                         // console.log("nm ", nm)
            //                         seasonality_train_plotd.push({
            //                             name:monChart[parseInt(im.month)-1],
            //                             y:parseInt(im.item_qty)
            //                         })
            //                     })
            //                 }
            //                 // console.log("seasonality_train_plotd ",seasonality_train_plotd)
            //                 seasonality_train_plot.push({
            //                     name:key,
            //                     data:seasonality_train_plotd
            //                 })
            //             });
            //         } catch (error) {
                        
            //         }
            //     }

            //     var seasonality_pred_plot = [];
            //     if(chartData[0].seasonality_pred_plot !== undefined)
            //     {
            //         var seasonality_pred_plotar = chartData[0].seasonality_pred_plot;
            //         if(seasonality_pred_plotar.length > 0){
            //             seasonality_pred_plotar.map((itm) => {
            //                 seasonality_pred_plot.push({
            //                     name:monChart[parseInt(itm.month)-1],
            //                     y:parseInt(itm.item_qty)
            //                 })
            //             })
            //         }
                    
            //     }
                
            //     var confidenceTrain = confidence_train_points; 
            //     var confidenceTrend = [{name:"Trend Points",data:trend_existing_points},{name:"Predict",data:trend_predicted_points,color:"#FF0000"}];
            //     var confidenceTestPoints = confidence_test_points; 
            //     var confidencePredictPoint = trend_predicted_points; 
            //     var seasonality_pred_plot = {name:seasonality_pred_plotar[0].year,data:seasonality_pred_plot};
            //     // seasonality_train_plot = seasonality_train_plot;
            //     seasonality_train_plot.push(seasonality_pred_plot);
                

            //     // console.log("confidenceTrend ", confidenceTrend)
            // }
            // else{
            //     var confidenceTrain = []; 
            //     var confidenceTrend = [];
            //     var confidenceTestPoints = []; 
            //     var confidencePredictPoint = [];
            //     var seasonality_pred_plot = ""
            //     var seasonality_train_plot = "";
            // }
           
            if(chartData.length > 0)
            {
                chartData.map((mm) => {
                    var chartSeries = mm.chart_data;
                    var motnhSeries = mm.month_data;
                    if(chartSeries.length > 0)
                    {
                        chartSeries.map((mt) => {
                            var tms = moment.parseZone(mt.ds).format("x");
                            // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                            areaplot.push(mt.area_plot);
                            areaYHat.push((mt.yhat < 0)?0:mt.yhat);
                            if(mt.ds !== "" && mt.ds !== undefined)
                            {
                                var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                if(mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "")
                                {
                                    if(parseInt(trnedns) < 2022)
                                    {
                                        trendplot.push({name:trnedns,y:mt.trend,lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                    }
                                }
                                // confLineSeasonal.push({name:tms/1000,y:Math.round(mt.yearly),lbl:Math.round(mt.yearly).toLocaleString()});
                                // confLineSeasonal.push([tms/1000,mt.yearly]);
                            }
                            if(mt.actual !== "" && mt.actual !== undefined && mt.actual !== null)
                            {
                                // if(mt.actual > 0)
                                // {
                                    areaScatter.push(mt.actual);
                                // }
                                
                            }
                        })
                        
                    }
                    if(motnhSeries.length > 0)
                    {
                        motnhSeries.map((mt) => {
                            confLineSeasonal.push({name:mt.month,y:Math.round(mt.month_sum),lbl:Math.round(mt.month_sum).toLocaleString()});
                            
                            
                        })
                    }
                })
            }
            // if(seasonality_train_plot.length > 0)
            // {
            //     var ss = seasonality_train_plot[seasonality_train_plot.length-1]
            // }
            // else{
            //     var ss = [];
            // }
        }
        catch(e){}
        // console.log("confLineSeasonal ", confLineSeasonal)
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            overlayBlock:"show-n",
            
            // chartCusts:chartCustsarr,
            // chartItems:chartItemarr,
            // allChartData:chartDatalist,
            confAreaData:areaplot,
            confAreaYHat:areaYHat,
            confTrendplot:trendplot,
            confScatter:areaScatter,
            confLineSeasonal:confLineSeasonal
        })
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    }
    onGridReady1 = (params) => {
		this.gridApiNew = params.api;
		this.gridColumnApiNew = params.columnApi;

    }
    onBtnExport = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApi.exportDataAsCsv(
            {
                fileName: filename
              }
        );
    }
    onBtExportExcel = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApi.exportDataAsExcel(
            {
                fileName: filename
              }
        );
    }
    onBtnExportF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew.exportDataAsCsv(
            {
                fileName: filename
              }
        );
    }
    onBtExportExcelF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew.exportDataAsExcel(
            {
                fileName: filename
              }
        );
    }
    render() {

        var columnwithDefs = [
            {
                headerName: "Transaction Id",
                field: "transaction_id",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Financial Year",
                field: "fy",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Material Code",
                field: "item_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Customer Code",
                field: "customer_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            // {
            //     headerName: "NRB Customer Code",
            //     field: "nrb_customer_code_1",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
           
            {
                headerName: "Apr",
                field: "Apr",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "May",
                field: "May",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jun",
                field: "Jun",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jul",
                field: "Jul",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Aug",
                field: "Aug",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Sep",
                field: "Sep",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Oct",
                field: "Oct",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Nov",
                field: "Nov",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Dec",
                field: "Dec",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jan",
                field: "Jan",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Feb",
                field: "Feb",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Mar",
                field: "Mar",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            
           
            
        ]
        return (
            <div class="container-fluid">
                <div className={"animatenow "+(this.state.showanimate)} style={{textAlign:"center"}}>
                    <span className="pp-h">Processing Plan. Please wait.....</span>
                    <img src={require("../../assets/images/r.gif")} style={{width:"85vh"}} />
                </div>
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            Forecasting

                            <a href={"/forecast-predict"} className="btn btn-default float-right">Back</a>
                        </h5>
                    </div>
                </div>
              
                <div className="row mt-20p">
                    <div className="col-md-12 col-sm-12">
                        <ul className="d-tabs">
                            <li onClick={this.onClickTab.bind(this,"1")} className={"lttabs "+(this.state.datagridTab)}>
                                <button type="button" className="btn">Summary Data</button>
                            </li>

                            <li onClick={this.onClickTab.bind(this,"2")} className={"lttabs "+(this.state.chartdataTab)}>
                                <button type="button" className="btn">Data Visualization</button>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className={"mt-20p "+(this.state.chartdata)}>
                    <div className="row">
                    <div className={" col-sm-2 "} >
                        <Select
                            options={this.state.chartCusts}
                            onChange={this.onChangeChartCustomer.bind(this)}
                            value={this.state.chartCustomer}
                        />
                    </div>
                    <div className={" col-sm-2 "} >
                        <Select
                            options={this.state.chartItems}
                            onChange={this.onChangeChartItem.bind(this)}
                            value={this.state.chartItem}
                        />
                    </div>
                    
                    <div className={" col-sm-2 "} >
                        <button type="button" style={{zIndex:9}} onClick={this.onClickShowCustomerData.bind(this)} className="btn btn-danger">Get Data</button>
                    </div>
                    </div>
                </div>
                <div className={"row mt-20p "+(this.state.chartdata)}>
                    <div className={"col-sm-12 "} >
                       &nbsp;
                    </div>
                </div>
                <div className={"row mb-20p "+(this.state.chartdata)}>
                {/* {(this.state.chartseries.length > 0)?
                    <div className={"mxheit col-sm-12 mt-20p "} >
                        <div className="card">
                            <div className="card-body">
                                
                                <ColumnChart
                                title={"FY wise Orders"}
                                series={this.state.chartseries[0].overallfinancialorders}
                                />
                               
                            </div>
                        </div>
                    </div> :""}
                    
                    {(this.state.chartseries.length > 0)?
                    <div className={"mxheit col-sm-12  mt-20p"} >
                        <div className="card">
                            <div className="card-body">
                                <ColumnChart
                                title={"FY wise Costs"}
                                series={this.state.chartseries[0].overallfinancialcost}
                                />
                               
                            </div>
                        </div>
                    </div> :""} */}

                        {/* {(this.state.confidenceTrain.length > 0 && this.state.confidenceTestPoints.length > 0)? */}
                        <div className={"mxheit col-sm-12 mt-20p"}>
                            <div className="card">
                               
                                <div className="card-body">
                                   
                                    <ConfidenceChart
                                        title={"Demand Forecast"}
                                        subtitle={"Material Code: "+(this.state.chartItem.value)+" Customer Code: "+(this.state.chartCustomer.value)}
                                        confLine={this.state.confidenceTrain}
                                        confLineTrend = {this.state.confidenceTrend}
                                        confLinePredictTest = {this.state.confidenceTestPoints}
                                        confLinePredictTrend = {this.state.trend_predicted_points}
                                        confAreaData={this.state.confAreaData}
                                        confAreaYHat={this.state.confAreaYHat}
                                        confScatter={this.state.confScatter}
                                        // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                        // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                    />
                                </div>
                            </div>
                        </div>
                         {/* :""} */}

                        {/* {( this.state.confidenceTrend.length > 0)? */}
                         <div className={"mxheit col-sm-12 mt-20p"}>
                            <div className="card">
                               
                                <div className="card-body">
                                   
                                    <LineChart
                                        title={"Yearly Trend"}
                                        subtitle={"Material Code: "+(this.state.chartItem.value)+" Customer Code: "+(this.state.chartCustomer.value)}
                                        confLineTrend = {this.state.confTrendplot}
                                        
                                    />
                                </div>
                            </div>
                        </div>
                        {/* :""} */}
                        
                        {/* {( this.state.seasonality_train_plot !== undefined && this.state.seasonality_pred_plot !== "" && this.state.seasonality_pred_plot !== undefined)? */}
                        <div className={"mxheit col-sm-12 mt-20p"}>
                            <div className="card">
{/*                                 
                                <div className="card-header">
                                    <div style={{width:"36%",zIndex:"9"}}>
                                        <div className="row">
                                            <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Select FY: </label>
                                            <div className="col-sm-8">
                                                <Select
                                                    value={this.state.fyChart}
                                                    options={this.fyitems()}
                                                    onChange={this.onChangeFYData.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="card-body">
                                    <AreaRangeChart
                                        title={"Seasonality Data"}
                                        subtitle={"Material Code: "+(this.state.chartItem.value)+" Customer Code: "+(this.state.chartCustomer.value)}
                                        confLineSeasonal={this.state.confLineSeasonal}
                                        // predictseries={this.state.seasonality_pred_plot}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* :""} */}
                                    
                    <br /><br />
                </div>
                <div class={"row mb-20p "+(this.state.datagrid)}>
                    <div className="row">
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-default float-right" onClick={this.onBtnExport.bind(this)}>Export to CSV</button>
                                <button type="button" className="btn btn-default float-right" onClick={this.onBtExportExcel.bind(this)}>Export to Excel</button>
                            </div>
                        </div>
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                // masterDetail={true}
                                // detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">Upload Input File</h5>
                    <div className="row">
                        
                    </div>
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                <div className={"loader "+this.state.loadshow}></div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>
            </div>
        )
    }
}

function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arr_diff (a1, a2) {

    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}