import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';  
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import getToolConfiguration from "./commonFunctions";
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import GridButton from './gridButton';
import CSVFileValidator from 'csv-file-validator';

var moment = require("moment");


export default class VehiclesAvailability extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleAvailabilityModel: false,
            data_type: '',
            location: this.props.plantLocation,
            vehicle_date: setHours(setMinutes(new Date(), 30), 16),
            vehicle_no: '',
            vehicleNumList: [],
            vehicle_availability_time: ['00:00','23:59'],
            break_time: ['00:00','23:59'],
            show:false,
            basicTitle:"",
            basicType:"default",
            availability_id:'',
            overlayBlock:"show-n",
            showSlideBlock:"",
            csvfiledata:[],
        }
            
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        let url_type = ul[parseInt(ul.length)-1];
        let path_type = url_type
        let data_type
        let path =url_type


        if (path == 'inbound'){
            data_type = 1
            this.setState({data_type : data_type})
        }else{
            data_type = 2
            this.setState({data_type : data_type})
        }
        let params = {
            data_type : data_type
        }
       await getToolConfiguration(params)
       await this.getMasterAvailabilityVehiclesData(params)
       await this.getVehiclesAvailabilityData(params)
        
    }


      handleVehicleDate = (date) =>{
        console.log(date,'fff')
        this.setState({
            vehicle_date : date
          })
      }


      getMasterAvailabilityVehiclesData = (params) =>{
        let vehicleNumList = this.state.vehicleNumList

        redirectURL.post('master/getMasterAvailabilityVehiclesData',params)
        .then(async (response) =>{
            if(response.data.status == 'success'){

                let vehiclesData = response.data.masterVehicleAvailabilityData
                if(vehiclesData.length > 0){
                    vehiclesData.map(item =>{
                        vehicleNumList.push({ value : item.vehicle_no,
                            label : item.vehicle_no,code:item.vehicle_type })
                    })
                    await this.setState({
                        vehicleNumList : vehicleNumList
                    })
                }
        }
        })
    }

    vehicleNumChanged = (vehicle_no) =>{
        this.setState({
            vehicle_no : vehicle_no
        })
    }

    
    getVehiclesAvailabilityData = async(params) =>{
    redirectURL.post('master/getVehicleAvailabilityData',params)
    .then(async (response) =>{
        if(response.data.status == 'success'){

            let vehicleAvailabilityData = response.data.vehicleAvailabilityData
            // vehicleAvailabilityData.map(item => item.vehicle_date = moment.parseZone(item.vehicle_date).format("YYYY-MM-DD"))
            await this.setState({
                rowData:vehicleAvailabilityData
            })
    }
    })
    }

    openModelForVehicleAvailability = () =>{
        
        this.setState(prevState =>({
            openVehicleAvailabilityModel :!prevState.openVehicleAvailabilityModel
        }))
    }

    handelVehicleAvailabilityModelCancel = ()=>{

        this.setState(prevState =>({
            openVehicleAvailabilityModel :!prevState.openVehicleAvailabilityModel,
            vehicle_date:'',
            vehicle_availability_time:['00:00','23:59'],
            break_time:['00:00','23:59'],
            vehicle_no:'',
            availability_id:'',
        }))

    }

    vehicleAvailabilityInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    handleAvailabilityTime = (val) => {
    	this.setState({vehicle_availability_time:val});
    }

    handleBreakTime = (val) => {
    	this.setState({break_time:val});
    }


    validateTimeRange = (from_time, to_time) =>{
        let frm_hr = from_time.split(':')
            let to_hr = to_time.split(':')
            
            if(from_time == to_time){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }
            else if(frm_hr[0] > to_hr[0]){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }else if(frm_hr[0] == to_hr[0]){
                if(frm_hr[1] > to_hr[1]){
                    this.setState({
                        show:true,
                        basicTitle:"Please Input Time Appropriately",
                        basicType:"danger"
                    })
                }else{
                    return true
                }

            }else{
                return true
            }

    }

    saveVehicleAvalabilityData = (params) =>{
        let data_type = this.state.data_type

        redirectURL.post('/master/saveVehicleAvailability',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesAvailabilityData(param)
                this.setState({openVehicleAvailabilityModel:false,
                            vehicle_date:'',
                            vehicle_availability_time:['00:00','23:59'],
                            break_time:['00:00','23:59'],
                            vehicle_no:'',
                            availability_id:'',
                        })
                    }else{
                        this.setState({
                            show:true,
                            basicTitle:"Data Not Saved successfully",
                            basicType:"danger"
                        })
                    }

                })
    }

    formVehicleAvailability = (e) =>{
        e.preventDefault();
        let data_type = this.state.data_type;
        let location = this.state.location;
        let vehicle_date = this.state.vehicle_date;
        let vehicle_no = this.state.vehicle_no;
        let break_time = this.state.break_time;
        let vehicle_availability_time = this.state.vehicle_availability_time;
        let availability_id = this.state.availability_id;
        
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let params = {
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            break_time_from : break_time[0],
            break_time_to : break_time[1],
            vehicle_availability_from : vehicle_availability_time[0],
            vehicle_availability_to : vehicle_availability_time[1],
            vehicle_no : vehicle_no.value,
            vehicle_date : vehicle_date,
            vehicle_type : vehicle_no.code
            
        }

        if(availability_id != ''){

            params.availability_id = availability_id
            if(toolConfig[0].vehicle_operational_times_type == 2){
            

                let vehicle_time = this.validateTimeRange(vehicle_availability_time[0],vehicle_availability_time[1])
                let break_times = this.validateTimeRange(break_time[0],break_time[1])
    
                if(vehicle_time && break_times){
    
                    this.updateVehicleAvailabilityData(params)  
                }
    
            }else{
                this.updateVehicleAvailabilityData(params)
            }

        }else{

            if(location.value != null){

                if(vehicle_no.value != null){

                    if(toolConfig[0].vehicle_operational_times_type == 2){
                        

                        let vehicle_time = this.validateTimeRange(vehicle_availability_time[0],vehicle_availability_time[1])
                        let break_times = this.validateTimeRange(break_time[0],break_time[1])

                        if(vehicle_time && break_times){

                            this.saveVehicleAvalabilityData(params)  
                        }

                    }else{
                        this.saveVehicleAvalabilityData(params)
                    }
                }else{
                    this.setState({
                        show:true,
                        basicTitle:"Please add Vehicles First",
                        basicType:"danger"
                    })
                }
            }else{
                
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
            }

        }
    }
    

    updateVehicleAvailability = async(propdata) =>{
        let data = propdata.data
        console.log(data,'ffq')
            await this.setState({
                // vehicle_date: new Date( data.vehicle_date),
                vehicle_date:moment.parseZone(data.vehicle_date).utcOffset("-05:30")._d,
                vehicle_no:{label:data.vehicle_no, value:data.vehicle_no,code:data.vehicle_type},
                vehicle_availability_time:[data.vehicle_availability_from,data.vehicle_availability_to],
                break_time:[data.break_time_from,data.break_time_to],
                availability_id:data.availability_id,
            })
            this.openModelForVehicleAvailability()
    
    }
    
    updateVehicleAvailabilityData = (params) =>{
        let data_type = this.state.data_type   

        redirectURL.post('/master/updateVehicleAvailability',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesAvailabilityData(param)
                this.setState({openVehicleAvailabilityModel:false,
                            vehicle_date:'',
                            vehicle_availability_time:['00:00','23:59'],
                            break_time:['00:00','23:59'],
                            vehicle_no:'',
                            availability_id:'',
                        })
                    }else{
                        this.setState({
                            show:true,
                            basicTitle:"Data Not Saved successfully",
                            basicType:"danger"
                        })
                    }

                })
    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}


    deleteVehicleAvailabilityDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteVehicleAvailabilityDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesAvailabilityData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }

    openUploadOptionForAvailability = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlock:"slide25",
            overlayBlock:"show-m"
          })
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
        })
      }

      formCSVUpload = (event) =>
      {
        let data_type = this.state.data_type
        let location = this.state.location
        event.preventDefault();
        var csvfiledata = this.state.csvfiledata;
        var p = {
          filedata: csvfiledata,
          data_type:data_type,
          location_code : location.value,
          location_name : location.label,
        }
        redirectURL.post("/master/uploadVehicleAvailabilityData",p)
        .then(async(resp) => {
          
          if(resp.data.status == 'success'){
            await this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"success",
                overlayBlock:"show-n",
                showSlideBlock:"",
            })
            window.location.reload();
            
          }else{
            this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"danger"
            })
          }
        })
      }


      changeCSVFileHandler = async (e) => {
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let transporterConfiguration = toolConfig[0].plant_wise_transporter_configuration
        const config = {
            headers: [
                // { 
                //     name: 'Location Code',
                //     inputName: 'location_code',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                // { 
                //     name: 'Location Name',
                //     inputName: 'location_name',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                      
                { 
                    name: 'Vehicle Number',
                    inputName: 'vehicle_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Date',
                    inputName: 'vehicle_date',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                // { 
                //     name: 'From Time',
                //     inputName: 'from_time',
                //     required: false,
                // },
                // { 
                //     name: 'To Time',
                //     inputName: 'to_time',
                //     required: false,
                // }
            ]
        }
        
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            document.getElementById("inValidDataInfo").innerHTML = "";
            if(csvData.inValidData.length >0){
                document.getElementById("bulkUploadBtn").type = "button"
                document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                let invalidData = csvData.inValidData;
                let element = document.getElementById("inValidDataInfo")
                invalidData.map(item =>{
                    let row,column
                    if(item.rowIndex == undefined){
                        row = 'NA'
                    }else{
                        row = item.rowIndex
                    }
                    if(item.columnIndex == undefined){
                        column = 'NA'
                    }else{
                        column = item.columnIndex
                    }
                    
                    element.innerHTML += "Column : "+column+","+" Row : " + row +"--"+ item.message +"<br></br>"
                })
                 
            }else{
                document.getElementById("bulkUploadBtn").type = "submit"
                document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                document.getElementById("bulkUploadBtn").classList.add("btn-danger");
                this.setState({
                    csvfiledata:csvData.data
                });
            }
        })
        .catch(err => {})
      }


    render(){

        var colsDefs = [
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                filter:false,
                sortable:false,
                params:{buttonName:'Edit',
                        onClickFunction:this.updateVehicleAvailability},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteVehicleAvailabilityDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"Date and Time",
                field:"vehicle_date",
                width:"160"
            },
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"130"
            },
            {
                headerName:"Vehicle Number",
                field:"vehicle_no",
                width:"130"
            },
            {
                headerName:"Vehicle Operational(from)",
                field:"vehicle_availability_from",
                width:"190",
            },
            {
                headerName:"Vehicle Operational(to)",
                field:"vehicle_availability_to",
                width:"180"
            },
            {
                headerName:"Break Time(from)",
                field:"break_time_from",
                width:"140",
            },
            {
                headerName:"Break Time(to) ",
                field:"break_time_to",
                width:"140",
            },
        ]

        let data = this.state.rowData
        let locValue = this.state.location.value   
        let filteredData = data.filter(item => item.location_code == locValue)

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let operationalTimesType = 0;
        let transporterConfiguration = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                operationalTimesType = toolConfig[0].vehicle_operational_times_type;
            }
        }
            var templatePath = require('../../assets/json/Vehicle_Availability_data_template.csv');
        
        
        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
            <div class="row mb-20p pt-20p">
                <div className="col-sm-12">
                    
                        <h5 className='fbold d-flex justify-content-between'>
                            <span>{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_HEADER_NAME}</span>
                            
                            <div className='float-right col-sm-3'>
                            <a href="javascript:;" onClick={this.openModelForVehicleAvailability} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_BUTTON_NAME}</a>
                            <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForAvailability} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_BULK_BUTTON_NAME}</a>
                            </span>
                            </div> 
                        </h5>

                        <div id="myGrid" style={{width:"100%",height:"80vh"}} className="col-sm-12 ag-theme-balham">
                            <AgGridReact
                            modules={this.state.modules} 
                                rowData={filteredData}
                                columnDefs={colsDefs}
                                gridOptions={{context:{componentParent:this}}}
                                defaultColDef={this.state.defaultColDef}
                                frameworkComponents={this.state.frameworkComponents}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                
                            />
                        </div>
                </div>
            </div>

            <Modal closeOnOverlayClick={false} open={this.state.openVehicleAvailabilityModel} onClose={this.handelVehicleAvailabilityModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>Vehicle Availability</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formVehicleAvailability}>

                        <div className="row number">

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_NUMBER}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.vehicleNumChanged}
                                        name="vehicle_no"
                                        value={this.state.vehicle_no}
                                        options={this.state.vehicleNumList} 
                                        />

                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Vehicle Type</label>
                                    <input type="text" disabled
                                        className= "forminp form-control" 
                                        name="vehicle_type" 
                                        value={this.state.vehicle_no.code}
                                        placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div>
                            
                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Vehicle Number</label>
                                    <input type="text" 
                                        className= "forminp form-control"
                                        required 
                                        name="vehicle_no" 
                                        value={this.state.vehicle_no}
                                        onChange={this.vehicleAvailabilityInputFields} 
                                        placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div>                            */}

                            

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12 d-block">Date</label>
                                  
                                    <DatePicker 
                                    todayButton="Today"
                                    onChange={this.handleVehicleDate} 
                                    minDate={subDays(new Date(), 0)}
                                    className="forminp form-control"
                                    required
                                    injectTimes={[
                                        setHours(setMinutes(new Date(), 1), 0),
                                        setHours(setMinutes(new Date(), 5), 12),
                                        setHours(setMinutes(new Date(), 59), 23),
                                      ]}
                                    selected={this.state.vehicle_date}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy/MM/dd h:mm aa"
                                    />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_AVAILABILITY_TIME}</label>
                                    {operationalTimesType == 2 ?
                                    <TimeRangePicker  onChange={this.handleAvailabilityTime} value={this.state.vehicle_availability_time} rangeDivider={'to'} 
                                    required={true} 
                                    className =  "forminp form-control vehicle-time"
                                    clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    :<TimeRangePicker  rangeDivider={'to'} value={this.state.vehicle_availability_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    }
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_BREAK_TIME}</label>
                                    {operationalTimesType == 2 ?
                                    <TimeRangePicker  onChange={this.handleBreakTime} value={this.state.break_time} rangeDivider={'to'} 
                                    required={true} 
                                    className =  "forminp form-control vehicle-time"
                                    clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    :<TimeRangePicker  rangeDivider={'to'} value={this.state.break_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    }
                                </div>
                            </div>
                            
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelVehicleAvailabilityModelCancel}>CANCEL</button>	
                        </div>
                       
                    </form>
                </div>
                    </Modal>

                    <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                    <div className={"sliderBlock2 "+(this.state.showSlideBlock)}>
                    <div className="row">   
                        <div className="col-sm-12">
                        <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                        <div className="row p-20p">
                            <div className="form-group col-sm-12">
                                            <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>                            
                            </div>
                            <div className="form-group col-sm-12 mb-20p">                                
                                <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                            </div>
                            <div className="form-group col-sm-12 mb-20p">
                            <button type="submit" id="bulkUploadBtn" className="btn btn-danger">SAVE</button>
                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                            </div>
                            <div id="inValidDataInfo" style={{color:"red"}}></div>
                        </div>
                        </form>
                        </div>
                    </div>
                    </div>

            </>
        )
    }
}
