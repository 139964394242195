import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import { verifyext } from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import MaterialDataChart from './materialChartDataChart.js';
import ViewLearningData from "./viewlearningdata";
import Mlist from "./menulist";
import StaticButton from './static-button';
import TriggerLearningChart from "./triggerlearningchart";
import MaterialList from './materialList';
import { UserActivityContext } from '../context';



var infoBox = require('../common/google-infowindow');
const scaleanimation = {
    transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows = [];
var markersArraylist = [];

export default class ForecastOutputParams extends Component {
    static contextType = UserActivityContext
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            frameworkComponents: {
                GridButton: GridButton,
                ViewLearningData: ViewLearningData
            },
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            detailCellRendererParams: {},
            detailCellRendererParams1: {},
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 1000,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            showSlideBlockRoute: "sidebarcls",
            showSlideBlockUpload: "sidebarcls",
            showSlideBlockStack: "sidebarcls",
            overlayBlock: "show-n",
            routeData: [],
            rfreshmap: 1,
            deliverpickupslist: [],
            dealers: [],
            plants: [],
            uploadfile: '',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            loadplan: [],
            allLoadPlan: [],
            deliverydates: [],
            deliveryopts: [],
            filterDeliveryDate: { "value": "", "label": "Select Delivery Date" },
            clusterlist: [],
            filtercluster: { "value": "", "label": "Select Cluster" },
            vehicletypelist: [],
            filterVehicleType: { "value": "", "label": "All" },
            mapcol: "show-n",
            gridocl: "col-sm-12",
            filter_date: "",
            vtypediv: "show-n",
            vsupplierdiv: "show-n",
            selectedCluster: "",
            fdealerlist: [],
            filterdealer: { "value": "", "label": "All" },
            showloader: "show-n",
            mpnode: [],
            mpfor: "",
            clusterpoints: [],
            nmapcluster: "show-n",
            nmap: "show-m",
            scluster: "",
            stackContent: "",
            stackImage: "",
            stacktbl: [],
            stacktblbottom: [],
            stackOriginalData: [],
            showrunprocessbtn: "show-m",
            showanimate: "show-n",
            locationNames: [],
            loadFile: '',
            material_configurations: 0,
            location: { label: "Select Location", value: "" },
            loadshow: 'show-m',
            overly: 'show-m',
            processErrMsg: "",
            processErrShow: "",
            transactionId: "",
            droppedList: [],
            customer_code: [{ "value": "ALL", "label": "All" }],
            material_code: [{ "value": "ALL", "label": "All" }],
            duration: { "value": "12", "label": "All" },
            customerlist: [],
            materiallist: [],
            durationlist: [],
            chartdata: "show-n",
            datagrid: 'show-m',
            datagridTab: "activet",
            chartdataTab: "btn-default",
            chartseries: [],
            rowForecastData: [],
            forecastitemslist: [],
            forecastcustomerlist: [],
            allforecastcustomerlist: [],
            forecastitem: { "value": "", label: "Select" },
            forecastCustomer: [{ "value": "", label: "All" }],
            predRecords: [],
            forecastingCustomersData: [],
            dataShow: "show-m",
            forecastData: "show-n",
            dayspred: 0,
            inpShow: "show-n",
            durationlist: [
                // {
                //     value:1,
                //     label:"Monthly"
                // },
                {
                    value: 3,
                    label: "3 Months"
                }, {
                    value: 6,
                    label: "6 Months"
                }, {
                    value: 12,
                    label: "1 Year"
                },
                // {
                //     value:0,
                //     label:"Custom"
                // }
            ],
            sliderType: 2,
            sliderTitle: "Trigger Learning",
            learningData: [],
            yearData: [],
            plotData: [],
            fyData: [],
            redirectChk: 0,
            duplicateChk: 0,
            consumptionDetails: [],
            consumptionData: [],
            chartData: [],
            confidenceTrain: [],
            confidenceTrend: [],
            confidenceTestPoints: [],
            confidencePredictPoint: [],
            confAreaData: [],
            confAreaYHat: [],
            confScatter: [],
            chartMaterial: "",
            trendingCategories: [],
            trendingcost: [],
            verticalColumn: [],
            learningComponent: true,
            yettolearnComponent: false,
            yetToLearnRowData: [],
            completedData: [],
            totalComponent: false,
            totalRowData: [],
            notCompletedCount: 0,
            completedCount: 0,
            totalCount: 0,
            scenarioslist: [],
            scenario: { value: "", label: "All" },
            allrowData: [],
            materialsRowData: [],
            selectedOption: "view",
            latestPhase: "",
            latestPhases: [],
            originalLatestPhases: [],
            actualUploadedDetailedData: [],
            isPopupOpen: false
        }

    }
    async componentDidMount() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            overlayBlock: "show-n"
        })
        await this.getMaterialList()
        await this.loadPageData()
        await redirectURL.post("/forecast/getActualUploadedDataPhases", {})
            .then(async response => {
                // console.log(response, "tyurght")
                const regex = /(\d{4}-\d{2}-\d{2})/;

                // Extract and create a new array with just the date parts
                const extractedDates = response.data.map((isoDateString) => {
                    const match = isoDateString.match(regex);
                    const date = match ? match[0] : null;
                    return { label: date, value: date };
                });
                // console.log(extractedDates, "tyurght")
                this.setState({
                    latestPhases: extractedDates,
                    originalLatestPhases: response.data
                })
            })

    }
    loadPageData() {
        this.setState({ overlayBlock: "show-m", loadshow: 'show-m', })
        let totalRowData = [];
        var params = {}
        // console.log(this.state.forecastitem)
        if (this.state.forecastitem != undefined && this.state.forecastitem != "") {
            if (this.state.forecastitem.value != undefined && this.state.forecastitem.value != "" && this.state.forecastitem.value != "all") {
                params.item_code = this.state.forecastitem.value
            }
        }
        redirectURL.post('/forecast/forecastlearningdata', params)
            .then(async response => {
                var records = response.data[0];
                var scenariosListData = response.data[1]
                var scenarioslist = [];
                // scenariosListData.map((item) => {
                //     scenarioslist.push({ value: item, label: item });
                // })
                scenarioslist = scenariosListData.map(item => ({ value: item, label: item }));

                let learningCompletedMaterials = records;
                console.log(params, "params290");
                
                redirectURL.post('/forecast/getDataFromMaterialsMaster', params)
                    .then(async response => {
                        let rec = response.data;

                        // await records.map(item => {

                        //     rec.map(recItem => {
                        //         if (recItem.item_code == item.item_code) {
                        //             item.item_description = recItem.item_description;
                        //             item.learning_completed = recItem.learning_completed;
                        //             item.material_classification = recItem.material_classification;
                        //             item.material_type = recItem.material_type;
                        //             // item.item_cost = recItem.item_cost;
                        //         }
                        //     })
                        // })
                        const recLookup = new Map();
                        await rec.forEach(recItem => {
                            recLookup.set(recItem.item_code, recItem);
                        });

                        // Update the records using the lookup table
                        await records.forEach(item => {
                            const recItem = recLookup.get(item.item_code);
                            if (recItem) {
                                item.item_description = recItem.item_description;
                                item.learning_completed = recItem.learning_completed;
                                item.material_classification = recItem.material_classification;
                                item.material_type = recItem.material_type;
                                // item.item_cost = recItem.item_cost;
                            }
                        });
                        console.log(records, "new records")

                        // const yetToLearnRowData = rec.filter((element) => {
                        // return !learningCompletedMaterials.some((object) => (object.item_code === element.item_code && object.learning_status !== 1));
                        // }).map(matCode => {
                        // return {
                        // item_code: matCode.item_code,
                        // item_description: matCode.item_description,
                        // learning_completed: matCode.learning_completed
                        // };
                        // });
                        //need to add learn
                        var yetToLearnRowData = learningCompletedMaterials.filter((each) => (each.learning_status == 1))
                        var completedData = learningCompletedMaterials.filter((each) => (each.learning_status == 2))
                        totalRowData = learningCompletedMaterials.concat(yetToLearnRowData)
                        //                       console.log(totalRowData, "na su")
                        //                       var total = [...totalRowData.filter((each) => (
                        //                         each.material_classification !== undefined || each.material_classification !== null || each.material_classification !== ""
                        //                       ))]
                        completedData = completedData.sort((a, b) => {
                            // Convert dates to timestamp for comparison
                            const dateA = new Date(a.recent_learning_completed_on).getTime();
                            const dateB = new Date(b.recent_learning_completed_on).getTime();
                        
                            // Sort in descending order
                            return dateB - dateA;
                        });
                        // console.log(total, "na su rey")
                        let completedCount = 0;
                        let totalCount = 0;
                        let notCompletedCount = 0;
                        if (records.length > 0) {
                            completedCount = getUniqueCount(completedData)
                        }
                        if (yetToLearnRowData.length > 0) {
                            notCompletedCount = getUniqueCount(yetToLearnRowData)
                        }
                        if (totalRowData.length > 0) {
                            totalCount = getUniqueCount(totalRowData)
                        }

                        let newArray = [];
                        let totuniqueObject = {};
                        var totobjTitle;
                        // Loop for the array elements 
                        for (let i in scenarioslist) {

                            // Extract the title 
                            totobjTitle = scenarioslist[i]['value'];

                            // Use the title as the index 
                            totuniqueObject[totobjTitle] = scenarioslist[i];
                        }

                        // Loop to push unique object into array 
                        for (var a in totuniqueObject) {
                            newArray.push(totuniqueObject[a]);
                        }
                        var allscenariosoptions = [{ value: "", label: "All" }]
                        if (newArray.length > 0) {
                            newArray.map((itm) => {
                                if (itm.value !== "" && itm.label !== "" && itm.value !== undefined && itm.label !== undefined) {
                                    //console.log("itm.label ",itm.label)
                                    //console.log("itm.value ",itm.value)
                                    allscenariosoptions.push(itm);
                                }
                            })
                        }
                        await this.setState({
                            rowData: records,
                            allrowData: records,
                            yetToLearnRowData: yetToLearnRowData,
                            totalRowData: totalRowData,
                            completedData: completedData,
                            notCompletedCount, completedCount, totalCount,
                            loadshow: 'show-n',
                            overlayBlock: "show-n",
                            scenarioslist: scenarioslist,
                            materialsRowData: rec,
                            scenario: { "label": "ALL", "value": "ALL" }
                        })
                    })
            })
    }

    getMaterialList = () => {
        redirectURL.post('forecast/getDistinctDataFromMaterialsMaster')
            .then(async (response) => {
                var records = response.data;
                var forecastitemslistarr = [{ value: "all", label: "All" }];
                if (records.length > 0) {
                    records.map((item) => {
                        forecastitemslistarr.push({ value: item, label: item })
                    })
                }
                this.setState({
                    forecastitemslist: forecastitemslistarr,
                })
            })
    }
    closeAlert = () => {
        var redirectChk = this.state.redirectChk;
        this.setState({
            show: false,
            redirectChk: 0,
            duplicateChk: 0,
            uploadxlsdata: []
        });
        if (redirectChk == 1) {
            window.location.href = "/forecast-materials";
        }
    }

    onClickShowUpload = () => {
        this.setState({
            showSlideBlockUpload: "slide30",
            sliderType: 1,
            sliderTitle: "Upload Input File",
            overlayBlock: "show-m"
        })
    }

    hideSlideBlock = () => {
        this.setState({
            overlayBlock: "show-n",
            showSlideBlock: "",
            showSlideBlockUpload: "",
            showSlideBlockRoute: "",
            showSlideBlockStack: "",
            loadFile: '',
            loadshow: 'show-n',
            overly: 'show-n',
            redirectChk: 0,
            duplicateChk: 0,
            uploadxlsdata: [],
            sliderType: 1
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    changeHandler(event) {
        var name = event.target.name;
        var value = event.target.value;
        if (value <= 31) {
            this.setState({
                [name]: value
            })
        }
    }
    onChangeCustomer(customer_code) {
        this.setState({ customer_code })
    }
    onChangeMaterial(material_code) {
        this.setState({ material_code })
    }
    onChangeDuration(duration) {
        this.setState({ duration }, () => {
            if (this.state.duration.value === 0) {
                this.setState({
                    inpShow: "show-m"
                })
            }
            else {
                this.setState({
                    inpShow: "show-n"
                })
            }
        })
    }
    onClickingViewDataBtn = async () => {
        const index = this.state.latestPhases.findIndex((item) => item.label === this.state.latestPhase.label);
        const params = {
            inserted_date: this.state.originalLatestPhases[index]
        }
        if (this.state.latestPhase.value === ""|| this.state.latestPhase.value === undefined){
            window.alert('Please select the date to view the data.');
        }
        else{
        await redirectURL.post('forecast/getActualUploadedDetailedData', params)
            .then(async (response) => {
                console.log(response, "I will see it y")
                this.setState({ actualUploadedDetailedData: response.data })
            })
        this.setState({
            showSlideBlockUpload: "slide45",
            sliderType: 10,
            sliderTitle: `Actual Consumption Upload on - ${this.state.latestPhase.value}`,
        })
    }
    }
    onClickingDeleteDataBtn = async () => {
        if (this.state.latestPhase.value === "" || this.state.latestPhase.value === undefined){
            window.alert('Please Select the date to delete the data.');
        }else{
        this.setState({
            isPopupOpen: true
        })}
    }
    onClickingPopupCancelBtn = () => {
        this.setState({
            isPopupOpen: false
        })
    }
    onClickingPopupDeleteBtn = async () => {
        const index = this.state.latestPhases.findIndex((item) => item.label === this.state.latestPhase.label);
        const params = {
            inserted_date: this.state.originalLatestPhases[index],
            created_date: this.state.latestPhase.value,
            email: localStorage.getItem("email")
        }
        await redirectURL.post("/forecast/toDeleteActualUploadedData", params)
        .then((response) => {
            console.log(response)
            this.setState({
                isPopupOpen: false
            })
            if (response.data.status === "success") {
                this.setState({
                    show: true,
                    basicTitle: "Successfully deleted",
                    basicType: "success",
                })
            }
        })
        .catch((error) => {
            // if (response.data.status === "failed") {
            //     this.setState({
            //         show: true,
            //         basicTitle: "Failed to delete the data",
            //         basicType: "danger",
            //     })
            // }
        })
    }
    handleOptionChange = (e) => {
        this.setState({
            selectedOption: e.target.value,
        });
    };
    onClickingGoBackBtn = () => {
        this.setState({
            showSlideBlockUpload: "slide25",
            sliderType: 1,
            sliderTitle: "Upload Input File",
            overlayBlock: "show-m"
        })
    }
    onChangeLatestPhases = (latestPhase) => {
        this.setState({ latestPhase })
    }
    onSubmitGetData(event) {
        event.preventDefault();
        var material_code = this.state.material_code;
        var materialcodes = [];
        if (material_code.length > 0) {
            // console.log(' (material_code.length', material_code.length)
            if (material_code.length > 1) {
                material_code.map((item) => {
                    if (item !== "" && item !== "ALL") {
                        materialcodes.push(item.value);
                    }

                })
                var index = materialcodes.indexOf("ALL");
                if (index > -1) {
                    materialcodes.splice(index, 1);
                }
            }
            else {
                materialcodes.push("ALL");
            }

        }
        var duration = this.state.duration.value;
        var params = {
            // customer_code:customercodes,
            item_code: materialcodes,
            fc_period: duration
        }
        // console.log("params ", params)
        redirectURL.post("/forecast/predictdata", params)
            .then((response) => {
                var rowdata = response.data;

                // console.log("rowdata ", rowdata)
                this.setState({
                    // rowData:rowdata,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlayBlock: "show-n"
                })


                this.loadPageData()
            })
    }
    onClickTab(oVal) {
        if (oVal == "1") {
            this.setState({
                datagrid: "show-m",
                chartdata: "show-n",
                datagridTab: "activet",
                chartdataTab: "btn-default"
            })
        }
        if (oVal == "2") {
            this.setState({
                datagrid: "show-n",
                chartdata: "show-m",
                datagridTab: "btn-default",
                chartdataTab: "activet"
            })
        }
    }
    onGridReadyYettolearn = (params) => {
        this.gridApiYettolearn = params.api;
        this.gridColumnApiYettolearn = params.columnApi;

    };
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

    };
    onGridReady1 = (params) => {
        this.gridApiNew = params.api;
        this.gridColumnApiNew = params.columnApi;

    };
    onGridReady2 = (params) => {
        this.gridApiNew2 = params.api;
        this.gridColumnApiNew2 = params.columnApi;

    };
    onGridState1 = () => {
        this.colState1 = this.gridColumnApi1.getColumnState();
        this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();
        this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
        window.filterState1 = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState1);
    }
    onGridState2 = () => {
        this.colState2 = this.gridColumnApi2.getColumnState();
        this.rowGroupState2 = this.gridColumnApi2.getRowGroupColumns();
        this.pivotColumns2 = this.gridColumnApi2.getPivotColumns();
        window.filterState2 = this.gridApi2.getFilterModel();
        this.gridApi2.setFilterModel(window.filterState2);
    }
    onBtnExport = () => {
        const timestamp = moment.parseZone(new Date()).format("yyyy-MM-DD");
        const filename = `Forecast_Learning_Completed_${timestamp}`;
        this.gridApi.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcel = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_Learning_Completed_${timestamp}`;
        this.gridApi.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onBtnExportF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_Learning_Analytics_${timestamp}`;
        this.gridApiNew.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcelF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_Learning_Analytics_${timestamp}`;
        this.gridApiNew.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onBtnExportG = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_Material_Year_${timestamp}`;
        this.gridApiNew2.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcelG = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_Material_Year_${timestamp}`;
        this.gridApiNew2.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };

    onBtnExportYettolearn = () => {
        const timestamp = moment.parseZone(new Date()).format("yyyy-MM-DD");
        const filename = `Forecast_Yet_to_Learn_${timestamp}`;
        this.gridApiYettolearn.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcelYettolearn = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_Yet_to_Learn_${timestamp}`;
        this.gridApiYettolearn.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };

    onChangeCustomers(forecastCustomer) {
        this.setState({ forecastCustomer }, () => {
            var sct = this.state.forecastCustomer;
            console.log("sct ", sct)
            if (sct.length > 1) {
                var list = [];
                sct.map((item) => {
                    if (item.value !== "") {
                        list.push(item);
                    }
                })
                this.setState({
                    forecastCustomer: list
                })
            }
            else {
                this.setState({
                    forecastCustomer: this.state.forecastCustomer
                })
            }
        })
    }

    updateMaterialCode = (newValue) => {
        // this.setState({forecastitem:newValue})
        this.onChangeItems(newValue)
    }

    onChangeItems(forecastitem) {
        this.setState({ forecastitem }, () => {
            var selectedItem = this.state.forecastitem.value;
            this.setState({
                forecastCustomer: [{ "value": "", label: "All" }]
            })
            setTimeout(() => {
                var predRecords = this.state.predRecords;
                var selectItemCustomers = [];
                if (predRecords.length > 0) {

                    var customerlist = predRecords.filter((f) => f.item_code === selectedItem);
                    if (customerlist.length > 0) {
                        var customerCodes = groupBy(customerlist, rdata => rdata.customer_code);
                        try {
                            // console.log("customerCodes ", customerCodes)
                            customerCodes.forEach((values, key) => {
                                selectItemCustomers.push({
                                    value: key,
                                    label: key
                                })
                            })
                        } catch (error) {

                        }
                    }
                }
                // console.log("selectItemCustomers ", selectItemCustomers)
                this.setState({
                    forecastcustomerlist: selectItemCustomers
                })
            }, 300)

        })
    }

    onClickForecastGrid() {
        this.setState({
            forecastingCustomersData: [],
            dataShow: "show-m",
            forecastData: 'show-n',
            forecastitem: { "value": "", label: "Select" },
            forecastCustomer: [{ "value": "", label: "All" }],
        })
    }

    onClickShowTrigger = () => {
        this.setState({
            showSlideBlockUpload: "slide45",
            sliderType: 2,
            sliderTitle: "Trigger Learning",
            overlayBlock: "show-m"
        })
    }

    viewLearningPredictionData = async (row) => {
        await redirectURL.post("/forecast/getMaterialRecentLearningAnalytics", {
            item_code: row.data.item_code,
        }).then(async (response) => {
            if (response.data.length > 0) {
                var test_data_predictions = response.data[0].test_data_predictions;
                var learningData = [];
                if (test_data_predictions != undefined && test_data_predictions != "") {
                    learningData = JSON.parse(test_data_predictions);
                    learningData.map(item => item.error = Math.round(item.error * 10000) / 100);
                }
                this.setState({
                    showSlideBlockUpload: "slide45",
                    sliderType: 3,
                    sliderTitle: "Learning Analytics - " + row.data.item_code,
                    overlayBlock: "show-m",
                    learningData: learningData
                })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    showActualMaterialConsumptionData = async (row) => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',      
            overlay: 'show-m',      
            overlayBlock: "show-m"
        })
        await redirectURL.post("/forecast/getMaterialConsumptionData", {
            item_code: row.data.item_code,
        }).then(async (response) => {
            // if (response.data.length > 0) {
            //     var test_data_predictions = response.data[0].test_data_predictions;
            //     var learningData = [];
            //     if (test_data_predictions != undefined && test_data_predictions != "") {
            //         learningData = JSON.parse(test_data_predictions);
            //         learningData.map(item => item.error = Math.round(item.error * 10000) / 100);
            //     }
            //     this.setState({
            //         showSlideBlockUpload: "slide45",
            //         sliderType: 11,
            //         sliderTitle: "Actual Material Consumption Data - " + row.data.item_code,
            //         overlayBlock: "show-m",
            //         learningData: learningData
            //     })
            // }
            // console.log(response, "880")


            var fy_data = []
            var plot_data = []
            var year_data = []
            if (response.data.fy_data){
                fy_data = JSON.parse(response.data.fy_data)
            }
            if (response.data.plot_data){
                plot_data = JSON.parse(response.data.plot_data)
            }
            if (response.data.year_data){
                year_data = JSON.parse(response.data.year_data)
            }

            this.setState({
                loadshow: 'show-n',
                overly: 'show-n',
                overlay: "show-n",
                // overlayBlock: "show-n",
                showSlideBlockUpload: "slide60",
                sliderType: 11,
                sliderTitle: "Actual Material Consumption Data - " + row.data.item_code,
                overlayBlock: "show-m",
                fyData: fy_data,
                plotData: plot_data,
                yearData: year_data,
            })

        }).catch(function (error) {
            console.log(error);
        })
    }

    changeFileHandler = async (e) => {
        // console.log(e);
        this.setState({
            loadFile: e.target.files[0]
        });

        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if (check == true) {
            if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))

            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }

    }

    uploadBulkFormHandler(event) {
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        saveData.append("file", this.state.loadFile);

        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        });
        redirectURL.post("/forecast/readXLSData", saveData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                'Access-Control-Allow-Headers': 'Origin, Content-Type'
            }
        }).then((response) => {
            if (response.data.records !== undefined) {
                var uploadxlsdata = response.data.records;
                console.log("uploadxlsdata tyu", uploadxlsdata)

                const requiredProperties = ["Material Code", "Posting Date", "Quantity"];

                var missingProperties = []
                const allObjectsHaveProperties = uploadxlsdata.every((record, index) => {
                    const missingProps = requiredProperties.filter(property => !record.hasOwnProperty(property));

                    if (missingProps.length > 0) {
                        missingProperties.push({ index, missingProps });
                    }

                    return missingProps.length === 0;
                });
                console.log(missingProperties, "missingProperties")
                // var missingProperties = []
                // const allObjectsHaveProperties = uploadxlsdata.every((record, index) => {
                //     const missingProps = requiredProperties.filter(property => !record.hasOwnProperty(property));
                
                //     if (missingProps.length > 0) {
                //         missingProperties.push({ index, missingProps });
                //     }
                
                //     return missingProps.length === 0;
                // });
                // console.log(uploadxlsdata, "uploadxlsdata")
                if (uploadxlsdata.length > 0 && allObjectsHaveProperties) {
                    var email = localStorage.getItem("email")
                    var p = {
                        xlsdata: uploadxlsdata,
                        over_ride: 0,
                        email: email
                    }
                    console.log("p ", p)
                    redirectURL.post("/forecast/savefyinputdatanew", p)
                        .then((resp) => {
                            console.log(resp.data, "respt")
                            if (resp.data.status == 1 || resp.data.status == "1") {
                                const {addUserActivityDetails} = this.context
                                addUserActivityDetails({screen : "Material Consumption", sub_screen: "-", action:"Upload", activity: "Uploaded Actual Material Consumption"})
                                this.setState({
                                    // uploadfile:"",
                                    // showSlideBlockUpload:"",
                                    // overlayBlock:"show-n",
                                    overly: "show-n",
                                    loadshow: "show-n",
                                    // show:true,
                                    // basicTitle:resp.data.message,
                                    // basicType:"warning",
                                    showloader: "show-n",
                                    duplicateChk: 1,
                                    uploadxlsdata: uploadxlsdata
                                });
                            }
                            else if (resp.data.status == "success") {
                                var respMessage = "Success";
                                var redirectChk = 0;
                                var basicType = "success";
                                if (resp.data.new_material_list != undefined && resp.data.new_material_list != "") {
                                    var new_material_list = JSON.parse(resp.data.new_material_list);
                                    if (new_material_list.length > 0) {
                                        respMessage = resp.data.message + " " + new_material_list.join(",");
                                        redirectChk = 1;
                                        basicType = "warning";
                                    }
                                }
                                this.setState({
                                    uploadfile: "",
                                    showSlideBlockUpload: "",
                                    overlayBlock: "show-n",
                                    overly: "show-n",
                                    loadshow: "show-n",
                                    show: true,
                                    basicTitle: respMessage,
                                    basicType: basicType,
                                    showloader: "show-n",
                                    redirectChk: redirectChk
                                });
                            } else if (resp.data.status == 'failure') {
                                this.setState({
                                    show: true,
                                    basicTitle: resp.data.message,
                                    basicType: "danger",
                                    showloader: "show-n",
                                    overly: "show-n",
                                    loadshow: "show-n",
                                });
                            }
                            else {
                                this.setState({
                                    show: true,
                                    basicTitle: "Failed to upload data",
                                    basicType: "danger",
                                    showloader: "show-n",
                                    overly: "show-n",
                                    loadshow: "show-n",
                                });
                            }
                        })
                } else {
                    this.setState({
                        basicTitle: `In your Excel file, at line ${missingProperties[0].index + 2}, the ${missingProperties[0].missingProps} column is empty, which is required for processing.`,
                        basicType: "warning",
                        show: true,
                        loadshow: 'show-n',
                        showloader: "show-n",
                        overly: 'show-n',
                    })
                }
            } else {
                this.setState({
                    basicTitle: "Please upload the file with Data",
                    basicType: "warning",
                    show: true,
                    loadshow: 'show-n',
                    overly: 'show-n',
                })
            }
        });
    };
    overRideExistingData = () => {
        var duplicateChk = this.state.duplicateChk;
        if (duplicateChk == 1) {
            var uploadxlsdata = this.state.uploadxlsdata;
            if (uploadxlsdata.length > 0) {
                var p = {
                    xlsdata: uploadxlsdata,
                    over_ride: 1
                }
                // console.log("p ", p)
                redirectURL.post("/forecast/savefyinputdatanew", p)
                    .then((resp) => {
                        //   console.log(resp.data)
                        if (resp.data.status == 1 || resp.data.status == "1") { 
                            const {addUserActivityDetails} = this.context
                            addUserActivityDetails({screen : "Material Consumption", sub_screen: "-", action:"Upload", activity: "Uploaded Actual Material Consumption"})
                            this.setState({
                                // uploadfile:"",
                                // showSlideBlockUpload:"",
                                // overlayBlock:"show-n",
                                overly: "show-n",
                                loadshow: "show-n",
                                // show:true,
                                // basicTitle:resp.data.message,
                                // basicType:"warning",
                                showloader: "show-n",
                                duplicateChk: 1,
                                uploadxlsdata: uploadxlsdata
                            });
                        }
                        else if (resp.data.status == 2 || resp.data.status == "2") {
                            var respMessage = "Success";
                            var redirectChk = 0;
                            var basicType = "success";
                            if (resp.data.new_material_list != undefined && resp.data.new_material_list != "") {
                                var new_material_list = JSON.parse(resp.data.new_material_list);
                                if (new_material_list.length > 0) {
                                    respMessage = resp.data.message + " " + new_material_list.join(",");
                                    redirectChk = 1;
                                    basicType = "warning";
                                }
                            }
                            this.setState({
                                uploadfile: "",
                                showSlideBlockUpload: "",
                                overlayBlock: "show-n",
                                overly: "show-n",
                                loadshow: "show-n",
                                show: true,
                                basicTitle: respMessage,
                                basicType: basicType,
                                showloader: "show-n",
                                redirectChk: redirectChk,
                                duplicateChk: 0
                            });
                        } else if (resp.data.status == 'failure') {
                            this.setState({
                                show: true,
                                basicTitle: resp.data.message,
                                basicType: "danger",
                                showloader: "show-n",
                                overly: "show-n",
                                loadshow: "show-n",
                                duplicateChk: 0
                            });
                        }
                        else {
                            this.setState({
                                show: true,
                                basicTitle: "Failed to upload data",
                                basicType: "danger",
                                showloader: "show-n",
                                overly: "show-n",
                                loadshow: "show-n",
                                duplicateChk: 0
                            });
                        }
                    })
            }
        }
    }
    showMaterialConsumptionData = async (row) => {
        this.setState({ detailCellRendererParams: {} });
        var consumptioncolsDefs = [
            {
                headerName: "Year",
                field: "year",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Quantity",
                field: "quantity",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Details",
                field: "view_consumption_details",
                width: 150,
                cellRenderer: function (params) {
                    return <StaticButton />
                },
                headerClass: ["column-header-style"]
            },
        ]
        await this.setState({
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: consumptioncolsDefs,
                    overlayNoRowsTemplate: 'No rows to show',
                    onCellClicked: this.viewConsumptionDetails,
                    height: 200,
                },
                getDetailRowData: async function (param) {
                    param.successCallback([]);
                    await redirectURL.post("/forecast/getMaterialConsumptionData", {
                        item_code: row.data.item_code,
                        data_type: 1
                    }).then(async (response) => {
                        param.successCallback(response.data);
                    }).catch(function (error) {
                        console.log(error);
                    })
                },
                masterDetail: true
            }
        });
        if (row.colDef.field == "viewmaterialconsumption") {
            row.node.setExpanded(!row.node.expanded);
        }
        else {
            row.node.setExpanded(false);
        }
    }
    viewConsumptionDetails = async (row) => {
        // console.log(row,"viewConsumptionDetails")
        if (row.colDef.field == "view_consumption_details") {
            await redirectURL.post("/forecast/getMaterialConsumptionData", {
                item_code: row.data.item_code,
                year: row.data.year,
                data_type: 2
            }).then(async (response) => {

                var records = response.data;
                var recordsarr = [];
                var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                if (records.length > 0) {
                    records.map((item, ind) => {
                        var keys = Object.keys(item);
                        // console.log("keys", keys);
                        if (keys.length > 0) {
                            for (var a = 0; a < keys.length; a++) {

                                // console.log("item[keys[a] ",item[keys[a]])
                                if (keys[a] === "item_code") {
                                    if (item[keys[a]] !== undefined) {

                                        recordsarr.unshift({
                                            // "col_name":"item_code",
                                            "col_item_code": item[keys[a]]
                                        })
                                    }
                                }
                                else {
                                    if (months[parseInt(a)] !== undefined) {
                                        recordsarr.push({
                                            "col_name": months[parseInt(a)],
                                            "col_value": item[keys[a]]
                                        })
                                    }
                                }
                            }
                        }
                    })
                }
                this.setState({
                    showSlideBlockUpload: "slide45",
                    sliderType: 4,
                    sliderTitle: "Material - " + row.data.item_code + "   " + "    Year - " + row.data.year,
                    overlayBlock: "show-m",
                    consumptionData: response.data,
                    verticalColumn: recordsarr
                })
            }).catch(function (error) {
                console.log(error);
            })
        }
    }
    showMaterialHistoryData = async (row) => {
        this.setState({ detailCellRendererParams: {} });
        var historycolsDefs = [
            {
                headerName: "Date",
                field: "learning_completed_on",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: function (params) {
                    try {
                        if (params.data.learning_completed_on !== "" && params.data.learning_completed_on !== undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.learning_completed_on);
                        }
                    } catch (error) {

                    }
                }
            },

            {
                headerName: "Learning Analytics",
                field: "viewlearingdata",
                width: 180,
                cellRenderer: function (params) {
                    return <StaticButton />
                },
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Data Visualization",
                field: "datavisualization",
                width: 180,
                cellRenderer: function (params) {
                    return <StaticButton />
                },
                headerClass: ["column-header-style"]
            }
        ]
        await this.setState({
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: historycolsDefs,
                    overlayNoRowsTemplate: 'No rows to show',
                    onCellClicked: this.childGridClickFunction,
                    height: 200,
                },
                getDetailRowData: async function (param) {
                    param.successCallback([]);
                    await redirectURL.post("/forecast/getMaterialLearningHistory", {
                        item_code: row.data.item_code
                    }).then(async (response) => {
                        param.successCallback(response.data);
                    }).catch(function (error) {
                        console.log(error);
                    })
                },
                masterDetail: true
            }
        });
        if (row.colDef.field == "learninghistory") {
            row.node.setExpanded(!row.node.expanded);
        }
        else {
            row.node.setExpanded(false);
        }
    }


    childGridClickFunction = async (row) => {
        if (row.colDef.field == "viewlearingdata") {
            await redirectURL.post("/forecast/getMaterialRecentLearningAnalytics", {
                item_code: row.data.item_code,
                transaction_id: row.data.transaction_id,
            }).then(async (response) => {
                if (response.data.length > 0) {
                    var test_data_predictions = response.data[0].test_data_predictions;
                    var learningData = [];
                    if (test_data_predictions != undefined && test_data_predictions != "") {
                        learningData = JSON.parse(test_data_predictions);
                        learningData.map(item => item.error = Math.round(item.error * 10000) / 100);
                    }
                    this.setState({
                        showSlideBlockUpload: "slide45",
                        sliderType: 3,
                        sliderTitle: "Learning Analytics - " + row.data.item_code,
                        overlayBlock: "show-m",
                        learningData: learningData
                    })
                }
            }).catch(function (error) {
                console.log(error);
            })
        }
        if (row.colDef.field == "datavisualization") {
            await redirectURL.post("/forecast/getMaterialRecentLearningAnalytics", {
                item_code: row.data.item_code,
                transaction_id: row.data.transaction_id,
            }).then(async (response) => {
                if (response.data.length > 0) {
                    var chart_data = response.data[0].chart_data;
                    var chartData = [];
                    var areaplot = [];
                    var areaYHat = [];
                    var areaScatter = [];
                    var trendplot = [];
                    var trendingCategories = [];
                    var trendingcost = [];
                    if (chart_data != undefined && chart_data != "") {
                        chartData = JSON.parse(chart_data);
                        var groupByYear = []
                        if (chartData.length > 0) {
                            groupByYear = groupBy(chartData, rdata => rdata.year);
                            await groupByYear.forEach(async (values, key) => {
                                var area_plot = 0;
                                var yhat = 0;
                                var actual = 0;
                                var cost = 0;
                                await values.map(async (mt) => {
                                    area_plot = area_plot + mt.area_plot
                                    if (mt.yhat < 0) {
                                        yhat = yhat + 0;
                                    }
                                    else {
                                        yhat = yhat + mt.yhat
                                    }
                                    if (mt.actual !== "" && mt.actual !== undefined && mt.actual !== null) {
                                        actual = actual + mt.actual
                                    }
                                    if (mt.cost !== "" && mt.cost !== undefined && mt.cost !== null) {
                                        cost = cost + mt.cost
                                    }
                                })
                                trendingCategories.push(key);
                                areaplot.push(area_plot);
                                areaYHat.push(yhat);
                                areaScatter.push(actual);
                                trendingcost.push(cost);
                            })
                        }
                    }
                    // console.log(trendingcost,"cost-1-1")
                    this.setState({
                        showSlideBlockUpload: "slide45",
                        sliderType: 5,
                        sliderTitle: "Data Visualization",
                        overlayBlock: "show-m",
                        confAreaData: areaplot,
                        confAreaYHat: areaYHat,
                        confScatter: areaScatter,
                        chartMaterial: row.data.item_code,
                        trendingCategories: trendingCategories,
                        trendingcost: trendingcost,
                    })
                }
            }).catch(function (error) {
                console.log(error);
            })
        }
    }

    handleShowDataTab = (tab) => {

        if (tab == 'learningTab') {
            this.setState({
                learningComponent: true,
                yettolearnComponent: false,
                totalComponent: false
            })
        } else if (tab == 'totalTab') {
            this.setState({
                learningComponent: false,
                yettolearnComponent: false,
                totalComponent: true
            })

        }
        else {
            this.setState({
                learningComponent: false,
                yettolearnComponent: true,
                totalComponent: false
            })
        }


    }

    onClickExport = () => {
        this.setState({
            showSlideBlockUpload: "slide25",
            sliderType: 6,
            sliderTitle: "Export Table Data Using Below Options",
            overlayBlock: "show-m",
        })
    }
    onChangeScenario = async (scenario) => {
        await this.setState({
            loadshow: "show-m",
            overlayBlock: "show-m"
        })
        await this.setState({ scenario }, async () => {
            //console.log("this.state.scenario.value" , this.state.scenario.value)

            var rowDatas = this.state.allrowData;
            var learningCompletedMaterials = rowDatas;
            console.log(rowDatas, this.state.materialsRowData, scenario)
            var totalRowData = 0;
            if (this.state.scenario.value === "") {

                let completedCount = 0;
                let totalCount = 0;
                let notCompletedCount = 0;

                //console.log("this.state.allrowData" , this.state.allrowData)
                var rec = this.state.materialsRowData;
                if (rec.length > 0) {
                    // const yetToLearnRowData = rec.filter((element) => {
                    //     return !learningCompletedMaterials.some((object) => object.item_code === element.item_code);
                    // }).map(matCode => {
                    //     return {
                    //         item_code: matCode.item_code,
                    //         item_description: matCode.item_description,
                    //         learning_completed: matCode.learning_completed
                    //     };
                    // });
                    //need to add learn
                    const yetToLearnRowData = rec
                        .filter(matCode => !learningCompletedMaterials.some(object => object.item_code === matCode.item_code))
                        .map(matCode => ({
                            item_code: matCode.item_code,
                            item_description: matCode.item_description,
                            learning_completed: matCode.learning_completed
                        }));

                    totalRowData = learningCompletedMaterials.concat(yetToLearnRowData)


                    if (rowDatas.length > 0) {
                        completedCount = getUniqueCount(rowDatas)
                    }
                    if (yetToLearnRowData.length > 0) {
                        notCompletedCount = getUniqueCount(yetToLearnRowData)
                    }
                    if (totalRowData.length > 0) {
                        totalCount = getUniqueCount(totalRowData)
                    }
                }
                this.setState({
                    rowData: this.state.allrowData,
                    loadshow: "show-n",
                    completedCount: completedCount,
                    notCompletedCount: notCompletedCount
                })
            }
            else {
                if (rowDatas.length > 0) {
                    var bestModelsData = []
                    await redirectURL.post("forecast/getDataFromBestModels", { scenario: this.state.scenario.value })
                        .then((response) => {
                            bestModelsData = response.data
                            console.log(bestModelsData, "bestModelsData")
                        }).catch(function (error) {
                            console.log(error);
                        })
                    var listarr = [];

                    listarr = rowDatas.filter(rowItem => {
                        return bestModelsData.some(bestItem => bestItem === rowItem.item_code);
                    });

                    listarr = listarr.map((each) => ({ ...each, scenario_type: this.state.scenario.value }))
                    console.log(listarr, "listarr")
                    var rec = this.state.materialsRowData;

                    var completedCount = 0;
                    var totalCount = 0;
                    var notCompletedCount = 0;
                    if (rec.length > 0) {
                        // const yetToLearnRowData = listarr.filter((element) => {
                        //     return !learningCompletedMaterials.some((object) => object.item_code === element.item_code);
                        // }).map(matCode => {
                        //     return {
                        //         item_code: matCode.item_code,
                        //         item_description: matCode.item_description,
                        //         learning_completed: matCode.learning_completed,
                        //     };
                        // });
                        //need to add learn

                        // totalRowData = learningCompletedMaterials.concat(yetToLearnRowData)
                        console.log(listarr, "no where i")
                        var yetToLearnRowData = listarr.filter((each) => (each.learning_status == 1))
                        var completedData = listarr.filter((each) => (each.learning_status == 2))
                        totalRowData = completedData.concat(yetToLearnRowData)
                        console.log(yetToLearnRowData, completedData, totalRowData, "u yu")
                        if (completedData) {
                            completedCount = getUniqueCount(completedData)
                        }
                        if (yetToLearnRowData.length > 0) {
                            notCompletedCount = getUniqueCount(yetToLearnRowData)
                        }
                        if (listarr.length > 0) {
                            totalCount = getUniqueCount(listarr)
                        }
                    }
                    //console.log("completedCount", completedCount)
                    //console.log("notCompletedCount", notCompletedCount)
                    this.setState({
                        rowData: listarr,
                        completedData: completedData,
                        yetToLearnRowData: yetToLearnRowData,
                        loadshow: "show-n",
                        overlayBlock: "show-n",
                        totalCount: totalCount,
                        completedCount: completedCount,
                        notCompletedCount: notCompletedCount
                    })

                }

            }
        })
    }
    render() {

        var actualUploadedDetailedDataColumnDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Posting Date",
                field: "posting_date",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: function (params) {
                    try {
                        if (params.data.posting_date !== "" && params.data.posting_date !== undefined) {
                            const dateObject = new Date(params.data.posting_date);

                            // Get the date part in 'YYYY-MM-DD' format
                            const datePart = dateObject.toISOString().split('T')[0];
                            return datePart
                        }
                        else {
                            return "Pending";
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "Quantity",
                field: "quantity",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            }
        ]

        var yetToLearnColumnDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            }, {
                headerName: "Material Description",
                field: "item_description",
                width: 260,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Scenario Name",
                field: "scenario_type",
                width: 260,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Status",
                field: "learning_completed",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: function (params) {
                    try {
                        if (params.data.learning_status !== "" && params.data.learning_status !== undefined && params.data.learning_status === 2) {
                            return "Up to Date";
                        }
                        else {
                            return "Pending";
                        }
                    } catch (error) {

                    }
                }
            },

        ]

        var verticalColumnDefs = [
            {
                headerName: "Material Code",
                field: "col_item_code",
                width: 150,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Months",
                field: "col_name",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Quantity",
                field: "col_value",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },

        ]
        var columnwithDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Description",
                field: "item_description",
                width: 260,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Scenario Name",
                field: "scenario_type",
                width: 260,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            // {
            //     headerName: "Last Learning On",
            //     field: "recent_learning_completed_on",
            //     width:180,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         try {
            //             if(params.data.recent_learning_completed_on !== "" && params.data.recent_learning_completed_on !== undefined)
            //             {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.recent_learning_completed_on);
            //             }
            //             else{
            //                 return "Processing";
            //             }
            //         } catch (error) {

            //         }
            //     }
            // },
            // {
            //   headerName: "Status",
            //   field: "learning_completed",
            //   width:180,
            //   filter: true,
            //    resizable: true,
            //    editable:false,
            //    valueGetter:function(params){
            //        try {
            //            if(params.data.learning_completed !== "" && params.data.learning_completed !== undefined && params.data.learning_completed !== 0)
            //            {
            //                return "Up to Date";
            //            }
            //            else{
            //                return "Pending";
            //            }
            //        } catch (error) {
            //            
            //        }
            //  }
            //},
            {
                headerName: "Learning Status",
                field: "learning_status",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: function (params) {
                    try {
                        if (params.data.learning_status !== "" && params.data.learning_status !== undefined && params.data.learning_status !== 1) {
                            return "Completed";
                        }
                        else {
                            return "Processing";
                        }
                    } catch (error) {

                    }
                }
            },
            // {
            //     headerName: "Recent Best Model",
            //     field: "best_model",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "Learning History",
                field: "learninghistory",
                width: 180,
                params: { buttonName: 'View History', onClickFunction: this.showMaterialHistoryData, iconName: "fa fa-eye", btnClass: "btn btn-success" },
                headerClass: ["column-header-style"],
                cellRendererSelector: function (params) {

                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent

                },
            },
            {
                headerName: "Recent Learning Analytics",
                field: "viewdata",
                width: 180,
                params: {
                    buttonName: 'Edit',
                    onClickFunction: this.viewLearningPredictionData
                },
                headerClass: ["column-header-style"],
                cellRendererSelector: function (params) {

                    var rendComponent = {
                        component: "ViewLearningData"
                    };
                    return rendComponent

                },
            },
            {
                headerName: "Actual Material Consumption",
                field: "viewmaterialconsumption",
                width: 200,
                params: { buttonName: 'View Data', onClickFunction: this.showActualMaterialConsumptionData, iconName: "fa fa-eye", btnClass: "btn btn-info" },
                headerClass: ["column-header-style"],
                cellRendererSelector: function (params) {

                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent

                },
            }
        ]

        var columnDefsForMaterialConsumption = [
            {
                headerName: "Financial Year",
                field: "financial_year",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "April",
                field: "Apr",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "May",
                field: "May",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "June",
                field: "Jun",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "July",
                field: "Jul",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "August",
                field: "Aug",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "September",
                field: "Sep",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "October",
                field: "Oct",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "November",
                field: "Nov",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "December",
                field: "Dec",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "January",
                field: "Jan",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "February",
                field: "Feb",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "March",
                field: "Mar",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Total Quantity", // New column for total quantity
                field: "total_quantity",       // This should match the 'total_quantity' field in your data
                width: 200,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            }
        ];

        var columnDefsForMaterialConsumption1 = [
            {
                headerName: "Year",
                field: "year",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "January",
                field: "Jan",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "February",
                field: "Feb",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "March",
                field: "Mar",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "April",
                field: "Apr",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "May",
                field: "May",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "June",
                field: "Jun",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "July",
                field: "Jul",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "August",
                field: "Aug",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "September",
                field: "Sep",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "October",
                field: "Oct",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "November",
                field: "Nov",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "December",
                field: "Dec",
                width: 100,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Total Quantity",
                field: "total_quantity",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            }
        ];
        

        
        var columnwithDefs1 = [
            {
                headerName: "Date",
                field: "date",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: function (params) {
                    try {
                        if (params.data.date !== "" && params.data.date !== undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.date);
                        }
                        else {
                            return "";
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "Actual",
                field: "actual",
                width: 110,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Predicted",
                field: "predicted",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Error (%)",
                field: "error",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
        ];
        var consumptioncolsDefs = [
            {
                headerName: "Item Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false
            },
        ]
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        for (var c = 0; c <= 11; c++) {
            consumptioncolsDefs.push(
                {
                    headerName: months[c],
                    field: (c + 1).toString(),
                    width: 150,
                    filter: true,
                    resizable: true,
                    editable: false
                },
            );
        }
        var templatePath = require('../../assets/json/materials_consumption_input_data_template.csv');
        const customStyles = {
            control: (provided) => ({
                ...provided,
                width: '170%', // Adjust the width as needed for the control
            }),
            menu: (provided) => ({
                ...provided,
                width: '170%', // Adjust the width as needed for the dropdown menu
            }),
            // Add more style overrides as needed

            option: (provided, state) => ({
                ...provided,
                fontWeight: 'bold',
                fontSize: "14px",
                color: "black" // Set font-weight to bold for selected options
            }),
            singleValue: (provided, state) => ({
                ...provided,
                fontSize: "14px",
                fontWeight: 'bold', // Always display the selected option in bold
                color: "black"
            }),

        };
        const newCustomStyles = {
            option: (provided, state) => ({
                ...provided,
                fontWeight: 'bold',
                fontSize: "14px",
                color: "black" // Set font-weight to bold for selected options
            }),
            singleValue: (provided, state) => ({
                ...provided,
                fontSize: "14px",
                fontWeight: 'bold', // Always display the selected option in bold
                color: "black"
            }),

        };
        // console.log(this.state.rowData, "renderrowdata", this.state.verticalColumn, "hty");
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-5 p-0p">
                        <h4 className='fbold' style={{ color: "black" }}>Material Consumption</h4>
                    </div>
                </div>
                <div class="row mb-20p">
                    <div className="col-2 p-0p">
                        <div className="form-group f12">
                            <label className="col-form-label f20 fbold" style={{ color: "black" }}>Material</label>
                            {/* <Select
                                        placeholder={"Select"}
                                        onChange={this.onChangeItems.bind(this)}
                                        name="forecastitem"
                                        value={this.state.forecastitem}
                                        options={this.state.forecastitemslist}
                                    /> */}
                            <MaterialList options={this.state.forecastitemslist} updateMaterialCode={this.updateMaterialCode}></MaterialList>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group ">
                            <button type="button" onClick={this.loadPageData.bind(this)} className="btn btn-success" style={{ marginTop: "45px" }}>Apply</button>
                        </div>
                    </div>
                    <div className="col-md-8">
                    {localStorage.getItem("role") == "admin" && (<button type="button" className="btn btn-info float-right font-white" onClick={this.onClickShowUpload}>Upload Actual Material Consumption</button>)}
                    {localStorage.getItem("role") == "admin" && (<button type="button" className="btn btn-success float-right font-white" onClick={this.onClickShowTrigger}>Trigger Learning</button>)}
                    </div>

                </div>
                <div className={"row mb-20p"}>
                    <div className={"mxheit col-sm-12"} >
                        <div className="row">
                            {/*<div className="col-md-2">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Material</label>
                                    
                                    <MaterialList options={this.state.forecastitemslist} updateMaterialCode={this.updateMaterialCode}></MaterialList>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group ">
                                    <button type="button" onClick={this.loadPageData.bind(this)} className="btn btn-success mt-30p">Get Data</button>
                                </div>
                            </div>
						*/}



                            <div className='col-sm-12 mb-10p p-0p'>

                                <div className={"col-md-4 bx-sh " + (this.state.totalComponent ? " border-color " : "")} onClick={this.handleShowDataTab.bind(this, "totalTab")}>
                                    <label className={"f17 cursorPointer fw-600 " + (this.state.totalComponent ? " num-color " : 'counter-header-color')}>Total Materials</label>
                                    <div className="f28">
                                        <span className={"fbold cursorPointer " + (this.state.totalComponent ? " num-color " : 'counter-header-color')}>{this.state.totalCount}</span>
                                    </div>
                                </div>
                                <div className={"col-md-4 bx-sh " + (this.state.learningComponent ? " border-color " : "")} onClick={this.handleShowDataTab.bind(this, "learningTab")}>
                                    <label className={"f17 cursorPointer fw-600 " + (this.state.learningComponent ? " num-color " : 'counter-header-color')}>Learning Completed Materials</label>
                                    <div className="f28">
                                        <span className={"fbold cursorPointer " + (this.state.learningComponent ? " num-color " : 'counter-header-color')}>{this.state.completedCount}</span>
                                    </div>
                                </div>
                                <div className={"col-md-4 bx-sh " + (this.state.yettolearnComponent ? " border-color " : "")} onClick={this.handleShowDataTab.bind(this, "yetToLearn")}>
                                    <label className={"f17 cursorPointer fw-600 " + (this.state.yettolearnComponent ? " num-color " : 'counter-header-color')}>Yet To Learn Materials</label>
                                    <div className="f28">
                                        <span className={"fbold cursorPointer " + (this.state.yettolearnComponent ? " num-color " : 'counter-header-color')}>{this.state.notCompletedCount}</span>
                                    </div>
                                </div>
                            </div>

                            {/* <div className={"col-md-2 "} onClick={this.handleShowDataTab.bind(this,"learningTab")}>
                                <label className={"f13 cursorPointer fw-600 "+ (this.state.learningComponent ? " num-color ": '')}>Learning Completed</label>
                                <div className="f28">
                                    <span className={"fbold cursorPointer " + (this.state.learningComponent ? " num-color ": '')}>{this.state.rowData.length}</span>
                                </div>    
                            </div>
                            <div className={"col-md-2"} onClick={this.handleShowDataTab.bind(this,"yetToLearn")}>
                                <label className={"f13 cursorPointer fw-600 " + (this.state.yettolearnComponent ? " num-color ": '')}>Yet To Learn</label>
                                <div className="f28">
                                    <span className={"fbold cursorPointer " + (this.state.yettolearnComponent ? " num-color ": '')}>{this.state.yetToLearnRowData.length}</span>
                                </div>    
                            </div> */}

                        </div>
                        <div className="col-sm-12 mt-20p">
                            <div className="card-header row">
                                <div className="col-sm-5">
                                    <h4 className='fbold' style={{ width: "30%" }}></h4>
                                </div>

                                <div className="col-md-5" style={{ width: "100%" }}>
                                    <div className="form-group col-sm-8" style={{ width: "159px" }}>&nbsp;</div>
                                    <div className="form-group f17 col-sm-4 fw-600" style={{ textAlign: "right", marginTop: "10px", width: "250px", color: "black" }}>Select Scenario</div>
                                    <div className="form-group col-sm-3">
                                        <Select
                                            placeholder={"Select"}
                                            onChange={this.onChangeScenario.bind(this)}
                                            name="scenario"
                                            value={this.state.scenario}
                                            options={this.state.scenarioslist}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>

                                {this.state.learningComponent || this.state.yettolearnComponent ?
                                    <div className="col-sm-2" style={{ width: "10%" }}>
                                        <button type="button" className="btn btn-info float-right" onClick={this.onClickExport}>Download Data</button>
                                    </div>
                                    : ''}
                            </div>
                        </div>


                        {this.state.learningComponent ?
                            <>
                                {/* <div className="row">
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-info float-right" onClick={this.onBtnExport.bind(this)}>Export to CSV</button>
                            <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcel.bind(this)}>Export to Excel</button>
                        </div>
                        </div> */}
                                <div id="myGrid1" style={{ width: "100%", height: "70vh", fontSize: "13px", fontWeight: "500", padding: "0px" }} className={"ag-theme-balham p-0p"}>
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.completedData}
                                        enableCharts={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this },
                                            rowClass: 'your-row-class', // Set the row class for styling
                                        }}
                                        // components={this.state.components}
                                        // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                        enableRangeSelection={true}
                                        masterDetail={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        suppressRowHoverHighlight={true}

                                    // modules={[MenuModule, ExcelExportModule]}

                                    />
                                </div>
                            </>
                            : ''}
                        {this.state.yettolearnComponent ?
                            <>
                                <div className="row">
                                    {/* <div className="col-sm-12">
                            <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportYettolearn.bind(this)}>Export to CSV</button>
                            <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelYettolearn.bind(this)}>Export to Excel</button>
                        </div> */}
                                </div>
                                <div id="myGrid1" style={{ width: "100%", height: "70vh", fontSize: "13px", fontWeight: "500", padding: "0px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        columnDefs={yetToLearnColumnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.yetToLearnRowData}
                                        onGridReady={this.onGridReadyYettolearn}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}

                                        enableRangeSelection={true}
                                        masterDetail={true}
                                        modules={[MenuModule, ExcelExportModule]}
                                    />
                                </div>
                            </> : ''}

                        {this.state.totalComponent ?
                            <>
                                {/* <div className="row">
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportTotal.bind(this)}>Export to CSV</button>
                            <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelTotal.bind(this)}>Export to Excel</button>
                        </div>
                        </div> */}
                                <div id="myGrid1" style={{ width: "100%", height: "70vh", fontSize: "13px", fontWeight: "500", padding: "0px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        columnDefs={yetToLearnColumnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onGridReady={this.onGridReadyYettolearn}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}

                                        enableRangeSelection={true}
                                        masterDetail={true}
                                    // modules={[MenuModule, ExcelExportModule]}
                                    />
                                </div>
                            </> : ''}
                    </div>
                </div>

                <div className={"overlay-block " + (this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 " + (this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                    <div className="row">
                        {(this.state.sliderType == 1) ?
                            <div className="col-sm-12">
                                {this.state.processErrShow == 0 ?
                                    <form onSubmit={this.uploadBulkFormHandler.bind(this)}>
                                        <div className="form-group col-sm-12">
                                            <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>
                                        </div>
                                        <div className="row p-20p">
                                            {/* <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Select FY</label> 
                                        <Select
                                            value={this.state.fy}
                                            options={this.fyitems()}
                                            onChange={this.onChangeFY.bind(this)}
                                        />
                                    </div>  */}
                                            {(this.state.duplicateChk == 1) ?
                                                <div className="form-group col-sm-12 mb-20p">
                                                    <h5>Data Uploaded has existing information, Do you wish to proceed and overwrite existing data</h5>
                                                </div>
                                                :
                                                <div className="form-group col-sm-12 mb-20p">
                                                    <label className="fw-600 f15" style={{ color: "black" }}>Upload File</label>
                                                    <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
                                                </div>
                                            }
                                            <div className="form-group col-sm-12 mb-20p">
                                                {(this.state.duplicateChk == 1) ?
                                                    <button type="button" className="btn btn-secondary" onClick={this.overRideExistingData.bind(this)}>PROCEED</button>
                                                    :
                                                    <button type="submit" className="btn btn-info">SAVE</button>
                                                }
                                                <button type="button" className="btn btn-success" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                            </div>
                                        </div>
                                        <div className="row p-10p">

                                            <div className="col-6 d-flex">
                                                <input
                                                    type="radio"
                                                    value="view"
                                                    checked={this.state.selectedOption === 'view'}
                                                    onChange={this.handleOptionChange}
                                                />
                                                <label className="fbold f15 ml-5p mt-5p" style={{ color: "black" }}>
                                                    View Data
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex">
                                                <input
                                                    type="radio"
                                                    value="delete"
                                                    checked={this.state.selectedOption === 'delete'}
                                                    onChange={this.handleOptionChange}
                                                />
                                                <label className="fbold f15 ml-5p mt-5p" style={{ color: "black" }}>
                                                    Delete Data
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row p-10p">
                                        <div className="col-12 d-flex align-items-end">
                                            <div className="col-7">
                                                <div className="form-group mb-0p">
                                                <label className="fbold f17 ml-5p mt-5p" style={{ color: "black" }}>
                                                    Data Uploaded on
                                                </label>
                                                    <Select
                                                        placeholder={"Select"}
                                                        onChange={this.onChangeLatestPhases}
                                                        name="latest phase"
                                                        value={this.state.latestPhase}
                                                        options={this.state.latestPhases}
                                                        styles={newCustomStyles}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                {this.state.selectedOption === "view" ? (<button type="button" className="btn btn-success mb-0p" onClick={this.onClickingViewDataBtn}>View Data</button>) : (<button type="button" className="btn btn-danger mb-0p" onClick={this.onClickingDeleteDataBtn}>Delete Data</button>)}
                                            </div>
                                            </div>
                                        </div>
                                    </form>
                                    :
                                    ""
                                }
                            </div>
                            : ""}
                        {(this.state.sliderType == 2) ?
                            <div className="col-md-12">
                                <Mlist options={this.state.forecastitemslist} durationlist={this.state.durationlist}></Mlist>
                            </div>
                            : ""}
                        {(this.state.sliderType == 3) ?
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {/*<button type="button" className="btn btn-info float-right" onClick={this.onBtnExportF.bind(this)}>Export to CSV</button>*/}
                                        <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelF.bind(this)}>Export to Excel</button>
                                    </div>
                                </div>
                                <div id="myGrid1" style={{ width: "100%", height: "70vh", padding: "2%", fontWeight: "600", fontSize: "13px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        columnDefs={columnwithDefs1}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.learningData}
                                        enableCharts={true}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={""}
                                        sideBar={""}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        enableRangeSelection={true}
                                        // masterDetail={true}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        modules={[MenuModule, ExcelExportModule]}
                                    />
                                </div>
                            </div>
                            : ""}

                        {(this.state.sliderType == 11) ?
                            <div className="col-md-12">
                                {/* <div className="row">
                                    <div className="col-sm-12">
                                        <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelF.bind(this)}>Export to Excel</button>
                                    </div>
                                </div> */}
                                <div id="myGrid1" style={{ width: "100%", height: "30vh", padding: "2%", fontWeight: "600", fontSize: "13px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        columnDefs={columnDefsForMaterialConsumption}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.fyData}
                                        enableCharts={true}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={""}
                                        sideBar={""}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        enableRangeSelection={true}
                                        // masterDetail={true}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        modules={[MenuModule, ExcelExportModule]}
                                    />
                                </div>
                                {/* <div className="row">
                                    <div className="col-sm-12">
                                        <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelF.bind(this)}>Export to Excel</button>
                                    </div>
                                </div> */}
                                <div id="myGrid1" style={{ width: "100%", height: "30vh", padding: "2%", fontWeight: "600", fontSize: "13px" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        columnDefs={columnDefsForMaterialConsumption1}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.yearData}
                                        enableCharts={true}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={""}
                                        sideBar={""}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        enableRangeSelection={true}
                                        // masterDetail={true}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        modules={[MenuModule, ExcelExportModule]}
                                    />
                                </div>
                                <div>
                                    <MaterialDataChart plotData={this.state.plotData} />
                                </div>
                            </div>
                        : ""}

                        {(this.state.sliderType == 4) ?
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportG.bind(this)}>Export to CSV</button>
                                        <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelG.bind(this)}>Export to Excel</button>
                                    </div>
                                </div>
                                <div id="myGrid2" style={{ width: "100%", height: "70vh", padding: "2%", fontSize: "13px", fontWeight: "600" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        columnDefs={verticalColumnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.verticalColumn}
                                        enableCharts={true}
                                        onGridReady={this.onGridReady2}
                                        onGridState={this.onGridState2}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={""}
                                        sideBar={""}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        enableRangeSelection={true}
                                        // masterDetail={true}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        modules={[MenuModule, ExcelExportModule]}
                                    />
                                </div>
                            </div>
                            : ""}
                        {(this.state.sliderType == 5) ?
                            <div className={"mxheit col-sm-12"}>
                                <TriggerLearningChart
                                    title={"Material consumption history"}
                                    subtitle={"Material Code: " + (this.state.chartMaterial)}
                                    xAxisTitle={"Year"}
                                    yAxisTitle={"Qunatity Consumed"}
                                    categories={this.state.trendingCategories}
                                    confLine={this.state.confidenceTrain}
                                    confLineTrend={this.state.confidenceTrend}
                                    confLinePredictTest={this.state.confidenceTestPoints}
                                    confLinePredictTrend={this.state.trend_predicted_points}
                                    confAreaData={this.state.confAreaData}
                                    confAreaYHat={this.state.confAreaYHat}
                                    confScatter={this.state.confScatter}
                                    dataType={1}
                                // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                />

                                <TriggerLearningChart
                                    title={"Inventory Cost"}
                                    subtitle={"Material Code: " + (this.state.chartMaterial)}
                                    xAxisTitle={""}
                                    yAxisTitle={"Cost ($)"}
                                    categories={this.state.trendingCategories}
                                    confLine={this.state.confidenceTrain}
                                    confLineTrend={this.state.confidenceTrend}
                                    confLinePredictTest={this.state.confidenceTestPoints}
                                    confLinePredictTrend={this.state.trend_predicted_points}
                                    confAreaData={this.state.confAreaData}
                                    confAreaYHat={this.state.confAreaYHat}
                                    confScatter={this.state.confScatter}
                                    trendingcost={this.state.trendingcost}
                                    dataType={2}
                                // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                />
                            </div>
                            : ""}
                        {(this.state.sliderType == 6) ?
                            <div className="col-md-12 pad-left-5per">
                                <div className="row">
                                    {(this.state.learningComponent) ?
                                        <>
                                            <div className="col-sm-12">
                                                <button type="button" className="btn btn-info" onClick={this.onBtnExport.bind(this)}>Export to CSV</button>
                                                <button type="button" className="btn btn-primary" onClick={this.onBtExportExcel.bind(this)}>Export to Excel</button>
                                            </div>
                                        </>
                                        : ''}
                                    {(this.state.yettolearnComponent) ?
                                        <div className="col-sm-12">
                                            <button type="button" className="btn btn-info " onClick={this.onBtnExportYettolearn.bind(this)}>Export to CSV</button>
                                            <button type="button" className="btn btn-primary " onClick={this.onBtExportExcelYettolearn.bind(this)}>Export to Excel</button>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            : ""}
                        {(this.state.sliderType == 10) ?
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button type="button" className="btn btn-danger float-right" onClick={this.onClickingGoBackBtn}>Go Back</button>
                                        <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelG.bind(this)}>Export to Excel</button>
                                    </div>
                                </div>
                                <div id="myGrid1" style={{ width: "100%", height: "80vh", padding: "2%", fontSize: "13px", fontWeight: "600" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        columnDefs={actualUploadedDetailedDataColumnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.actualUploadedDetailedData}
                                        enableCharts={true}
                                        onGridReady={this.onGridReady2}
                                        onGridState={this.onGridState2}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={""}
                                        sideBar={""}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        enableRangeSelection={true}
                                        // masterDetail={true}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        modules={[MenuModule, ExcelExportModule]}
                                    />
                                </div>
                            </div>
                            : ""}

                    </div>
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                {/* <div className={"loader " + this.state.loadshow} style={{ zIndex: "9999" }}></div> */}
            {this.state.isPopupOpen === true && (
                <div className="new-popup-bg-container">
                    <div className="new-popup-container">
                        <h3 className="f20 fbold mb-30p" style={{color: "black"}}>Are you sure you want to delete the data?</h3>
                        <button className="btn btn-success" onClick={this.onClickingPopupDeleteBtn}>Delete</button>
                        <button className="btn btn-danger" onClick={this.onClickingPopupCancelBtn}>Cancel</button>
                    </div>
                </div>
            )}
                {this.state.loadshow === "show-m" && (
                    <>
                        <div class="new-loader" style={{ zIndex: "9999" }}>
                            <div class="bar bar1"></div>
                            <div class="bar bar2"></div>
                            <div class="bar bar3"></div>
                            <div class="bar bar4"></div>
                            <div class="bar bar5"></div>
                            <div class="bar bar6"></div>
                            <div class="bar bar7"></div>
                            <div class="bar bar8"></div>
                        </div>
                        <div className="col-12 fw-600 f20" style={{ color: "black", position: "absolute", top: "54%", left: "47%", zIndex: "9999" }}>
                            <p>Loading...</p>
                        </div>
                    </>
                )}
                {/* <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div> */}
            </div>
        )
    }
}

function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

function getUniqueCount(arr) {
    const uniqueNames = new Map();

    for (const obj of arr) {
        const item_code = obj.item_code;
        uniqueNames.set(item_code, (uniqueNames.get(item_code) || 0) + 1);
    }

    const uniqueCount = uniqueNames.size;

    return uniqueCount;

}
