import { Logger } from "ag-grid-community"
// import "../../assets/css/tdsg.css"
import React from 'react';
import { FcApproval } from "react-icons/fc";
import { MdDriveFolderUpload } from "react-icons/md";


const AGgridCellDetailComponent = (props) => {
    let {usecase, data} = props
    if (usecase=== "view_consumption_data"){
    const handleContainerClick = (e) => {
        e.stopPropagation();
        data.context.AGgridCellDetailComponentFunction(data,usecase);
    }
    return (
<div className="aggrid-legs-style-css" onClick={(e) => handleContainerClick(e)} title="Customs details">
  <button
    id="aggrid-button"
    className="btn btn-info full-width-btn"
    title="View Data"
    onClick={(e) => handleContainerClick(e)}
  >
    <i className="fa fa-eye" /> View Data
  </button>
</div>

        )
    }
    else if (usecase === "view_actual_data"){
        const handleContainerClick = (e) => {
            e.stopPropagation();
            data.context.AGgridCellDetailComponentFunction(data,usecase);
        }
        return (
                <div className="aggrid-legs-style-css" onClick={(e) => handleContainerClick(e)} title="Customs details">
                <button
                    id="aggrid-button"
                    className="btn btn-success full-width-btn"
                    title="View Data"
                    onClick={(e) => handleContainerClick(e)}
                >
                    <i className="fa fa-eye" /> View 
                </button>
                </div>
    
            )

    }
    }


 
export default AGgridCellDetailComponent