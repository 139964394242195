import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";

export default class RouteConfigurations extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleAvailabilityModel:false,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            showAvailablityTable:false,
            data_type:'',
            location:'',
            state_name:{"label":"Select State","value":""},
            zone:'',
            transporter_code:'',
            transporter_name:'',
            route:'',
            rowId:'',
            locationNames:[],
            state_names:[],
            boundLocations:[],
            transporter_details:[],
            city:{"label":"Select City","value":""},
            transporter:{"label":"Select Transporter","value":""},
            tptOptions:[],
            allData:[],
            transporterFilter:{"label":"All","value":""},
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        await this.boundTypeLocations(params)
        await this.getRouteData(params)
        await this.getStatesData(params)
        await this.getTransporterDetails(params)
    }

    getRouteData = (params) =>{
        redirectURL.post('master/getRouteData',params)
        .then(response =>{
            this.setState({
                allData:response.data,
                rowData:response.data,
            })
        })
    }

    getStatesData = (params) =>{
        redirectURL.post('master/getStatesData',params)
        .then(response =>{
            this.setState({
                state_names:response.data
            })
        })
    }

    getTransporterDetails = (params) =>{
        redirectURL.post('master/getTransporterDetails',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                var transporter_details = response.data.transporterDetailsData;
                var tptFilterOptions = [];
                var tptOptions = [];
                if(transporter_details !=undefined && transporter_details !="")
                {
                    if(transporter_details.length > 0)
                    {
                        var tpt_details = await distinctArrayBy(transporter_details,"transporter_code");
                        console.log(tpt_details,"tpt_details")
                        tptFilterOptions.push({ value : "",
                            label : "All" })
                        await tpt_details.map(item =>{
                            tptFilterOptions.push({ value : item.transporter_code,
                                label : item.transporter_name })
                            tptOptions.push({ value : item.transporter_code,
                                label : item.transporter_name })
                        })
                        // console.log(tptOptions,"tptOptions")
                        this.setState({tptOptions:tptOptions,tptFilterOptions:tptFilterOptions,transporter_details:transporter_details});
                    }
                }
            }
        })
    }

    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            state:'',
            city:'',
            zone:'',
            transporter_code:'',
            transporter_name:'',
            route:'',
            rowId:'',
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    
    vehicleConfigurationInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0],
                        boundLocations : boundLocations,
                    })
                }
            }
        })
        
    }
    
    getStateNames =()=> {
		let items = [];    
		try{
			if(this.state.state_names.length > 0)
			{
				this.state.state_names.map((item) =>{
					items.push({"value":item.zone,"label":item.state})
				})
			}
		}
		catch(e){
		}		
		return items;
	}

    cityDropDownOptions = () => { 
        var boundLocations = this.state.boundLocations;
        var cityOptions = [];
        if(boundLocations !=undefined && boundLocations !="")
        {
            boundLocations.map(item =>{
                cityOptions.push({ value : item.location_city,
                    label : item.location_city })
            })
        }
        return cityOptions;
    }

    saveRouteConfiguration = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveRouteConfiguration',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getRouteData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        state:'',
                        city:'',
                        zone:'',
                        transporter_code:'',
                        transporter_name:'',
                        route:'',
                        rowId:'',
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:response.data.message,
                    basicType:"danger"
                })
            }
        })
    }


    formRouteConfiguration = (e)=>{
        e.preventDefault();
        let state = this.state.state_name.label;
        let city = this.state.city.value;
        let zone = this.state.state_name.value;
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        var transporterEnable = 0;
        var zoneWiseData = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                var route_configuration_type = toolConfig[0].route_configuration_type;
                if(route_configuration_type == 3)
                {
                    transporterEnable = 1;
                }
                if(route_configuration_type == 2)
                {
                    zoneWiseData = 1;
                }
            }
        }
        if(transporterEnable == 1)
        {
            var transporter_code = this.state.transporter.value;
            var transporter_name = this.state.transporter.label;
        }
        else
        {
            var transporter_code = "LSP";
            var transporter_name = "LSP";
        }
        let route = this.state.route;
        let data_type = this.state.data_type;
        let location = this.state.location;
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let rowId = this.state.rowId;
        let params = {
            module_type : localStorage.getItem("module_type"),
            zoneWiseData : zoneWiseData,
            state : state,
            city : city,
            zone : zone,
            transporter_code : transporter_code,
            transporter_name : transporter_name,
            route : route,
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            rowId : rowId         
        }

        if(location.value != null){
            this.saveRouteConfiguration(params)
        }else{
            this.setState({
                show:true,
                basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                basicType:"danger"
            })
        }
    }

    editRouteConfiguration = async(propdata) =>{
        let data = propdata.data
        var state_data = "";
        if(data.state !=undefined && data.state !="")
        {
            var state_names = this.state.state_names;
            var sns = state_names.filter((vt) => vt.state == data.state);
            if(sns.length > 0)
            {
                state_data = {"label":sns[0].state,"value":sns[0].zone}
            }
        }
        var city = "";
        if(data.state !=undefined && data.state !="")
        {
            var boundLocations = this.state.boundLocations;
            var sns = boundLocations.filter((vt) => vt.location_city == data.city);
            if(sns.length > 0)
            {
                city = {"label":sns[0].location_city,"value":sns[0].location_city}
            }
        }
        var transporter = "";
        if(data.transporter_code !=undefined && data.transporter_code !="")
        {
            var transporter_details = this.state.transporter_details;
            var sns = transporter_details.filter((vt) => vt.transporter_code == data.transporter_code);
            if(sns.length > 0)
            {
                transporter = {"label":sns[0].transporter_name,"value":sns[0].transporter_code}
            }
        }
        await this.setState({
            "state_name" : state_data,
            "city" : city,
            "zone" : data.zone,
            "transporter" : transporter,
            // "transporter_code" : data.transporter_code,
            // "transporter_name" : data.transporter_name,
            "route" : data.route,
            "rowId" : data._id
        })
        this.openModelForMaterialConfiguration()
    }

    deleteRouteConfiguration = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteRouteConfiguration',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getRouteData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }
    onChangeStateNames(state_name){
		this.setState(
            { state_name },
            () => console.log(`Option selected:`, this.state.state_name)
        );
	}
    onChangeCityNames(city){
		this.setState(
            { city },
            () => console.log(`Option selected:`, this.state.city)
        );
	}
    onChangeTransporterNames(transporter){
		this.setState(
            { transporter },
            () => console.log(`Option selected:`, this.state.transporter)
        );
	}
    onChangeTransporterFilter(transporterFilter){
		this.setState(
            { transporterFilter },
            () => console.log(`Option selected:`, this.state.transporterFilter)
        );
        if(transporterFilter.value !=undefined && transporterFilter.value !="")
        {
            this.setState({
                transporter : transporterFilter
            });
        }
	}

    render(){

        var colsDefs = [
            {
                headerName:Constant.DEALER_FORM_STATE,
                field:"state",
                width:"120"
            },
            {
                headerName:Constant.DEALER_FORM_CITY,
                field:"city",
                width:"130"
            },
            {
                headerName:Constant.ZONE,
                field:"zone",
                width:"130"
            },
            {
                headerName:Constant.TRANSPORTER_CODE,
                field:"transporter_code",
                width:"150",
            },
            {
                headerName:Constant.TRANSPORTER_NAME,
                field:"transporter_name",
                width:"200"
            },
            {
                headerName:Constant.ROUTE,
                field:"route",
                width:"200"
            },
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Edit',
                        onClickFunction:this.editRouteConfiguration},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteRouteConfiguration},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
        ]
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        var transporterEnable = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                var route_configuration_type = toolConfig[0].route_configuration_type;
                if(route_configuration_type == 3)
                {
                    transporterEnable = 1;
                }
            }
        }
        
        let data = this.state.rowData
        let locValue = this.state.location.value
         
        let filteredData = data.filter(item => item.location_code == locValue)
        if(this.state.transporterFilter.value !=undefined && this.state.transporterFilter.value !="")
        {
            filteredData = filteredData.filter((e)=>e.transporter_code == this.state.transporterFilter.value);
        }
        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12 d-flex">
                <div className= {this.state.showAvailablityTable ? "col-sm-7 " : "col-sm-12 " + "  d-inline"}>
                    
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Route Configurations</span>
                                <div className='float-right'>
                                    <a href="javascript:;" onClick={this.openModelForMaterialConfiguration} className="f18 float-right themefont fbold mr-10p mb-10p">Add Route Info</a>
                                </div> 

                        </h5>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                <Select
                                    placeholder={"Select"}
                                    onChange={this.locationChanged}
                                    name="location"
                                    value={this.state.location}
                                    options={this.state.locationNames} />

                            </div>
                        </div>
                        {(transporterEnable == 1)?
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">Transporters</label>
                                <Select
                                    placeholder={"Select"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeTransporterFilter.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.transporterFilter}
                                    options={this.state.tptFilterOptions} 
                                />
                            </div>
                        </div>
                        :""}
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                        
                    </div>

                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openMaterialConfigurationModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>Route Configuration</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formRouteConfiguration}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.DEALER_FORM_STATE} </label>
                                    <Select 
                                    placeholder={"Select "+Constant.TRANSPORTER_FORM_STATE}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeStateNames.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.state_name}
                                    options={this.getStateNames()} />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.DEALER_FORM_CITY} </label>
                                    <Select 
                                    placeholder={"Select "+Constant.DEALER_FORM_CITY}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeCityNames.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.city}
                                    options={this.cityDropDownOptions()} />
                                </div>
                            </div>
                            {(transporterEnable == 1)?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.TRANSPORTER} </label>
                                    <Select 
                                    placeholder={"Select "+Constant.TRANSPORTER}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeTransporterNames.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.transporter}
                                    options={this.state.tptOptions} />
                                </div>
                            </div>
                            :""}
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.ROUTE}</label>
                                    <input type="text" 
                                        className= "forminp form-control"
                                        required 
                                        name="route" 
                                        value={this.state.route}
                                        onChange={this.vehicleConfigurationInputFields} 
                                        placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div>

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                    </Modal>
            </>
        )
    }
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}