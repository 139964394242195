import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import getToolConfiguration from "./commonFunctions";

export default class VehiclesData extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleConfigurationModel:false,
            vehicle_type:'',
            vehicle_name:'',
            vehicle_priority:"Owned",
            min_volume_cmt:'',
            max_volume_cmt:'',
            vehicle_dimensions_width_mm:'',
            vehicle_dimensions_depth_mm:'',
            vehicle_dimensions_height_mm:'',
            vehicle_min_weight:'',
            vehicle_max_weight:'',
            max_no_of_nodes:'',
            variable_vehicle_cost_expandable:[],
            show:false,
            basicTitle:"",
            basicType:"default",
            showAvailablityTable:false,
            data_type:'',
            location:'',
            max_distance:'',
            vehicle_availability_time:['00:00','23:59'],
            break_time:['00:00','23:59'],
            locationNames:[],
            min_load_per_delivery:'',
            // vehicle_capacity:'',
            vehicle_id:'',
            vehicle_type_count:0,
            openVehiclePriorityConfigurationModel:false,
            vehicle_priority_order_owned:1,
            vehicle_priority_order_contract:2,
            vehicle_priority_order_market:3,

        }
        
    }

   async componentDidMount(){
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path_type == 'inbound'){
            data_type = 1
            this.setState({data_type : data_type})
        }else{
            data_type = 2
            this.setState({data_type : data_type})
        }
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        await this.getVehiclesData(params)
        await this.boundTypeLocations(params)
        await this.getVehiclePriorityOrder(params)
    }

    getVehiclesData = (params) =>{
        redirectURL.post('master/getVehiclesData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                await vehiclesData.map(item => {
                    if(item.vehicle_capacity){
                       item.vehicle_capacity= Math.round((item.vehicle_capacity/1000000000)*100)/100
                    }
                })
            this.setState({
                rowData:vehiclesData
            })
        }
        })
    }

    openModelForVehicleConfiguration = () =>{
        this.setState(prevState =>({
            openVehicleConfigurationModel :!prevState.openVehicleConfigurationModel
        }))
    }

    handelVehiclePriorityConfigurationModel = () =>{
        this.setState(prevState =>({
            openVehiclePriorityConfigurationModel :!prevState.openVehiclePriorityConfigurationModel
        }))
    }
    handelVehiclePriorityConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openVehiclePriorityConfigurationModel :!prevState.openVehiclePriorityConfigurationModel,
        }))
    }

    saveVehiclePriorityOrder = () =>{
        // location specific ??
        let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
        let vehicle_priority_order_contract = this.state.vehicle_priority_order_contract;
        let vehicle_priority_order_market = this.state.vehicle_priority_order_market;
        let data_type = this.state.data_type
        let params = {
            owned : parseInt(vehicle_priority_order_owned),
            contract : parseInt(vehicle_priority_order_contract),
            market : parseInt(vehicle_priority_order_market),
            data_type : data_type
        }
        redirectURL.post('master/saveVehiclePriorityOrder',params)
        .then(response =>{
            if(response.data.status == 'success'){
                this.setState({
                    openVehiclePriorityConfigurationModel:false
                })

            }else{
                this.setState({
                    show:true,
                    basicTitle:"Data Not Saved successfully",
                    basicType:"danger"
                })
            }
        })
    }

    getVehiclePriorityOrder = (params)=>{
        let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
        let vehicle_priority_order_contract = this.state.vehicle_priority_order_contract;
        let vehicle_priority_order_market = this.state.vehicle_priority_order_market;
        redirectURL.post('master/getVehiclePriorityOrder',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let vehiclePriorityData = response.data.vehiclePriorityData
                vehicle_priority_order_owned = vehiclePriorityData[0].owned
                vehicle_priority_order_contract = vehiclePriorityData[0].contract
                vehicle_priority_order_market = vehiclePriorityData[0].market
                this.setState({
                    vehicle_priority_order_owned,
                vehicle_priority_order_contract,
                vehicle_priority_order_market
                })
            }
        })

    }

    handelVehicleConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openVehicleConfigurationModel :!prevState.openVehicleConfigurationModel,
            vehicle_type:'',
            vehicle_name:'',
            vehicle_priority:"Owned",
            min_volume_cmt:'',
            max_volume_cmt:'',
            vehicle_dimensions_width_mm:'',
            vehicle_dimensions_depth_mm:'',
            vehicle_dimensions_height_mm:'',
            vehicle_min_weight:'',
            vehicle_max_weight:'',
            max_no_of_nodes:'',
            min_load_per_delivery:'',
            // vehicle_capacity:'',
            vehicle_availability_time:['00:00','23:59'],
            break_time:['00:00','23:59'],
            max_distance:'',
            vehicle_id : '',
            vehicle_type_count:0
            
        }))

    }

    // priorityOptions() {
    //     var items = []
    //     items.push({
    //     {
    //         label: "Owned"
    //         value: "owned",
    //     }, {
    //         value: "contract",
    //         label: "Contract"
    //     },{
    //         value: "market",
    //         label: "Market"
    //     }
    //     )
    //     return items;
    // }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}

    handleAvailabilityTime = (val) => {
    	this.setState({vehicle_availability_time:val});
    }

    handleBreakTime = (val) => {
    	this.setState({break_time:val});
    }
    
    vehicleConfigurationInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value

        if(name == "vehicle_type"){
            value = value.toUpperCase()
        }
        this.setState({ [name] : value })
    }

    // vehiclePriority = (vehicle_priority) =>{
    //     console.log(vehicle_priority)
    //     this.setState({
    //         vehicle_priority : vehicle_priority
    //     })
    // }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
            await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }


    validateTimeRange = (from_time, to_time) =>{
        let frm_hr = from_time.split(':')
            let to_hr = to_time.split(':')
            
            if(from_time == to_time){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }
            else if(frm_hr[0] > to_hr[0]){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }else if(frm_hr[0] == to_hr[0]){
                if(frm_hr[1] > to_hr[1]){
                    this.setState({
                        show:true,
                        basicTitle:"Please Input Time Appropriately",
                        basicType:"danger"
                    })
                }else{
                    return true
                }

            }else{
                return true
            }

    }



    saveVehicleConfigurationData = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveVehicleConfiguration',params)
        .then(response =>{
                    
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesData(param)
                this.setState({openVehicleConfigurationModel:false,
                            vehicle_type:'',
                            vehicle_name:'',
                            vehicle_priority:"Owned",
                            min_volume_cmt:'',
                            max_volume_cmt:'',
                            vehicle_dimensions_width_mm:'',
                            vehicle_dimensions_depth_mm:'',
                            vehicle_dimensions_height_mm:'',
                            vehicle_min_weight:'',
                            vehicle_max_weight:'',
                            min_load_per_delivery:'',
                            vehicle_availability_time:['00:00','23:59'],
                            break_time:['00:00','23:59'],
                            max_distance:'',
                            max_no_of_nodes:'',
                            // vehicle_capacity:'',
                            vehicle_type_count:0

                        })
                    }else{
                        if(response.data.status == 'failed'){
                            this.setState({
                                show:true,
                                basicTitle:response.data.message,
                                basicType:"danger"
                            })
                        }else{
                            this.setState({
                                show:true,
                                basicTitle:"Data Not Saved successfully",
                                basicType:"danger"
                            })
                        }
                    }

                })
    }


    formVehicleConfiguration = (e)=>{
        e.preventDefault();
        let vehicle_type = this.state.vehicle_type;
        let vehicle_name = this.state.vehicle_name;
        let vehicle_priority = this.state.vehicle_priority;
        let min_volume_cmt = this.state.min_volume_cmt;
        let max_volume_cmt = this.state.max_volume_cmt;
        let vehicle_dimensions_width_mm = this.state.vehicle_dimensions_width_mm;
        let vehicle_dimensions_depth_mm = this.state.vehicle_dimensions_depth_mm;
        let vehicle_dimensions_height_mm = this.state.vehicle_dimensions_height_mm;
        let vehicle_min_weight = this.state.vehicle_min_weight;
        let vehicle_max_weight = this.state.vehicle_max_weight;
        let max_no_of_nodes = this.state.max_no_of_nodes;
        let min_load_per_delivery = this.state.min_load_per_delivery
        let data_type = this.state.data_type;
        let location = this.state.location;
        let max_distance = this.state.max_distance;
        let vehicle_availability_time = this.state.vehicle_availability_time;
        let break_time = this.state.break_time;
        // let vehicle_capacity = this.state.vehicle_capacity;
        let vehicle_id = this.state.vehicle_id;
        let vehicle_type_count = this.state.vehicle_type_count

        
        let vehicle_capacity = (vehicle_dimensions_width_mm * vehicle_dimensions_depth_mm *  vehicle_dimensions_height_mm);

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let break_time_from,break_time_to,vehicle_availability_from,vehicle_availability_to

        
        if(max_distance == '' || toolConfig[0].distance_preferences == 0){
            max_distance = 100000
        }
        if(max_no_of_nodes == '' || toolConfig[0].clubbing_limits == 0){
            max_no_of_nodes = 100000
        }
        if(min_load_per_delivery == '' || toolConfig[0].min_load_preferences == 0){
            min_load_per_delivery = 0
        }
        if(max_volume_cmt == '' || toolConfig[0].truck_min_max_configuration_preferences == 0){
            let metricValue = Math.round((vehicle_capacity/1000000000))
            max_volume_cmt = parseInt(metricValue)
        }
        if(min_volume_cmt == '' || toolConfig[0].truck_min_max_configuration_preferences == 0){
            min_volume_cmt = 0
        }
        if (break_time[0]==null){
            
            break_time_from = '00:00';
            break_time_to = '23:59';
        }else{
            break_time_from = break_time[0];
            break_time_to = break_time[1]
            
        }
        if (vehicle_availability_time[0]==null){
            vehicle_availability_from = '00:00';
            vehicle_availability_to = '23:59';
        }else{
            vehicle_availability_from = vehicle_availability_time[0];
            vehicle_availability_to = vehicle_availability_time[1]
        }
  
        let params = {
            vehicle_type : vehicle_type,
            vehicle_name : vehicle_name,
            vehicle_priority : vehicle_priority,
            min_volume_cmt : parseInt(min_volume_cmt),
            max_volume_cmt : parseInt(max_volume_cmt),
            vehicle_dimensions_width_mm : parseInt(vehicle_dimensions_width_mm),
            vehicle_dimensions_depth_mm : parseInt(vehicle_dimensions_depth_mm),
            vehicle_dimensions_height_mm : parseInt(vehicle_dimensions_height_mm),
            vehicle_min_weight : parseInt(vehicle_min_weight),
            vehicle_max_weight : parseInt(vehicle_max_weight),
            max_no_of_nodes : parseInt(max_no_of_nodes),
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            max_distance : parseInt(max_distance),
            min_load_per_delivery : parseInt(min_load_per_delivery),
            break_time_from : break_time_from,
            break_time_to : break_time_to,
            vehicle_availability_from : vehicle_availability_from,
            vehicle_availability_to : vehicle_availability_to,
            vehicle_capacity : parseFloat(vehicle_capacity),
            vehicle_type_count : parseInt(vehicle_type_count)
            
        }

        if(vehicle_id != ''){

            params.vehicle_id = vehicle_id
            if(toolConfig[0].vehicle_operational_times_type == 1 && toolConfig[0].vehicle_operational_times == 1){
            

                let vehicle_time = this.validateTimeRange(vehicle_availability_time[0],vehicle_availability_time[1])
                let break_times = this.validateTimeRange(break_time[0],break_time[1])
    
                if(vehicle_time && break_times){
    
                    this.updateVehicleConfigurationData(params)  
                }
    
            }else{
                this.updateVehicleConfigurationData(params)
            }

        }else{

            if(location.value != null){

                if(toolConfig[0].vehicle_operational_times_type == 1 && toolConfig[0].vehicle_operational_times == 1){
                    

                    let vehicle_time = this.validateTimeRange(vehicle_availability_time[0],vehicle_availability_time[1])
                    let break_times = this.validateTimeRange(break_time[0],break_time[1])

                    if(vehicle_time && break_times){

                        this.saveVehicleConfigurationData(params)  
                    }

                }else{
                    this.saveVehicleConfigurationData(params)
                }
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
            }


        }

    }


    updateVehicleConfiguration = async(propdata) =>{
        let data = propdata.data

            await this.setState({
                vehicle_type:data.vehicle_type,
                vehicle_name:data.vehicle_name,
                vehicle_priority:data.vehicle_priority,
                min_volume_cmt:data.min_volume_cmt,
                max_volume_cmt:data.max_volume_cmt,
                vehicle_dimensions_width_mm:data.vehicle_dimensions_width_mm,
                vehicle_dimensions_depth_mm:data.vehicle_dimensions_depth_mm,
                vehicle_dimensions_height_mm:data.vehicle_dimensions_height_mm,
                vehicle_min_weight:data.vehicle_min_weight,
                vehicle_max_weight:data.vehicle_max_weight,
                max_no_of_nodes:data.max_no_of_nodes,
                min_load_per_delivery:data.min_load_per_delivery,
                vehicle_availability_time:[data.vehicle_availability_from,data.vehicle_availability_to],
                break_time:[data.break_time_from,data.break_time_to],
                max_distance:data.max_distance,
                // vehicle_capacity:data.vehicle_capacity,
                vehicle_id:data.vehicle_id,
                vehicle_type_count:data.vehicle_type_count

            })
            this.openModelForVehicleConfiguration()
    
    }

    updateVehicleConfigurationData = (params) =>{
        let data_type = this.state.data_type

        redirectURL.post('master/updateVehicleConfigurationData',params)
        .then(response =>{
                            
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesData(param)
                this.setState({openVehicleConfigurationModel:false,
                            vehicle_type:'',
                            vehicle_name:'',
                            vehicle_priority:"Owned",
                            min_volume_cmt:'',
                            max_volume_cmt:'',
                            vehicle_dimensions_width_mm:'',
                            vehicle_dimensions_depth_mm:'',
                            vehicle_dimensions_height_mm:'',
                            vehicle_min_weight:'',
                            vehicle_max_weight:'',
                            max_no_of_nodes:'',
                            min_load_per_delivery:'',
                            vehicle_availability_time:['00:00','23:59'],
                            break_time:['00:00','23:59'],
                            max_distance:'',
                            // vehicle_capacity:'',
                            vehicle_id:'',
                            vehicle_type_count:0

                        })
                    }else{
                        if(response.data.status == 'failed'){
                            this.setState({
                                show:true,
                                basicTitle:response.data.message,
                                basicType:"danger"
                            })
                        }else{
                            this.setState({
                                show:true,
                                basicTitle:"Data Not Saved successfully",
                                basicType:"danger"
                            })
                        }
                    }

                })
    }

    deleteVehicleConfiguration = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id,
            vehicle_type : data.vehicle_type,
            data_type : data_type
        }
        redirectURL.post('master/deleteVehicleConfiguration',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }


    render(){

        var colsDefs = [
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"100"
            },
            {
                headerName:"Vehicle Name",
                field:"vehicle_name",
                width:"100"
            },
            {
                headerName:"Vehicle Priority",
                field:"vehicle_priority",
                width:"100"
            },
            // {
            //     headerName:"No of Vehicles",
            //     field:"no_of_vehicles",
            //     width:"80",
                // initialWidth:"80",
                // editable:true,
            // },
            {
                headerName:"Vehicle Operational(from)",
                field:"vehicle_availability_from",
                width:"150",
                // initialWidth:"80",
                // editable:true,
                // cellEditorPopup: true,
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                // values: Constant.TIMER_VALUES,
                // }
            },
            {
                headerName:"Vehicle Operational(to)",
                field:"vehicle_availability_to",
                width:"140"
                // initialWidth:"80",
                // editable:true,
                // cellEditorPopup: true,
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                // values: Constant.TIMER_VALUES,
                // }
            },
            {
                headerName:"Break Time(from)",
                field:"break_time_from",
                width:"110",
                // initialWidth:"80",
                // editable:true,
                // cellEditorPopup: true,
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                // values: Constant.TIMER_VALUES,
                // }
            },
            {
                headerName:"Break Time(to) ",
                field:"break_time_to",
                width:"100",
                // initialWidth:"80",
                // role:"listbox",
                // editable:true,
                // cellEditorPopup: true,
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                // values: Constant.TIMER_VALUES,
                // }
            },
            {
                headerName:"Min Volume (CMT)",
                field:"min_volume_cmt",
                width:"120"
            },
            {
                headerName:"Max Volume (CMT)",
                field:"max_volume_cmt",
                width:"120"
            },
            {
                headerName:"Vehicle Width (mm)",
                field:"vehicle_dimensions_width_mm",
                width:"120"
            },
            {
                headerName:"Vehicle Height (mm)",
                field:"vehicle_dimensions_height_mm",
                width:"125"
            },
            {
                headerName:"Vehicle Depth (mm)",
                field:"vehicle_dimensions_depth_mm",
                width:"120"
            },
            {
                headerName:"Vehicle Min Weight (Kgs)",
                field:"vehicle_min_weight",
                width:"145"
            },
            {
                headerName:"Vehicle Max Weight (Kgs)",
                field:"vehicle_max_weight",
                width:"145"
            },
            {
                headerName:"Vehicle Capacity (CMT)",
                field:"vehicle_capacity",
                width:"135"
            },
            {
                headerName:"Min Load Per Delivery",
                field:"min_load_per_delivery",
                width:"140"
            },
            {
                headerName:"Max Distance (Kms)",
                field:"max_distance",
                width:"120"
            },
            // {
            //     headerName:"Variable Vehicle Cost",
            //     field:"variable_vehicle_cost_expandable",
            //     width:"120"
            // },
            // {
            //     headerName:"Variable Vehicle Cost",
            //     field:"cluster",
            //     width:"120",
            //     pinned:'left',
            //     params:{buttonName:'Variable Cost',
            //             onClickFunction:this.variableCostFunction},
            //     cellRendererSelector:function(params){
                    
            //         var rendComponent = {
            //             component: "GridButton"
            //         };
            //         return rendComponent
                
            //     },
            // },
            {
                headerName:"Actions",
                field:"cluster",
                width:"20",
                pinned:'left',
                params:{buttonName:'Edit',
                        onClickFunction:this.updateVehicleConfiguration},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteVehicleConfiguration},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
        ]
        if(this.state.data_type == 1){
            colsDefs.push({
                headerName:"Suppliers / Vendor Clubbing Limits" ,
                field:"max_no_of_nodes",
                width:"300"
            });
        }else{
            colsDefs.push({
                headerName:"Consignees / Dealers Clubbing Limits" ,
                field:"max_no_of_nodes",
                width:"300"
            });
        }

        let data = this.state.rowData
        let locValue = this.state.location.value   
        let filteredData = data.filter(item => item.location_code == locValue)

        let vehicleTypeCnt = 4;
        let truck_min_max = 0;
        let truckPriorities = 0;
        let distancePreferences = 0;
        let operationalTimes = 0;
        let operationalTimesType = 0;
        let clubbingLimits = 0;
        let minLoad = 0;
        let vehicleAvailability = 0;
        let vehicleMinMaxWeight = 0;

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                vehicleTypeCnt = toolConfig[0].max_no_of_trucks;
                truck_min_max = toolConfig[0].truck_min_max_configuration_preferences;
                truckPriorities = toolConfig[0].truck_priorities;
                distancePreferences = toolConfig[0].distance_preferences;
                operationalTimes = toolConfig[0].vehicle_operational_times;
                operationalTimesType = toolConfig[0].vehicle_operational_times_type;
                clubbingLimits = toolConfig[0].clubbing_limits;
                minLoad = toolConfig[0].min_load_preferences
                vehicleAvailability = toolConfig[0].vehicle_availability
                vehicleMinMaxWeight = toolConfig[0].truck_weight_min_max_preferences;
            }
        }




        return(
            <div className="">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
            {/* <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            <span>{Constant.MASTER_VEHICLE_DATA_HEADER_NAME}</span>
                                    <div className='float-right col-sm-4'>
                                    <a href="javascript:;" onClick={this.openModelForPickupDrop} className="f18 float-right themefont fbold mr-10p mb-10p">+ Add pickup/drop locations</a>
                                    
                                    <span className="float-right">
                                    <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">+ Upload pickup/drop Locations Data</a>
                                    </span>
                                    </div>    
                        </h5>
                    </div>
                </div> */}
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12 d-flex">
                <div className= {this.state.showAvailablityTable ? "col-sm-7 " : "col-sm-12 " + "  d-inline"}>
                    
                        <h5 className='fbold d-flex justify-content-between'>
                            <span>{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_HEADER_NAME}</span>
                            {vehicleTypeCnt > this.state.rowData.length ?
                            <div className='float-right'>
                            <a href="javascript:;" onClick={this.openModelForVehicleConfiguration} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
                            </div> 
                            : <div className='float-right'>
                            <a href="javascript:;" data-toggle="tooltip" title="Max-Limit Reached" className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            
                            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
                            </div>}

                        </h5>
                            {truckPriorities ?
                            <span className="float-right">
                            <a href="javascript:;"  onClick={this.handelVehiclePriorityConfigurationModel} className="btn btn-warning f12 mr-10p">Set Vehicle Priority</a>
                            </span>
                            :<div/>}
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                <Select
                                    placeholder={"Select"}
                                    onChange={this.locationChanged}
                                    name="location"
                                    value={this.state.location}
                                    options={this.state.locationNames} 
                                    />

                            </div>
                        </div>

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                        
                    </div>

                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openVehicleConfigurationModel} onClose={this.handelVehicleConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                            <div>
                                <h4>Vehicle Type Configuration</h4>
                            </div>
                    <form className="theme-form" onSubmit={this.formVehicleConfiguration}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_TYPE} </label>
                                    <input required type="text" className="forminp form-control" id="vehicle_type" name="vehicle_type" value={this.state.vehicle_type} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_NAME}</label>
                                    <input required type="text" className="forminp form-control" name="vehicle_name" value={this.state.vehicle_name}
                                     onChange={this.vehicleConfigurationInputFields} 
                                      placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div>
                            

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_CMT_MIN_VOLUME}</label>
                                    <input type="number" min = '0' 
                                    name="min_volume_cmt" id="min_volume_cmt" value={this.state.min_volume_cmt} 
                                    onChange={this.vehicleConfigurationInputFields}  
                                    placeholder="" autoComplete="off"
                                    required = {truck_min_max ? true : false }
                                    className= { truck_min_max ? "forminp form-control"  : "forminp form-control disable-field"}
                                    disabled = {truck_min_max ? false : true } 
                                    />           
                                </div>
                            </div>

                            

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_CMT_MAX_VOLUME}</label>
                                    <input type="number" min = '0' 
                                    name="max_volume_cmt" value={this.state.max_volume_cmt} 
                                    onChange={this.vehicleConfigurationInputFields} autoComplete='off'
                                    required = {truck_min_max ? true : false }
                                    className= { truck_min_max ? "forminp form-control"  : "forminp form-control disable-field"}
                                    disabled = {truck_min_max ? false : true }  
                                    />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label htmlFor="vehicle_priority" className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_PRIORITY}</label>
                                    {/* <Select
                                        placeholder={"Select"}
                                        onChange={this.vehiclePriority}
                                        className={""}
                                        name="vehicle_priority"
                                        value={this.state.vehicle_priority}
                                        options={this.priorityOptions()} 
                                        isDisabled={toolConfig[0].truck_priorities ? false : true}
                                        
                                        /> */}

                                    <select name="vehicle_priority" id="vehicle_priority" value = {this.state.vehicle_priority}
                                        required={truckPriorities ? true : false}
                                        disabled={truckPriorities ? false : true}
                                        className={truckPriorities ? "forminp form-control"  : "forminp form-control disable-field"}
                                        onChange={this.vehicleConfigurationInputFields}>
                                        {/* <option value="" disabled selected>Select</option> */}
                                        <option value="Owned">Owned</option>
                                        <option value="Contract">Contract</option>
                                        <option value="Market">Market</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_DIMENSIONS}</label>
                                    <div className="d-flex justify-content-between number">
                                    <div className="col-md-4 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold">W</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="width" name="vehicle_dimensions_width_mm" value={this.state.vehicle_dimensions_width_mm} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="width" autoComplete="off" />
                                    </div>

                                    <div className="col-md-4 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold">H</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="height" name="vehicle_dimensions_height_mm" value={this.state.vehicle_dimensions_height_mm} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="height" autoComplete="off" />
                                    </div>

                                    <div className="col-md-3 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold">D</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="depth" name="vehicle_dimensions_depth_mm" value={this.state.vehicle_dimensions_depth_mm} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="depth" autoComplete="off" />
                                    </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MIN_WEIGHT} </label>
                                    <input type="number" min = '0' id="vehicle_min_weight" name="vehicle_min_weight" value={this.state.vehicle_min_weight} 
                                    onChange={this.vehicleConfigurationInputFields}
                                    required = {vehicleMinMaxWeight ? true : false }
                                    className= { vehicleMinMaxWeight ? "forminp form-control"  : "forminp form-control disable-field"}
                                    disabled = {vehicleMinMaxWeight ? false : true }
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_WEIGHT} </label>
                                    <input type="number" min = '0' id="vehicle_max_weight" name="vehicle_max_weight" value={this.state.vehicle_max_weight} 
                                    onChange={this.vehicleConfigurationInputFields}
                                    required = {vehicleMinMaxWeight ? true : false }
                                    className= { vehicleMinMaxWeight ? "forminp form-control"  : "forminp form-control disable-field"}
                                    disabled = {vehicleMinMaxWeight ? false : true }
                                     placeholder="" autoComplete="off" />
                                </div>
                            </div>

                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_CAPACITY} </label>
                                    <input required type="number" min = '0' className="forminp form-control" id="vehicle_capacity" name="vehicle_capacity" value={this.state.vehicle_capacity} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="" autoComplete="off" />
                                </div>
                            </div> */}

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DISTANCE}</label>
                                    <input type="number" min = '1' 
                                    required = {distancePreferences ? true : false }
                                    className= { distancePreferences ? "forminp form-control"  : "forminp form-control disable-field"}
                                    placeholder = { distancePreferences ? "Kms" : "Maximum" }
                                    disabled = {distancePreferences ? false : true }
                                    id="max_distance" name="max_distance" value={this.state.max_distance} 
                                    onChange={this.vehicleConfigurationInputFields}
                                    autoComplete="off" />
                                    
                                </div>
                            </div>

                            
                            
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_AVAILABILITY_TIME}</label>
                                    { operationalTimes ? 
                                      operationalTimesType == 1 ? 
                                    <TimeRangePicker  onChange={this.handleAvailabilityTime} value={this.state.vehicle_availability_time} rangeDivider={'to'} 
                                    required={true} 
                                    className =  "forminp form-control vehicle-time"
                                    clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    : operationalTimesType == 2 ? 
                                    <TimeRangePicker  rangeDivider={'to'} value={this.state.vehicle_availability_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    :<TimeRangePicker  rangeDivider={'to'} value={this.state.vehicle_availability_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    :<TimeRangePicker  rangeDivider={'to'} value={this.state.vehicle_availability_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    }
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_BREAK_TIME}</label>
                                    { operationalTimes ? 
                                      operationalTimesType == 1 ? 
                                    <TimeRangePicker  onChange={this.handleBreakTime} value={this.state.break_time} rangeDivider={'to'} 
                                    required={true} 
                                    className =  "forminp form-control vehicle-time"
                                    clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    : operationalTimesType == 2 ? 
                                    <TimeRangePicker  rangeDivider={'to'} value={this.state.break_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    :<TimeRangePicker  rangeDivider={'to'} value={this.state.break_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    :<TimeRangePicker  rangeDivider={'to'} value={this.state.break_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    }
                                </div>
                            </div>
                            

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    {this.state.data_type == 1 ? 
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DELIVERY_INBOUND}</label>
                                    : <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DELIVERY_OUTBOUND}</label>}
                                    <input type="number" min = '1' id="max_no_of_nodes" name="max_no_of_nodes" value={this.state.max_no_of_nodes} 
                                        onChange={this.vehicleConfigurationInputFields}
                                        placeholder= {clubbingLimits ? "" : 'Maximum'} autoComplete="off" 
                                        required = {clubbingLimits ? true : false }
                                        className= { clubbingLimits ? "forminp form-control"  : "forminp form-control disable-field"}
                                        disabled = {clubbingLimits ? false : true } 
                                    />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MIN_LOAD}</label>
                                    <input type="text" id="min_load_per_delivery" name="min_load_per_delivery" value={this.state.min_load_per_delivery} 
                                        onChange={this.vehicleConfigurationInputFields}
                                        placeholder="" autoComplete="off" 
                                        required = {minLoad ? true : false }
                                        className= { minLoad ? "forminp form-control"  : "forminp form-control disable-field"}
                                        disabled = {minLoad ? false : true}
                                     />
                                    
                                </div>
                            </div>
                            {vehicleAvailability == 0 ?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Vehicle Type Count</label>
                                    <input type="number" min = '0' id="vehicle_type_count" name="vehicle_type_count" value={this.state.vehicle_type_count} 
                                        onChange={this.vehicleConfigurationInputFields}
                                        placeholder="" autoComplete="off" 
                                        required = {true}
                                        className=  "forminp form-control"
                                    />
                                    
                                </div>
                            </div>
                            :<div/>}

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelVehicleConfigurationModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                </Modal>

                <Modal closeOnOverlayClick={false} open={this.state.openVehiclePriorityConfigurationModel} onClose={this.handelVehiclePriorityConfigurationModelCancel} 
                    classNames={{modal: 'vehiclepriority-modelClass'}} >
                        <div className="col-sm-12">

                            <div className="mb-10p">
                                <h4>Set Vehicle Priority</h4>
                            </div>
                        <div className="col-md-12 col-sm-12 d-flex justify-content-around">
                            <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">Owned</h5>
                                <div className="form-group col-md-6">
                                    
                                    <select name="vehicle_priority_order_owned" id="vehicle_priority_order_owned" value = {this.state.vehicle_priority_order_owned}
                                        className= "forminp form-control" onChange={this.vehicleConfigurationInputFields}>
                                        
                                        <option value="1">High</option>
                                        <option value="2">Medium</option>
                                        <option value="3">Low</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 ">
                            <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">Contract</h5>
                                <div className="form-group col-md-6">
                                    
                                    <select name="vehicle_priority_order_contract" id="vehicle_priority_order_contract" value = {this.state.vehicle_priority_order_contract}
                                        className= "forminp form-control" onChange={this.vehicleConfigurationInputFields}>
                                        
                                        <option value="1">High</option>
                                        <option value="2">Medium</option>
                                        <option value="3">Low</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 ">
                            <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">Market</h5>
                                <div className="form-group col-md-6">
                                    
                                    <select name="vehicle_priority_order_market" id="vehicle_priority_order_market" value = {this.state.vehicle_priority_order_market}
                                        className= "forminp form-control" onChange={this.vehicleConfigurationInputFields}>
                                        
                                        <option value="1">High</option>
                                        <option value="2">Medium</option>
                                        <option value="3">Low</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group">
                            <button type="button" onClick={this.saveVehiclePriorityOrder} className="btn tbtn float-right save-btn" >SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelVehiclePriorityConfigurationModelCancel}>CANCEL</button>	
                        </div>
                            </div>
                            </Modal>
            </div>
        )
    }
}