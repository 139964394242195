
import React, { Component } from 'react';

 
const GridButton = (props) => {
    
   const handleOnClick = () => {
        props.colDef.params.onClickFunction(props,true);
    };
    var iconName = "fa fa-edit";
    if(props.colDef.params.iconName !=undefined && props.colDef.params.iconName !="")
    {
        iconName = props.colDef.params.iconName;
    }
    var btnClass = "btn btn-warning";
    if(props.colDef.params.iconName !=undefined && props.colDef.params.iconName !="")
    {
        btnClass = props.colDef.params.btnClass;
    }
    var btnColor = {color:"#000",border:"0px"}
    if(props.colDef.params.iconName !=undefined && props.colDef.params.iconName !="")
    {
        btnColor = props.colDef.params.btnColor;
    }
    return (
        <div>
           
            <button className={btnClass+" grid-btn"} onClick={handleOnClick} style={btnColor}>
                <i className={iconName}></i> {props.colDef.params.buttonName}
            </button>
            
        </div>
    );
};

export default GridButton;
