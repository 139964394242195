
import React, { Component } from 'react';

 
const EditItem = (props) => {
    
   const handleOnClick = () => {
        props.context.componentParent.onClickEditForm(props);
    };
    // console.log("props.context ", props)
    return (
        <div>
          <button type="button" onClick={handleOnClick} className="btn btn-danger grid-btn" style={{border:"0px"}}>
                <i className={"fa fa-pencil"}></i> Edit
            </button>
        </div>
    );
};

export default EditItem;
