import React, { createContext, useState } from 'react';
import redirectURL from "./redirectURL"
var moment = require('moment-timezone');

export const UserActivityContext = createContext();

export const UserActivityProvider = ({ children }) => {
  const [userActivityDetails, setUserActivityDetails] = useState({
    username : localStorage.getItem("username"),
    email_id : localStorage.getItem('email'),
    role : localStorage.getItem("role"),
    screen : '',
    sub_screen : '',
    action :'',
    activity : '',
    action_at : ''
  })

  const sendUserActivityToBackend = async (data) => {
    console.log("Send Function Triggred")
    try {
      const response = await redirectURL.post("users/addUserActivity", data);
      if (response.data.status === 'success') {
        console.log('User activity details successfully sent to the backend.');
      }
    } catch (error) {
      console.error('Error sending user activity details:', error);
    }
  };

  const addUserActivityDetails = ({screen, sub_screen, action, activity}) => {
    console.log("Add User Data Function Triggred")
    const updatedDetails = {
      ...userActivityDetails,
      screen,
      sub_screen,
      action,
      activity,
      action_at:moment.tz(new Date(), "Asia/Kolkata")._i
    };

    setUserActivityDetails(updatedDetails);
    sendUserActivityToBackend(updatedDetails);
  }


  return (
    <UserActivityContext.Provider value={{ addUserActivityDetails }}>
      {children}
    </UserActivityContext.Provider>
  );
};