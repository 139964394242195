import React, { Component } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import {BiKey} from "react-icons/bi"
import { FaUserClock } from "react-icons/fa6";
import {AiOutlineLogout} from "react-icons/ai"
import withRouter from "../withRouter";
import $ from "jquery";
import Select from 'react-select';
import redirectURL from '../redirectURL';
class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dispatchDataTypecheck: 0,
            dispatch_data_type: { "label": "Select Data Type", "value": "" },
            showLogoutPopup: false
        }
    }
    componentDidMount() {
        if (localStorage.getItem("module_type") == 1 && localStorage.getItem("both_data_types_enabled_for_dispatch") == 1) {
            var currentUrl = window.location.href;
            if (localStorage.getItem("data_type") == 1) {
                var dataType = "Inbound"
            }
            else {
                var dataType = "Outbound"
            }
            if (currentUrl.includes("viewdispatchplandetails") == true) {
                this.setState({
                    dispatchDataTypecheck: 0
                });
            }
            else {
                this.setState({
                    dispatchDataTypecheck: 1,
                    dispatch_data_type: { "label": dataType, "value": localStorage.getItem("data_type") }
                });
            }
        }
        
    }
    
    logout = async (e) => {
        e.preventDefault();

        await localStorage.removeItem('token');
        await localStorage.removeItem('email');
        await localStorage.removeItem('user_type');
        await localStorage.removeItem('role');
        await localStorage.removeItem('user_code');
        await localStorage.clear();
        sessionStorage.setItem('ref', null);
        //localStorage.setItem('menuText',[]);
        await delete axios.defaults.headers.common['Authorization'];
        this.setState({
            redirectToReferrer: ''
        });
        console.log("logout successfully", localStorage.getItem('token'));

        window.location.reload();
        // await relaodpage(loginType);


    }
    changeDispatchDataType = (event) => {
        if (event.value != undefined && event.value != "") {
            localStorage.setItem("data_type", event.value);
            var url_type = window.location.href
            console.log(url_type, "url_type")
            var path_type = url_type.split('?')
            console.log(path_type, "path_type")
            if (event.value == 1) {
                var pathend = "inbound";
            }
            else {
                var pathend = "outbound";
            }
            // var path = path_type[0]+"?"+pathend;
            var path = "loadprocessrun?" + pathend;
            window.location.href = path;
        }
    }
    dispathTypeOptns = () => {
        var optns = [];
        // optns.push({"label" : "Select Data Type","value" : ""});
        optns.push({ "label": "Inbound", "value": 1 });
        optns.push({ "label": "Outbound", "value": 2 });
        console.log(optns, "optns")
        return optns;
    }
    handleMouseEnter = () => {
        this.setState({
            showLogoutPopup: true,
        });
      };
    
      handleMouseLeave = () => {
        this.setState({
            showLogoutPopup: false,
        });
      };
    
    
    render() {
        // if (localStorage.getItem("token") === "" || localStorage.getItem("token") === undefined || localStorage.getItem("token") === null) {
        //     return <Navigate to={"/login"} push={true} />
        // }
        var moduleTitle = "";
        if (localStorage.getItem("module_type") == 1) {
            moduleTitle = "Dispatch Planning";
        }
        if (localStorage.getItem("module_type") == 2) {
            moduleTitle = "Stacking";
        }
        if (localStorage.getItem("module_type") == 3) {
            moduleTitle = "Production Planning";
        }
        if (localStorage.getItem("module_type") == 4) {
            moduleTitle = "Optipred";
        }
        if (localStorage.getItem("module_type") == 5) {
            moduleTitle = "Resource Scheduling";
        }
        
        console.log(this.state.showLogoutPopup, "showUserPort")
        return (
            <div className="nav menu" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "13px", backgroundColor: "#f0f0f0" }}>
                <div className="nav toggle">
                    <a href={"/"} style={{ fontSize: "20px" }}>
                        <img src={require("../../assets/images/optipred.png")} style={{ paddingBottom: "5px" }} alt="enmovil" />
                    </a>
                </div>
                <div style={{ width: "80%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    {this.state.dispatchDataTypecheck == 1 ?
                        <div className="col-sm-3" style={{ marginRight: "100px" }}>
                            <Select
                                placeholder={"Select"}
                                onChange={this.changeDispatchDataType}
                                name="location"
                                id="selectoptns"
                                className="mt-5p selectoptns"
                                value={this.state.dispatch_data_type}
                                options={this.dispathTypeOptns()}
                            />
                        </div>
                        : ""}
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginRight: "15px" }}>
                        <li className="nav-item" >
                            <img style={{ width: "59px" }} src={require("../../assets/images/cairn-india-logo.png")} alt="cairn-india-logo" />
                        </li>
                        <li className="nav-item logout-icon d-flex align-items-center fw-600" style={{ paddingLeft: "15px", color: "black" }} onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave} >
                            {(localStorage.getItem("username"))}
                            <i className='fa fa-sign-out' style={{ fontSize: "15px", marginLeft: "5px" }}></i>
                            {this.state.showLogoutPopup && (
                
                                <div class="popup">
                                    <ul>
                                        <li style={{display: "flex", alignItems: "center"}}><BiKey style={{fontSize: "20px", marginRight: "5px"}}/><a href="/change-password">Change Password</a></li>
                                        <li style={{display: "flex", alignItems: "center"}}><FaUserClock style={{fontSize: "20px", marginRight: "5px"}}/><a href="/user-actions">User Actions</a></li>
                                        <li style={{display: "flex", alignItems: "center"}}><AiOutlineLogout style={{fontSize: "15px", marginRight: "10px"}}/><a href="/logout">Logout</a></li>
                                    </ul>
                                </div>
                            
                            )}
                            
                        </li>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Navbar);

