
import React, { Component } from 'react';

 
const ViewLearningData = (props) => {
    
   const handleOnClick = () => {
        props.colDef.params.onClickFunction(props,true);
    };
    return (
        <div>
           {(props.data.recent_learning_completed_on !== undefined && props.data.recent_learning_completed_on !== "")?
            <button onClick={handleOnClick} className="btn btn-secondary grid-btn" style={{color:"#fff",border:"0px"}}>
                <i className={"fa fa-eye"}></i> View Analytics
            </button>
            :""}
        </div>
    );
};

export default ViewLearningData;
