
import React, { Component } from 'react';

 
const GridButton = (props) => {
    
   const handleOnClick = () => {
        props.colDef.params.onClickFunction(props,true);
    };
  
    return (
        <div>
           
            <button className="label label-warning" onClick={handleOnClick} style={{color:"#000",border:"0px"}}>
                <i className="fa fa-edit"></i>{props.colDef.params.buttonName}
            </button>
            
        </div>
    );
};

export default GridButton;
