
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

 
const View3DStacking = (props) => {
    
  
    return (
        <div>
            <a href={"/packing3dnew?"+props.data._id}  className="label label-warning"  style={{color:"#000",border:"0px"}}>
                <i className="fa fa-sitemap"></i>View 3D
            </a>
            
        </div>
    );
};

export default View3DStacking;