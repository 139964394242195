
import React, { Component } from 'react';

 
const ViewItems = (props) => {
    
   const handleOnClick = () => {
        props.context.componentParent.onClickViewItems(props);
    };
    // console.log("props.context ", props)
    return (
        <div>
          <button type="button" onClick={handleOnClick} className="btn btn-danger grid-btn" style={{border:"0px"}}>
                <i className={"fa fa-eye"}></i> {props.context.buttons.viewItemBtn}
            </button>
        </div>
    );
};

export default ViewItems;
