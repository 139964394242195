import React, { Component, useState, useEffect , useContext} from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import redirectURL from '../redirectURL';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import CSVFileValidator from 'csv-file-validator';
import { UserActivityContext } from "../context";

const height = 35;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const LearningMaterialListForm = (props) => {
    const { addUserActivityDetails } = useContext(UserActivityContext);
    const options = props.options;
    const durationlist = props.durationlist
    const bestModelsPhaseList = props.bestModelsPhaseList
    const scenarioAndItemCodes = props.scenarioAndItemCodes
    const [material_code, setMaterialCode] = useState([]);
    const [show, setShow] = useState(false);
    const [basicTitle, setBasicTitle] = useState('');
    const [basicType, setBasicType] = useState('default');
    const [duration, setDuration] = useState(null);
    const [upload_type, setUpload_type] = useState(1)
    const [scenarioType, setScenarioType] = useState([]);
    const [last_consumption_date, setLastConsumptionDate] = useState({value: "", label: ""})
    const [phaseList, setPhaseList] = useState([])
    const [email, setEmail] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    

// console.log(bestModelsPhaseList, "1")
    var overlayBlock = "show-n";
    var loadshow = "show-n";
        // This useEffect hook will run after the component renders
       // The empty dependency array means this effect runs only once, similar to componentDidMountS   


    const onChangeMaterial = (newValue) => {
        setMaterialCode(newValue);
    };

    const onChangeDuration = (newValue) => {
        setDuration(newValue);
    }
    const onChangePhase = (newValue) => {
        setLastConsumptionDate(newValue)
    }

    const onChangeScenario = (newValue) => {
        setScenarioType(newValue);
        setMaterialCode(newValue.value)
    }
    const onChangeEmail = (newValue) => {
        setEmail(newValue.target.value)
    }

    const handleUploadType = (e) => {
        setUpload_type(e.target.value)
        
        if(e.target.value == 1 || e.target.value == 3){
            setMaterialCode([]);
            document.getElementById("inValidDataInfo").innerHTML = "";
            document.getElementById("bulkUploadBtn").type = "submit"
            document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
            document.getElementById("bulkUploadBtn").classList.add("btn-danger");
        }
    }

    const onSubmitGetData = async (e) => {
        setIsOpen(true)
        console.log(material_code)
        e.preventDefault();
        let check = 0;
        // console.log(material_code,duration, "material_code-duration");
        if (material_code.length > 0) {
            if(upload_type == 1){
            var materialCodes = material_code.map((mc) => mc.value);
            // if (materialCodes.length > 0) {
                if(materialCodes.indexOf("all") >= 0)
                {
                    var mcodes = [];
                    await options.map((on)=>{
                        if(on.value !="all")
                        {
                            mcodes.push(on.value)
                        }
                    });
                    var materialCodes = $.grep(mcodes, function(value) {
                        return value !== "all";
                    });
                }
                setIsOpen(false)
        }else{
                materialCodes = material_code
            }
                // console.log(materialCodes.length,"materialCodes-length")
                if(duration !=undefined && duration !="")
                {
                    var durationVal = duration.value;
                    if(durationVal !=undefined && durationVal !="")
                    {
                    if (email != undefined && email != ""){
                        check = 1;
                        overlayBlock = "show-m";
                        loadshow = "show-m";
                        const params = {
                            item_code: materialCodes,
                            fc_period: durationVal,
                            scenario_type:scenarioType.value,
                            last_consumption_date: last_consumption_date.value,
                            email: email,
                            sent_email: 0
                        }
                        redirectURL.post('/forecast/predictdata', params)
                        .then(response => {
                            setIsOpen(false)
                            setShow(true);
                            if (response.data.status === "Success") {
                                addUserActivityDetails({screen: "Material Forecast", sub_screen:"-", action:"Trigger Upload", activity:"Upload Triggred"})
                                setBasicTitle(response.data.message); Fore
                                setBasicType('success');
                            } else {
                                setBasicTitle("Something went wrong!!!");
                                setBasicType('danger');
                            }
                            overlayBlock = "show-n";
                            loadshow = "show-n";
                        });
                    }
                }
                }
            
        }
        if (check === 0) {
            setShow(true);
            setIsOpen(false)
            // setBasicTitle("Select the Material and Prediction Horizon to continue");
            setBasicTitle("Please fill in the fields with appropriate Data");
            setBasicType('warning');
        }
    }

    const closeAlert = () => {
        setShow(false);
        if(basicType == "success")
        {
            window.location.reload();
        }
    }

    const templatePath = require('../../assets/json/trigger_forecast_material_data_template.csv');

    const changeCSVFileHandler = async (e) => {
    
        const config = {
            headers: [
                
                { 
                    name: 'Material Code',
                    inputName: 'item_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                 },    
                    
            ]
        }

        
        
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            document.getElementById("inValidDataInfo").innerHTML = "";
            if(csvData.inValidData.length >0){
                document.getElementById("bulkUploadBtn").type = "button"
                document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                let invalidData = csvData.inValidData;
                let element = document.getElementById("inValidDataInfo")
                invalidData.map(item =>{
                    let row,column
                    if(item.rowIndex == undefined){
                        row = 'NA'
                    }else{
                        row = item.rowIndex
                    }
                    if(item.columnIndex == undefined){
                        column = 'NA'
                    }else{
                        column = item.columnIndex
                    }
                    
                    element.innerHTML += "Column : "+column+","+" Row : " + row +"--"+ item.message +"<br></br>"
                })
                 
            }else{
                document.getElementById("bulkUploadBtn").type = "submit"
                document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                document.getElementById("bulkUploadBtn").classList.add("btn-danger");

                let uploadedData = csvData.data;
                const valuesOfMat = uploadedData.map(obj => obj.item_code);
                setMaterialCode(valuesOfMat)
            }
        })
        .catch(err => {})
      }
      const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontWeight: 'bold',
          fontSize: "14px",
          color: "black" // Set font-weight to bold for selected options
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: 'bold', // Always display the selected option in bold
            color: "black"
          }),
      };
console.log(material_code, "material_code")
    return (
        <form method="POST" onSubmit={onSubmitGetData}>
            <div className={"overlay-block "+(overlayBlock)}></div>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            />
            <div className="col-md-12 fw-600" style={{color: "black"}}>
            <div className="form-group ">
                <label className="col-form-label f15 fbold">
                    <input type='radio' value={1} checked={upload_type == 1} onChange={handleUploadType} />&nbsp;&nbsp;Material Multi Select &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <label className="col-form-label f15 fbold">
                    <input type='radio' value={2} checked={upload_type == 2} onChange={handleUploadType} />&nbsp;&nbsp;Material Bulk Upload &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <label className="col-form-label f15 fbold">
                    <input type='radio' value={3} checked={upload_type == 3} onChange={handleUploadType} />&nbsp;&nbsp;Selection of Materials based on Scenarios</label>
                    <br/>

            {upload_type == 1 ?
            <div className="row">
                <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Material</label>
                        <Select 
                            placeholder={"Select"}
                            isMulti={true}
                            onChange={onChangeMaterial}
                            name="material_code"
                            value={material_code} 
                            components={{ MenuList }} 
                            options={options} 
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Prediction Horizon</label>
                        <Select
                            placeholder={"Select"}
                            onChange={onChangeDuration}
                            name="duration"
                            value={duration}
                            options={durationlist}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Select Learning Period</label>
                        <Select
                            placeholder={"Select"}
                            onChange={onChangePhase}
                            name="phase"
                            value={last_consumption_date}
                            options={bestModelsPhaseList}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Email</label>
                        <input type="text" className="form-control" value={email} onChange={onChangeEmail} placeholder="Enter your email"/>
                    </div>
                </div>
                
            </div>
            :''}
            {upload_type == 2 ?
                    <>
                    <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Prediction Horizon</label>
                        <Select
                            placeholder={"Select"}
                            onChange={onChangeDuration}
                            name="duration"
                            value={duration}
                            options={durationlist}
                            styles={customStyles}
                        />
                    </div>
                    </div>
                    <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Select Learning Period</label>
                        <Select
                            placeholder={"Select"}
                            onChange={onChangePhase}
                            name="phase"
                            value={last_consumption_date}
                            options={bestModelsPhaseList}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Email</label>
                        <input type="text" className="form-control" value={email} onChange={onChangeEmail} placeholder="Enter your email"/>
                    </div>
                </div>
                    <div className="form-group col-sm-12 mb-20p">                                
                        <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={changeCSVFileHandler} required />
                    </div>
                    <div className="form-group col-sm-12">
                        <a className="btn btn-warning float-right" href={templatePath} target="_blank">Download Sample Template</a>                            
                    </div>
                    </>
                    :''}
                    {upload_type == 3 ?
                    <>
                    <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Scenario</label>
                        <Select
                            placeholder={"Select"}
                            onChange={onChangeScenario}
                            name="duration"
                            value={scenarioType}
                            options={scenarioAndItemCodes}
                            styles={customStyles}
                        />
                    </div>
                    </div>

                    <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Prediction Horizon</label>
                        <Select
                            placeholder={"Select"}
                            onChange={onChangeDuration}
                            name="duration"
                            value={duration}
                            options={durationlist}
                            styles={customStyles}
                        />
                    </div>
                    </div>
                    <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Select Learning Period</label>
                        <Select
                            placeholder={"Select"}
                            onChange={onChangePhase}
                            name="phase"
                            value={last_consumption_date}
                            options={bestModelsPhaseList}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="form-group ">
                        <label className="col-form-label f15 fbold">Email</label>
                        <input type="text" className="form-control" value={email} onChange={onChangeEmail} placeholder="Enter your email"/>
                    </div>
                </div>
                    </>
                    :''}
                <div className="col-md-7">
                    <div className="form-group ">
                    <button type="submit" id="bulkUploadBtn" className="btn btn-danger mt-30p">Predict Now</button>
                    </div>
                </div>
                {isOpen && (
                    <>
                    <div className={"sliderbg"}></div>
                    
                            <div class="new-loader" style={{ zIndex: "15000" }}>
                                <div class="bar bar1"></div>
                                <div class="bar bar2"></div>
                                <div class="bar bar3"></div>
                                <div class="bar bar4"></div>
                                <div class="bar bar5"></div>
                                <div class="bar bar6"></div>
                                <div class="bar bar7"></div>
                                <div class="bar bar8"></div>
                            </div>
                            <div className="col-12 fw-600 f20" style={{ color: "black", position: "absolute", top: "54%", left: "42%", zIndex: "9999" }}>
                                <p>Loading...</p>
                            </div>
                            </>
                )}
            </div>
            </div>
            <div id="inValidDataInfo" style={{color:"red"}}></div>
            <div className={"loader "+loadshow}></div>
        </form>
    );
};

export default LearningMaterialListForm;
