import React, { Component } from "react";
import axios from 'axios';
import {Navigate} from 'react-router-dom';
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance

class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state={
			isNav:0,
			email:"",
			password:"",
			loginMessage:"",
		}
		this.changeHandler = this.changeHandler.bind(this);
    }

    async componentDidMount() {
        var main_token = window.location.pathname.split("/");
		const token = main_token[main_token.length-1]
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var usrtoken = ppwwdd(token)
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            if (response.data.status === "success") {
				console.log("login ", response.data)
                var params = {
					e:response.data.user[0].email,
					d:response.data.user[0].password_txt
				}
				redirectURL.post("/verify",params)
				.then((response) => {
					if(response.data.status == "success")
					{
						console.log("login ", response.data)
						localStorage.setItem("email",response.data.user[0].email);
						localStorage.setItem("firstname",response.data.user[0].firstname);
						localStorage.setItem("lastname",response.data.user[0].lastname);
						localStorage.setItem("username",response.data.user[0].username);
						localStorage.setItem("token",response.data.token);
						localStorage.setItem("user_type",response.data.user[0].user_type);
						localStorage.setItem("role",response.data.user[0].role);
						localStorage.setItem("user_code",response.data.user[0].user_code);
						this.setState({
							isNav : 1
						})
					}
					else
					{
						this.setState({
							loginMessage : response.data.message
						})
					}
				})
            }else
			{
				this.setState({
					loginMessage : response.data.msg
				})
			}
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }
	changeHandler(event){
		var name = event.target.name;
		var value = event.target.value;
		this.setState({
			[name]:value
		})
	}
	cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
	formSubmit(event){
		event.preventDefault();
		if(this.state.email !=undefined && this.state.email !="" && this.state.password !=undefined && this.state.password !="")
		{
			var params = {
				e:this.state.email,
				d:this.state.password
			}
			redirectURL.post("/verify",params)
			.then((response) => {
				if(response.data.status == "success")
				{
					console.log("login ", response.data)
					localStorage.setItem("email",response.data.user[0].email);
					localStorage.setItem("firstname",response.data.user[0].firstname);
					localStorage.setItem("lastname",response.data.user[0].lastname);
					localStorage.setItem("username",response.data.user[0].username);
					localStorage.setItem("token",response.data.token);
					localStorage.setItem("user_type",response.data.user[0].user_type);
					localStorage.setItem("role",response.data.user[0].role);
					localStorage.setItem("user_code",response.data.user[0].user_code);
					this.setState({
						isNav : 1
					})
				}
				else
				{
					this.setState({
						loginMessage : response.data.message
					})
				}
			})
		}
		else
		{
			this.setState({
				loginMessage : "Enter Email and Password"
			})
		}
	}
    render() {
		console.log(this.state.isNav, "isNav")
		if(this.state.isNav === 1)
		{
			// return <Navigate  to={"/dashboard"} push={true} />
			window.location.href = "/dashboard"
		}
        return (
            <div className='container-fluid container-bg'>
				<div className='row'>
					<div className='col-sm-5'>
						<div className='app-logo'>
							<img src={require("../../assets/images/enmovil.png")} />
						</div>
						<div className='form-body'>
							<form className="loginForm" onSubmit={this.formSubmit.bind(this)}>
								<h4>Login</h4>
								{(this.state.loginMessage)?
								<div className="alert alert-danger">{this.state.loginMessage}</div>
								:""}
								<div className="form-group" style={{marginTop: "2em"}}>
									<input type="email" className="form-control form-control-user" id="exampleInputEmail" name="email" onChange={this.changeHandler} aria-describedby="emailHelp" placeholder="Enter Email Address..." autocomplete="off" required/>
								</div>
								<div className="form-group">
									<input type="password" className="form-control form-control-password" id="exampleInputPassword" name="password" onChange={this.changeHandler} placeholder="Password" autocomplete="off" required/>
								</div>
								<div className="form-group txt-center mt-20p">
									<button type="submit" className="btn btn-user">
										Login
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

export default EnmUserLogin;