import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getStackingToolConfiguration from "./commonFunctions";

export default class MaterialDetails extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:'',
            material_code:'',
            material_name:'',
            material_type:'',
            material_weight:'',
            material_dimensions_width_mm:'',
            material_dimensions_depth_mm:'',
            material_dimensions_height_mm:'',
            rowId:'',
            locationNames:[],
            fragility_index:0
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'itemstacking'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getStackingToolConfiguration(params)
        await this.getStackingPlantWarehouseLocationsData(params)
        await this.getStackingMaterialData(params)
    }

    getStackingMaterialData = (params) =>{
        redirectURL.post('stacking/getStackingMaterialData',params)
        .then(response =>{
            if(response.data.status == 'success'){

                this.setState({
                    rowData:response.data.materialData
                })
            }
        })
    }

    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            material_code:'',
            material_name:'',
            material_type:'',
            material_weight:'',
            material_dimensions_width_mm:'',
            material_dimensions_depth_mm:'',
            material_dimensions_height_mm:'',
            rowId:'',
            fragility_index:0
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    
    materialDetailsInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }

    getStackingPlantWarehouseLocationsData = async(param) => {

        let locationNames = this.state.locationNames;
        await redirectURL.post('stacking/getStackingPlantWarehouseLocationsData',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.plantWarehouseLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                          locationNames.push({ value : item.location_code,
                              label : item.location_name })
                      })
              await this.setState({
                          locationNames : locationNames,
                          location : locationNames[0]
                      })
                  }
              }
          })
          
      }

    saveStackingMaterialDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('stacking/saveStackingMaterialDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getStackingMaterialData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        material_code:'',
                        material_name:'',
                        material_type:'',
                        material_weight:'',
                        material_dimensions_width_mm:'',
                        material_dimensions_depth_mm:'',
                        material_dimensions_height_mm:'',
                        fragility_index:0
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    updateStackingMaterialDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('stacking/updateStackingMaterialDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getStackingMaterialData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        material_code:'',
                        material_name:'',
                        material_type:'',
                        material_weight:'',
                        material_dimensions_width_mm:'',
                        material_dimensions_depth_mm:'',
                        material_dimensions_height_mm:'',
                        rowId:'',
                        fragility_index:0
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    formMaterialDetails = (e)=>{
        e.preventDefault();
        let material_code = this.state.material_code;
        let material_name = this.state.material_name;
        let material_type = this.state.material_type;
        let material_weight = this.state.material_weight;
        let material_dimensions_depth_mm = this.state.material_dimensions_depth_mm;
        let material_dimensions_width_mm = this.state.material_dimensions_width_mm;
        let material_dimensions_height_mm = this.state.material_dimensions_height_mm;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let fragility_index = this.state.fragility_index
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let rowId = this.state.rowId;
        let params = {
            material_code : material_code,
            material_name : material_name,
            material_type : material_type,
            material_weight : parseFloat(material_weight),
            material_dimensions_width_mm : parseFloat(material_dimensions_width_mm),
            material_dimensions_depth_mm : parseFloat(material_dimensions_depth_mm),
            material_dimensions_height_mm : parseFloat(material_dimensions_height_mm),
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            fragility_index : parseInt(fragility_index)
        }

        if(rowId != ''){

            params.material_id = rowId
            
                this.updateStackingMaterialDetails(params)
            

        }else{
            if(location.value != null){
                this.saveStackingMaterialDetails(params)
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
            }
        }
    }

    editMaterialConfiguration = async(propdata) =>{
        let data = propdata.data
        await this.setState({
            "material_code" : data.material_code,
            "material_name" : data.material_name,
            "material_type" : data.material_type,
            "material_weight" : data.material_weight,
            "material_dimensions_width_mm" : data.material_dimensions_width_mm,
            "material_dimensions_depth_mm" : data.material_dimensions_depth_mm,
            "material_dimensions_height_mm" : data.material_dimensions_height_mm,
            "fragility_index" : data.fragility_index,
            "rowId" : data.material_id
        })
        this.openModelForMaterialConfiguration()
    }

    deleteStackingMaterialDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('stacking/deleteStackingMaterialDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getStackingMaterialData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }


    render(){

        var colsDefs = [
            {
                headerName:Constant.MATERIAL_CODE,
                field:"material_code",
                width:"120"
            },
            {
                headerName:Constant.MATERIAL_NAME,
                field:"material_name",
                width:"130"
            },
            {
                headerName:Constant.MATERIAL_TYPE,
                field:"material_type",
                width:"130"
            },
            {
                headerName:Constant.MATERIAL_WEIGHT,
                field:"material_weight",
                width:"150",
            },
            {
                headerName:Constant.MATERIAL_DIMENSIONS_DEPTH,
                field:"material_dimensions_depth_mm",
                width:"200"
            },
            {
                headerName:Constant.MATERIAL_DIMENSIONS_WIDTH,
                field:"material_dimensions_width_mm",
                width:"200"
            },
            {
                headerName:Constant.MATERIAL_DIMENSIONS_HEIGHT,
                field:"material_dimensions_height_mm",
                width:"200"
            },
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Edit',
                        onClickFunction:this.editMaterialConfiguration},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteStackingMaterialDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
        ]

        let fregility_index_preferences = 0;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))

        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                fregility_index_preferences = toolConfig[0].fregility_index_preferences;
            }
        }
        let data = this.state.rowData
        let locValue = this.state.location.value
         
        let filteredData = data.filter(item => item.location_code == locValue)

        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12 d-flex">
                <div className= {this.state.showAvailablityTable ? "col-sm-7 " : "col-sm-12 " + "  d-inline"}>
                    
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Material Configurations</span>
                                <div className='float-right'>
                                    <a href="javascript:;" onClick={this.openModelForMaterialConfiguration} className="f18 float-right themefont fbold mr-10p mb-10p">Add Material Info</a>
                                </div> 

                        </h5>
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.locationChanged}
                                        name="location"
                                        value={this.state.location}
                                        options={this.state.locationNames} />

                                </div>
                            </div>

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                        
                    </div>

                    {/* { this.state.showAvailablityTable ?
                    <div className='col-sm-5 d-inline'>
                    
                    <h5 className='fbold white d-flex justify-content-between'>
                            <span>{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_HEADER_NAME}</span>
                            <div className='float-right'>
                            <a href="javascript:;" onClick={this.openModelForVehicleAvailability} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                             */}
                            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
                            {/* </div>    
                        </h5>
                        
                        <VehiclesAvailability
                        availabilityData = {this.state.availabilityData}/>
                        <span className="float-right">
                            <button onClick={this.hideVehicleAvailabilityTable} className="btn btn-warning f12 mr-15p mt-5p">Close</button>
                            </span>
                    </div>
                    :<div/>} */}
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openMaterialConfigurationModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                    <form className="theme-form" onSubmit={this.formMaterialDetails}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MATERIAL_CODE} </label>
                                    <input required type="text" className="forminp form-control" id="material_code" name="material_code" value={this.state.material_code} 
                                    onChange={this.materialDetailsInputFields}
                                    placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MATERIAL_NAME} </label>
                                    <input required type="text" className="forminp form-control" id="material_name" name="material_name" value={this.state.material_name} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MATERIAL_TYPE} </label>
                                    <input required type="text" className="forminp form-control" id="material_type" name="material_type" value={this.state.material_type} 
                                    onChange={this.materialDetailsInputFields}
                                    placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MATERIAL_WEIGHT} </label>
                                    <input required type="number" min='0' className="forminp form-control" id="material_weight" name="material_weight" value={this.state.material_weight} 
                                    onChange={this.materialDetailsInputFields}
                                    placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                            {fregility_index_preferences == 1 ?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Fragility Index </label>
                                    <input type="number" min='0' id="fragility_index" name="fragility_index" value={this.state.fragility_index} 
                                    onChange={this.materialDetailsInputFields}
                                    required
                                    className= "forminp form-control"
                                    placeholder="" autoComplete="off" />                               
                                </div>
                            </div>
                            :<div/>}

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MATERIAL_DIMENSIONS}</label>
                                    <div className="d-flex justify-content-between number">
                                    <div className="col-md-4 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold pd-rt-6">W</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="width" name="material_dimensions_width_mm" value={this.state.material_dimensions_width_mm} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="width" autoComplete="off" />
                                    </div>

                                    <div className="col-md-4 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold pd-rt-6">H</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="height" name="material_dimensions_height_mm" value={this.state.material_dimensions_height_mm} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="height" autoComplete="off" />
                                    </div>

                                    <div className="col-md-3 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold pd-rt-6">D</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="depth" name="material_dimensions_depth_mm" value={this.state.material_dimensions_depth_mm} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="depth" autoComplete="off" />
                                    </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                    </Modal>
            </>
        )
    }
}