import React,{Component} from "react";
import Constant from "../common/constants";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import getToolConfiguration from "./commonFunctions";
import FixedVehicleCost from './vehicleFixedCost';
import VehicleCityLevelCost from './vehicleCityLevelVariableCost';
import VehicleLevelCost from './vehicleLevelVariableCost';



export default class VehicleCostConfigurations extends Component{
    constructor(props){
        super(props);
        this.state={
            locationNames:[],
            location:'',
            fixedCostTab:"",
            vehicleLevelCostTab : "",
            cityWiseCostTab : "",
            fixedCostData: false,
            vehicleLevelCostData:false,
            cityWiseCostData:false,
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})

        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        var plant_wise_freight_fixed_cost = 0;
        var plant_wise_freight_vehicle_type_variable_cost = 0;
        var plant_wise_freight_city_level_vehicle_cost = 0;

        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                plant_wise_freight_fixed_cost = toolConfig[0].plant_wise_freight_fixed_cost;
                plant_wise_freight_vehicle_type_variable_cost = toolConfig[0].plant_wise_freight_vehicle_type_variable_cost;
                plant_wise_freight_city_level_vehicle_cost = toolConfig[0].plant_wise_freight_city_level_vehicle_cost;
            }
    
            if(plant_wise_freight_fixed_cost == 1)
            {
                this.setState({fixedCostTab : "activet",
                              fixedCostData:true})
            }
            else if(plant_wise_freight_vehicle_type_variable_cost == 1)
            {
                this.setState({vehicleLevelCostTab : "activet",
                                vehicleLevelCostData:true})
            }
            else if(plant_wise_freight_city_level_vehicle_cost == 1)
            {
                this.setState({cityWiseCostTab : "activet",
                                cityWiseCostData:true})
            }
        }
        await this.boundTypeLocations(params)
    }

    boundTypeLocations = async(param) => {

        let locationNames = this.state.locationNames;
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                          locationNames.push({ value : item.location_code,
                              label : item.location_name })
                      })
                      await this.setState({
                          locationNames : locationNames,
                          location : locationNames[0]
                      })
                  }
              }
          })
          
      }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }


    onClickTab = (pageType) => {

        if(pageType == "fixedCostData")
        {
            var fixedCostTab = "activet";
            var cityWiseCostTab = "";
            var vehicleLevelCostTab = "";
            var fixedCostData = true;
            var vehicleLevelCostData = false;
            var cityWiseCostData = false;
            
        }
        else if(pageType == "vehicleLevelCostData")
        {

            var fixedCostTab = "";
            var vehicleLevelCostTab = "activet";
            var cityWiseCostTab = "";
            var fixedCostData = false;
            var vehicleLevelCostData = true;
            var cityWiseCostData = false;
        }
        else if(pageType == "cityWiseCostData")
        {
            var fixedCostTab = "";
            var vehicleLevelCostTab = "";
            var cityWiseCostTab = "activet";
            var fixedCostData = false;
            var vehicleLevelCostData = false;
            var cityWiseCostData = true;
        }
        this.setState({
            fixedCostTab:fixedCostTab,
            vehicleLevelCostTab:vehicleLevelCostTab,
            cityWiseCostTab:cityWiseCostTab,
            fixedCostData:fixedCostData,
            vehicleLevelCostData:vehicleLevelCostData,
            cityWiseCostData:cityWiseCostData,
            
        })
    }


    render(){

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        var plant_wise_freight_configuration = 0;
        var plant_wise_freight_fixed_cost = 0;
        var plant_wise_freight_vehicle_type_variable_cost = 0;
        var plant_wise_freight_city_level_vehicle_cost = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                plant_wise_freight_configuration = toolConfig[0].plant_wise_freight_configuration
                plant_wise_freight_fixed_cost = toolConfig[0].plant_wise_freight_fixed_cost;
                plant_wise_freight_vehicle_type_variable_cost = toolConfig[0].plant_wise_freight_vehicle_type_variable_cost;
                plant_wise_freight_city_level_vehicle_cost = toolConfig[0].plant_wise_freight_city_level_vehicle_cost;
            }
        }
        const {location,locationNames,fixedCostData,vehicleLevelCostData,cityWiseCostData,
        fixedCostTab,vehicleLevelCostTab,cityWiseCostTab}=this.state
        return(
            <div className="">
            <div className="row mb-20p pt-20p">
                    <div className="col-sm-12">
                        
                            <h5 className='fbold  d-flex justify-content-between'>
                                <span>Vehicle Cost Configurations</span>
                            </h5>
                        
                    </div>
                </div> 
                    <div className="col-md-4 col-sm-12">
                        <div className="form-group ">
                            <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={location}
                                options={locationNames} />

                        </div>
                    </div>

                            <div className="col-md-12 col-sm-12">
                                <ul className="d-tabs">
                                    {plant_wise_freight_fixed_cost == 1 ?
                                    <li onClick={this.onClickTab.bind(this,"fixedCostData")} className={"lttabs "+(fixedCostTab)}>
                                        <button type="button" className="btn">Fixed Vehicle Cost</button>
                                    </li>
                                    :''}

                                    {plant_wise_freight_vehicle_type_variable_cost == 1 ?
                                    <li onClick={this.onClickTab.bind(this,"vehicleLevelCostData")} className={"lttabs "+(vehicleLevelCostTab)}>
                                        <button type="button" className="btn">Variable Vehicle Cost</button>
                                    </li>
                                    :''}

                                    {plant_wise_freight_city_level_vehicle_cost == 1 ?
                                    <li onClick={this.onClickTab.bind(this,"cityWiseCostData")} className={"lttabs "+(cityWiseCostTab)}>
                                        <button type="button" className="btn">City Wise Vehicle Cost</button>
                                    </li>
                                    :''}

                                </ul>
                            </div>

                    {fixedCostData ?  location != '' ? <FixedVehicleCost plantLocation={location}/> :'': ''}
                    {vehicleLevelCostData ? location != '' ? <VehicleLevelCost plantLocation={location}/> :'': ''}
                    {cityWiseCostData ? location != '' ? <VehicleCityLevelCost plantLocation={location}/> :'': ''}
            </div> 
        )
    }
}