// HighChartComponent.js
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const HighChartComponent = ({ plotData }) => {
    console.log(plotData, 'plotData7');
    
    // Process data to group quantities by financial year
    const dataByYear = plotData.reduce((acc, item) => {
        if (acc[item.fy]) {
            acc[item.fy] += item.quantity;
        } else {
            acc[item.fy] = item.quantity;
        }
        return acc;
    }, {});

    // Prepare data for Highcharts
    const categories = Object.keys(dataByYear); // Financial years
    const quantities = Object.values(dataByYear); // Total quantities per year

    const options = {
        chart: {
            type: 'line',
            width: null,
        },
        title: {
            text: 'Quantity per Financial Year'
        },
        xAxis: {
            categories: categories,
            title: {
                text: 'Financial Year'
            },
            labels: {
                rotation: -45, // Rotate labels for better readability
                style: {
                    fontSize: '13px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Total Quantity'
            }
        },
        series: [{
            name: 'Quantity',
            data: quantities
        }],
        tooltip: {
            headerFormat: '<b>{point.key}</b><br>',
            pointFormat: 'Total Quantity: {point.y}'
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500 // Adjust this based on your needs
                },
                chartOptions: {
                    xAxis: {
                        labels: {
                            rotation: -90,
                            align: 'right',
                            style: {
                                fontSize: '10px'
                            }
                        }
                    }
                }
            }]
        }
    };

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default HighChartComponent;
