import React, {Component} from 'react';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from "jquery";
import CSVFileValidator from 'csv-file-validator'
import DatePicker from 'react-datepicker';
import Modal from "react-responsive-modal";
// import Dashboard from "../dashboard/dealerMapComponent";
import "react-datepicker/dist/react-datepicker.css";
import Constant from "../common/constants";

import redirectURL from '../redirectURL';

var moment = require("moment");

export default class DiscussionBox extends Component {
    constructor(props){
        super(props);
        this.state={
          overlayBlock:"show-n",
          alert:null,
          show: false,
          basicTitle:'',
          basicType:"default",
          open: false,
          showloader:"show-n"
        }
    }
    componentWillMount(){
        
       
    }
    onClickCloseChatBox(){

    }
    sendChatBoxData(){

    }
    render() {
        
        const { open } = this.state;
        return (
            <div className="discussBox">
            {/* <div className="chat-discussion-module">
                <div className="chat-box-layout">
                    <div className="chat-box">
                        <div className="chat-box-header" id="chatBoxHeader-1">
                        <h4>User-1
                            <span className="float-right" onClick={this.onClickCloseChatBox.bind(this)}>
                                <i className="fa fa-close"></i>
                            </span>
                        </h4>
                        </div>
                        <div className="chat-box-body" id="chatBoxBody-1">
                        <div className="chat-box-even" id="chatBoxBody-1">
                            <div className="row">
                                <div className="col-sm-2">
                                <img src={require("../../assets/images/user/user.png")} className="chat-box-user" />
                                </div>
                                <div className="col-sm-10">
                                    Testing message
                                </div>
                            </div>
                        </div>
                        <div className="chat-box-odd" id="chatBoxBody-1">
                            <div className="row">
                                <div className="col-sm-2">
                                <img src={require("../../assets/images/user/user.png")} className="chat-box-user" />
                                </div>
                                <div className="col-sm-10">
                                    123 Testing message http://localhost:3000/forecast-predict
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="chat-box-input" id="chatBoxInput-1">
                        <form id="formChat">
                            <div className="col-sm-12">
                            <div className="row">
                                <textarea className="col-sm-10 chat-box-textarea" rows="1" id="chatBoxTextarea-1"></textarea>
                                <div className="col-sm-2">
                                <button type="button" className="btn btn-success" id="chatBtn-1" onClick={this.sendChatBoxData.bind(this,1)}>Send</button>
                                </div>
                            </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="row no-gutters bshadow">
                <div className="col-md-4 border-right chatlist">
                    <div className="settings-tray">
                    {/* <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="Profile img" /> */}
                    <h5 style={{height:"43px",padding:"15px"}}>User List</h5>
                    <span className="settings-tray--left">
                        {/* <i className="fa fa-refresh"></i>
                        <i className="fa fa-commenting"></i>
                        <i className="fa fa-bars"></i> */}
                        
                    </span>
                    </div>
                    <div className="search-box">
                    <div className="input-wrapper">
                        <i className="material-icons">search</i>
                        <input placeholder="Search here" className="chatInp" type="text" />
                    </div>
                    </div>
                    <div className="friend-drawer friend-drawer--onhover">
                    <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="" />
                    <div className="text">
                        <h6>User Demo</h6>
                        <p className="text-muted">Admin</p>
                    </div>
                    <span className="time text-muted small">13:21</span>
                    </div>
                    <hr />
                    <div className="friend-drawer friend-drawer--onhover">
                    <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="" />
                    <div className="text">
                        <h6>User Demo-1</h6>
                        <p className="text-muted">Manager</p>
                    </div>
                    <span className="time text-muted small">00:32</span>
                    </div>
                    <hr />
                    <div className="friend-drawer friend-drawer--onhover ">
                    <img className="profile-image" src={require("../../assets/images/user/user.png")}alt="" />
                    <div className="text">
                        <h6>User Demo-2</h6>
                        <p className="text-muted">Executive</p>
                    </div>
                    <span className="time text-muted small">13:21</span>
                    </div>
                    <hr />
                    <div className="friend-drawer friend-drawer--onhover">
                    <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="" />
                    <div className="text">
                        <h6>User Demo-3</h6>
                        <p className="text-muted">Team Lead</p>
                    </div>
                    <span className="time text-muted small">13:21</span>
                    </div>
                    <hr />
                    <div className="friend-drawer friend-drawer--onhover">
                    <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="" />
                    <div className="text">
                        <h6>User Demo-4</h6>
                        <p className="text-muted">Testing</p>
                    </div>
                    <span className="time text-muted small">13:21</span>
                    </div>
                    
                </div>
                <div className="col-md-8 chatlist">
                    <div className="settings-tray">
                        <div className="friend-drawer no-gutters friend-drawer--grey">
                        <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="" />
                        <div className="text">
                        <h6>User Demo</h6>
                        <p className="text-muted">Sending message</p>
                        </div>
                        <span className="settings-tray--right">
                            <i className="fa fa-refresh"></i>
                            <i className="fa fa-commenting"></i>
                            <i className="fa fa-bars"></i>
                        </span>
                    </div>
                    </div>
                    <div className="chat-panel">
                        <div className="row no-gutters">
                            <div className="col-md-1 tc" >
                                <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="Profile img" />
                            </div>
                            <div className="col-md-10">
                                <div className="chat-bubble chat-bubble--left">
                                    Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-8 tcright offset-md-2">
                                <div className="chat-bubble chat-bubble--right">
                                    
                                Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1
                                </div>
                            </div>
                            <div className="col-md-1 tc">
                                <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="Profile img" />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-8  tcright offset-md-2">
                                <div className="chat-bubble chat-bubble--right">
                                    
                                Testing message from user demo 1 Testing message from user demo 1
                                </div>
                            </div>
                            <div className="col-md-1 tc">
                                <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="Profile img" />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-1 tc">
                                <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="Profile img" />
                            </div>
                            <div className="col-md-10">
                                <div className="chat-bubble chat-bubble--left">
                                    Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-1 tc">
                                <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="Profile img" />
                            </div>
                            <div className="col-md-10">
                            <div className="chat-bubble chat-bubble--left">
                                Hello Testing!
                            </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-1 tc">
                                <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="Profile img" />
                            </div>
                            <div className="col-md-10">
                                <div className="chat-bubble chat-bubble--left">
                                    Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1 Testing message from user demo 1
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-8 tcright offset-md-2">
                                <div className="chat-bubble chat-bubble--right">
                                    Hello dude!
                                </div>
                            </div>
                            
                            <div className="col-md-1 tc">
                                <img className="profile-image" src={require("../../assets/images/user/user.png")} alt="Profile img" />
                            </div>
                        </div>
                    </div>
                    <div className="chat-ps">
                        <div className="col-12">
                        <div className="chat-box-tray">
                            {/* <i className="material-icons">sentiment_very_satisfied</i> */}
                            <input type="text" className="chatInp" placeholder="Type your message here..." />
                            {/* <i className="material-icons">mic</i> */}
                            <i className="fa fa-send"></i>
                        </div>
                        </div>
                    </div>
                
                </div>
                </div>

                
            </div>
        )
    }
}