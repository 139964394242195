import React,{Component} from 'react';



const tabs = [{label:"Pickup/Drop locations",value:"pickup/Drop locations",code:"pickup_and_drop"},{label:"Vehicles Data",value:"Vehicles Data",code:"vehicles_data"},
{label:"Vehicle Types",value:"Vehicle Types",code:"vehicle_type"},{label:"Material Configuration",value:"Material Configuration",code:"Material_Config"}]
export default class OptiRunAdmin extends Component {



    render(){
        return(
                <div className='container-fluid'>
                        <div className="row">

                    {tabs.map((item,idx) => 
                        <div className="col-sm-3 mb-40p f14 beffect fdeffect ">
                          
                            <div className="card fcls-a">
                                <a href={"/" + item.code }>
                                <div className={"card-body card-color-" +idx} style={{background:"transparent"}} >
                                    <h4 className="f15 fbold fclstxt">{item.value}
                                    </h4>
                                </div>
                                </a>
                            </div>
                        </div>
                     )}
                </div>

                </div>

        )
        }
};