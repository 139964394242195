import React, {Component} from 'react';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from "jquery";
import CSVFileValidator from 'csv-file-validator'
import DatePicker from 'react-datepicker';
import Modal from "react-responsive-modal";
// import Dashboard from "../dashboard/dealerMapComponent";
import "react-datepicker/dist/react-datepicker.css";
import Constant from "../common/constants";

import redirectURL from '../redirectURL';
var infoBox = require('../common/google-infowindow');

var moment = require("moment");
var map;
var overlay;
var infowindows=[];
var markersArraylist=[];

var iconWidth = 64;
var iconHeight = 64;
var marker;
var markers = [];

export default class Dealers extends Component {
    constructor(props){
        super(props);
        this.state={
          showSlideBlock:"",
          showSlideBlockUpload:"",
          showSlideBlockUploadParts:"",
          showSlideBlockDealers:"",
          overlayBlock:"show-n",
          dealer_address:"",
          dealer_city:"",
          dealer_code:"",
          dealer_lat:"",
          dealer_lng:"",
          dealer_name:"",
          dealer_pincode:"",
          dealer_state:{value:"",label:"Select"},
          dealer_plant:{value:"",label:"Select"},
          dealer_start_time:"",
          dealer_end_time:"",
          start_time:"",
          end_time:"",
          statelist:[],
          dealerslist:[],
          alert:null,
          show: false,
          basicTitle:'',
          basicType:"default",
          open: false,
          dealermodal:[],
          plantlist:[],
          searchKeyword:"",
          originalData:[],
          uploadfile:'',
          csvfiledata:[],
          csvfile:'',
          showloader:"show-n"
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.loadDealers = this.loadDealers.bind(this);
        this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
        this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.changeCSVFileHandler = this.changeCSVFileHandler.bind(this);
      }
      componentWillMount(){
        this.setState({
          showloader:"show-m"
        })
        var dd= "13:40";
        var m =moment.parseZone().format("YYYY, MM, DD")
        console.log("dddd ", new Date(m, 13,40));
        redirectURL.post("/dealers/states")
        .then((response) => {
          var records = response.data;
          records = records.sort(GetAscSortOrder("state"));
          this.setState({
            statelist:records
          });
        })
        
        redirectURL.post("/dealers/plants")
        .then((response) => {
          var records = response.data;
          records = records.sort(GetAscSortOrder("plant_name"));
          this.setState({
            plantlist:records
          });
        })
        this.loadDealers();
      }
      loadDealers(){
        redirectURL.post("/dealers/list")
        .then((response) => {
          var records = response.data;
          
          var c = moment.parseZone().format("YYYY-MM-DD");
          var recordsarr = [];
          if(records.length > 0){
            records.map((item) => {
              var s = item.start_time.split(":");
              var endt = item.end_time.split(":");
              var st = moment.parseZone(new Date(c+" "+s[0]+":"+s[1])).format("HH:mm A");
              var et = moment.parseZone(new Date(c+" "+endt[0]+":"+endt[1])).format("HH:mm A")
              item.stime = st;
              item.etime = et;
              recordsarr.push(item);
            })
          }
          this.setState({
            dealerslist:recordsarr,
            originalData:recordsarr,
            showloader:"show-n"
          })
        });
      }
      componentDidMount(){
        this.renderMap();
      }
      onClickAddDealer(){
        this.setState({
          showSlideBlock:"slide90",
          overlayBlock:"show-m"
        })
      }
      changeHandler(event){
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
          [name]:value
        })
      }
      changeState(dealer_state){
        this.setState({dealer_state})
      }
      changePlant(dealer_plant){
        this.setState({dealer_plant})
      }
      statelist(){
        var statelist = this.state.statelist;
        var items = []
        items.push({
          value:"",
          label:"Select State"
        })
        if(statelist.length > 0)
        {
          items = statelist.map((item) => {
           return  {
              value:item.state_code,
              label:item.state
            };
          })
        }
        return items;
      }
      plantlist(){
        var plantlist = this.state.plantlist;
        var items = []
        items.push({
          value:"",
          label:"Select Plant"
        })
        if(plantlist.length > 0)
        {
          items = plantlist.map((item) => {
           return  {
              value:item.plant_code,
              label:item.plant_name
            };
          })
        }
        return items;
      }
      toggleBounce() {
            if (marker.getAnimation() !== null) {
              marker.setAnimation(null);
            } else {
              marker.setAnimation(window.google.maps.Animation.BOUNCE);
            }
            
      }
     
    renderMap = () => {
      loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing,geometry&callback=initMap");
        window.initMap = this.initMap
    }
    
    initMap = () => 
    {
        var lt=17.432143; 
        var ln=78.374427;
        
        this.setState({
            dealerCode:[]
        });
        var mapOptions = {
            zoom: 11,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels:true,
            mapTypeControlOptions: {
                mapTypeIds: ['satellite', 'roadmap'],
            },
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            styles: [
                    
                // {
                //     "featureType": "administrative",
                //     "elementType": "labels.text.fill",
                //     "stylers": [
                //         {
                //             "color": "#444444"
                //         }
                //     ]
                // },
                // {
                //     "featureType": "landscape",
                //     "elementType": "all",
                //     "stylers": [
                //         {
                //             "color": "#eeeeee"
                //         }
                //     ]
                // },
                // {
                //     "featureType": "road",
                //     "elementType": "all",
                //     "stylers": [
                //         {
                //             "saturation": -100
                //         },
                //         {
                //             "lightness": 45
                //         }
                //     ]
                // },
                // {
                //     "featureType": "road.highway",
                //     "elementType": "all",
                //     "stylers": [
                //         {
                //             "visibility": "simplified"
                //         }
                //     ]
                // },
                // {
                //     "featureType": "road.arterial",
                //     "elementType": "labels.icon",
                //     "stylers": [
                //         {
                //             "visibility": "off"
                //         }
                //     ]
                // },
                // {
                //     "featureType": "transit",
                //     "elementType": "all",
                //     "stylers": [
                //         {
                //             "visibility": "off"
                //         }
                //     ]
                // },
            ],
        };
        try{
            map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        }catch(e){}
        
        
        var bounds = new window.google.maps.LatLngBounds();
        this.maploadData();
        
        
        overlay = new window.google.maps.OverlayView();
        overlay.draw = function() {};
        overlay.setMap(map);
		// map.fitBounds(bounds)
	 
        window.google.maps.event.addListener(map, 'click', (function() {
            closeAllInfoWindows()
        }))
    }

    maploadData(){
        for (let i = 0; i < markersArraylist.length; i++) {
            markersArraylist[i].setMap(null);
        }
        
        var plants = this.state.plantlist;
        var dealers = this.state.dealerslist;
        try{
            if(dealers.length > 0)
            {
                var lt=dealers[0].latitude; 
                var ln=dealers[0].longitude;
            }
            else{
                var lt=17.432143; 
                var ln=78.374427;
            }
                
        }
        catch(e){

        }
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(parseFloat(lt),parseFloat(ln)));
    
        try{
            var infowindow = new window.google.maps.InfoWindow();
                
            if(dealers.length > 0)
            {
                // console.log("dealers ", dealers)
                    
                dealers.map(function(e,index){
                    bounds.extend(new window.google.maps.LatLng(parseFloat(e.latitude),parseFloat(e.longitude)));
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(parseFloat(e.latitude), parseFloat(e.longitude)),
                        icon:require('../../assets/images/customer_pin_38.png'),
                        map: map,	
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            //	console.log(clusters[0])
                            var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = e.dealer_name
                        contentarr.push({"key":"Location", "value":e.location})
                        // contentarr.push({"key":"Region", "value":e.region})
                        var s = e.start_time.split(":");
                        var endt = e.end_time.split(":");
                        var c = moment.parseZone().format("YYYY-MM-DD");
                        contentarr.push({"key":"Start Time", "value":moment.parseZone(new Date(c+" "+s[0]+":"+s[1])).format("HH:mm A")})
                        contentarr.push({"key":"End Time", "value":moment.parseZone(new Date(c+" "+endt[0]+":"+endt[1])).format("HH:mm A")})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        infowindows.push(infowindow);
                        // currentwindow = infowindow;
                        infowindow.open(map, marker);
                        //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker));
                })  
                
            }
            if(plants.length > 0)
            {
                // console.log("plants ", plants)
                
                    
                plants.map(function(plant,index){
                    bounds.extend(new window.google.maps.LatLng(parseFloat(plant.latitude),parseFloat(plant.longitude)));
                    var pmarker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(plant.latitude, plant.longitude),
                        icon:require('../../assets/images/business.png'),
                        map: map,	
                    });
                    window.google.maps.event.addListener(pmarker, 'click', (function(marker) {
                        return function() {
                            //	console.log(clusters[0])
                            var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = plant.plant_name
                        contentarr.push({"key":"Location", "value":plant.location})
                        contentarr.push({"key":"Region", "value":plant.region})
                        var ps = plant.start_time.split(":");
                        var pendt = plant.end_time.split(":");
                        var pc = moment.parseZone().format("YYYY-MM-DD");
                        contentarr.push({"key":"Start Time", "value":moment.parseZone(new Date(pc+" "+ps[0]+":"+ps[1])).format("HH:mm A")})
                        contentarr.push({"key":"End Time", "value":moment.parseZone(new Date(pc+" "+pendt[0]+":"+pendt[1])).format("HH:mm A")})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        infowindows.push(infowindow);
                        // currentwindow = infowindow;
                        infowindow.open(map, marker);
                        //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(pmarker));
                })  
                
            }
            
            map.fitBounds(bounds)
        }catch(e){}
    
            
            // var v = map.getZoom();
            // // console.log("VVV ", v)
            // map.setZoom(v)
    }

      hideSlideBlock(){
        this.setState({
          overlayBlock:"show-n",
          showSlideBlock:"",
          showSlideBlockUpload:"",
          showSlideBlockUploadParts:"",
          showSlideBlockDealers:""
        })
      }
      formAdd(event){
        event.preventDefault();
        var dealer_address=this.state.dealer_address;
        var dealer_city=this.state.dealer_city;
        var dealer_code=this.state.dealer_code;
        var dealer_lat=$("#dealer_lat").val();
        var dealer_lng=$("#dealer_lng").val();
        var dealer_start_time = this.state.dealer_start_time;
        var dealer_end_time = this.state.dealer_end_time;
        var dealer_name=this.state.dealer_name;
        var dealer_pincode=this.state.dealer_pincode;
        var dealer_state_code=this.state.dealer_state.value;
        var dealer_state_name=this.state.dealer_state.label;
        var dealer_plant_code=this.state.dealer_plant.value;
        var dealer_plant_name=this.state.dealer_plant.label;
        if(dealer_lat !== "" && dealer_lng !== "" && dealer_city !== "" && dealer_code !== "" && dealer_name !== "" && dealer_pincode !== "" && dealer_state_code !== "" && dealer_state_name !== "" && dealer_plant_code !== "" && dealer_plant_name !== "")
        {
          var params ={
            dealer_address:dealer_address,
            dealer_city:dealer_city,
            dealer_code:dealer_code,
            dealer_lat:dealer_lat,
            dealer_lng:dealer_lng,
            dealer_name:dealer_name,
            dealer_pincode:dealer_pincode,
            dealer_state_code:dealer_state_code,
            dealer_state_name:dealer_state_name,
            dealer_plant_code:dealer_plant_code,
            dealer_plant_name:dealer_plant_name,
            dealer_start_time:dealer_start_time,
            dealer_end_time:dealer_end_time
          }
          redirectURL.post("/dealers/saveitem",params)
          .then((response) => {
            if(response.data.status === "success")
            {
              this.setState({
                show:true,
                basicTitle:"Successfully to added dealer",
                basicType:"success",
                dealer_address:"",
                dealer_city:"",
                dealer_code:"",
                dealer_lat:"",
                dealer_lng:"",
                dealer_name:"",
                dealer_pincode:"",
                dealer_state:{value:"",label:"Select"},
                overlayBlock:"show-n",
                showSlideBlock:"",
                start_time:"",
                end_time:"",
                dealer_start_time:"",
                dealer_end_time:"",
              })
              $("#dealer_lat").val("");
              $("#dealer_lng").val("");
              this.loadDealers();
              map.setMapOnAll(null)
            }
            else{
              this.setState({
                show:true,
                basicTitle:"Failed to add dealer",
                basicType:"danger"
              })
            }
          })
        }
        else{
    
        }
      }
      closeAlert = () => {
        this.setState({
            show: false
        });
      }
      // onOpenModal = (code) => {
      //   var dealerslist = this.state.dealerslist;
      //   if(dealerslist.length > 0)
      //   {
      //     var dealer = {};
      //     dealerslist.map((f) => {
      //         if(f.dealer_code === code)
      //         {
      //           dealer=f;
      //         }
      //     })
      //     console.log("dealer", dealer)
      //     this.setState({
      //       dealermodal:[dealer],
      //       open: true
      //     })
      //     this.renderMapModal();
      //   }
      // };
    
      onCloseModal = () => {
        this.setState({ open: false });
      };
    
      searchItem(event)
      {
          var value = event.target.value;
          var skey = value.toLowerCase();
          var list = this.state.originalData;

          var output = list.filter((item) =>{
            // console.log("item ", item)
              var keyword1 = item.dealer_name.toLowerCase();
              var keyword2 = item.dealer_code.toString().toLowerCase();
              var keyword3 = item.region.toLowerCase();
              var keyword4 = item.location.toLowerCase();
              var keyword5 = (item.stime != undefined)?item.stime.toLowerCase():"";
              var keyword6 = (item.etime != undefined)?item.etime.toLowerCase():"";
              if(keyword1.includes(skey) || keyword2.includes(skey) || keyword3.includes(skey) || keyword4.includes(skey) || keyword5.includes(skey) || keyword6.includes(skey)){
                  return item;
              }
          });
          this.setState({
              searchKeyword:value,
              dealerslist:output
          })
          // console.log("output search ", output);
    
      }
      handleChangeStartTime(date) {
        var dd = new Date(date).toString();
        // console.log('data ',moment.parseZone(dd).format("HH:mm"))
        var dt = moment.parseZone(dd).format("HH:mm");
        // console.log("dt ", dt);
        this.setState({
          dealer_start_time: dt,
          start_time: date
        })
      }
    
      handleChangeEndTime(date) {
        var dd = new Date(date).toString();
        // console.log("dd ", dd);
        var dt = moment.parseZone(dd).format("HH:mm");
        // console.log('data ',dt)
        this.setState({
          dealer_end_time: dt,
          end_time:date
        })
      }
      
      onClickUploadDealer(){
        this.setState({
          showSlideBlockUpload:"slide25",
          overlayBlock:"show-m"
        })
      }
      
      onClickDealerMap(){
        this.setState({
          showSlideBlockDealers:"slide60",
          overlayBlock:"show-m"
        })
        this.renderMap();
      }
      
      onClickUploadDealerParts(){
        this.setState({
          showSlideBlockUploadParts:"slide25",
          overlayBlock:"show-m"
        })
      }
      
      changeFileHandler = async (e) => {
            console.log("e.target.files[0] ", e.target.files[0])
            this.setState({
                uploadfile:e.target.files[0]
            });
        }
      
        
        changeCSVFileHandler = async (e) => {
            const config = {
                headers: [
                    { 
                        name: 'Unique Part No',
                        inputName: 'unique_part_no',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
            { 
                        name: 'Part No',
                        inputName: 'part_no',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
            { 
                        name: 'Part Name',
                        inputName: 'part_name',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
            { 
                        name: 'Supplier Code',
                        inputName: 'supplier_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
            { 
                        name: 'Part Qty',
                        inputName: 'part_qty',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
            { 
                        name: 'Length',
                        inputName: 'part_length',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
            { 
                        name: 'Width',
                        inputName: 'width',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
            { 
                        name: 'Height',
                        inputName: 'height',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'Part Weight',
                        inputName: 'part_weight',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'Packing Weight',
                        inputName: 'packing_weight',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'Pkg Total Volume',
                        inputName: 'pkg_total_volume',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'Pkg Total Weight',
                        inputName: 'pkg_total_weight',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    }
            
                    
                ]
            }
            var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                this.setState({
                    csvfiledata:csvData.data
                });
            })
            .catch(err => {})
                
            var out = new Promise(function(reject, resolve){
                var reader =  new FileReader();
                reader.onload = async function(e) {
                    var contents = await e.target.result;
                    console.log("contents ", contents )
                    resolve(contents);
                };
                var tt =  reader.readAsText(e.target.files[0]);
                console.log("tt ",tt)
            });
            //console.log("Out ", out);
            
            this.setState({
                file:e.target.files[0]
            });
            //console.log("e.target.files[0].type ", e.target.files[0].type);
    
            // if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            // {
            // }
            // else{
            // 	e.target.value = null;
            // 	this.setState({
            // 		uploadFile:'',
            // 		show: true, 
            // 		basicType:'danger', 
            // 		basicTitle:'Please upload file having extensions .csv only.',
            // 	});
            // }
            
        }
    
      formUpload(event){
        event.preventDefault();
        this.setState({
          showloader:"show-m"
        })
        var reqparams = new FormData()
        reqparams.append("file", this.state.uploadfile)
        // console.log("reqparams ", reqparams);
        redirectURL.post("/dealers/readXLSData",reqparams)
        .then((response) => {
          // console.log(response.data)
          var uploadxlsdata = response.data.records;
          console.log("uploadxlsdata ", uploadxlsdata)
          if(uploadxlsdata.length > 0)
          {
            var p = {
              xlsdata:uploadxlsdata
            }
            redirectURL.post("/dealers/uploadDealers",p)
            .then((resp) => {
              // console.log(resp.data)
              if(resp.data.message == "success")
              {
                this.setState({
                  uploadfile:"",
                  showSlideBlockUpload:"",
                  overlayBlock:"show-n",
                  show:true,
                  basicTitle:"Successfully upload data",
                  basicType:"success",
                  showloader:"show-n"
                });
                this.loadDealers();
                // window.location.reload();
              }
              else{
                this.setState({
                  show:true,
                  basicTitle:"Failed to upload data",
                  basicType:"danger",
                  showloader:"show-n"
                });
              }
            })
          }
        })
      }
      formCSVUpload(event)
      {
        event.preventDefault();
        this.setState({
          showloader:"show-m"
        })
        var csvfiledata = this.state.csvfiledata;
        // console.log("csvfiledata ", csvfiledata)
        var p = {
          filedata: csvfiledata
        }
        redirectURL.post("/dealers/uploadDealerParts",p)
        .then((resp) => {
          // console.log("resp ", resp.data)
          window.location.reload();
        })
      }
    render() {
        
    const { open } = this.state;
        return (
            <div class="container-fluid">
            <div className={'lodr '+(this.state.showloader)}>
                <div className="loaderdiv">
                    <div className="loaderwave"></div>
                    <div className="loaderwave"></div>
                    <div className="loaderwave"></div>
                    <div className="loaderwave"></div>
                    <div className="loaderwave"></div>
                    <div className="loaderwave"></div>
                    <div className="loaderwave"></div>
                    <div className="loaderwave"></div>
                    <div className="loaderwave"></div>
                    <div className="loaderwave"></div>
                </div>
                <div className="ldrtext">Loading....</div>
            </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12 ">
                        <h5 className='fbold'>
                            <i className="fa fa-users"></i> {Constant.PAGE_TITLE_DEALER}
                            <span className="float-right">
                                {/* <a href="javascript:;" ref="Add Dealer" onClick={this.onClickAddDealer.bind(this)} className="btn btn-warning f12">+ Add Dealer</a> */}
                            </span>
                            <span className="float-right">
                                <a href="javascript:;" ref="Add Dealer" onClick={this.onClickUploadDealer.bind(this)} className="btn btn-info f12 mr-10p">+ Upload Supplier</a>
                            </span>
                            <span className="float-right">
                                <a href="javascript:;" ref="Add Dealer" onClick={this.onClickUploadDealerParts.bind(this)} className="btn btn-primary f12 mr-10p">+ Upload Supplier Parts</a>
                            </span>
                            <span className="float-right">
                                <a href="javascript:;" ref="Add Dealer" onClick={this.onClickDealerMap.bind(this)} className="btn btn-secondary f12 mr-10p">View Supplier On Map</a>
                            </span>
                            <span className="float-right f12 mr-10p">
                                <input type="text" placeholder="Search..." className="form-control" value={this.state.searchKeyword} name="searchKeyword" id="searchKeyword" onChange={this.searchItem.bind(this)} />
                                </span>
                        </h5>
                    </div>
                </div>
                <div className="row">
                {this.state.dealerslist.length > 0?
                    (this.state.dealerslist.map(item => 
                        <div className="col-sm-3 mb-40p f14 beffect fdeffect ">
                          {/* {console.log("itemitem ", item)} */}
                            <div className="card fcls-a">
                                <div className="card-header" style={{background:"transparent"}}>
                                    <h4 className="f15 fbold fclstxt">{item.dealer_name}
                                        {/* <a href="" className="fclstxt float-right">
                                            <i className="fa fa-trash"></i>
                                        </a>
                                        <a href="" className="fclstxt float-right mr-10p">
                                            <i className="fa fa-pencil"></i>
                                        </a> */}
                                    </h4>
                                </div>
                                <div className="card-body mh180 fclstxt">
                                    <div className="row lh30">
                                        <div className="col-sm-6 fclstxt"><span className="fbold">Code:</span> {item.dealer_code}</div>
                                        <div className="col-sm-6 fclstxt"><span className="fbold">Region:</span> {item.region}</div>
                                        <div className="col-sm-12 fclstxt"><span className="fbold">Address:</span> {item.location}</div>
                                        {/* <div className="col-sm-5"><span className="fbold">State:</span> {item.dealer_state_name}</div> */}
                                        {/* <div className="col-sm-4"><span className="fbold">Pincode:</span> {item.dealer_pincode}</div> */}
                                        <div className="col-sm-12 fclstxt"><span className="fbold">Availability:</span> {item.stime} - {item.etime}</div>
                                        {/* <div className="col-sm-6"><span className="fbold cursor gclr fregular f12"  onClick={this.onOpenModal.bind(this,item.dealer_code)}><i className="fa fa-map-marker"></i> View On Map</span></div> */}
                                    </div>  
                                </div>
                            </div>
                        </div>
                    ))
                    :""
                }
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock.bind(this)}></div>
            <div className={"sliderBlock "+(this.state.showSlideBlock)}>
              <div className="card">
                <div className="card-header crd-bg">
                  <h5>{Constant.DEALER_FORM_ADD}</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    
                    <div className="col-sm-4" style={{color:"#000"}}>
                      <form onSubmit={this.formAdd.bind(this)}>
                      <div className="row">
                        <div className="form-group col-sm-12 mb-20p">
                            <label>{Constant.DEALER_FORM_PLANT} <span className="rclr fbold">*</span></label>
                            <Select 
                                  placeholder={"Select Plant"}
                                  // closeMenuOnSelect={true}
                                  onChange={this.changePlant.bind(this)} 
                                  className={"col-xl-12 col-lg-12 border-radius-0"}
                                  style={{borderRadius:"0px"}}
                                  value={this.state.dealer_plant}
                                  options={this.plantlist()} 
                              /> 
                        </div>
                        <div className="form-group col-sm-6 mb-20p">
                            <label>{Constant.DEALER_FORM_CODE} <span className="rclr fbold">*</span></label>
                            <input type="text" name="dealer_code" id="dealer_code" className="form-control" onChange={this.changeHandler} value={this.state.dealer_code} required />
                        </div>
                        <div className="form-group col-sm-6 mb-20p">
                            <label>{Constant.DEALER_FORM_NAME} <span className="rclr fbold">*</span></label>
                            <input type="text" name="dealer_name" id="dealer_name" className="form-control" onChange={this.changeHandler} value={this.state.dealer_name} required />
                        </div>
                        
                        <div className="form-group col-sm-6 mb-20p">
                            <label>{Constant.DEALER_FORM_START_TIME} <span className="rclr fbold">*</span></label>
                            {/* <input type="text" name="dealer_start_time" id="dealer_start_time" className="form-control datepickertime" onChange={this.changeHandler} value={this.state.dealer_start_time} required /> */}
                            <DatePicker
                              selected={ this.state.start_time }
                              onChange={ this.handleChangeStartTime }
                              name="dealer_start_time"
                              showTimeSelect
                              showTimeSelectOnly
                              timeFormat="HH:mm"
                              timeIntervals={20}
                              timeCaption="time"
                              dateFormat="HH:mm"
                          />
                        </div>
                        <div className="form-group col-sm-6 mb-20p">
                            <label>{Constant.DEALER_FORM_END_TIME} <span className="rclr fbold">*</span></label>
                            {/* <input type="text" name="dealer_end_time" id="dealer_end_time" className="form-control datepickertime" onChange={this.changeHandler} value={this.state.dealer_end_time} required /> */}
                            <DatePicker
                              selected={ this.state.end_time }
                              onChange={ this.handleChangeEndTime }
                              name="dealer_end_time"
                              showTimeSelect
                              showTimeSelectOnly
                              timeFormat="HH:mm"
                              timeIntervals={20}
                              timeCaption="time"
                              dateFormat="HH:mm"
                          />
                        
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                            <label>{Constant.DEALER_FORM_ADDRESS} <span className="rclr fbold">*</span></label>
                            <input type="text" name="dealer_address" id="dealer_address" className="form-control" onChange={this.changeHandler} value={this.state.dealer_address} required />
                        </div>
                        <div className="form-group col-sm-4 mb-20p">
                            <label>{Constant.DEALER_FORM_CITY} <span className="rclr fbold">*</span></label>
                            <input type="text" name="dealer_city" id="dealer_city" className="form-control" onChange={this.changeHandler} value={this.state.dealer_city} required />
                        </div>
                        <div className="form-group col-sm-4 mb-20p">
                            <label>{Constant.DEALER_FORM_STATE} <span className="rclr fbold">*</span></label>
                            <Select 
                                  placeholder={"Select State"}
                                  // closeMenuOnSelect={true}
                                  onChange={this.changeState.bind(this)} 
                                  className={"col-xl-12 col-lg-12 border-radius-0"}
                                  style={{borderRadius:"0px"}}
                                  value={this.state.dealer_state}
                                  options={this.statelist()} 
                              /> 
                        </div>
                        <div className="form-group col-sm-4 mb-20p">
                            <label>{Constant.DEALER_FORM_PINCODE} <span className="rclr fbold">*</span></label>
                            <input type="text" name="dealer_pincode" id="dealer_pincode" className="form-control" onChange={this.changeHandler} value={this.state.dealer_pincode} required />
                        </div>
                        <div className="form-group col-sm-6 mb-20p show-n">
                            <label>{Constant.DEALER_FORM_LAT} <span className="rclr fbold">*</span></label>
                            <input type="text" name="dealer_lat" id="dealer_lat" className="form-control" onChange={this.changeHandler} value={this.state.dealer_lat} required />
                        </div>
                        <div className="form-group col-sm-6 mb-20p show-n">
                            <label>{Constant.DEALER_FORM_LNG} <span className="rclr fbold">*</span></label>
                            <input type="text" name="dealer_lng" id="dealer_lng" className="form-control" onChange={this.changeHandler} value={this.state.dealer_lng} required />
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                          <button type="submit" className="btn btn-danger">SAVE</button>
                          <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                        </div>
                      </div>
                      </form>
                    </div>
                    <div className="col-sm-8">
                       <input type="text" id="search" className="form-control" placeholder="Search location / address" style={{width:"50%",position:"absolute",marginTop:"10px",marginLeft:"10px"}} />
                   
                        <div id="dealer_map" style={{width:"100%", height:"700px"}}></div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <Modal open={open} onClose={this.onCloseModal}>
            <div className="card">
              <div className="card-header">
                {this.state.modal_dealer_name}
              </div>
              <div className="card-body">
                <div id="dealer_modal_map" style={{width:"100%", height:"700px"}}></div>
              </div>
            </div>
          </Modal>

          
          <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
              <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD}</h5>
              <div className="row">
                
                <div className="col-sm-12">
                  <form onSubmit={this.formUpload.bind(this)}>
                  <div className="row p-20p">
                    <div className="form-group col-sm-12 mb-20p">
                        <label style={{color:"#000"}}>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label>
                        
                        <input type="file" name="upload_file" id="upload_file" className="form-control" onChange={this.changeFileHandler} required />
                    </div>
                    <div className="form-group col-sm-12 mb-20p">
                      <button type="submit" className="btn btn-danger">SAVE</button>
                      <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
            
          <div className={"sliderBlock2 "+(this.state.showSlideBlockUploadParts)}>
              <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD_PARTS}</h5>
              <div className="row">
                
                <div className="col-sm-12">
                  <form onSubmit={this.formCSVUpload.bind(this)}>
                  <div className="row p-20p">
                    <div className="form-group col-sm-12 mb-20p">
                        <label style={{color:"#000"}}>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label>
                        
                        <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                    </div>
                    <div className="form-group col-sm-12 mb-20p">
                      <button type="submit" className="btn btn-danger">SAVE</button>
                      <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
            <div className={"sliderBlock2 "+(this.state.showSlideBlockDealers)}>
              <h5 className="crd-bg p-10p">Suppliers List</h5>
              <div className="row">
                
                <div className="col-sm-12">
                  
                  <div id="map" style={{width:"100%",height:"90vh"}}></div>
                  {/* <Dashboard
                   mapinfo={[]}
                   rfreshmap={[]}
                   dealers={[]}
                   plants={[]}
                    /> */}
                </div>
              </div>
            </div>
          </div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function GetAscSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  }
function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function closeAllInfoWindows() {
  for (var i=0;i<infowindows.length;i++) {
      infowindows[i].close();
  }
}



