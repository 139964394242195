import React, { Component } from 'react';




class PowerBIDashboard extends Component {

    render(){
        return (
            <div class="row m-0p">
                <div className="col-12">
             <iframe title="Report Section" 
             style={{width:"100%", height:"100vh"}}
             src="https://app.powerbi.com/view?r=eyJrIjoiMTk1ZjgwMDMtYTljMC00MmE2LWI0MTMtMjNmNTc1MTZlYTM3IiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9" 
             frameborder="0" allowFullScreen="true"></iframe>
            </div></div>
          );
    }


}

export default PowerBIDashboard