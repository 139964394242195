import React, { Component } from 'react';


import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

// drilldown(Highcharts);
export default class LineChartComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
                chart: {
                    type: 'spline',
                    // height:"500px"
                },
                title: {
                    text: this.props.title,
                    align:"left"
                },
            
                // subtitle: {
                // },
            
                yAxis: {
                    title: {
                        text: 'Quantity'
                    }
                },
            
                xAxis: {
                    type:"category",
                    // accessibility: {
                    //     rangeDescription: 'Range: 2010 to 2020'
                    // }
                },
            
                // legend: {
                //     // layout: 'vertical',
                //     align: 'bottom',
                //     // verticalAlign: 'middle'
                // },
            
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        },
						 dataLabels: {
						  enabled: true,
						  // format: '{point.ytxt}', // Customize the label format as per your requirement
                          formatter: function() {
                            // 'this.y' contains the original label value, e.g., "$2.767k"
                            var value = this.point.ytxt.replace('$', '')
                            return value;
                        },
						  style: {
							fontSize: '12px',
							fontWeight: 'bold',
							color: 'black'
						  }
						}
                        // pointStart: 2010
                    }
                },
				
				tooltip: {
					// headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
					// pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.ytxt}</b><br /><span style="color:#ff0000">Difference (%):{point.diff_percent}</span>',
					// valueDecimals: 0
                    formatter: function() {
                        
                            var series = this.series.chart.series,
                                result = '<span style="color:' +
                                this.point.color + '">\u25CF</span> ' +
                                this.series.name + '<br/ ><span style="color:' +
                                this.point.color + '">'+this.point.name+' </span>: <b>' + this.point.ytxt.replace('$' , '') +
                                '</b><br/>';
                                // if(this.series.name !== "Actual Cost")
                                // {
                                //      result += '<span style="color: #ff0000"> Difference (%) : ' + this.point.diff_percent + '</span><br/>';
                                // }
                            // series.forEach(function(s) {
                            //     if (s !== this.series) {
                            //         // console.log("s.points[this.point.index]", s.points[this.point.index])
                            //         if(s.points[this.point.index] != undefined)
                            //         {
                            //                 result += '<span style="color:' +s.points[this.point.index].color +'">\u25CF</span><span style="color: #c6c6c6"> ' + s.name + ': ' + s.points[this.point.index].ytxt + '</span><br/>';
                                        
                            //                 if(s.name !== "Predicted Cost")
                            //                 {
                            //                      result += '<span style="color: #c6c6c6"> Difference (%) : ' + s.points[this.point.index].diff_percent + '</span><br/>';
                            //                 }
                            //         }
                                
                            //     }
                            // }, this);
                        
                            return result;
                            
                        }
				},
                series: this.props.series,
                colors: [
                    '#4caefe',
                    '#FF3535',
                    '#23e274',
                    '#2dd9db',
                    '#1feeaf',
                    '#0ff3a0',
                    '#00e887'
                ],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                },
                credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}
