import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";
import { data } from "jquery";

export default class ZoneMapping extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleAvailabilityModel:false,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            showAvailablityTable:false,
            data_type:'',
            rowId:'',
            zoneNames:[],
            location:this.props.plantLocation,
            zone:'',
            statename:''
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        await this.getZoneMappingData(params)
        await this.getZoneMasterDetails(params)
    }

    getZoneMappingData = (params) =>{
        redirectURL.post('master/getZoneMappingData',params)
        .then(response =>{
            this.setState({
                rowData:response.data
            })
        })
    }

    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            rowId:'',
            zone:'',
            statename:''
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    
    vehicleConfigurationInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        value = value.toUpperCase()
        this.setState({ [name] : value })
    }

    zoneChanged = (zone) =>{
        this.setState({
            zone : zone
        })
    }


    getZoneMasterDetails = async(param) => {

      let zoneNames = this.state.zoneNames;
      await redirectURL.post('master/getZoneMasterDetails',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let zonesData = response.data.zonesData
                if(zonesData.length > 0){
                    zonesData.map(item =>{
                        zoneNames.push({ value : item.zone,
                            label : item.zone })
                    })
                    await this.setState({
                        zoneNames : zoneNames
                    })
                }
            }
        })
        
    }

    saveZoneMapping = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveZoneMapping',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getZoneMappingData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        statename:'',
                        zone:'',
                        rowId:'',
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }

    deleteZoneMappingDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteZoneMappingDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getZoneMappingData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }


    formVehicleConfiguration = (e)=>{
        e.preventDefault();
        let zone = this.state.zone;
        let rowId = this.state.rowId;
        let state = this.state.statename;
        let location = this.state.location;
        let data_type = this.state.data_type
        let params = {
            zone : zone.value,
            rowId : rowId,
            state : state,
            location_code : location.value,
            location_name : location.label,
            data_type : data_type     
        }
       if(location.value != null && zone.value != null){
        this.saveZoneMapping(params)
       }else{
        if(zone.value == null){
            this.setState({
                show:true,
                basicTitle:"Please Select Zone",
                basicType:"danger"
            })
        }else{
            this.setState({
                show:true,
                basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                basicType:"danger"
            })
            }
        }
        
    }

    editzonemapping = async(propdata) =>{
        let data = propdata.data
        await this.setState({
            "statename" : data.state,
            "zone" : {"label":data.zone,"value":data.zone},
            "rowId" : data._id
        })
        this.openModelForMaterialConfiguration()
    }
    render(){
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        var auto_clustering_type = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                var auto_clustering_type = toolConfig[0].auto_clustering_type;
            }
        }
        var colsDefs = [
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Edit',
                        onClickFunction:this.editzonemapping},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteZoneMappingDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"State",
                field:"state",
                width:"450",
            },
            {
                headerName:"zone",
                field:"zone",
                width:"250"
            },
        ]
        // let data = this.state.rowData
        // let locValue = this.state.location.value
         
        // let filteredData = data.filter(item => item.location_code == locValue)
        let filteredData = this.state.rowData;
        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12 d-flex">
                <div className= {this.state.showAvailablityTable ? "col-sm-7 " : "col-sm-12 " + "  d-inline"}>
                        {/* {(filteredData.length == 0)? */}
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Zone Mapping</span>
                            <div className='float-right'>
                                <a href="javascript:;" onClick={this.openModelForMaterialConfiguration} className="f18 float-right themefont fbold mr-10p mb-10p">+ Add Zone Mapping</a>
                            </div> 

                        </h5>
                        {/* :""} */}
                        {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.locationChanged}
                                        name="location"
                                        value={this.state.location}
                                        options={this.state.locationNames} />

                                </div>
                            </div> */}

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                        
                    </div>

                    {/* { this.state.showAvailablityTable ?
                    <div className='col-sm-5 d-inline'>
                    
                    <h5 className='fbold white d-flex justify-content-between'>
                            <span>{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_HEADER_NAME}</span>
                            <div className='float-right'>
                            <a href="javascript:;" onClick={this.openModelForVehicleAvailability} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                             */}
                            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
                            {/* </div>    
                        </h5>
                        
                        <VehiclesAvailability
                        availabilityData = {this.state.availabilityData}/>
                        <span className="float-right">
                            <button onClick={this.hideVehicleAvailabilityTable} className="btn btn-warning f12 mr-15p mt-5p">Close</button>
                            </span>
                    </div>
                    :<div/>} */}
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openMaterialConfigurationModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>Zone Mapping</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formVehicleConfiguration}>

                        <div className="row number">
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">State</label>
                                    <input required type="text" className="forminp form-control" id="statename" name="statename" value={this.state.statename} 
                                    onChange={this.vehicleConfigurationInputFields}
                                    placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Zone</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.zoneChanged}
                                        name="zone"
                                        value={this.state.zone}
                                        options={this.state.zoneNames} 
                                        />

                                </div>
                            </div>
                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Zone</label>
                                    <select name="zone" id="zone" value = {this.state.zone}
                                        required={true}
                                        className={"forminp form-control"}
                                        onChange={this.vehicleConfigurationInputFields}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="SOUTH">SOUTH</option>
                                        <option value="WEST">WEST</option>
                                        <option value="EAST">EAST</option>
                                        <option value="NORTH EAST">NORTH EAST</option>
                                        <option value="NORTH">NORTH</option>
                                    </select>
                                    
                                </div>
                            </div> */}
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                    </Modal>
            </>
        )
    }
}