import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';  
// import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import getToolConfiguration from "./commonFunctions";
import SweetAlert from 'react-bootstrap-sweetalert';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import { subDays } from "date-fns";
import GridButton from './gridButton';
import CSVFileValidator from 'csv-file-validator';

var moment = require("moment");


export default class VehiclesAvailabilityMaster extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openMasterVehicleAvailabilityModel: false,
            data_type: '',
            location: this.props.plantLocation,
            vehicle_type: '',
            vehicleTypeList: [],
            transporter_name: '',
            transporterList: [],
            vehicle_no: '',
            show:false,
            basicTitle:"",
            basicType:"default",
            row_id:'',
            vehicle_priority:"Owned",
            overlayBlock:"show-n",
            showSlideBlock:"",
            csvfiledata:[],
            openVehiclePriorityConfigurationModel:false,
            vehicle_priority_order_owned:1,
            vehicle_priority_order_contract:2,
            vehicle_priority_order_market:3,
        }
            
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type


        if (path == 'inbound'){
            data_type = 1
            this.setState({data_type : data_type})
        }else{
            data_type = 2
            this.setState({data_type : data_type})
        }
        let params = {
            data_type : data_type
        }
       await getToolConfiguration(params)
       await this.getVehiclesData(params)
       await this.getTransporterDetails(params)
       await this.getMasterAvailabilityVehiclesData(params)
       await this.getVehiclePriorityOrder(params)
        
    }



    getVehiclesData = (params) =>{
        let vehicleTypeList = this.state.vehicleTypeList

        redirectURL.post('master/getVehiclesData',params)
        .then(async (response) =>{
            if(response.data.status == 'success'){

                let vehiclesData = response.data.vehiclesData
                if(vehiclesData.length > 0){
                    vehiclesData.map(item =>{
                        vehicleTypeList.push({ value : item.vehicle_type,
                            label : item.vehicle_type })
                    })
                    await this.setState({
                        vehicleTypeList : vehicleTypeList,
                        vehicle_type : vehicleTypeList[0]
                    })
                }
        }
        })
    }

    vehicleTypeChanged = (vehicle_type) =>{
        this.setState({
            vehicle_type : vehicle_type
        })
    }

    transporterChanged = (transporter_name) =>{
        this.setState({
            transporter_name : transporter_name
        })
    }

    getTransporterDetails = async(param) => {
        let transporterList = this.state.transporterList;
        await redirectURL.post('master/getTransporterDetails',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let transporters = response.data.transporterDetailsData
                  if(transporters.length > 0){
                    transporters.map(item =>{
                        //update item name 
                        transporterList.push({ value : item.transporter_code,
                              label : item.transporter_name })
                      })

                    let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
                    let transporter_name = ''
                    if(toolConfig[0].plant_wise_transporter_configuration == 0){
                      transporter_name = {label : 'LSP', value : 'LSP'};
                    }                 
              await this.setState({
                    transporterList : transporterList,
                    transporter_name : transporter_name
                    })
                }else{
                    let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
                    if(toolConfig[0].plant_wise_transporter_configuration == 0){
                      this.setState({transporter_name : {label : 'LSP', value : 'LSP'}});
                    } 
                }
            }
          })
          
      }

      getMasterAvailabilityVehiclesData = async(params) =>{
        redirectURL.post('master/getMasterAvailabilityVehiclesData',params)
        .then(async (response) =>{
            if(response.data.status == 'success'){

                let masterVehicleAvailabilityData = response.data.masterVehicleAvailabilityData
                await this.setState({
                    rowData:masterVehicleAvailabilityData
                })
        }
        })
      }

    openModelForMasterVehicleAvailability = () =>{
        
        this.setState(prevState =>({
            openMasterVehicleAvailabilityModel :!prevState.openMasterVehicleAvailabilityModel
        }))
    }

    handelMasterVehicleAvailabilityModelCancel = ()=>{

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let transporter_name = ''
        if(toolConfig[0].plant_wise_transporter_configuration == 0){

            transporter_name = {label : 'LSP', value : 'LSP'};
        }

        this.setState(prevState =>({
            openMasterVehicleAvailabilityModel :!prevState.openMasterVehicleAvailabilityModel,
            vehicle_date:'',
            transporter_name:transporter_name,
            vehicle_no:'',
            row_id:'',
            vehicle_priority:"Owned",
        }))

    }

    vehicleAvailabilityInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    saveMasterVehicleAvalabilityData = (params) =>{
        let data_type = this.state.data_type

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let transporter_name = ''
        if(toolConfig[0].plant_wise_transporter_configuration == 0){

            transporter_name = {label : 'LSP', value : 'LSP'};
        }

        redirectURL.post('/master/saveMasterVehicleAvailability',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getMasterAvailabilityVehiclesData(param)
                this.setState({openMasterVehicleAvailabilityModel:false,
                            vehicle_type:'',
                            vehicle_date:'',
                            transporter_name:transporter_name,
                            vehicle_no:'',
                            row_id:'',
                            vehicle_priority:"Owned",
                        })
            }else{
                if(response.data.status == 'failed'){
                    this.setState({
                        show:true,
                        basicTitle:response.data.message,
                        basicType:"danger"
                    })
                }else{
                this.setState({
                    show:true,
                    basicTitle:"Data Not Saved successfully",
                    basicType:"danger"
                })
            }
            }

                })
    }

    formMasterVehicleAvailability = (e) =>{
        e.preventDefault();
        let openMasterVehicleAvailabilityModel = this.state.openMasterVehicleAvailabilityModel;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let vehicle_type = this.state.vehicle_type;
        let transporter_name = this.state.transporter_name;
        let vehicle_no = this.state.vehicle_no;
        let row_id = this.state.row_id;
        let vehicle_priority = this.state.vehicle_priority;
       
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let params = {
            vehicle_type : vehicle_type.value,
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            vehicle_no : vehicle_no,
            transporter_code : transporter_name.value,
            transporter_name : transporter_name.label,
            vehicle_priority : vehicle_priority,
            
        }

        if(row_id != ''){

            params.row_id = row_id
            
                this.updateMasterVehicleAvailabilityData(params)

        }else{

            if(location.value != null && transporter_name.value != null){

                if(vehicle_type.value != null){

                        this.saveMasterVehicleAvalabilityData(params)
                    
                }else{
                    this.setState({
                        show:true,
                        basicTitle:"Please add Vehicles First",
                        basicType:"danger"
                    })
                }
            }else{
                if(transporter_name.value == null){
                    this.setState({
                        show:true,
                        basicTitle:"Please Select Transporter",
                        basicType:"danger"
                    })
                }else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
            }
            }

        }
    }
    

    updateMasterVehicleAvailability = async(propdata) =>{
        let data = propdata.data
            await this.setState({
                vehicle_no:data.vehicle_no,
                transporter_name:{label:data.transporter_name, value:data.transporter_code},
                row_id:data._id,
                vehicle_priority:data.vehicle_priority,
                vehicle_type:{label:data.vehicle_type,value:data.vehicle_type}

            })
            this.openModelForMasterVehicleAvailability()
    
    }
    
    updateMasterVehicleAvailabilityData = (params) =>{
        let data_type = this.state.data_type

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let transporter_name = ''
        if(toolConfig[0].plant_wise_transporter_configuration == 0){

            transporter_name = {label : 'LSP', value : 'LSP'};
        }     

        redirectURL.post('/master/updateMasterVehicleAvailability',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getMasterAvailabilityVehiclesData(param)
                this.setState({openMasterVehicleAvailabilityModel:false,
                            vehicle_type:'',
                            transporter_name:transporter_name,
                            vehicle_no:'',
                            row_id:'',
                            vehicle_priority:"Owned",
                        })
                    }else{
                        if(response.data.status == 'failed'){
                            this.setState({
                                show:true,
                                basicTitle:response.data.message,
                                basicType:"danger"
                            })
                        }else{
                        this.setState({
                            show:true,
                            basicTitle:"Data Not Saved successfully",
                            basicType:"danger"
                        })
                    }
                    }

                })
    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}


    deleteMasterVehicleAvailabilityDetails = (propData) =>{
        let data = propData.data;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let params = {
            delete_id : data._id,
            vehicle_no:data.vehicle_no,
            location_code:location.value,
            data_type:data_type
        }
        redirectURL.post('master/deleteMasterVehicleAvailabilityDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getMasterAvailabilityVehiclesData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }

    openUploadOptionForMasterVehicleAvailability = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlock:"slide25",
            overlayBlock:"show-m"
          })
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
        })
      }

      formCSVUpload = (event) =>
      {
        let data_type = this.state.data_type
        let location = this.state.location
        event.preventDefault();
        var csvfiledata = this.state.csvfiledata;
        var p = {
          filedata: csvfiledata,
          data_type:data_type,
          location_code : location.value,
          location_name : location.label,
        }
        redirectURL.post("/master/uploadMasterVehicleAvailabilityData",p)
        .then(async(resp) => {
          
          if(resp.data.status == 'success'){
            await this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"success",
                overlayBlock:"show-n",
                showSlideBlock:"",
            })
            window.location.reload();
            
          }else{
            this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"danger"
            })
          }
        })
      }


      changeCSVFileHandler = async (e) => {
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let transporterConfiguration = toolConfig[0].plant_wise_transporter_configuration
        const config = {
            headers: [
                // { 
                //     name: 'Location Code',
                //     inputName: 'location_code',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                // { 
                //     name: 'Location Name',
                //     inputName: 'location_name',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                { 
                    name: 'Vehicle Type',
                    inputName: 'vehicle_type',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                 },
       
                { 
                    name: 'Vehicle Number',
                    inputName: 'vehicle_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Vehicle Priority',
                    inputName: 'vehicle_priority',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                
                // { 
                //     name: 'From Time',
                //     inputName: 'from_time',
                //     required: false,
                // },
                // { 
                //     name: 'To Time',
                //     inputName: 'to_time',
                //     required: false,
                // }
            ]
        }

        if(transporterConfiguration == 1){
        config.headers.push({ 
            name: 'Transporter Name',
            inputName: 'transporter_name',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
        },{ 
            name: 'Transporter Code',
            inputName: 'transporter_code',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
        })
        
        }
        
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            document.getElementById("inValidDataInfo").innerHTML = "";
            if(csvData.inValidData.length >0){
                document.getElementById("bulkUploadBtn").type = "button"
                document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                let invalidData = csvData.inValidData;
                let element = document.getElementById("inValidDataInfo")
                invalidData.map(item =>{
                    let row,column
                    if(item.rowIndex == undefined){
                        row = 'NA'
                    }else{
                        row = item.rowIndex
                    }
                    if(item.columnIndex == undefined){
                        column = 'NA'
                    }else{
                        column = item.columnIndex
                    }
                    
                    element.innerHTML += "Column : "+column+","+" Row : " + row +"--"+ item.message +"<br></br>"
                })
                 
            }else{
                document.getElementById("bulkUploadBtn").type = "submit"
                document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                document.getElementById("bulkUploadBtn").classList.add("btn-danger");
                this.setState({
                    csvfiledata:csvData.data
                });
            }
        })
        .catch(err => {})
      }


      handelVehiclePriorityConfigurationModel = () =>{
        this.setState(prevState =>({
            openVehiclePriorityConfigurationModel :!prevState.openVehiclePriorityConfigurationModel
        }))
    }
    handelVehiclePriorityConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openVehiclePriorityConfigurationModel :!prevState.openVehiclePriorityConfigurationModel,
        }))
    }

    saveVehiclePriorityOrder = () =>{
        // location specific ??
        let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
        let vehicle_priority_order_contract = this.state.vehicle_priority_order_contract;
        let vehicle_priority_order_market = this.state.vehicle_priority_order_market;
        let data_type = this.state.data_type
        let params = {
            owned : parseInt(vehicle_priority_order_owned),
            contract : parseInt(vehicle_priority_order_contract),
            market : parseInt(vehicle_priority_order_market),
            data_type : data_type
        }
        redirectURL.post('master/saveVehiclePriorityOrder',params)
        .then(response =>{
            if(response.data.status == 'success'){
                this.setState({
                    openVehiclePriorityConfigurationModel:false
                })

            }else{
                this.setState({
                    show:true,
                    basicTitle:"Data Not Saved successfully",
                    basicType:"danger"
                })
            }
        })
    }

    getVehiclePriorityOrder = (params)=>{
        let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
        let vehicle_priority_order_contract = this.state.vehicle_priority_order_contract;
        let vehicle_priority_order_market = this.state.vehicle_priority_order_market;
        redirectURL.post('master/getVehiclePriorityOrder',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let vehiclePriorityData = response.data.vehiclePriorityData
                vehicle_priority_order_owned = vehiclePriorityData[0].owned
                vehicle_priority_order_contract = vehiclePriorityData[0].contract
                vehicle_priority_order_market = vehiclePriorityData[0].market
                this.setState({
                    vehicle_priority_order_owned,
                vehicle_priority_order_contract,
                vehicle_priority_order_market
                })
            }
        })

    }


    render(){

        var colsDefs = [
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                filter:false,
                sortable:false,
                params:{buttonName:'Edit',
                        onClickFunction:this.updateMasterVehicleAvailability},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteMasterVehicleAvailabilityDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"110"
            },
            {
                headerName:"Vehicle Number",
                field:"vehicle_no",
                width:"130"
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:"145",
            },
            {
                headerName:"Vehicle Priority",
                field:"vehicle_priority",
                width:"125"
            },
        ]

        let data = this.state.rowData
        let locValue = this.state.location.value   
        let filteredData = data.filter(item => item.location_code == locValue)

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let operationalTimesType = 0;
        let transporterConfiguration = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                operationalTimesType = toolConfig[0].vehicle_operational_times_type;
                transporterConfiguration = toolConfig[0].plant_wise_transporter_configuration
            }
        }

            var templatePath = require('../../assets/json/Vehicle_Availability_data_template.csv');
        
        
        
        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
            <div class="row mb-20p pt-20p">
                <div className="col-sm-12">
                    
                        <h5 className='fbold d-flex justify-content-between'>
                            <span>{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_MASTER_DETAILS_HEADER_NAME}</span>
                            
                            <div className='float-right col-sm-6'>
                            <a href="javascript:;" onClick={this.openModelForMasterVehicleAvailability} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_MASTER_DETAILS_BUTTON_NAME}</a>
                            <div className="d-flex flex-row-reverse col-sm-12 control-padding-right">
                            <span >
                            <a href="javascript:;"  onClick={this.openUploadOptionForMasterVehicleAvailability} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_MASTER_DETAILS_BULK_BUTTON_NAME}</a>
                            </span>
                            <span >
                            <a href="javascript:;"  onClick={this.handelVehiclePriorityConfigurationModel} className="btn btn-warning f12 mr-10p">Set Vehicle Priority</a>
                            </span>
                            </div>
                            </div> 
                        </h5>

                        <div id="myGrid" style={{width:"100%",height:"80vh"}} className="col-sm-12 ag-theme-balham">
                            <AgGridReact
                            modules={this.state.modules} 
                                rowData={filteredData}
                                columnDefs={colsDefs}
                                gridOptions={{context:{componentParent:this}}}
                                defaultColDef={this.state.defaultColDef}
                                frameworkComponents={this.state.frameworkComponents}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                
                            />
                        </div>
                </div>
            </div>

            <Modal closeOnOverlayClick={false} open={this.state.openMasterVehicleAvailabilityModel} onClose={this.handelMasterVehicleAvailabilityModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>Vehicle Master</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formMasterVehicleAvailability}>

                        <div className="row number">

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_TYPE}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.vehicleTypeChanged}
                                        name="vehicle_type"
                                        value={this.state.vehicle_type}
                                        options={this.state.vehicleTypeList} 
                                        />

                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Vehicle Number</label>
                                    <input type="text" 
                                        className= "forminp form-control"
                                        required 
                                        name="vehicle_no" 
                                        value={this.state.vehicle_no}
                                        onChange={this.vehicleAvailabilityInputFields} 
                                        placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div>                           

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Transporter Name</label>
                                    {/* <input type="text" className="forminp form-control" name="transporter_name" value={this.state.transporter_name} 
                                    onChange={this.vehicleConfigurationInputFields} autoComplete='off' 
                                    /> */}
                                    <Select
                                    placeholder={"Select"}
                                    onChange={this.transporterChanged}
                                    name="transporter_name"
                                    value={this.state.transporter_name}
                                    options={this.state.transporterList} 
                                    isDisabled = { transporterConfiguration ? false : true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label htmlFor="vehicle_priority" className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_PRIORITY}</label>

                                    <select name="vehicle_priority" id="vehicle_priority" value = {this.state.vehicle_priority}
                                        required
                                        className= "forminp form-control"
                                        onChange={this.vehicleAvailabilityInputFields}>
                                        {/* <option value="" disabled selected>Select</option> */}
                                        <option value="Owned">Owned</option>
                                        <option value="Contract">Contract</option>
                                        <option value="Market">Market</option>
                                    </select>
                                </div>
                            </div>                            
                            
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMasterVehicleAvailabilityModelCancel}>CANCEL</button>	
                        </div>
                       
                    </form>
                </div>
                    </Modal>

                    <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                    <div className={"sliderBlock2 "+(this.state.showSlideBlock)}>
                    <div className="row">   
                        <div className="col-sm-12">
                        <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                        <div className="row p-20p">
                            <div className="form-group col-sm-12">
                                            <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>                            
                            </div>
                            <div className="form-group col-sm-12 mb-20p">                                
                                <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                            </div>
                            <div className="form-group col-sm-12 mb-20p">
                            <button type="submit" id="bulkUploadBtn" className="btn btn-danger">SAVE</button>
                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                            </div>
                            <div id="inValidDataInfo" style={{color:"red"}}></div>
                        </div>
                        </form>
                        </div>
                    </div>
                    </div>


                    <Modal closeOnOverlayClick={false} open={this.state.openVehiclePriorityConfigurationModel} onClose={this.handelVehiclePriorityConfigurationModelCancel} 
                    classNames={{modal: 'vehiclepriority-modelClass'}} >
                        <div className="col-sm-12">

                            <div className="mb-10p">
                                <h4>Set Vehicle Priority</h4>
                            </div>
                        <div className="col-md-12 col-sm-12 d-flex justify-content-around">
                            <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">Owned</h5>
                                <div className="form-group col-md-6">
                                    
                                    <select name="vehicle_priority_order_owned" id="vehicle_priority_order_owned" value = {this.state.vehicle_priority_order_owned}
                                        className= "forminp form-control" onChange={this.vehicleAvailabilityInputFields}>
                                        
                                        <option value="1">High</option>
                                        <option value="2">Medium</option>
                                        <option value="3">Low</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 ">
                            <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">Contract</h5>
                                <div className="form-group col-md-6">
                                    
                                    <select name="vehicle_priority_order_contract" id="vehicle_priority_order_contract" value = {this.state.vehicle_priority_order_contract}
                                        className= "forminp form-control" onChange={this.vehicleAvailabilityInputFields}>
                                        
                                        <option value="1">High</option>
                                        <option value="2">Medium</option>
                                        <option value="3">Low</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 ">
                            <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">Market</h5>
                                <div className="form-group col-md-6">
                                    
                                    <select name="vehicle_priority_order_market" id="vehicle_priority_order_market" value = {this.state.vehicle_priority_order_market}
                                        className= "forminp form-control" onChange={this.vehicleAvailabilityInputFields}>
                                        
                                        <option value="1">High</option>
                                        <option value="2">Medium</option>
                                        <option value="3">Low</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group">
                            <button type="button" onClick={this.saveVehiclePriorityOrder} className="btn tbtn float-right save-btn" >SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelVehiclePriorityConfigurationModelCancel}>CANCEL</button>	
                        </div>
                            </div>
                            </Modal>
            </>
        )
    }
}