
import React, { Component } from 'react';

 
const AddLocation = (props) => {
    
   const handleOnClick = () => {
        props.context.componentParent.onClickShowServiceLocation(props,true);
    };
    return (
        <div>
           <button type="button" onClick={handleOnClick} className="btn btn-warning grid-btn" style={{color:"#000",border:"0px"}}>
                <i className={"fa fa-plus"}></i> Add Location
            </button>
        </div>
    );
};

export default AddLocation;
