import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class ColumnChartComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		 
			chart: {
                type:"spline"
            },
			title: {
				align: 'left',
				text: this.props.title
			},
			subtitle: {
				align: 'left',
				text: this.props.subtitle
			},
			accessibility: {
				announceNewData: {
					enabled: true
				}
			},
			pane: {
                startAngle: 0,
                endAngle: 360
            },
        
            xAxis: {
				type:"category"
                // categories: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec','Jan', 'Feb', 'Mar']
            },
        
			legend: {
				enabled: true
			},
			plotOptions: {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '{point.y:,.0f}'
					}
				}
			},
		
			tooltip: {
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.0f}'
			},
		
			series: this.props.series,
			drilldown: {},
			credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












