
import React, { Component } from 'react';

 
const GridButton = (props) => {
    
   const handleOnClick = () => {
        props.colDef.params.onClickFunction(props,true);
    };
    var iconName = "fa fa-edit";
    if(props.colDef.params.iconName !=undefined && props.colDef.params.iconName !="")
    {
        iconName = props.colDef.params.iconName;
    }
    var gButton = "btn btn-warning";
    if(props.colDef.params.buttonCls !=undefined && props.colDef.params.buttonCls !="")
    {
        gButton = props.colDef.params.buttonCls;
    }
    return (
        <div style={{"textAlign":"center"}}>
           
            <button className={gButton+" grid-btn"} onClick={handleOnClick} style={{border:"0px"}}>
                <i className={iconName}></i> {props.colDef.params.buttonName}
            </button>
            
        </div>
    );
};

export default GridButton;
