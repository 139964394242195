import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);

export default class AnomalyColumnChart extends Component {

    constructor(props) {
        super(props);
		this.state={
			seriesData:[]
		}
		// console.log(props,'prop')
    }

	componentWillReceiveProps(newprops){
		// console.log(newprops,'newprops')
		setTimeout(()=>{

		if(newprops.series != undefined && newprops.showMaterialCount == 2){
			this.setState({
				seriesData : newprops.series
			})
		}
	},500);
		
	}

    render(){
		const dStyles={
            width:'100%',
            height:'100%'
        }
		
		var highchartoptions = {
	      		 
			chart: {
                type: 'column',
                height:"500px"
            },
        
            title: {
                text: this.props.title,
                align: 'left'
            },
        
            xAxis: {
                type:"category"
            },
        
            yAxis: {
                
                type: 'logarithmic',
                allowDecimals: false,
                title:{
                    text:this.props.yAxisTitle
                }
            },
        
            tooltip: {
                format: '<b>{key}</b><br/>{series.name}: {y}<br/>' +
                    'Cost: $ {point.y}'
            },
        
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        // format: '$ {point.y}'
                        formatter: function() {
                            if(this.series.name !== "Actual Material Count" && this.series.name !== 'Predicted Material Count' && this.series.name !== 'Actual New Material Count' && this.series.name !== 'Predicted New Material Count')
                            {
                                var series = this.series.chart.series,
                                    result = '$ ' + this.point.y + "M";
                            
                            
                                return result;
                                
                            }
                            else{
                                return this.point.y;
                            }
                        }
                    }
                }
            },
            // colors:["#0D95FF", "#ff0000"],
            series: this.props.series,
			credits:false
        };

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












