import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import getToolConfiguration from "../optirun-admin/commonFunctions";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

export default class Dashboard extends Component {
	constructor(props){
        super(props);
        this.state={
			totalBookings:0,
			pendingAtVendorQty:0,
			pendingAtQA:0,
			approvedQty:0,
			totalCompleted:0,
			dispatchPlanningModel:false,
		}
		localStorage.removeItem("module_type","");
		localStorage.removeItem("data_type","");
		localStorage.removeItem("both_data_types_enabled_for_dispatch","");
		localStorage.removeItem("both_data_types_enabled_for_stacking","");
	}
	async componentDidMount(){
        await getToolConfiguration()
	}
	redirectToModule = function(moduleType) {
		var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
		if(toolConfig !=undefined && toolConfig !="")
		{
			if(moduleType == 1)
			{
				var dispatchConfig = toolConfig.filter((tc) => tc.module_type == 1);
				if(dispatchConfig.length > 0)
				{
					var dispatchConfiginbound = dispatchConfig.filter((tc) => tc.data_type == 1);
					var dispatchConfigoutbound = dispatchConfig.filter((tc) => tc.data_type == 2);
					if(dispatchConfiginbound.length > 0 && dispatchConfigoutbound.length == 0)
					{
						localStorage.setItem("module_type",1);
						localStorage.setItem("data_type",1);
						localStorage.setItem("both_data_types_enabled_for_dispatch",0);
						window.location.href = "/loadprocessrun?inbound";
					}
					else if(dispatchConfigoutbound.length > 0 && dispatchConfiginbound.length == 0)
					{
						localStorage.setItem("module_type",1);
						localStorage.setItem("data_type",1);
						localStorage.setItem("both_data_types_enabled_for_dispatch",0);
						window.location.href = "/loadprocessrun?outbound";
					}
					else
					{
						// window.location.href = "/dispatchPlanningHomePage";
						window.location.href = "/loadprocessrun?inbound";
						localStorage.setItem("module_type",1);
						localStorage.setItem("data_type",1);
						localStorage.setItem("both_data_types_enabled_for_dispatch",1);
					}
				}
			}
			if(moduleType == 2)
			{
				var dispatchConfig = toolConfig.filter((tc) => tc.module_type == 2);
				if(dispatchConfig.length > 0)
				{
					var dispatchConfiginbound = dispatchConfig.filter((tc) => tc.data_type == 1);
					var dispatchConfigoutbound = dispatchConfig.filter((tc) => tc.data_type == 2);
					if(dispatchConfiginbound.length > 0 && dispatchConfigoutbound.length == 0)
					{
						localStorage.setItem("module_type",2);
						localStorage.setItem("data_type",1);
						localStorage.setItem("both_data_types_enabled_for_stacking",0);
						window.location.href = "/stackplan?inbound";
					}
					else if(dispatchConfigoutbound.length > 0 && dispatchConfiginbound.length == 0)
					{
						localStorage.setItem("module_type",2);
						localStorage.setItem("data_type",1);
						localStorage.setItem("both_data_types_enabled_for_stacking",0);
						window.location.href = "/stackplan?outbound";
					}
					else
					{
						// window.location.href = "/dispatchPlanningHomePage";
						window.location.href = "/stackplan?inbound";
						localStorage.setItem("module_type",2);
						localStorage.setItem("data_type",1);
						localStorage.setItem("both_data_types_enabled_for_stacking",1);
					}
				}
				else
				{
				  	localStorage.setItem("module_type",2);
				  	window.location.href = "/stackplan";
				}
			}
			if(moduleType == 4)
			{
				var dispatchConfig = toolConfig.filter((tc) => tc.module_type == 2);
				window.location.href = "/forecast-input";
				
			}
		}
		else
		{
			alert("No Access...");
		}
	}.bind(this);
	handeldispatchPlanningModelCancel = ()=>{
		this.setState(prevState =>({
			dispatchPlanningModel :!prevState.dispatchPlanningModel
		})
	)}
    render(){
		if(localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined )
        {
          return <Navigate to={"/login"} push={true} />
        }
        return (
            <div className="row mt9">
				<div className="col-md-2 widget widget_tally_box" onClick={() => { this.redirectToModule(1) }}>
					<div className="x_panel p50tb10lr hvr">
						<div className="x_content center">
							<h2 className="dTile bgc2fdc1">
							{/*<span className="glyphicon glyphicon-random" aria-hidden="true"></span>*/}
							<img src={require("../../assets/images/dispatch_planning.png")} />
							</h2>
						</div>
						<div className="x_content center">
							<h4 className="h4custom">Dispatch Planning</h4>
						</div>

					</div>
					
				</div>
				<div className="col-md-2 widget widget_tally_box" onClick={() => { this.redirectToModule(2) }}>
					<div className="x_panel p50tb10lr hvr">
						<div className="x_content center">
							<h2 className="dTile bgfdfbc1">
							{/*<span className="glyphicon glyphicon-th" aria-hidden="true"></span>*/}
								<img src={require("../../assets/images/stacking.png")} />
							</h2>
						</div>
						<div className="x_content center">
							<h4 className="h4custom">Stacking</h4>
						</div>

					</div>
				</div>
				<div className="col-md-2 widget widget_tally_box">
					<div className="x_panel p50tb10lr hvr">
						<div className="x_content center">
							<h2 className="dTile bgd8c1fd">
								{/*<span className="glyphicon glyphicon-cog" aria-hidden="true"></span>*/}
								<img src={require("../../assets/images/production_planning.png")} />
							</h2>
						</div>
						<div className="x_content center">
							<h4 className="h4custom">Production Planning</h4>
						</div>

					</div>
					
				</div>
				<div className="col-md-2 widget widget_tally_box" onClick={() => { this.redirectToModule(4) }}>
					<div className="x_panel p50tb10lr hvr">
						<div className="x_content center">
							<h2 className="dTile bgc1fdf7">
								{/*<span className="glyphicon glyphicon-facetime-video" aria-hidden="true"></span>*/}
								<img src={require("../../assets/images/forecasting.png")} />
							</h2>
						</div>
						<div className="x_content center">
							<h4 className="h4custom">Forecasting</h4>
						</div>

					</div>
					
				</div>				
				<div className="col-md-2 widget widget_tally_box">
					<div className="x_panel p50tb10lr hvr">
						<div className="x_content center">
							<h2 className="dTile bgfdc1cf">
								{/*<span className="glyphicon glyphicon-hourglass" aria-hidden="true"></span>*/}
								<img src={require("../../assets/images/resource_schedule.png")} />
							</h2>
						</div>
						<div className="x_content center">
							<h4 className="h4custom">Resource Scheduling</h4>
						</div>

					</div>
					
				</div>		
				<Modal closeOnOverlayClick={false} open={this.state.dispatchPlanningModel} onClose={this.handeldispatchPlanningModelCancel} classNames={{modal: 'pickupdrop-modelClass'}} >
					<div className="col-sm-6">
						<div className="x_panel p20tb10lr hvr">
							<div className="x_content center">
								<h2 className="dTile bgfdc1cf">
									<i className="fa fa-truck" aria-hidden="true"></i>
								</h2>
							</div>
							<div className="x_content center">
								<h4 className="h4customdata">Inbound</h4>
							</div>

						</div>
					</div>
					<div className="col-sm-6">
						<div className="x_panel p20tb10lr hvr">
							<div className="x_content center">
								<h2 className="dTile bgd8c1fd">
									<i className="fa fa-truck" aria-hidden="true"></i>
								</h2>
							</div>
							<div className="x_content center">
								<h4 className="h4customdata">Outbound</h4>
							</div>

						</div>
					</div>
				</Modal>
			</div>
        );
    }
}


function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map11;
}