import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Constant from "../common/constants";
import  draggable from '../../../node_modules/jquery-ui/ui/widgets/draggable';
import SweetAlert from 'react-bootstrap-sweetalert';
import ViewPartsData from "./viewpartsdata";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';

import redirectURL from '../redirectURL';
var infoBox = require('../common/google-infowindow');
var moment = require("moment");
var map;
var overlay;
var infowindows=[];
var markersArraylist=[];

var iconWidth = 64;
var iconHeight = 64;

Array.prototype.itemIndex = function(key, item) {
    for (var i = 0; i < this.length; i++) {
      if(this[i][key] == item) {
        return i;
      }
    }
    return -1;
};

export default class ClusterRoute extends Component {
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            frameworkComponents:{
                ViewPartsData:ViewPartsData
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:200,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showmapslider:"",
            showmapsliderOverly:"show-n",
            vehiclelist:[],
            dealers:[],
            plants:[],
            allbtn:"btn-warning",
            loadebtn:"btn-default",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            clusterlist:[],
            filtercluster:{"value":"","label":"All"},
            showloader:"show-n",
            overlayBlock:"show-n",
            suplierslist:[]
        }
        window.showSidebar = this.showSidebar.bind(this);
        this.maploadData = this.maploadData.bind(this);
        window.refreshMap = this.refreshMap.bind(this);
        this.loadClusterData = this.loadClusterData.bind(this);
        this.changeDeliveryDate = this.changeDeliveryDate.bind(this);
        this.changeCluster = this.changeCluster.bind(this);
        this.hideSlideBlock = this.hideSlideBlock.bind(this);
    }
    componentDidMount = async () =>{
        redirectURL.post("/plants/list")
            .then((response) => {
              var records = response.data;
              this.setState({
                plants:records
              })
            });
       this.loadClusterData();

    }
    loadClusterData(){
        this.setState({
            showloader:"show-m"
        })
        redirectURL.post("/dealers/loadplan")
        .then(async (response) => {
            var records = response.data;
            // console.log("records ", records)
            var deliverydates = [];
            records = records.sort(GetSortAscOrder("cluster"));
                
            var allsuplierslist= [];
            var recordsarr = groupBy(records, rdata => rdata.cluster);
            var loaddata = [];
            var allLoadPlan = [];
            var clusterlist=[];
            try{
                clusterlist.push({
                    value:"",
                    label:"All"
                })
                
            var colors = ["#2152BC","#BC2196","#21BCB5","#BCBC21", "#BC6721","#BC2121","#005B89","#D0CE32","#002C89","#4FD032","#32D0C9","#8132D0","#CB32D0","#D0327A"]
            var sn=0;
            recordsarr.forEach((item,key) => {
                
                var suplierslist= [];
                var color=colors[sn];
                var arritems = []
                if(item.length > 0)
                {
                    item.map((i,index) => {
                        // if(index < 100)
                        // {
                            arritems.push(i)
                        // }
                        
                    })
                }
                clusterlist.push({
                    value:key,
                    label:"Cluster "+key
                })
                
                // console.log("Cluster "+key)
               var delts = groupBy(item,rdata => rdata.dealer_code);
            //    console.log("delts ", delts)
                delts.forEach((delr,k) => {
                    var delrs = groupBy(delr,rdata => rdata.type);
                    delrs.forEach((mm,kl) => {
                        if(kl == "SUPPLIER")
                        {
                            suplierslist.push(mm[0])
                        }
                    })
                    
                })
                var rw = {
                    cluster:key,
                    plotdata:suplierslist,
                    color:color
                }
                loaddata.push(rw);
                allLoadPlan.push(rw);
                sn=sn+1;
              })
            }
            catch(e){
    
            }
            // console.log("suplierslist ", suplierslist)
            // var newArray = [];
            // let totuniqueObject = {}; 
            // var totobjTitle;
            // // Loop for the array elements 
            // for (let i in suplierslist) { 

            //     // Extract the title
            //     if(suplierslist[i]['dealer_code'] != "")
            //     {
            //         totobjTitle = suplierslist[i]['dealer_code']; 

            //         // Use the title as the index 
            //         totuniqueObject[totobjTitle] = suplierslist[i];
            //     } 
                 
            // } 

            // // Loop to push unique object into array 
            // for (var a in totuniqueObject) { 
            //     newArray.push(totuniqueObject[a]); 
            // } 
            // console.log("newArray ", newArray)
            await this.setState({
                loadplan:loaddata,
                // suplierslist:suplierslist,
                allLoadPlan:allLoadPlan,
                overlayBlock:"show-n",
                clusterlist:clusterlist,
                // filtercluster:clusterlist[1],
                showloader:"show-n"
            })
            // await this.renderMap()
            this.onClickUpdateMap();
            
        })
    }
    renderMap = () => {
        loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing,geometry&callback=initMap");
        loadScript1();
        loadScript2();
        window.initMap = this.initMap
    }
    
    initMap = () => 
    {
        var currentwindow;
        
        var mapinfo = this.state.loadplan;
        var lt=17.432143; 
        var ln=78.374427;
        
        this.setState({
            dealerCode:[]
        });
        var mapOptions = {
            zoom: 11,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels:true,
            mapTypeControlOptions: {
                mapTypeIds: ['satellite', 'roadmap'],
            },
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            styles: [
                    
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
            ],
        };
        try{
            map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        }catch(e){}
        
        // var bounds = new window.google.maps.LatLngBounds();
        this.maploadData();
        
        
        overlay = new window.google.maps.OverlayView();
        overlay.draw = function() {};
        overlay.setMap(map);
		// map.fitBounds(bounds)
	 
        window.google.maps.event.addListener(map, 'click', (function() {
            closeAllInfoWindows()
        }))
    }

    maploadData(){
        var currentwindow;
        for (let i = 0; i < markersArraylist.length; i++) {
            markersArraylist[i].setMap(null);
        }
        var mapinfo = this.state.loadplan;
        
        var allplants=this.state.plants;
        var bounds = new window.google.maps.LatLngBounds();
        if(mapinfo.length > 0)
        {    
            // console.log("mapinfo ", mapinfo)
            mapinfo.map((item) => {
                var plots = item.plotdata;
                if(plots.length > 0)
                {
                    plots.map((plot) => {
                        // if(plot.type == "PLANT")
                        // {
                        //     allplants.push(plot)
                        // }
                        bounds.extend(new window.google.maps.LatLng(parseFloat(plot.lat),parseFloat(plot.lng)));
                        
                        var marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(parseFloat(plot.lat),parseFloat(plot.lng)),
                            sName: plot.dealer_name,
                            map: map,
                            icon: {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 8.5,
                                fillColor: item.color,
                                fillOpacity: 1,
                                strokeWeight: 0.4
                            },
                        });
                        window.google.maps.event.addListener(marker, 'click', (function(marker) {
                            return function() {
                                //	console.log(clusters[0])
                                var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = plot.dealer_name
                            contentarr.push({"key":"Location", "value":plot.location})
                            // contentarr.push({"key":"Vehicle Name", "value":plot.vehicle_name})
                            // contentarr.push({"key":"Vehicle Type", "value":plot.vehicle_type})
                            // contentarr.push({"key":"Capacity", "value":plot.vehicle_capacity})
                            // contentarr.push({"key":"Weight", "value":plot.vehicle_weight})
                            // contentarr.push({"key":"Part No", "value":plot.part_no})
                            // contentarr.push({"key":"Part Name", "value":plot.part_name})
                            // contentarr.push({"key":"Part Quantity", "value":plot.part_qty})
                            // contentarr.push({"key":"Part Volume", "value":plot.part_volume})
                            // contentarr.push({"key":"Part Weight", "value":plot.part_weight})
                            var contentString = infoBox("", header, contentarr,'')
                                                    
                            infowindow.setContent(contentString);
                            // currentwindow = infowindow;
                            
                            infowindows.push(infowindow);
                            infowindow.open(map, marker);
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                            }
                        })(marker));
                    })
                }   
            
            })         
            // 
            try{
                var infowindow = new window.google.maps.InfoWindow();
                // console.log("allplants ", allplants)
                if(allplants.length > 0)
                {
                    var markers= []
                for (let i = 0; i < allplants.length; i++) {
                //if(arr[i].speed > 0)
                // {
                    // console.log("allplants[i].latitude ", allplants[i].latitude)
                    bounds.extend(new window.google.maps.LatLng(parseFloat(allplants[i].latitude), parseFloat(allplants[i].longitude)));
                        
                    var markerLatlng = new window.google.maps.LatLng(parseFloat(allplants[i].latitude), parseFloat(allplants[i].longitude));
                    var image =  require('../../assets/images/business.png');

                    var mark = new window.google.maps.Marker({
                        position: markerLatlng,
                        map: map,
                        title:allplants[i].plant_name,
                        icon: image
                    });
                    markers.push(mark)
                    mark.addListener('click', function() {
                        var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = "PLANT"
                        contentarr.push({"key":"Plant Name", "value":allplants[i].plant_name});
                        var contentString = infoBox(mark.icon, header, contentarr,'')
                            
                        infowindow.setContent(contentString);	
                        infowindow.setContent(contentString);
                        currentwindow = infowindow;
                        
                        infowindows.push(infowindow);
                        infowindow.open(map, mark);
                    });
                    }
                }
                
            }catch(e){}


        }
            
        var v = map.getZoom();
        // console.log("VVV ", v)
        map.setZoom(v)
        map.fitBounds(bounds)
    }
    refreshMap(){
        this.maploadData();
    }
    showSidebar(){
        // console.log("S1")
        this.setState({
            showmapslider:"slide35",
            showmapsliderOverly:"show-m"
        })
    }
    hideSlideBlock(){
        this.setState({
            overlayBlock:"show-n"
        })
    }
    changeDeliveryDate = (filterDeliveryDate) =>{
        this.setState({filterDeliveryDate},() => {
            console.log(filterDeliveryDate)
           
        })
    }
    changeCluster = (filtercluster) => {
        this.setState({filtercluster},() => {
           this.onClickUpdateMap();
        });
    }
    
    onClickUpdateMap(){
        this.setState({
            // overlayBlock:"show-m",
            showloader:"show-m"
        })
        setTimeout(() => {
            var allclusterdata = this.state.allLoadPlan;
            var selectedCluster = [];
            var suplierslist=[];
            var allplants= [];
            if(this.state.filtercluster.value != "")
            {
                if(allclusterdata.length > 0)
                {
                    allclusterdata.map((item) => {
                        if(item.cluster == this.state.filtercluster.value)
                        {
                            selectedCluster.push(item)
                        }
                    })
                }
                // console.log("selectedCluster ", selectedCluster)
                var delts = groupBy(selectedCluster[0].plotdata,rdata => rdata.dealer_code);
                delts.forEach((delr,k) => {
                    var delrs = groupBy(delr,rdata => rdata.type);
                    delrs.forEach((mm,kl) => {
                        if(kl == "SUPPLIER")
                        {
                            suplierslist.push(mm[0])
                        }
                    })
                    
                })

                this.setState({
                    loadplan:selectedCluster,
                    suplierslist:suplierslist,
                    overlayBlock:"show-n",
                    showloader:"show-n"
                })

                
                this.renderMap()
                
            }
            else{
                // console.log("allclusterdata ", allclusterdata)
                var suplierslist = [];
                if(allclusterdata.length > 0)
                {
                    allclusterdata.map((utem) => {
                        var delts = groupBy(utem.plotdata,rdata => rdata.dealer_code);
                        delts.forEach((delr,k) => {
                            var delrs = groupBy(delr,rdata => rdata.type);
                            delrs.forEach((mm,kl) => {
                                if(kl == "SUPPLIER")
                                {
                                    suplierslist.push(mm[0])
                                }
                            })
                            
                        })
                    })
                }
                
                this.setState({
                    loadplan:allclusterdata,
                    suplierslist:suplierslist,
                    overlayBlock:"show-n",
                    showloader:"show-n"
                })
                this.renderMap()
            }
        
        },2000)

    }

    onClickShowSupplierParts = async(rownode) =>  {
        var filtercluster= this.state.filtercluster.value;
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: [
                    {
                        headerName:"Cluster",
                        field:"cluster",
                        width:"100"
                    },
                    {
                        headerName:"Unique Part No",
                        field:"unique_part_no"
                    },
                    {
                        headerName:"Part No",
                        field:"part_no",
                        width:"120"
                    },{
                        headerName:"Part Name",
                        field:"part_name"
                    },
                    // {
                    //     headerName:"Part Qty",
                    //     field:"part_qty",
                    //     width:"100"
                    // },
                    {
                        headerName:"Part Volume",
                        field:"part_volume",
                        width:"100"
                    },{
                        headerName:"Part Weight",
                        field:"part_weight",
                        width:"100"
                    },
                   
                ],
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    // console.log("Step 4 ",param)
                    // console.log("reqparams",filtercluster);
                    var reqprm = {
                        dealer_code : param.data.dealer_code
                    }
                    if(filtercluster != "" && filtercluster != undefined)
                    {
                        reqprm.cluster = param.data.cluster;                        
                    }
                    await redirectURL.post("/dealers/plandealerparts",reqprm).then(async (response) =>{
                        // console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var records=response.data;
                        records = records.sort(GetSortAscOrder("sequence_no"));
                        var parts = groupBy(records,rdata => rdata.part_no);
                        // console.log("Step 2 ",parts)
                        var legsdata = [];
                        await parts.forEach((im,k) => {
                            legsdata.push(im[0])
                            
                        })
                        await param.successCallback(legsdata);
                        
                        
                        
                    }).catch(function(error){
                        console.log(error);
                    })
                     
                },
                masterDetail: true
            }
        });
        // console.log(rownode.column.colDef.field);
        if(rownode.column.colDef.field == 'dealer_code')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
    render() {
        var columnwithDefs = [
            {
                headerName:"",
                field:"dealer_code",
                width:"80",
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewPartsData"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"Supplier Code",
                field:"dealer_code",
                width:"150",
            },
            {
                headerName:"Supplier Name",
                field:"dealer_name",
                width:"200",
            },
            {
                headerName:"Supplier Location",
                field:"location",
                width:"250",
            },
        ];
        return (
            <div class="container-fluid">
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                    <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
                </SweetAlert>
                {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            <i className="fa fa-users"></i> {Constant.PAGE_TITLE_CULSTER_SCREEN}
                            <div className="col-sm-2 float-right">
                                <div className="form-group f14" style={{color:"#000"}}>
                                    <label>Select Cluster</label>
                                    <Select 
                                        placeholder={"Select Cluster"}
                                        onChange={this.changeCluster} 
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.filtercluster}
                                        options={this.state.clusterlist} 
                                    />
                                </div>
                            </div>
                        </h5>
                    </div>
                </div>
                <div class="row mb-20p" style={{position:"relative"}}>
                    <div className="col-sm-7">
                        <div id="map" className="card" style={{width:"100%",height:"85vh"}}></div>
                       
                    </div>
                    <div className="col-sm-5">
                        <div id="myGrid" style={{width:"100%",height:"85vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.suplierslist}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                   
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                
                    
                </div>
            
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function loadScript1(){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function loadScript2(){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src="https://ajax.googleapis.com/ajax/libs/jqueryui/1.12.0/jquery-ui.min.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  
  function closeAllInfoWindows() {
    for (var i=0;i<infowindows.length;i++) {
        infowindows[i].close();
    }
}  


