import redirectURL from '../redirectURL';

const getStackingToolConfiguration = async (params) =>{
   await redirectURL.post('stacking/getStackingToolConfiguration',params)
            .then(response =>{
                if(response.data.status == 'success'){
                    let stackingToolConfigData = response.data.stackingToolConfigData
                    localStorage.setItem("tlcfg",JSON.stringify(stackingToolConfigData))
                }
            })
}

export default getStackingToolConfiguration
