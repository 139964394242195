import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class SplineChartComponent extends Component {

    constructor(props) {
        super(props);
		this.state={
			seriesData:[]
		}
		// console.log(props,'prop')
    }

	componentWillReceiveProps(newprops){
		// console.log(newprops,'newprops')
		setTimeout(()=>{

		if(newprops.series != undefined && newprops.showMaterialCount == 2){
			this.setState({
				seriesData : newprops.series
			})
		}
	},500);
		
	}

    render(){
		// let categories = ["2010-2011","2011-2012","2012-2013","2013-2014","2014-2015","2015-2016","2016-2017","2017-2018","2018-2019","2019-2020"]
		let categories = ["Jan-2020","Feb-2020","March-2020","April-2020","May-2020","June-2020"]
		
		// console.log(this.props)
        let actualOrderQuantity = []
        let predictedOrderQuantity = []
        if(this.props.drillDownData.length>0){
            actualOrderQuantity = this.props.drillDownData[0];
            predictedOrderQuantity = this.props.drillDownData[1];
        }
		
		
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		let highchartoptions

		let twoLines = [
			{
                type: 'spline',
                showInLegend: true,
                name: 'Actual Order Quantity',
                data: actualOrderQuantity,
				color:'#457b9d',
                marker: {
                    enabled: false
                },
				
            },
			{
				type: 'spline',
				showInLegend: true,
				name: 'Predicted Order Quantity',
				color:'#52b788',
				data: predictedOrderQuantity,
				marker: {
					enabled: false
				},
				
			},
            
		]
		

			

		
		
		highchartoptions = {
	      		 
				chart: {
					type: 'line',
				},
				title: {
					align: 'left',
					text: this.props.title
				},
				subtitle: {
					align: 'right',
					text: this.props.subtitle,
                    style: {
						color: 'red',
						fontSize: '10px',
						fontWeight: 'bold'
					}
				},
				accessibility: {
					announceNewData: {
						enabled: true
					}
				},
				xAxis: {
					// type: 'datetime'
					// categories:["2011-2012","2012-2013","2013-2014","2014-2015","2015-2016","2016-2017","2017-2018","2018-2019","2019-2020","2020-2021","2021-2022"]
					categories:categories
				},
				yAxis: {title: {
					text: 'Order Quantity',}
                },
				legend: {
					enabled: true
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							format: '{point.lbl}'
						},
                        
					},
                    
                    
				},
			
				// tooltip: {
				// 	headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				// 	pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.lbl}'
				// },
			
				series: twoLines,
				drilldown: {},
				credits:false
				};
		

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












