
import React, { Component } from 'react';
import Constant from "../common/constants";

var infoBox = require('../common/google-infowindow');

var map
var overlay;
var infowindows=[];
var markersArraylist=[];



export default class ViewMap extends Component {
    constructor(props){
        super(props);
    this.state = {
        previousData : []
    }
    }
    
    async componentDidMount(){
		var curl = window.location.search;
        var ul= curl.split("?");
        let url_type = ul[parseInt(ul.length)-1];
        let data_type
        
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
    	this.renderMap();

    }

    renderMap = () => {

        //   loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}


    initMap = () => 
    {
        this.setState({previousData:this.props.locationsData})

        var lt=17.432143; 
        var ln=78.374427;
        
        var mapOptions = {
            zoom: 11,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels:true,
            mapTypeControlOptions: {
                mapTypeIds: ['satellite', 'roadmap'],
            },
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            styles: [
                    
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 65
						},
						{
							"visibility": "on"
						}
					]
				},
				
				{
					"featureType": "landscape.man_made",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#c1c2c2"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
			],
        };
        try{
            map = new window.google.maps.Map(document.getElementById('pickupDrop_Locations_map'), mapOptions);
        }catch(e){}
        
        
        var bounds = new window.google.maps.LatLngBounds();
        this.maploadData();

		// const iconBase = require("../../assets/images/");
		const icons = {
			plant: {
			name: "Plant",
			icon: require("../../assets/images/plant-location.png"),
			},
			supplier: {
			name: "Vendor",
			icon: require("../../assets/images/customer_pin_38.png"),
			},
			both: {
			name: "Both",
			icon: require("../../assets/images/business.png"),
			},
			
		};
        
        var legend = document.getElementById("legend");
		legend.innerHTML = '';
		for (var key in icons) {
			var type = icons[key];
			var name = type.name;
			var icon = type.icon;
			var div = document.createElement("div");
			
			div.innerHTML = '<img class="legend-height" src="' + icon + '"> ' + name;
			legend.appendChild(div);
		}

		map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(legend);

        overlay = new window.google.maps.OverlayView();
        overlay.draw = function() {};
        overlay.setMap(map);
	 
        window.google.maps.event.addListener(map, 'click', (function() {
            closeAllInfoWindows()
        }))
    }

    maploadData = () =>{
		let data_type = this.state.data_type
		
        for (let i = 0; i < markersArraylist.length; i++) {
            markersArraylist[i].setMap(null);
        }
        
        var locations = this.props.locationsData;

        var lt=17.432143; 
        var ln=78.374427;
        
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng((lt),(ln)));
    
        try{
            var infowindow = new window.google.maps.InfoWindow();
                
            if(locations.length > 0)
            {

                var image =  require('../../assets/images/business.png');
                    
                locations.map(function(e,index){
                    if(e.location_type == 1){
						if(data_type == 2){
							var image =  require('../../assets/images/plant-location.png');
						}else{
                        var image =  require('../../assets/images/customer_pin_38.png');
						}
                    }else if(e.location_type == 3){
                        var image =  require('../../assets/images/business.png');
                    }else{
						if(data_type == 2){
							var image =  require('../../assets/images/customer_pin_38.png');
						}else{
                        var image =  require('../../assets/images/plant-location.png');
						}
                    }
                    bounds.extend(new window.google.maps.LatLng((e.latitude),(e.longitude)));
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng((e.latitude), (e.longitude)),
                        icon: image,
                        map: map,	
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            
                            var contentarr = []
                        
                        var header = e.location_name
						contentarr.push({"key":"Location Code", "value":e.location_code})
                        contentarr.push({"key":"Location Address", "value":e.location_address})
                        // contentarr.push({"key":"Start Time", "value":e.from_time})
                        // contentarr.push({"key":"End Time", "value":e.to_time})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        infowindows.push(infowindow);
                        
                        infowindow.open(map, marker);
                        
                        }
                    })(marker));
                })  
                
            }
            
            map.fitBounds(bounds)
        }catch(e){}
    
    }

    updateMap = () =>{
        let previousData = this.state.previousData
        if(previousData == this.props.locationsData){
            
        }else{
            
            this.renderMap()
        }
        
    }

  render(){
    
    this.updateMap()
    return (
			<div className=''>
            <div id="pickupDrop_Locations_map" className="col-xl-12 col-lg-12 ml-0p" style={{width:'100%',height:"70vh", borderRadius:"4px"}}></div>
			<div id="legend" className='d-flex' style={{color:'#000'}}></div>
			</div>
    );
  }
};

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function closeAllInfoWindows() {
  for (var i=0;i<infowindows.length;i++) {
      infowindows[i].close();
  }
}
