import { useState, useEffect } from "react";
import Select from "react-select";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import AGgridCellDetailComponent from "./AggridCellDetailComponent";
import redirectURL from '../redirectURL';
import { ExcelExportModule, MenuModule } from "@ag-grid-enterprise/all-modules";
import MaterialDataChart from './materialChartDataChart.js';
import * as XLSX from 'xlsx';
import moment from "moment";
import LineChart from "./lineChart";
import AreaRangeChart from "./areaRangeChart";
import ConfidenceChart from "./confidenceChart";
import SweetAlert from "react-bootstrap-sweetalert";




const customStyles = {
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        fontSize: "14px",
        color: "black" // Set font-weight to bold for selected options
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontSize: "14px",
        fontWeight: 'bold', // Always display the selected option in bold
        color: "black"
    }),
};


const SummaryData = () => {
    const [phase, setPhase] = useState("");
    const [phaseList, setPhaseList] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [frameworkComponents, setFrameworkComponents] = useState({});
    const [statusBar, setStatusBar] = useState({});
    const [sideBar, setSideBar] = useState({});
    const [loadShow, setLoadShow] = useState({
        loadshow: 'show-n',
        overly: 'show-n',      
        overlay: 'show-n',      
        overlayBlock: "show-n"
    })
    const [slider, setSlider] = useState({
        showSlideBlockUpload:"",
        sliderType: "",
        sliderTitle: "",
    })

    // slider 
    const [sliderCl, setSliderCl] = useState("")
    const [sliderTitle, setSliderTitle] = useState("");
    const [isSliderOpen, setIsSliderOpen] = useState(false)
    const [sliderType, setSliderType] = useState(null)
    const [fileName, setFileName] = useState("")
    const [closeSlider, setCloseSlider] = useState(false)
    const [file, setFile] = useState(null);
    const [uploadedFileData, setUploadedFileData] = useState([])
    const [bulkUplaodPhase, setBulkUplaodPhase] = useState("")

    // sweet alert 
    const [show, setShow] = useState(false)
    const [basicType, setBasicType] = useState("")
    const [fcPeriod, setFcPeriod] = useState(0)
    const [basicTitle, setBasicTitle] = useState("") 

    
    const [actualConsumptionSlider,setActualConsumptionSlider] = useState(
                                                        {fyData: [{}],
                                                        plotData: [{}],
                                                        yearData: [{}]
                                                        })
    const [dataVisualization, setDataVisulization] = useState({
            confidenceTrain: [],
            confidenceTrend: [],
            confidenceTestPoints: [],
            confidencePredictPoint: [],
            trend_predicted_points:[],
            seasonality_train_plot: [],
            seasonality_pred_plot: [],
            seasonality_train_plotdata: [],
            allseason: [],
            chartCusts: [],
            chartItems: [],
            chartCustomer: { label: "Select", value: "" },
            chartItem: { label: "Select", value: "" },
            fyChart: { label: "Select", value: "" },
            customerwisedata: [],

            allChartData: [],
            confAreaData: [],
            foreCast_period: '',
            confAreaYHat: [],
            confTrendplot: [],
            confScatter: [],
            trendingcost: [],
            confLineSeasonal: [],

        

    })
    



    const onGridReady1 = (params) => {
        // Handle grid ready logic
        console.log("Grid is ready");
    };

    const onGridState = (state) => {
        // Handle grid state changes
        console.log(state);
    };

    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    function arr_diff(a1, a2) {
        var a = [], diff = [];
        for (var i = 0; i < a1.length; i++) {
            a[a1[i]] = true;
        }
        for (var i = 0; i < a2.length; i++) {
            if (a[a2[i]]) {
                delete a[a2[i]];
            } else {
                a[a2[i]] = true;
            }
        }
        for (var k in a) {
            diff.push(k);
        }
        return diff;
    }


    const onClickShowCustomerData = ()=> {
        setLoadShow(prevState => ({
            ...prevState,
            loadshow: 'show-n',
            overly: 'show-n'
          }));
        var customer = dataVisualization.chartCustomer.value;
        var itemCode = dataVisualization.chartItem.value;
        // console.log("customer ", customer)

        var chartDataarr = dataVisualization.allChartData;
        var chartData = chartDataarr.filter((f) => (f.customer_code === customer && f.item_code === itemCode));
        // console.log("chartData ", chartDataarr)
        // console.log("chartDatachartData ", chartData)
        var chartDatalist = [];                 
        var chartCusts = [];
        var chartItems = [];
        var areaplot = [];
        var areaYHat = [];
        let foreCast_period = '';
        var trendplot = [];
        var areaScatter = [];
        var confLineSeasonal = []
        var trendingcost = []

        var monthsChart = ["04", "05", "06", "07", "08", "09", "10", "11", "12", "01", "02", "03"];
        var monChart = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        try {

            // if(chartData.length > 0)
            // {
            //     var confidence_train_points = [];
            //     var cn=0;
            //     if(chartData[0].confidence_train_points !== undefined)
            //     {
            //         if(chartData[0].confidence_train_points.length > 0)
            //         {
            //             chartData[0].confidence_train_points.map((itm,ind) => {
            //                 var d = itm[0]+"-"+monthsChart[ind]+"01 00:00";
            //                 var dt = moment.parseZone(new Date(d+":00")).utcOffset("+05:30")._d;
            //                 var timefrm = moment.parseZone(dt).format("x");
            //                 confidence_train_points.push([ind,parseInt(itm[1])]);

            //             })
            //         }
            //     }

            //     var trend_existing_points = [];
            //     if(chartData[0].trend_existing_points !== undefined)
            //     {
            //         chartData[0].trend_existing_points.map((itm,ind) => {
            //             trend_existing_points.push({
            //                 name: itm[0],
            //                 y:parseInt(itm[1])
            //             })
            //         })
            //     }


            //     var confidence_test_points = [];
            //     if(confidence_train_points.length > 0)
            //     {
            //         var ed =confidence_train_points[confidence_train_points.length-1][0];
            //         if(chartData[0].confidence_test_points !== undefined)
            //         {
            //             chartData[0].confidence_test_points.map((itm,ind) => {
            //                 confidence_test_points.push([parseInt(ed),parseInt(itm[1])]);
            //                 ed=ed+1;
            //             })
            //         }

            //     }

            //     // console.log("confidence_test_points ", confidence_test_points)
            //     var trend_predicted_points = [];
            //     if(trend_existing_points.length > 0)
            //     {
            //         // console.log("trend_existing_points[trend_existing_points.length-1] ", trend_existing_points[trend_existing_points.length-1])
            //         trend_predicted_points.push({name:trend_existing_points[trend_existing_points.length-1].name,y:trend_existing_points[trend_existing_points.length-1].y})

            //         trend_predicted_points.push({name:chartData[0].trend_predicted_points[0],y:parseInt(chartData[0].trend_predicted_points[1])});
            //     }
            //     // console.log("HEre trend_predicted_points", trend_predicted_points)
            //     var seasonality_train_plotar = [];
            //     // console.log('chartData[0].seasonality_train_plot ', chartData[0].seasonality_train_plot)
            //     if(chartData[0].seasonality_train_plot !== undefined)
            //     {
            //         chartData[0].seasonality_train_plot.map((itm) => {

            //             seasonality_train_plotar.push(JSON.parse(itm));
            //         })
            //     }

            //     // console.log("seasonality_train_plotar ", seasonality_train_plotar)
            //     var seasonality_train_plot = [];

            //     if(seasonality_train_plotar.length > 0)
            //     {
            //         var sdatatrne = groupBy(seasonality_train_plotar, rdata => rdata.year);
            //         try {
            //             // console.log("sdatatrne ", sdatatrne)

            //             sdatatrne.forEach((values,key) => {

            //                 var seasonality_train_plotd = [];
            //                 if(values.length > 0)
            //                 {
            //                     values.map((im) => {
            //                         // console.log("itm ", key+"===="+JSON.stringify(im))
            //                         // console.log("monChart[im.month-1] ", monChart[parseInt(im.month)-1])
            //                         var nm = monChart[parseInt(im.month)-1];
            //                         // console.log("nm ", nm)
            //                         seasonality_train_plotd.push({
            //                             name:monChart[parseInt(im.month)-1],
            //                             y:parseInt(im.item_qty)
            //                         })
            //                     })
            //                 }
            //                 // console.log("seasonality_train_plotd ",seasonality_train_plotd)
            //                 seasonality_train_plot.push({
            //                     name:key,
            //                     data:seasonality_train_plotd
            //                 })
            //             });
            //         } catch (error) {

            //         }
            //     }

            //     var seasonality_pred_plot = [];
            //     if(chartData[0].seasonality_pred_plot !== undefined)
            //     {
            //         var seasonality_pred_plotar = chartData[0].seasonality_pred_plot;
            //         if(seasonality_pred_plotar.length > 0){
            //             seasonality_pred_plotar.map((itm) => {
            //                 seasonality_pred_plot.push({
            //                     name:monChart[parseInt(itm.month)-1],
            //                     y:parseInt(itm.item_qty)
            //                 })
            //             })
            //         }

            //     }

            //     var confidenceTrain = confidence_train_points; 
            //     var confidenceTrend = [{name:"Trend Points",data:trend_existing_points},{name:"Predict",data:trend_predicted_points,color:"#FF0000"}];
            //     var confidenceTestPoints = confidence_test_points; 
            //     var confidencePredictPoint = trend_predicted_points; 
            //     var seasonality_pred_plot = {name:seasonality_pred_plotar[0].year,data:seasonality_pred_plot};
            //     // seasonality_train_plot = seasonality_train_plot;
            //     seasonality_train_plot.push(seasonality_pred_plot);


            //     // console.log("confidenceTrend ", confidenceTrend)
            // }
            // else{
            //     var confidenceTrain = []; 
            //     var confidenceTrend = [];
            //     var confidenceTestPoints = []; 
            //     var confidencePredictPoint = [];
            //     var seasonality_pred_plot = ""
            //     var seasonality_train_plot = "";
            // }

            if (chartData.length > 0) {
                chartData.map((mm) => {
                    var chartSeries = mm.chart_data;
                    var motnhSeries = mm.month_data;
                    foreCast_period = mm.fc_period
                    if (chartSeries.length > 0) {
                        chartSeries.map((mt) => {
                            var tms = moment.parseZone(mt.ds).format("x");
                            const originalDate = new Date(mt.ds);
                            originalDate.setDate(1);
                            originalDate.setHours(0, 0, 0, 0);
                            const modifiedDate = originalDate.toISOString()
                            // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                            areaplot.push([new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), ...mt.area_plot]);
                            areaYHat.push((mt.yhat < 0) ? [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), 0] : [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), parseInt(mt.yhat)]);
                            trendingcost.push((mt.cost < 0) ? [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), 0] : [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), mt.cost]);
                            if (mt.ds !== "" && mt.ds !== undefined) {
                                var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                if (mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "") {
                                    if (parseInt(trnedns) < 2022) {
                                        trendplot.push({ name: trnedns, y: mt.trend, lbl: Math.round((mt.trend < 0) ? 0 : mt.trend).toLocaleString() });
                                    }
                                }
                                // confLineSeasonal.push({name:tms/1000,y:Math.round(mt.yearly),lbl:Math.round(mt.yearly).toLocaleString()});
                                // confLineSeasonal.push([tms/1000,mt.yearly]);
                            }
                            if (mt.actual !== "" && mt.actual !== undefined && mt.actual !== null) {
                                // if(mt.actual > 0)
                                // {
                                areaScatter.push([new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), mt.actual]);
                                // }

                            }
                        })

                    }
                    if (motnhSeries.length > 0) {
                        motnhSeries.map((mt) => {
                            confLineSeasonal.push({ name: mt.month, y: Math.round(mt.month_sum), lbl: Math.round(mt.month_sum).toLocaleString() });


                        })
                    }
                })
            }
            // if(seasonality_train_plot.length > 0)
            // {
            //     var ss = seasonality_train_plot[seasonality_train_plot.length-1]
            // }
            // else{
            //     var ss = [];
            // }
        }
        catch (e) { }
        // console.log("confLineSeasonal ", confLineSeasonal)
        setDataVisulization(prevState => ({
            ...prevState,
            confAreaData: areaplot,
            confAreaYHat: areaYHat,
            confTrendplot: trendplot,
            confScatter: areaScatter,
            confLineSeasonal: confLineSeasonal,
            trendingcost: trendingcost,
            foreCast_period: foreCast_period
          }));
          
    }
    
    
  const AGgridCellDetailComponentFunction = async(params, usecase) => {
    
    if (usecase === "view_consumption_data") {
            setLoadShow({
                loadshow: 'show-m',
                overly: 'show-m',      
                overlay: 'show-m',      
                overlayBlock: "show-m"
            })
            await redirectURL.post("/forecast/getMaterialConsumptionData", {
                item_code: params.data.material_code,
                // item_code:"100048459"
            }).then(async (response) => {
    
                var fy_data = []
                var plot_data = []
                var year_data = []
                if (response.data.fy_data){
                    try{
                        fy_data = JSON.parse(response.data.fy_data)
                    }catch{
                        fy_data = []
                    }
                }
                if (response.data.plot_data){
                    try{
                        plot_data = JSON.parse(response.data.plot_data)

                    }catch{
                        plot_data = []
                    }
                }
                if (response.data.year_data){
                    try{
                        year_data = JSON.parse(response.data.year_data)
                    }catch{
                        year_data = []
                    }
                }

                setLoadShow({
                    loadshow: 'show-n',
                    overly: 'show-n',      
                    overlay: 'show-n',      
                    overlayBlock: "show-m"
                })

                setSlider({
                    showSlideBlockUpload: "slide60",
                    sliderType: "view_consumption_data",
                    sliderTitle: "Actual Material Consumption Data - " + params.data.material_code,
                })
                setActualConsumptionSlider({
                    fyData: fy_data,
                    plotData: plot_data,
                    yearData: year_data

                })

            }).catch(function (error) {
                console.log(error);
            })
        }
    else if (usecase === "view_actual_data"){
        // console.log( params.data,"ebter")
        setLoadShow({
            loadshow: 'show-m',
            overly: 'show-m',      
            overlay: 'show-m',      
            overlayBlock: "show-m"
        })

        // params.data.status === "completed"
        // todo
        if (true) {
            // window.location.href="/forecast-output?"+params.data.transaction_id;
            var months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
            await redirectURL.post("/forecast/getforecastoutputchartdata", {

                transaction_id: params.data.transaction_id,
                item_code: [params.data.material_code]
                // transaction_id: "241107085206",
                // item_code: ["100000372"]

            }).then(async (response) => {
                var records = response.data.predict;
                var history = response.data.history;
                var chartDataaa = response.data.chartData;

                // chartDataaa = JSON.parse(mchartDataaa);
                // console.log("chartDataaa ", chartDataaa)
                var chartDatalist = [];
                var chartCusts = [];
                var chartItems = [];
                var areaplot = [];
                var areaYHat = [];
                let foreCast_period = '';
                var trendplot = [];
                var areaScatter = [];
                var confLineSeasonal = []
                var trendingcost = []
                if (chartDataaa.length > 0) {
                    chartDataaa.map((itm) => {
                        itm["chart_data"] = JSON.parse(itm.chart_data);
                        itm["month_data"] = JSON.parse(itm.month_data);

                        // if(itm.confidence_test_points !== undefined && itm.confidence_test_points !== "")
                        // {

                        // console.log("itm ", itm)
                        chartCusts.push({ customer: itm.customer_code });
                        chartItems.push({ itemcode: itm.item_code });
                        chartDatalist.push(itm)
                        // }

                    })
                }
                //    console.log("chartDatalist", chartDatalist)
                if (chartDatalist.length > 0) {
                    var chartData = [chartDatalist[0]];
                    // console.log("chartData ", chartData)
                    if (chartData.length > 0) {
                        chartData.map((mm) => {
                            var chartSeries = mm.chart_data;
                            var motnhSeries = mm.month_data;
                            foreCast_period = mm.fc_period
                            if (chartSeries.length > 0) {
                                chartSeries.map((mt) => {
                                    var tms = moment.parseZone(mt.ds).format("x");
                                    // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                                    areaplot.push(mt.area_plot);
                                    areaYHat.push((mt.yhat < 0) ? 0 : mt.yhat);
                                    trendingcost.push((mt.cost < 0) ? 0 : mt.cost)
                                    if (mt.ds !== "" && mt.ds !== undefined) {
                                        var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                        var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                        // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                        // console.log("mt.trend ", mt.trend )
                                        if (mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "") {
                                            if (parseInt(trnedns) < 2021) {
                                                trendplot.push({ name: trnedns, y: mt.trend, lbl: Math.round((mt.trend < 0) ? 0 : mt.trend).toLocaleString() });
                                            }
                                        }
                                        confLineSeasonal.push({ name: tms / 1000, y: Math.round(mt.month_sum), lbl: Math.round(mt.month_sum).toLocaleString() });
                                    }
                                    if (mt.actual !== "" && mt.actual !== undefined && mt.actual !== null) {
                                        // if(mt.actual > 0)
                                        // {
                                        areaScatter.push(mt.actual);
                                        // }

                                    }
                                })

                            }
                            if (motnhSeries.length > 0) {
                                motnhSeries.map((mt) => {
                                    // confLineSeasonal.push({name:mt.month,y:Math.round(mt.month_sum),lbl:Math.round(mt.month_sum).toLocaleString()});


                                })
                            }
                        })
                    }

                    setDataVisulization(prevState => ({
                        ...prevState,
                        chartCustomer: { value: chartData[0].customer_code, label: chartData[0].customer_code },
                        chartItem: { value: chartData[0].item_code, label: chartData[0].item_code }
                      }));
                    console.log({ value: chartData[0].item_code, label: chartData[0].item_code },"stays")
                }
                else {
                    var chartData = [];
                }
                // console.log("confLineSeasonal ", confLineSeasonal)
                // console.log("areaplot ", areaplot)
                var monthsChart = ["04", "05", "06", "07", "08", "09", "10", "11", "12", "01", "02", "03"];
                var monChart = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                var confidenceTrain = [];
                var confidenceTrend = [];
                var confidenceTestPoints = [];
                var confidencePredictPoint = [];
                var seasonality_pred_plot = []
                var seasonality_train_plot = [];
                var allseason = [];

                var rowdata = [];
                var months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
                // console.log("records ", records)
                if (records.length > 0) {
                    var itmdata = groupBy(records, rdata => rdata.customer_code);
                    // console.log("itmdata ", itmdata)
                    try {
                        itmdata.forEach((valuesar, keys) => {
                            if (valuesar.length > 0) {
                                var valuesinf = groupBy(valuesar, rdata => rdata.item_code);
                                // console.log("valuesinf ", valuesinf)
                                valuesinf.forEach((values, vkeys) => {
                                    var rw = {}
                                    var exmons = [];
                                    if (values.length > 0) {
                                        rw["_id"] = values[0]._id;
                                        rw["transaction_id"] = values[0].transaction_id;
                                        rw["fy"] = values[0].fy;
                                        rw["fy_start_year"] = values[0].fy_start_year;
                                        rw["fy_end_year"] = values[0].fy_end_year;
                                        rw["item_code"] = values[0].item_code;
                                        rw["customer_code"] = values[0].customer_code;
                                        rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
                                        rw["item_cost"] = values[0].iteminfo[0].item_cost

                                        values.map((i, n) => {
                                            rw[months[parseInt(i.month) - 1]] = i.item_quantity;
                                            rw[months[parseInt(i.month) - 1] + "_cost"] = parseInt(i.item_quantity) * (i.iteminfo[0].item_cost);
                                            exmons.push(months[parseInt(i.month) - 1]);

                                        })
                                        // console.log("RWW ", rw)

                                    }

                                    if (exmons.length > 0) {
                                        var diffkeys = arr_diff(exmons, months);
                                        // console.log("diffkeys ", diffkeys)
                                        if (diffkeys.length > 0) {
                                            diffkeys.map((id) => {
                                                if (id !== "itemIndex") {
                                                    rw[id] = 0;
                                                    rw[id + "_cost"] = 0;
                                                }
                                            })
                                        }
                                    }
                                    rowdata.push(rw);
                                })
                            }

                        })
                    } catch (error) {

                    }
                }

                var chartseries = {};
                if (rowdata.length > 0) {

                    var fywisedata = groupBy(rowdata, rdata => rdata.fy);
                    try {
                        var overallordersfy = [];
                        var overallcostfy = [];
                        var fynonths = []
                        var fynonthsCost = []
                        var historify = [];
                        fywisedata.forEach((values, keys) => {
                            var totlOrders = 0;
                            var totlCosts = 0;
                            // console.log("values",values)
                            if (values.length > 0) {
                                var monthfy = [];
                                var monthfyCost = [];
                                var hmonthfy = []
                                var pmonthfy = []
                                var apr = 0;
                                var may = 0;
                                var jun = 0;
                                var jul = 0;
                                var aug = 0;
                                var sep = 0;
                                var oct = 0;
                                var nov = 0;
                                var dec = 0;
                                var jan = 0;
                                var feb = 0;
                                var mar = 0;

                                var apr_cost = 0;
                                var may_cost = 0;
                                var jun_cost = 0;
                                var jul_cost = 0;
                                var aug_cost = 0;
                                var sep_cost = 0;
                                var oct_cost = 0;
                                var nov_cost = 0;
                                var dec_cost = 0;
                                var jan_cost = 0;
                                var feb_cost = 0;
                                var mar_cost = 0;

                                values.map((item) => {
                                    totlOrders = parseInt(totlOrders) + parseInt(item.Apr) + parseInt(item.May) + parseInt(item.Jun) + parseInt(item.Jul) + parseInt(item.Aug) + parseInt(item.Sep) + parseInt(item.Oct) + parseInt(item.Nov) + parseInt(item.Dec) + parseInt(item.Jan) + parseInt(item.Feb) + parseInt(item.Mar);

                                    totlCosts = parseInt(totlCosts) + parseInt(item.Apr_cost) + parseInt(item.May_cost) + parseInt(item.Jun_cost) + parseInt(item.Jul_cost) + parseInt(item.Aug_cost) + parseInt(item.Sep_cost) + parseInt(item.Oct_cost) + parseInt(item.Nov_cost) + parseInt(item.Dec_cost) + parseInt(item.Jan_cost) + parseInt(item.Feb_cost) + parseInt(item.Mar_cost);
                                    apr = parseInt(apr) + parseInt(item.Apr);
                                    may = parseInt(may) + parseInt(item.May);
                                    jun = parseInt(jun) + parseInt(item.Jun);
                                    jul = parseInt(jul) + parseInt(item.Jul);
                                    aug = parseInt(aug) + parseInt(item.Aug);
                                    sep = parseInt(sep) + parseInt(item.Sep);
                                    oct = parseInt(oct) + parseInt(item.Oct);
                                    nov = parseInt(nov) + parseInt(item.Nov);
                                    dec = parseInt(dec) + parseInt(item.Dec);
                                    jan = parseInt(jan) + parseInt(item.Jan);
                                    feb = parseInt(feb) + parseInt(item.Feb);
                                    mar = parseInt(mar) + parseInt(item.Mar);


                                    apr_cost = parseInt(apr_cost) + parseInt(item.Apr_cost);
                                    may_cost = parseInt(may_cost) + parseInt(item.May_cost);
                                    jun_cost = parseInt(jun_cost) + parseInt(item.Jun_cost);
                                    jul_cost = parseInt(jul_cost) + parseInt(item.Jul_cost);
                                    aug_cost = parseInt(aug_cost) + parseInt(item.Aug_cost);
                                    sep_cost = parseInt(sep_cost) + parseInt(item.Sep_cost);
                                    oct_cost = parseInt(oct_cost) + parseInt(item.Oct_cost);
                                    nov_cost = parseInt(nov_cost) + parseInt(item.Nov_cost);
                                    dec_cost = parseInt(dec_cost) + parseInt(item.Dec_cost);
                                    jan_cost = parseInt(jan_cost) + parseInt(item.Jan_cost);
                                    feb_cost = parseInt(feb_cost) + parseInt(item.Feb_cost);
                                    mar_cost = parseInt(mar_cost) + parseInt(item.Mar_cost);

                                })
                                hmonthfy.push(apr);
                                hmonthfy.push(may);
                                hmonthfy.push(jun);
                                hmonthfy.push(jul);
                                hmonthfy.push(aug);
                                hmonthfy.push(sep);
                                hmonthfy.push(oct);
                                hmonthfy.push(nov);
                                hmonthfy.push(dec);
                                hmonthfy.push(jan);
                                hmonthfy.push(feb);
                                hmonthfy.push(mar);
                                monthfy.push({
                                    name: "Apr", y: apr
                                }, {
                                    name: "May", y: may
                                }, {
                                    name: "Jun", y: jun
                                }, {
                                    name: "Jul", y: jul
                                }, {
                                    name: "Aug", y: aug
                                }, {
                                    name: "Sep", y: sep
                                }, {
                                    name: "Oct", y: oct
                                }, {
                                    name: "Nov", y: nov
                                }, {
                                    name: "Dec", y: dec
                                }, {
                                    name: "Jan", y: jan
                                }, {
                                    name: "Feb", y: feb
                                }, {
                                    name: "Mar", y: mar
                                });

                                monthfyCost.push({
                                    name: "Apr", y: apr_cost
                                }, {
                                    name: "May", y: may_cost
                                }, {
                                    name: "Jun", y: jun_cost
                                }, {
                                    name: "Jul", y: jul_cost
                                }, {
                                    name: "Aug", y: aug_cost
                                }, {
                                    name: "Sep", y: sep_cost
                                }, {
                                    name: "Oct", y: oct_cost
                                }, {
                                    name: "Nov", y: nov_cost
                                }, {
                                    name: "Dec", y: dec_cost
                                }, {
                                    name: "Jan", y: jan_cost
                                }, {
                                    name: "Feb", y: feb_cost
                                }, {
                                    name: "Mar", y: mar_cost
                                });

                                overallordersfy.push({
                                    name: "FY - " + keys,
                                    y: totlOrders
                                });
                                overallcostfy.push({
                                    name: "FY - " + keys,
                                    y: totlCosts
                                });
                                fynonths.push({
                                    name: keys,
                                    data: monthfy,
                                    color: "#ff0000"
                                });
                                fynonthsCost.push({
                                    name: keys,
                                    data: monthfyCost,
                                    color: "#ff0000"
                                });
                                historify.push(hmonthfy)
                            }
                        })
                        // console.log("fynonths ", fynonths)
                        chartseries["poverallfinancialorders"] = { name: "Predict Orders", color: "#1ABC9C", data: overallordersfy }
                        chartseries["poverallfinancialcost"] = { name: "Predict Cost", color: "#1ABC9C", data: overallcostfy }
                        var selectedfymonths = fynonths.filter((f) => dataVisualization.fyChart.value === f.name);
                        chartseries["pallfymonthwise"] = { name: "Predict Orders", color: "#1ABC9C", data: fynonths }
                        chartseries["pallfymonthwisecost"] = { name: "Predict Cost", color: "#1ABC9C", data: fynonthsCost }
                        chartseries["pselectedfymonths"] = selectedfymonths
                        chartseries["phistoric"] = historify
                    } catch (error) {

                    }
                    // item = item.sort(GetSortAscOrder("sequence_no"));
                }

                if (history.length > 0) {
                    var prowdata = [];
                    var hitmdata = groupBy(history, rdata => rdata.fy);
                    // console.log("hitmdata ", hitmdata)
                    try {
                        hitmdata.forEach((valuears, akeys) => {
                            if (valuears.length > 0) {
                                var vData = groupBy(valuears, rdata => rdata.item_code);
                                vData.forEach((values, keys) => {
                                    var rw = {}
                                    var exmons = [];
                                    if (values.length > 0) {
                                        rw["_id"] = values[0]._id;
                                        rw["transaction_id"] = values[0].transaction_id;
                                        rw["fy"] = values[0].fy;
                                        rw["fy_start_year"] = values[0].fy_start_year;
                                        rw["fy_end_year"] = values[0].fy_end_year;
                                        rw["item_code"] = values[0].item_code;
                                        rw["customer_code"] = values[0].customer_code;
                                        rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
                                        rw["item_cost"] = values[0].iteminfo[0].item_cost

                                        values.map((i, n) => {

                                            rw[months[parseInt(i.month) - 1]] = i.item_qty;
                                            rw[months[parseInt(i.month) - 1] + "_cost"] = parseInt(i.item_qty) * (i.item_cost);
                                            exmons.push(months[parseInt(i.month) - 1]);

                                        })
                                        // console.log("RWW ", rw)

                                    }

                                    if (exmons.length > 0) {
                                        var diffkeys = arr_diff(exmons, months);
                                        // console.log("diffkeys ", diffkeys)
                                        if (diffkeys.length > 0) {
                                            diffkeys.map((id) => {
                                                if (id !== "itemIndex") {
                                                    rw[id] = 0;
                                                    rw[id + "_cost"] = 0;
                                                }
                                            })
                                        }
                                    }
                                    prowdata.push(rw);
                                })

                            }
                        })
                    } catch (error) {

                    }
                    // console.log('prowdata ', prowdata)
                    var hfywisedata = groupBy(prowdata, rdata => rdata.fy);
                    // console.log("hfywisedata ", hfywisedata)
                    try {
                        var hoverallordersfy = [];
                        var hoverallcostfy = [];
                        var hfynonths = []
                        var hfynonthsCost = [];
                        var hConfarea = [];
                        var hConfLine = [];
                        var hhistorify = [];
                        hfywisedata.forEach((values, keys) => {
                            var totlOrders = 0;
                            var totlCosts = 0;
                            // console.log("values",values)
                            if (values.length > 0) {
                                var monthfy = [];
                                var monthfyCost = [];
                                var hmonthfy = []
                                var pmonthfy = []
                                var apr = 0;
                                var may = 0;
                                var jun = 0;
                                var jul = 0;
                                var aug = 0;
                                var sep = 0;
                                var oct = 0;
                                var nov = 0;
                                var dec = 0;
                                var jan = 0;
                                var feb = 0;
                                var mar = 0;

                                var apr_cost = 0;
                                var may_cost = 0;
                                var jun_cost = 0;
                                var jul_cost = 0;
                                var aug_cost = 0;
                                var sep_cost = 0;
                                var oct_cost = 0;
                                var nov_cost = 0;
                                var dec_cost = 0;
                                var jan_cost = 0;
                                var feb_cost = 0;
                                var mar_cost = 0;

                                values.map((item) => {
                                    totlOrders = parseInt(totlOrders) + parseInt(item.Apr) + parseInt(item.May) + parseInt(item.Jun) + parseInt(item.Jul) + parseInt(item.Aug) + parseInt(item.Sep) + parseInt(item.Oct) + parseInt(item.Nov) + parseInt(item.Dec) + parseInt(item.Jan) + parseInt(item.Feb) + parseInt(item.Mar);

                                    totlCosts = parseInt(totlCosts) + parseInt(item.Apr_cost) + parseInt(item.May_cost) + parseInt(item.Jun_cost) + parseInt(item.Jul_cost) + parseInt(item.Aug_cost) + parseInt(item.Sep_cost) + parseInt(item.Oct_cost) + parseInt(item.Nov_cost) + parseInt(item.Dec_cost) + parseInt(item.Jan_cost) + parseInt(item.Feb_cost) + parseInt(item.Mar_cost);
                                    apr = parseInt(apr) + parseInt(item.Apr);
                                    may = parseInt(may) + parseInt(item.May);
                                    jun = parseInt(jun) + parseInt(item.Jun);
                                    jul = parseInt(jul) + parseInt(item.Jul);
                                    aug = parseInt(aug) + parseInt(item.Aug);
                                    sep = parseInt(sep) + parseInt(item.Sep);
                                    oct = parseInt(oct) + parseInt(item.Oct);
                                    nov = parseInt(nov) + parseInt(item.Nov);
                                    dec = parseInt(dec) + parseInt(item.Dec);
                                    jan = parseInt(jan) + parseInt(item.Jan);
                                    feb = parseInt(feb) + parseInt(item.Feb);
                                    mar = parseInt(mar) + parseInt(item.Mar);

                                    apr_cost = parseInt(apr_cost) + parseInt(item.Apr_cost);
                                    may_cost = parseInt(may_cost) + parseInt(item.May_cost);
                                    jun_cost = parseInt(jun_cost) + parseInt(item.Jun_cost);
                                    jul_cost = parseInt(jul_cost) + parseInt(item.Jul_cost);
                                    aug_cost = parseInt(aug_cost) + parseInt(item.Aug_cost);
                                    sep_cost = parseInt(sep_cost) + parseInt(item.Sep_cost);
                                    oct_cost = parseInt(oct_cost) + parseInt(item.Oct_cost);
                                    nov_cost = parseInt(nov_cost) + parseInt(item.Nov_cost);
                                    dec_cost = parseInt(dec_cost) + parseInt(item.Dec_cost);
                                    jan_cost = parseInt(jan_cost) + parseInt(item.Jan_cost);
                                    feb_cost = parseInt(feb_cost) + parseInt(item.Feb_cost);
                                    mar_cost = parseInt(mar_cost) + parseInt(item.Mar_cost);

                                })
                                hmonthfy.push(apr);
                                hmonthfy.push(may);
                                hmonthfy.push(jun);
                                hmonthfy.push(jul);
                                hmonthfy.push(aug);
                                hmonthfy.push(sep);
                                hmonthfy.push(oct);
                                hmonthfy.push(nov);
                                hmonthfy.push(dec);
                                hmonthfy.push(jan);
                                hmonthfy.push(feb);
                                hmonthfy.push(mar);
                                monthfy.push({
                                    name: "Apr", y: apr
                                }, {
                                    name: "May", y: may
                                }, {
                                    name: "Jun", y: jun
                                }, {
                                    name: "Jul", y: jul
                                }, {
                                    name: "Aug", y: aug
                                }, {
                                    name: "Sep", y: sep
                                }, {
                                    name: "Oct", y: oct
                                }, {
                                    name: "Nov", y: nov
                                }, {
                                    name: "Dec", y: dec
                                }, {
                                    name: "Jan", y: jan
                                }, {
                                    name: "Feb", y: feb
                                }, {
                                    name: "Mar", y: mar
                                });

                                monthfyCost.push({
                                    name: "Apr", y: apr_cost
                                }, {
                                    name: "May", y: may_cost
                                }, {
                                    name: "Jun", y: jun_cost
                                }, {
                                    name: "Jul", y: jul_cost
                                }, {
                                    name: "Aug", y: aug_cost
                                }, {
                                    name: "Sep", y: sep_cost
                                }, {
                                    name: "Oct", y: oct_cost
                                }, {
                                    name: "Nov", y: nov_cost
                                }, {
                                    name: "Dec", y: dec_cost
                                }, {
                                    name: "Jan", y: jan_cost
                                }, {
                                    name: "Feb", y: feb_cost
                                }, {
                                    name: "Mar", y: mar_cost
                                });

                                hConfarea.push({
                                    name: "FY - " + keys,
                                    // data:[[apr_cost,may_cost],[may_cost,jun_cost],[jun_cost,jul_cost],[jul_cost,aug_cost],[aug_cost,sep_cost],[sep_cost,oct_cost],[oct_cost,nov_cost],[nov_cost,dec_cost],[dec_cost,jan_cost],[jan_cost,feb_cost],[feb_cost,mar_cost],[mar_cost,0]]
                                    data: [[apr_cost, mar_cost]]
                                });
                                hConfLine.push({
                                    name: "FY - " + keys,
                                    data: [apr_cost, may_cost, jun_cost, jul_cost, aug_cost, sep_cost, oct_cost, nov_cost, dec_cost, jan_cost, feb_cost, mar_cost]
                                })
                                // hConfarea.push({
                                //     name:"FY - "+keys,
                                //     data:[[apr,may],[may,jun],[jun,jul],[jul,aug],[aug,sep],[sep,oct],[oct,nov],[nov,dec],[dec,jan],[jan,feb],[feb,mar]]
                                // });
                                hoverallordersfy.push({
                                    name: "FY - " + keys,
                                    y: totlOrders
                                });
                                hoverallcostfy.push({
                                    name: "FY - " + keys,
                                    y: totlCosts
                                });
                                hfynonths.push({
                                    name: keys,
                                    data: monthfy
                                });
                                hfynonthsCost.push({
                                    name: keys,
                                    data: monthfyCost
                                });
                                hhistorify.push(hmonthfy)
                            }
                        })
                        // console.log("fynonths ", fynonths)
                        chartseries["hoverallfinancialorders"] = { name: "History", colorByPoint: true, data: hoverallordersfy }
                        chartseries["hoverallfinancialcost"] = { name: "History", colorByPoint: true, data: hoverallcostfy }
                        chartseries["hallfymonthwise"] = { name: "History", colorByPoint: true, data: hfynonths }
                        chartseries["hallfymonthwisecost"] = { name: "History", colorByPoint: true, data: hfynonthsCost }
                        chartseries["hhistoric"] = hhistorify
                    } catch (error) {

                    }
                    // item = item.sort(GetSortAscOrder("sequence_no"));
                }

                var newArray = [];
                let totuniqueObject = {};
                var totobjTitle;
                // Loop for the array elements 
                for (let i in chartCusts) {

                    // Extract the title
                    if (chartCusts[i]['customer'] !== "" && chartCusts[i]['customer'] !== undefined) {
                        totobjTitle = chartCusts[i]['customer'];

                        // Use the title as the index 
                        totuniqueObject[totobjTitle] = chartCusts[i];
                    }

                }

                // Loop to push unique object into array 
                for (var a in totuniqueObject) {
                    newArray.push(totuniqueObject[a]);
                }

                var chartCustsarr = [];
                if (newArray.length > 0) {
                    newArray.map((item) => {
                        chartCustsarr.push({
                            value: item.customer,
                            label: item.customer
                        })
                    })
                }


                var newArrayItem = [];
                let totuniqueObjectItem = {};
                var totobjTitle;
                // Loop for the array elements 
                for (let i in chartItems) {

                    // Extract the title
                    if (chartItems[i]['itemcode'] !== "" && chartItems[i]['itemcode'] !== undefined) {
                        totobjTitle = chartItems[i]['itemcode'];

                        // Use the title as the index 
                        totuniqueObjectItem[totobjTitle] = chartItems[i];
                    }

                }

                // Loop to push unique object into array 
                for (var a in totuniqueObjectItem) {
                    newArrayItem.push(totuniqueObjectItem[a]);
                }


                var chartItemarr = [];
                if (newArrayItem.length > 0) {
                    newArrayItem.map((item) => {
                        chartItemarr.push({
                            value: item.itemcode,
                            label: item.itemcode
                        })
                    })
                }
                // console.log("trendplot ", trendplot)
                setDataVisulization(prevState => ({
                    ...prevState,
                    confidenceTrain: confidenceTrain,
                    confidenceTrend: confidenceTrend,
                    confidenceTestPoints: confidenceTestPoints,
                    confidencePredictPoint: confidencePredictPoint,
                    seasonality_train_plot: seasonality_train_plot[seasonality_train_plot.length - 1],
                    seasonality_pred_plot: seasonality_pred_plot,
                    seasonality_train_plotdata: seasonality_train_plot,
                    allseason: allseason,
                    chartCusts: chartCustsarr,
                    chartItems: chartItemarr,
                    allChartData: chartDatalist,
                    confAreaData: areaplot,
                    foreCast_period: foreCast_period,
                    confAreaYHat: areaYHat,
                    confTrendplot: trendplot,
                    confScatter: areaScatter,
                    trendingcost: trendingcost,
                    confLineSeasonal: confLineSeasonal
                  }));
                  
                
                setLoadShow({
                    loadshow: 'show-n',
                    overly: 'show-n',      
                    overlay: 'show-n',      
                    overlayBlock: "show-m"
                })

                setSlider({
                    showSlideBlockUpload: "slide45",
                    sliderType: "view_actual_data",
                    sliderTitle: "Data Visualization"
                })

                setTimeout(() => {
                    onClickShowCustomerData();
                }, 500)
            }).catch(function (error) {
                console.log(error);
            })
        }

    }
    
}

    const hideSlideBlock = () =>{
        setSlider({
            showSlideBlockUpload:"",
            sliderType: "",
            sliderTitle: "",
            
        })
        setLoadShow(prevState => ({
            ...prevState,
            overlayBlock: "show-n"
          }));
        setIsSliderOpen(false)

    }


    const fetchData = async (phase) => {
        // Show loading overlay
        setLoadShow({
            loadshow: 'show-m',
            overly: 'show-m',
            overlay: 'show-m',
            overlayBlock: 'show-m'
        });

        try {
            // Await the API request
            const response = await redirectURL.post("/forecast/fetchAiVsBusinessDashboardData", { phase: phase.value });
            
            // Check if the response is successful
            if (response.status === 200) {
                setRowData(response.data.data);  // Set the fetched data into state
                console.log(response.data.data, "data");
            }
        } catch (error) {
            console.error("Error fetching data:", error);  // Handle errors
        } finally {
            // Hide loading overlay after request completes (success or failure)
            setLoadShow({
                loadshow: 'show-n',
                overly: 'show-n',
                overlay: 'show-n',
                overlayBlock: 'show-n'
            });
        }
    };

    
    const onClickApply = async () => {
        fetchData(phase)
    };

    useEffect(() => {

        const fetchphaseData = async () => {
            setLoadShow({
                loadshow: 'show-m',
                overly: 'show-m',
                overlay: 'show-m',
                overlayBlock: 'show-m'
            });

            try {
                // Await the API response
                const response = await redirectURL.post("/dashboard/phaselists");
                const records = response.data.records;
                const recordsArr = [];

                // Transform records to desired structure
                if (records.length > 0) {
                    records.map((itm) => {
                        recordsArr.push({ value: itm, label: itm });
                    });
                }

                // Update state with the new data
                setPhaseList(recordsArr);
                setPhase(recordsArr[0]);
                try {
                    // Await the API request
                    const response = await redirectURL.post("/forecast/fetchAiVsBusinessDashboardData", { phase: recordsArr[0].value });
                    
                    // Check if the response is successful
                    if (response.status === 200) {
                        setRowData(response.data.data);  // Set the fetched data into state
                        console.log(response.data.data, "data");
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);  // Handle errors
                }


            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                // Hide the loading spinner or overlay
                setLoadShow({
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlay: 'show-n',
                    overlayBlock: 'show-n'
                });
            }
        };

        // Call the async function
        fetchphaseData();
    }, []);  // Empty dependency array means this runs only once, after the first render

    const columnHeaders = [
        {
            headerName: "Material Code",
            field: "material_code",
            filter: true,
            resizable: true,
            headerClass: ["column-header-style"],
            pinned: "left",
            width: 134,
            cellClass: function (params) {
                try {
                    // Check if 'deviation' is defined and non-empty
                    if (params.data.deviation) {
                        if (params.data.deviation === "Excess") {
                            return ["redbg", "fbold"];
                        } else if (params.data.deviation === "Exact") {
                            return ["greenbg", "fbold"];
                        } else if (params.data.deviation === "Under") {
                            return ["orangebg", "fbold"];
                        }
                    }
                } catch (error) {
                    console.error("Error in cellClass:", error);
                }
            },
            valueGetter: function (params) {
                if (params) {
                    return params.data.material_code === "" || params.data.material_code === undefined
                        ? "NA"
                        : params.data.material_code;
                } else {
                    return "";
                }
            },
        },
        
        {
            headerName: "Material Description",
            field: "material_description",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 177,
            headerClass: ["column-header-style"],

            valueGetter: function (params) {
              if (params) {
                return params.data.material_description === "" || params.data.material_description === undefined
                  ? "NA"
                  : params.data.material_description;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Material Department",
            field: "material_department",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 181,
            headerClass: ["column-header-style"],

            valueGetter: function (params) {
              if (params) {
                return params.data.material_department === "" || params.data.material_department === undefined
                  ? "NA"
                  : params.data.material_department;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Material Classification",
            field: "material_classification",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 188,
            headerClass: ["column-header-style"],

            valueGetter: function (params) {
              if (params) {
                return params.data.material_classification === "" || params.data.material_classification === undefined
                  ? "NA"
                  : params.data.material_classification;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Material Part Type",
            field: "material_part_type",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 165,
            headerClass: ["column-header-style"],

            valueGetter: function (params) {
              if (params) {
                return params.data.material_part_type === "" || params.data.material_part_type === undefined
                  ? "NA"
                  : params.data.material_part_type;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Material Category",
            field: "material_category",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 164,
            headerClass: ["column-header-style"],

            valueGetter: function (params) {
              if (params) {
                return params.data.material_category === "" || params.data.material_category === undefined
                  ? "NA"
                  : params.data.material_category;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Unit Cost ($)",
            field: "unit_cost",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 125,
            headerClass: ["column-header-style"],

            valueGetter: function (params) {
              if (params) {
                return params.data.unit_cost === "" || params.data.unit_cost === undefined
                  ? "NA"
                  : params.data.unit_cost;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Current Stock",
            field: "current_stock",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 125,
            headerClass: ["column-header-style"],

            valueGetter: function (params) {
              if (params) {
                return params.data.current_stock === "" || params.data.current_stock === undefined
                  ? "NA"
                  : params.data.current_stock;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "AI Predicted Quantity",
            field: "ai_predicted_qunatity",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 190,
            headerClass: ["column-header-style"],

            valueGetter: function (params) {
              if (params) {
                return params.data.ai_predicted_qunatity === "" || params.data.ai_predicted_qunatity === undefined
                  ? "NA"
                  : params.data.ai_predicted_qunatity;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Business Predicted Quantity",
            field: "business_predicted_qunatity",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 233,
            headerClass: ["column-header-style"],
            valueGetter: function (params) {
              if (params) {
                return params.data.business_predicted_qunatity === "" || params.data.business_predicted_qunatity === undefined
                  ? "NA"
                  : params.data.business_predicted_qunatity;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Qunatity Difference",
            field: "quantity_difference",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 177,
            headerClass: ["column-header-style"],
            valueGetter: function (params) {
              if (params) {
                return params.data.quantity_difference === "" || params.data.quantity_difference === undefined
                  ? "NA"
                  : params.data.quantity_difference;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "AI Predicted Cost",
            field: "ai_predicted_cost",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 157,
            headerClass: ["column-header-style"],
            valueGetter: function (params) {
              if (params) {
                return params.data.ai_predicted_cost === "" || params.data.ai_predicted_cost === undefined
                  ? "NA"
                  : params.data.ai_predicted_cost;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Business Predicted Cost",
            field: "business_predicted_cost",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 200,
            headerClass: ["column-header-style"],
            valueGetter: function (params) {
              if (params) {
                return params.data.business_predicted_cost === "" || params.data.business_predicted_cost === undefined
                  ? "NA"
                  : params.data.business_predicted_cost;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Cost Difference",
            field: "cost_difference",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 144,
            headerClass: ["column-header-style"],
            valueGetter: function (params) {
              if (params) {
                return params.data.cost_difference === "" || params.data.cost_difference === undefined
                  ? "NA"
                  : params.data.cost_difference;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Deviation",
            field: "deviation",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 108,
            headerClass: ["column-header-style"],
            valueGetter: function (params) {
              if (params) {
                return params.data.deviation === "" || params.data.deviation === undefined
                  ? "NA"
                  : params.data.deviation;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "View Consumption Data",
            field: "view_consumption_data",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 203,
            headerClass: ["column-header-style"],
            cellRendererFramework: (params) => <AGgridCellDetailComponent usecase="view_consumption_data" data={params}/>

        },
        {
            headerName: "View Actual Data",
            field: "view_actual_data",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 157,
            headerClass: ["column-header-style"],
            cellRendererFramework: (params) => <AGgridCellDetailComponent usecase="view_actual_data" data={params}/>
        },

        {
            headerName: "Last FY Consumption",
            field: "last_fy_consumption",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 181,
            headerClass: ["column-header-style"],
            valueGetter: function (params) {
              if (params) {
                return params.data.last_fy_consumption === "" || params.data.last_fy_consumption === undefined
                  ? "NA"
                  : params.data.last_fy_consumption;
              } else {
                return "";
              }
            },
        },
        {
            headerName: "Last Date of Procurement",
            field: "last_date_of_procurement",
            filter: true,
            resizable: true,
            // pinned: "left",
            width: 216,
            headerClass: ["column-header-style"],
            valueGetter: function (params) {
                if (params) {
                    // Check if last_date_of_procurement is empty or undefined
                    if (params.data.last_date_of_procurement === "" || params.data.last_date_of_procurement === undefined) {
                        return "NA";
                    } else {
                        // If last_date_of_procurement is defined, format the invoice_date
                        const date = new Date(params.data.last_date_of_procurement);
                        const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");
                        return formattedDate;
                    }
                } else {
                    return "";
                }

            },
        },
        
    ]
    var columnDefsForMaterialConsumption = [
        {
            headerName: "Financial Year",
            field: "financial_year",
            width: 150,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "April",
            field: "Apr",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "May",
            field: "May",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "June",
            field: "Jun",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "July",
            field: "Jul",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "August",
            field: "Aug",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "September",
            field: "Sep",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "October",
            field: "Oct",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "November",
            field: "Nov",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "December",
            field: "Dec",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "January",
            field: "Jan",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "February",
            field: "Feb",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "March",
            field: "Mar",
            width: 190,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "Total Quantity", // New column for total quantity
            field: "total_quantity",       // This should match the 'total_quantity' field in your data
            width: 200,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        }
    ]
    
    var columnDefsForMaterialConsumption1 = [
        {
            headerName: "Year",
            field: "year",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "January",
            field: "Jan",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "February",
            field: "Feb",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "March",
            field: "Mar",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "April",
            field: "Apr",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "May",
            field: "May",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "June",
            field: "Jun",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "July",
            field: "Jul",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "August",
            field: "Aug",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "September",
            field: "Sep",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "October",
            field: "Oct",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "November",
            field: "Nov",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "December",
            field: "Dec",
            width: 100,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        },
        {
            headerName: "Total Quantity",
            field: "total_quantity",
            width: 120,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"]
        }
    ];
 
    const onClickingBulkUpload = () => {
        setIsSliderOpen(true)
        setSliderType(1)
        setSliderCl("slider-25")
        setLoadShow(prevState => ({
            ...prevState,
            overlayBlock: "show-m"
          })); 
        setSliderTitle("Upload Business Prediction Quantity")
    }
    const onSubmitBulkUpload =  async (e)=>{
        e.preventDefault()

         if (bulkUplaodPhase === "" ){
            setShow(true)
            setBasicType("warning")
            setBasicTitle("Please select Phase")
            setLoadShow(prevState => ({
                ...prevState,
                overlayBlock: "show-m"
              }));
            return
        }
        // started sending data 

        try {
            setLoadShow({
                loadshow: 'show-m',
                overly: 'show-m',
                overlay: 'show-m',
                overlayBlock: 'show-m'
            });
            function toSnakeCase(str) {
                return str.replace(/[A-Z]/g, letter => `${letter.toLowerCase()}`);
            }

            function convertKeysToSnakeCase(data) {
                return data.map(item => {
                    let newItem = {};
                    for (let key in item) {
                        if (item.hasOwnProperty(key)) {
                            let newKey = toSnakeCase(key);
                            newItem[newKey] = item[key];
                        }
                    }
                    return newItem;
                });
            }
            
            var transformedData = convertKeysToSnakeCase(uploadedFileData);
            var transaction = moment().format("YYYYMMDDHHmmssSSS");
            var params = {
                fileName: `${fileName}_${transaction}`,
                xlsData: JSON.stringify(transformedData),
                transaction_id: transaction.toString(),
                phase: bulkUplaodPhase,
            };
            if (uploadedFileData.length === 0 || transformedData.length === 0){
                setShow(true);
                // setBasicTitle("No File Selected");
                setBasicTitle('Oops! Missing File')
                setBasicType("info")
                setLoadShow({
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlay: 'show-n',
                    overlayBlock: 'show-n'
                });
                return;
            }
            const response = await redirectURL.post('/forecast/UploadBusinessPredictionQuantity', params);
            if (response.data.status === "success") {
                localStorage.setItem("file_name", `${fileName}_${transaction}`)
                localStorage.setItem("transaction_id", transaction.toString())
                setLoadShow({
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlay: 'show-n',
                    overlayBlock: 'show-n'
                });
                setShow(true);
                setBasicType("success");
                setBasicTitle("Successfully Uploaded")
                setCloseSlider(true)

            } else {
                setLoadShow({
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlay: 'show-n',
                    overlayBlock: 'show-n'
                });
                setShow(true);
                setBasicType("warning");
                setBasicTitle("Something Went Wrong")
            }
        } catch (error) {
            setLoadShow({
                loadshow: 'show-n',
                overly: 'show-n',
                overlay: 'show-n',
                overlayBlock: 'show-n'
            });
            setShow(true);
            setBasicType("warning");
            setBasicTitle("Something Went Wrong")
        }




    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convert the entire worksheet to JSON
            const parsedData = XLSX.utils.sheet_to_json(worksheet, {
                raw: false,
                defval: '', // Treat empty cells as empty strings
                header: 1 // Use the first row as headers
            });

            // Remove the first row which contains headers
            const headers = parsedData.shift();

            // Construct objects with column names as keys
            const jsonData = parsedData.map(row => {
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header] = row[index];
                });
                return obj;
            });

            setUploadedFileData(jsonData);
            setFileName(file.name)
        };
        reader.readAsArrayBuffer(file);
    };
    const onClickingSliderCloseIcon = () => {
        setLoadShow(prevState => ({
            ...prevState,
            overlayBlock: "show-n"
          }));
        setIsSliderOpen(false)
        setFile(null)
        if (document.getElementById("bulkUploadFile")) {
            document.getElementById("bulkUploadFile").value = ""
        }
        setUploadedFileData([])
        setBulkUplaodPhase("")

        // close pop up
        setShow(false);
        setBasicType("");
        setBasicTitle("")
    }
    const closeAlert = () => {
        // if (basicType == "success") {
            setShow(false)
            setBasicType("")
            setBasicTitle("")
            setLoadShow(prevState => ({
                ...prevState,
                overlayBlock: "show-m"
              }));
              console.log(closeSlider,"chcek")
            if (closeSlider){
                setIsSliderOpen(false)
                setFileName("")
                setCloseSlider(false)
               setUploadedFileData([])
               setLoadShow(prevState => ({
                ...prevState,
                overlayBlock: "show-n"
              }));
              window.location.reload();
            }
    }
    
    var templatePath = require('../../assets/json/bulk_upload_business_quantity.csv');


    return (
        <div className="row m-0p">
            <div className="col-12">
            <div style={{ zIndex: "9999999" }}>
                    <SweetAlert
                        show={show}
                        type={basicType}
                        title={basicTitle}
                        onConfirm={closeAlert}
                        style={{ fontSize: "9px" }}
                    >
                    </SweetAlert>
                </div>
                {/* Filter */}
                <div className="d-flex justify-content-between col-12"  style={{paddingLeft:"0px", marginBottom:"5px", width: "100%"}} >
                    <div className="d-flex">
                        <div className="col-md-9" style={{paddingLeft:"0px" }}>
                                <label className="col-form-label f19 fbold" style={{ color: "black" }}>
                                    Phase
                                </label>
                                <div className="" style={{ paddingLeft: "0px" }}>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={(value) => setPhase(value)}
                                        name="phase"
                                        value={phase}
                                        options={phaseList}
                                        styles={customStyles}
                                    />
                                </div>
                        </div>
                        <div className="" style={{ marginTop: "0px", alignSelf: "flex-end" }}>
                            <div className="form-group m-0p">
                                <button type="button" onClick={onClickApply} className="btn btn-success m-0p">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>


                    <div style={{alignSelf:"end"}}>
                        <button className="upload-btn  manual_forcast_upload_btn align-self-end" onClick={onClickingBulkUpload}>Upload Business Prediction Quantity</button>
                    </div>

                </div>

                {/* Ag Grid */}

                <div id="myGrid1" style={{ width: "100%", height: "70vh", marginBottom: "100px" }} className={"ag-theme-balham"}>

                    <AgGridReact
                        columnDefs={columnHeaders}
                         defaultColDef= {{
                            sortable: true,
                            filter: true,
                            editable: false,
                            resizable: true
                        }}
                        rowData={rowData}
                        enableCharts={false}
                        onGridReady={onGridReady1}
                        onGridState={onGridState}
                        frameworkComponents={frameworkComponents}
                        statusBar={statusBar}
                        sideBar={sideBar}
                        stopEditingWhenGridLosesFocus={true}
                        paginationPageSize={1000}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this },
                            rowClass: 'your-row-class'
                        }}
                        context={{
                            AGgridCellDetailComponentFunction:AGgridCellDetailComponentFunction,
                          }}
                        enableRangeSelection={true}
                        masterDetail={true}
                        // rowClassRules={rowClassRules}
                        suppressRowHoverHighlight={true}
                    />
                </div>

                <div className={"overlay-block " + (loadShow.overlayBlock)} onClick={hideSlideBlock}></div>
                <div className={"sliderBlock2 " + (slider.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">{slider.sliderTitle}</h5>
                            {(slider.sliderType === "view_consumption_data") &&
                                        <div className="col-md-12">

                                            <div id="myGrid1" style={{ width: "100%", height: "30vh", padding: "2%", fontWeight: "600", fontSize: "13px" }} className={"ag-theme-balham"}>
                                                <AgGridReact
                                                    columnDefs={columnDefsForMaterialConsumption}
                                                    defaultColDef= {{
                                                        sortable: true,
                                                        filter: true,
                                                        editable: false,
                                                        resizable: true
                                                    }}
                                                    rowData={actualConsumptionSlider.fyData}
                                                    enableCharts={false}
                                                    onGridReady={onGridReady1}
                                                    onGridState={onGridState}
                                                    frameworkComponents={frameworkComponents}
                                                    statusBar={""}
                                                    sideBar={""}
                                                    stopEditingWhenGridLosesFocus={true}
                                                    paginationPageSize={1000}
                                                    pagination={false}
                                                    gridOptions={{
                                                        context: { componentParent: this },
                                                        rowClass: "your-row-class", // Set the row class for styling
                                                    }}
                                                    enableRangeSelection={true}
                                                    modules={[MenuModule, ExcelExportModule]}
                                                />
                                            </div>

                                            <div id="myGrid1" style={{ width: "100%", height: "30vh", padding: "2%", fontWeight: "600", fontSize: "13px" }} className={"ag-theme-balham"}>
                                                <AgGridReact
                                                    columnDefs={columnDefsForMaterialConsumption1}

                                                    defaultColDef= {{
                                                        sortable: true,
                                                        filter: true,
                                                        editable: false,
                                                        resizable: true
                                                    }}
                                                    rowData={actualConsumptionSlider.yearData}
                                                    enableCharts={false}
                                                    onGridReady={onGridReady1}
                                                    onGridState={onGridState}
                                                    frameworkComponents={frameworkComponents}
                                                    statusBar={""}
                                                    sideBar={""}
                                                    stopEditingWhenGridLosesFocus={true}
                                                    paginationPageSize={1000}
                                                    pagination={false}
                                                    gridOptions={{
                                                        context: { componentParent: this}
                                                    }}
                                                    enableRangeSelection={true}
                                                    modules={[MenuModule, ExcelExportModule]}
                                                />
                                            </div>
                                            <div>
                                                <MaterialDataChart plotData={actualConsumptionSlider.plotData} />
                                            </div>
                                        </div>
                            }
                            {(slider.sliderType === "view_actual_data") &&
                                <div className="slider-z-index">
                                    <div className={"row mt-20p"}>
                                        <div className={"col-sm-12 "} >
                                            &nbsp;
                                        </div>
                                    </div>
                                    <div className={"row mb-20p"}>
                                        {/* {(this.state.chartseries.length > 0)?
                                    <div className={"mxheit col-sm-12 mt-20p "} >
                                        <div className="card">
                                            <div className="card-body">
                                                
                                                <ColumnChart
                                                title={"FY wise Orders"}
                                                series={this.state.chartseries[0].overallfinancialorders}
                                                />
                                            
                                            </div>
                                        </div>
                                    </div> :""}
                                    
                                    {(this.state.chartseries.length > 0)?
                                    <div className={"mxheit col-sm-12  mt-20p"} >
                                        <div className="card">
                                            <div className="card-body">
                                                <ColumnChart
                                                title={"FY wise Costs"}
                                                series={this.state.chartseries[0].overallfinancialcost}
                                                />
                                            
                                            </div>
                                        </div>
                                    </div> :""} */}

                                        {/* {(this.state.confidenceTrain.length > 0 && this.state.confidenceTestPoints.length > 0)? */}
                                        
                                        <div className={"mxheit col-sm-12 mt-20p"}>
                                            <ConfidenceChart
                                                title={"Inventory Forecast"}
                                                subtitle={"Material Code: " + (dataVisualization.chartItem.value)}
                                                xAxisTitle={""}
                                                yAxisTitle={"Inventory Quantity Consumption"}
                                                confLine={dataVisualization.confidenceTrain}
                                                confLineTrend={dataVisualization.confidenceTrend}
                                                confLinePredictTest={dataVisualization.confidenceTestPoints}
                                                confLinePredictTrend={dataVisualization.trend_predicted_points}
                                                confAreaData={dataVisualization.confAreaData}
                                                confAreaYHat={dataVisualization.confAreaYHat}
                                                confScatter={dataVisualization.confScatter}
                                                dataType={1}
                                                foreCast_period={dataVisualization.foreCast_period}
                                            // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                            // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                            />
                                        </div>
                                        <div className={"mxheit col-sm-12 mt-20p"}>
                                            <ConfidenceChart
                                                title={"Inventory Cost"}
                                                subtitle={"Material Code: " + (dataVisualization.chartItem.value)}
                                                xAxisTitle={""}
                                                yAxisTitle={"Cost"}
                                                confLine={dataVisualization.confidenceTrain}
                                                confLineTrend={dataVisualization.confidenceTrend}
                                                confLinePredictTest={dataVisualization.confidenceTestPoints}
                                                confLinePredictTrend={dataVisualization.trend_predicted_points}
                                                confAreaData={dataVisualization.confAreaData}
                                                confAreaYHat={dataVisualization.confAreaYHat}
                                                confScatter={dataVisualization.confScatter}
                                                trendingcost={dataVisualization.trendingcost}
                                                dataType={2}
                                            // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                            // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                            />
                                        </div>
                                        {/* :""} */}

                                        {/* {( this.state.confidenceTrend.length > 0)? */}
                                        <div className={"mxheit col-sm-12 mt-20p"}>
                                            <LineChart
                                                title={"Yearly Trend"}
                                                subtitle={"Material Code: " + (dataVisualization.chartItem.value)}
                                                confLineTrend={dataVisualization.confTrendplot}

                                            />
                                        </div>
                                        {/* :""} */}

                                        {/* {( this.state.seasonality_train_plot !== undefined && this.state.seasonality_pred_plot !== "" && this.state.seasonality_pred_plot !== undefined)? */}
                                        <div className={"mxheit col-sm-12 mt-20p"}>
                                            <div className="card">
                                                {/*
                                                <div className="card-header">
                                                    <div style={{width:"36%",zIndex:"9"}}>
                                                        <div className="row">
                                                            <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Select FY: </label>
                                                            <div className="col-sm-8">
                                                                <Select
                                                                    value={this.state.fyChart}
                                                                    options={this.fyitems()}
                                                                    onChange={this.onChangeFYData.bind(this)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="card-body">
                                                    <AreaRangeChart
                                                        title={"Seasonality Data"}
                                                        subtitle={"Material Code: " + (dataVisualization.chartItem.value)}
                                                        confLineSeasonal={dataVisualization.confLineSeasonal}
                                                    // predictseries={this.state.seasonality_pred_plot}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* :""} */}

                                        <br /><br />
                                    </div>
                                </div>
                                }
                </div>
                {/* bulk upload slider  */}
                <div className={`stock-status-slider ${isSliderOpen ? 'open' : ''} ${sliderCl}`} style={{ zIndex: "100000000" }}>
                    <div className="slider-header-container d-flex justify-content-between">
                        {/* <button onClick={() => setSliderOpen(false)}>Close Slider</button> */}
                        <p className="m-0p" style={{fontSize: "1.3vw"}}>{sliderTitle}</p>
                        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "#f1f1f1", borderRadius: "50%", width: "30px", height: "30px", padding: "5px" }}>
                            <p className="" style={{ cursor: "pointer", margin: "0px" }} onClick={onClickingSliderCloseIcon}>X</p>
                        </div>
                    </div>
                    {sliderType == 1 && 
                        <div className="p-10p" style={{backgroundColor: "#ECEEED", height: "92vh"}}>
                        <form onSubmit={onSubmitBulkUpload}>
                        <div >
                            <div className="form-group col-sm-12">
                                <a className="upload-btn" href={templatePath} target="_blank">Download Sample Template</a>
                            </div>
                            <div className="form-group col-sm-12 mb-5p">
                               <label className="fw-600 f15" style={{ color: "black" }}>Upload File</label>
                               <input
                                    type="file" accept=".csv,.xlsx,.xls"
                                    onChange={handleFileChange}
                                    style={{ border: "1px solid lightgrey", width: "100%", height: "100%", padding: "8px", paddingLeft: "10px", paddingRight: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", color: "#000", fontSize: "13px" }}
                                    id="bulkUploadFile"
                                />                            
                            </div>
                            <p style={{ color: "red", fontSize: "13px", margin: "0px", marginTop: "10px", marginLeft:"10px",  marginBottom:"20px"}}>*Accept .csv & .xlsx files only</p>

                            <div className="form-group " style={{marginLeft:"10px", marginRight:"10px"}}>
                                <label className="fw-600 f15" style={{ color: "black" }}>Phase</label>
                                <Select
                                        name="pahse"
                                        placeholder="Select"
                                        onChange={(event) => {
                                            setBulkUplaodPhase(event)
                                        }}
                                        style={{ border: "1px solid lightgrey", width: "100%", height: "100%", padding: "8px", paddingLeft: "10px", paddingRight: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", color: "#000", fontSize: "13px" }}
                                        value={bulkUplaodPhase}
                                        options={phaseList}
                                        styles={customStyles}
                                        required
                                    />
                            </div>
                                <div className="col-2 d-flex align-items-end" style={{ width: "10%", marginTop: "22px",  marginLeft:"8px", paddingLeft:"4px"}}>
                                   <button className="upload-btn">Submit</button>
                                </div>
 
                        </div>
                    </form>
                    </div>
                        }
                </div>
                

                {loadShow.loadshow === "show-m" && (
                    <>
                        <div class="new-loader" style={{ zIndex: "9999" }}>
                            <div class="bar bar1"></div>
                            <div class="bar bar2"></div>
                            <div class="bar bar3"></div>
                            <div class="bar bar4"></div>
                            <div class="bar bar5"></div>
                            <div class="bar bar6"></div>
                            <div class="bar bar7"></div>
                            <div class="bar bar8"></div>
                        </div>
                        <div className="col-12 fw-600 f20" style={{ color: "black", position: "absolute", top: "54%", left: "47%", zIndex: "9999" }}>
                            <p>Loading...</p>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
};

export default SummaryData;
