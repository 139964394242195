import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import Select from 'react-select';
import redirectURL from '../redirectURL';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';

window['jQuery'] = window['$'] = $;
var moment = require("moment");

class UnderPredictionAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showloader: "show-n",
            phase: { "value": "2023-Jan-Jun", "label": "2023-Jan-Jun" },
            phases: [{ "value": "2023-Jan-Jun", "label": "2023-Jan-Jun" }],
            s1: "btn-success",
            s2: "btn-default",
            s3: "btn-default",
            requestType: 1,
            showPhase: "",
            showSlideBlock: "",
            overly: "",
            overlayBlock: "",
            sliderTitle: "Trigger Learining",
            title: "",
            plotData: [],
            predDiffPlotData: [],
            summaryData: [],
            headerName: "",
            overall_excess_cost: 0,
            overall_under_prediction_percentage: 0,
            pred_per_ved_wrt_all_df: [],
            pred_percentage_wrt_ved_df: [],
            pred_wrt_all_cost_df: [],
            pred_wrt_ved_df: [],
            is_tab_1_active: true,
            is_tab_2_active: false,
            is_tab_3_active: false,
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },

        }
    }


    componentDidMount = async () => {
        this.setState({
            showloader: "show-m",
            overly: 'show-n',
            overlayBlock: "show-n"
        })
        redirectURL.post("/dashboard/phaselists")
            .then((response) => {
                var records = response.data.records;
                var recordsarr = [];
                if (records.length > 0) {
                    records.map((itm) => {
                        recordsarr.push({ value: itm, label: itm });
                    })
                }
                this.setState({
                    phases: recordsarr.reverse(),
                    phase: recordsarr[1]
                }, () => { this.loadData() })
            })
    }



    loadData() {
        var params = {
            phase: this.state.phase.value,
            requestType: this.state.requestType,
            occuranceCount: 5,
            totalQuantity: 10
        }

        redirectURL.post("forecast/getLessConsumptionData", params)
            .then((response) => {
                console.log(response.data, "get-less-consumption")
                try {
                    if (response.status === 200) {
                        console.log(JSON.parse(response.data.occurance_data))
                        var rowData = JSON.parse(response.data.occurance_data).map((each) => {
                            var start_date = moment.parseZone(each.consumption_start_date).format('YYYY-MM-DD');
                            var end_date = moment.parseZone(each.consumption_end_date).format('YYYY-MM-DD');
                            return ({ ...each, consumption_start_date: start_date, consumption_end_date: end_date })
                        }
                        )
                        this.setState({
                            showloader: "show-n",
                            overly: "show-n",
                            plotData: rowData
                        })
                    }
                    else {
                        this.setState({
                            showloader: "show-n",
                            overly: "show-n"
                        })
                    }
                } catch (error) {

                }
            })
    }

    loadPredictionDifferenceData() {
        var params = {
            phase: this.state.phase.value
        }

        redirectURL.post("forecast/getPredictionDifferenceData", params)
            .then((response) => {
                console.log(response.data, "get-less-consumption")
                try {
                    if (response.status === 200) {
                        console.log(JSON.parse(response.data.difference_data))
                        this.setState({
                            showloader: "show-n",
                            overly: "show-n",
                            predDiffPlotData: JSON.parse(response.data.difference_data),
                            summaryData: JSON.parse(response.data.summary_data)
                        })
                    }
                    else {
                        this.setState({
                            showloader: "show-n",
                            overly: "show-n"
                        })
                    }
                } catch (error) {

                }
            })
    }

    onClickTab(requestType) {
        if (requestType == 1) {
            this.setState({
                s1: "btn-success",
                s2: "btn-default",
                s3: "btn-default",
                showloader: "show-m",
                is_tab_1_active: true,
                is_tab_2_active: false,
                is_tab_3_active: false,
                requestType
                ,
            }, () => { this.loadData() })
        }
        if (requestType == 2) {
            this.setState({
                s1: "btn-default",
                s2: "btn-success",
                s3: "btn-default",
                showloader: "show-m",
                is_tab_1_active: false,
                is_tab_2_active: true,
                is_tab_3_active: false,
                requestType
                ,
            }, () => { this.loadData() })
        }
        if (requestType == 3) {
            this.setState({
                s1: "btn-default",
                s2: "btn-default",
                s3: "btn-success",
                showloader: "show-m",
                is_tab_1_active: false,
                is_tab_2_active: false,
                is_tab_3_active: true,
            }, () => { this.loadPredictionDifferenceData() })
        }
    }

    onClickGetData() {
        this.setState({
            showloader: "show-m",
        })
        var params = {
            phase: this.state.phase.value,
            requestType: this.state.requestType,
            occuranceCount: 5,
            totalQuantity: 10
        }
        this.loadData(params),
        this.loadPredictionDifferenceData()
    }

    onChangePhase(phase) {
        this.setState({ phase })
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

    };


    render() {

        if (localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined) {
            return <Navigate to={"/login"} push={true} />
        }

        var columnwithDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 190,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "No. of Consumptions",
                field: "occurance_count",
                width: 190,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Total Quantity",
                field: "total_quantity",
                width: 140,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Consumption Start Date",
                field: "consumption_start_date",
                width: 200,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Consumption End Date",
                field: "consumption_end_date",
                width: 190,
                filter: true,
                resizable: true,
                editable: false
            },
            // {
            //     headerName: "Six Month Windows",
            //     field: "six_month_windows",
            //     width: 150,
            //     filter: true,
            //     resizable: true,
            //     editable: false
            // },
            // {
            //     headerName: "Window Wise Consumption",
            //     field: "window_wise_consumption",
            //     width: 170,
            //     filter: true,
            //     resizable: true,
            //     editable: false
            // }
        ]
        var columnwithPredDiffDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Material Description",
                field: "item_description",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 150,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Forecast Horizon(months)",
                field: "fc_period",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Predicted Cost ( $ )",
                field: "predicted_cost",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Predicted Quantity",
                field: "predicted_quantity",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Unit Cost ( $ )",
                field: "unit_cost",
                width: 140,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Actual Cost ( $ )",
                field: "actual_cost",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Actual Quantity",
                field: "actual_quantity",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Cost Difference",
                field: "cost_difference",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Quantity Difference",
                field: "quantity_difference",
                width: 150,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: "Quantity Difference Range",
                field: "quantity_difference_range",
                width: 140,
                filter: true,
                resizable: true,
                editable: false
            },
        ]
        this.gridOptions = {
            // Other grid options
            rowClass: 'your-row-class', // Set the row class for styling
          };
          this.gridOptions1 = {
            // Other grid options
            rowClass: 'your-row-class', // Set the row class for styling
          };
        console.log(this.state.summaryData)
        const headers = ["Material Classification", "Total Materials", "1-2", "3-4", "5-6", "7-8", "9-10", ">10"];
        // Column definitions
        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              fontWeight: 'bold',
              color: "black" // Set font-weight to bold for selected options
            }),
            singleValue: (provided, state) => ({
                ...provided,
                fontWeight: 'bold', // Always display the selected option in bold
              }),
          };
        return (
            <div>
                <div className={'lodr ' + (this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                <div className="row mb-20p pt-20p">
                    <div className="col-sm-12 d-flex">
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Under Prediction Analysis</span>
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 d-flex">
                        <button type="button" className={"btn " + (this.state.s1)} onClick={this.onClickTab.bind(this, 1)}>Less Consumption Data</button>
                        <button type="button" className={"btn " + (this.state.s2)} onClick={this.onClickTab.bind(this, 2)}>Limited Material Quantity</button>
                        <button type="button" className={"btn " + (this.state.s3)} onClick={this.onClickTab.bind(this, 3)}>Prediction vs Actual Difference</button>
                    </div>
                </div>
                <div className="row align-items-center mb-20p pt-20p">

                    <div className="col-sm-2 mt-5p">
                        <label className="fbold f17">Phase</label>
                        <Select
                            onChange={this.onChangePhase.bind(this)}
                            options={this.state.phases}
                            value={this.state.phase}
                            styles={customStyles} 
                        />
                    </div>

                    <div className="col-sm-2 d-flex">
                        <button type="button" className="btn btn-warning getBtn" onClick={this.onClickGetData.bind(this)}>Get Data</button>
                    </div>
                </div>
                {this.state.is_tab_1_active || this.state.is_tab_2_active ? (
                    <div class="row mb-20p mt-30p">
                        <div className="col-sm-12 d-flex mb-20p" style={{color: "red", fontWeight: "bold", fontSize: "17px"}}>
                           <span style={{color: "black"}}>Note:&nbsp;</span> <span>*&nbsp;</span><span>{this.state.is_tab_1_active ? ("This screen shows materials having number of consumptions <= 5 and  total quantity <= 10 units in the entire consumption history, so accurate predictions are not possible.") : ("This screen shows materials with total quantity <= 10 units in entire consumption history. so accurate predictions are not possible")}</span>
                        </div>
                        <div className={"mxheit col-sm-12 "} >
                            <div id="myGrid1" style={{ width: "100%", height: "70vh" }} className={"ag-theme-balham"}>
                                <AgGridReact
                                    // modules={this.state.modules}
                                    rowData={this.state.plotData}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                    gridOptions={this.gridOptions}
                                    suppressRowHoverHighlight={true}
                                />
                            </div>
                        </div>

                    </div>
                ) : ""}
                {this.state.is_tab_3_active ? (
                    <div className="row mb-20p mt-30p">
                        <div className="col-sm-12 mb-10p">
                            <h5 className="fbold">Actual vs Predicted Quantity Missed Range</h5>
                        </div>
                        <div className="col-sm-12 ">
                            <table className="table table-bordered mb-50p">
                                <thead style={{ backgroundColor: "#beddeb", textAlign: "center"}}>
                                    <tr>
                                        {/* {headers.map((header, index) => (
                                            <th key={index}>{header}</th>
                                        ))} */}
                                        <th rowspan="2" style={{verticalAlign: "middle"}}>Material Classification</th>
                                        <th rowspan="2" style={{verticalAlign: "middle"}}>Total Materials</th>
                                        <th colspan="6">Quantity Missed Range</th>
                                    </tr>
                                    <tr>
                                        <th>1-2</th>
                                        <th>3-4</th>
                                        <th>5-6</th>
                                        <th>7-8</th>
                                        <th>9-10</th>
                                        <th>&gt;10</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.summaryData.map((row, rowIndex) => (
                                        <tr key={rowIndex} className="text-center bg-light">
                                            {headers.map((header, cellIndex) => (
                                                <td key={cellIndex}>{row[header]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-sm-12 mb-10p">
                            <h5 className="fbold">Under Predicted Materials Actual vs Predicted Data</h5>
                        </div>
                        <div className={"mxheit col-sm-12 "} >
                            <div id="myGrid1" style={{ width: "100%", height: "70vh" }} className={"ag-theme-balham"}>
                                <AgGridReact
                                    // modules={this.state.modules}
                                    rowData={this.state.predDiffPlotData}
                                    columnDefs={columnwithPredDiffDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                    gridOptions={this.gridOptions1}
                                    suppressRowHoverHighlight={true}
                                />
                            </div>
                        </div>

                    </div>
                ) : ""}
            </div>
        )
    }


}

export default UnderPredictionAnalysis