import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default class ColumnChartComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		 
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                    }
	      		  },
                    legend: {
                        enabled: false
                    },
	      		  title:{
	      				text: this.props.title,
						style: {
							color: "#f3d800"
						}
	      		  },
	      		xAxis: {
	      	        // categories: this.props.categories
						type: 'category',
						labels: {
							style: {
								color: '#f3d800'
							}
						},
						title: {
							style: {
								color: '#f3d800'
							}
						}
	      	     },
				 yAxis:{
					labels: {
						style: {
							color: '#f3d800'
						}
					},
					title: {
						style: {
							color: '#f3d800'
						}
					}
				 },
	      		  series: [
	      		    {
	      		      data: this.props.series
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: this.props.color,
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  },
				  

	      		};
                // console.log(this.props.chartType ,"this.props.chartTyp");
            highchartoptions.chart= {
				type: this.props.chartType,
				// background:'#00182e',
				// backgroundColor: {
				// 	linearGradient: ["#00182e","#000000"],
				// 	stops: [
				// 		[0, '#00182e'],
				// 		[1, '#000000']
				// 	]
				// },
				backgroundColor:'rgba(0,0,0,0)',
				style: {
				color: "#fff"
			}};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












