import React, { Component } from 'react';


import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

// drilldown(Highcharts);
export default class PieChartComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		 
			chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: this.props.title,
                align: 'left'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: this.props.series,
            credits:false
            // series:[
            //     {
            //         "name": "Planning",
            //         "colorByPoint": true,
            //         "data": [
            //             {
            //                 "name": "Total Demand CMT",
            //                 "y": 101
            //             },
            //             {
            //                 "name": "Planned Demand CMT",
            //                 "y": 10
            //             },
            //             {
            //                 "name": "Dropped Demand CMT",
            //                 "y": 20
            //             },
            //             {
            //                 "name": "Unplanned Demand CMT",
            //                 "y": 30
            //             }
            //         ]
            //     }
            // ],
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}
