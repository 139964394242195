import React, { Component, useState } from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";



const height = 35;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const MaterialList = (props) => {
    const options = props.options;
    const [material_code, setMaterialCode] = useState([{"value":"ALL","label":"ALL"}]);
    const [show, setShow] = useState(false);
    const [basicTitle, setBasicTitle] = useState('');
    const [basicType, setBasicType] = useState('default');
    const [duration, setDuration] = useState(null); 
    const {updateMaterialCode} = props;
    

    const onChangeMaterial = (newValue) => {
        setMaterialCode(newValue);
        updateMaterialCode(newValue)
    };

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        fontSize: "14px",
        color: "black" // Set font-weight to bold for selected options
      }),
      singleValue: (provided, state) => ({
          ...provided,
          fontSize: "14px",
          fontWeight: 'bold', // Always display the selected option in bold
          color: "black"
        }),
    };


    return (
        
            <div className="col-md-12" style={{paddingLeft: "0px"}}>
                <div className="">
                    {/* <label className="col-form-label f12">Material</label> */}
                    <Select 
                        placeholder={"Select"}
                        onChange={onChangeMaterial}
                        name="material_code"
                        value={material_code} 
                        components={{ MenuList }} 
                        options={options} 
                        styles={customStyles}
                    />
                </div>
            </div>
            
            
    );
};

export default MaterialList;
