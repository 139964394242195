import React, {Component} from 'react';
import { VscGraphScatter, VscGraphLeft } from "react-icons/vsc";
import getToolConfiguration from "../optirun-admin/commonFunctions";
import withRouter from "../withRouter";
class Sidebar extends Component {
    constructor(props){
      super(props);
      this.state = {
        mainmenutoggle : "show-n",
      }
      localStorage.setItem("module_type",4);
      getToolConfiguration({})
    }
    toggleMainMenu = () =>{
      console.log(this.state.mainmenutoggle)
      if(this.state.mainmenutoggle == "show-n")
      {
        var mainmenutoggle = "show-m";
      }
      else
      {
        var mainmenutoggle = "show-n";
      }
      this.setState({
          mainmenutoggle :mainmenutoggle
      })
    }
    closeToggleMainMenu = () => {
      this.setState({
          mainmenutoggle :'show-n'
      })
    }
    redirectToModule = function(moduleType) {
      var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
      if(toolConfig !=undefined && toolConfig !="")
      {
        if(moduleType == 0)
        {
          window.location.href = "";
        }
        if(moduleType == 1)
        {
          var dispatchConfig = toolConfig.filter((tc) => tc.module_type == 1);
          if(dispatchConfig.length > 0)
          {
            var dispatchConfiginbound = dispatchConfig.filter((tc) => tc.data_type == 1);
            var dispatchConfigoutbound = dispatchConfig.filter((tc) => tc.data_type == 2);
            if(dispatchConfiginbound.length > 0 && dispatchConfigoutbound.length == 0)
            {
              localStorage.setItem("module_type",1);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_dispatch",0);
              window.location.href = "/loadprocessrun?inbound";
            }
            else if(dispatchConfigoutbound.length > 0 && dispatchConfiginbound.length == 0)
            {
              localStorage.setItem("module_type",1);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_dispatch",0);
              window.location.href = "/loadprocessrun?outbound";
            }
            else
            {
              // window.location.href = "/dispatchPlanningHomePage";
              window.location.href = "/loadprocessrun?inbound";
              localStorage.setItem("module_type",1);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_dispatch",1);
            }
          }
        }        
        if(moduleType == 2)
        {
          var dispatchConfig = toolConfig.filter((tc) => tc.module_type == 2);
          if(dispatchConfig.length > 0)
          {
            var dispatchConfiginbound = dispatchConfig.filter((tc) => tc.data_type == 1);
            var dispatchConfigoutbound = dispatchConfig.filter((tc) => tc.data_type == 2);
            if(dispatchConfiginbound.length > 0 && dispatchConfigoutbound.length == 0)
            {
              localStorage.setItem("module_type",2);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_stacking",0);
              window.location.href = "/stackplan?inbound";
            }
            else if(dispatchConfigoutbound.length > 0 && dispatchConfiginbound.length == 0)
            {
              localStorage.setItem("module_type",2);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_stacking",0);
              window.location.href = "/stackplan?outbound";
            }
            else
            {
              // window.location.href = "/dispatchPlanningHomePage";
              window.location.href = "/stackplan?inbound";
              localStorage.setItem("module_type",2);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_stacking",1);
            }
          }
          else
          {
            localStorage.setItem("module_type",2);
            window.location.href = "/stackplan";
          }
        }
    
        if(moduleType == 4)
        {
          // var dispatchConfig = toolConfig.filter((tc) => tc.module_type == 2);
          // if(dispatchConfig.length > 0)
          // {
          //   var dispatchConfiginbound = dispatchConfig.filter((tc) => tc.data_type == 1);
          //   var dispatchConfigoutbound = dispatchConfig.filter((tc) => tc.data_type == 2);
          //   if(dispatchConfiginbound.length > 0 && dispatchConfigoutbound.length == 0)
          //   {
          //     localStorage.setItem("module_type",3);
          //     localStorage.setItem("data_type",1);
          //     localStorage.setItem("both_data_types_enabled_for_stacking",0);
          //     window.location.href = "/stackplan?inbound";
          //   }
          //   else if(dispatchConfigoutbound.length > 0 && dispatchConfiginbound.length == 0)
          //   {
          //     localStorage.setItem("module_type",3);
          //     localStorage.setItem("data_type",1);
          //     localStorage.setItem("both_data_types_enabled_for_stacking",0);
          //     window.location.href = "/stackplan?outbound";
          //   }
          //   else
          //   {
          //     // window.location.href = "/dispatchPlanningHomePage";
          //     window.location.href = "/stackplan?inbound";
          //     localStorage.setItem("module_type",3);
          //     localStorage.setItem("data_type",1);
          //     localStorage.setItem("both_data_types_enabled_for_stacking",1);
          //   }
          // }
          // else
          // {
            localStorage.setItem("module_type",4);
            window.location.href = "/forecast-predict";
          // }
        }
         
      }
      else
      {
        alert("No Access...");
      }
    }.bind(this);
    render(){
        var path = "";
        var activeChk = "";
        var url_type = window.location.href;
        if(url_type !=undefined && url_type !="")
        {
          var path_type = url_type.split('/');
          var path = path_type[path_type.length-1];
          var path_type_2 = url_type.split('?');
          var path_type_3 = path_type_2[0].split('/');
          var activeChk = path_type_3[path_type.length-1];
        }
        var inbound_vehicle_availability = 0;
        var inbound_material_configurations = 0;
        var inbound_plant_wise_transporter_configuration = 0;
        var inbound_plant_wise_freight_configuration = 0;
        var inbound_route_configuration = 0;
        var inbound_auto_clustering = 0;
        var inbound_transporter_share_of_business = 0;
        var inbound_route_configuration_type = 0
        var outbound_vehicle_availability = 0;
        var outbound_material_configurations = 0;
        var outbound_plant_wise_transporter_configuration = 0;
        var outbound_plant_wise_freight_configuration = 0;
        var outbound_route_configuration = 0;
        var outbound_auto_clustering = 0;
        var outbound_transporter_share_of_business = 0;
        var outbound_route_configuration_type = 0
        if(localStorage.getItem('tlcfg') !=undefined && localStorage.getItem('tlcfg') !="")
        {
          var toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
          if(toolConfig.length > 0)
          {
              var inboundData = toolConfig.filter((t) => t.module_type == 1 && t.data_type == 1);
              if(inboundData.length > 0)
              {
                inbound_material_configurations = inboundData[0].material_configuration;
                inbound_vehicle_availability = inboundData[0].vehicle_availability;
                inbound_plant_wise_transporter_configuration = inboundData[0].plant_wise_transporter_configuration;
                inbound_plant_wise_freight_configuration = inboundData[0].plant_wise_freight_configuration;
                inbound_route_configuration = inboundData[0].route_configuration;
                inbound_auto_clustering = inboundData[0].auto_clustering;
                inbound_transporter_share_of_business = inboundData[0].transporter_share_of_business;
                inbound_route_configuration_type = inboundData[0].route_configuration_type;
              }
              var outboundData = toolConfig.filter((t) => t.module_type == 1 && t.data_type == 2);
              if(outboundData.length > 0)
              {
                outbound_material_configurations = outboundData[0].material_configuration;
                outbound_vehicle_availability = outboundData[0].vehicle_availability;
                outbound_plant_wise_transporter_configuration = outboundData[0].plant_wise_transporter_configuration;
                outbound_plant_wise_freight_configuration = outboundData[0].plant_wise_freight_configuration;
                outbound_route_configuration = outboundData[0].route_configuration;
                outbound_auto_clustering = outboundData[0].auto_clustering;
                outbound_transporter_share_of_business = outboundData[0].transporter_share_of_business;
                outbound_route_configuration_type = outboundData[0].route_configuration_type;
              }
          }
        }
        var inboundZoneMapping = 0;
        if(inbound_transporter_share_of_business == 1 || inbound_route_configuration_type == 2)
        {
          inboundZoneMapping = 1;
        }
        var outboundZoneMapping = 0;
        if(outbound_transporter_share_of_business == 1 || outbound_route_configuration_type == 2)
        {
          outboundZoneMapping = 1;
        }
        return (
            <div className="left_col scroll-view">
            {/* <div className="navbar nav_title" style={{border: "0"}}>
              <a id="menu_toggle" className="site_title" onClick={this.toggleMainMenu}><i className="fa fa-th"></i> <span></span></a>
            </div> */}
            <div className={"main-menu "+this.state.mainmenutoggle}>
                <i className='fa fa-close float-right p-20p close-toggle' onClick={this.closeToggleMainMenu}></i>
                <a style={{width:"50%"}} id="menu_toggle_1" className="site_title" onClick={this.toggleMainMenu}><i className="fa fa-th"></i> <span></span></a>
                  <div className="clearfix"></div>
                  <div className="col-sm-12">
                      <div className="m_content">
                          <div className="col-md-12" onClick={() => { this.redirectToModule(1) }}>
                              <div className="x_content center">
                                <h2 className="dTile1 bgc2fdc1">
                                  <img src={require("../../assets/images/home.png")} />
                                </h2>
                              </div>
                          </div>
                          <div className="col-sm-12" onClick={() => { this.redirectToModule(0) }}>
                              <div className="x_content center">
                                <h6 className="h6custom">Home</h6>
                              </div>
                          </div>
                        </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="m_content">
                          <div className="col-md-12" onClick={() => { this.redirectToModule(1) }}>
                              <div className="x_content center">
                                <h2 className="dTile1 bgc2fdc1">
                                  <img src={require("../../assets/images/dispatch_planning.png")} />
                                </h2>
                              </div>
                          </div>
                          <div className="col-sm-12" onClick={() => { this.redirectToModule(1) }}>
                              <div className="x_content center">
                                <h6 className="h6custom">Dispatch Planning</h6>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="m_content">
                        <div className="col-md-12" onClick={() => { this.redirectToModule(2) }}>
                            <div className="x_content center">
                              <h2 className="dTile1 bgc2fdc1">
                                <img src={require("../../assets/images/stacking.png")} />
                              </h2>
                            </div>
                        </div>
                        <div className="col-sm-12" onClick={() => { this.redirectToModule(2) }}>
                            <div className="x_content center">
                              <h6 className="h6custom">Stacking</h6>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="m_content">
                        <div className="col-md-12" onClick={() => { this.redirectToModule(3) }}>
                            <div className="x_content center">
                              <h2 className="dTile1 bgc2fdc1">
                                <img src={require("../../assets/images/production_planning.png")} />
                              </h2>
                            </div>
                        </div>
                        <div className="col-sm-12" onClick={() => { this.redirectToModule(3) }}>
                            <div className="x_content center">
                              <h6 className="h6custom">Production Planning</h6>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="m_content">
                      <div className="col-md-12" onClick={() => { this.redirectToModule(4) }}>
                          <div className="x_content center">
                            <h2 className="dTile1 bgc2fdc1">
                              <img src={require("../../assets/images/forecasting.png")} />
                            </h2>
                          </div>
                      </div>
                      <div className="col-sm-12" onClick={() => { this.redirectToModule(4) }}>
                          <div className="x_content center">
                            <h6 className="h6custom">Forecasting</h6>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="m_content">
                        <div className="col-md-12" onClick={() => { this.redirectToModule(5) }}>
                            <div className="x_content center">
                              <h2 className="dTile1 bgc2fdc1">
                                <img src={require("../../assets/images/resource_schedule.png")} />
                              </h2>
                            </div>
                        </div>
                        <div className="col-sm-12" onClick={() => { this.redirectToModule(5) }}>
                            <div className="x_content center">
                              <h6 className="h6custom">Resource Scheduling</h6>
                            </div>
                        </div>
                      </div>
                  </div>
            </div>
            <div className="clearfix"></div>
            <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
              <div className="menu_section">
                <h3>General</h3>
                <ul className="nav side-menu">
                  <li>
                    <a href={"/"} className={(activeChk == "" ? "theme-clr":"")} style={{fontWeight: "bold"}}><i className="fa fa-home"></i> Home</a>                   
                  </li>
                  {/* {(path == "" || path == "dashboard" || path == "configurations") ?
                  <li>
                      <a className={(path == "configurations" ? "theme-clr":"")} href={"/configurations"}><i className="fa fa-cogs"></i> Configurations</a>                   
                  </li>
                  :""} */}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1) ?
                  <li>
                      <a className={(activeChk == "pickup_and_drop" ? "theme-clr":"")} href={"/pickup_and_drop?inbound"}><i className="fa fa-map-marker"></i> Pickup/Drop Locations</a>
                  </li>:""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1) ?
                  <li>
                      <a className={(activeChk == "vehicles_data" ? "theme-clr":"")} href={"/vehicles_data?inbound"}><i className="fa fa-truck"></i> Vehicle Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_vehicle_availability == 1) ?
                  <li>
                      <a className={(activeChk == "vehicle_availability" ? "theme-clr":"")} href={"/vehicle_availability?inbound"}><i className="fa fa-calendar-check-o"></i> Vehicle Availability</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inboundZoneMapping == 1) ?
                  <li>
                      <a className={(activeChk == "zonemapping" ? "theme-clr":"")} href={"/zonemapping?inbound"}><i className="fa fa-link"></i> Zone Mapping</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_plant_wise_transporter_configuration == 1) ?
                  <li>
                      <a className={(activeChk == "transporter_details" ? "theme-clr":"")} href={"/transporter_details?inbound"}><i className="fa fa-users"></i> Transporters</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_material_configurations == 1) ?
                  <li>
                      <a className={(activeChk == "materialconfigurations" ? "theme-clr":"")} href={"/materialconfigurations?inbound"}><i className="fa fa-archive"></i> Material Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_plant_wise_freight_configuration == 1) ?
                  <li>
                      <a className={(activeChk == "freightconfigurations" ? "theme-clr":"")} href={"/freightconfigurations?inbound"}><i className="fa fa-th-large"></i> Freight Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_route_configuration == 1) ?
                  <li>
                      <a className={(activeChk == "routeconfigurations" ? "theme-clr":"")} href={"/routeconfigurations?inbound"}><i className="fa fa-map-signs"></i> Route Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_auto_clustering == 2) ?
                  <li>
                      <a className={(activeChk == "autoclustering" ? "theme-clr":"")} href={"/autoclustering?inbound"}><i className="fa fa-database"></i> Auto Clustering</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_transporter_share_of_business == 1) ?
                  <li>
                      <a className={(activeChk == "transportershareofbusiness" ? "theme-clr":"")} href={"/transportershareofbusiness?inbound"}><i className="fa fa-credit-card"></i> Transporter Share Of Business</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1) ?
                  <li>
                      <a className={(activeChk == "loadprocessrun" ? "theme-clr":"")} href={"/loadprocessrun?inbound"}><i className="fa fa-tasks"></i> Dispatch Planning</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1) ?
                  <li>
                      <a className={(activeChk == "dipatchplanhistory" ? "theme-clr":"")} href={"/dipatchplanhistory?inbound"}><i className="fa fa-history"></i> Previous Plans</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2) ?
                  <li>
                      <a className={(activeChk == "pickup_and_drop" ? "theme-clr":"")} href={"/pickup_and_drop?outbound"}><i className="fa fa-map-marker"></i> Pickup/Drop Locations</a>
                  </li>:""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2) ?
                  <li>
                      <a className={(activeChk == "vehicles_data" ? "theme-clr":"")} href={"/vehicles_data?outbound"}><i className="fa fa-truck"></i> Vehicle Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_vehicle_availability == 1) ?
                  <li>
                      <a className={(activeChk == "vehicle_availability" ? "theme-clr":"")} href={"/vehicle_availability?outbound"}><i className="fa fa-calendar-check-o"></i> Vehicle Availability</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outboundZoneMapping == 1) ?
                  <li>
                      <a className={(activeChk == "zonemapping" ? "theme-clr":"")} href={"/zonemapping?outbound"}><i className="fa fa-link"></i> Zone Mapping</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_plant_wise_transporter_configuration == 1) ?
                  <li>
                      <a className={(activeChk == "transporter_details" ? "theme-clr":"")} href={"/transporter_details?outbound"}><i className="fa fa-users"></i> Transporters</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_material_configurations == 1) ?
                  <li>
                      <a className={(activeChk == "materialconfigurations" ? "theme-clr":"")} href={"/materialconfigurations?outbound"}><i className="fa fa-archive"></i> Material Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_plant_wise_freight_configuration == 1) ?
                  <li>
                      <a className={(activeChk == "freightconfigurations" ? "theme-clr":"")} href={"/freightconfigurations?outbound"}><i className="fa fa-th-large"></i> Freight Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_route_configuration == 1) ?
                  <li>
                      <a className={(activeChk == "routeconfigurations" ? "theme-clr":"")} href={"/routeconfigurations?outbound"}><i className="fa fa-map-signs"></i> Route Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_auto_clustering == 2) ?
                  <li>
                      <a className={(activeChk == "autoclustering" ? "theme-clr":"")} href={"/autoclustering?outbound"}><i className="fa fa-database"></i> Auto Clustering</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_transporter_share_of_business == 1) ?
                  <li>
                      <a className={(activeChk == "transportershareofbusiness" ? "theme-clr":"")} href={"/transportershareofbusiness?outbound"}><i className="fa fa-credit-card"></i> Transporter Share Of Business</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2) ?
                  <li>
                      <a className={(activeChk == "loadprocessrun" ? "theme-clr":"")} href={"/loadprocessrun?outbound"}><i className="fa fa-tasks"></i> Dispatch Planning</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2) ?
                  <li>
                      <a className={(activeChk == "dipatchplanhistory" ? "theme-clr":"")} href={"/dipatchplanhistory?outbound"}><i className="fa fa-history"></i> Previous Plans</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1) ?
                  <li>
                      <a className={(path == "configurations?dispatch_planning" ? "theme-clr":"")} href={"/configurations?dispatch_planning"}><i className="fa fa-cogs"></i> Configurations</a>                   
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 2) ?
                  <li>
                      <a className={(activeChk == "inputplan" ? "theme-clr":"")} href={"/inputplan"}><i className="fa fa-table"></i> Input Plan Summary</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 2) ?
                  <li>
                      <a className={(activeChk == "stackplan" ? "theme-clr":"")} href={"/stackplan"}><i className="fa fa-tasks"></i> Load Plan Summary</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 2) ?
                  <li>
                      <a className={(activeChk == "previousplans" ? "theme-clr":"")} href={"/previousplans"}><i className="fa fa-history"></i> Previous Plans</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 2) ?
                  <li>
                      <a className={(path == "configurations?stacking" ? "theme-clr":"")} href={"/configurations?stacking"}><i className="fa fa-cogs"></i> Configurations</a>                   
                  </li>
                  :""}
                  
                  {/* {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "forecast-suppliers" ? "theme-clr":"")} href={"/forecast-suppliers"}><i className="fa fa-users"></i> Suppliers</a>
                  </li>
                  :""}
                  {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "forecast-customers" ? "theme-clr":"")} href={"/forecast-customers"}><i className="fa fa-users"></i> Customers</a>
                  </li>
                  :""} */}
                  
                  {/* {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "forecast-dashboard" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/forecast-dashboard"}><i className="fa fa-bar-chart"></i> Forecast Dashboard</a>
                  </li>
                  :""} */}
                   {(localStorage.getItem("module_type") == 4) ?
                 <li>
                      <a className={(activeChk == "forecast-materials" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/forecast-materials"}><i className="fa fa-database"></i> Material Master</a>
                  </li>
                  :""}
                  {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "consumption-dashboard" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/consumption-dashboard"}><i className="fa fa-table"></i>Consumption Dashboard</a>
                  </li>
                  :""}
                  {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "forecast-trigger-learning" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/forecast-trigger-learning"}><i className="fa fa-cubes"></i> Material Consumption</a>
                  </li>
                  :""}
                  {/* {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "forecast-input" ? "theme-clr":"")} href={"/forecast-input"}><i className="fa fa-table"></i> Material Consumption Analytics</a>
                  </li>
                  :""} */}
                  {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "forecast-predict" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/forecast-predict"}><i className="fa fa-line-chart"></i>Material Forecast</a>
                  </li>
                  :""}
                  {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "anomaly-predict" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/anomaly-predict"}><VscGraphScatter style={{fontSize: "30px", marginBottom: "5px"}}/><br/>Prediction Analysis</a>
                  </li>
                  :""}
                  {/* {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "newmaterial-analysis" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/newmaterial-analysis"}><i className="fa fa-search"></i> New Material Analysis</a>
                  </li>
                  :""} */}
                  {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "forecastresult_analysis" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/forecastresult_analysis"}><i className="fa fa-area-chart"></i> Forecast Result Analysis</a>
                      {/* <a className={(activeChk == "predictionanalysis" ? "theme-clr":"")} href={"/predictionanalysis"}><i className="fa fa-bar-chart"></i> Forecast Analysis</a> */}
                  </li>
                  :""}
                  {(localStorage.getItem("module_type") == 4 && (localStorage.getItem("email") == "testing_new@enmovil.in" )) ?
                  <li>
                      <a className={(activeChk == "results-summary" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/results-summary"}><i className="fa fa-file-text-o"></i> Results Summary</a>
                  </li>
                  :""}
                  {(localStorage.getItem("module_type") == 4 && (localStorage.getItem("email") != "anita.modi@cairnindia.com" && localStorage.getItem("email") != "sasikumar.krishnan@cairnindia.com")) ?
                  <li>
                      <a className={(activeChk == "business-dashboard" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/business-dashboard"}><i className="fa fa-file-text-o"></i>Business Dashboard</a>
                  </li>
                  :""}
                  {/* {(localStorage.getItem("module_type") == 4 && (localStorage.getItem("email") != "anita.modi@cairnindia.com" && localStorage.getItem("email") != "sasikumar.krishnan@cairnindia.com")) ?
                  <li>
                      <a className={(activeChk == "under-prediction-analysis" ? "theme-clr":"")} style={{fontWeight: "bold"}} href={"/under-prediction-analysis"}><VscGraphLeft style={{fontSize: "30px", marginBottom: "5px"}}/><br/>Under Prediction Analysis</a>
                  </li>
                  :""} */}
                  {/* {(localStorage.getItem("module_type") == 4) ?
                  <li>
                      <a className={(activeChk == "forecast-previous" ? "theme-clr":"")} href={"/forecast-previous"}><i className="fa fa-pie-chart"></i> Previous Plan</a>
                  </li>
                  :""} */}
                  {(localStorage.getItem("module_type") == 4 && (localStorage.getItem("role") == "admin")) ?
                  <li>
                      <a className={(path == "configurations?forecasting" ? "theme-clr":"")} style={{fontWeight: "500"}} href={"/configurations?forecasting"}><i className="fa fa-cogs"></i> Configurations</a>                   
                  </li>
                  :""}
                </ul>
              </div> 
            </div>
          </div>
        );
    }
}

export default withRouter(Sidebar) ;
