import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class ConfidenceChart extends Component {

    constructor(props) {
        super(props);
    }

    render(){
        var zonelist = []
        var confSeries  = [];
        let predictLine = [];
        let predictLineCombined = [];
        let originalValues = [];
        let newConfAreaYHat = [];
        // console.log(this.props,'conf poorp')
        if(this.props.foreCast_period != undefined && this.props.foreCast_period != ''){
            let lenOfArr = (this.props.confAreaYHat).length 
            let coloredConfYHat = [];
            let coloredPredictLine = [];

            newConfAreaYHat = (this.props.confAreaYHat).slice(0,lenOfArr - this.props.foreCast_period);
            let id = 0
            let lastValue = 0
            // newConfAreaYHat.map((item,idx) =>{
            //     coloredConfYHat.push({x:idx,y:item})
            //     // coloredConfYHat.push({y:item,color:"#260CFF"})
            //     // zonelist.push({
            //     //     value:item,
            //     //     color:"#260CFF"
            //     // })
            //     id = idx;
            //     lastValue = item;
            // })
            // confSeries = coloredConfYHat

            //to show original trend on chart
            let actValues = this.props.actualValues;
            let updatedValues = [];
            let lastItemData = [lastValue];
            updatedValues = lastItemData.concat(actValues)
            updatedValues.map((item,idx)=>{
              
                originalValues.push({x:id+idx,y:item})
            })
            
            
            //to show predicted trend on chart
            predictLine = (this.props.newConfAreaYHat).slice(-(this.props.foreCast_period))
            // predictLine.map(item =>coloredPredictLine.push({name:"Predict",y:item,color:"#ff0000"}))
            // predictLine.map((item,idx) =>{
            //     // coloredConfYHat.push({y:item})
            //     predictLineCombined.push({x:id+idx,y:item})
            //     // zonelist.push({
            //     //     value:item,
            //     //     color:"#ff0000"
            //     // })
            // })

            // predictLineCombined = coloredConfYHat.concat(coloredPredictLine)
            // confSeries = coloredConfYHat

        }

        // const dStyles={
		// 		width:'100%',
		// 		height:'100%'
		// }
		// console.log("this.props.series ", this.props.series)
        
        var chartSeries = [
            {
                type: 'areasplinerange',
               showInLegend: true,
               visible: true,
            //    pointStart: Date.UTC(2016, 0),
            //     pointIntervalUnit: 'month',
            //     zoneAxis: 'x',
               color:"#D0E4FF",
               // enableMouseTracking: true,
               name:"Confidence Interval",
                data: this.props.confAreaData,
                
                // pointInterval: 2592000000,
                marker: {
                    radius: 4,
                
                    enabled: false
                },
                tooltip:{
                    enabled:false
                }
            },
            {
                type: 'spline',
                showInLegend: true,
                name: 'Past Trend',
                color:'blue',
                data: newConfAreaYHat,
                marker: {
                    enabled: false
                },
                // zones: zonelist
                // states: {
                //     hover: {
                //         lineWidth: 0
                //     }
                // },
                // enableMouseTracking: false
            },
            {
                type: 'spline',
                showInLegend: true,
                name: 'Predicted Trend',
                color:'red',
                data: predictLine,
                marker: {
                    enabled: false
                },
            },
            
            // {
            //     type: 'spline',
            //     showInLegend: true,
            //     color:"#ff0000",
            //     name: 'Predict Points',
            //     data: this.props.confLinePredictTest,
            //     // marker: {
            //     //     enabled: false
            //     // },
            //     // states: {
            //     //     hover: {
            //     //         lineWidth: 0
            //     //     }
            //     // },
            //     // enableMouseTracking: false
            // },
            {
                type: 'scatter',
                showInLegend: true,
                // enableMouseTracking: false,
                color:"#444444",
                name: 'Actual Data',
                data: this.props.confScatter,
                marker: {
                    radius: 3
                }
            },
            {
                type: 'spline',
                showInLegend: true,
                name: 'Actual Data In Prediction Window',
                color:'green',
                data: this.props.actualValues,
                marker: {
                    enabled: false
                },
            },
        
        ]
        if(this.props.dataType == 2)
        {
            chartSeries = [
                {
                    type: 'spline',
                    showInLegend: true,
                    name: 'Cost',
                    color:"#d12440",
                    data: this.props.trendingcost,
                    marker: {
                        enabled: false
                    },
                },
            
            ]
        }
		const highchartoptions = {
            chart: {
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
            },
			title: {
                text: this.props.title,
            },
            subtitle:{
                text:this.props.subtitle
            },
            xAxis: {
                // min: -0.5,
                // max: 5.5
                // type:"category",
                // accessibility: {
                //     rangeDescription: 'Range: Apr 1st 2016 to Mar 31st 2022.'
                // }
                // categories:["2016","2017","2018","2019","2020","2021","2022"],
                type:"datetime",
                title: {
                    text: this.props.xAxisTitle
                },
                maxPadding:0.04
            },
            
            yAxis: {
                title: {
                    text: this.props.yAxisTitle
                }
            },
            // tooltip: {
			// 	headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
			// 	pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.0f}'
			// },
            // tooltip: {
            //     xDateFormat: '%Y %B ',
            //     // shared: true
            // },
            tooltip: {
                formatter: function() {
                    if(this.series.name !== "Confidence Interval")
                    {
                        var series = this.series.chart.series,
                            result = Highcharts.dateFormat('%Y %B ', new Date(this.x))+" <br />";
                            result += '<span style="color:' +
                            this.point.color + '">\u25CF</span> ' +
                            this.series.name + ': <b>' + this.point.y +
                            '</b><br/>';
                    
                        series.forEach(function(s) {
                            if (s !== this.series) {
                                // console.log("s.points[this.point.index]", s.points[this.point.index])
                                if(s.points[this.point.index] != undefined)
                                {
                                    if(s.name !== "Confidence Interval")
                                    {
                                        result += '<span style="color:' +s.points[this.point.index].color +'">\u25CF</span><span style="color: #c6c6c6"> ' + s.name + ': ' + s.points[this.point.index].y + '</span><br/>';
                                    }
                                }
                            
                            }
                        }, this);
                    
                        return result;
                        }
                    }
              },
            legend: {
                enabled: true
            },
            plotOptions: {
                series: {
                    dataGrouping:true
                    // data: this.props.confAreaYHat,
                    // pointInterval: 1,
                    // dataGrouping: {
                    //     approximation: 'average',
                    //     enabled: true,
                    //     forced: true,
                    //     // units: [
                    //     //     ['hour', [2]]
                    //     // ]
                    // }
                }
              },
            series: chartSeries,
			credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%', fontWeight: "bold", fontSize: "25px", color: "black" } }} highcharts={Highcharts} options={highchartoptions} />

			);
		}
}












