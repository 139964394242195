import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui';
// import 'jquery-ui/themes/base/datepicker.css';
// import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import redirectURL from '../redirectURL';
import CountUp from "react-countup";
// import PieChart from "./pieChart";
// import LineChart from "./lineChart";
import withRouter from '../withRouter';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import ForecastDashboardLineChartComponent from "./foreacastdashboardlinechart";
// import SplineChartComponent from "../forecast/splineChart";

window['jQuery'] = window['$'] = $;
var moment = require("moment");

class ForecastDashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            showloader:"show-n",
            exact_count:0,
            excess_count:0,
            under_count:0,
            excess_cost:0,
            under_pred_percentage:0,
            pieSeries:[],
            lineChartSeries:[],
            lineCategories:[],
            predlineChartSeries:[],
            predlineCategories:[],
            exactData:[],
            excessData:[],
            underData:[],

            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            phase:{"value":"","label":""},
            phases:[],
            animateRows: true,
            showSlideBlock:"",
            overlayBlock:"show-n",
            loadshow:'show-n',
            overly:'show-n',
            gridState:"",
            total_budget:0,
            total_predicted_cost:0,
            existing_outlier_cost:0,
            new_material_cost:0,
            line_complete_data:[],
            line_predicting_data:[],
            drillDownData:[]
            
        }
    }
    componentDidMount = async() => {
        var sdate = "2020-07-01";
        var edate = moment.parseZone(sdate).add(5,"months").format("YYYY-MM-DD");

        
        let start_date = moment.parseZone(new Date((sdate))).format("YYYY-MM-DD")
        let end_date = moment.parseZone(new Date((edate))).endOf('month').format("YYYY-MM-DD")

        this.setState({
            showloader:"show-m",
            startDate:start_date,
            endDate:end_date
        })
        await redirectURL.post("/dashboard/phaselists")
        .then((response) => {
            var records = response.data.records;
            var recordsarr = [];
            if(records.length > 0)
            {
                records.map((itm) => {
                    recordsarr.push({value:itm,label:itm});
                })
            }
            this.setState({
                phases:recordsarr.reverse(),
                phase: recordsarr[1]
            }, () => {this.loadData()})
        })
        
        
        $( ".datepicker" ).datepicker({
            dateFormat:"yy-mm-dd",
            changeYear:true,
            startDate:start_date,
            endDate:end_date
        });

        
    
        
    }
    loadData(){
        var params = {
            phase:this.state.phase.value,
        }
        redirectURL.post("dashboard/getForecastDashboardData",params)
        .then((response) => {
            // console.log("response ", response.data)
            try {
                if(response.data.status === "success")
                {
                    var total_predicted_cost = 0
                    var existing_outlier_cost = 0
                    var new_material_cost = 0
                    var line_complete_dataarr = []
                    var line_complete_data = []
                    var line_predicting_dataarr=[]
                    var line_predicting_data=[]
                    var total_budget = 0;

                    var forecastData =  response.data.forecastData;
                    var a = parseFloat(forecastData.total_predicted_cost);
                    var b = parseFloat(forecastData.existing_outlier_cost);
                    var c = parseFloat(forecastData.new_material_cost);
                    var d= a+b+c;
                    // console.log("d", d)
                    // console.log("d Fixed ", d.toFixed(2));
                    // total_budget = parseFloat(forecastData.total_predicted_cost)+parseFloat(forecastData.existing_outlier_cost)+parseFloat(forecastData.new_material_cost);
                    total_budget = forecastData.total_cost;
                    // console.log("total_budget ",total_budget)
                    var total_predicted_cost = forecastData.total_predicted_cost;
                    var existing_outlier_cost = forecastData.existing_outlier_cost;
                    var new_material_cost = forecastData.new_material_cost;

                    line_complete_dataarr = JSON.parse(forecastData.complete_data);
                    line_predicting_dataarr = JSON.parse(forecastData.predicting_data);
                    
                    // console.log("line_complete_dataarr ", line_complete_dataarr)
                    // console.log("line_predicting_dataarr ", line_predicting_dataarr)
                    if(line_complete_dataarr.length > 0)
                    {
                        
                        line_complete_dataarr.map(item => item.dataTimeStamp = (new Date(item.date)).getTime()+(60*60*24*1000))
                        line_complete_dataarr.map(item=>{
                            line_complete_data.push([item.dataTimeStamp,item.total_item_quantity])
                        })
                    }
                    if(line_predicting_dataarr.length > 0)
                    {
                        line_predicting_dataarr.map(item => item.dataTimeStamp = (new Date(item.date)).getTime()+(60*60*24*1000))
                        line_predicting_dataarr.map(item=>{
                            line_predicting_data.push([item.dataTimeStamp,item.total_item_quantity])
                        })
                    }
                    // console.log("line_complete_data ", line_complete_data)
                    // console.log("line_predicting_data ", line_predicting_data)
                    // console.log(total_budget , "total_budget")
                    // console.log(total_predicted_cost , "total_predicted_cost")
                    // console.log(existing_outlier_cost , "existing_outlier_cost")

                    this.setState({
                        total_budget:total_budget,
                        total_predicted_cost:total_predicted_cost,
                        existing_outlier_cost:existing_outlier_cost,
                        new_material_cost:new_material_cost,
                        line_complete_data:[line_complete_data],
                        line_predicting_data:[line_predicting_data],
                        showloader:"show-n"
                    })
                }
                else{
                    this.setState({
                        showloader:"show-n"
                    })
                }
            
                var months = ["Jan", "Feb", "Mar", "Apr","May","Jun","Jul", "Aug","Sep","Oct","Nov", "Dec"];
                
            } catch (error) {
                
            }
        })
    }
    
    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            loadshow:'show-n',
            overly:'show-n',
        });
    }
    
    setStartDate = (date) =>{
        this.setState({startDate:date})
    }
    setEndDate = (date) =>{
        this.setState({endDate:date})
    }
    onClickGetData(){
        this.setState({
            showloader:"show-m",
            startDate:$("#startDate").val(),
            endDate:$("#endDate").val()
        })
        var params = {
            phase:this.state.phase.value,
        }
        console.log(params);
        this.loadData(params);
    }
    onChangePhase(phase){
        this.setState({phase})
    }

    render() {
       
        if(localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined )
        {
          return <Navigate to={"/login"} push={true} />
        }
        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              fontWeight: 'bold',
              color: "black" // Set font-weight to bold for selected options
            }),
            singleValue: (provided, state) => ({
                ...provided,
                fontWeight: 'bold', // Always display the selected option in bold
              }),
          };
        var {total_budget, total_predicted_cost, phase} = this.state
        console.log(phase, "phase5")
        return (
            <div>
               <div className={'lodr '+(this.state.showloader)}>
                        <div className="loaderdiv">
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                            <div className="loaderwave"></div>
                        </div>
                        <div className="ldrtext">Loading....</div>
                    </div>
                <div className="row mb-20p pt-20p">
                    <div className="col-sm-12 d-flex">
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Forecast Dashboard</span>
                        </h5>
                    </div>
                </div>   
                <div className="row align-items-center mb-20p pt-20p">
                    
                    {/*<div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">Start Date</label>
                            <input type='text' className="datepicker form-inputbox forminp" id="startDate" name="startDate" value={this.state.startDate} />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">To Date</label>
                            <input type='text' className="datepicker form-inputbox forminp" id="endDate" name="endDate" value={this.state.endDate} />
                        </div>
                    </div>*/}
                    <div className="col-sm-2">
                        <label className="fbold f15">Phase </label>
                        <Select
                            onChange={this.onChangePhase.bind(this)}
                            options={this.state.phases}
                            value={this.state.phase}
                            styles={customStyles}
                        />
                    </div>

                    <div className="col-sm-2 d-flex">
                        <button type="button" className="btn btn-warning getBtn" onClick={this.onClickGetData.bind(this)}>Get Data</button>
                    </div>
                </div>   
                
                <div className="row mb-20p pt-20p">
                    <div className="col cirlce-d cursorPointer beffect">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <span className="f13 countertxt"> Total Budget
                                    </span>
                                    <h4 className="txt-success-dark f30">
                                        <span className="counter">
									        $ {this.state.total_budget}
									    </span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col cirlce-d cursorPointer beffect">
                    <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <span className="f13 countertxt"> Predicted Budget
                                    </span>
                                    <h4 className="txt-danger-dark f30">
                                        <span className="counter">
									        $ {total_predicted_cost}
									    </span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col cirlce-d cursorPointer beffect">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <span className="f13 countertxt"> Unplanned Existing Material Budget
                                    </span>
                                    <h4 className="txt-warning-dark f30"><span className="counter">
									$ {this.state.existing_outlier_cost}
									</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col cirlce-d cursorPointer beffect">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <span className="f13 countertxt"> Unplanned New Material Budget
                                    </span>
                                    <h4 className="txt-info-dark f30"><span className="counter">
									$ {this.state.new_material_cost}
                                    </span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
					
                    
                </div> 
                <div className="row mb-20p pt-20p beffect">
                    <div className="col-sm-12">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                   <ForecastDashboardLineChartComponent
                                        title={"Material Consumption Trend And Forecast"}
                                        subtitle={""}
                                        apiOrderData={this.state.line_complete_data}
                                        predApiOrderData={this.state.line_predicting_data}
                                        drillDownData = {this.state.drillDownData}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                        

                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                
                <div className={"loader "+this.state.loadshow}></div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>

            </div>
        )
    }
}

function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 

function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
$( function() {
    $( ".datepicker" ).datepicker({
        dateFormat:"yy-mm-dd",
        changeYear:true,
        startDate:$("#startDate").val(),
        endDate:$("#endDate").val()
    });
});

function abbreviateNumber(number) {
  /*if (number >= 1e12) {
    return (number / 1e12).toFixed(2) + ' T';
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(2) + ' B';
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(2) + ' M';
  } else if (number >= 1e5) {
    return (number / 1e5).toFixed(2) + ' L';
  } else if (number >= 1e4) {
    return (number / 1e3).toFixed(2) + ' K';
  } else {
    return number.toString();
  }*/
  if (number >= 1e12) {
    return (number / 1e12).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' T';
  } else if (number >= 1e9) {
    return (number / 1e9).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' B';
  } else if (number >= 1e6) {
    return (number / 1e6).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' M';
  } /*else if (number >= 1e5) {
    return (number / 1e5).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' L';
  }*/ else if (number >= 1e5) {
    return (number / 1e35).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' K';
  } else {
    return number.toLocaleString('en-US');
  }
  
}

export default withRouter(ForecastDashboard);