
import React, { Component } from 'react';

 
const MappingButton = (props) => {
    
   const handleOnClick = () => {
        props.context.componentParent.onClickMappItems(props,true);
    };
    return (
        <div>
          <button type="button" onClick={handleOnClick} className="btn btn-secondary grid-btn" style={{border:"0px"}}>
                <i className={"fa fa-link"}></i> Mapping
            </button>
        </div>
    );
};

export default MappingButton;
