import React, {Component} from 'react';
import CompareActualAndPredictedData from "./compareActualPredictData";
import redirectURL from '../redirectURL';
import ColumnChart from "./predictionAnalysisColumnChart";
import CSVFileValidator from 'csv-file-validator';
import ConfidenceChart from "./predictionAnalysisConfidenceChart";

export default class PredictionAnalysis extends Component {
    constructor(props){
        super(props);
        this.state = {
            materiallist:[],
            durationlist:[],
            actualChartData:[],
            predictedChartData:[],
            all_months:[],
            chartdata:[],
            showCharts:false,
            overlayBlock:"show-n",
            showSlideBlock:"",
            csvfiledata:[],
            item_code:'',
            confAreaData: [],
            foreCast_period: [],
            confAreaYHat: [],
            confScatter: [],
            actualValues:[],
        }


    }

    async componentDidMount() {
        
        var durationlist = [
            {
                value:3,
                label:"3 Months"
            },{
                value:6,
                label:"6 Months"
            },{
                value:12,
                label:"1 Year"
            }
        ]
        this.setState({
            durationlist:durationlist
        })
        await this.predictedMaterialList()
    }

    predictedMaterialList = async() =>{
        var params = {}
        let mList=[]
        redirectURL.post('/forecast/predictedMaterialList',params)
        .then(async response =>{
            var records = response.data;
            await records.map((rec)=>{
                mList.push({"label":rec,"value":rec});
            });
            await this.setState({
                materiallist:mList,
                materialSelected:mList[0]
            })
        })
        
    }

    compareData = (actualRecords,predictedRecords,mat_code,fcPeriod) =>{

        this.getMaterialChartDataLatest(mat_code,fcPeriod)
        
        let all_months =[];
        let datamonths =[];
        let chartdata =[];
        let data=[]
        let actualValues = [];

        var months = ["Jan","Feb","Mar","Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

        // let rw={}

        actualRecords.map((i) => {
            let key = months[parseInt(i.month)];
            datamonths.push(key)
            let value = i.quantity;
            actualValues.push(value)

            // if (key in rw) {
            //     rw[key] += value;
            //   } else {
                // rw[key] = value;
            //   }
            data.push({"name":key,"y":value,'lbl':value,color:'#006789'})
            
        })
        all_months.push(datamonths)
        chartdata.push({name:"Actual Data",colorByPoint: true,data:data})

        data=[]
        predictedRecords.map((i) => {
            let key = months[parseInt(i.month)];
            // all_months.push(key)
            let value = i.item_quantity;
            // if (key in rw) {
            //     rw[key] += value;
            //   } else {
                // rw[key] = value;
            //   }
            data.push({"name":key,"y":value,'lbl':value,color:'#deaa00'})
            
            
        })
        chartdata.push({name:"Predicted Data",colorByPoint: true,data:data})

        this.setState({
            actualChartData : actualRecords,
            predictedChartData : predictedRecords,
            chartdata,
            all_months,
            showCharts:true,
            item_code:mat_code,
            actualValues
        })
        
    }


    openUploadOptionForAvailability = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlock:"slide25",
            overlayBlock:"show-m"
          })
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
        })
      }

      formCSVUpload = (event) =>
      {
       
        event.preventDefault();
        var csvfiledata = this.state.csvfiledata;
        var p = {
          filedata: csvfiledata,
          
        }
        redirectURL.post("/forecast/uploadPredictMaterialData",p)
        .then(async(resp) => {
          
          if(resp.data.status == 'success'){
            await this.setState({
                // show:true,
                // basicTitle:resp.data.message,
                // basicType:"success",
                overlayBlock:"show-n",
                showSlideBlock:"",
            })
            // window.location.reload();
            alert(resp.data.message)
            
          }else{
            // this.setState({
            //     show:true,
            //     basicTitle:resp.data.message,
            //     basicType:"danger"
            // })
            alert(resp.data.message)
          }
        })
      }


      changeCSVFileHandler = async (e) => {
    
        const config = {
            headers: [
                
                { 
                    name: 'Item Code',
                    inputName: 'item_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                 },
       
                { 
                    name: 'Quantity',
                    inputName: 'quantity',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                
                { 
                    name: 'Actual Date',
                    inputName: 'posting_date',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                
            ]
        }

        
        
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            document.getElementById("inValidDataInfo").innerHTML = "";
            if(csvData.inValidData.length >0){
                document.getElementById("bulkUploadBtn").type = "button"
                document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                let invalidData = csvData.inValidData;
                let element = document.getElementById("inValidDataInfo")
                invalidData.map(item =>{
                    let row,column
                    if(item.rowIndex == undefined){
                        row = 'NA'
                    }else{
                        row = item.rowIndex
                    }
                    if(item.columnIndex == undefined){
                        column = 'NA'
                    }else{
                        column = item.columnIndex
                    }
                    
                    element.innerHTML += "Column : "+column+","+" Row : " + row +"--"+ item.message +"<br></br>"
                })
                 
            }else{
                document.getElementById("bulkUploadBtn").type = "submit"
                document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                document.getElementById("bulkUploadBtn").classList.add("btn-danger");
                this.setState({
                    csvfiledata:csvData.data
                });
            }
        })
        .catch(err => {})
      }


      getMaterialChartDataLatest = async(mat_code,fcPeriod) =>{
        
        let item_code = mat_code
        var months = ["Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
                await redirectURL.post("/forecast/getmaterialforecastoutputchartdata",{
                    item_code : item_code,fc_period : fcPeriod
                }).then(async (response) =>{
                    var chartDataaa = response.data.latestRecords;
                    
                    // chartDataaa = JSON.parse(mchartDataaa);
                    // console.log("chartDataaa ", chartDataaa)
                    var chartDatalist = [];
                    
                    var areaplot=[];
                    var areaYHat=[];
                    let foreCast_period='';
                    
                    var areaScatter=[];
                   
                    if(chartDataaa.length > 0)
                    {
                        chartDataaa.map((itm) =>{
                            itm["chart_data"] = JSON.parse(itm.chart_data);
                            itm["month_data"] = JSON.parse(itm.month_data);
                            
                            chartDatalist.push(itm)
                            
                        })
                    }

                    if(chartDatalist.length > 0)
                        {
                            var chartData = [chartDatalist[0]];
                            
                            if(chartData.length > 0)
                            {
                                chartData.map((mm) => {
                                    var chartSeries = mm.chart_data;
                                    foreCast_period = mm.fc_period
                                    if(chartSeries.length > 0)
                                    {
                                        chartSeries.map((mt) => {
                                            areaplot.push(mt.area_plot);
                                            areaYHat.push((mt.yhat < 0)?0:mt.yhat);
                                            
                                            if(mt.actual !== "" && mt.actual !== undefined && mt.actual !== null)
                                            {
                                                    areaScatter.push(mt.actual);
                                            }
                                        })
                                        
                                    }
                                })
                            }
                        }

                this.setState({
                    confAreaData:areaplot,
                    foreCast_period:foreCast_period,
                    confAreaYHat:areaYHat,
                    confScatter:areaScatter,
                })

                })

                
      }


    render(){

        var templatePath = require('../../assets/json/material_data_template.csv');

        return(

            <div class="container-fluid">
                 <div className="btn-index">
                <span className="float-right">
                    <a href="javascript:;"  onClick={this.openUploadOptionForAvailability} className="btn btn-warning f12 mr-10p">Upload Actual Data</a>
                </span>
                </div>
                <div className={"col-sm-12 mb-20p"}>
                    <CompareActualAndPredictedData 
                        options={this.state.materiallist} 
                        durationlist={this.state.durationlist}
                        compareData = {this.compareData}
                        
                    ></CompareActualAndPredictedData>
                </div>
                
                {this.state.showCharts ? 
                <>
                <div className={"mxheit col-sm-12 mt-20p"}>                                        
                                    <ConfidenceChart
                                        // title={"Inventory Forecast"}
                                        xAxisTitle={"Data Samples"}
                                        yAxisTitle={"Inventory Consumption"}
                                        // confLine={this.state.confidenceTrain}
                                        // confLineTrend = {this.state.confidenceTrend}
                                        // confLinePredictTest = {this.state.confidenceTestPoints}
                                        // confLinePredictTrend = {this.state.trend_predicted_points}
                                        confAreaData={this.state.confAreaData}
                                        confAreaYHat={this.state.confAreaYHat}
                                        confScatter={this.state.confScatter}
                                        foreCast_period={this.state.foreCast_period}
                                        actualValues={this.state.actualValues}
                                        // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                        // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                    />
                                </div>
                                
                {/* <div className={"mxheit col-sm-12 mt-20p"} >
                        <div className="card">
                            <div className="card-body">
                                <ColumnChart
                                    title={"Material Prediction Analysis"}
                                    subtitle={"Line chart"}
                                    series={this.state.chartdata}
                                    categories={this.state.all_months}
                                    chartType={'line'}
                                />

                            </div>
                        </div>
                    </div> */}

                    <div className={"mxheit col-sm-12 mt-20p"} >
                        <div className="card">
                            <div className="card-body">
                                <ColumnChart
                                    // title={"Material Prediction Analysis"}
                                    subtitle={"Bar chart"}
                                    series={this.state.chartdata}
                                    categories={this.state.all_months}
                                    chartType={'column'}
                                />

                            </div>
                        </div>
                    </div>
                    </>
                    :''}

                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                    <div className={"sliderBlock2 "+(this.state.showSlideBlock)}>
                    <div className="row">   
                        <div className="col-sm-12">
                        <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                        <div className="row p-20p">
                            <div className="form-group col-sm-12">
                                <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>                            
                            </div>
                            <div className="form-group col-sm-12 mb-20p">                                
                                <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                            </div>
                            <div className="form-group col-sm-12 mb-20p">
                            <button type="submit" id="bulkUploadBtn" className="btn btn-danger">SAVE</button>
                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                            </div>
                            <div id="inValidDataInfo" style={{color:"red"}}></div>
                        </div>
                        </form>
                        </div>
                    </div>
                    </div>
            </div>
            
        )
    }

            
}