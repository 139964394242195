import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Select from "react-select";

const MaterialCharts = ({ dataArray }) => {
  var newArray = [
    {
      "Phase": "2020-Jan-Jun",
      "Vital Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 353
        },
        {
          "Material Cost K'USD (Enmovil)": 40
        },
        {
          "count": 54
        }
      ],
      "Essential Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 119
        },
        {
          "Material Cost K'USD (Enmovil)": 55
        },
        {
          "count": 61
        }
      ],
      "Desirable Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 36
        },
        {
          "Material Cost K'USD (Enmovil)": 7
        },
        {
          "count": 51
        }
      ]
    },
    {
      "Phase": "2020-Jul-Dec",
      "Vital Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 686
        },
        {
          "Material Cost K'USD (Enmovil)": 52
        },
        {
          "count": 168
        }
      ],
      "Essential Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 207
        },
        {
          "Material Cost K'USD (Enmovil)": 57
        },
        {
          "count": 113
        }
      ],
      "Desirable Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 263
        },
        {
          "Material Cost K'USD (Enmovil)": 11
        },
        {
          "count": 130
        }
      ]
    },
    {
      "Phase": "2021-Jan-Jun",
      "Vital Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 126
        },
        {
          "Material Cost K'USD (Enmovil)": 21
        },
        {
          "count": 57
        }
      ],
      "Essential Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 310
        },
        {
          "Material Cost K'USD (Enmovil)": 33
        },
        {
          "count": 52
        }
      ],
      "Desirable Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 41
        },
        {
          "Material Cost K'USD (Enmovil)": 29
        },
        {
          "count": 38
        }
      ]
    },
    {
      "Phase": "2021-Jul-Dec",
      "Vital Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 463
        },
        {
          "Material Cost K'USD (Enmovil)": 29
        },
        {
          "count": 102
        }
      ],
      "Essential Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 267
        },
        {
          "Material Cost K'USD (Enmovil)": 44
        },
        {
          "count": 114
        }
      ],
      "Desirable Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 198
        },
        {
          "Material Cost K'USD (Enmovil)": 20
        },
        {
          "count": 118
        }
      ]
    },
    {
      "Phase": "2022-Jan-Jun",
      "Vital Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 105
        },
        {
          "Material Cost K'USD (Enmovil)": 18
        },
        {
          "count": 61
        }
      ],
      "Essential Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 158
        },
        {
          "Material Cost K'USD (Enmovil)": 53
        },
        {
          "count": 35
        }
      ],
      "Desirable Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 92
        },
        {
          "Material Cost K'USD (Enmovil)": 80
        },
        {
          "count": 52
        }
      ]
    },
    {
      "Phase": "2022-Jul-Dec",
      "Vital Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 217
        },
        {
          "Material Cost K'USD (Enmovil)": 13
        },
        {
          "count": 66
        }
      ],
      "Essential Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 245
        },
        {
          "Material Cost K'USD (Enmovil)": 13,
        },
        {
          "count": 72
        }
      ],
      "Desirable Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 282
        },
        {
          "Material Cost K'USD (Enmovil)": 108
        },
        {
          "count": 82
        }
      ]
    },
    {
      "Phase": "2023-Jan-Jun",
      "Vital Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 212
        },
        {
          "Material Cost K'USD (Enmovil)": 30
        },
        {
          "count": 76
        }
      ],
      "Essential Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 101
        },
        {
          "Material Cost K'USD (Enmovil)": 21
        },
        {
          "count": 63
        }
      ],
      "Desirable Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 255
        },
        {
          "Material Cost K'USD (Enmovil)": 65
        },
        {
          "count": 64
        }
      ]
    },
    {
      "Phase": "2023-Jul-Oct",
      "Vital Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 378
        },
        {
          "Material Cost K'USD (Enmovil)": 31
        },
        {
          "count": 121
        }
      ],
      "Essential Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 289
        },
        {
          "Material Cost K'USD (Enmovil)": 28
        },
        {
          "count": 103
        }
      ],
      "Desirable Materials Cost": [
        {
          "Material Cost K'USD (Vedanta)": 272
        },
        {
          "Material Cost K'USD (Enmovil)": 78
        },
        {
          "count": 135
        }
      ]
    }
  ];
  var phase_options = [
    { "label": "2020-Jan-Jun", "value": "2020-Jan-Jun" },
    { "label": "2020-Jul-Dec", "value": "2020-Jul-Dec" },
    { "label": "2021-Jan-Jun", "value": "2021-Jan-Jun" },
    { "label": "2021-Jul-Dec", "value": "2021-Jul-Dec" },
    { "label": "2022-Jan-Jun", "value": "2022-Jan-Jun" },
    { "label": "2022-Jul-Dec", "value": "2022-Jul-Dec" },
    { "label": "2023-Jan-Jun", "value": "2023-Jan-Jun" },
    { "label": "2023-Jul-Oct", "value": "2023-Jul-Oct" }
  ]
  const [selectedPhase, setSelectedPhase] = useState({ "label": "2021-Jul-Dec", "value": "2021-Jul-Dec" });
  useEffect(() => {
    // Set selectedPhase to the last phase value when dataArray changes
    if (dataArray.length > 0) {
      setSelectedPhase({
        label: dataArray[dataArray.length - 1].Phase,
        value: dataArray[dataArray.length - 1].Phase
      });
    }
  }, [dataArray]);


  const handlePhaseChange = (selectedOption) => {
    setSelectedPhase(selectedOption);
  };

  const generateEnmovilCostChartData = () => {
    const selectedPhaseData = dataArray.find((item) => item.Phase === selectedPhase.value);

    if (!selectedPhaseData) {
      return [];
    }

    const materialCostData = [
      {
        name: 'New Materials',
        y: selectedPhaseData['New Materials'][2]['Material Cost K\'USD (Enmovil)'],
        count: selectedPhaseData['New Materials'][0]['Material Count'],
      },
      {
        name: 'No Consumption in Last 3 Years NC3Y',
        y: selectedPhaseData['No Consumption in Last 3 Years NC3Y'][2]['Material Cost K\'USD (Enmovil)'],
        count: selectedPhaseData['No Consumption in Last 3 Years NC3Y'][0]['Material Count'],
      },
      {
        name: 'Consumption Started in Last 6 Months',
        y: selectedPhaseData['Consumption Started in Last 6 Months'][2]['Material Cost K\'USD (Enmovil)'],
        count: selectedPhaseData['Consumption Started in Last 6 Months'][0]['Material Count'],
      },
      {
        name: 'AI-Sensitive Materials',
        y: selectedPhaseData['AI-Sensitive Materials'][2]['Material Cost K\'USD (Enmovil)'],
        count: selectedPhaseData['AI-Sensitive Materials'][0]['Material Count'],
      },
    ];

    return materialCostData;
  };

  const generateVedantaCostChartData = () => {
    const selectedPhaseData = dataArray.find((item) => item.Phase === selectedPhase.value);

    if (!selectedPhaseData) {
      return [];
    }

    const materialCostData = [
      {
        name: 'New Materials',
        y: selectedPhaseData['New Materials'][1]['Material Cost K\'USD (Vedanta)'],
        count: selectedPhaseData['New Materials'][0]['Material Count'],
      },
      {
        name: 'No Consumption in Last 3 Years NC3Y',
        y: selectedPhaseData['No Consumption in Last 3 Years NC3Y'][1]['Material Cost K\'USD (Vedanta)'],
        count: selectedPhaseData['No Consumption in Last 3 Years NC3Y'][0]['Material Count'],
      },
      {
        name: 'Consumption Started in Last 6 Months',
        y: selectedPhaseData['Consumption Started in Last 6 Months'][1]['Material Cost K\'USD (Vedanta)'],
        count: selectedPhaseData['Consumption Started in Last 6 Months'][0]['Material Count'],
      },
      {
        name: 'AI-Sensitive Materials',
        y: selectedPhaseData['AI-Sensitive Materials'][1]['Material Cost K\'USD (Vedanta)'],
        count: selectedPhaseData['AI-Sensitive Materials'][0]['Material Count'],
      },
    ];

    return materialCostData;
  };

  const generateVedantaChartData = () => {
    const selectedPhaseData = newArray.find((item) => item.Phase === selectedPhase.value);

    if (!selectedPhaseData) {
      return [];
    }

    const materialCostData = [
      {
        name: 'Vital Materials Cost',
        y: selectedPhaseData['Vital Materials Cost'][0]['Material Cost K\'USD (Vedanta)'],
        count: selectedPhaseData['Vital Materials Cost'][2]['count'],
      },
      {
        name: 'Essential Materials Cost',
        y: selectedPhaseData['Essential Materials Cost'][0]['Material Cost K\'USD (Vedanta)'],
        count: selectedPhaseData['Essential Materials Cost'][2]['count'],
      },
      {
        name: 'Desirable Materials Cost',
        y: selectedPhaseData['Desirable Materials Cost'][0]['Material Cost K\'USD (Vedanta)'],
        count: selectedPhaseData['Desirable Materials Cost'][2]['count'],
      },
      // {
      //   name: 'AI-Sensitive Materials',
      //   y: selectedPhaseData['AI-Sensitive Materials'][1]['Material Cost K\'USD (Vedanta)'],
      //   count: selectedPhaseData['AI-Sensitive Materials'][0]['Material Count'],
      // },
    ];

    return materialCostData;
  };

  const generateEnmovilChartData = () => {
    const selectedPhaseData = newArray.find((item) => item.Phase === selectedPhase.value);

    if (!selectedPhaseData) {
      return [];
    }

    const materialCostData = [
      {
        name: 'Vital Materials Cost',
        y: selectedPhaseData['Vital Materials Cost'][1]['Material Cost K\'USD (Enmovil)'],
        count: selectedPhaseData['Vital Materials Cost'][2]['count'],
      },
      {
        name: 'Essential Materials Cost',
        y: selectedPhaseData['Essential Materials Cost'][1]['Material Cost K\'USD (Enmovil)'],
        count: selectedPhaseData['Essential Materials Cost'][2]['count'],
      },
      {
        name: 'Desirable Materials Cost',
        y: selectedPhaseData['Desirable Materials Cost'][1]['Material Cost K\'USD (Enmovil)'],
        count: selectedPhaseData['Desirable Materials Cost'][2]['count'],
      },
      // {
      //   name: 'AI-Sensitive Materials',
      //   y: selectedPhaseData['AI-Sensitive Materials'][1]['Material Cost K\'USD (Vedanta)'],
      //   count: selectedPhaseData['AI-Sensitive Materials'][0]['Material Count'],
      // },
    ];

    return materialCostData;
  };

  const chartConfigCount = {
    chart: {
      type: 'pie',
    },
    title: {
      text: `<b>${selectedPhase.value} Consumption Cost (K'USD)</b>`,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        tooltip: {
          pointFormat: '<b>Material Cost</b>: {point.y} K\'USD </br><b>Material Count</b>: {point.count}',
        },
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        name: 'Material Cost (Vedanta)',
        data: generateVedantaCostChartData(),
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: '{point.y} K\'USD'
        }
      },
    ],
    credits: false
  };

  const chartConfigCost = {
    chart: {
      type: 'pie',
    },
    title: {
      text: `<b>${selectedPhase.value} Prediction Cost (K'USD)</b>`,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        tooltip: {
          pointFormat: '<b>Material Cost</b>: {point.y} K\'USD </br><b>Material Count</b>: {point.count}',
        },
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        name: 'Material Cost (Enmovil)',
        data: generateEnmovilCostChartData(),
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: '{point.y} K\'USD'
        }
      },
    ],
    credits: false
  };
  const chartVedantaCost = {
    chart: {
      type: 'pie',
    },
    title: {
      text: `<b>${selectedPhase.value} Consumption Cost (K'USD)</b>`,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        tooltip: {
          pointFormat: '<b>Material Cost</b>: {point.y} K\'USD </br><b>Material Count</b>: {point.count}',
        },
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        name: 'Material Cost (Enmovil)',
        data: generateVedantaChartData(),
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: '{point.y} K\'USD'
        }
      },
    ],
    credits: false
  };
  const chartEnmovilCost = {
    chart: {
      type: 'pie',
    },
    title: {
      text: `<b>${selectedPhase.value} Prediction Cost (K'USD)</b>`,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        tooltip: {
          pointFormat: '<b>Material Cost</b>: {point.y} K\'USD </br><b>Material Count</b>: {point.count}',
        },
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        name: 'Material Cost (Enmovil)',
        data: generateEnmovilChartData(),
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: '{point.y} K\'USD'
        }
      },
    ],
    credits: false
  };
  return (
    <>
      <div style={{ width: "150px", marginBottom: "20px" }}>
        <div className="row pt-20p">
          <div className="col-sm-12 d-flex">
            <h5 className='fbold f20 d-flex justify-content-between' style={{ color: "black" }}>
              <span>Phase</span>
            </h5>
          </div>
        </div>
        <Select
          options={phase_options}
          value={selectedPhase}
          onChange={handlePhaseChange}
        />
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "48%", marginLeft: "10px", marginRight: "10px" }}>
          <HighchartsReact highcharts={Highcharts} options={chartConfigCount} />
        </div>

        <div style={{ width: "48%", marginLeft: "10px", marginRight: "10px" }}>
          <HighchartsReact highcharts={Highcharts} options={chartConfigCost} />
        </div>
      </div>
      <div className="row pt-20p">
          <div className="col-sm-12 d-flex">
            <h5 className='fbold f23 ml-5p mt-5p d-flex justify-content-between' style={{ color: "black" }}>
              <span>VED Material Analysis</span>
            </h5>
          </div>
        </div>
      <div style={{ width: "100%", display: "flex", marginTop: "20px" }}>
        <div style={{ width: "48%", marginLeft: "10px", marginRight: "10px" }}>
          <HighchartsReact highcharts={Highcharts} options={chartVedantaCost} />
        </div>

        <div style={{ width: "48%", marginLeft: "10px", marginRight: "10px" }}>
          <HighchartsReact highcharts={Highcharts} options={chartEnmovilCost} />
        </div>
      </div>
    </>
  );
};

export default MaterialCharts;