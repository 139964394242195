import React from 'react';

class DeletePopup extends React.Component {
  render() {
    const { isOpen, onCancel, onDelete, rowData } = this.props;

    if (!isOpen) return null;

    return (
      <div className="delete-popup">
        <div className="delete-content">
          <span className="close-button" onClick={onCancel}>
            &times;
          </span>
          <p className="fbold" style={{fontSize: "20px"}}>Are you sure you want to delete the material?</p>
          <div className="popup-buttons">
            <button className="btn btn-success" onClick={() => onDelete(rowData)}>Delete</button>
            <button className="btn btn-danger" onClick={onCancel}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }
}

export default DeletePopup;

