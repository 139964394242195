import React, { Component } from 'react';


import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

// drilldown(Highcharts);
export default class ColumnChartComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		 
			chart: {
				type: 'column'
			},
			title: {
				align: 'left',
				text: this.props.title
			},
			subtitle: {
				align: 'left',
				text: this.props.subtitle
			},
			accessibility: {
				announceNewData: {
					enabled: true
				}
			},
			xAxis: {
				type: 'category'
			},
			yAxis: {
				title: {
					text: this.props.yaxisTitle
				}
		
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '{point.y}'
					}
				}
			},
		
			tooltip: {
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
			},
		
			series: this.props.series,
            lang: {
                noData: this.props.noDataMessage,
              },
              noData: {
                style: {
                  fontWeight: 'bold',
                  fontSize: '15px',
                  color: '#303030',
                },
              },

            // series:[
            //     {
            //         "name": "Planning",
            //         "colorByPoint": true,
            //         "data": [
            //             {
            //                 "name": "Total Demand CMT",
            //                 "y": 101
            //             },
            //             {
            //                 "name": "Planned Demand CMT",
            //                 "y": 10
            //             },
            //             {
            //                 "name": "Dropped Demand CMT",
            //                 "y": 20
            //             },
            //             {
            //                 "name": "Unplanned Demand CMT",
            //                 "y": 30
            //             }
            //         ]
            //     }
            // ],
			// drilldown: {},
			credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}
