import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import redirectURL from "../redirectURL";
import './styles.css';

export default class Login extends Component {
	constructor(props)
	{
		super(props);
		this.state={
			isNav:0,
			email:"",
			password:"",
			loginMessage:"",
		}
		this.changeHandler = this.changeHandler.bind(this);
	}
	// componentDidMount(){
	// 	redirectURL.post("/login/welcome")
	// 	.then((response) => {
	// 		console.log("response ", response.data)
	// 	})
	// }

	// onClickGoToDashboard(){
	// 	redirectURL.post("/login/welcome")
	// 	.then((response) => {
	// 		console.log("response ", response.data)
	// 		localStorage.setItem("token","1xEdr125dg6577dfgjseXbnddgxuy2kg")
		
	// 		this.setState({
	// 			isNav:1
	// 		});
	// 	})
		
	// }
	changeHandler(event){
		var name = event.target.name;
		var value = event.target.value;
		this.setState({
			[name]:value
		})
	}
	formSubmit(event){
		event.preventDefault();
		if(this.state.email !=undefined && this.state.email !="" && this.state.password !=undefined && this.state.password !="")
		{
			var params = {
				e:this.state.email,
				d:this.state.password
			}
			redirectURL.post("/verify",params)
			.then((response) => {
				if(response.data.status == "success")
				{
					console.log("login ", response.data)
					localStorage.setItem("email",response.data.user[0].email);
					localStorage.setItem("firstname",response.data.user[0].firstname);
					localStorage.setItem("lastname",response.data.user[0].lastname);
					localStorage.setItem("username",response.data.user[0].username);
					localStorage.setItem("token",response.data.token);
					localStorage.setItem("user_type",response.data.user[0].user_type);
					localStorage.setItem("role",response.data.user[0].role);
					localStorage.setItem("user_code",response.data.user[0].user_code);
					this.setState({
						isNav : 1
					})
				}
				else
				{
					this.setState({
						loginMessage : response.data.message
					})
				}
			})
		}
		else
		{
			this.setState({
				loginMessage : "Enter Email and Password"
			})
		}
	}
    render(){
		if(this.state.isNav === 1)
		{
			return <Navigate  to={"/dashboard"} push={true} />
		}
        return (
            // <div className="login_wrapper">
			// 	<div className="animate form login_form">
			// 		<section className="login_content">
			// 			<form className="user" onSubmit={this.formSubmit.bind(this)}>
			// 				<img src={require("../../assets/images/enmovil.png")} />
			// 				<div className="form-group" style={{marginTop: "2em"}}>
			// 					<input type="email" className="form-control form-control-user"
			// 						id="exampleInputEmail" name="email" onChange={this.changeHandler} aria-describedby="emailHelp"
			// 						placeholder="Enter Email Address..." />
			// 				</div>
			// 				<div className="form-group">
			// 					<input type="password" className="form-control form-control-user"
			// 						id="exampleInputPassword" name="password" onChange={this.changeHandler} placeholder="Password" />
			// 				</div>
			// 				{/* <div className="form-group">
			// 					<div className="custom-control custom-checkbox small">
			// 						<input type="checkbox" className="custom-control-input" id="customCheck" />
			// 						<label className="custom-control-label" for="customCheck">Remember
			// 							Me</label>
			// 					</div>
			// 				</div> */}
			// 				<button type="submit" className="btn btn-primary btn-user btn-block">
			// 					Login
			// 				</button>
			// 			</form>										
			// 		</section>
			// 	</div>
			// </div>
			<div className='container-fluid container-bg'>
				<div className='row'>
					<div className='col-sm-5'>
						<div className='app-logo'>
							<img src={require("../../assets/images/enmovil.png")} />
						</div>
						<div className='form-body'>
							<form className="loginForm" onSubmit={this.formSubmit.bind(this)}>
								<h4>Login</h4>
								{(this.state.loginMessage)?
								<div className="alert alert-danger">{this.state.loginMessage}</div>
								:""}
								<div className="form-group" style={{marginTop: "2em"}}>
									<input type="email" className="form-control form-control-user" id="exampleInputEmail" name="email" onChange={this.changeHandler} aria-describedby="emailHelp" placeholder="Enter Email Address..." autocomplete="off" required/>
								</div>
								<div className="form-group">
									<input type="password" className="form-control form-control-password" id="exampleInputPassword" name="password" onChange={this.changeHandler} placeholder="Password" autocomplete="off" required/>
								</div>
								<div className="form-group txt-center mt-20p">
									<button type="submit" className="btn btn-user">
										Login
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
        )
    }
}