
import React, { Component } from 'react';

 
const NAButton = (props) => {
    return (
        <div style={{"textAlign":"center"}}>           
            N/A            
        </div>
    );
};

export default NAButton;
