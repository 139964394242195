import React,{Component} from "react";
import Constant from "../common/constants";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import getToolConfiguration from "./commonFunctions";
import TransporterMaster from './transporterMaster';
import TransporterOperations from './transporterOperations';




export default class TransporterConfiguration extends Component{
    constructor(props){
        super(props);
        this.state={
            locationNames:[],
            location:'',
            transporterMasterTab:"activet",
            transporterOperationsTab : "",
            transporterMasterData: true,
            transporterOperationsData:false,
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        let url_type = ul[parseInt(ul.length)-1];
        let data_type
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})

        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        await this.boundTypeLocations(params)
    }

    boundTypeLocations = async(param) => {

        let locationNames = this.state.locationNames;
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                          locationNames.push({ value : item.location_code,
                              label : item.location_name })
                      })
                      await this.setState({
                          locationNames : locationNames,
                          location : locationNames[0]
                      })
                  }
              }
          })
          
      }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }


    onClickTab = (pageType) => {

        if(pageType == "transporterMasterData")
        {
            var transporterMasterTab = "activet";
            var transporterOperationsTab = "";
            var transporterMasterData = true;
            var transporterOperationsData = false;
            
        }
        else if(pageType == "transporterOperationsData")
        {
            var transporterMasterTab = "";
            var transporterOperationsTab = "activet";
            var transporterMasterData = false;
            var transporterOperationsData = true;
        }
        this.setState({
            transporterMasterTab:transporterMasterTab,
            transporterOperationsTab:transporterOperationsTab,
            transporterMasterData:transporterMasterData,
            transporterOperationsData:transporterOperationsData,
            
        })
    }


    render(){

        const {location,locationNames,transporterMasterTab,transporterOperationsTab,transporterMasterData,transporterOperationsData}=this.state
        return(
            <div className="">
            <div className="row mb-20p pt-20p">
                    <div className="col-sm-12">
                        
                            <h5 className='fbold  d-flex justify-content-between'>
                                <span>Transporter Configurations</span>
                            </h5>
                        
                    </div>
                </div> 
                    <div className="col-md-4 col-sm-12">
                        <div className="form-group ">
                            <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={location}
                                options={locationNames} />

                        </div>
                    </div>

                            <div className="col-md-12 col-sm-12">
                                <ul className="d-tabs">
                                    
                                    <li onClick={this.onClickTab.bind(this,"transporterMasterData")} className={"lttabs "+(transporterMasterTab)}>
                                        <button type="button" className="btn">Transporter Master </button>
                                    </li>
                                    
                                    <li onClick={this.onClickTab.bind(this,"transporterOperationsData")} className={"lttabs "+(transporterOperationsTab)}>
                                        <button type="button" className="btn">Transporter Operations</button>
                                    </li>

                                </ul>
                            </div>

                    {transporterMasterData ?  location != '' ? <TransporterMaster plantLocation={location}/> :'': ''}
                    {transporterOperationsData ? location != '' ? <TransporterOperations plantLocation={location}/> :'': ''}
            </div> 
        )
    }
}