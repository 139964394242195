import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import redirectURL from "../redirectURL";

const sideBar =  {
    toolPanels: [
        {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
        },
        {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
        }
    ]
};

const statusBar =  {
    statusPanels: [
        {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
        },
        {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" }
    ]
};

const UserActions = () => {
    const [loadshow, setLoadShow] = useState('show-n');
    const [rowData, setRowData] = useState([]);
    const defaultColDefs = {
        sortable: true,
        filter: true,
        headerClass: ["column-header-style"],
        width: 180,
        editable: false,
        resizable: true
    };

    useEffect(() => {
        const getData = async () => {
            setLoadShow('show-m'); // Show loader when fetching data
            await getUserActionsData();
            setLoadShow('show-n'); // Hide loader after data is fetched
        };
        getData();
    }, []);

    const getUserActionsData = async () => {
        try {
            // Make the POST request to fetch user activity data
            const response = await redirectURL.post("getUserActivity", {});
    
            if (response && response.data && response.data.status === "success") {
                setRowData(response.data.result);  // Update rowData state
            } else {
                console.error("Failed to fetch user activity data");
            }
        } catch (err) {
            console.error("Error occurred while fetching user activity data:", err.message);
        }
    };

    const [columnDefs] = useState([
        { headerName: "Username", field: "username", width: 180 },
        { headerName: "Email", field: "email_id", width: 180 },
        { headerName: "Role", field: "role", width: 180 },
        { headerName: "Screen", field: "screen", width: 180 },
        { headerName: "Sub Screen", field: "sub_screen", width: 180 },
        { headerName: "Action", field: "action", width: 180 },
        { headerName: "Activity", field: "activity", width: 350 }
    ]);

    return (
        <>
            <div id="myGrid" style={{ width: "100%", height: "70vh", fontSize: "13px", fontWeight: "500" }} className={"ag-theme-balham"}>
                <AgGridReact
                    rowData={rowData}
                    defaultColDef={defaultColDefs}
                    sideBar={sideBar}
                    statusBar={statusBar}
                    columnDefs={columnDefs}
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
            {loadshow === 'show-m' && (
                <div className="lodr">
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
            )}
        </>
    );
};

export default UserActions;
