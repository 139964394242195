import React,{Component} from 'react';
import CSVFileValidator from 'csv-file-validator';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import ViewMap from "./viewmap";
import Constant from "../common/constants";
import getToolConfiguration from "./commonFunctions";
import GridButton from './gridButton';
import $ from 'jquery';

var map;


export default class PickupDropLocations extends Component{
    constructor(props){
        super(props);
    this.state = {
        modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
        overlayBlock:"show-n",
        showSlideBlockPickupDrop:"",
        showloader:"show-n",
        csvfiledata:[],
        openPickupDropLocationsModel:false,
        address_name:'',
        addressLat:'',
        addressLng:'',
        location_name:'',
        location_type:'',
        location_code:'',
        show:false,
        basicTitle:"",
        basicType:"default",
        data_type:"",
        modalType : 1,
        location_address:"",
        latitude:"",
        longitude:"",
        pickup_from_time:"",
        pickup_to_time:"",
        drop_from_time:"",
        drop_to_time:"",
        rowId:"",
        pickup_window_time : ['00:00','23:59'], 
        drop_window_time : ['00:00','23:59'],
        mapp : false
    }

    // this.showMap = this.showMap.bind(this);

    }

    componentDidMount = async() =>{

        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        await this.getLocationsData(params)
        
    }
    

    getLocationsData = (params) =>{
        
        redirectURL.post('master/getLocationsData',params)
        .then(response =>{
            if(response.data.status == 'success'){
// column value change for pickup/drop to show on ui
                let locationsData = response.data.locationsData;
            this.setState({
                rowData:response.data.locationsData
            })
        }
        })
    }
  
    openModelForPickupDrop = async() =>{
        await this.setState(prevState =>({
            openPickupDropLocationsModel :!prevState.openPickupDropLocationsModel,
            modalType : 1,
            mapp : !prevState.mapp
        }))
        setTimeout(async()=> {
            await this.showMap();
		}, 1000 );
    }

    handelPickupDropModelCancel = ()=>{
        this.setState(prevState =>({
            openPickupDropLocationsModel :!prevState.openPickupDropLocationsModel,
            addressLng:'',
            addressLat:'',
            address_name:'',
            location_name:'',
            location_type:'',
            location_code:'',
            pickup_window_time:['00:00','23:59'],
            drop_window_time:['00:00','23:59'],
        }))

    }

    openUploadOptionForPickupDrop = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlockPickupDrop:"slide25",
            overlayBlock:"show-m"
          })
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlockPickupDrop:"",
        })
      }

      formCSVUpload = (event) =>
      {
        let data_type = this.state.data_type
        event.preventDefault();
        var csvfiledata = this.state.csvfiledata;
        var p = {
          filedata: csvfiledata,
          data_type:data_type
        }
        redirectURL.post("/master/uploadLocationsData",p)
        .then(async(resp) => {
          
          if(resp.data.status == 'success'){
            await this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"success",
                overlayBlock:"show-n",
                showSlideBlockPickupDrop:"",
            })
            window.location.reload();
            
          }else{
            this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"danger"
            })
          }
        })
      }


      changeCSVFileHandler = async (e) => {
        const config = {
            headers: [
                { 
                    name: 'Location Type',
                    inputName: 'location_type',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Location Code',
                    inputName: 'location_code',
                    required: true,
                    unique: true,
                    uniqueError: function (headerName) {
                        return `${headerName} is not unique`
                    },
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Location Name',
                    inputName: 'location_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Latitude',
                    inputName: 'latitude',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                 },
       
                { 
                    name: 'Longitude',
                    inputName: 'longitude',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                // { 
                //     name: 'Location Address',
                //     inputName: 'location_address',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                // { 
                //     name: 'From Time',
                //     inputName: 'from_time',
                //     required: false,
                // },
                // { 
                //     name: 'To Time',
                //     inputName: 'to_time',
                //     required: false,
                // }
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {

            document.getElementById("inValidDataInfo").innerHTML = "";
            if(csvData.inValidData.length >0){
                document.getElementById("bulkUploadBtn").type = "button"
                document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                let invalidData = csvData.inValidData;
                let element = document.getElementById("inValidDataInfo")
                invalidData.map(item =>{
                    let row,column
                    if(item.rowIndex == undefined){
                        row = 'NA'
                    }else{
                        row = item.rowIndex
                    }
                    if(item.columnIndex == undefined){
                        column = 'NA'
                    }else{
                        column = item.columnIndex
                    }
                    
                    element.innerHTML += "Column : "+column+","+" Row : " + row +"--"+ item.message +"<br></br>"
                })
                 
            }else{
                document.getElementById("bulkUploadBtn").type = "submit"
                document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                document.getElementById("bulkUploadBtn").classList.add("btn-danger");
                this.setState({
                    csvfiledata:csvData.data
                });
            }
        })
        .catch(err => {})
            
        // var out = new Promise(function(reject, resolve){
        //     var reader =  new FileReader();
        //     reader.onload = async function(e) {
        //         var contents = await e.target.result;
        //         resolve(contents);
        //     };
        //     var tt =  reader.readAsText(e.target.files[0]);
        // });
        
        // console.log("e.target.files[0].type ", e.target.files[0]);   
    
        // if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') 
        // { 
            // console.log(fileData); 
            // console.log(typeof(fileData)) 
        // } 
        // else{ 
        //     e.target.value = null; 
        //     this.setState({ 
        //         uploadFile:'', 
        //         show: true, 
        //         basicType:'danger', 
        //         basicTitle:'Please upload file having extensions .csv only.', 
        //     }); 
        // } 
        
    }


    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {

        //   loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}


    initMap = () => {

        const getAddressLatLng = (address,lat,lng)=>{
            
            this.setState({
                addressLng:lng,
                addressLat:lat,
                address_name:address
               })
            }

        const updateLatLngOnClickMap = (lat,lng)=>{

            try{
            var reqparams = new FormData()
            reqparams.append("location_latitude", lat)
            reqparams.append("location_longitude", lng)

            
            redirectURL.post("http://216.48.191.229:8005/dispatch_planning/fetchLocationAddressDetails",reqparams)
            .then((response) => {
                if(response.data.status == "Success"){
                    this.setState({
                        addressLng:lng,
                        addressLat:lat,
                        address_name:response.data.formatted_address
                       })
                }else{
                    this.setState({
                        addressLng:lng,
                        addressLat:lat,
                        address_name:''
                       })
                }

            })
            }catch(e){
                
            }

        }    
        try{
            var latitude = 28.6139391;
            var longitude = 77.2090212;
            if(this.state.addressLat !=undefined && this.state.addressLng !=undefined && this.state.addressLat !="" && this.state.addressLng !="")
            {
                var latitude = this.state.addressLat;
                var longitude = this.state.addressLng;
            }
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 12,
            center: new window.google.maps.LatLng(latitude,longitude),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.TOP_RIGHT
			},            
			styles: [
                    
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 65
						},
						{
							"visibility": "on"
						}
					]
				},
				
				{
					"featureType": "landscape.man_made",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#c1c2c2"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
			],
			disableDefaultUI: true,
			zoomControl: true
          });
            if(this.state.addressLat !=undefined && this.state.addressLng !=undefined && this.state.addressLat !="" && this.state.addressLng !="")
            {
                var latLng=new window.google.maps.LatLng(parseFloat(this.state.addressLat),parseFloat(this.state.addressLng));
                var marker = new window.google.maps.Marker({
                    position: latLng,
                    icon: require('../../assets/images/business.png'),
                    map: map
                });
                
                markers.push(marker);
            }
        }
        catch(e){
            console.log(e)
        }
  	    var input = document.getElementById('search');
          var searchBox = new window.google.maps.places.SearchBox(input);
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
          });

  		var markers = [];
  		 searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();
            
            if (places.length == 0) {
              return;
            }

            markers.forEach(function(marker) {
              marker.setMap(null);
            });
            markers = [];
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function(place) {
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }
              var icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25)
              };


			var image =  require('../../assets/images/business.png');
              markers.push(new window.google.maps.Marker({
                map: map,
                icon: image,
                title: place.name,
                position: place.geometry.location
              }));
            
                getAddressLatLng(place.formatted_address,place.geometry.location.lat(),place.geometry.location.lng())
                bounds.extend(place.geometry.location);
            });
            map.fitBounds(bounds);
          });
  		
  		window.google.maps.event.addListener(map, "click", (event) => {
            markers.forEach(function(marker) {
                marker.setMap(null);
              });
              var image
            markers.push(new window.google.maps.Marker({
                map: map,
                icon: image,
                // title: place.name,
                position: event.latLng
              }));
            
            updateLatLngOnClickMap(event.latLng.lat(),event.latLng.lng())
            
          });
    
 
    }
    
    
    addressInputField = (e) =>{
        
        this.setState({address_name:e.target.value})
    }
    handleSearchLocation = (e) => {

        this.setState({address_name:e.target.value})
    }
    handelLatInput = (e) =>{
        
        this.setState({addressLat:e.target.value})
    }
    handelLngInput = (e) =>{
        
        this.setState({addressLng:e.target.value})
    }
    
    locationTypeHandler = (event) => {
		let value = event.target.value;
    
    	this.setState({location_type:value});
    }
    locationCodeField = (event) => {
		let value = (event.target.value).toUpperCase();
    	this.setState({location_code:value});
    }
    locationNameField = (event) => {
		let value = event.target.value;
    	this.setState({location_name:value});
    }
    handlePickupWindowTime = (val) => {
    	this.setState({pickup_window_time:val});
    }
    handleDropWindowTime = (val) => {
    	this.setState({drop_window_time:val});
    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}

    saveLocationsData = (params) =>{
        let data_type = this.state.data_type
        redirectURL.post('/master/saveLocationsData',params)
        .then(response =>{

            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getLocationsData(param)
                this.setState(prevState =>({
                    openPickupDropLocationsModel :!prevState.openPickupDropLocationsModel,
                    addressLng:'',
                    addressLat:'',
                    address_name:'',
                    location_code:'',
                    location_name:'',
                    location_type:'',
                    pickup_window_time : ['00:00','23:59'], 
                    drop_window_time : ['00:00','23:59']
       
                }))
            }else{
                this.setState({
                    show:true,
                    basicTitle:response.data.message,
                    basicType:"danger"
                })
            }
        })
    }

    validateTime = (window_time) =>{
        let frm_hr = window_time[0].split(':')
            let to_hr = window_time[1].split(':')
            
            if(window_time[0] == window_time[1]){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }
            else if(frm_hr[0] > to_hr[0]){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }else if(frm_hr[0] == to_hr[0]){
                if(frm_hr[1] > to_hr[1]){
                    this.setState({
                        show:true,
                        basicTitle:"Please Input Time Appropriately",
                        basicType:"danger"
                    })
                }else{
                    return true
                }
            }else{
                return true
            }
    }

    locationFormHandler = (e) =>{
        e.preventDefault();
        
        let address_name = this.state.address_name
        let addressLat = parseFloat(this.state.addressLat)
        let addressLng = parseFloat(this.state.addressLng)
        let location_name = this.state.location_name
        let location_type = this.state.location_type
        let location_code = this.state.location_code
        let pickup_window_time = this.state.pickup_window_time
        let drop_window_time = this.state.drop_window_time
        let data_type = this.state.data_type

        let pickup_from_time,pickup_to_time,drop_from_time,drop_to_time

        if(address_name == ''){
            try{
                var reqparams = new FormData()
                reqparams.append("location_latitude", addressLat)
                reqparams.append("location_longitude", addressLng)
    
                
                redirectURL.post("http://216.48.191.229:8005/dispatch_planning/fetchLocationAddressDetails",reqparams)
                .then((response) => {
                    if(response.data.status == "Success"){
                        
                        address_name  = response.data.formatted_address
                        
                    }else{
                        this.setState({
                            show:true,
                            basicTitle:"Please check lat and lng",
                            basicType:"danger"
                        })
                    }
                })
            }catch(e){

            }
        }

        if (pickup_window_time[0]==null){
            
            pickup_from_time = '00:00';
            pickup_to_time = '23:59';
        }else{
            pickup_from_time = pickup_window_time[0];
            pickup_to_time = pickup_window_time[1]
            
        }
        if (drop_window_time[0]==null){
            drop_from_time = '00:00';
            drop_to_time = '23:59';
        }else{
            drop_from_time = drop_window_time[0];
            drop_to_time = drop_window_time[1]
        }

        if (location_type == ''){
            this.setState({
                show:true,
                basicTitle:"Please check location type",
                basicType:"danger"
            })
        }else{
            if(pickup_window_time.length > 0)
            {
                var valid_time = this.validateTime(pickup_window_time)
            }
            else
            {
                pickup_window_time = ['00:00','23:59']
                var valid_time = this.validateTime(pickup_window_time)
            }
            if(drop_window_time.length > 0)
            {
                var valid_time = this.validateTime(drop_window_time)
            }
            else
            {
                drop_window_time = ['00:00','23:59']
                var valid_time = this.validateTime(drop_window_time)
            }
            if(valid_time){
                let params = {
                    address_name : address_name,
                    addressLat : addressLat,
                    addressLng : addressLng,
                    location_name : location_name,
                    location_type : parseInt(location_type),
                    location_code : location_code,
                    pickup_from_time : pickup_from_time,
                    pickup_to_time : pickup_to_time,
                    drop_from_time : drop_from_time,
                    drop_to_time : drop_to_time,
                    data_type : data_type,
                    form_type : this.state.modalType,
                    rowId : this.state.rowId,
                }
                this.saveLocationsData(params)
            }else{
            console.log('err')
            }
        }
    }
    
    updatePickupAndDropLocations = async(propdata) =>{
        let data = propdata.data
        let ptime = [data.pickup_from_time,data.pickup_to_time]
        let ftime = [data.drop_from_time,data.drop_to_time]
        if(data.pickup_from_time == undefined){
            ptime = ['00:00','23:59']
        }
        if(data.drop_from_time == undefined){
            ftime = ['00:00','23:59']
        }
        
        await this.setState({
            location_code:data.location_code,
            location_name:data.location_name,
            address_name:data.location_address,
            location_type:data.location_type,
            addressLat:data.latitude,
            addressLng:data.longitude,
            pickup_window_time:ptime,
            drop_window_time:ftime,
            rowId:data._id
        })
        this.openModelForPickupAndDropLocations()
    }

    openModelForPickupAndDropLocations = () =>{
        this.setState(prevState =>({
            openPickupDropLocationsModel :!prevState.openPickupDropLocationsModel,
            modalType : 2
        }))
        this.showMap(); 
    }


    deleteLocationsDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteLocationsDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getLocationsData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }


    render(){
        var colsDefs = [
            {
                headerName:"Actions",
                field:"cluster",
                width:"100",
                pinned:'left',
                filter:false,
                sortable:false,
                params:{buttonName:'Edit',
                        onClickFunction:this.updatePickupAndDropLocations},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteLocationsDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"Location Type",
                field:"location_type",
                width:"120",
                valueGetter:function(params){
                    if( params.data.location_type == 1){
                        return Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_PICKUP_DISPALY_NAME
                    }else if( params.data.location_type == 2){
                        return Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_DROP_DISPALY_NAME
                    }else{
                        return Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BOTH_DISPALY_NAME
                    }
                }
            },
            {
                headerName:"Location Code",
                field:"location_code",
                width:"120"
            },
            {
                headerName:"Location Name",
                field:"location_name",
                width:"130"
            },
            {
                headerName:"Address",
                field:"location_address",
                width:"200"
            },
            {
                headerName:"City",
                field:"location_city",
                width:"200"
            },
            {
                headerName:"State",
                field:"location_state",
                width:"200"
            },
            {
                headerName:"Pickup From Time",
                field:"pickup_from_time",
                width:"140"
            },
            {
                headerName:"Pickup To Time",
                field:"pickup_to_time",
                width:"130"
            },
            {
                headerName:"Drop From Time",
                field:"drop_from_time",
                width:"130"
            },
            {
                headerName:"Drop To Time",
                field:"drop_to_time",
                width:"130"
            },

        ]

        var pickupfieldToChk = 0;
        var dropfieldToChk = 0;
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                var location_type = this.state.location_type;
                if(location_type == 1)
                {
                    pickupfieldToChk = toolConfig[0].pickup_time_windows;
                }
                else if(location_type == 2)
                {
                    dropfieldToChk = toolConfig[0].drop_time_windows;
                }
                else if(location_type == 3)
                {
                    pickupfieldToChk = toolConfig[0].pickup_time_windows;
                    dropfieldToChk = toolConfig[0].drop_time_windows;
                }
            }
        }

        var templatePath = require('../../assets/json/Locations_input_data_template.csv');

        return(
            <div>

                <div className="row mb-20p  pt-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold '>
                            <span>{Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_HEADER_NAME}</span>
                                    <div className='float-right col-sm-3'>
                                    <a href="javascript:;" onClick={this.openModelForPickupDrop} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BUTTON_NAME}</a>
                                    <span className="float-right">
                                    <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BULK_BUTTON_NAME}</a>
                                    </span>
                                    </div>    
                        </h5>
                    </div>
                </div>
                
                <div className="row mb-20p">
                <div className="col-sm-12 d-flex">
                <div className="col-sm-5 d-inline">
                    <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham">
                       <AgGridReact
                       modules={this.state.modules} 
                        rowData={this.state.rowData}
                        columnDefs={colsDefs}
                        gridOptions={{context:{componentParent:this}}}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                       />
                    </div>
                    </div>
                    <div className='col-sm-7 d-inline'>
                    <ViewMap 
                    locationsData = {this.state.rowData}
                    data_type = {this.state.data_type}
                    />
                    {/* <div id="pickupDrop_Locations_map" className="col-xl-12 col-lg-12 ml-0p" style={{width:'100%',height:"80vh"}}></div> */}
                    </div>
                    
                    </div>
                </div>


                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockPickupDrop)}>
                {/* <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD_PARTS}</h5> */}
                <div className="row">   
                    <div className="col-sm-12">
                    <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                    <div className="row p-20p">
                        <div className="form-group col-sm-12">
                                        <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>                            
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                            {/* <label style={{color:"#000"}}>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label> */}
                            
                            <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                        <button type="submit" id="bulkUploadBtn" className="btn btn-danger">SAVE</button>
                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                        </div>
                        <div id="inValidDataInfo" style={{color:"red"}}></div>
                    </div>
                    </form>
                    </div>
                </div>
                </div>




                <Modal closeOnOverlayClick={false} open={this.state.openPickupDropLocationsModel} onClose={this.handelPickupDropModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >


                    <div className="container-fluid">
                            <SweetAlert
                                show={this.state.show}
                                type={this.state.basicType}
                                title={this.state.basicTitle}
                                onConfirm={this.closeAlert}>
                                    </SweetAlert>
                    <div className="mappg mprel">
                        <div className="row">
                            <div className={"col-xl-8 col-lg-8 " +this.state.mapp }>

                                <div id="map_canvas" className="col-xl-12 col-lg-12 ml-0p" style={{width:'100%',height:"90vh", borderRadius:"2px"}}></div>
                                
                            </div>
                            <form className="col-xl-4 col-lg-4" onSubmit={this.locationFormHandler}>
                                <input type="text" name="search" onChange={this.handleSearchLocation}  value={this.state.address_name} className="col-xl-6 col-lg-6 mt-20p ml-20p form-control" id="search" placeholder="Enter Address to search" />
                                
                                <div className="form-group" style={{paddingLeft:'15px',marginBottom:"3px"}}>
                                    <label className="c-lbl fbold">Location Type <span className="redColor">*</span></label>
                                    <div className="row col-sm-12 " style={{marginLeft : "-10px" ,paddingRight:"0px"}}>
                                        <div className="col col-sm-6">
                                            {this.state.location_type == 1 ? 
                                                <input id="radio44" checked onChange={this.locationTypeHandler} name="location_type" type="radio" className="form-check-input" value = '1' /> 
                                                :
                                                <input id="radio44" onChange={this.locationTypeHandler} name="location_type" type="radio" className="form-check-input" value = '1' />
                                            }
                                            <label htmlFor="radio44" className="">{Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_PICKUP_DISPALY_NAME}</label>
                                        </div>

                                        <div className="col col-sm-6">
                                        {this.state.location_type == 2 ? 
                                            <input id="radio55" checked onChange={this.locationTypeHandler} name="location_type" type="radio" className="form-check-input" value = "2" />
                                            :
                                            <input id="radio55" onChange={this.locationTypeHandler} name="location_type" type="radio" className="form-check-input" value = "2" />
                                        }
                                            <label htmlFor="radio55" className="">{Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_DROP_DISPALY_NAME}</label>
                                        </div>

                                        <div className="col col-sm-6">
                                        {this.state.location_type == 3 ?
                                            <input id="radio66" checked onChange={this.locationTypeHandler} name="location_type" type="radio" className="form-check-input" value = "3" />
                                            :
                                            <input id="radio66" onChange={this.locationTypeHandler} name="location_type" type="radio" className="form-check-input" value = "3" />
                                        }
                                            <label htmlFor="radio66" className="">{Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BOTH_DISPALY_NAME}</label>
                                        </div>
                                                                                    
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12">
                                    <div className="form-group">
                                        <label className="c-lbl fbold">Location Code <span className="redColor">*</span></label>
                                            <input type="text" name="location_code" value={this.state.location_code} 
                                            onChange = {this.locationCodeField}   className="form-control forminp" placeholder="Code" maxLength="100" required />
                                        <label className="c-lbl fbold">Location Name <span className="redColor">*</span></label>
                                            <input type="text" name="location_name" value={this.state.location_name} 
                                            onChange = {this.locationNameField}   className="form-control forminp" placeholder="Name" maxLength="100" required />
                                        {/* <label className="c-lbl fbold">Address <span className="redColor">*</span></label>
                                            <input type="text" name="address_name" value={this.state.address_name} 
                                            onChange = {this.addressInputField}   className="form-control forminp" placeholder="Address" maxLength="100" required /> */}
                                        {/* <p className={this.state.duplicateChk} style={{color:"red",fontWeight:"bold"}}>Already Exists</p> */}
                                        {/* <div className="errfnt" style={{color: "rgb(153, 153, 153)"}}><i className="icofont icofont-warning-alt"></i> Special Characters @&-_. are allowed</div> */}
                                    </div>
                                    <div className="form-group">
                                    <label className="c-lbl fbold">Location Latitude <span className="redColor">*</span></label>
                                        <input type="number" name="" value={this.state.addressLat} onChange={this.handelLatInput}
                                        className="form-control forminp" placeholder="Latitude" maxLength="100" required />
                                        <label className="c-lbl fbold">Location Longitude <span className="redColor">*</span></label>
                                        <input type="number" name="" value={this.state.addressLng} onChange={this.handelLngInput}
                                        className="form-control forminp" placeholder="Longitude" maxLength="100" required />
                                    </div>	
                                    
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className="col-xl-12 col-lg-12">
                                                <label className="c-lbl fbold">{Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_PICKUP_DISPALY_NAME+" Operational Hours"} <span className="redColor">*</span></label>
                                                    {pickupfieldToChk == 1 ?
                                                    <TimeRangePicker className={"text-color forminp form-control vehicle-time pad-left timer-width "} onChange={this.handlePickupWindowTime} value={this.state.pickup_window_time} rangeDivider={'to'} required={true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/> :
                                                    <TimeRangePicker className={"text-color forminp form-control vehicle-time disable-field-time timer-width"} rangeDivider={'to'} value={this.state.pickup_window_time} disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                                    }
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className="col-xl-12 col-lg-12">
                                                <label className="c-lbl fbold">{Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_DROP_DISPALY_NAME+" Operational Hours"} <span className="redColor">*</span></label>
                                                    {dropfieldToChk == 1 ?
                                                    <TimeRangePicker className={"text-color forminp form-control vehicle-time pad-left timer-width"} onChange={this.handleDropWindowTime} value={this.state.drop_window_time} rangeDivider={'to'} required={true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/> :
                                                    <TimeRangePicker className={"text-color forminp form-control vehicle-time disable-field-time timer-width"} rangeDivider={'to'} value={this.state.drop_window_time} disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                                    }
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                        <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                                        <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelPickupDropModelCancel}>CANCEL</button>	
                                </div>
                            </form>
                                            
                            </div>
                        </div>
                    </div>
                </Modal>


            </div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
