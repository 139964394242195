import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import { verifyext } from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import ViewTransData from "./viewtransactiondata";
import LearningMaterialListForm from "./learningmateriallist";
import NAButton from './naButton';
import StaticButton from './static-button';
import AreaRangeChart from "./areaRangeChart";
import ConfidenceChart from "./confidenceChart";
import LineChart from "./lineChart";
import MaterialList from './materialList';
import CSVFileValidator from 'csv-file-validator';
import ComparisonCharts from './funcCompMaterialComparisonCharts';
var infoBox = require('../common/google-infowindow');
const scaleanimation = {
    transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows = [];
var markersArraylist = [];

export default class ForecastOutputParams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            frameworkComponents: {
                GridButton: GridButton,
                ViewTransData: ViewTransData
            },
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            detailCellRendererParams: {},
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 1000,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            showSlideBlockRoute: "sidebarcls",
            showSlideBlockUpload: "sidebarcls",
            showSlideBlockStack: "sidebarcls",
            overlayBlock: "show-n",
            routeData: [],
            rfreshmap: 1,
            deliverpickupslist: [],
            dealers: [],
            plants: [],
            uploadfile: '',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            loadplan: [],
            allLoadPlan: [],
            deliverydates: [],
            deliveryopts: [],
            filterDeliveryDate: { "value": "", "label": "Select Delivery Date" },
            clusterlist: [],
            filtercluster: { "value": "", "label": "Select Cluster" },
            vehicletypelist: [],
            filterVehicleType: { "value": "", "label": "All" },
            mapcol: "show-n",
            gridocl: "col-sm-12",
            filter_date: "",
            vtypediv: "show-n",
            vsupplierdiv: "show-n",
            selectedCluster: "",
            fdealerlist: [],
            filterdealer: { "value": "", "label": "All" },
            showloader: "show-n",
            mpnode: [],
            mpfor: "",
            clusterpoints: [],
            nmapcluster: "show-n",
            nmap: "show-m",
            scluster: "",
            stackContent: "",
            stackImage: "",
            stacktbl: [],
            stacktblbottom: [],
            stackOriginalData: [],
            showrunprocessbtn: "show-m",
            showanimate: "show-n",
            locationNames: [],
            loadFile: '',
            material_configurations: 0,
            location: { label: "Select Location", value: "" },
            loadshow: 'show-m',
            overly: 'show-m',
            processErrMsg: "",
            processErrShow: "",
            transactionId: "",
            droppedList: [],
            customer_code: [{ "value": "ALL", "label": "All" }],
            material_code: [{ "value": "ALL", "label": "All" }],
            customerlist: [],
            materiallist: [],
            durationlist: [],
            duration_phase: { "value": 12, label: "12 months" },
            chartdata: "show-n",
            datagrid: 'show-m',
            datagridTab: "activet",
            chartdataTab: "btn-default",
            chartseries: [],
            rowForecastData: [],
            forecastitemslist: [],
            forecastcustomerlist: [],
            allforecastcustomerlist: [],
            forecastitem: { "value": "", label: "Select" },
            forecastCustomer: [{ "value": "", label: "All" }],
            predRecords: [],
            forecastingCustomersData: [],
            dataShow: "show-m",
            forecastData: "show-n",
            dayspred: 0,
            inpShow: "show-n",
            sliderTitle: "",
            sliderType: 1,
            materialInfo: [],
            transactionOutput: [],
            forecastingAllData: [],
            chartseries: [],
            confidenceTrain: [],
            confidenceTrend: [],
            confidenceTestPoints: [],
            confidencePredictPoint: [],
            seasonality_train_plot: [],
            seasonality_pred_plot: [],
            seasonality_train_plotdata: [],
            allseason: [],
            chartCusts: [],
            chartItems: [],
            chartCustomer: { label: "Select", value: "" },
            chartItem: { label: "Select", value: "" },
            customerwisedata: [],
            allChartData: [],
            confAreaData: [],
            confTrendplot: [],
            confScatter: [],
            confAreaYHat: [],
            confLineSeasonal: [],
            confAreaDataDemo: [],
            trendingcost: [],
            foreCast_period: '',
            mat_itm_code: { "value": "", label: "Select" },
            scenarioAndItemCodes: [],
            csvfiledata: [],
            scenario: "",
            completedScenariolist: [],
            phaseList: [],
            phase: { "value": "2020-Apr-2021-Mar", label: "2020-Apr-2021-Mar" },
            bestModelsPhaseList: [],
            selectedOption: "phase",
            radioPhase: { "label": "", "value": "" },
            phases: [],
            loadshownew: "show-n",
            sliderbg: "show-n",
            mainScenario: "",
            mainScenariosList: [],
            mainSelectedOption: "material",
            scenarioBasedForecastList: [],
            predictionCompletedForecastList: [],
            yetToPredictForecastList: [],
            totalComponent: false,
            predictionCompleteComponent: true,
            yettoPredictComponent: false

        }

    }
    async componentDidMount() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            overlayBlock: "show-n"
        })
        var durationlist = [
            {
                value: 1,
                label: "1 month"
            }, {
                value: 2,
                label: "2 months"
            }, {
                value: 3,
                label: "3 months"
            },
            {
                value: 4,
                label: "4 months"
            },
            {
                value: 5,
                label: "5 months"
            },
            {
                value: 6,
                label: "6 months"
            },
            {
                value: 7,
                label: "7 months"
            },
            {
                value: 8,
                label: "8 months"
            },
            {
                value: 9,
                label: "9 months"
            },
            {
                value: 10,
                label: "10 months"
            },
            {
                value: 11,
                label: "11 months"
            },
            {
                value: 12,
                label: "12 months"
            }
        ]
        this.setState({
            durationlist: durationlist
        })
        redirectURL.post("/dashboard/phaselists")
            .then((response) => {
                var records = response.data.records;
                var recordsarr = [];
                if (records.length > 0) {
                    records.map((itm) => {
                        recordsarr.push({ value: itm, label: itm });
                    })
                }
                this.setState({
                    phases: recordsarr.reverse(),
                    radioPhase: recordsarr[1]
                })
            })
        redirectURL.post("/dashboard/forecastPredictScenariosList")
            .then((response) => {
                console.log(response, "mainScenariosList")
                var records = response.data.records;
                var recordsarr = [];
                if (records.length > 0) {
                    records.map((itm) => {
                        recordsarr.push({ value: itm, label: itm });
                    })
                }
                this.setState({
                    mainScenariosList: recordsarr
                })
            })
        await this.learningMaterialList()
        await this.getBestModelsPhases()
        await this.loadPageData()
        this.getForecastCompletedScenariosList()
    }
    getBestModelsPhases = async () => {
        this.setState({
            loadshow: 'show-m',
            overlayBlock: 'show-m',
        })
        await redirectURL.post("/forecast/getPhasesFromForecatBestModels", {})
            .then((response) => {
                var phases = []
                response.data.reverse().map((each) => {
                    //var yearString = each.split('-')[0];
                    //var year = parseInt(yearString);
                    //if (year > 2022){
                    //phases.push({value: each, label: each})
                    //}
                    if (each != "" && each != "undefined" && each != null) {
                        phases.push({ value: each, label: each })
                    }
                })
                this.setState({ bestModelsPhaseList: phases })
            }).catch((error) => {
                console.log(error)
            })
    }

    handleOptionChange = (event) => {
        this.setState({ selectedOption: event.target.value, forecastingAllData: [], radioPhase: "", scenario: "" });
    };
    onClickTrackPredictions = (event) => {
        this.setState({ mainSelectedOption: "main_scenario" })
    }
    onClickScenarioGoBack = () => {
        this.setState({
            mainScenario: "", mainSelectedOption: "material", scenarioBasedForecastList: [],
            predictionCompletedForecastList: [],
            yetToPredictForecastList: [],
        })
    }

    getForecastCompletedScenariosList = () => {
        let completedScenariolist = [];
        let phaseList = [];
        redirectURL.post('forecast/getForecastCompletedScenariosList')
            .then(async (response) => {
                var records = response.data.scenarios;
                let distinctPhases = response.data.distinctPhases

                if (records.length > 0) {
                    records.map((item) => {
                        completedScenariolist.push({ value: item, label: item })
                    })
                }
                await distinctPhases.map(item => { phaseList.push({ label: item, value: item }) })
                this.setState({
                    completedScenariolist: completedScenariolist,
                    phaseList: phaseList
                })
            })
    }

    loadPageData() {
        var params = {}
        if (this.state.mat_itm_code != undefined && this.state.mat_itm_code != "") {
            if (this.state.mat_itm_code.value != undefined && this.state.mat_itm_code.value != "") {
                params.item_code = this.state.mat_itm_code.value
            }
        }
        // redirectURL.post('/forecast/forecastoutputdata',params)
        this.setState({ loadshow: 'show-m', overlayBlock: 'show-m' })
        redirectURL.post('/forecast/forecastMaterialRequests', params)
            .then(response => {
                var records = response.data;

                if (records.length > 0) {
                    var params = {

                    }
                    redirectURL.post('/forecast/getDataFromMaterialsMaster', params)
                        .then(async response => {
                            let rec = response.data;
                            // await records.map(item =>{
                            //     rec.map(recItem =>{
                            //         if(recItem.item_code == item.item_code){
                            //             item.item_description = recItem.item_description;
                            //             item.material_classification = recItem.material_classification,
                            //             item.material_type = recItem.material_type,
                            //             item.cost_in_USD_per_item = recItem.cost_in_USD_per_item;
                            //         }
                            //     })
                            // })

                            const recLookup = new Map();
                            await rec.forEach(recItem => {
                                recLookup.set(recItem.item_code, recItem);
                            });

                            // Update the records using the lookup table
                            await records.forEach(item => {
                                const recItem = recLookup.get(item.item_code);
                                if (recItem) {
                                    item.item_description = recItem.item_description;
                                    item.learning_completed = recItem.learning_completed;
                                    item.material_classification = recItem.material_classification;
                                    item.material_type = recItem.material_type;
                                    item.cost_in_USD_per_item = recItem.cost_in_USD_per_item;
                                    // item.item_cost = recItem.item_cost;
                                }
                            });
                            records = records.sort((a, b) => {
                                const classificationOrder = { "V": 1, "E": 2, "D": 3 };

                                // Get the classification values of each object
                                const classificationA = a.material_classification;
                                const classificationB = b.material_classification;

                                // Assign a numerical value based on classification order
                                const orderA = classificationOrder[classificationA] || Number.MAX_SAFE_INTEGER;
                                const orderB = classificationOrder[classificationB] || Number.MAX_SAFE_INTEGER;

                                // Compare numerical values for sorting
                                return orderA - orderB; // Ascending order
                            });
                            await this.setState({
                                rowData: records,
                                loadshow: 'show-n',
                                overlayBlock: 'show-n',
                            })
                        })
                }


            })
    }
    async mainScenarioData() {
        var params = {}
        console.log(this.state.mainScenario, "ber")
        if (this.state.mainScenario !== undefined && this.state.mainScenario !== "") {
            if (this.state.mainScenario.value !== undefined && this.state.mainScenario.value !== "") {
                params.scenario_type = this.state.mainScenario.value
            }
        }
        this.setState({ loadshow: 'show-m', overlayBlock: 'show-m' })
        await redirectURL.post('forecast/getScenarioBasedForecastList', params)
            .then((response) => {
                // console.log(response)
                if (response.data.status === "success") {



                    const latestDataMap = response.data.result.reduce((accumulator, current) => {
                        const existingItem = accumulator[current.item_code];

                        // Check if there's already an object with the same item_code in the accumulator
                        if (!existingItem || current.timestamp > existingItem.timestamp) {
                            // If there's no existing item or the current one has a later timestamp, replace it
                            accumulator[current.item_code] = current;
                        }

                        return accumulator;
                    }, {});

                    // Convert the map values back to an array
                    const latestData = Object.values(latestDataMap);
                    var scenarioBasedForecastList = latestData
                    var predictionCompletedForecastList = scenarioBasedForecastList.filter(item => item.process === 1);
                    var yetToPredictForecastList = scenarioBasedForecastList.filter(item => item.process === 0);
                    this.setState({ scenarioBasedForecastList, predictionCompletedForecastList, yetToPredictForecastList, loadshow: 'show-n', overlayBlock: 'show-n' })
                }
            })
    }
    learningMaterialList = async () => {
        var params = {}
        redirectURL.post('/forecast/forecastlearningMaterialData', params)
            .then(async response => {
                var records = response.data;
                var mList = [{ "label": "ALL", "value": "all" }];
                await records.map((rec) => {
                    mList.push({ "label": rec, "value": rec });
                });
                await this.setState({
                    materiallist: mList
                })
            })

        let scenarioAndItemCodes = [];
        redirectURL.post('/forecast/forecastlearningdata', params)
            .then(async response => {
                var records = response.data;
                var newscenarioAndItemCodes = []
                response.data[1].map((each) => {
                    newscenarioAndItemCodes.push({ value: each, label: each })
                })
                console.log(newscenarioAndItemCodes)
                {/*let scenarioItemsList = groupBy(records, rdata => rdata.scenario_type);
            console.log(scenarioItemsList, "scenarioItemsList")
            scenarioItemsList.forEach((values,key) => {
                let matCodes = [];
                if(key != undefined && key != null && key != ''){
                    matCodes=values.map(item=>item.item_code)
                    scenarioAndItemCodes.push({
                        value:key,
                        label:key,
                        code:matCodes
                    })
            }
            })*/}
                this.setState({ scenarioAndItemCodes: newscenarioAndItemCodes })
            })
    }

    updateMaterialCode = (newValue) => {
        this.setState({ mat_itm_code: newValue })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickShowUpload = () => {
        this.setState({
            showSlideBlockUpload: "slide25",
            overlayBlock: "show-m"
        })
    }

    hideSlideBlock = () => {
        this.setState({
            overlayBlock: "show-n",
            showSlideBlock: "",
            showSlideBlockUpload: "",
            showSlideBlockRoute: "",
            showSlideBlockStack: "",
            loadFile: '',
            loadshow: 'show-n',
            overly: 'show-n',
            duration: { "value": "", "label": "Select Option" },
            material_code: [{ "value": "ALL", "label": "All" }],
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    onChangeCustomer(customer_code) {
        this.setState({ customer_code })
    }
    onChangeMaterial(material_code) {
        this.setState({ material_code })
    }
    onChangeDuration = (duration) => {
        console.log(duration, "duration1")
        this.setState({ duration_phase: duration })
    }
    onChangeMainScenario = (mainScenario) => {
        this.setState({ mainScenario })
    }
    // onChangePhase(radioPhase) {
    //     this.setState({ radioPhase })
    // }

    onChangeScenario = async (scenario) => {
        this.setState({ loadshownew: "show-m", sliderbg: "show-m" })
        var params = {
            scenario_type: scenario.value,
            phase: ""
        }

        await redirectURL.post("/forecast/getAllForecastingData", params).then(async (response) => {
            if (response.data.length > 0) {
                var downloadInfo = response.data;
                if (typeof downloadInfo == "string") {
                    downloadInfo = JSON.parse(response.data);

                }
                this.setState({
                    forecastingAllData: downloadInfo,
                })
            }
        }).catch(function (error) {
            console.log(error);
        })
        this.setState({ scenario, loadshownew: "show-n", sliderbg: "show-n" })
    }

    onChangePhase = async (radioPhase) => {
        this.setState({ loadshownew: "show-m", sliderbg: "show-m" })
        var params = {
            phase: radioPhase.value,
            scenario_type: ""
        }
        await redirectURL.post("/forecast/getAllForecastingData", params).then(async (response) => {
            if (response.data.length > 0) {
                var downloadInfo = response.data;
                if (typeof downloadInfo == "string") {
                    downloadInfo = JSON.parse(response.data);

                }
                this.setState({
                    forecastingAllData: downloadInfo,
                })
            }
        }).catch(function (error) {
            console.log(error);
        })
        this.setState({ radioPhase, loadshownew: "show-n", sliderbg: "show-n" })
    }
    handleShowDataTab = (tab) => {

        if (tab == 1) {
            this.setState({
                predictionCompleteComponent: false,
                yettoPredictComponent: false,
                totalComponent: true
            })
        } else if (tab == 2) {
            this.setState({
                predictionCompleteComponent: true,
                yettoPredictComponent: false,
                totalComponent: false
            })

        }
        else {
            this.setState({
                predictionCompleteComponent: false,
                yettoPredictComponent: true,
                totalComponent: false
            })
        }


    }
    onSubmitGetData = (event) => {
        event.preventDefault();
        var material_code = this.state.material_code;
        var materialCodes = material_code.map((mc) => mc.value);
        if (duration != undefined && duration != "") {
            var params = {
                item_code: materialCodes,
                fc_period: duration
            }
            redirectURL.post("/forecast/predictdata", params)
                .then((response) => {
                    if (response.data.status === "Success") {
                        this.setState({
                            loadshow: 'show-n',
                            overly: 'show-n',
                            overlayBlock: "show-n",
                            showSlideBlockUpload: "",
                            duration: { "value": "", "label": "Select Option" },
                            material_code: [{ "value": "ALL", "label": "All" }],
                            show: true,
                            basicTitle: response.data.message,
                            basicType: "success"
                        })
                        this.loadPageData()
                    }
                    else {
                        this.setState({
                            loadshow: 'show-n',
                            overly: 'show-n',
                            overlayBlock: "show-n",
                            showSlideBlockUpload: "",
                            duration: { "value": "", "label": "Select Option" },
                            material_code: [{ "value": "ALL", "label": "All" }],
                            show: true,
                            basicTitle: "Something went wrong!!!",
                            basicType: "danger"
                        })
                    }
                })
        }
    }
    onClickTab(oVal) {
        if (oVal == "1") {
            this.setState({
                datagrid: "show-m",
                chartdata: "show-n",
                datagridTab: "activet",
                chartdataTab: "btn-default"
            })
        }
        if (oVal == "2") {
            this.setState({
                datagrid: "show-n",
                chartdata: "show-m",
                datagridTab: "btn-default",
                chartdataTab: "activet"
            })
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    onGridReady1 = (params) => {
        this.gridApiNew = params.api;
        this.gridColumnApiNew = params.columnApi;
    };
    onGridReady2 = (params) => {
        this.gridApiNew2 = params.api;
        this.gridColumnApiNew2 = params.columnApi;
    };
    onGridReady3 = (params) => {
        this.gridApiNew3 = params.api;
        this.gridColumnApiNew3 = params.columnApi;
    };
    onBtnExport = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApi.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcel = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApi.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onBtnExportF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcelF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onBtnExportG = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew2.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcelG = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew2.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onBtnExportH = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew3.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtExportExcelH = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew3.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };
    onChangeCustomers(forecastCustomer) {
        this.setState({ forecastCustomer }, () => {
            var sct = this.state.forecastCustomer;
            console.log("sct ", sct)
            if (sct.length > 1) {
                var list = [];
                sct.map((item) => {
                    if (item.value !== "") {
                        list.push(item);
                    }
                })
                this.setState({
                    forecastCustomer: list
                })
            }
            else {
                this.setState({
                    forecastCustomer: this.state.forecastCustomer
                })
            }
        })
    }
    onChangeItems(forecastitem) {
        this.setState({ forecastitem }, () => {
            var selectedItem = this.state.forecastitem.value;
            this.setState({
                forecastCustomer: [{ "value": "", label: "All" }]
            })
            setTimeout(() => {
                var predRecords = this.state.predRecords;
                var selectItemCustomers = [];
                if (predRecords.length > 0) {

                    var customerlist = predRecords.filter((f) => f.item_code === selectedItem);
                    if (customerlist.length > 0) {
                        var customerCodes = groupBy(customerlist, rdata => rdata.customer_code);
                        try {
                            // console.log("customerCodes ", customerCodes)
                            customerCodes.forEach((values, key) => {
                                selectItemCustomers.push({
                                    value: key,
                                    label: key
                                })
                            })
                        } catch (error) {

                        }
                    }
                }
                // console.log("selectItemCustomers ", selectItemCustomers)
                this.setState({
                    forecastcustomerlist: selectItemCustomers
                })
            }, 300)

        })
    }
    onClickGetItemCustomerData() {
        var itemcode = this.state.forecastitem.value;
        var selectedcustomers = [];
        if (itemcode === "all") {
            var forecastcustomerlist = this.state.allforecastcustomerlist;
            // console.log("forecastcustomerlist ", forecastcustomerlist)
            if (forecastcustomerlist.length > 0) {
                forecastcustomerlist.map((item) => {
                    selectedcustomers.push(item.value);
                })
            }
        }
        var customercodes = this.state.forecastCustomer;
        var sct = []
        // console.log("customercodes ", customercodes)
        if (customercodes.length > 0) {
            customercodes.map((item) => {
                if (item.value !== "") {
                    selectedcustomers.push(item.value)

                }
                else {
                    sct.push("all")
                }

            })
            // forecastcustomerlist
        }
        if (sct.includes("all") === true) {
            var sforecastcustomerlist = this.state.forecastcustomerlist;
            if (sforecastcustomerlist.length > 0) {
                sforecastcustomerlist.map((item) => {
                    selectedcustomers.push(item.value);
                })
            }
        }
        // console.log("itemcode ", itemcode)
        // console.log("selectedcustomers ", selectedcustomers)

        var forecastingCustomersData = []
        var predRecords = this.state.predRecords;
        var selectItemCustomers = [];
        if (predRecords.length > 0) {
            predRecords = predRecords.sort(GetSortDescOrder("transaction_id"));
            // console.log("predRecords ", predRecords)
            var trans = []
            if (selectedcustomers.length > 0) {
                selectedcustomers.map((ti) => {
                    predRecords.filter((f) => {
                        if (itemcode !== "all") {
                            if (f.customer_code === ti && f.item_code === itemcode) {
                                trans.push({ customer_code: f.customer_code, item_code: f.item_code, transaction_id: f.transaction_id })
                            }
                        }
                        else {

                            if (f.customer_code === ti) {
                                trans.push({ customer_code: f.customer_code, item_code: f.item_code, transaction_id: f.transaction_id })
                            }
                        }

                    })
                })
            }
            // console.log("transs ", trans)
            if (trans.length > 0) {
                var newArrayItems = [];
                let totuniqueObjectItem = {};
                var totobjTitleItem;
                // Loop for the array elements 
                for (let i in trans) {

                    // Extract the title
                    if (trans[i]['transaction_id'] != "" && trans[i]['transaction_id'] != undefined) {
                        totobjTitleItem = trans[i]['transaction_id'];

                        // Use the title as the index 
                        totuniqueObjectItem[totobjTitleItem] = trans[i];
                    }

                }

                // Loop to push unique object into array 
                for (var a in totuniqueObjectItem) {
                    newArrayItems.push(totuniqueObjectItem[a]);
                }
                // console.log("newArrayItems ", newArrayItems)
                newArrayItems = newArrayItems.sort(GetSortDescOrder("transaction_id"));
                var customegroup = groupBy(newArrayItems, rdata => rdata.customer_code);
                try {
                    customegroup.forEach((cit) => {
                        if (cit.length > 0) {
                            var otm = groupBy(cit, rdata => rdata.item_code);
                            otm.forEach((mm) => {
                                // console.log("mmmm ", mm)
                                mm.sort(GetSortDescOrder("transaction_id"));
                                forecastingCustomersData.push(mm[0])
                            })
                        }
                    })
                } catch (error) {

                }

            }
            // groupBy(customerlist, rdata => rdata.customer_code);
        }
        // console.log("forecastingCustomersData ", forecastingCustomersData)
        this.setState({
            forecastingCustomersData: forecastingCustomersData,
            dataShow: "show-n",
            forecastData: 'show-m'
        })

    }
    onClickForecastGrid() {
        this.setState({
            forecastingCustomersData: [],
            dataShow: "show-m",
            forecastData: 'show-n',
            forecastitem: { "value": "", label: "Select" },
            forecastCustomer: [{ "value": "", label: "All" }],
        })
    }
    showMaterialForecastHistory = async (row) => {
        var historycolsDefs = [
            {
                headerName: "Forecast Summary",
                field: "view_trans_data",
                width: 150,
                headerClass: ["column-header-style"],
                cellRenderer: function (params) {
                    if (params.data.status === "completed") {
                        return <StaticButton />
                    }
                    else {
                        return <NAButton />
                    }
                },
            },
            {
                headerName: "Data Visualization",
                field: "data_visualization",
                width: 150,
                headerClass: ["column-header-style"],
                cellRenderer: function (params) {
                    if (params.data.status === "completed") {
                        return <StaticButton />
                    }
                    else {
                        return <NAButton />
                    }
                },
            },
            {
                headerName: "Forecast Run Date",
                field: "created_on",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: function (params) {
                    try {
                        if (params.data.created_on !== "" && params.data.created_on !== undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.created_on);
                        }
                        else {
                            return "";
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "Duration(months)",
                field: "fc_period",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Status",
                field: "status",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            /*{
                headerName: "Best Model",
                field: "best_model",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            }, */
            {
                headerName: "Phase",
                field: "phase",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
        ]
        await this.setState({
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: historycolsDefs,
                    overlayNoRowsTemplate: 'No rows to show',
                    onCellClicked: this.viewForeCastDetails,
                    height: 200,
                },
                getDetailRowData: async function (param) {
                    param.successCallback([]);
                    await redirectURL.post("/forecast/getForecastingMaterialHistory", {
                        item_code: row.data.item_code
                    }).then(async (response) => {
                        param.successCallback(response.data);
                    }).catch(function (error) {
                        console.log(error);
                    })
                },
                masterDetail: true
            }
        });
        if (row.colDef.field == "viewdata") {
            row.node.setExpanded(!row.node.expanded);
        }
        else {
            row.node.setExpanded(false);
        }
    }
    runNewForeCast = async (row) => {
        this.setState({
            showSlideBlockUpload: "slide25",
            sliderType: 1,
            sliderTitle: "Run Forecast For Material-" + row.data.item_code,
            overlayBlock: "show-m",
            material_code: [{ "label": row.data.item_code, "value": row.data.item_code }]
        })
    }

    compareActualVsPredict = async (row) => {
        await this.setState({
            showSlideBlockUpload: "slide45",
            sliderType: 9,
            sliderTitle: "Comparison of Actual Vs Predict For Material-" + row.data.item_code,
            overlayBlock: "show-m",
            material_code: [{ "label": row.data.item_code, "value": row.data.item_code }],
            duration: { "label": '180 Days', "value": 180 },
        })
    }

    showMaterialInfo = async (row) => {
        this.setState({
            materialInfo: [],
            loadshow: 'show-m',
            overlayBlock: 'show-m',
        })
        await redirectURL.post("/forecast/getDataFromMaterialsMaster", {
            item_code: row.data.item_code
        }).then(async (response) => {
            if (response.data.length > 0) {
                this.setState({
                    showSlideBlockUpload: "slide25",
                    sliderType: 2,
                    sliderTitle: "Material Info",
                    loadshow: 'show-n',
                    overlayBlock: "show-m",
                    materialInfo: response.data
                })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
    downloadPredictionDaa = async (row) => {
        this.setState({
            downloadInfo: [],
            loadshow: 'show-m',
            overlayBlock: 'show-m',
        })
        let downloadInfo = this.state.forecastingAllData

        if (downloadInfo.length > 0) {
            this.setState({
                showSlideBlockUpload: "slide45",
                sliderType: 5,
                sliderTitle: "Download Forecasting Data",
                loadshow: 'show-n',
                overlayBlock: "show-m",
                forecastingAllData: downloadInfo
            })
        } else {
            await redirectURL.post("/forecast/getAllForecastingData", { scenario_type: this.state.scenario.value }).then(async (response) => {
                if (response.data.length > 0) {
                    var downloadInfo = response.data;
                    if (typeof downloadInfo == "string") {
                        downloadInfo = JSON.parse(response.data);
                    }
                    this.setState({
                        showSlideBlockUpload: "slide45",
                        sliderType: 5,
                        sliderTitle: "Download Forecasting Data",
                        loadshow: 'show-n',
                        overlayBlock: "show-m",
                        forecastingAllData: downloadInfo
                    })
                }
            }).catch(function (error) {
                console.log(error);
            })
        }
    }
    viewForeCastDetails = async (row) => {
        if (row.colDef.field === "view_trans_data") {
            if (row.data.status === "completed") {
                // window.location.href="/forecast-output?"+params.data.transaction_id;
                // var months = ["Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"];
                var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                await redirectURL.post("/forecast/getforecastoutputdata", {
                    transaction_id: row.data.transaction_id,
                    item_code: row.data.item_code
                }).then(async (response) => {
                    var records = response.data;
                    var rowData = [];
                    console.log("records ", records)
                    if (records.length > 0) {
                        try {
                            var valuesinf = await groupBy(records, rdata => rdata.item_code);
                            console.log("valuesinf ", valuesinf)
                            await valuesinf.forEach(async (values, vkeys) => {
                                var rw = {}
                                var exmons = [];
                                if (values.length > 0) {
                                    rw["Material Classification"] = values[0].material_classification;
                                    rw["Material Type"] = values[0].material_type;
                                    rw["Forecast Horizon"] = values[0].fc_period;
                                    rw["fy_start_year"] = values[0].fy_start_year;
                                    rw["fy_end_year"] = values[0].fy_end_year;
                                    rw["Material Code"] = values[0].item_code;

                                    values.map((i, n) => {
                                        // rw[months[parseInt(i.month)-4]] = i.item_quantity; 
                                        var month = `Period-${i.month.toString()}`
                                        rw[month] = i.item_quantity;
                                    })
                                }

                                if (exmons.length > 0) {
                                    var diffkeys = arr_diff(exmons, months);
                                    // console.log("diffkeys ", diffkeys)
                                    if (diffkeys.length > 0) {
                                        diffkeys.map((id) => {
                                            if (id !== "itemIndex") {
                                                rw[id] = 0;
                                            }
                                        })
                                    }
                                }
                                rowData.push(rw);
                            })
                        } catch (error) {

                        }
                    }
                    this.setState({
                        showSlideBlockUpload: "slide45",
                        sliderType: 3,
                        sliderTitle: "Forecasting Summary",
                        loadshow: 'show-n',
                        overlayBlock: "show-m",
                        transactionOutput: rowData
                    })
                }).catch(function (error) {
                    console.log(error);
                })
            }
        }
        if (row.colDef.field === "data_visualization") {
            if (row.data.status === "completed") {
                // window.location.href="/forecast-output?"+params.data.transaction_id;
                var months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
                await redirectURL.post("/forecast/getforecastoutputchartdata", {
                    transaction_id: row.data.transaction_id,
                    item_code: row.data.item_code
                }).then(async (response) => {
                    var records = response.data.predict;
                    var history = response.data.history;
                    var chartDataaa = response.data.chartData;

                    // chartDataaa = JSON.parse(mchartDataaa);
                    // console.log("chartDataaa ", chartDataaa)
                    var chartDatalist = [];
                    var chartCusts = [];
                    var chartItems = [];
                    var areaplot = [];
                    var areaYHat = [];
                    let foreCast_period = '';
                    var trendplot = [];
                    var areaScatter = [];
                    var confLineSeasonal = []
                    var trendingcost = []
                    if (chartDataaa.length > 0) {
                        chartDataaa.map((itm) => {
                            itm["chart_data"] = JSON.parse(itm.chart_data);
                            itm["month_data"] = JSON.parse(itm.month_data);

                            // if(itm.confidence_test_points !== undefined && itm.confidence_test_points !== "")
                            // {

                            // console.log("itm ", itm)
                            chartCusts.push({ customer: itm.customer_code });
                            chartItems.push({ itemcode: itm.item_code });
                            chartDatalist.push(itm)
                            // }

                        })
                    }
                    //    console.log("chartDatalist", chartDatalist)
                    if (chartDatalist.length > 0) {
                        var chartData = [chartDatalist[0]];
                        // console.log("chartData ", chartData)
                        if (chartData.length > 0) {
                            chartData.map((mm) => {
                                var chartSeries = mm.chart_data;
                                var motnhSeries = mm.month_data;
                                foreCast_period = mm.fc_period
                                if (chartSeries.length > 0) {
                                    chartSeries.map((mt) => {
                                        var tms = moment.parseZone(mt.ds).format("x");
                                        // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                                        areaplot.push(mt.area_plot);
                                        areaYHat.push((mt.yhat < 0) ? 0 : mt.yhat);
                                        trendingcost.push((mt.cost < 0) ? 0 : mt.cost)
                                        if (mt.ds !== "" && mt.ds !== undefined) {
                                            var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                            var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                            // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                            // console.log("mt.trend ", mt.trend )
                                            if (mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "") {
                                                if (parseInt(trnedns) < 2021) {
                                                    trendplot.push({ name: trnedns, y: mt.trend, lbl: Math.round((mt.trend < 0) ? 0 : mt.trend).toLocaleString() });
                                                }
                                            }
                                            confLineSeasonal.push({ name: tms / 1000, y: Math.round(mt.month_sum), lbl: Math.round(mt.month_sum).toLocaleString() });
                                        }
                                        if (mt.actual !== "" && mt.actual !== undefined && mt.actual !== null) {
                                            // if(mt.actual > 0)
                                            // {
                                            areaScatter.push(mt.actual);
                                            // }

                                        }
                                    })

                                }
                                if (motnhSeries.length > 0) {
                                    motnhSeries.map((mt) => {
                                        // confLineSeasonal.push({name:mt.month,y:Math.round(mt.month_sum),lbl:Math.round(mt.month_sum).toLocaleString()});


                                    })
                                }
                            })
                        }

                        this.setState({
                            chartCustomer: { value: chartData[0].customer_code, label: chartData[0].customer_code },
                            chartItem: { value: chartData[0].item_code, label: chartData[0].item_code }
                        })
                    }
                    else {
                        var chartData = [];
                    }
                    // console.log("confLineSeasonal ", confLineSeasonal)
                    // console.log("areaplot ", areaplot)
                    var monthsChart = ["04", "05", "06", "07", "08", "09", "10", "11", "12", "01", "02", "03"];
                    var monChart = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                    var confidenceTrain = [];
                    var confidenceTrend = [];
                    var confidenceTestPoints = [];
                    var confidencePredictPoint = [];
                    var seasonality_pred_plot = []
                    var seasonality_train_plot = [];
                    var allseason = [];

                    var rowdata = [];
                    var months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
                    // console.log("records ", records)
                    if (records.length > 0) {
                        var itmdata = groupBy(records, rdata => rdata.customer_code);
                        // console.log("itmdata ", itmdata)
                        try {
                            itmdata.forEach((valuesar, keys) => {
                                if (valuesar.length > 0) {
                                    var valuesinf = groupBy(valuesar, rdata => rdata.item_code);
                                    // console.log("valuesinf ", valuesinf)
                                    valuesinf.forEach((values, vkeys) => {
                                        var rw = {}
                                        var exmons = [];
                                        if (values.length > 0) {
                                            rw["_id"] = values[0]._id;
                                            rw["transaction_id"] = values[0].transaction_id;
                                            rw["fy"] = values[0].fy;
                                            rw["fy_start_year"] = values[0].fy_start_year;
                                            rw["fy_end_year"] = values[0].fy_end_year;
                                            rw["item_code"] = values[0].item_code;
                                            rw["customer_code"] = values[0].customer_code;
                                            rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
                                            rw["item_cost"] = values[0].iteminfo[0].item_cost

                                            values.map((i, n) => {
                                                rw[months[parseInt(i.month) - 1]] = i.item_quantity;
                                                rw[months[parseInt(i.month) - 1] + "_cost"] = parseInt(i.item_quantity) * (i.iteminfo[0].item_cost);
                                                exmons.push(months[parseInt(i.month) - 1]);

                                            })
                                            // console.log("RWW ", rw)

                                        }

                                        if (exmons.length > 0) {
                                            var diffkeys = arr_diff(exmons, months);
                                            // console.log("diffkeys ", diffkeys)
                                            if (diffkeys.length > 0) {
                                                diffkeys.map((id) => {
                                                    if (id !== "itemIndex") {
                                                        rw[id] = 0;
                                                        rw[id + "_cost"] = 0;
                                                    }
                                                })
                                            }
                                        }
                                        rowdata.push(rw);
                                    })
                                }

                            })
                        } catch (error) {

                        }
                    }

                    var chartseries = {};
                    if (rowdata.length > 0) {

                        var fywisedata = groupBy(rowdata, rdata => rdata.fy);
                        try {
                            var overallordersfy = [];
                            var overallcostfy = [];
                            var fynonths = []
                            var fynonthsCost = []
                            var historify = [];
                            fywisedata.forEach((values, keys) => {
                                var totlOrders = 0;
                                var totlCosts = 0;
                                // console.log("values",values)
                                if (values.length > 0) {
                                    var monthfy = [];
                                    var monthfyCost = [];
                                    var hmonthfy = []
                                    var pmonthfy = []
                                    var apr = 0;
                                    var may = 0;
                                    var jun = 0;
                                    var jul = 0;
                                    var aug = 0;
                                    var sep = 0;
                                    var oct = 0;
                                    var nov = 0;
                                    var dec = 0;
                                    var jan = 0;
                                    var feb = 0;
                                    var mar = 0;

                                    var apr_cost = 0;
                                    var may_cost = 0;
                                    var jun_cost = 0;
                                    var jul_cost = 0;
                                    var aug_cost = 0;
                                    var sep_cost = 0;
                                    var oct_cost = 0;
                                    var nov_cost = 0;
                                    var dec_cost = 0;
                                    var jan_cost = 0;
                                    var feb_cost = 0;
                                    var mar_cost = 0;

                                    values.map((item) => {
                                        totlOrders = parseInt(totlOrders) + parseInt(item.Apr) + parseInt(item.May) + parseInt(item.Jun) + parseInt(item.Jul) + parseInt(item.Aug) + parseInt(item.Sep) + parseInt(item.Oct) + parseInt(item.Nov) + parseInt(item.Dec) + parseInt(item.Jan) + parseInt(item.Feb) + parseInt(item.Mar);

                                        totlCosts = parseInt(totlCosts) + parseInt(item.Apr_cost) + parseInt(item.May_cost) + parseInt(item.Jun_cost) + parseInt(item.Jul_cost) + parseInt(item.Aug_cost) + parseInt(item.Sep_cost) + parseInt(item.Oct_cost) + parseInt(item.Nov_cost) + parseInt(item.Dec_cost) + parseInt(item.Jan_cost) + parseInt(item.Feb_cost) + parseInt(item.Mar_cost);
                                        apr = parseInt(apr) + parseInt(item.Apr);
                                        may = parseInt(may) + parseInt(item.May);
                                        jun = parseInt(jun) + parseInt(item.Jun);
                                        jul = parseInt(jul) + parseInt(item.Jul);
                                        aug = parseInt(aug) + parseInt(item.Aug);
                                        sep = parseInt(sep) + parseInt(item.Sep);
                                        oct = parseInt(oct) + parseInt(item.Oct);
                                        nov = parseInt(nov) + parseInt(item.Nov);
                                        dec = parseInt(dec) + parseInt(item.Dec);
                                        jan = parseInt(jan) + parseInt(item.Jan);
                                        feb = parseInt(feb) + parseInt(item.Feb);
                                        mar = parseInt(mar) + parseInt(item.Mar);


                                        apr_cost = parseInt(apr_cost) + parseInt(item.Apr_cost);
                                        may_cost = parseInt(may_cost) + parseInt(item.May_cost);
                                        jun_cost = parseInt(jun_cost) + parseInt(item.Jun_cost);
                                        jul_cost = parseInt(jul_cost) + parseInt(item.Jul_cost);
                                        aug_cost = parseInt(aug_cost) + parseInt(item.Aug_cost);
                                        sep_cost = parseInt(sep_cost) + parseInt(item.Sep_cost);
                                        oct_cost = parseInt(oct_cost) + parseInt(item.Oct_cost);
                                        nov_cost = parseInt(nov_cost) + parseInt(item.Nov_cost);
                                        dec_cost = parseInt(dec_cost) + parseInt(item.Dec_cost);
                                        jan_cost = parseInt(jan_cost) + parseInt(item.Jan_cost);
                                        feb_cost = parseInt(feb_cost) + parseInt(item.Feb_cost);
                                        mar_cost = parseInt(mar_cost) + parseInt(item.Mar_cost);

                                    })
                                    hmonthfy.push(apr);
                                    hmonthfy.push(may);
                                    hmonthfy.push(jun);
                                    hmonthfy.push(jul);
                                    hmonthfy.push(aug);
                                    hmonthfy.push(sep);
                                    hmonthfy.push(oct);
                                    hmonthfy.push(nov);
                                    hmonthfy.push(dec);
                                    hmonthfy.push(jan);
                                    hmonthfy.push(feb);
                                    hmonthfy.push(mar);
                                    monthfy.push({
                                        name: "Apr", y: apr
                                    }, {
                                        name: "May", y: may
                                    }, {
                                        name: "Jun", y: jun
                                    }, {
                                        name: "Jul", y: jul
                                    }, {
                                        name: "Aug", y: aug
                                    }, {
                                        name: "Sep", y: sep
                                    }, {
                                        name: "Oct", y: oct
                                    }, {
                                        name: "Nov", y: nov
                                    }, {
                                        name: "Dec", y: dec
                                    }, {
                                        name: "Jan", y: jan
                                    }, {
                                        name: "Feb", y: feb
                                    }, {
                                        name: "Mar", y: mar
                                    });

                                    monthfyCost.push({
                                        name: "Apr", y: apr_cost
                                    }, {
                                        name: "May", y: may_cost
                                    }, {
                                        name: "Jun", y: jun_cost
                                    }, {
                                        name: "Jul", y: jul_cost
                                    }, {
                                        name: "Aug", y: aug_cost
                                    }, {
                                        name: "Sep", y: sep_cost
                                    }, {
                                        name: "Oct", y: oct_cost
                                    }, {
                                        name: "Nov", y: nov_cost
                                    }, {
                                        name: "Dec", y: dec_cost
                                    }, {
                                        name: "Jan", y: jan_cost
                                    }, {
                                        name: "Feb", y: feb_cost
                                    }, {
                                        name: "Mar", y: mar_cost
                                    });

                                    overallordersfy.push({
                                        name: "FY - " + keys,
                                        y: totlOrders
                                    });
                                    overallcostfy.push({
                                        name: "FY - " + keys,
                                        y: totlCosts
                                    });
                                    fynonths.push({
                                        name: keys,
                                        data: monthfy,
                                        color: "#ff0000"
                                    });
                                    fynonthsCost.push({
                                        name: keys,
                                        data: monthfyCost,
                                        color: "#ff0000"
                                    });
                                    historify.push(hmonthfy)
                                }
                            })
                            // console.log("fynonths ", fynonths)
                            chartseries["poverallfinancialorders"] = { name: "Predict Orders", color: "#1ABC9C", data: overallordersfy }
                            chartseries["poverallfinancialcost"] = { name: "Predict Cost", color: "#1ABC9C", data: overallcostfy }
                            var selectedfymonths = fynonths.filter((f) => this.state.fyChart.value === f.name);
                            chartseries["pallfymonthwise"] = { name: "Predict Orders", color: "#1ABC9C", data: fynonths }
                            chartseries["pallfymonthwisecost"] = { name: "Predict Cost", color: "#1ABC9C", data: fynonthsCost }
                            chartseries["pselectedfymonths"] = selectedfymonths
                            chartseries["phistoric"] = historify
                        } catch (error) {

                        }
                        // item = item.sort(GetSortAscOrder("sequence_no"));
                    }

                    if (history.length > 0) {
                        var prowdata = [];
                        var hitmdata = groupBy(history, rdata => rdata.fy);
                        // console.log("hitmdata ", hitmdata)
                        try {
                            hitmdata.forEach((valuears, akeys) => {
                                if (valuears.length > 0) {
                                    var vData = groupBy(valuears, rdata => rdata.item_code);
                                    vData.forEach((values, keys) => {
                                        var rw = {}
                                        var exmons = [];
                                        if (values.length > 0) {
                                            rw["_id"] = values[0]._id;
                                            rw["transaction_id"] = values[0].transaction_id;
                                            rw["fy"] = values[0].fy;
                                            rw["fy_start_year"] = values[0].fy_start_year;
                                            rw["fy_end_year"] = values[0].fy_end_year;
                                            rw["item_code"] = values[0].item_code;
                                            rw["customer_code"] = values[0].customer_code;
                                            rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
                                            rw["item_cost"] = values[0].iteminfo[0].item_cost

                                            values.map((i, n) => {

                                                rw[months[parseInt(i.month) - 1]] = i.item_qty;
                                                rw[months[parseInt(i.month) - 1] + "_cost"] = parseInt(i.item_qty) * (i.item_cost);
                                                exmons.push(months[parseInt(i.month) - 1]);

                                            })
                                            // console.log("RWW ", rw)

                                        }

                                        if (exmons.length > 0) {
                                            var diffkeys = arr_diff(exmons, months);
                                            // console.log("diffkeys ", diffkeys)
                                            if (diffkeys.length > 0) {
                                                diffkeys.map((id) => {
                                                    if (id !== "itemIndex") {
                                                        rw[id] = 0;
                                                        rw[id + "_cost"] = 0;
                                                    }
                                                })
                                            }
                                        }
                                        prowdata.push(rw);
                                    })

                                }
                            })
                        } catch (error) {

                        }
                        // console.log('prowdata ', prowdata)
                        var hfywisedata = groupBy(prowdata, rdata => rdata.fy);
                        // console.log("hfywisedata ", hfywisedata)
                        try {
                            var hoverallordersfy = [];
                            var hoverallcostfy = [];
                            var hfynonths = []
                            var hfynonthsCost = [];
                            var hConfarea = [];
                            var hConfLine = [];
                            var hhistorify = [];
                            hfywisedata.forEach((values, keys) => {
                                var totlOrders = 0;
                                var totlCosts = 0;
                                // console.log("values",values)
                                if (values.length > 0) {
                                    var monthfy = [];
                                    var monthfyCost = [];
                                    var hmonthfy = []
                                    var pmonthfy = []
                                    var apr = 0;
                                    var may = 0;
                                    var jun = 0;
                                    var jul = 0;
                                    var aug = 0;
                                    var sep = 0;
                                    var oct = 0;
                                    var nov = 0;
                                    var dec = 0;
                                    var jan = 0;
                                    var feb = 0;
                                    var mar = 0;

                                    var apr_cost = 0;
                                    var may_cost = 0;
                                    var jun_cost = 0;
                                    var jul_cost = 0;
                                    var aug_cost = 0;
                                    var sep_cost = 0;
                                    var oct_cost = 0;
                                    var nov_cost = 0;
                                    var dec_cost = 0;
                                    var jan_cost = 0;
                                    var feb_cost = 0;
                                    var mar_cost = 0;

                                    values.map((item) => {
                                        totlOrders = parseInt(totlOrders) + parseInt(item.Apr) + parseInt(item.May) + parseInt(item.Jun) + parseInt(item.Jul) + parseInt(item.Aug) + parseInt(item.Sep) + parseInt(item.Oct) + parseInt(item.Nov) + parseInt(item.Dec) + parseInt(item.Jan) + parseInt(item.Feb) + parseInt(item.Mar);

                                        totlCosts = parseInt(totlCosts) + parseInt(item.Apr_cost) + parseInt(item.May_cost) + parseInt(item.Jun_cost) + parseInt(item.Jul_cost) + parseInt(item.Aug_cost) + parseInt(item.Sep_cost) + parseInt(item.Oct_cost) + parseInt(item.Nov_cost) + parseInt(item.Dec_cost) + parseInt(item.Jan_cost) + parseInt(item.Feb_cost) + parseInt(item.Mar_cost);
                                        apr = parseInt(apr) + parseInt(item.Apr);
                                        may = parseInt(may) + parseInt(item.May);
                                        jun = parseInt(jun) + parseInt(item.Jun);
                                        jul = parseInt(jul) + parseInt(item.Jul);
                                        aug = parseInt(aug) + parseInt(item.Aug);
                                        sep = parseInt(sep) + parseInt(item.Sep);
                                        oct = parseInt(oct) + parseInt(item.Oct);
                                        nov = parseInt(nov) + parseInt(item.Nov);
                                        dec = parseInt(dec) + parseInt(item.Dec);
                                        jan = parseInt(jan) + parseInt(item.Jan);
                                        feb = parseInt(feb) + parseInt(item.Feb);
                                        mar = parseInt(mar) + parseInt(item.Mar);

                                        apr_cost = parseInt(apr_cost) + parseInt(item.Apr_cost);
                                        may_cost = parseInt(may_cost) + parseInt(item.May_cost);
                                        jun_cost = parseInt(jun_cost) + parseInt(item.Jun_cost);
                                        jul_cost = parseInt(jul_cost) + parseInt(item.Jul_cost);
                                        aug_cost = parseInt(aug_cost) + parseInt(item.Aug_cost);
                                        sep_cost = parseInt(sep_cost) + parseInt(item.Sep_cost);
                                        oct_cost = parseInt(oct_cost) + parseInt(item.Oct_cost);
                                        nov_cost = parseInt(nov_cost) + parseInt(item.Nov_cost);
                                        dec_cost = parseInt(dec_cost) + parseInt(item.Dec_cost);
                                        jan_cost = parseInt(jan_cost) + parseInt(item.Jan_cost);
                                        feb_cost = parseInt(feb_cost) + parseInt(item.Feb_cost);
                                        mar_cost = parseInt(mar_cost) + parseInt(item.Mar_cost);

                                    })
                                    hmonthfy.push(apr);
                                    hmonthfy.push(may);
                                    hmonthfy.push(jun);
                                    hmonthfy.push(jul);
                                    hmonthfy.push(aug);
                                    hmonthfy.push(sep);
                                    hmonthfy.push(oct);
                                    hmonthfy.push(nov);
                                    hmonthfy.push(dec);
                                    hmonthfy.push(jan);
                                    hmonthfy.push(feb);
                                    hmonthfy.push(mar);
                                    monthfy.push({
                                        name: "Apr", y: apr
                                    }, {
                                        name: "May", y: may
                                    }, {
                                        name: "Jun", y: jun
                                    }, {
                                        name: "Jul", y: jul
                                    }, {
                                        name: "Aug", y: aug
                                    }, {
                                        name: "Sep", y: sep
                                    }, {
                                        name: "Oct", y: oct
                                    }, {
                                        name: "Nov", y: nov
                                    }, {
                                        name: "Dec", y: dec
                                    }, {
                                        name: "Jan", y: jan
                                    }, {
                                        name: "Feb", y: feb
                                    }, {
                                        name: "Mar", y: mar
                                    });

                                    monthfyCost.push({
                                        name: "Apr", y: apr_cost
                                    }, {
                                        name: "May", y: may_cost
                                    }, {
                                        name: "Jun", y: jun_cost
                                    }, {
                                        name: "Jul", y: jul_cost
                                    }, {
                                        name: "Aug", y: aug_cost
                                    }, {
                                        name: "Sep", y: sep_cost
                                    }, {
                                        name: "Oct", y: oct_cost
                                    }, {
                                        name: "Nov", y: nov_cost
                                    }, {
                                        name: "Dec", y: dec_cost
                                    }, {
                                        name: "Jan", y: jan_cost
                                    }, {
                                        name: "Feb", y: feb_cost
                                    }, {
                                        name: "Mar", y: mar_cost
                                    });

                                    hConfarea.push({
                                        name: "FY - " + keys,
                                        // data:[[apr_cost,may_cost],[may_cost,jun_cost],[jun_cost,jul_cost],[jul_cost,aug_cost],[aug_cost,sep_cost],[sep_cost,oct_cost],[oct_cost,nov_cost],[nov_cost,dec_cost],[dec_cost,jan_cost],[jan_cost,feb_cost],[feb_cost,mar_cost],[mar_cost,0]]
                                        data: [[apr_cost, mar_cost]]
                                    });
                                    hConfLine.push({
                                        name: "FY - " + keys,
                                        data: [apr_cost, may_cost, jun_cost, jul_cost, aug_cost, sep_cost, oct_cost, nov_cost, dec_cost, jan_cost, feb_cost, mar_cost]
                                    })
                                    // hConfarea.push({
                                    //     name:"FY - "+keys,
                                    //     data:[[apr,may],[may,jun],[jun,jul],[jul,aug],[aug,sep],[sep,oct],[oct,nov],[nov,dec],[dec,jan],[jan,feb],[feb,mar]]
                                    // });
                                    hoverallordersfy.push({
                                        name: "FY - " + keys,
                                        y: totlOrders
                                    });
                                    hoverallcostfy.push({
                                        name: "FY - " + keys,
                                        y: totlCosts
                                    });
                                    hfynonths.push({
                                        name: keys,
                                        data: monthfy
                                    });
                                    hfynonthsCost.push({
                                        name: keys,
                                        data: monthfyCost
                                    });
                                    hhistorify.push(hmonthfy)
                                }
                            })
                            // console.log("fynonths ", fynonths)
                            chartseries["hoverallfinancialorders"] = { name: "History", colorByPoint: true, data: hoverallordersfy }
                            chartseries["hoverallfinancialcost"] = { name: "History", colorByPoint: true, data: hoverallcostfy }
                            chartseries["hallfymonthwise"] = { name: "History", colorByPoint: true, data: hfynonths }
                            chartseries["hallfymonthwisecost"] = { name: "History", colorByPoint: true, data: hfynonthsCost }
                            chartseries["hhistoric"] = hhistorify
                        } catch (error) {

                        }
                        // item = item.sort(GetSortAscOrder("sequence_no"));
                    }

                    var newArray = [];
                    let totuniqueObject = {};
                    var totobjTitle;
                    // Loop for the array elements 
                    for (let i in chartCusts) {

                        // Extract the title
                        if (chartCusts[i]['customer'] !== "" && chartCusts[i]['customer'] !== undefined) {
                            totobjTitle = chartCusts[i]['customer'];

                            // Use the title as the index 
                            totuniqueObject[totobjTitle] = chartCusts[i];
                        }

                    }

                    // Loop to push unique object into array 
                    for (var a in totuniqueObject) {
                        newArray.push(totuniqueObject[a]);
                    }

                    var chartCustsarr = [];
                    if (newArray.length > 0) {
                        newArray.map((item) => {
                            chartCustsarr.push({
                                value: item.customer,
                                label: item.customer
                            })
                        })
                    }


                    var newArrayItem = [];
                    let totuniqueObjectItem = {};
                    var totobjTitle;
                    // Loop for the array elements 
                    for (let i in chartItems) {

                        // Extract the title
                        if (chartItems[i]['itemcode'] !== "" && chartItems[i]['itemcode'] !== undefined) {
                            totobjTitle = chartItems[i]['itemcode'];

                            // Use the title as the index 
                            totuniqueObjectItem[totobjTitle] = chartItems[i];
                        }

                    }

                    // Loop to push unique object into array 
                    for (var a in totuniqueObjectItem) {
                        newArrayItem.push(totuniqueObjectItem[a]);
                    }


                    var chartItemarr = [];
                    if (newArrayItem.length > 0) {
                        newArrayItem.map((item) => {
                            chartItemarr.push({
                                value: item.itemcode,
                                label: item.itemcode
                            })
                        })
                    }
                    // console.log("trendplot ", trendplot)
                    this.setState({
                        // chartseries:[chartseries],
                        confidenceTrain: confidenceTrain,
                        confidenceTrend: confidenceTrend,
                        confidenceTestPoints: confidenceTestPoints,
                        confidencePredictPoint: confidencePredictPoint,
                        seasonality_train_plot: seasonality_train_plot[seasonality_train_plot.length - 1],
                        seasonality_pred_plot: seasonality_pred_plot,
                        seasonality_train_plotdata: seasonality_train_plot,
                        allseason: allseason,
                        chartCusts: chartCustsarr,
                        chartItems: chartItemarr,
                        allChartData: chartDatalist,
                        confAreaData: areaplot,
                        foreCast_period: foreCast_period,
                        confAreaYHat: areaYHat,
                        confTrendplot: trendplot,
                        confScatter: areaScatter,
                        trendingcost: trendingcost,
                        confLineSeasonal: confLineSeasonal,
                        showSlideBlockUpload: "slide45",
                        sliderType: 4,
                        sliderTitle: "Data Visualization",
                        loadshow: 'show-n',
                        overlayBlock: 'show-m',
                    })
                    setTimeout(() => {
                        this.onClickShowCustomerData();
                    }, 500)
                }).catch(function (error) {
                    console.log(error);
                })
            }
        }
    }
    onChangeChartItem(chartItem) {
        this.setState({ chartItem })
    }
    onClickShowCustomerData() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });
        var customer = this.state.chartCustomer.value;
        var itemCode = this.state.chartItem.value;
        // console.log("customer ", customer)

        var chartDataarr = this.state.allChartData;
        var chartData = chartDataarr.filter((f) => (f.customer_code === customer && f.item_code === itemCode));
        // console.log("chartData ", chartDataarr)
        // console.log("chartDatachartData ", chartData)
        var chartDatalist = [];
        var chartCusts = [];
        var chartItems = [];
        var areaplot = [];
        var areaYHat = [];
        let foreCast_period = '';
        var trendplot = [];
        var areaScatter = [];
        var confLineSeasonal = []
        var trendingcost = []

        var monthsChart = ["04", "05", "06", "07", "08", "09", "10", "11", "12", "01", "02", "03"];
        var monChart = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        try {

            // if(chartData.length > 0)
            // {
            //     var confidence_train_points = [];
            //     var cn=0;
            //     if(chartData[0].confidence_train_points !== undefined)
            //     {
            //         if(chartData[0].confidence_train_points.length > 0)
            //         {
            //             chartData[0].confidence_train_points.map((itm,ind) => {
            //                 var d = itm[0]+"-"+monthsChart[ind]+"01 00:00";
            //                 var dt = moment.parseZone(new Date(d+":00")).utcOffset("+05:30")._d;
            //                 var timefrm = moment.parseZone(dt).format("x");
            //                 confidence_train_points.push([ind,parseInt(itm[1])]);

            //             })
            //         }
            //     }

            //     var trend_existing_points = [];
            //     if(chartData[0].trend_existing_points !== undefined)
            //     {
            //         chartData[0].trend_existing_points.map((itm,ind) => {
            //             trend_existing_points.push({
            //                 name: itm[0],
            //                 y:parseInt(itm[1])
            //             })
            //         })
            //     }


            //     var confidence_test_points = [];
            //     if(confidence_train_points.length > 0)
            //     {
            //         var ed =confidence_train_points[confidence_train_points.length-1][0];
            //         if(chartData[0].confidence_test_points !== undefined)
            //         {
            //             chartData[0].confidence_test_points.map((itm,ind) => {
            //                 confidence_test_points.push([parseInt(ed),parseInt(itm[1])]);
            //                 ed=ed+1;
            //             })
            //         }

            //     }

            //     // console.log("confidence_test_points ", confidence_test_points)
            //     var trend_predicted_points = [];
            //     if(trend_existing_points.length > 0)
            //     {
            //         // console.log("trend_existing_points[trend_existing_points.length-1] ", trend_existing_points[trend_existing_points.length-1])
            //         trend_predicted_points.push({name:trend_existing_points[trend_existing_points.length-1].name,y:trend_existing_points[trend_existing_points.length-1].y})

            //         trend_predicted_points.push({name:chartData[0].trend_predicted_points[0],y:parseInt(chartData[0].trend_predicted_points[1])});
            //     }
            //     // console.log("HEre trend_predicted_points", trend_predicted_points)
            //     var seasonality_train_plotar = [];
            //     // console.log('chartData[0].seasonality_train_plot ', chartData[0].seasonality_train_plot)
            //     if(chartData[0].seasonality_train_plot !== undefined)
            //     {
            //         chartData[0].seasonality_train_plot.map((itm) => {

            //             seasonality_train_plotar.push(JSON.parse(itm));
            //         })
            //     }

            //     // console.log("seasonality_train_plotar ", seasonality_train_plotar)
            //     var seasonality_train_plot = [];

            //     if(seasonality_train_plotar.length > 0)
            //     {
            //         var sdatatrne = groupBy(seasonality_train_plotar, rdata => rdata.year);
            //         try {
            //             // console.log("sdatatrne ", sdatatrne)

            //             sdatatrne.forEach((values,key) => {

            //                 var seasonality_train_plotd = [];
            //                 if(values.length > 0)
            //                 {
            //                     values.map((im) => {
            //                         // console.log("itm ", key+"===="+JSON.stringify(im))
            //                         // console.log("monChart[im.month-1] ", monChart[parseInt(im.month)-1])
            //                         var nm = monChart[parseInt(im.month)-1];
            //                         // console.log("nm ", nm)
            //                         seasonality_train_plotd.push({
            //                             name:monChart[parseInt(im.month)-1],
            //                             y:parseInt(im.item_qty)
            //                         })
            //                     })
            //                 }
            //                 // console.log("seasonality_train_plotd ",seasonality_train_plotd)
            //                 seasonality_train_plot.push({
            //                     name:key,
            //                     data:seasonality_train_plotd
            //                 })
            //             });
            //         } catch (error) {

            //         }
            //     }

            //     var seasonality_pred_plot = [];
            //     if(chartData[0].seasonality_pred_plot !== undefined)
            //     {
            //         var seasonality_pred_plotar = chartData[0].seasonality_pred_plot;
            //         if(seasonality_pred_plotar.length > 0){
            //             seasonality_pred_plotar.map((itm) => {
            //                 seasonality_pred_plot.push({
            //                     name:monChart[parseInt(itm.month)-1],
            //                     y:parseInt(itm.item_qty)
            //                 })
            //             })
            //         }

            //     }

            //     var confidenceTrain = confidence_train_points; 
            //     var confidenceTrend = [{name:"Trend Points",data:trend_existing_points},{name:"Predict",data:trend_predicted_points,color:"#FF0000"}];
            //     var confidenceTestPoints = confidence_test_points; 
            //     var confidencePredictPoint = trend_predicted_points; 
            //     var seasonality_pred_plot = {name:seasonality_pred_plotar[0].year,data:seasonality_pred_plot};
            //     // seasonality_train_plot = seasonality_train_plot;
            //     seasonality_train_plot.push(seasonality_pred_plot);


            //     // console.log("confidenceTrend ", confidenceTrend)
            // }
            // else{
            //     var confidenceTrain = []; 
            //     var confidenceTrend = [];
            //     var confidenceTestPoints = []; 
            //     var confidencePredictPoint = [];
            //     var seasonality_pred_plot = ""
            //     var seasonality_train_plot = "";
            // }

            if (chartData.length > 0) {
                chartData.map((mm) => {
                    var chartSeries = mm.chart_data;
                    var motnhSeries = mm.month_data;
                    foreCast_period = mm.fc_period
                    if (chartSeries.length > 0) {
                        chartSeries.map((mt) => {
                            var tms = moment.parseZone(mt.ds).format("x");
                            const originalDate = new Date(mt.ds);
                            originalDate.setDate(1);
                            originalDate.setHours(0, 0, 0, 0);
                            const modifiedDate = originalDate.toISOString()
                            // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                            areaplot.push([new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), ...mt.area_plot]);
                            areaYHat.push((mt.yhat < 0) ? [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), 0] : [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), parseInt(mt.yhat)]);
                            trendingcost.push((mt.cost < 0) ? [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), 0] : [new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), mt.cost]);
                            if (mt.ds !== "" && mt.ds !== undefined) {
                                var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                if (mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "") {
                                    if (parseInt(trnedns) < 2022) {
                                        trendplot.push({ name: trnedns, y: mt.trend, lbl: Math.round((mt.trend < 0) ? 0 : mt.trend).toLocaleString() });
                                    }
                                }
                                // confLineSeasonal.push({name:tms/1000,y:Math.round(mt.yearly),lbl:Math.round(mt.yearly).toLocaleString()});
                                // confLineSeasonal.push([tms/1000,mt.yearly]);
                            }
                            if (mt.actual !== "" && mt.actual !== undefined && mt.actual !== null) {
                                // if(mt.actual > 0)
                                // {
                                areaScatter.push([new Date(modifiedDate).getTime() + (60 * 60 * 24 * 1000), mt.actual]);
                                // }

                            }
                        })

                    }
                    if (motnhSeries.length > 0) {
                        motnhSeries.map((mt) => {
                            confLineSeasonal.push({ name: mt.month, y: Math.round(mt.month_sum), lbl: Math.round(mt.month_sum).toLocaleString() });


                        })
                    }
                })
            }
            // if(seasonality_train_plot.length > 0)
            // {
            //     var ss = seasonality_train_plot[seasonality_train_plot.length-1]
            // }
            // else{
            //     var ss = [];
            // }
        }
        catch (e) { }
        // console.log("confLineSeasonal ", confLineSeasonal)
        this.setState({
            // chartCusts:chartCustsarr,
            // chartItems:chartItemarr,
            // allChartData:chartDatalist,
            confAreaData: areaplot,
            confAreaYHat: areaYHat,
            confTrendplot: trendplot,
            confScatter: areaScatter,
            confLineSeasonal: confLineSeasonal,
            trendingcost: trendingcost,
            foreCast_period: foreCast_period
        })
    }
    dowloadAllForecastingData = async () => {
        this.setState({
            downloadInfo: [],
            loadshow: 'show-m',
            overlayBlock: 'show-m',
        })
        await redirectURL.post("/forecast/getAllForecastingData", {}).then(async (response) => {
            if (response.data.length > 0) {
                var downloadInfo = response.data;
                if (typeof downloadInfo == "string") {
                    downloadInfo = JSON.parse(response.data);
                }
                // console.log(downloadInfo,'down')
                this.setState({
                    showSlideBlockUpload: "slide45",
                    sliderType: 5,
                    sliderTitle: "Download Prediction Vs Actual Data",
                    loadshow: 'show-n',
                    overlayBlock: "show-m",
                    forecastingAllData: downloadInfo
                })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    showOnlyPredictionData = async () => {
        this.setState({
            // loadshow: 'show-m',
            overlayBlock: 'show-m',
            loadshownew: "show-m",
            showSlideBlockUpload: "slide45",
            sliderType: 5,
            sliderTitle: "Download Forecasting Data",
            sliderbg: "show-m"

        })
        let downloadInfo = this.state.forecastingAllData
        console.log(downloadInfo, "")
        if (downloadInfo.length > 0) {
            this.setState({
                // showSlideBlockUpload: "slide45",
                // sliderType: 5,
                // sliderTitle: "Download Forecasting Data",
                loadshow: 'show-n',
                overlayBlock: "show-m",
                forecastingAllData: downloadInfo,
                loadshownew: "show-n",
                sliderbg: "show-n"
            })
        } else {
            await redirectURL.post("/forecast/getAllForecastingData", {phase: this.state.radioPhase.value}).then(async (response) => {
                if (response.data.length > 0) {
                    var downloadInfo = response.data;
                    if (typeof downloadInfo == "string") {
                        downloadInfo = JSON.parse(response.data);
                    }
                    this.setState({
                        // showSlideBlockUpload: "slide45",
                        // sliderType: 5,
                        // sliderTitle: "Download Forecasting Data",
                        loadshow: 'show-n',
                        overlayBlock: "show-m",
                        forecastingAllData: downloadInfo,
                        loadshownew: "show-n",
                        sliderbg: "show-n"
                    })
                }
            }).catch(function (error) {
                console.log(error);
            })
        }

    }

    handleTriggerForecast = () => {
        this.setState({
            showSlideBlockUpload: "slide35",
            sliderType: 7,
            sliderTitle: "Forecast",
            overlayBlock: "show-m",
        })
    }


    openUploadOptionForAvailability = () => {
        // document.getElementById("bulkUploadForm").reset()
        // document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlockUpload: "slide25",
            sliderType: 8,
            sliderTitle: "Actual Data",
            overlayBlock: "show-m",
        })
    }

    hideSlideBlock = () => {

        if (document.getElementById("bulkUploadForm") != null) {
            document.getElementById("bulkUploadForm").reset()
            document.getElementById("inValidDataInfo").innerHTML = "";
        }

        if (document.getElementById('csvfile') != null) {
            document.getElementById("csvfile").value = ''
            document.getElementById("inValidDataInfo").innerHTML = "";
        }

        this.setState({
            overlayBlock: "show-n",
            showSlideBlockUpload: "",
            scenario: { "value": "", "label": "Select" }
        })
    }

    formCSVUpload = (event) => {

        event.preventDefault();
        var csvfiledata = this.state.csvfiledata;
        var p = {
            filedata: csvfiledata,

        }
        redirectURL.post("/forecast/uploadPredictMaterialData", p)
            .then(async (resp) => {

                if (resp.data.status == 'success') {
                    await this.setState({
                        // show:true,
                        // basicTitle:resp.data.message,
                        // basicType:"success",
                        overlayBlock: "show-n",
                        showSlideBlock: "",
                        showSlideBlockUpload: ''
                    })
                    // window.location.reload();
                    alert(resp.data.message)

                } else {
                    // this.setState({
                    //     show:true,
                    //     basicTitle:resp.data.message,
                    //     basicType:"danger"
                    // })
                    alert(resp.data.message)
                }
            })
    }


    changeCSVFileHandler = async (e) => {

        const config = {
            headers: [

                {
                    name: 'Item Code',
                    inputName: 'item_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'Quantity',
                    inputName: 'quantity',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'Actual Date',
                    inputName: 'posting_date',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

            ]
        }



        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                document.getElementById("inValidDataInfo").innerHTML = "";
                if (csvData.inValidData.length > 0) {
                    document.getElementById("bulkUploadBtn").type = "button"
                    document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                    document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                    let invalidData = csvData.inValidData;
                    let element = document.getElementById("inValidDataInfo")
                    invalidData.map(item => {
                        let row, column
                        if (item.rowIndex == undefined) {
                            row = 'NA'
                        } else {
                            row = item.rowIndex
                        }
                        if (item.columnIndex == undefined) {
                            column = 'NA'
                        } else {
                            column = item.columnIndex
                        }

                        element.innerHTML += "Column : " + column + "," + " Row : " + row + "--" + item.message + "<br></br>"
                    })

                } else {
                    document.getElementById("bulkUploadBtn").type = "submit"
                    document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                    document.getElementById("bulkUploadBtn").classList.add("btn-danger");
                    this.setState({
                        csvfiledata: csvData.data
                    });
                }
            })
            .catch(err => { })
    }

    onChangePredictionPhase = (phase) => {
        this.setState({ phase: phase })
    }

    predictionData = () => {
        this.showOnlyPredictionData()

        //     this.setState({
        //         showSlideBlockUpload:"slide25",
        //         sliderType:10,
        //         sliderTitle:"Download Prediction Data",
        //         loadshow:'show-n',
        //         overlayBlock:"show-m",
        //     })   
    }

    render() {
        var templatePath = require('../../assets/json/material_data_template.csv');
        let transactionMonth = this.state.transactionOutput

        let showJan = false;
        let showFeb = false;
        let showMar = false;
        let showApr = false;
        let showMay = false;
        let showJun = false;
        let showJul = false;
        let showAug = false;
        let showSep = false;
        let showOct = false;
        let showNov = false;
        let showDec = false;

        if (transactionMonth.length > 0) {
            if (transactionMonth[0].Jan === "" || transactionMonth[0].Jan === undefined) {
                showJan = true
            }
            if (transactionMonth[0].Feb === "" || transactionMonth[0].Feb === undefined) {
                showFeb = true
            }
            if (transactionMonth[0].Mar === "" || transactionMonth[0].Mar === undefined) {
                showMar = true
            }
            if (transactionMonth[0].Apr === "" || transactionMonth[0].Apr === undefined) {
                showApr = true
            }
            if (transactionMonth[0].May === "" || transactionMonth[0].May === undefined) {
                showMay = true
            }
            if (transactionMonth[0].Jun === "" || transactionMonth[0].Jun === undefined) {
                showJun = true
            }
            if (transactionMonth[0].Jul === "" || transactionMonth[0].Jul === undefined) {
                showJul = true
            }
            if (transactionMonth[0].Aug === "" || transactionMonth[0].Aug === undefined) {
                showAug = true
            }
            if (transactionMonth[0].Sep === "" || transactionMonth[0].Sep === undefined) {
                showSep = true
            }
            if (transactionMonth[0].Oct === "" || transactionMonth[0].Oct === undefined) {
                showOct = true
            }
            if (transactionMonth[0].Nov === "" || transactionMonth[0].Nov === undefined) {
                showNov = true
            }
            if (transactionMonth[0].Dec === "" || transactionMonth[0].Dec === undefined) {
                showDec = true
            }
        }

        var columnwithDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Description",
                field: "item_description",
                width: 260,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Unit Cost ( $ ) ",
                field: "cost_in_USD_per_item",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },

            /* {
                 headerName: "Scenario",
                 field: "scenario_type",
                 width:140,
                 filter: true,
                 resizable: true,
                 editable:false
             },
             {
                 headerName: "Scenario Description",
                 field: "scenario_description",
                 width:140,
                 filter: true,
                 resizable: true,
                 editable:false
             },*/
            // {
            //     headerName: "No. of Predictions Performed",
            //     field: "no_of_predicts",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Recent Best Model",
            //     field: "best_model",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Created Date",
            //     field: "created_date",
            //     width:140,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         try {
            //             if(params.data.created_date !== "" && params.data.created_date !== undefined)
            //             {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.created_date);
            //             }
            //             else{
            //                 return "";
            //             }
            //         } catch (error) {

            //         }
            //     }
            // }
            {
                headerName: "Forecasting History",
                field: "viewdata",
                width: 190,
                params: { buttonName: 'View History', onClickFunction: this.showMaterialForecastHistory, iconName: "fa fa-eye", btnClass: "btn btn-secondary" },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                },
                headerClass: ["column-header-style"]
            },
            {
                headerName: "New Forecast",
                field: "runnewforecast",
                width: 150,
                params: { buttonName: 'Run', onClickFunction: this.runNewForeCast, iconName: "fa fa-line-chart", btnClass: "btn btn-info", btnColor: { color: "#fff", border: "0px" } },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                },
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Compare Actual Vs Predict",
                field: "compareactualvspredict",
                width: 210,
                params: { buttonName: 'Compare', onClickFunction: this.compareActualVsPredict, iconName: "fa fa-line-chart", btnClass: "btn btn-info", btnColor: { color: "#fff", border: "0px" } },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                },
                headerClass: ["column-header-style"]
            },
            // {
            //     headerName: "Material Info",
            //     field: "materialinfo",
            //     width:130,
            //     params:{buttonName:'View Info',onClickFunction:this.showMaterialInfo,iconName:"fa fa-eye",btnClass:"btn btn-primary",btnColor:{color:"#fff",border:"0px"}},
            //     cellRendererSelector:function(params){
            //         var rendComponent = {
            //             component: "GridButton"
            //         };
            //         return rendComponent
            //     },
            // },
            // {
            //     headerName: "Prediction Data",
            //     field: "predictiondata",
            //     width:170,
            //     params:{buttonName:'Download',onClickFunction:this.downloadPredictionDaa,iconName:"fa fa-download",btnClass:"btn btn-success",btnColor:{color:"#fff",border:"0px"}},
            //     cellRendererSelector:function(params){
            //         var rendComponent = {
            //             component: "GridButton"
            //         };
            //         return rendComponent
            //     },
            // },          
        ]
        var columnwithDefsForTransactionOutput = [
            {
                headerName: "Material Code",
                field: "Material Code",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Classification",
                field: "Material Classification",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Type",
                field: "Material Type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Forecast Horizon (months)",
                field: "Forecast Horizon",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            // {
            //     headerName: "Best Model",
            //     field: "Best Model",
            //     width:140,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "January",
                field: "Period-1",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Febraury",
                field: "Period-2",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "March",
                field: "Period-3",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "April",
                field: "Period-4",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "May",
                field: "Period-5",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "June",
                field: "Period-6",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "July",
                field: "Period-7",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "August",
                field: "Period-8",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "September",
                field: "Period-9",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "October",
                field: "Period-10",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "November",
                field: "Period-11",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "December",
                field: "Period-12",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
        ]
        var columnwithDefsForAllForecastData = [
            {
                headerName: "Material Code",
                field: "Material Code",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Classification",
                field: "Material Classification",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Type",
                field: "Material Type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Prediction Category",
                field: "Prediction Category",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Forecast Horizon (months)",
                field: "Forecast Horizon",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            // {
            //     headerName: "Best Model",
            //     field: "Best Model",
            //     width:140,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "January",
                field: "Period-1",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Febraury",
                field: "Period-2",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "March",
                field: "Period-3",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "April",
                field: "Period-4",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "May",
                field: "Period-5",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "June",
                field: "Period-6",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "July",
                field: "Period-7",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "August",
                field: "Period-8",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "September",
                field: "Period-9",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "October",
                field: "Period-10",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "November",
                field: "Period-11",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "December",
                field: "Period-12",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Unit Cost( $ )",
                field: "Cost per Unit($)",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Actual Quantity",
                field: "Actual Consumption Quantity",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Actual Cost ( $ )",
                field: "Actual Cost($)",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Predicted Quantity",
                field: "Predicted Quantity",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Predicted Cost ( $ )",
                field: "Total Cost($)",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Quantity Difference",
                field: "Quantity Difference",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Cost Difference ( $ )",
                field: "Cost Difference ($)",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
        ]

        var scenarioBasedColumnWithDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Scenario Type",
                field: "scenario_type",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Consumption Data Used Till",
                field: "last_consumption_date",
                width: 250,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Prediction Started By",
                field: "email",
                width: 250,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Prediction Status",
                field: "process",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: function (params) {
                    try {
                        if (params.data.process !== "" && params.data.process !== undefined && params.data.process === 1) {
                            return "Completed";
                        }
                        else {
                            return "In Progress";
                        }
                    } catch (error) {

                    }
                }
            }
        ]

        var columnwithDefsForAllForecastDataOnlyPredicted = [
            {
                headerName: "Material Code",
                field: "Material Code",
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Classification",
                field: "Material Classification",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Type",
                field: "Material Type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Prediction Category",
                field: "Prediction Category",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Forecast Horizon (months)",
                field: "Forecast Horizon",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            // {
            //     headerName: "Best Model",
            //     field: "Best Model",
            //     width:140,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "January",
                field: "Period-1",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Febraury",
                field: "Period-2",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "March",
                field: "Period-3",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "April",
                field: "Period-4",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "May",
                field: "Period-5",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "June",
                field: "Period-6",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "July",
                field: "Period-7",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "August",
                field: "Period-8",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "September",
                field: "Period-9",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "October",
                field: "Period-10",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "November",
                field: "Period-11",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "December",
                field: "Period-12",
                width: 120,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Predicted Quantity",
                field: "Predicted Quantity",
                width: 180,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
        ]
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                fontWeight: 'bold',
                fontSize: "14px",
                color: "black" // Set font-weight to bold for selected options
            }),
            singleValue: (provided, state) => ({
                ...provided,
                fontSize: "14px",
                fontWeight: 'bold', // Always display the selected option in bold
                color: "black"
            }),
            control: (provided) => ({
                ...provided,
                fontWeight: 'bold',
                color: "black!important",
                border: "1px solid black"

            }),
        };
        var filteredScenarioData = []
        if (this.state.totalComponent === true) {
            filteredScenarioData = this.state.scenarioBasedForecastList
        }
        else if (this.state.predictionCompleteComponent === true) {
            filteredScenarioData = this.state.predictionCompletedForecastList
        } else {
            filteredScenarioData = this.state.yetToPredictForecastList
        }
        console.log(filteredScenarioData, "filteredScenarioData")
        console.log(this.state.transactionOutput, "haha")
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row mb-10p">
                    <div className="col-sm-3">
                        <h5 className='fbold f23' style={{ color: "black" }}>
                            Material Forecast
                            {/* <span className="float-right">
                                <a href="javascript:;"  onClick={this.openUploadOptionForAvailability} className="btn btn-info f12 mr-10p p-9p">Upload Actual Data</a>
                            </span> */}
                        </h5>
                    </div>

                    {/* {this.state.mainSelectedOption === "material" && (<div className="col-9">
                        {localStorage.getItem("role") == "admin" && (<button type="button" className="btn btn-success float-right font-white mr-10p" onClick={this.handleTriggerForecast}>Trigger Forecast</button>)}
                        <button type="button" className="btn btn-success float-right font-white mr-10p" onClick={this.onClickTrackPredictions}>Track Predictions</button>
                    </div>)} */}
                    {this.state.mainSelectedOption === "main_scenario" && (<div className="col-9">
                        <button type="button" className="btn btn-danger float-right font-white mr-10p" onClick={this.onClickScenarioGoBack}>Go Back</button>
                    </div>)}
                </div>
                {/* <div className="row mb-10p">
                    <div className="col-sm-2">
                        <input
                            type="radio"
                            value="material"
                            checked={this.state.mainSelectedOption === 'material'}
                            onChange={this.handleMainOptionChange}
                        />
                        <label className="col-form-label f19 fbold ml-5p" style={{ color: "black" }}>Material</label>
                    </div>
                    <div className="col-sm-2">
                        <input
                            type="radio"
                            value="main_scenario"
                            checked={this.state.mainSelectedOption === 'main_scenario'}
                            onChange={this.handleMainOptionChange}
                        />
                        <label className="col-form-label f19 fbold ml-5p" style={{ color: "black" }}>Scenario</label>
                    </div>

                </div> */}
                <div className="row">
                    <div className={"col-sm-12 mb-20p d-flex align-items-end"} style={{ paddingLeft: "0px" }} >
                        <>
                            {this.state.mainSelectedOption === "material" && (<><div className='col-sm-2'>
                                <label className="col-form-label f19 fbold" style={{ color: "black" }}>Material</label>
                                <MaterialList options={this.state.materiallist} updateMaterialCode={this.updateMaterialCode}></MaterialList>
                                {/* <LearningMaterialListForm options={this.state.materiallist} durationlist={this.state.durationlist}></LearningMaterialListForm>*/}
                            </div>
                                <div className="col-sm-2" style={{ marginTop: "0px" }}>
                                    <div className="form-group m-0p">
                                        <button type="button" onClick={this.loadPageData.bind(this)} className="btn btn-success m-0p">Apply</button>
                                    </div>
                                </div>
                            </>)}
                            {this.state.mainSelectedOption === "main_scenario" && (<>
                                <div className='col-sm-2'>
                                    <label className="col-form-label f19 fbold" style={{ color: "black" }}>Scenario</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.onChangeMainScenario}
                                        name="scenario"
                                        value={this.state.mainScenario}
                                        options={this.state.mainScenariosList}
                                        styles={customStyles}
                                    />
                                    {/* <LearningMaterialListForm options={this.state.materiallist} durationlist={this.state.durationlist}></LearningMaterialListForm>*/}
                                </div>
                                <div className="col-sm-2" style={{ marginTop: "0px" }}>
                                    <div className="form-group m-0p">
                                        <button type="button" onClick={this.mainScenarioData.bind(this)} className="btn btn-success m-0p">Apply</button>
                                    </div>
                                </div>
                            </>)}
                        </>
                        {this.state.mainSelectedOption === "material" && (<>
                            <div className="col-8" style={{padding: "0px"}}>
                            {localStorage.getItem("role") == "admin" && (<button type="button" className="btn btn-info float-right" onClick={this.predictionData.bind(this)}><i className="fa fa-download"></i> Prediction Data</button>)}
                                {localStorage.getItem("role") == "admin" && (<button type="button" className="btn btn-success float-right font-white mr-10p" onClick={this.handleTriggerForecast}>Trigger Forecast</button>)}
                                <button type="button" className="btn btn-success float-right font-white mr-10p" onClick={this.onClickTrackPredictions}>Track Predictions</button>
                            </div>
                            </>)}
                    </div>
                </div>
                {this.state.mainSelectedOption === "main_scenario" && (<div className="row">
                    <div className='col-sm-12 mb-10p p-0p'>

                        <div className={"col-md-4 bx-sh " + (this.state.totalComponent ? " border-color " : "")} onClick={this.handleShowDataTab.bind(this, 1)}>
                            <label className={"f17 cursorPointer fw-600 " + (this.state.totalComponent ? " num-color " : 'counter-header-color')}>Total Materials</label>
                            <div className="f28">
                                <span className={"fbold cursorPointer " + (this.state.totalComponent ? " num-color " : 'counter-header-color')}>{this.state.scenarioBasedForecastList.length}</span>
                            </div>
                        </div>
                        <div className={"col-md-4 bx-sh " + (this.state.predictionCompleteComponent ? " border-color " : "")} onClick={this.handleShowDataTab.bind(this, 2)}>
                            <label className={"f17 cursorPointer fw-600 " + (this.state.predictionCompleteComponent ? " num-color " : 'counter-header-color')}>Prediction Completed Materials</label>
                            <div className="f28">
                                <span className={"fbold cursorPointer " + (this.state.predictionCompleteComponent ? " num-color " : 'counter-header-color')}>{this.state.predictionCompletedForecastList.length}</span>
                            </div>
                        </div>
                        <div className={"col-md-4 bx-sh " + (this.state.yettoPredictComponent ? " border-color " : "")} onClick={this.handleShowDataTab.bind(this, 3)}>
                            <label className={"f17 cursorPointer fw-600 " + (this.state.yettoPredictComponent ? " num-color " : 'counter-header-color')}>Yet To Predict Materials</label>
                            <div className="f28">
                                <span className={"fbold cursorPointer " + (this.state.yettoPredictComponent ? " num-color " : 'counter-header-color')}>{this.state.yetToPredictForecastList.length}</span>
                            </div>
                        </div>
                    </div>
                </div>
                )}
                {this.state.mainSelectedOption === "main_scenario" && (<div className="row">
                    <div id="myGrid1" style={{ width: "100%", height: "70vh", fontSize: "13px", fontWeight: "500" }} className={"ag-theme-balham"}>
                        <AgGridReact
                            // modules={this.state.modules}
                            columnDefs={scenarioBasedColumnWithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={filteredScenarioData}
                            enableCharts={false}
                            onGridReady={this.onGridReady1}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus={true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this },
                                rowClass: 'your-row-class', // Set the row class for styling
                            }}
                            // components={this.state.components}
                            // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                            enableRangeSelection={true}
                            masterDetail={true}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowHoverHighlight={true}

                        />
                    </div>
                </div>
                )}
                {this.state.mainSelectedOption === "material" && (<div className={"row mb-20p"}>
                    {/* <div className="col-sm-12">
                        
                        <button type="button" className="btn btn-default float-right" onClick={this.onBtnExportF.bind(this)}>Export to CSV</button>
                        <button type="button" className="btn btn-default float-right" onClick={this.onBtExportExcelF.bind(this)}>Export to Excel</button>
                    </div> */}

                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid1" style={{ width: "100%", height: "70vh", fontSize: "13px", fontWeight: "500" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                // modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady1}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this },
                                    rowClass: 'your-row-class', // Set the row class for styling
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection={true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                rowClassRules={this.state.rowClassRules}
                                suppressRowHoverHighlight={true}

                            />
                        </div>
                    </div>

                </div>)}
                <div className={"overlay-block " + (this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 " + (this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                    {(this.state.sliderType == 1) ?
                        <form method="POST" onSubmit={this.onSubmitGetData} className="slider-z-index">
                            <div className="col-md-12">
                                <div className="form-group ">
                                    <label className="col-form-label f15 fw-600" style={{ color: "black" }}>Prediction Horizon</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.onChangeDuration}
                                        name="duration"
                                        value={this.state.duration}
                                        options={this.state.durationlist}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group ">
                                    <button type="submit" className="btn btn-success mt-30p">Predict Now</button>
                                </div>
                            </div>
                        </form>
                        : ""}
                    {(this.state.sliderType == 2) ?
                        <div className="col-md-12 slider-z-index">
                            <div className="form-group">
                                <label className="col-form-label f18">Code</label>
                                <h6 style={{ color: "#000" }}>{this.state.materialInfo[0].item_code}</h6>
                            </div>
                            <div className="form-group">
                                <label className="col-form-label f18">Description</label>
                                <h6 style={{ color: "#000" }}>{this.state.materialInfo[0].item_description}</h6>
                            </div>
                            <div className="form-group">
                                <label className="col-form-label f18">Cost</label>
                                <h6 style={{ color: "#000" }}>{this.state.materialInfo[0].item_cost}</h6>
                            </div>
                        </div>
                        : ""}
                    {(this.state.sliderType == 3) ?
                        <div>
                            <div className="col-sm-12 slider-z-index">
                                <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportG.bind(this)}>Export to CSV</button>
                                <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelG.bind(this)}>Export to Excel</button>
                            </div>
                            <div className={"col-sm-12"} >
                                <div id="myGrid" style={{ width: "100%", height: "70vh" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefsForTransactionOutput}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.transactionOutput}
                                        enableCharts={false}
                                        onGridReady={this.onGridReady2}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={""}
                                        sideBar={""}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        enableRangeSelection={true}
                                    />
                                </div>
                            </div>

                        </div>
                        : ""}
                    {(this.state.sliderType == 4) ?
                        <div className="slider-z-index">
                            <div className={"row mt-20p"}>
                                <div className={"col-sm-12 "} >
                                    &nbsp;
                                </div>
                            </div>
                            <div className={"row mb-20p"}>
                                {/* {(this.state.chartseries.length > 0)?
                            <div className={"mxheit col-sm-12 mt-20p "} >
                                <div className="card">
                                    <div className="card-body">
                                        
                                        <ColumnChart
                                        title={"FY wise Orders"}
                                        series={this.state.chartseries[0].overallfinancialorders}
                                        />
                                    
                                    </div>
                                </div>
                            </div> :""}
                            
                            {(this.state.chartseries.length > 0)?
                            <div className={"mxheit col-sm-12  mt-20p"} >
                                <div className="card">
                                    <div className="card-body">
                                        <ColumnChart
                                        title={"FY wise Costs"}
                                        series={this.state.chartseries[0].overallfinancialcost}
                                        />
                                    
                                    </div>
                                </div>
                            </div> :""} */}

                                {/* {(this.state.confidenceTrain.length > 0 && this.state.confidenceTestPoints.length > 0)? */}
                                <div className={"mxheit col-sm-12 mt-20p"}>
                                    <ConfidenceChart
                                        title={"Inventory Forecast"}
                                        subtitle={"Material Code: " + (this.state.chartItem.value)}
                                        xAxisTitle={""}
                                        yAxisTitle={"Inventory Quantity Consumption"}
                                        confLine={this.state.confidenceTrain}
                                        confLineTrend={this.state.confidenceTrend}
                                        confLinePredictTest={this.state.confidenceTestPoints}
                                        confLinePredictTrend={this.state.trend_predicted_points}
                                        confAreaData={this.state.confAreaData}
                                        confAreaYHat={this.state.confAreaYHat}
                                        confScatter={this.state.confScatter}
                                        dataType={1}
                                        foreCast_period={this.state.foreCast_period}
                                    // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                    // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                    />
                                </div>
                                <div className={"mxheit col-sm-12 mt-20p"}>
                                    <ConfidenceChart
                                        title={"Inventory Cost"}
                                        subtitle={"Material Code: " + (this.state.chartItem.value)}
                                        xAxisTitle={""}
                                        yAxisTitle={"Cost"}
                                        confLine={this.state.confidenceTrain}
                                        confLineTrend={this.state.confidenceTrend}
                                        confLinePredictTest={this.state.confidenceTestPoints}
                                        confLinePredictTrend={this.state.trend_predicted_points}
                                        confAreaData={this.state.confAreaData}
                                        confAreaYHat={this.state.confAreaYHat}
                                        confScatter={this.state.confScatter}
                                        trendingcost={this.state.trendingcost}
                                        dataType={2}
                                    // series={this.state.chartseries[0].allfymonthwisecost[1]}
                                    // predictseries={this.state.chartseries[0].allfymonthwisecost[0]}
                                    />
                                </div>
                                {/* :""} */}

                                {/* {( this.state.confidenceTrend.length > 0)? */}
                                <div className={"mxheit col-sm-12 mt-20p"}>
                                    <LineChart
                                        title={"Yearly Trend"}
                                        subtitle={"Material Code: " + (this.state.chartItem.value)}
                                        confLineTrend={this.state.confTrendplot}

                                    />
                                </div>
                                {/* :""} */}

                                {/* {( this.state.seasonality_train_plot !== undefined && this.state.seasonality_pred_plot !== "" && this.state.seasonality_pred_plot !== undefined)? */}
                                <div className={"mxheit col-sm-12 mt-20p"}>
                                    <div className="card">
                                        {/*
                                        <div className="card-header">
                                            <div style={{width:"36%",zIndex:"9"}}>
                                                <div className="row">
                                                    <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Select FY: </label>
                                                    <div className="col-sm-8">
                                                        <Select
                                                            value={this.state.fyChart}
                                                            options={this.fyitems()}
                                                            onChange={this.onChangeFYData.bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="card-body">
                                            <AreaRangeChart
                                                title={"Seasonality Data"}
                                                subtitle={"Material Code: " + (this.state.chartItem.value)}
                                                confLineSeasonal={this.state.confLineSeasonal}
                                            // predictseries={this.state.seasonality_pred_plot}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* :""} */}

                                <br /><br />
                            </div>
                        </div>
                        : ""}
                    {(this.state.sliderType == 5) ?
                        <div className="slider-z-index">
                            <div className={this.state.sliderbg === "show-m" && "sliderbg"}></div>
                            {this.state.loadshownew === "show-m" && (
                                <>
                                    <div class="new-loader" style={{ zIndex: "15000" }}>
                                        <div class="bar bar1"></div>
                                        <div class="bar bar2"></div>
                                        <div class="bar bar3"></div>
                                        <div class="bar bar4"></div>
                                        <div class="bar bar5"></div>
                                        <div class="bar bar6"></div>
                                        <div class="bar bar7"></div>
                                        <div class="bar bar8"></div>
                                    </div>
                                    <div className="col-12 fw-600 f20" style={{ color: "black", position: "absolute", top: "54%", left: "42%", zIndex: "9999" }}>
                                        <p>Loading...</p>
                                    </div>
                                </>
                            )}

                            {/* <button type="button" className="btn btn-info float-right" onClick={this.showOnlyPredictionData}><i className="fa fa-download"></i> Show Prediction Data</button> */}
                            <div className="row">
                                <div className="col-4 d-flex align-items-center">
                                    <input
                                        type="radio"
                                        value="scenario_type"
                                        checked={this.state.selectedOption === 'scenario_type'}
                                        onChange={this.handleOptionChange}
                                    />
                                    <label className="fw-600 f15 mt-10p ml-5p" style={{ color: "black" }}>
                                        Scenario Type
                                    </label>
                                </div>
                                <div className="col-4 d-flex align-items-center">
                                    <input
                                        type="radio"
                                        value="phase"
                                        checked={this.state.selectedOption === 'phase'}
                                        onChange={this.handleOptionChange}
                                    />
                                    <label className="fw-600 f15 mt-10p ml-5p" style={{ color: "black" }}>
                                        Phase
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-end">
                                <div className="col-4 mt-10p">
                                    {this.state.selectedOption === "scenario_type" && (
                                        <div className="form-group ">
                                            <label className="col-form-label f17 fw-600" style={{ color: "black" }}>Scenario</label>
                                            <Select
                                                placeholder={"Select"}
                                                onChange={this.onChangeScenario}
                                                name="scenario"
                                                value={this.state.scenario}
                                                options={this.state.completedScenariolist}
                                                styles={customStyles}
                                            />
                                        </div>
                                    )}
                                    {this.state.selectedOption === "phase" && (
                                        <div className="form-group">
                                            <label className="col-form-label f17 fw-600" style={{ color: "black" }}>Phase</label>
                                            <Select
                                                placeholder={"Select"}
                                                onChange={this.onChangePhase}
                                                options={this.state.phases}
                                                value={this.state.radioPhase}
                                                styles={customStyles}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="col-8 mb-5p">
                                    <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportH.bind(this)}>Export to CSV</button>
                                    <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelH.bind(this)}>Export to Excel</button>
                                </div>
                            </div>
                            <div className={"col-sm-12"} >
                                <div id="myGrid1" style={{ width: "100%", height: "70vh", fontWeight: "600", color: "black" }} className={"ag-theme-balham"}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefsForAllForecastData}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.forecastingAllData}
                                        enableCharts={false}
                                        onGridReady={this.onGridReady3}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={""}
                                        sideBar={""}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        enableRangeSelection={true}
                                        masterDetail={true}
                                    />
                                </div>
                            </div>

                        </div>
                        : ""}
                    {/* {(this.state.sliderType == 6)?
                    <div>
                        <button type="button" className="btn btn-info float-right" onClick={this.dowloadAllForecastingData.bind(this)}><i className="fa fa-download"></i> Show Prediction vs Actual Data</button>
                        <div className="col-md-4">
                        <div className="form-group ">
                            <label className="col-form-label f12">Scenario</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.onChangeScenario}
                                name="scenario"
                                value={this.state.scenario}
                                options={this.state.completedScenariolist}
                            />
                        </div>
                        </div>
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportH.bind(this)}>Export to CSV</button>
                            <button type="button" className="btn btn-secondary float-right" onClick={this.onBtExportExcelH.bind(this)}>Export to Excel</button>
                        </div>
                        <div className={"col-sm-12"} >
                            <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefsForAllForecastDataOnlyPredicted}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.forecastingAllData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady3}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={""}
                                    sideBar={""}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    enableRangeSelection= {true}
                                    />
                            </div>
                        </div>
                        
                    </div>
                    :""} */}

                    {(this.state.sliderType == 7) ?
                        <div className="col-md-12 slider-z-index">
                            <LearningMaterialListForm options={this.state.materiallist} durationlist={this.state.durationlist} scenarioAndItemCodes={this.state.scenarioAndItemCodes} bestModelsPhaseList={this.state.bestModelsPhaseList}></LearningMaterialListForm>
                            {/* <Mlist options={this.state.forecastitemslist} durationlist={this.state.durationlist}></Mlist> */}
                        </div>
                        : ""}

                    {(this.state.sliderType == 8) ?
                        <div className="col-sm-12 slider-z-index">
                            <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12">
                                        <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <button type="submit" id="bulkUploadBtn" className="btn btn-danger">SAVE</button>
                                        {/* <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button> */}
                                    </div>
                                    <div id="inValidDataInfo" style={{ color: "red" }}></div>
                                </div>
                            </form>
                        </div>
                        : ""}

                    {(this.state.sliderType == 9) ?
                        <>
                            <div className="col-md-4 slider-z-index">
                                <div className="form-group ">
                                    <label className="col-form-label f15 fw-600" style={{ color: "black" }}>Prediction Horizon</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.onChangeDuration}
                                        name="duration"
                                        value={this.state.duration_phase}
                                        options={this.state.durationlist}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group ">
                                    <label className="col-form-label f15 fw-600" style={{ color: "black" }}>Prediction Period</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.onChangePredictionPhase}
                                        name="phase"
                                        value={this.state.phase}
                                        options={this.state.phaseList}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <ComparisonCharts phase={this.state.phase.value} materialCode={this.state.material_code[0].value} period={this.state.duration_phase.value} />
                        </>
                        : ""}

                    {/* {(this.state.sliderType == 10)?
                    <div className="row p-20p">
                        <div className="col-sm-12">
                    
                        <button type="button" className="btn btn-info " onClick={this.dowloadAllForecastingData.bind(this)}><i className="fa fa-download"></i> Download Prediction vs Actual Data</button>
                        <button type="button" className="btn btn-info " onClick={this.showOnlyPredictionData}><i className="fa fa-download"></i> Download Prediction Data</button>
                    </div>
                    </div>
                        :""} */}
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                {/* <div className={"loader "+this.state.loadshow} style={{zIndex: "9999"}}></div> */}
                {this.state.loadshow === "show-m" && (
                    <>
                        <div class="new-loader" style={{ zIndex: "9999" }}>
                            <div class="bar bar1"></div>
                            <div class="bar bar2"></div>
                            <div class="bar bar3"></div>
                            <div class="bar bar4"></div>
                            <div class="bar bar5"></div>
                            <div class="bar bar6"></div>
                            <div class="bar bar7"></div>
                            <div class="bar bar8"></div>
                        </div>
                        <div className="col-12 fw-600 f20" style={{ color: "black", position: "absolute", top: "54%", left: "47%", zIndex: "9999" }}>
                            <p>Loading...</p>
                        </div>
                    </>
                )}
                {/* <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div> */}
            </div>
        )
    }
}

function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

function arr_diff(a1, a2) {
    var a = [], diff = [];
    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }
    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }
    for (var k in a) {
        diff.push(k);
    }
    return diff;
}