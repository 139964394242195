import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import CSVFileValidator from 'csv-file-validator'
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import  draggable from '../../../node_modules/jquery-ui/ui/widgets/draggable';
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
// import ViewTruckData from "./viewtruckaction";
// import ViewClusterRoute from "./viewclusterroute";
// import ViewRoute from "./viewroute";

import redirectURL from '../redirectURL';
var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class VehicleDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            frameworkComponents:{
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',
            csvfiledata:[],
            csvfile:'',    
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            showSlideBlockUploadParts:""
        }
        this.loadPlanData = this.loadPlanData.bind(this);
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        this.changeCSVFileHandler = this.changeCSVFileHandler.bind(this);
        
      }
    componentDidMount() {
        
        this.loadPlanData();
  
    }
    loadPlanData(){
        this.setState({
            showloader:"show-m"
        })
        redirectURL.post("/vehicles/vehicledetails")
        .then(async (response) => {
            var records = response.data;
            
            records = records.sort(GetSortAscOrder("vehicle_code"));
            this.setState({
                rowData:records,
                showloader:"show-n"
            })
            
        })
      }
      
      logout()
         {
            
               localStorage.removeItem('tn');
               localStorage.removeItem('s');
               localStorage.removeItem('sidebar');
               localStorage.clear();
               sessionStorage.removeItem('tn');
               sessionStorage.removeItem('s');
               sessionStorage.clear();
       
               sessionStorage.setItem('ref', null);
               //localStorage.setItem('menuText',[]);
               delete axios.defaults.headers.common['Authorization'];
               this.setState({
                   redirectToReferrer: ''
               });
         }
      
     
        hideSlideBlock(){
        this.setState({
            overlayBlock:"show-n",
            showSlideBlockUploadParts:""
        })
      }
     
    // changeFileHandler = async (e) => {
    //     console.log("e.target.files[0] ", e.target.files[0])
    //     this.setState({
    //         uploadfile:e.target.files[0]
    //     });
    // }
    
    // formUpload(event){
    //     event.preventDefault();
        
    // }


    onClickUploadDealerParts(){
        this.setState({
          showSlideBlockUploadParts:"slide25",
          overlayBlock:"show-m"
        })
    }
    
    
        
    changeCSVFileHandler = async (e) => {
        const config = {
            headers: [
                { 
                    name: 'Vehicle Code',
                    inputName: 'vehicle_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Vehicle Name',
                    inputName: 'vehicle_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Vehicle Type',
                    inputName: 'vehicle_type',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Length',
                    inputName: 'vehicle_length',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                 },
       
                { 
                    name: 'Width',
                    inputName: 'vehicle_width',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Height',
                    inputName: 'vehicle_height',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Volume',
                    inputName: 'vehicle_volume',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Max Volume',
                    inputName: 'vehicle_max_volume',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Weight',
                    inputName: 'vehicle_weight',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            this.setState({
                csvfiledata:csvData.data
            });
        })
        .catch(err => {})
            
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                console.log("contents ", contents )
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
            console.log("tt ",tt)
        });
        //console.log("Out ", out);
        
        this.setState({
            file:e.target.files[0]
        });
        
    }
    formCSVUpload(event)
      {
        event.preventDefault();
        this.setState({
          showloader:"show-m"
        })
        var csvfiledata = this.state.csvfiledata;
        // console.log("csvfiledata ", csvfiledata)
        var p = {
          filedata: csvfiledata
        }
        redirectURL.post("/vehicles/uploadVehicleData",p)
        .then((resp) => {
          // console.log("resp ", resp.data)
          window.location.reload();
        })
      }
    
    render() {
        if(localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined )
        {
          return <Navigate to={"/login"} push={true} />
        }
        var columnwithDefs = [
            
          
            {
                headerName:"Vehicle Code",
                field:"vehicle_code",
                width:"120",
            },
            {
                headerName:"Vehicle Name",
                field:"vehicle_name",
                width:"180",
            },
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"100",
            },
            {
                headerName:"Length",
                field:"vehicle_length",
            },
            {
                headerName:"Width",
                field:"vehicle_width",
                width:"100"
            },
            {
                headerName:"Height",
                field:"vehicle_height",
                width:"150"
            },
            {
                headerName:"Volume",
                field:"vehicle_volume",
                width:"100"
            },
            {
                headerName:"Max. Volume",
                field:"vehicle_max_volume",
                width:"150"
            },
            {
                headerName:"Weight",
                field:"vehicle_weight",
                width:"100"
            }
        ]
        var loadplan = this.state.loadplan;
        return (
            <div class="container-fluid">
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            <i className="fa fa-users"></i> {Constant.PAGE_TITLE_VEHICLE_DETAILS}
                            
                            <span className="float-right">
                                <a href="javascript:;" ref="Add Dealer" onClick={this.onClickUploadDealerParts.bind(this)} className="btn btn-warning f12 mr-10p">+ Upload Vehicle Data</a>
                            </span>
                            
                        </h5>
                    </div>
                </div>
                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>

                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock.bind(this)}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUploadParts)}>
                <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD_PARTS}</h5>
                <div className="row">
                    
                    <div className="col-sm-12">
                    <form onSubmit={this.formCSVUpload.bind(this)}>
                    <div className="row p-20p">
                        <div className="form-group col-sm-12 mb-20p">
                            <label style={{color:"#000"}}>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label>
                            
                            <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                        <button type="submit" className="btn btn-danger">SAVE</button>
                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                        </div>
                    </div>
                    </form>
                    </div>
                </div>
                </div>
               
            </div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function closeAllInfoWindows() {
    for (var i=0;i<infowindows.length;i++) {
        infowindows[i].close();
    }
}