import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";
import CSVFileValidator from 'csv-file-validator';


export default class TransporterDetails extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openTransporterDetailsModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:this.props.plantLocation,
            rowId:'',
            transporter_code:'',
            transporter_name:'',
            showSlideBlock:"",
            overlayBlock:"show-n",
            csvfiledata:[],
            bulkFileName:''
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path_type == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        
        await getToolConfiguration(params)
        await this.getTransporterMasterDetails(params)
    }


    openTransporterDetailsModel = () =>{
        this.setState(prevState =>({
            openTransporterDetailsModel :!prevState.openTransporterDetailsModel
        }))
    }

    handelTransporterDetailsModelCancel = ()=>{
        this.setState(prevState =>({
            openTransporterDetailsModel :!prevState.openTransporterDetailsModel,
            rowId:'',
            transporter_code:'',
            transporter_name:''
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    
    transporterDetailsInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value

        if(name == "transporter_code"){
            value = value.toUpperCase()
        }
        this.setState({ [name] : value })
    }

   
    saveTransporterMasterDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveTransporterMasterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getTransporterMasterDetails(param)
                this.setState({
                        openTransporterDetailsModel:false,
                        rowId:'',
                        transporter_code:'',
                        transporter_name:''
                    })
            }else{
                if(response.data.status == 'failed'){
                    this.setState({
                        show:true,
                        basicTitle:response.data.message,
                        basicType:"danger"
                    })
                }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
                }
            }
        })
    }

    updateTransporterMasterDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/updateTransporterMasterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getTransporterMasterDetails(param)
                this.setState({
                        openTransporterDetailsModel:false,
                        rowId:'',
                        transporter_code:'',
                        transporter_name:''
                    })
            }else{
                if(response.data.status == 'failed'){
                    this.setState({
                        show:true,
                        basicTitle:response.data.message,
                        basicType:"danger"
                    })
                }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
                }
            }
        })
    }


    formTransporterDetails = (e)=>{
        e.preventDefault();
        let data_type = this.state.data_type;
        let location = this.state.location;
        let rowId = this.state.rowId;
        let transporter_code = this.state.transporter_code
        let transporter_name = this.state.transporter_name

        let params = {
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            transporter_code : transporter_code,
            transporter_name: transporter_name
        }

        if (rowId != ''){
            params.row_id = rowId

            this.updateTransporterMasterDetails(params)
        }
        else{

            if(location.value != null){
                this.saveTransporterMasterDetails(params)
                
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
            }
        }
    }

    editTransporterDetails = async(propdata) =>{
        let data = propdata.data;
        
        await this.setState({
            "rowId" : data._id,
            "transporter_name" : data.transporter_name,
            "transporter_code" : data.transporter_code
        })
        this.openTransporterDetailsModel()
    }
    

    getTransporterMasterDetails = (params) =>{
        redirectURL.post('master/getTransporterMasterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
            this.setState({
                rowData:response.data.transporterDetailsData
            })
          }
        })
    }

    deleteTransporterMasterDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteTransporterMasterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getTransporterMasterDetails(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }

    openUploadOptionForTransporters = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlock:"slide25",
            overlayBlock:"show-m",
          })
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
        })
      }

      formCSVUpload = (event) =>
      {
        let location = this.state.location
        let data_type = this.state.data_type
        event.preventDefault();
        var csvfiledata = this.state.csvfiledata;
        var p = {
          filedata: csvfiledata,
          data_type:data_type,
          location_code : location.value,
          location_name : location.label,
        }
        redirectURL.post("/master/uploadTransportersMasterData",p)
        .then(async(resp) => {
          
          if(resp.data.status == 'success'){
            await this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"success",
                overlayBlock:"show-n",
                showSlideBlock:"",
            })
            window.location.reload();
            
          }else{
            this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"danger"
            })
          }
        })
      }


      changeCSVFileHandler = async (e) => {
        const config = {
            headers: [
                // { 
                //     name: 'Location Code',
                //     inputName: 'location_code',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                // { 
                //     name: 'Location Name',
                //     inputName: 'location_name',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                // { 
                //     name: 'Vehicle Type',
                //     inputName: 'vehicle_type',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                
                 { 
                    name: 'Transporter Code',
                    inputName: 'transporter_code',
                    required: true,
                    unique: true,
                    uniqueError: function (headerName) {
                        return `${headerName} is not unique`
                    },
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Transporter Name',
                    inputName: 'transporter_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                 },
                
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {

            document.getElementById("inValidDataInfo").innerHTML = "";
            if(csvData.inValidData.length >0){
                document.getElementById("bulkUploadBtn").type = "button"
                document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                let invalidData = csvData.inValidData;
                let element = document.getElementById("inValidDataInfo")
                invalidData.map(item =>{
                    let row,column
                    if(item.rowIndex == undefined){
                        row = 'NA'
                    }else{
                        row = item.rowIndex
                    }
                    if(item.columnIndex == undefined){
                        column = 'NA'
                    }else{
                        column = item.columnIndex
                    }
                    
                    element.innerHTML += "Column : "+column+","+" Row : " + row +"--"+ item.message +"<br></br>"
                })
                 
            }else{
                document.getElementById("bulkUploadBtn").type = "submit"
                document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                document.getElementById("bulkUploadBtn").classList.add("btn-danger");
                this.setState({
                    csvfiledata:csvData.data
                });
            }
        })
        .catch(err => {})
    }

    render(){

        var colsDefs = [
            // {
            //     headerName:Constant.FORM_VEHICLE_TYPES,
            //     field:"vehicle_type",
            //     width:"120"
            // },
            {
                headerName:"Transporter Code",
                field:"transporter_code",
                
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                
            },
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Edit',
                        onClickFunction:this.editTransporterDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteTransporterMasterDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
        ]

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let data = this.state.rowData
        let locValue = this.state.location.value
         
        let filteredData = data.filter(item => item.location_code == locValue)
        
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                
            }
        }

        var templatePath = require('../../assets/json/Transporters_input_data_template.csv');

        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12">
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Transporter Details</span>
                                <div className='float-right col-sm-3'>
                                    <a href="javascript:;" onClick={this.openTransporterDetailsModel} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.TRANSPORTER_MASTER_SCREEN_BUTTON_NAME}</a>
                                    <span className="float-right">
                                    <a href="javascript:;"  onClick={this.openUploadOptionForTransporters} className="btn btn-warning f12 mr-10p">{Constant.TRANSPORTER_MASTER_SCREEN_BULK_BUTTON_NAME}</a>
                                    </span>
                                </div> 

                        </h5>

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openTransporterDetailsModel} onClose={this.handelTransporterDetailsModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>Transaporter Master</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formTransporterDetails}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.TRANSPORTER_CODE}</label>
                                    <input type="text" 
                                        className= "forminp form-control"
                                        required 
                                        name="transporter_code" 
                                        value={this.state.transporter_code}
                                        onChange={this.transporterDetailsInputFields} 
                                        placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.TRANSPORTER_NAME}</label>
                                    <input type="text" 
                                        className= "forminp form-control"
                                        required 
                                        name="transporter_name" 
                                        value={this.state.transporter_name}
                                        onChange={this.transporterDetailsInputFields} 
                                        placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div>

                                               

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelTransporterDetailsModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                    </Modal>

                    <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                    <div className={"sliderBlock2 "+(this.state.showSlideBlock)}>
                    <div className="row">   
                    <div className="col-sm-12">
                    <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                    <div className="row p-20p">
                        <div className="form-group col-sm-12">
                            <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>                            
                        </div>
                        <div className="form-group col-sm-12 mb-20p">                            
                            <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                        <button type="submit" id="bulkUploadBtn" className="btn btn-danger">SAVE</button>
                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                        </div>
                        <div id="inValidDataInfo" style={{color:"red"}}></div>
                    </div>
                    </form>
                    </div>
                </div>
                </div>
            </>
        )
    }
}