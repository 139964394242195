
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

 
const ViewRoute = (props) => {
    
    const truckData = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickShowRouteOnMpa(props);
    };
  
    return (
        <div>
            <button className="label label-warning" onClick={truckData} style={{color:"#000",border:"0px"}}>
                <i className="fa fa-eye"></i>Route
            </button>
            
        </div>
    );
};

export default ViewRoute;
