import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";

export default class VehicleCityLevelCost extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:this.props.plantLocation,
            vehicle_type: '',
            vehicleTypeList: [],
            location_city:'',
            location_state:'',
            per_cmt_cost:0,
            per_km_cost:0,
            rowId:'',
            state_names:[],
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        // const {plantLocation} = this.props
        await this.setState({data_type : data_type,
        // location:plantLocation
    })
        let params = {
            data_type : data_type
        }

        await getToolConfiguration(params)
        await this.getVehiclesData(params)
        await this.getCityWiseVehicleCostData(params)
        await this.getStatesData()
    }

    getVehiclesData = (params) =>{
        let vehicleTypeList = this.state.vehicleTypeList
        redirectURL.post('master/getVehiclesData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                if(vehiclesData.length > 0){
                    vehiclesData.map(item =>{
                        vehicleTypeList.push({ value : item.vehicle_type,
                            label : item.vehicle_type })
                    })
                    await this.setState({
                        vehicleTypeList : vehicleTypeList,
                        vehicle_type : vehicleTypeList[0]
                    })
                }
        }
        })
    }

    getCityWiseVehicleCostData = (params) =>{
        redirectURL.post('master/getCityWiseVehicleCostData',params)
        .then(response =>{
            if(response.data.status == 'success'){

                this.setState({
                    rowData:response.data.cityWiseVehicleCostData
                })
            }
        })
    }

    getStatesData = () =>{
        redirectURL.post('master/getStatesData')
        .then(response =>{
            this.setState({
                state_names:response.data
            })
        })
    }

    //function names need to be updated
    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            location_city:'',
            per_cmt_cost:0,
            per_km_cost:0,
            vehicle_type: '',
            location_state:'',
            rowId:'',
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}

    onChangeStateNames(location_state){
		this.setState(
            { location_state },
            () => console.log(`Option selected:`, this.state.location_state)
        );
	}

    getStateNames =()=> {
		let items = [];    
		try{
			if(this.state.state_names.length > 0)
			{
				this.state.state_names.map((item) =>{
					items.push({"value":item.state,"label":item.state})
				})
			}
		}
		catch(e){
		}		
		return items;
	}
    
    materialDetailsInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    saveCityWiseVehicleCostDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveCityWiseVehicleCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getCityWiseVehicleCostData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        location_city:'',
                        per_cmt_cost:0,
                        per_km_cost:0,
                        vehicle_type: '',
                        location_state:'',
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    updateCityWiseVehicleCostDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/updateCityWiseVehicleCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getCityWiseVehicleCostData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        location_city:'',
                        per_cmt_cost:0,
                        per_km_cost:0,
                        vehicle_type: '',
                        location_state:'',
                        rowId:''
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    formMaterialDetails = (e)=>{
        e.preventDefault();
        let location_city = this.state.location_city;
        let location_state = this.state.location_state;
        let per_cmt_cost = this.state.per_cmt_cost;
        let per_km_cost = this.state.per_km_cost;
        let vehicle_type = this.state.vehicle_type;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let rowId = this.state.rowId;

        let params = {
            vehicle_type : vehicle_type.value,
            location_city : location_city,
            location_state : location_state.value,
            per_cmt_cost : parseInt(per_cmt_cost),
            per_km_cost : parseInt(per_km_cost),
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
        }

        if(rowId != ''){

            params.vehicleCost_id = rowId
            
                this.updateCityWiseVehicleCostDetails(params)
            

        }else{
            if(location.value != null && location_state.value != null && vehicle_type.value != null){
                this.saveCityWiseVehicleCostDetails(params)
            }else{

                if(location_state.value == null){
                    this.setState({
                        show:true,
                        basicTitle:"Please Select State",
                        basicType:"danger"
                    })
                }else if(vehicle_type.value == null){
                    this.setState({
                        show:true,
                        basicTitle:"Please Select Vehicle",
                        basicType:"danger"
                    })
                }
                else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
                }
            }
        }
    }

    editMaterialConfiguration = async(propdata) =>{
        let data = propdata.data
        await this.setState({
            "vehicle_type" : {label:data.vehicle_type,value:data.vehicle_type},
            "fixed_vehicle_cost" : data.fixed_vehicle_cost,
            "location_city" : data.location_city,
            "location_state" : {label:data.location_state, value:data.location_state},
            "per_cmt_cost" : data.per_cmt_cost,
            "per_km_cost" : data.per_km_cost,
            "rowId" : data.vehicleCost_id
        })
        this.openModelForMaterialConfiguration()
    }

    vehicleTypeChanged = (vehicle_type) =>{
        this.setState({
            vehicle_type : vehicle_type
        })
    }

    deleteCityWiseVehicleCostDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteCityWiseVehicleCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getCityWiseVehicleCostData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }


    render(){

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))

        var per_cmt_cost = 0;
        var per_km_cost = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                if(toolConfig[0].per_cmt_cost > 0){
                    per_cmt_cost = false
                }else{
                    per_cmt_cost = true
                }
                if(toolConfig[0].per_km_cost > 0){
                    per_km_cost = false
                }else{
                    per_km_cost = true
                }
            }
        }
        var colsDefs = [
            {
                headerName:Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_TYPE,
                field:"vehicle_type",
                width:"120"
            },
            {
                headerName:Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_CITY,
                field:"location_city",
                width:"130"
            },
            {
                headerName:Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_STATE,
                field:"location_state",
                width:"130"
            },
            {
                headerName:Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_CMT_COST,
                field:"per_cmt_cost",
                width:"130",
                hide:per_cmt_cost
            },
            {
                headerName:Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_KM_COST,
                field:"per_km_cost",
                width:"150",
                hide:per_km_cost
            },
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Edit',
                        onClickFunction:this.editMaterialConfiguration},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteCityWiseVehicleCostDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            
        ]

        
        let data = this.state.rowData
        let locValue = this.state.location.value
         
        let filteredData = data.filter(item => item.location_code == locValue)

        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12">
                                    
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_CITY_LEVEL_COST_HEADER}</span>
                                <div className='float-right'>
                                    <a href="javascript:;" onClick={this.openModelForMaterialConfiguration} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_CITY_LEVEL_COST_BUTTON}</a>
                                </div> 

                        </h5>
                                                   

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openMaterialConfigurationModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>Freight Details</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formMaterialDetails}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_TYPE}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.vehicleTypeChanged}
                                        name="vehicle_type"
                                        value={this.state.vehicle_type}
                                        options={this.state.vehicleTypeList} 
                                        />

                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_STATE} </label>
                                    <Select 
                                    placeholder={"Select "+Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_STATE}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeStateNames.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.location_state}
                                    options={this.getStateNames()} />  
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_CITY} </label>
                                    <input required type="text"  className="forminp form-control" id="location_city" name="location_city" value={this.state.location_city} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            {per_cmt_cost == false ?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_CMT_COST} </label>
                                    <input required type="number" min='0'  className="forminp form-control" id="per_cmt_cost" name="per_cmt_cost" value={this.state.per_cmt_cost} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                            :  ""  }

                            { per_km_cost == false ?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_KM_COST} </label>
                                    <input required type="number" min='0'  className="forminp form-control" id="per_km_cost" name="per_km_cost" value={this.state.per_km_cost} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                            :  ""  }
                           

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                    </Modal>
            </>
        )
    }
}