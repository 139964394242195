import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import Select from 'react-select';
import redirectURL from '../redirectURL';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import withRouter from "../withRouter";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import $ from 'jquery';

window['jQuery'] = window['$'] = $;
var moment = require("moment");

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showloader: "show-n",
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            errMsg: "",
            show: false,
            basicType: "",
            basicTitle: "",
            redirectAction: false
        }
    }
    componentDidMount() {
        this.setState({
            showloader: "show-n",
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        console.log(name)
        console.log(value)
        this.setState({ [name]: value });
      };

      handleChangePassword = (e) => {
        e.preventDefault();
        var { oldPassword, newPassword, confirmPassword } = this.state;
        var email = localStorage.getItem("email")
        var params = {
            oldPassword,
            newPassword,
            email
        }
        console.log(params)
        if (oldPassword != "" && newPassword != "" && confirmPassword != "") {
            if (newPassword.length >= 5){
            if (newPassword === confirmPassword) {
                redirectURL.post("forecast/changepassword", params)
                    .then((response) => {
                        console.log(response.data)
                        try {
                            if (response.data.status === "success"){
                                console.log("if")
                            this.setState({ 
                                show: true,
                                basicType: "success",
                                basicTitle: response.data.message,
                                showUserPort: false,
                                showNewPasswordInput: false,
                                oldPassword: "",
                                newPassword: "",
                                confirmPassword: "",
                                errMsg: "",
                                
                             });
                            }else if (response.data.status === "invalid"){
                                console.log("else if")
                                this.setState({ 
                                    show: true,
                                    basicType: "danger",
                                    basicTitle: response.data.message,
                                    errMsg: ""
                                 });
                                 
                            }else{
                                this.setState({ 
                                    show: true,
                                    basicType: "danger",
                                    basicTitle: "Password is not Updated"
                                 });
                            }
                        } catch (error) {
                            this.setState({ 
                                show: true,
                                basicType: "danger",
                                basicTitle: "Password is not Updated"
                             });
                        }
                    })
            }
            else {
                this.setState({ errMsg: "*Passwords do not match. Please make sure the new password and confirm password fields are the same.", showMsg: true })
            }
        }else{
            this.setState({errMsg: "*Password Should contain atleast five characters"})
        }
        }
        else {
            this.setState({ errMsg: "*All Fields Are Required", showMsg: true })
        }
    
        console.log(oldPassword, newPassword, confirmPassword)
        
    };
    closeAlert = () => {
        this.setState({
            show: false,
        });
        if (this.state.basicType === "success"){
        window.location.href = "/"
        }
    }

    render() {

        if (localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined) {
            return <Navigate to={"/login"} push={true} />
        }
        
        console.log(this.state.oldPassword)
        return (
            <div>
                <div className={'lodr ' + (this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="container mt-5">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-4">
                           
                            <div className="card bg-light " style={{borderRadius: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px"}}>
                                <div className="card-body">
                                    <h3 className="card-title text-center fbold mb-30p text-success" >Change Password</h3>
                                    <form onSubmit={this.handleChangePassword}>
                                        <div className="mb-3">
                                            <label htmlFor="oldpassword" className="form-label fbold">Old Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="oldpassword"
                                                name="oldPassword"
                                                value={this.state.oldPassword}
                                                onChange={this.handleChange}
                                                placeholder="Enter your email"
                                                required
                                                style={{borderRadius: "10px"}}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="newpassword" className="form-label fbold">New Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="newpassword"
                                                name="newPassword"
                                                value={this.state.newPassword}
                                                onChange={this.handleChange}
                                                placeholder="Enter your new password"
                                                required
                                                style={{borderRadius: "10px"}}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="confirmpassword" className="form-label fbold">Confirm New Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="confirmpassword"
                                                name="confirmPassword"
                                                value={this.state.confirmPassword}
                                                onChange={this.handleChange}
                                                placeholder="Confirm your new password"
                                                required
                                                style={{borderRadius: "10px"}}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-success" >Save</button>
                                        <p className="err-msg mt-10p">{this.state.errMsg}</p>
                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }


}

export default withRouter(ChangePassword);

