
var GoogleInfoWindow = (icon, headerTitle, rows,mode) => {
    //console.log("Props ", headerTitle)
    var imgis;
    var content = "<div>";
    if(icon == '')
    {
        imgis = "<img class='c-google-img' src='"+require("../../assets/images/dot.png")+"' />"
    }
    else{
        imgis = "<img class='c-google-img' src='"+icon+"' />";
    }
    content += "<h5 class='g-header'> "+imgis+" <span class='ml-10p'>"+headerTitle+"</span></h5>";
    if(mode == 'table')
    {
        content +=rows;
    }
    else{
        
        content +="<table class='table table-border'>";
        rows.map((item) => {
             content +="<tr><th>"+item.key+"</th><td class='fw400'>"+item.value+"</td></tr>"
        })
        content +="</table>";
    }
    content += "</div>";
    //console.log("Geo Infowindow content ", content)
    return content;
}


module.exports = GoogleInfoWindow;