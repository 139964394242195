import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import  draggable from '../../../node_modules/jquery-ui/ui/widgets/draggable';
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import ViewTruckData from "./viewtruckaction";
import ViewClusterRoute from "./viewclusterroute";
import ViewRoute from "./viewroute";
import ViewStackingData from "./viewstackingaction";
import ViewStack from "./viewstack";
// import BinPacking from "./packingComponent";

import redirectURL from '../redirectURL';
var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class LoadPlanSummary extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            frameworkComponents:{
                ViewTruckData:ViewTruckData,
                ViewRoute:ViewRoute,
                ViewClusterRoute:ViewClusterRoute,
                ViewStackingData:ViewStackingData,
                ViewStack:ViewStack
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[]
        }
        this.onClickShowUpload=this.onClickShowUpload.bind(this);
        this.hideSlideBlock = this.hideSlideBlock.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.maploadData = this.maploadData.bind(this);
        this.loadPlanData = this.loadPlanData.bind(this);
        this.handleChangeFilterDate = this.handleChangeFilterDate.bind(this);
		this.plotMultiColorPolyline = this.plotMultiColorPolyline.bind(this);
        this.extractDeliveryDates = this.extractDeliveryDates.bind(this);
        
        // window.dragIn = this.dragIn.bind(this);
        // window.dragOut = this.dragOut.bind(this);
        // window.updateMarkerPosition = this.updateMarkerPosition.bind(this);
        // window.generatePageMarkers = this.generatePageMarkers.bind(this);
        // window.generateMarkers = this.generateMarkers.bind(this);
        // window.getPixelPosition = this.getPixelPosition.bind(this);
        this.changeDeliveryDate = this.changeDeliveryDate.bind(this);
        this.changeCluster = this.changeCluster.bind(this);
        this.changeVehicleType = this.changeVehicleType.bind(this);
        this.onClickShowStackingData = this.onClickShowStackingData.bind(this);
      }
    componentDidMount() {
        
        this.loadPlanData();
  
    }
    loadPlanData(){
        redirectURL.post("/plants/list")
        .then((response) => {
          var records = response.data;
          this.setState({
            plants:records
          })
        });
        this.setState({
            showloader:"show-m"
        })
        redirectURL.post("/dealers/loadplan")
        .then(async (response) => {
            var records = response.data;
            // console.log("records ", records)
            /*
            // OLD CODE
            var deliverydates = [];
            records = records.sort(GetSortAscOrder("cluster"));
                
            var recordsarr = groupBy(records, rdata => rdata.cluster);
            // console.log("recordsarr ", recordsarr)
            var loaddata = [];
            var allLoadPlan = [];
            try{
                
              recordsarr.forEach((item,key) => {
                
                item = item.sort(GetSortAscOrder("truck_no"));
                var trucks = groupBy(item, rdata => rdata.dealer_code);
                // console.log("trucks ", trucks)
                trucks.forEach((t,k) => {
                    if(k == "DICVPLANT")
                    {
                        if(t.length > 0)
                        {
                            var  rw = {
                                cluster:key,
                                items:t
                            }
                            loaddata.push(rw);
                
                        }
                        
                    }
                    
                })
              })
            }
            catch(e){
    
            }
            // console.log("loaddata ", loaddata)
            var rowdata = [];
            if(loaddata.length > 0)
            {
                loaddata.map((item) => {
                    
                    var data = item.items;
                    
                    data = data.sort(GetSortAscOrder("sequence_no"));
                    data = data.sort(GetSortAscOrder("delivery_date"));
                    if(data.length > 0)
                    {
                        data.map((item) => {
                            rowdata.push(item);
                        })
                    }
                })
            }
            // console.log("rowdata ", rowdata)
            this.setState({
                loadplan:loaddata,
                allLoadPlan:records,
                rowData:rowdata,
                showloader:"show-n"
            })
            */
        //    NEW CODE
            // console.log("records ", records)
            
            records = records.sort(GetSortAscOrder("plan_day"));
            var loaddata = [];
            var allLoadPlan = [];
            try{

                var plandaygrp = groupBy(records, rdata => rdata.plan_day);
                // console.log("plandaygrp ", plandaygrp)
                plandaygrp.forEach((plangrp,pkey) => {
                    // console.log("pkey ", pkey)
                    var plangrp = plangrp.sort(GetSortAscOrder("cluster"));
                    var clustergrp = groupBy(plangrp, rdata => rdata.cluster);
                    // console.log("clustergrp ",clustergrp)
                    clustergrp.forEach((cluster,clusterkey) => {
                        cluster = cluster.sort(GetSortAscOrder("truck_nocluster"));
                        var dealergrp = groupBy(cluster, rdata => rdata.dealer_code);
                        dealergrp.forEach((dealer,dealerkey) => {
                            if(dealerkey == "DICVPLANT")
                            {
                                if(dealer.length > 0)
                                {
                                    var  rw = {
                                        cluster:clusterkey,
                                        items:dealer
                                    }
                                    loaddata.push(rw);
                        
                                }
                                
                            }
                        })
                    })
                    
                });

                var rowdata = [];
                if(loaddata.length > 0)
                {
                    loaddata.map((item) => {
                        
                        var data = item.items;
                        
                        data = data.sort(GetSortAscOrder("trip_no"));
                        data = data.sort(GetSortAscOrder("delivery_date"));
                        if(data.length > 0)
                        {
                            data.map((item) => {
                                rowdata.push(item);
                            })
                        }
                    })
                }
                // console.log("rowdata ", rowdata)
                this.setState({
                    loadplan:loaddata,
                    allLoadPlan:records,
                    rowData:rowdata,
                    showloader:"show-n"
                })
            }
            catch(e){

            }
        })
      }
      renderMap = () => {   
            
        loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing,geometry&callback=initMap");
            // loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
            window.initMap = this.initMap
        }
        renderMapCluster = () => {   
                
        loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing,geometry&callback=initMapCluster");
            // loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
            window.initMapCluster = this.initMapCluster
        }
      logout()
         {
            
               localStorage.removeItem('tn');
               localStorage.removeItem('s');
               localStorage.removeItem('sidebar');
               localStorage.clear();
               sessionStorage.removeItem('tn');
               sessionStorage.removeItem('s');
               sessionStorage.clear();
       
               sessionStorage.setItem('ref', null);
               //localStorage.setItem('menuText',[]);
               delete axios.defaults.headers.common['Authorization'];
               this.setState({
                   redirectToReferrer: ''
               });
         }
      
     
        hideSlideBlock(){
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
        })
      }
      initMap = async () => 
        {

		    var currentwindow;
            var arr=[];
            var arr1=[];
            var locInfo = this.props.locInfo;
            var arr = this.state.routeData;
            // console.log("coords",arr)
            if( arr == undefined)
            {
                var lt=28.4519751;
                var ln=77.0310713;
            }
          
            // console.log("arr ", arr)
            var routeinfo ;
            
            try{
                var mapOptions = {
                    zoom: 12,
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    streetViewControl: true,
                    rotateControl: true,
                    fullscreenControl: true,
                    labels:true,
                    mapTypeControlOptions: {
                        mapTypeIds: ['satellite', 'roadmap'],
                    },
                    center: new window.google.maps.LatLng(lt,ln),
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    styles: [
                    
                        {
                            "featureType": "administrative",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#444444"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 45
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                    ],
                };
                        
            }
            catch(e){
                var mapOptions = {
                    zoom: 13,
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    streetViewControl: true,
                    rotateControl: true,
                    fullscreenControl: true,
                    labels:true,
                    mapTypeControlOptions: {
                        mapTypeIds: ['satellite', 'roadmap'],
                    },
                    center: new window.google.maps.LatLng(28.4519751,77.0310713),
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    styles: [
                    
                        {
                            "featureType": "administrative",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#444444"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 45
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                    ],
                };
                        
            }
            try{
                map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
            }catch(e){}
            
            var bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(lt,ln));
                // Create our info window content
            var currentinfowindow = null;
            var line=new window.google.maps.Polyline(
            {
                map:map,
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [{
                        icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                strokeColor:'#ff8c52',
                                fillColor:'#ff8c52',
                                fillOpacity:1,
                                strokeWeight: 2
                        },
                        repeat:'100px',
                        path:[]
                    }]
            });
            // var colors = ["#21BC5C","#2152BC","#21B7BC","#8BBC21", "#BC4921","#BCA221"]
            var alphas = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL","AM","AN","AO","AP","AQ","AR","AS","AT","AU","AV","AW","AX","AY","AZ",
            "BA","BB","BC","BD","BE","BF","BG","BH","BI","BJ","BK","BL","BM","BN","BO","BP","BQ","BR","BS","BT","BU","BV","BW","BX","BY","BZ",
            "CA","CB","CC","CD","CE","CF","CG","CH","CI","CJ","CK","CL","CM","CN","CO","CP","CQ","CR","CS","CT","CU","CV","CW","CX","CY","CZ"]
            var alldata = [];
            if(arr == undefined){
    
            }
            else{
                var allplants=[]
                try{
                    if(arr.length > 0)
                    {
                        var wapoints = [];
                        var waypaths = [];
                        // console.log(arr,"arr")
                        await arr.map(function(er,clr){
                        
                        var datapoints = [];
                        var datapointslist = [];
                        datapointslist = er.items;
                        
                        if(datapointslist.length > 0)
                        {
                            var darr = datapointslist.filter(function (value, index, arr) {
                                //console.log("Vale ",value)
                                return value;
                            } );
                            if(darr.length < 26)
                            {
                                var ratio = 1;
                            }
                            else if(darr.length < 50)
                            {
                                var ratio = 2;
                            }
                            else if(darr.length < 100)
                            {
                                var ratio = 4;
                            }
                            
                            else if(darr.length < 200)
                            {
                                var ratio = 8;
                            }
                            else if(darr.length < 300)
                            {
                                var ratio = 12;
                            }
                            else if(darr.length < 400)
                            {
                                var ratio = 15;
                            }
                            else if(darr.length < 500)
                            {
                                var ratio = 20;
                            }
                            // else if(darr.length > 26 && darr.length <500)
                            // {
                            //     var ration = 200;
                            // }
                            
                            // else if(darr.length > 500 && darr.length <2000)
                            // {
                            //     var ration = 300;
                            // }
                            
                            // else if(darr.length > 2000 && darr.length <5000)
                            // {
                            //     var ration = 400;
                            // }
                            // else if(darr.length > 5000 && darr.length <15000)
                            // {
                            //     var ration = 500;
                            // }
                            else{
                                //  changed value 20 to 1 as per the 24th august 2022 change 
                                var ratio = 150;
                            }
                            //console.log("darr.length ", darr.length)
                            datapoints = darr.filter(function (value, index, darr) {
                                return (index % ratio == 0);
                            } );
                        }
                        
                        // console.log("datapoints ", datapoints)

                        //Hi VENKATESH based on waypoints we need to fran route linepath try this once.
            
                        var bounds = new window.google.maps.LatLngBounds();
                        var markers = [];
                                
                        //this.plotMultiColorPolyline(arr,map,bounds);
                        // var directionsService = new window.google.maps.DirectionsService;
                        // var directionsDisplay = new window.google.maps.DirectionsRenderer();
                        // directionsDisplay.setMap(map);
                        // var slant = datapoints[0];
                        // // console.log("slant.lat",slant.lat)
                        // // console.log("slant.lng",slant.lng)
                        // var startlng= new window.google.maps.LatLng( parseFloat(slant.lat),parseFloat(slant.lng));
                        // // var endlng= new window.google.maps.LatLng( parseFloat(datapoints[datapoints.length - 1].lat),parseFloat(datapoints[datapoints.length - 1].lng));
                        // var endlng= "";
                        // var waypts = [];
                        // datapoints.map(function(e,index){
                        //     // console.log(e)
                        //     if(e.type == "PLANT")
                        //     {
                        //         allplants.push(e);
                        //     }
                        //     // console.log("eitem lat ", e.lat)
                        //     // console.log("eitem lng ", e.lng)
                        //     var latLng = new window.google.maps.LatLng( parseFloat(e.lat),parseFloat(e.lng));
                        //     bounds.extend(latLng);
                            
                        //     if(e.type != "PLANT")
                        //     {
                        //         waypts.push({
                        //             location: latLng,
                        //             stopover: true
                        //         });
                        //         if(index == (datapoints.length - 1))
                        //         {
                        //             // console.log("e.lat",e.lat)
                        //             // console.log("e.lng",e.lng)
                        //             endlng = new window.google.maps.LatLng(parseFloat(e.lat),parseFloat(e.lng));
                        //         }
                
                                
                        //         addMarker(latLng,(alphas[index]),e);
                        //     }
                        // });

                        var directionsService = new window.google.maps.DirectionsService;
                        var directionsDisplay = new window.google.maps.DirectionsRenderer();
                        directionsDisplay.setMap(map);
                        var slant = datapoints[0];
                        // console.log("slant.lat",slant.lat)
                        // console.log("slant.lng",slant.lng)
                        var startlng= new window.google.maps.LatLng( parseFloat(slant.lat),parseFloat(slant.lng));
                        var endlng= "";
                        var waypts = [];
                        datapoints.map(function(e,index){
                            if(e.type == "PLANT")
                            {
                                allplants.push(e);
                            }
                            // console.log("eitem lat ", e.lat)
                            // console.log("eitem lng ", e.lng)
                            var latLng = new window.google.maps.LatLng( parseFloat(e.lat),parseFloat(e.lng));
                            waypts.push({
                                location: latLng,
                                stopover: true
                            });
                            if(index == (datapoints.length - 1))
                            {
                                // console.log("e.lat",e.lat)
                                // console.log("e.lng",e.lng)
                                endlng = new window.google.maps.LatLng(parseFloat(e.lat),parseFloat(e.lng));
                            }
            
                            bounds.extend(latLng);
                            if(index == 0)
                            {
                                addMarker(latLng,alphas[index],e);
                            }
                            else{
                                addMarker(latLng,index,e);
                            }
                            
                        });
                        
                        if (waypts.length > 0)
                        {
                            waypts.pop();
                        }
                        // console.log("waypts ", waypts)
                        // console.log("startlng ", startlng)
                        // console.log("endlng ", endlng)
                        var ff = {
                            origin: startlng,
                            destination: endlng,
                            waypoints: waypts,
                            travelMode: 'DRIVING'
                        };
                        var colors=generateRandomColor();
                        // console.log("colors FFF ", colors)
                        directionsService.route({
                            origin: startlng,
                            destination: endlng,
                            waypoints: waypts,
                            travelMode: 'DRIVING'
                        }, function(response, status) {
                            // console.log("status ", status)
                            // console.log("response status ", response)
                            // OVER_QUERY_LIMIT
                            if (status === 'OK') {
                                const route = response.routes[0];
                                alldata.push(response);
                                var routepathss = route.overview_path;
                                for(var r=0;r<routepathss.length;r++)
                                {
                                    // console.log("asasadsadsadsdasd ",routepathss[r].lat())
                                    waypaths.push({
                                        // item:e,
                                        lat:routepathss[r].lat(),
                                        lng:routepathss[r].lng(),
                                        color:colors
                                    })
                                }
                            var summaryPanel = "";

                            // For each route, display summary information.
                            for (let i = 0; i < route.legs.length; i++) {
                                var rw = {
                                    lat:route.legs[i].steps[0].lat_lngs[0].lat(),
                                    lng:route.legs[i].steps[0].lat_lngs[0].lng(),
                                    distance:route.legs[i].distance,
                                    duration:route.legs[i].duration,
                                    start_address:route.legs[i].start_address,
                                    end_address:route.legs[i].end_address,
                                    start_location:route.legs[i].start_location,
                                    end_location:route.legs[i].end_location,
                                    label:alphas[i]
                                }
                                
                                wapoints.push(rw)
                            }
                            // if(status == window.google.maps.GeocoderStatus.OVER_QUERY_LIMIT)
                            // {
                            //     setTimeout(3000);
                            // }
                            // else{

                                directionsDisplay.setDirections(response);
                                directionsDisplay.setOptions({
                                    polylineOptions: {
                                    strokeColor: colors,
                                    icons: [{
                                        icon: {
                                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                strokeColor:'#ff8c52',
                                                fillColor:'#ff8c52',
                                                fillOpacity:1,
                                                strokeWeight: 2
                                        },
                                        repeat:'100px',
                                        path:[]
                                    }]
                                    }
                                });

                                
                                // console.log("summaryPanel ", summaryPanel)

                            // }

                            
                        // var totalDistance = 0;
                        // var totalDuration = 0;
                        // var legs = response.routes[0].legs;

                        //     for(var i=0; i<legs.length; ++i) {
                        //         totalDistance += legs[i].distance.value;
                        //         totalDuration += legs[i].duration.value;
                        //     }
                        
                                            
                        // $('.ttlDuration').html("  Total Duration : " + Math.round(totalDuration/60/60,0) + " Hrs");
            
                        for (var x=0;x < markers.length;x++)
                        {
                            markers[x].set('labelContent', x+1);
                            // console.log("markers[x] ", markers[x])
                        }
                        
                        // var route = response.routes[0];
                        } else {
                            window.alert('Directions request failed due to ' + status);
                        }
                    });


                    var mapIcon = require('../../assets/images/truck_3_32.png');              
                    function addMarker(location,lbl,item) {
                        // console.log("lbl" , lbl)
                        var marker = new window.google.maps.Marker({
                            position: location,
                            label:lbl,
                            icon: mapIcon,
                            map: map
                        });
                        
                        markers.push(marker);
                        if(item.type == "PLANT"){
                            var locInfo = "<h5 style='color:#082e19;padding:10px;'>"+item.dealer_name+"</h5><p style='margin-top:5px;margin-bottom:5px;padding:0 10px;font-weight:bold;'>Location: <b>"+item.location+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Start Time: <b>"+item.vehicle_start_time+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Start Time: <b>"+item.vehicle_end_time+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Distance (kms): <b>"+item.distance+"</b></p>";
                        }
                        else{
                            var locInfo = "<h5 style='color:#082e19;padding:10px;'>"+item.dealer_name+"</h5><p style='margin-bottom:5px;margin-top:5px;padding:0 10px;font-weight:bold;'>Location: <b>"+item.location+"here</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Start Time: <b>"+item.vehicle_start_time+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Start Time: <b>"+item.vehicle_end_time+"</b></p>";
                            locInfo += "<p style='margin-bottom:5px;padding:0 10px;font-weight:bold;'>Distance (kms): <b>"+item.distance+"</b></p>";
                        }
                        var infowindow = new window.google.maps.InfoWindow({
                            //content: this.props.locInfo,
                            content: locInfo,
                            maxWidth: 350
                        });
            
                        marker.addListener('click', function() {
                            infowindow.open(map, marker);
                        });
                        //oms.addMarker(marker);
                    }
                    })
                    setTimeout(()=>{
                    // this.plotMultiColorPolyline(waypaths,map,wapoints);
                    },2000)
                    
                //   console.log("waypaths ", waypaths)
                //   console.log("waypoints ", wapoints)
        
                
                }

                // console.log("allplants ", allplants)
                var infowindow = new window.google.maps.InfoWindow();
                if(allplants.length > 0)
                {
                    var markers= []
    		  	for (let i = 0; i < allplants.length; i++) {
				  //if(arr[i].speed > 0)
				 // {
                    var markerLatlng = new window.google.maps.LatLng(parseFloat(allplants[i].lat), parseFloat(allplants[i].lng));
                    var image =  require('../../assets/images/business.png');

                    var mark = new window.google.maps.Marker({
                        position: markerLatlng,
                        map: map,
                        title:allplants[i].dealer_name,
                        icon: image
					});
					markers.push(mark)
                    mark.addListener('click', function() {
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "PLANT"
						contentarr.push({"key":"Plant Name", "value":allplants[i].dealer_name});
						var contentString = infoBox(mark.icon, header, contentarr,'')
							
						infowindow.setContent(contentString);	
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, mark);
					  });
                    }
                }

            }
            catch(e){

            }
        }
    }


    initMapCluster = () => 
    {
        var currentwindow;
        
        var mapinfo = this.state.clusterpoints;
        var lt=17.432143; 
        var ln=78.374427;
        
        this.setState({
            dealerCode:[]
        });
        var mapOptions = {
            zoom: 11,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels:true,
            mapTypeControlOptions: {
                mapTypeIds: ['satellite', 'roadmap'],
            },
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            styles: [
                    
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
            ],
        };
        try{
            mapcluster = new window.google.maps.Map(document.getElementById('mapCluster'), mapOptions);
        }catch(e){}
        var infowindow = new window.google.maps.InfoWindow();
                
        var bounds = new window.google.maps.LatLngBounds();
        // this.maploadData();
        
        var markers = this.state.clusterpoints.map((marker) => {
            if(marker.type == "SUPPLIER")
            {
                var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.lat), parseFloat(marker.lng));
                    bounds.extend(new window.google.maps.LatLng(parseFloat(marker.lat), parseFloat(marker.lng)));

                var mark = new window.google.maps.Marker({
                    position: markerLatlng,
                    map: mapcluster,
                    title:marker.dealer_name,
                    icon: {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 8.5,
                        fillColor: "#0A18EC",
                        fillOpacity: 0.4,
                        strokeWeight: 0.4
                    },
                });
                mark.addListener('click', function() {
                    var contentarr = []
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var header = (marker.type == "PLANT")?"PLANT":"SUPPLIER";
                    contentarr.push({"key":"Name", "value":marker.dealer_name});
                    contentarr.push({"key":"Loaction", "value":marker.location});
                    var contentString = infoBox("", header, contentarr,'')
                        
                    infowindow.setContent(contentString);	
                    infowindow.setContent(contentString);
                    currentwindow = infowindow;
                    
                    infowindows.push(infowindow);
                    infowindow.open(map, mark);
                });
            }
            else{
                var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.lat), parseFloat(marker.lng));
                var image =  require('../../assets/images/business.png');

                var mark = new window.google.maps.Marker({
                    position: markerLatlng,
                    map: map,
                    title:marker.dealer_name,
                    icon: image
                });
                // markers.push(mark)
                mark.addListener('click', function() {
                    var contentarr = []
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var header = "PLANT"
                    contentarr.push({"key":"Plant Name", "value":marker.dealer_name});
                    var contentString = infoBox(mark.icon, header, contentarr,'')
                        
                    infowindow.setContent(contentString);	
                    infowindow.setContent(contentString);
                    currentwindow = infowindow;
                    
                    infowindows.push(infowindow);
                    infowindow.open(map, mark);
                });
            }
            mark.setMap(mapcluster);
        })
        
        
        var v = mapcluster.getZoom();
        // console.log("VVV ", v)
        mapcluster.setZoom(v)
        mapcluster.fitBounds(bounds)

        overlay = new window.google.maps.OverlayView();
        overlay.draw = function() {};
        overlay.setMap(mapcluster);
		// map.fitBounds(bounds)
	 
        window.google.maps.event.addListener(mapcluster, 'click', (function() {
            closeAllInfoWindows()
        }))
    }

    maploadData(){
        var currentwindow;
        for (let i = 0; i < markersArraylist.length; i++) {
            markersArraylist[i].setMap(null);
        }
        var mapinfo = this.state.loadplan;
        
        var allplants=[];
        var bounds = new window.google.maps.LatLngBounds();
        if(mapinfo.length > 0)
        {    
            // console.log("mapinfo ", mapinfo)
            mapinfo.map((plot) => {
                   if(plot.type == "PLANT")
                    {
                        allplants.push(plot)
                    }
                    bounds.extend(new window.google.maps.LatLng(parseFloat(plot.lat),parseFloat(plot.lng)));
                    
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(parseFloat(plot.lat),parseFloat(plot.lng)),
                        sName: plot.dealer_name,
                        map: map,
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 8.5,
                            fillColor: "#0A18EC",
                            fillOpacity: 0.4,
                            strokeWeight: 0.4
                        },
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            //	console.log(clusters[0])
                            var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = plot.dealer_name
                        contentarr.push({"key":"Vehicle Name", "value":plot.vehicle_name})
                        contentarr.push({"key":"Vehicle Type", "value":plot.vehicle_type})
                        contentarr.push({"key":"Capacity", "value":plot.vehicle_capacity})
                        contentarr.push({"key":"Weight", "value":plot.vehicle_weight})
                        contentarr.push({"key":"Part No", "value":plot.part_no})
                        contentarr.push({"key":"Part Name", "value":plot.part_name})
                        contentarr.push({"key":"Part Quantity", "value":plot.part_qty})
                        contentarr.push({"key":"Part Volume", "value":plot.part_volume})
                        contentarr.push({"key":"Part Weight", "value":plot.part_weight})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        
                        infowindows.push(infowindow);
                        // currentwindow = infowindow;
                        infowindow.open(map, marker);
                        //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker));
                
            
            })         
            // 
            try{
                var infowindow = new window.google.maps.InfoWindow();
                if(allplants.length > 0)
                {
                    var markers= []
                    for (let i = 0; i < allplants.length; i++) {
                //if(arr[i].speed > 0)
                // {
                        var markerLatlng = new window.google.maps.LatLng(parseFloat(allplants[i].lat), parseFloat(allplants[i].lng));
                        var image =  require('../../assets/images/business.png');

                        var mark = new window.google.maps.Marker({
                            position: markerLatlng,
                            map: map,
                            title:allplants[i].dealer_name,
                            icon: image
                        });
                        markers.push(mark)
                        mark.addListener('click', function() {
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "PLANT"
                            contentarr.push({"key":"Plant Name", "value":allplants[i].dealer_name});
                            var contentString = infoBox(mark.icon, header, contentarr,'')
                                
                            infowindow.setContent(contentString);	
                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            
                            infowindows.push(infowindow);
                            infowindow.open(map, mark);
                        });
                    }
                }
                
            }catch(e){}


        }
            
        var v = map.getZoom();
        // console.log("VVV ", v)
        map.setZoom(v)
        map.fitBounds(bounds)
    }

    plotMultiColorPolyline(arr2,map,waypoints, baseroute=false)
    {
        // console.log("waypoints ", waypoints)
        var lt = arr2[0].lat;
        var ln = arr2[0].lng;
        var mapOptions = {
            zoom: 13,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels:true,
            mapTypeControlOptions: {
                mapTypeIds: ['satellite', 'roadmap'],
            },
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP
        };
                
        
        
        try{
            map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        }catch(e){}
        // console.log("arr2 ", arr2)
        
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(lt,ln));

        var icon_cnt = 1;
        var icon_divisible = 1;
        
        for(var i=0;i< arr2.length-1;i++)
        {
            var locationLatLng = [];
            locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
            locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
            if(icon_cnt % icon_divisible == 0)
            {
                var lineicon = [{
                            // icon: {
                            //         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            //         strokeColor:'#FF0000',
                            //         fillColor:'#FF0000',
                            //         fillOpacity:1,
                            //         strokeWeight: 2
                            // },
                            repeat:'100px',
                            path:[]
                        }];
            }else{lineicon=[]}
            icon_cnt = icon_cnt + 1
            var consignments_missing_route_line = new window.google.maps.Polyline({
                map:map,
                path : locationLatLng,
                strokeColor: arr2[i].color,
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: lineicon
            });
            
            bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
        }
        var marker;
        for(var w=0;w< waypoints.length-1;w++)
        {
            marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(waypoints[w].lat, waypoints[w].lng),
                icon:require('../../assets/images/pin.png'),
                map: map,	
                label:waypoints[w].label,
            });
            window.google.maps.event.addListener(marker, 'click', (function(marker) {
                return function() {
                }
            })(marker));
            
            bounds.extend(new window.google.maps.LatLng(waypoints[w].lat,waypoints[w].lng));
        }
    }
        onClickShowUpload(){
            this.setState({
                showSlideBlockUpload:"slide25",
                overlayBlock:"show-m"
              })
        }
    
    changeFileHandler = async (e) => {
        console.log("e.target.files[0] ", e.target.files[0])
        this.setState({
            uploadfile:e.target.files[0]
        });
    }
    
    formUpload(event){
        event.preventDefault();
        
        this.setState({
            showloader:"show-m"
        })
        var reqparams = new FormData()
        reqparams.append("file", this.state.uploadfile)
        // console.log("reqparams ", reqparams);
        redirectURL.post("/dealers/readXLSData",reqparams)
        .then((response) => {
          // console.log(response.data)
          var uploadxlsdata = response.data.records;
          console.log("uploadxlsdata ", uploadxlsdata)
          if(uploadxlsdata.length > 0)
          {
            var p = {
              xlsdata:uploadxlsdata
            }
            redirectURL.post("/dealers/uploadLoadDealersPlan",p)
            .then((resp) => {
              console.log(resp.data)
              if(resp.data.message == "success")
              {
                redirectURL.post("/dealers/uploadFiletoS3",reqparams)
                .then((reps3) => {
                })
                this.setState({
                  uploadfile:"",
                  showSlideBlockUpload:"",
                  overlayBlock:"show-n",
                  show:true,
                  basicTitle:"Successfully upload data",
                  basicType:"success",
                  showloader:"show-n"
                });
                window.location.reload();
              }
              else{
                this.setState({
                  show:true,
                  basicTitle:"Failed to upload data",
                  basicType:"danger",
                  showloader:"show-n"
                });
              }
            })
          }
        })
    }

    extractDeliveryDates(){
        var allLoadPlan = this.state.allLoadPlan;
        // console.log("allLoadPlan ", allLoadPlan)
        if(allLoadPlan.length > 0)
        {
            var deliverdates = [];
            var deliveryopts = [];
            allLoadPlan.map((i,k) => {
                var r = {
                    delivery_date:i.delivery_date,
                    delivery_dateformat:i.delivery_dateformat
                }

                deliveryopts.push({
                    value:i.delivery_date,
                    label:i.delivery_dateformat
                })
                deliverdates.push(r)
            })
            this.setState({
                deliverydates:deliverdates,
                deliveryopts:deliveryopts
            })
            // window.generatePageMarkers()
        }
    }
    changeDeliveryDate = (filterDeliveryDate) =>{
        this.setState({filterDeliveryDate},() => {
            // console.log(filterDeliveryDate)
            var p ={
                delivery_date:this.state.filterDeliveryDate.value
            }
            redirectURL.post("/dealers/clusterbydate",p)
            .then((response) => {
                var records = response.data.records;
                // console.log("records ,",records)
                var clusterlist = [];
                if(records.length > 0)
                {
                    records.map((itm) => {
                        clusterlist.push({
                            value:itm,
                            label:"Cluster "+itm
                        })
                    })
                }
                this.setState({
                    clusterlist:clusterlist
                })
            })
        })
    }
    changeCluster = (filtercluster) => {
        this.setState({filtercluster},() => {
            // console.log("filtercluster ",filtercluster)

        var p ={
            cluster:this.state.filtercluster.value,
            delivery_date:this.state.filterDeliveryDate.value
        }
        redirectURL.post("/dealers/vehicletypesbycluster",p)
        .then((response) => {
            var records = response.data.records;
            // console.log("records ,",records)
            var vehicletypelist = [];
            vehicletypelist.push({
                value:"",
                label:"All"
            })
            if(records.length > 0)
            {
                records.map((itm) => {
                    vehicletypelist.push({
                        value:itm,
                        label:itm
                    });
                })
            }
            this.setState({
                vehicletypelist:vehicletypelist,
            })
        })
            
        });
    }
    changeVehicleType = (filterVehicleType) => {
        this.setState({filterVehicleType},()=>{
         
    
        });
        
    }
    changeDealers = (filterdealer) => {
        this.setState({filterdealer});
    }

    onClickUpdateMap(){
        this.setState({
            routeData:[],
            showloader:"show-m"
        })
        var params = {
            delivery_date:this.state.filter_date,
            cluster:this.state.selectedCluster,
            truck_no:this.state.filterVehicleType.value,
            dealer_code:this.state.filterdealer.value
        }
        // console.log("params ", params);
        redirectURL.post("/dealers/filterloadplan",params)
        .then(async (response) => {
            var records = response.data;
            // console.log("records ", records)
            var deliverydates = [];
            records = records.sort(GetSortAscOrder("cluster"));
                
            // var recordsarr = groupBy(records, rdata => rdata.cluster);
            // console.log("recordsarr ", recordsarr)
            var loaddata = [];
            var allLoadPlan = [];
            try{
                var sn=0;
            //   recordsarr.forEach((items,key) => {
                    
                records = records.sort(GetSortAscOrder("sequence_no"));
                
                    var trucks = groupBy(records, rdata => rdata.truck_no);
                    // console.log("trucks",trucks);
                    var sn=0;
                    trucks.forEach((ti,k) => {

    
                    ti = ti.sort(GetSortAscOrder("sequence_no"));
                        if(sn < 10)
                        {
                      
                            var rw1 = {
                                truck_no:k,
                                items: ti,
                                // distance:distance.toFixed(2),
                                // capacity:capacity.toFixed(2)
                            }
                            allLoadPlan.push(rw1);
                            loaddata.push(rw1);
                            
                        // })
                        }
                        sn=sn+1;
                    })
                
                
                
            //   })
            }
            catch(e){
    
            }
            // console.log("loaddata ", loaddata)
            // loaddata = loaddata.sort(GetSortAscOrder("sequence_no"));
            this.setState({
                routeData:loaddata,
                showloader:"show-n"
            });
            this.renderMap();
        })
    }
    onClickShowRoute(cluster){
        this.setState({
            showloader:"show-m"
        })
        // console.log("cluster ", cluster)
        var routeData =[];
        var alldata = this.state.allLoadPlan;
        var vtypes = [];
        var rowdata=[];
        if(alldata.length > 0)
        {
            // console.log("alldata ", alldata)
            alldata.map((item) => {
                if(item.cluster == cluster)
                {
                    // if(item.items.length > 0)
                    // {
                    //     item.items.map((im) => {
                    //         vtypes.push({"vehicle_type":im.vehicle_type})
                    //     })
                    // }
                    rowdata.push(item);
                }
            })
        }

        // console.log("rowdata ", rowdata)
        if(rowdata.length > 0)
        {
            rowdata.map((item) => {
                var data = item.items.sort(GetSortAscOrder("sequence_no"));
              
                        var trucks = groupBy(data, rdata => rdata.truck_no);
                        // console.log("trucks",trucks);
                        var sn =0;
                        trucks.forEach((ti,k) => {
                            if(sn < 10)
                            {
                                ti = ti.sort(GetSortAscOrder("sequence_no"));
                               
                                // var sqs = groupBy(t1, rdata => rdata.sequence_no);
                                // console.log("sqs",sqs) 
                                var rw1 = {
                                    cluster:cluster,
                                    items: ti,
                                }
                                routeData.push(rw1)
                            }
                            sn = sn+1;
                            
                        });
                    
            })
        }
        
        this.setState({
            routeData:routeData,
            mapcol:"col-sm-7",
            selectedCluster:cluster,
            gridocl:"col-sm-5",
            filter_date:"",
            filterVehicleType:{"value":"","label":"All"},
            filterdealer:{"value":"","label":"All"},
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            showloader:"show-n"
        })
        $("#filter_date").val("");

        this.renderMap();
    }
    onClickCloseMap(){
        this.setState({
            routeData:[],
            mapcol:"show-n",
            gridocl:"col-sm-12"
        })
    }
    handleChangeFilterDate(date) {
        // console.log("date ", date)
        if(date != "")
        {
            var dd = new Date(date).toString();
            // console.log('data ',moment.parseZone(dd).format("HH:mm"))
            var dt = moment.parseZone(dd).format("DD-MM-YYYY");
        // console.log("dt ", dt);
            $("#filter_date").val(dt);
            var d = dt.split("-");
            if(d[0].length == 4)
            {
                var dte = d[0]+""+d[1]+""+d[2];
            }
            else{
                var dte = d[2]+""+d[1]+""+d[0];
            }
            var p ={
                cluster:this.state.selectedCluster,
                delivery_date:dte
            }
            // console.log("asdsadasda ", dte)
            redirectURL.post("/dealers/trucksbycluster",p)
            .then((response) => {
                var records = response.data.records;
                // console.log("records ,",records)
                var vehicletypelist = [];
                vehicletypelist.push({
                    value:"",
                    label:"All"
                })
                if(records.length > 0)
                {
                    records.map((itm) => {
                        vehicletypelist.push({
                            value:itm,
                            label:itm
                        })
                    })
                }
                this.setState({
                    vehicletypelist:vehicletypelist,
                    filter_date: dte,
                    vtypediv:"show-m"
                })
            })
            
        }
      }
    onClickShowTruckLegs = async (rownode) =>{
        // console.log("rownode ", rownode);
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: [
                    {
                        headerName:"Unique Part No",
                        field:"unique_part_no"
                    },
                    {
                        headerName:"Part No",
                        field:"part_no",
                        width:"120"
                    },{
                        headerName:"Part Name",
                        field:"part_name"
                    },
                    {
                        headerName:"Part Qty",
                        field:"part_qty",
                        width:"100"
                    },
                    {
                        headerName:"Part Volume",
                        field:"part_volume",
                        width:"100"
                    },{
                        headerName:"Part Weight",
                        field:"part_weight",
                        width:"100"
                    },
                    {
                        headerName:"Supplier Code",
                        field:"dealer_code",
                        width:"100"
                    },{
                        headerName:"Supplier Name",
                        field:"dealer_name"
                    },{
                        headerName:"Supplier Location",
                        field:"location"
                    },
                    
                    {
                        headerName:"Vehicle Start Time",
                        field:"vehicle_start_time",
                        valueGetter:function(parmas){
                            try{
                                if(parmas.data.vehicle_start_time != "" && parmas.data.vehicle_start_time != undefined)
                                {
                                    return moment.parseZone(parmas.data.vehicle_start_time).format("DD-MM-YYYY HH:mm");
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){

                            }
                        }
                    },{
                        headerName:"Vehicle End Time",
                        field:"vehicle_end_time",
                        valueGetter:function(parmas){
                            try{
                                if(parmas.data.vehicle_end_time != "" && parmas.data.vehicle_end_time != undefined)
                                {
                                    return moment.parseZone(parmas.data.vehicle_end_time).format("DD-MM-YYYY HH:mm");
                                }
                                else{
                                    return "";
                                }
                            }
                            catch(e){

                            }
                        }
                    },
                    {
                        headerName:"Distance (Kms)",
                        field:"distance"
                    }
                ],
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    // console.log("Step 4 ",param)
                    // console.log("reqparams",reqparams);
                    await redirectURL.post("/dealers/filterloadplan",{
                        cluster : param.data.cluster,
                        truck_no : param.data.truck_no,
                        trip_no : param.data.trip_no,
                        delivery_date : param.data.delivery_date
                    }).then(async (response) =>{
                        // console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var records=response.data;
                        records = records.sort(GetSortAscOrder("sequence_no"));

                        // console.log("Step 2 ",records)
                        param.successCallback(records);
                        
                        
                        
                    }).catch(function(error){
                        console.log(error);
                    })
                     
                },
                masterDetail: true
            }
        });
        // console.log(rownode.column.colDef.field);
        if(rownode.column.colDef.field == 'truck_no')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
        
    }
    onClickShowRouteOnMpa(rownode){
        // console.log("roue node ", rownode)
        var cluster = rownode.data.cluster;
        var truck_no = rownode.data.truck_no;
        var trip_no = rownode.data.trip_no;
        var delivery_date = rownode.data.delivery_date;
        var allLoadPlan = this.state.allLoadPlan;
        // console.log("allLoadPlan ", allLoadPlan)
        var trucklist= [];
        if(allLoadPlan.length > 0)
        {
            allLoadPlan.map((item) => {
                if(item.cluster === cluster && item.delivery_date === delivery_date && item.trip_no === trip_no)
                {
                    trucklist.push(item);
                }
            })
        }

        var routitems = [];
        if(trucklist.length > 0)
        {
            trucklist = trucklist.sort(GetSortAscOrder("trip_no"));
            var tripds = groupBy(trucklist, rdata => rdata.trip_no);
            tripds.forEach((trips,tripkey) => {
                
                trips = trips.sort(GetSortAscOrder("sequence_no"));
                var sqs = groupBy(trips, rdata => rdata.sequence_no);
                // console.log("sqs",sqs) 
                sqs.forEach((st,sk) => {
                    routitems.push(st[0])
                })
            })
                
        }
        // console.log("trucklist ", trucklist)

        // var sqs = groupBy(ti, rdata => rdata.sequence_no);
        // console.log("sqs",sqs) 
        // sqs.forEach((st,sk) => {
        //     var rw1 = {
        //         cluster:cluster,
        //         items: st[0],
        //     }
        //     routeData.push(rw1)
        // })
        var json = [{
            cluster:cluster,
            truck_no:truck_no,
            delivery_date:delivery_date,
            // items:trucklist ,
            items:routitems ,
            mpfor:"" 
        }]
        // console.log("trucklist ", json)
        this.setState({
            routeData:json,
            showSlideBlockRoute:"slide60",
            overlayBlock:"show-m",
            nmapcluster:"show-n",
            nmap:"show-m",
        })
        this.renderMap();
    }
    
    onClickShowClusterRouteOnMap(rownode){
        // console.log("roue node ", rownode)
        // if(rownode.colDef.field == "cluster")
        // {
                
            var cluster = rownode.data.cluster;
            var truck_no = rownode.data.truck_no;
            var trip_no = rownode.data.trip_no;
            var delivery_date = rownode.data.delivery_date;
            var allLoadPlan = this.state.allLoadPlan;
            // console.log("allLoadPlan ", allLoadPlan)
            var trucklist= [];
            if(allLoadPlan.length > 0)
            {
                allLoadPlan.map((item) => {
                    if(item.cluster === cluster && item.delivery_date == delivery_date && item.trip_no == trip_no)
                    {
                        trucklist.push(item);
                    }
                })
            }

            // console.log("trucklist ", trucklist)
            this.setState({
                clusterpoints:trucklist,
                nmapcluster:"show-m",
                nmap:"show-n",
                mpnode:[rownode.data],
                mpfor:"cluster",
                scluster:cluster,
                showSlideBlockRoute:"slide60",
                overlayBlock:"show-m"
            })
            this.renderMapCluster();
        // }
        // if(rownode.colDef.field == "truck_no")
        // {
        //     var cluster = rownode.data.cluster;
        //     var truck_no = rownode.data.truck_no;
        //     var delivery_date = rownode.data.delivery_date;
        //     var allLoadPlan = this.state.allLoadPlan;
        //     // console.log("allLoadPlan ", allLoadPlan)
        //     var trucklist= [];
        //     if(allLoadPlan.length > 0)
        //     {
        //         allLoadPlan.map((item) => {
        //             if(item.cluster === cluster && item.delivery_date === delivery_date && item.truck_no === truck_no)
        //             {
        //                 trucklist.push(item);
        //             }
        //         })
        //     }
        //     var json = [{
        //         cluster:cluster,
        //         truck_no:truck_no,
        //         delivery_date:delivery_date,
        //         items:trucklist  
        //     }]
        //     // console.log("trucklist ", json)
        //     this.setState({
        //         routeData:json,
        //         mpnode:[rownode.data],
        //         showSlideBlockRoute:"slide60",
        //         overlayBlock:"show-m",
        //         mpfor:""
        //     })
        //     this.renderMap();
        // }
    }

    onClickShowStackingData(rownode){
        // console.log(rownode);
        var params = {rownode:rownode.data}
        redirectURL.post("/dealers/showStackingData",params)
        .then((response) => {
            // console.log("response stacking ", response.data)
            var tblcontent = [];
            var bottomcontent = [];
            var totvols = 0;
            var totweights = 0;
            var stackOriginalData = "";
            if(response.data.status == "Success")
            {
                if(response.data.content != "" && response.data.content != undefined)
                {
                    var cnt = response.data.content;
                    try{
                        var splittext = cnt.split("#@");
                        console.log("splittext", splittext)
                    
                        var splitsc = splittext[0].split("#");
                        // console.log("splitsc", splitsc)
                        
                        if(splitsc.length > 0)
                        {
                            splitsc.map((itm) => {
                                var delim = itm.split(",");
                                if(delim.length > 0)
                                {
                                    try{

                                        if(delim[4] != "" && delim[4] != undefined)
                                        {
                                            var dvol = parseFloat(delim[4])/(1000*1000*1000);
                                            var vol = dvol.toFixed(3);
                                        }
                                        else{
                                            var dvol = "";
                                            var vol = 0;
                                        }
                                    }
                                    catch(e){
                                        
                                    }
                                    
                                    tblcontent.push({
                                        sku:delim[0],
                                        stack_length:delim[1],
                                        stack_width:delim[2],
                                        stack_height:delim[3],
                                        stack_volume:vol,
                                        stack_weight:delim[5],
                                        stack_color:delim[6]
                                    })
                                   
                                    if(delim[5] != "" && delim[5] != undefined)
                                    {
                                        var weig = delim[5];
                                    }
                                    else{
                                        var weig = 0;
                                    }
                                    // console.log("Weig", weig);
                                    totvols=parseFloat(totvols)+parseFloat(vol);
                                    totweights=parseFloat(totweights)+parseFloat(weig);
                                }
                            })
                        }
                        if(splittext.length > 0)
                        {
                            var btm = splittext[1].split("#");
                            var btnc = btm[0].split(",");
                            if(btnc.length > 0){
                                try{
                                    if(btnc[4] != "" && btnc[4] != undefined)
                                    {
                                        var dvol = parseFloat(btnc[4])/(1000*1000*1000);
                                        var vol = dvol.toFixed(3);
                                    }
                                    else{
                                        var dvol = "";
                                        var vol = 0;
                                    }
                                }
                                catch(e){
                                    
                                }
                                
                                bottomcontent.push({
                                    truck_type:btnc[0],
                                    truck_length:btnc[1],
                                    truck_width:btnc[2],
                                    truck_height:btnc[3],
                                    truck_volume:vol,
                                    truck_weight:btnc[5],
                                    truck_pallets:btnc[6]
                                })
                            }
                        }
                    }
                    catch(e){

                    }
                }
                else{
                    var cnt = "";
                }
                // console.log("totweights ", totweights)
                try{
                    tblcontent.push({
                        sku:"Total",
                        stack_length:"",
                        stack_width:"",
                        stack_height:"",
                        stack_volume:totvols.toFixed(3),
                        stack_weight:totweights.toFixed(3),
                        stack_color:""
                    })
                }
                catch(e){}
                
                this.setState({
                    stackImage : response.data.image,
                    stackOriginalData:response.data.content,
                    stacktbl:tblcontent,
                    stacktblbottom:bottomcontent,
                    showSlideBlockStack:"slide90",
                    overlayBlock:"show-m"
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Data not found.",
                    basicType:"danger"
                })
            }
            
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
    render() {
        if(localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined )
        {
          return <Navigate to={"/login"} push={true} />
        }
        var columnwithDefs = [
            
            {
                headerName:"",
                field:"truck_no",
                width:"80",
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewTruckData"
                    };
                    return rendComponent
                
                },
            },
            
            {
                headerName:"",
                field:"cluster",
                width:"80",
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewRoute"
                    };
                    return rendComponent
                
                },
            },
             
            {
                headerName:"",
                field:"cluster",
                width:"80",
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewClusterRoute"
                    };
                    return rendComponent
                
                },
            },
            
            {
                headerName:"",
                field:"cluster",
                width:"80",
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewStackingData"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"110",
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewStack"
                    };
                    return rendComponent
                
                },
            },
            
            
            {
                headerName:"Plan Day",
                field:"plan_day",
                width:"100"
            },
            {
                headerName:"Trip No",
                field:"trip_no",
                width:"100"
            },
            {
                headerName:"Route Mode",
                field:"mode",
                width:"100"
            },
            {
                headerName:"Cluster",
                field:"cluster",
                width:"100",
            },
            {
                headerName:"Truck No",
                field:"truck_no",
                width:"100",
            },
            {
                headerName:"Vehicle Name",
                field:"vehicle_name",
                width:"100"
            },
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"100"
            },
            {
                headerName:"Vehicle Capacity",
                field:"vehicle_capacity",
                width:"120"
            },
            {
                headerName:"Vehicle Weight",
                field:"vehicle_weight",
                width:"120"
            },
            
            {
                headerName:"Part Volume",
                field:"part_volume",
                width:"100"
            },
            {
                headerName:"Part Weight",
                field:"part_weight",
                width:"100"
            },
            
            {
                headerName:"Distance (kms)",
                field:"cumulative_distance",
                width:"100"
            },
            
            {
                headerName:"Deliver Date",
                field:"delivery_dateformat",
                valueGetter:function(params){
                    try{
                        if(params.data.delivery_dateformat != "" && params.data.delivery_dateformat != undefined)
                        {
                            return moment.parseZone(params.data.delivery_dateformat).format("DD-MM-YYYY");
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){

                    }
                }
            }
        ]
        var loadplan = this.state.loadplan;
        return (
            <div class="container-fluid">
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            <i className="fa fa-users"></i> Load Plan Summary
                            
                            <button type="button" className="btn btn-warning float-right" onClick={this.onClickShowUpload}>Upload Load Plan</button>
                        </h5>
                    </div>
                </div>
                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockRoute)}>
                    <h5 className="crd-bg p-10p">{this.state.mpfor != "cluster"?"View Route":"Cluster - "+this.state.scluster+" suppliers in the plan"}</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <div className={this.state.nmap} id="map" style={{width:"100%",height:"90vh"}}></div>
                            <div className={this.state.nmapcluster} id="mapCluster" style={{width:"100%",height:"90vh"}}></div>
                            {(this.state.mpnode.length > 0 && this.state.mpfor != "cluster")?
                                <div className="mpinf" style={{height:"100px"}}>
                                    <div className="mpinfbg"></div>
                                    <div className="row">
                                        <div className="col-sm-3 fbold">
                                            Cluster : {this.state.mpnode[0].cluster}
                                        </div>
                                        <div className="col-sm-3 fbold">
                                        Delivery Date:  {moment.parseZone(this.state.mpnode[0].delivery_dateformat).format("DD-MM-YYYY")}
                                        </div>
                                        {this.state.mpfor != "cluster"?
                                            <div className={"col-sm-3 fbold "}>
                                                Truck No : {this.state.mpnode[0].truck_no}
                                            </div>
                                        :""}
                                        
                                        <div className={"col-sm-3 fbold "}>
                                            Distance (kms) : {this.state.mpnode[0].cummilative_distance}
                                        </div>
                                        {this.state.mpfor != "cluster"?
                                            <div className={"col-sm-3 fbold mt-20p "}>
                                                Supplier:{this.state.mpnode[0].dealer_name}
                                            </div>
                                        :""}
                                        {this.state.mpfor != "cluster"?
                                            <div className={"col-sm-3 fbold mt-20p "}>
                                                Vehicle Name: {this.state.mpnode[0].vehicle_name}
                                            </div>
                                        :""}
                                    </div>
                                </div>
                            :""}
                            
                        </div>
                    </div>
                    </div>
                    

                    <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                        <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD}</h5>
                        <div className="row">
                            
                            <div className="col-sm-12">
                            <form onSubmit={this.formUpload.bind(this)}>
                            <div className="row p-20p">
                                <div className="form-group col-sm-12 mb-20p">
                                    <label style={{color:"#000"}}>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label>
                                    
                                    <input type="file" name="upload_file" id="upload_file" className="form-control" onChange={this.changeFileHandler} required />
                                </div>
                                <div className="form-group col-sm-12 mb-20p">
                                <button type="submit" className="btn btn-danger">SAVE</button>
                                <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                </div>
                            </div>
                            </form>
                            </div>
                        </div>
                    </div>

                    <div className={"sliderBlock2 "+(this.state.showSlideBlockStack)}>
                    <h5 className="crd-bg p-10p">Stacking Data</h5>
                    <div className="row">
                        
                        <div className="col-sm-12" style={{textAlign:"center"}}>
                                {this.state.stackImage != ''?
                                    <img src={"data:image/png;base64,"+this.state.stackImage} />
                                :""}
                            {/* <BinPacking 
                                stackOriginalData={this.state.stackOriginalData}
                            /> */}
                        </div>
                        <div className="col-sm-12 mt-20p p-35p">
                            <div className="row mb-40p">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-10 ctbl">
                                    <table className="table" cellpadding="0" cellSpacing="0">
                                        <tr>
                                            <th>SKU</th>
                                            <th>Length (cm)</th>
                                            <th>Width (cm)</th>
                                            <th>Height (cm)</th>
                                            <th>Volume (CMT)</th>
                                            <th>Weight (Kg)</th>
                                            <th>Color</th>
                                        </tr>
                                {this.state.stacktbl.length > 0?
                                    (this.state.stacktbl.map(itm=>
                                        (itm.sku != "Total")?
                                        <tr>
                                            <td>{itm.sku}</td>
                                            <td>{itm.stack_length}</td>
                                            <td>{itm.stack_width}</td>
                                            <td>{itm.stack_height}</td>
                                            <td>{itm.stack_volume}</td>
                                            <td>{itm.stack_weight}</td>
                                            <td>{itm.stack_color}</td>
                                        </tr>
                                        :
                                        <tr>
                                            <th>{itm.sku}</th>
                                            <td>{itm.stack_length}</td>
                                            <td>{itm.stack_width}</td>
                                            <td>{itm.stack_height}</td>
                                            <th>{itm.stack_volume}</th>
                                            <th>{itm.stack_weight}</th>
                                            <td>{itm.stack_color}</td>
                                        </tr>
                                    ))
                                :""}

                                {this.state.stacktblbottom.length > 0?
                                    <tr>
                                        <th>Truck Type</th>
                                        <th>Length (cm)</th>
                                        <th>Width (cm)</th>
                                        <th>Height (cm)</th>
                                        <th>Volume (CMT)</th>
                                        <th>Weight (Kg)</th>
                                        <th>Total Pallets</th>
                                    </tr>
                                :""}
                                {this.state.stacktblbottom.length > 0?

                                    (this.state.stacktblbottom.map(itm=>
                                        <tr>
                                            <td>{itm.truck_type}</td>
                                            <td>{itm.truck_length}</td>
                                            <td>{itm.truck_width}</td>
                                            <td>{itm.truck_height}</td>
                                            <td>{itm.truck_volume}</td>
                                            <td>{itm.truck_weight}</td>
                                            <td>{itm.truck_pallets}</td>
                                        </tr>
                                    ))
                                :""}
                                    </table>
                                </div>
                                
                                <div className="col-sm-1"></div>
                            </div>
                                
                                
                            
                        </div>
                    </div>
                    </div>
            </div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function closeAllInfoWindows() {
    for (var i=0;i<infowindows.length;i++) {
        infowindows[i].close();
    }
}  

