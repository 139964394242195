import React, { Component, FiberProvider } from 'react'
import Select from 'react-select';
import * as $ from 'jquery';
import { Navigate } from 'react-router-dom';
// import BinPackCanvas from "./binpackCanvas";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {
  CSS2DRenderer,
  CSS2DObject,
} from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import redirectURL from '../redirectURL';
import withRouter from "../withRouter";
// import EdgesHelper from "./edgesHelper";
var initializeDomEvents = require('threex.domevents')
var THREEx = {}

var widgets = [];

// initializeDomEvents(THREE, THREEx)
var bins = [];
var items = [];
const scene = new THREE.Scene();
var itemMap = new Map();

class BinPacking extends Component {
  constructor(props){
    super(props);
    this.state={
      stackingData:[],
      stackOriginalData:"",
      stacktblbottom:[]
    }
    this.loadscene = this.loadscene.bind(this);
    this.loadStackData = this.loadStackData.bind(this);
  }
  componentDidMount = async () =>{
    try{
      var rowid = this.props.params.rowid;
      var p ={
        id : rowid
      }
      redirectURL.post("/dealers/loadDealersById",p)
      .then(async (resp) => {
        if(resp.data.status == "success")
        {
          if(resp.data.records.length > 0){
            var params = {
              rownode:resp.data.records[0]
            };
            await redirectURL.post("/dealers/showStackingData",params)
            .then(async (response) => {
                if(response.data.status == "Success")
                {
                    if(response.data.content != "" && response.data.content != undefined)
                    {
                        var cnt = response.data.content;
                    }
                    else{
                        var cnt = "";
                    }
                   
                    await this.setState({
                        stackOriginalData:response.data.content
                    })
                    await setTimeout(() => {
                      this.loadStackData();
                    },500)
                }
                else{
                    this.setState({
                        show:true,
                        basicTitle:"Data not found.",
                        basicType:"danger"
                    })
                }
                
            })
          }
        }
        
      })
      
    }
    catch(e){

    }
    
    // await this.loadscene();
  
  }
  // componentWillReceiveProps = async (newprops) =>{
  //   // console.log("newprops ", newprops)
  //   this.setState({
  //     stackOriginalData:newprops.stackOriginalData
  //   })
  //   await this.loadStackData(newprops.stackOriginalData);
  // }
  loadStackData = async(stackOriginalData) =>{
    var stackdata = this.state.stackOriginalData;
    // console.log("stackdata ", stackdata)
    var cnt = stackdata;
    var stackingData = [];
    var tblcontent =[];
    var bottomcontent = [];
    var totvols = 0;
    var totweights = 0;
    try{
        var splittext = cnt.split("#@");
        // console.log("splittext", splittext)
    
        var splitsc = splittext[0].split("#");
        // console.log("splitsc", splitsc)
        
        if(splitsc.length > 0)
        {
            splitsc.map((itm) => {
                var delim = itm.split(",");
                if(delim.length > 0)
                {
                    try{

                        if(delim[4] != "" && delim[4] != undefined)
                        {
                            var dvol = parseFloat(delim[4])/(1000*1000*1000);
                            var vol = dvol.toFixed(3);
                        }
                        else{
                            var dvol = "";
                            var vol = 0;
                        }
                    }
                    catch(e){
                        
                    }
                    
                    tblcontent.push({
                        sku:delim[0],
                        stack_length:delim[1],
                        stack_width:delim[2],
                        stack_height:delim[3],
                        stack_volume:vol,
                        stack_weight:delim[5],
                        stack_color:delim[6]
                    })
                    stackingData.push({
                      sku:delim[0],
                      width:(parseInt(delim[1])/1000),
                      depth:(parseInt(delim[2])/1000),
                      height:(parseInt(delim[3])/1000),
                      stack_volume:vol,
                      stack_weight:delim[5],
                      color:delim[6],
                      x:(parseInt(delim[7])/1000).toFixed(2),
                      y:(parseInt(delim[8])/1000).toFixed(2),
                      z:(parseInt(delim[9])/1000).toFixed(2)

                    })
                    
                    if(delim[5] != "" && delim[5] != undefined)
                    {
                        var weig = delim[5];
                    }
                    else{
                        var weig = 0;
                    }
                    // console.log("Weig", weig);
                    totvols=parseFloat(totvols)+parseFloat(vol);
                    totweights=parseFloat(totweights)+parseFloat(weig);
                }
            })
        }
        if(splittext.length > 0)
        {
            var btm = splittext[1].split("#");
            var btnc = btm[0].split(",");
            if(btnc.length > 0){
                try{
                    if(btnc[4] != "" && btnc[4] != undefined)
                    {
                        var dvol = parseFloat(btnc[4])/(1000*1000*1000);
                        var vol = dvol.toFixed(3);
                    }
                    else{
                        var dvol = "";
                        var vol = 0;
                    }
                }
                catch(e){
                    
                }
                
                bottomcontent.push({
                    truck_type:btnc[0],
                    truck_length:btnc[1],
                    truck_width:btnc[2],
                    truck_height:btnc[3],
                    truck_volume:vol,
                    truck_weight:btnc[5],
                    truck_pallets:btnc[6]
                })
            }
        }
    }
    catch(e){

    }
    try{
        tblcontent.push({
            sku:"Total",
            stack_length:"",
            stack_width:"",
            stack_height:"",
            stack_volume:totvols.toFixed(3),
            stack_weight:totweights.toFixed(3),
            stack_color:""
        })
    }
    catch(e){}
    // console.log('stackingData ', stackingData)
    this.setState({
        stackingData : stackingData,
        stacktblbottom:bottomcontent
    });
    await setTimeout(()=>{
      this.loadscene();
    },500);
  }
  loadscene(){
    //First Create a scene to display the box view
    var scene = new THREE.Scene();
    var h = 564;
    var w = 1281;
    var fov = 1;
    var imaps =  new Map();

    const lightd = new THREE.DirectionalLight( 0xffffff, 1 );
    lightd.position.set( 1, 1, 1 ).normalize();
    scene.add( lightd );
    // console.log("this.state.stacktblbottom ", this.state.stacktblbottom)
    if(this.state.stacktblbottom.length > 0)
    {
      //Assigning Length, Height and Depth values to variables.
      var container_width_px = this.state.stacktblbottom[0].truck_length;
      var container_width_cm = (this.state.stacktblbottom[0].truck_length/1000);
      var container_height_px = this.state.stacktblbottom[0].truck_height;
      var container_height_cm = this.state.stacktblbottom[0].truck_height/1000;
      var container_depth_px = this.state.stacktblbottom[0].truck_width;
      var container_depth_cm = this.state.stacktblbottom[0].truck_width/1000;
      
    }
    else{
      var container_width_px = 10000;
      var container_width_cm = 9600/1000;
      var container_height_px = 2400;
      var container_height_cm = 2400/1000;
      var container_depth_px = 2400;
      var container_depth_cm = 2400/1000;
      
    }
    
    //To get the Ratio value of the window.
    var ratio = window.innerWidth/window.innerHeight;
    // var camera = new THREE.PerspectiveCamera(70,ratio,5,100);

    //Creteing a Camera 
    var camera = new THREE.PerspectiveCamera( fov, ratio, 1, 10000);

    //Get the Div element to append scene , camera, etc.,
    const canvas = document.querySelector('#sceneAppend');
    //Displaying box with transparent backgorund
    const renderer = new THREE.WebGLRenderer({alpha:true});
    renderer.setSize( window.innerWidth, window.innerHeight );
    document.getElementById("sceneAppend").appendChild( renderer.domElement );
    //Applying the color to the box skeleton
    var material = new THREE.MeshBasicMaterial({color: 0xff0000});

    // Create Box View 
    var geometry = new THREE.BoxGeometry(container_width_cm,container_height_cm+0.5,container_depth_cm+0.3);
    //Creating box edges and apply color
    var edges = new THREE.EdgesGeometry(geometry);
    var lines = new THREE.LineSegments(edges, new THREE.LineBasicMaterial( { color: 0xff0000 } ));
    var cube = new THREE.Mesh(geometry,material);
    // Append lines to scene
    scene.add(lines);
  
    camera.position.x = -120;
    camera.position.y = -282;
    camera.position.z = -340;
  
    var controls =  new OrbitControls(camera, canvas);
    controls.target.set(0,0,0);
    controls.enableDamping = true;
    controls.update();


    var axis = new THREE.AxesHelper(5);
    scene.add(axis);
    
    // var shape = new THREE.Shape();
    // for(var j = 0; j < 5; j++) {
    //   shape.moveTo(0,0);       
    // }

    
    // var settings = { wireframe: true, amount: 100, bevelEnabled: false, bevelSegments: 1,steps: 1, bevelSize: 3, bevelThickness: 5 };
    // var geometry1 = new THREE.ExtrudeGeometry(shape, settings);
    // var mesh = new THREE.Mesh(geometry1, material);
  

    const geometryed = new THREE.BoxGeometry( 3, 1, 1 );
    var edges = new THREE.EdgesGeometry( geometryed );
    const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0xffffff, size:2 } ) );
    // scene.add( line );
    edges.matrixAutoUpdate = true;

  var items = this.state.stackingData;
  //  console.log("items ", items)
  // const raycaster = new THREE.Raycaster();
  // const mouse = new THREE.Vector2( 1, 1 );
  var objects=[];
  var selected;
  const color = new THREE.Color();
  const white = new THREE.Color().setHex( 0xffffff );
    var cubes = [];
    var edges = [];
    var clights = [];
    for (var i = 0; i < items.length; i++) {
      
      // console.log("items ", items[i])
      var geometryi = new THREE.BoxGeometry(items[i].height, items[i].width, items[i].depth);
      var materiali = new THREE.MeshBasicMaterial({color:items[i].color});
      var cubei = new THREE.Mesh(geometryi, materiali);

      var pos = positionWithPivotNeg(items[i].x, (items[i].y), (items[i].z), items[i].height, items[i].width, items[i].depth);
      // console.log("pos ", pos)
      cubei.position.x = pos.x;
      cubei.position.y = pos.y;
      cubei.position.z = pos.z;
      // cubei.position.x = -2.665;
      // cubei.position.y = -0.6499999999999999;
      // cubei.position.z = 0.63;

      cubei.userData.rownode = items[i];
      
      objects.push(cubei);
      cubes.push(cubei);
      items[i].graphicsItem = cubei;
      imaps.set(cube, items[i]);

      var edgei = new THREE.EdgesGeometry(cubei.geometry);
      var linei = new THREE.LineSegments(edgei, new THREE.LineBasicMaterial({color: 0x000000}));
      
      linei.position.x = pos.x;
      linei.position.y = pos.y;
      linei.position.z = pos.z;
      
      edges.push(linei);
      
      // scene.add(cubei);

      // const colr = items[i].color;
      const intensity = 1;
         
     const skyColor = 0xB1E1FF;  // light blue
     var groundColor = items[i].color;  // brownish orange
     var light = new THREE.HemisphereLight(skyColor, groundColor, intensity);
     clights.push(light);
     

    }
    
    for(var c=0;c<cubes.length;c++)
    {
      scene.add(cubes[c]);
    }

    for(var e=0;e<edges.length;e++)
    {
      scene.add(edges[e]);
    }

    for(var lt=0;lt<clights.length;lt++){
      scene.add(clights[lt]);
    }
    
    for(var m=0;m<imaps.length;m++){
      // console.log("imaps ", imaps[m])
      // scene.add(clights[lt]);
    }
    
    function onMouseOverItem(item) {};
    function onMouseOutItem(item) {};

    // var allccubses = cubes.concat(edges);
    // scene.add(allccubses);
    
    // const geometryd = new THREE.BoxGeometry( 3, 1, 1 );
    // var cbsmat = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    // const cbe = new THREE.Mesh( geometryd, cbsmat );
    // scene.add( cbe );

    edges.matrixAutoUpdate = true;

    // // graphicsItem = edges;


    
    function positionWithPivot(x, y, z, width, height, depth) {
      var newPosition = {
        x: x + width/2.0,
        y: y + height/2.0,
        z: z + depth/2.0
      };
      return newPosition;
    }

    
    function positionWithPivotNeg1(x, y, z, width, height, depth) {
      // console.log("Neg pos ", x+"____"+y+"____"+z+"_____"+width+"_____"+height+"_____"+depth+"_____containerWidth===="+container_width_cm+"_____containerHeight===="+container_height_cm+"_____containerDepth===="+container_depth_cm)
      var newPosition = {
        // x: x/2 -(width/2),
        // y: y/2 -(height/2),
        // z: z/2 -(depth/2)

        x: (container_width_cm/2.0)-(width/2.0)-x,
        y: y-(container_height_cm/2.0)+(height/2.0),
        z: ((container_depth_cm/2.0)+(depth/2.0))/2-z
        
      };
      return newPosition;
    }
   
    function positionWithPivotNeg(x, y, z, width, height, depth) {
      // console.log("Neg pos ", x+"____"+y+"____"+z+"_____"+width+"_____"+height+"_____"+depth+"_____containerWidth===="+container_width_cm+"_____containerHeight===="+container_height_cm+"_____containerDepth===="+container_depth_cm)
      var newPosition = {
        // x: x/2 -(width/2),
        // y: y/2 -(height/2),
        // z: z/2 -(depth/2)

        x: (container_width_cm/2.0)-(width/2.0)-x,
        y: y-(container_height_cm/2.0)+(height/2.0)-0.3,
        z: ((container_depth_cm/2.0)+(depth/2.0)-0.5)/2-z
        
      };
      return newPosition;
    }
  
    function resizeRendererToDisplaySize(renderer) {
      const canvas = renderer.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
        renderer.setSize(width, height, false);
      }
      return needResize;
    }
  
    let renderRequested = false;
  
    function render() {
      renderRequested = undefined;
  
      if (resizeRendererToDisplaySize(renderer)) {
        const canvas = renderer.domElement;
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
      }
  
      controls.update();
      renderer.render(scene, camera);
    }
    render();
  
    function requestRenderIfNotRequested() {
      if (!renderRequested) {
        renderRequested = true;
        requestAnimationFrame(render);
      }
    }
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();
    
    let INTERSECTED;
    // let renderRequested = false;

    function onMouseDown( event ) {
      // console.log("event ", event)
      // event.preventDefault();
      // calculate pointer position in normalized device coordinates
      // (-1 to +1) for both components

      pointer.x = (event.clientX / (window.innerWidth)) * 2 - 1;
      pointer.y = -(event.clientY / (window.innerHeight)) * 2 + 1;
      // console.log("pointer.x ", pointer.x)
      raycaster.setFromCamera( pointer, camera );

      const intersects = raycaster.intersectObjects( objects, true );

      if ( intersects.length > 0 ) {

        if ( INTERSECTED != intersects[ 0 ].object ) {

          INTERSECTED = intersects[ 0 ].object;
          
          // INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
          // INTERSECTED.material.color.setHex( 0xff0000 );
         

        }

      } else {

        // if ( INTERSECTED ) INTERSECTED.material.color.set( 0xff0000 );

        INTERSECTED = null;

      }

    }

    
     controls.addEventListener('change', requestRenderIfNotRequested);
     window.addEventListener('resize', requestRenderIfNotRequested);
     window.addEventListener( 'mousedown', onMouseDown );
     window.addEventListener( 'click', ()=>{
         console.log("Test ", INTERSECTED);
         if(INTERSECTED != null)
         {
           if(INTERSECTED.userData.rownode != undefined)
           {
             var data = INTERSECTED.userData.rownode;
             document.querySelector('#itemBox').innerHTML="";
             // setTimeout(() =>{
               // loadItemBox(data);
             // },500)
             // INTERSECTED.material.color.set( "#"+data.color );
             var html ="<div class='dinf'>Details: </div>SKU: "+data.sku+"<br />";
             // html +="Description: "+data.sku_desc+"<br />";
             html +="Length: "+(data.width*1000)+"<br />";
             html +="Width: "+(data.depth*1000)+"<br />";
             html +="Height: "+(data.height*1000)+"<br />";
             html +="Volume: "+(data.stack_volume*1000*1000*1000)+"<br />";
             html +="Weight(Kgs): "+data.stack_weight+"<br />";            
             // html +="Color: "+data.color+"<br />";
             // html +="X: "+data.Ox+"<br />";
             // html +="Y: "+data.Oy+"<br />";
             // html +="Z: "+data.Oz+"<br />";
             // html +="X-Axis: "+data.x+"<br />";
             // html +="Y-Axis: "+data.y+"<br />";
             // html +="Z-Axis: "+data.z+"<br />";
             // html +="Orient: "+data.orient+"<br />";
             document.getElementById("box_details").innerHTML=html;
             selected = INTERSECTED;
             
           }
         }         
      });
     renderer.render(scene, camera);
  }


  loadsceneNew(){
    var h = 564;
    var w = 1281;
    var fov = 45;
    var imaps =  new Map();

    const { innerWidth, innerHeight } = window;
    const labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize(innerWidth, innerHeight);
    labelRenderer.domElement.style.position = 'absolute';
    labelRenderer.domElement.style.top = '0px';
    labelRenderer.domElement.style.pointerEvents = 'none';
    document.body.appendChild(labelRenderer.domElement);

    var scene = new THREE.Scene();
    if(this.state.stacktblbottom.length > 0)
    {
      //Assigning Length, Height and Depth values to variables.
      var container_width_px = this.state.stacktblbottom[0].truck_length;
      var container_width_cm = (this.state.stacktblbottom[0].truck_length/1000);
      var container_height_px = this.state.stacktblbottom[0].truck_height;
      var container_height_cm = this.state.stacktblbottom[0].truck_height/1000;
      var container_depth_px = this.state.stacktblbottom[0].truck_width;
      var container_depth_cm = this.state.stacktblbottom[0].truck_width/1000;
      
    }
    else{
      var container_width_px = 10000;
      var container_width_cm = 9600/1000;
      var container_height_px = 2400;
      var container_height_cm = 2400/1000;
      var container_depth_px = 2400;
      var container_depth_cm = 2400/1000;
      
    }
    
    const canvas = document.querySelector('#sceneAppend');
    //To get the Ratio value of the window.
    var ratio = window.innerWidth/window.innerHeight;
    // var camera = new THREE.PerspectiveCamera(70,ratio,5,100);
    //Creteing a Camera 
    var camera = new THREE.PerspectiveCamera( fov, ratio, 1, 10000);
    
    camera.position.x = 0;
    camera.position.y = 3;
    camera.position.z = 10;

    //Get the Div element to append scene , camera, etc.,
    // //Displaying box with transparent backgorund
    const renderer = new THREE.WebGLRenderer( { alpha: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    document.body.appendChild( renderer.domElement );
    // document.getElementById("sceneAppend").appendChild( renderer.domElement )

    //Applying the color to the box skeleton
    var material = new THREE.MeshBasicMaterial({color: 0xff0000, wireframe:true});
    
    // Create Box View 
    var geometry = new THREE.BoxGeometry(container_width_cm,container_height_cm,container_depth_cm);
    //Creating box edges and apply color
    var edges = new THREE.EdgesGeometry(geometry);
    var lines = new THREE.LineSegments(edges, new THREE.LineBasicMaterial( { color: 0xff0000 } ));
    var cube = new THREE.Mesh(geometry,material);
    cube.userData.name = "box";
    // Append lines to scene
    scene.add(cube);
    // scene.add(lines);

    // const light = new THREE.AmbientLight( 0x404040 ); // soft white light
    // scene.add( light );

    // const light = new THREE.HemisphereLight( 0xffffbb, 0x080820, 1 );
    // scene.add( light );
    const light = new THREE.DirectionalLight( 0xffffff, 1 );
    light.position.set( 1, 1, 1 ).normalize();
    scene.add( light );


    //Set up shadow properties for the light
    light.shadow.mapSize.width = container_width_cm; // default
    light.shadow.mapSize.height = container_height_cm; // default
    light.shadow.camera.near = 0.5; // default
    light.shadow.camera.far = 1000; // default

    // const lightd = new THREE.DirectionalLight( 0xffffff, 1 );
    // // lightd.position.set( 1, 1, 1 ).normalize();
    // scene.add( lightd );
  

    var controls =  new OrbitControls(camera, renderer.domElement);
    controls.target.set(0,0,0);
    controls.enableDamping = true;
    
    // var controls = new DragControls( [ ... objects ], camera, renderer.domElement );
    // controls.addEventListener( 'drag', render );
    controls.update();


    const labelDiv = document.createElement('div');
    labelDiv.className = 'label';
    labelDiv.style.marginTop = '-1em';
    const label = new CSS2DObject(labelDiv);
    label.visible = false;
    scene.add(label);

    var cubes = [];
    var objects = [];
    var clights = [];
    var edges = [];
    const smgeometry = new THREE.BoxGeometry( container_width_cm,container_height_cm,container_depth_cm );
    var items = this.state.stackingData;
    for (var i = 0; i < items.length; i++) {
      
      // console.log("items : ", items[i])
      // console.log("items ", items[i].orient)

      // D=height=depth
      // H=width=width
      // W=depth=length

      var mywid = items[i].width;
      var myhigh = items[i].height;
      var mydep = items[i].depth;
      // if(parseInt(items[i].orient) == 0)
      // {
      //   mywid = items[i].width;
      //   myhigh = items[i].height;
      //   mydep = items[i].depth;
      // }
      // if(parseInt(items[i].orient) == 1)
      // {
      //   mywid = items[i].depth;
      //   myhigh = items[i].height;
      //   mydep = items[i].width;
      // }
      // if(parseInt(items[i].orient) == 2)
      // {
      //   // console.log("orient 2", items[i])
      //   mywid = items[i].depth;
      //   myhigh = items[i].width;
      //   mydep = items[i].height;
      // }
      // if(parseInt(items[i].orient) == 3)
      // {
      //   // console.log("orient 3", items[i])
      //   mywid = items[i].height;
      //   myhigh = items[i].width;
      //   mydep = items[i].depth;
      // }
      // if(parseInt(items[i].orient) == 4)
      // {
      //   // console.log("orient 4", items[i])
      //   mywid = items[i].height;
      //   myhigh = items[i].depth;
      //   mydep = items[i].width;
      // }
      // if(parseInt(items[i].orient) == 5)
      // {
      //   // console.log("orient 5", items[i].sku)
      //   mywid = items[i].depth;
      //   myhigh = items[i].width;
      //   mydep = items[i].height;
      // }
      var geometryi = new THREE.BoxBufferGeometry( mywid,myhigh, mydep);
      var materiali = new THREE.MeshBasicMaterial({color:items[i].color});
      var cubei = new THREE.Mesh(geometryi, materiali);
      materiali.userData.rownode = items[i];

      var pos = positionWithPivotNeg(items[i].x, (items[i].z), (items[i].y), mywid,myhigh, mydep, items[i].orient);
      // console.log("pos ", pos)
      // console.log("items[i].sku ", items[i].sku)
      cubei.position.x = parseFloat(pos.x);
      cubei.position.y = parseFloat(pos.y);
      cubei.position.z = parseFloat(pos.z);
      // cubei.position.x = -2.665;
      // cubei.position.y = -0.6499999999999999;
      // cubei.position.z = 0.63;

      cubei.userData.rownode = items[i];
      cubes.push(cubei);

      scene.add( cubei );

      imaps.set(materiali,items[i]);
      
      objects.push(cubei);
      items[i].graphicsItem = cubei;
      // imaps.set(cube, items[i]);

      var edgei = new THREE.EdgesGeometry(cubei.geometry);
      var linei = new THREE.LineSegments(edgei, new THREE.LineBasicMaterial({color: 0x000000,wireframe : true }));
      
      linei.position.x = pos.x;
      linei.position.y = pos.y;
      linei.position.z = pos.z;
      linei.userData.rownode = items[i];
      edges.push(linei);
      scene.add(linei);
      
      // scene.add(cubei);

      // const colr = items[i].color;
      const intensity = 1;
         
     const skyColor = 0xB1E1FF;  // light blue
     var groundColor = items[i].color;  // brownish orange
    //  var ilight = new THREE.HemisphereLight(skyColor, groundColor, intensity);
    //  ilight.position.set( 1, 1, 1 ).normalize();
    //  clights.push(ilight);

     const ilight = new THREE.DirectionalLight( 0xffffff, 1 );
     ilight.position.set( 1, 1, 1 ).normalize();
     scene.add( ilight );


    }

    function positionWithPivotNeg(x, y, z, width, height, depth, orient) {
      // console.log("Neg pos ", x+"____"+y+"____"+z+"_____"+width+"_____"+height+"_____"+depth+"_____containerWidth===="+container_width_cm+"_____containerHeight===="+container_height_cm+"_____containerDepth===="+container_depth_cm)
      if(orient == 1)
      {
        var newPosition = {
          x: parseFloat(x)+(parseFloat(width)/2.0)-(parseFloat(container_width_cm)/2.0),
          y: parseFloat(y)+(parseFloat(height)/2.0)-(parseFloat(container_height_cm)/2.0),
          z: ((parseFloat(container_depth_cm)/2.0)+(parseFloat(depth)/2.0))/2+0.14-parseFloat(z)
          
        };
      }
      else if(orient == 2)
      {
        var newPosition = {
          x: parseFloat(x)+(parseFloat(width)/2.0)-(parseFloat(container_width_cm)/2.0),
          y: parseFloat(y)+(parseFloat(height)/2.0)-(parseFloat(container_height_cm)/2.0),
          z: ((parseFloat(container_depth_cm)/2.0)+(parseFloat(depth)/2.0))/2+0.45-parseFloat(z)
          
        };
      }
      else{
        var newPosition = {
          // x: x/2 -(width/2),
          // y: y/2 -(height/2),
          // z: z/2 -(depth/2)

          // x: (container_width_cm/2.0)-(width/2.0)-x,
          // y: y-(container_height_cm/2.0)+(height/2.0),
          // z: ((container_depth_cm/2.0)+(depth/2.0))/2+0.2-z

          
          x: parseFloat(x)+(parseFloat(width)/2.0)-(parseFloat(container_width_cm)/2.0),
          y: parseFloat(y)+(parseFloat(height)/2.0)-(parseFloat(container_height_cm)/2.0),
          z: ((parseFloat(container_depth_cm)/2.0)+(parseFloat(depth)/2.0))/2+0.3-parseFloat(z)
          
        };
      }
      // console.log("newPosition ", newPosition)
      return newPosition;
    }

    const raycaster = new THREE.Raycaster();
    // raycaster.ray.origin.x = 0;
    // raycaster.ray.origin.y = 0;
    // raycaster.ray.origin.z = 0;

    // raycaster.ray.direction.x = container_width_cm/2;
    // raycaster.ray.direction.y = container_height_cm/2;
    // raycaster.ray.direction.z = container_depth_cm/2;
    // let arrowray = new THREE.ArrowHelper(raycaster.ray.direction,camera.position, 10, 0Xff0000);
    // scene.add(arrowray);
    const pointer = new THREE.Vector2();

     function resizeRendererToDisplaySize(renderer) {
      const canvas = renderer.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
        renderer.setSize(width, height, false);
      }
      return needResize;
    }
  
    let theta = 0;
    let INTERSECTED;
    let renderRequested = false;
    let selected;
    function render() {
      renderRequested = undefined;
  
      // if(selected != undefined)
      // {
      //   selected.rotation.x += 0.01
      // }
      if (resizeRendererToDisplaySize(renderer)) {
        const canvas = renderer.domElement;
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
      }
      controls.update();
      
      // arrowray.setDirection(raycaster.ray.direction);
      
      renderer.render(scene, camera);
    }
    render();
  
    function requestRenderIfNotRequested() {
      if (!renderRequested) {
        renderRequested = true;
        requestAnimationFrame(render);
        
				camera.updateProjectionMatrix();
      }
    }
     
    function onMouseDown( event ) {
      // console.log("event ", event)
      // event.preventDefault();
      // calculate pointer position in normalized device coordinates
      // (-1 to +1) for both components

      pointer.x = (event.clientX / (window.innerWidth)) * 2 - 1;
      pointer.y = -(event.clientY / (window.innerHeight)) * 2 + 1;
      // console.log("pointer.x ", pointer.x)
      raycaster.setFromCamera( pointer, camera );

      const intersects = raycaster.intersectObjects( objects, true );

      if ( intersects.length > 0 ) {

        if ( INTERSECTED != intersects[ 0 ].object ) {

          INTERSECTED = intersects[ 0 ].object;
          
          // INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
          // INTERSECTED.material.color.setHex( 0xff0000 );
         

        }

      } else {

        // if ( INTERSECTED ) INTERSECTED.material.color.set( 0xff0000 );

        INTERSECTED = null;

      }

    }
    
    
    controls.addEventListener('change', requestRenderIfNotRequested);
    window.addEventListener('resize', requestRenderIfNotRequested);
    window.addEventListener( 'mousedown', onMouseDown );
    window.addEventListener( 'click', ()=>{
        // console.log("Test ", INTERSECTED);
        if(INTERSECTED != null)
        {
          if(INTERSECTED.userData.rownode != undefined)
          {
            var data = INTERSECTED.userData.rownode;
            document.querySelector('#itemBox').innerHTML="";
            // setTimeout(() =>{
              loadItemBox(data);
            // },500)
            // INTERSECTED.material.color.set( "#"+data.color );
            var html ="<div class='dinf'>Details: </div>SKU: "+data.sku+"<br />";
            // html +="Description: "+data.sku_desc+"<br />";
            html +="Length: "+(data.width*1000)+"<br />";
            html +="Width: "+(data.depth*1000)+"<br />";
            html +="Height: "+(data.height*1000)+"<br />";
            html +="Volume: "+(data.stack_volume*1000*1000*1000)+"<br />";
            html +="Weight(Kgs): "+data.stack_weight+"<br />";            
            // html +="Color: "+data.color+"<br />";
            // html +="X: "+data.Ox+"<br />";
            // html +="Y: "+data.Oy+"<br />";
            // html +="Z: "+data.Oz+"<br />";
            // html +="X-Axis: "+data.x+"<br />";
            // html +="Y-Axis: "+data.y+"<br />";
            // html +="Z-Axis: "+data.z+"<br />";
            // html +="Orient: "+data.orient+"<br />";
            document.getElementById("box_details").innerHTML=html;
            selected = INTERSECTED;
            
          }
        }
    });

    renderer.render(scene, camera);
    

  }
  
  render() {
    return (
      <div className="container-fluid" id="sceneAppend" >
        <div className="row">
          <div className="col-sm-8" id="continr">
            <div id="sceneAppend1" ref={this.sceneAppend} className="packwidget"></div>
          </div>
          
          <div className="col-sm-4">
          <div id="itemBox" style={{position:"fixed",top:"0",left:"0",width:"200px", height:"200px"}}>
              
            </div>
          <div style={{position:"fixed",top:"200px", left:"10px",overflowY:"auto",maxHeight:"550px",padding:"20px",fontSize:"12px",color:"#fff",width:"400px",height:"550px",lineHeight:"30px"}}>
            
            <div  id="box_details">
              </div>
          </div>
          

          </div>

        </div>
              
    </div>
    )
  }
}

function loadItemBox(rownode) {
  // console.log("rownode ", rownode)
  setTimeout(() => {
    
    const canvas = document.querySelector('#itemBox');
      
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera( 45, 1, 1, 10000 );

    const renderer = new THREE.WebGLRenderer({ alpha: true });
    // renderer.setSize( 800, 800 );
    document.getElementById("itemBox").appendChild( renderer.domElement )

    const geometry = new THREE.BoxGeometry( rownode.width*2, rownode.height*2, rownode.depth*2 );
    const material = new THREE.MeshBasicMaterial( { color: rownode.color } );
    const cube = new THREE.Mesh( geometry, material );
    
    var edgei = new THREE.EdgesGeometry(cube.geometry);
    var linei = new THREE.LineSegments(edgei, new THREE.LineBasicMaterial({color: 0x000000,wireframe : true }));
    scene.add( cube );
    // scene.add( edgei );
    scene.add( linei );

    camera.position.z = 5;

    function animate() {
      requestAnimationFrame( animate );

      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;

      linei.rotation.x += 0.01;
      linei.rotation.y += 0.01;

      renderer.render( scene, camera );
    };

    animate();
    
  },100)
}


export default withRouter(BinPacking) ;
