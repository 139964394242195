
import React, { Component } from 'react';

 
const StaticButton = (props) => {
    return (
        
        <div style={{"textAlign":"center"}}>
           
        <button className='btn btn-success grid-btn' style={{border:"0px"}}>
            <i className="fa fa-eye"></i> View
        </button>
        
    </div>
    );
};

export default StaticButton;
