
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

 
const ViewTruckData = (props) => {
    
    const truckData = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickShowTruckLegs(props);
    };
  
    return (
        <div>
			<button className="label label-warning" onClick={truckData} style={{color:"#000",border:"0px"}}>
                <i className="fa fa-eye"></i>Data
            </button>
            
        </div>
    );
};

export default ViewTruckData;
