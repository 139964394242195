import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM} from '../common/utils'; 
import {verifyext} from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import ColumnChart from "./columnChartComponent";
import PolarChart from "./polarChartComponent";
import AreaRangeChart from "./areaRangeChart";
import ViewTransData from "./viewtransactiondata";
import TimeSeriesChartComponent from "./timeSeriesChart";
import SplineChartComponent from "./splineChart";

import Mlist from "./menulist";
import MaterialList from './materialList';

var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class ForecastInputData extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
                ViewTransData:ViewTransData
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showrunprocessbtn:"show-m",
            showanimate:"show-n",
            locationNames:[],
            loadFile:'',
            material_configurations:0,
            location:{label:"Select Location",value:""},
            fy:{label:"Select",value:""},
            fyChart:[{label:"Select",value:""}],
            loadshow:'show-m',
            overly:'show-m',
            processErrMsg : "",
            processErrShow : "",
            transactionId : "",
            droppedList : [],
            chartdata:"show-m",
            datagrid:'show-n',
            datagridTab:"activet",
            chartdataTab:"btn-default",
            chartseries:[],
            defFY:{label:"Select",value:""},
            selectFY:{label:"Select",value:""},
            itemCd:[{label:"Select",value:"All"}],
            itemCodesList:[{value:"",label:"All"}],
            itemCodeSeries:[],
            redirectChk:0,
            duplicateChk:0,
            uploadxlsdata:[],
            sliderType:1,
            forecastitemslist:[],
            durationlist:[
            // {
            //     value:1,
            //     label:"Monthly"
            // },
            {
                value:3,
                label:"3 Months"
            },{
                value:6,
                label:"6 Months"
            },{
                value:12,
                label:"1 Year"
            },
            // {
            //     value:0,
            //     label:"Custom"
            // }
            ],
            dayspred:0,
            inpShow:"show-n",
            sliderTitle:"Upload Input File",
            fyMaterials:[],
            showMaterialCount:1,
            predictedChartData:[],
            predictedResults:[],
            predictedMaterialCount:[],
            drillDownData:[],
            apiOrderData:[],
            predApiOrderData:[]
        }
        
    }
    async componentDidMount() {
        this.setState({
            fyChart:[{value:"2011",label:"2011"},],
            // selectFY:{value:"2021-2022",label:"2021-2022"}
        })
        let url_type = window.location.href
        let path_type = url_type.split('?')
        let data_type
        let path = path_type[path_type.length-1]

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
        var material_configurations = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        this.setState({
            material_configurations : material_configurations
        });
        // await this.boundTypeLocations(params)
        await this.getPredictedResults()
        await this.getMaterialList()
        await this.loadInputPlanData()
        await this.getDrillDownData()
        await this.getMaterialOrderDataFromApi()
    }

    getDrillDownData = () =>{
        let drillDownData = [];
        let params={
            year:2020,
            month_start:1,
            month_end:6
        }
        redirectURL.post("/forecast/getDrillDownData")
        .then(response =>{
            let data = response.data
            let predQty = [{name:"Jan",y:data[0].month_one_pred_quantity,color:'blue',lbl:data[0].month_one_pred_quantity.toLocaleString()},
            {name:"Feb",y:data[0].month_two_pred_quantity,color:'blue',lbl:data[0].month_two_pred_quantity.toLocaleString()},
            {name:"Mar",y:data[0].month_three_pred_quantity,color:'blue',lbl:data[0].month_three_pred_quantity.toLocaleString()},
            {name:"April",y:data[0].month_four_pred_quantity,color:'blue',lbl:data[0].month_four_pred_quantity.toLocaleString()},
            {name:"May",y:data[0].month_five_pred_quantity,color:'blue',lbl:data[0].month_five_pred_quantity.toLocaleString()},
            {name:"June",y:data[0].month_six_pred_quantity,color:'blue',lbl:data[0].month_six_pred_quantity.toLocaleString()},
            ]
            let actualQty = [{name:"Jan",y:data[0].month_one_actual_quantity,color:'blue',lbl:data[0].month_one_actual_quantity.toLocaleString()},
            {name:"Feb",y:data[0].month_two_actual_quantity,color:'blue',lbl:data[0].month_two_actual_quantity.toLocaleString()},
            {name:"Mar",y:data[0].month_three_actual_quantity,color:'blue',lbl:data[0].month_three_actual_quantity.toLocaleString()},
            {name:"April",y:data[0].month_four_actual_quantity,color:'blue',lbl:data[0].month_four_actual_quantity.toLocaleString()},
            {name:"May",y:data[0].month_five_actual_quantity,color:'blue',lbl:data[0].month_five_actual_quantity.toLocaleString()},
            {name:"June",y:data[0].month_six_actual_quantity,color:'blue',lbl:data[0].month_six_actual_quantity.toLocaleString()},
            ]
            drillDownData.push(actualQty)
            drillDownData.push(predQty)
            
            this.setState({drillDownData})
        })
    }

    getMaterialOrderDataFromApi = () =>{
        
        let param={}
        if(this.state.itemCd.value != ""){
            param.item_code = this.state.itemCd.value
        }
        let apiOrderData = [];
        let predApiOrderData = [];
        
        redirectURL.post("/forecast/getMaterialOrderDataFromApi",param)
        .then(response =>{
            let data = JSON.parse(response.data.final_actual_data)
            
            let predData = JSON.parse(response.data.predicted_data)
           data.map(item => item.dataTimeStamp = (new Date(item.date)).getTime()+(60*60*24*1000))
            // console.log(data,'daaa')
            predData.map(item => item.dataTimeStamp = (new Date(item.date)).getTime()+(60*60*24*1000))

            // console.log(predData)

        let itemQnty = [];
        data.map(item=>{
            itemQnty.push([item.dataTimeStamp,item.total_item_quantity])
        })
        let materialCount = [];
        data.map(item=>{
            materialCount.push([item.dataTimeStamp,item.unique_material_count])
        })

        apiOrderData.push(itemQnty)
        apiOrderData.push(materialCount)

        let predItemQnty = [];
        predData.map(item=>{
            predItemQnty.push([item.dataTimeStamp,item.total_item_quantity])
        })
        let predMaterialCount = [];
        predData.map(item=>{
            predMaterialCount.push([item.dataTimeStamp,item.unique_material_count])
        })

        predApiOrderData.push(predItemQnty)
        predApiOrderData.push(predMaterialCount)
        this.setState({apiOrderData,predApiOrderData})
        })
    }



    getPredictedResults = () =>{
        redirectURL.post("/forecast/getForecastingPredictionResults")
        .then(async response => {
            if(response.data.status == 'success'){
            let records = response.data.predictionResults
            let materialCount = records.length
            // let predictedMaterialCount = [{name: '2020', y: materialCount, lbl: materialCount.toLocaleString()}]
            // console.log(records,'predrec')
            let sum = 0;
            let nonZeroItemQty = 0;
            for (const obj of records) {
                if(obj.item_quantity != 0){
                    nonZeroItemQty +=1
                }
                sum += obj.item_quantity;
            }
            let predictedChartData = [{name: '2020', y: sum, lbl: sum.toLocaleString()}]
            let predictedMaterialCount = [{name: '2020', y: nonZeroItemQty, lbl: nonZeroItemQty.toLocaleString()}]

            this.setState({
                predictedResults:records,
                predictedChartData:predictedChartData,
                predictedMaterialCount:predictedMaterialCount
            })
            }
        })
    }

    loadInputPlanData = () =>{
        this.setState({loadshow:'show-m',
                overly:'show-m',
                overlayBlock:"show-m"})
        var params = {
            
        }
        redirectURL.post('/forecast/materialinputdata',params)
        .then(async response =>{
            var records = response.data;
            // console.log(records,'records')
            var rowdata = [];
            var itemCodesList = [];
            itemCodesList.push({value:"",label:"All"});
            var itemCodeSeries  = [];
            var months = ["Jan","Feb","Mar","Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            let combineData = []
            if(records.length > 0){
                await records.map(item =>{
                    if(item.posting_date){
                        let monthAndFy = getYear(new Date(item.posting_date));
                        item.month = (monthAndFy[0]);
                        item.fy = monthAndFy[1];
                    }
                })
            }
            if(records.length > 0)
            {
                records.sort((a, b) => a.fy.localeCompare(b.fy));
                var itmdata = groupBy(records, rdata => rdata.fy);

                try {
                    itmdata.forEach((valueslist,keys)=>{
                        if(valueslist.length > 0)
                        {
                            // var valueslistXC =  groupBy(valueslist, rdata => rdata.customer_code);
                            // valueslistXC.forEach((valuesA,aKey) => {
                                // if(valuesA.length > 0)
                                // {
                                    var valueslistX =  groupBy(valueslist, rdata => rdata.item_code);
                                    
                                    valueslistX.forEach((values,keys)=>{
                                        itemCodesList.push({itemcode:keys});
                                        var rw = {}
                                            if(values.length > 0)
                                            {
                                                rw["_id"] = values[0]._id;
                                                // rw["transaction_id"] = values[0].transaction_id;
                                                rw["fy"] = values[0].fy;
                                                let fy_start_year,fy_end_year
                                                // let years = (values[0].fy).split('-');
                                                fy_end_year = values[0].fy;
                                                fy_start_year = values[0].fy;
                                                rw["fy_start_year"] = fy_start_year;
                                                rw["fy_end_year"] = fy_end_year;
                                                rw["item_code"] = values[0].item_code;
                                                // rw["customer_code"] = values[0].customer_code;
                                                // rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
        
                                            values.map((i) => {
                                                let key = months[parseInt(i.month)];
                                                let value = i.quantity;
                                                if (key in rw) {
                                                    rw[key] += value;
                                                  } else {
                                                    rw[key] = value;
                                                  }
                                                // rw[months[parseInt(i.month)]] = i.quantity;
                                                // rw[months[parseInt(i.month)-1]+"_cost"] = i.item_total_cost;
                                            })
                                            // console.log("RWW ", JSON.stringify(rw))
                                            rowdata.push(rw);
                                        }
                                    })
                                // }
                            // })                            
                        }
                    })
                } catch (error) {
                    
                }
                records.map((item) => {

                })
            }
            var chartseries = {};
            let fyMaterials=[];
            if(rowdata.length > 0)
            {

                var fywisedata = groupBy(rowdata, rdata => rdata.fy);
                try {
                    // console.log("fywisedata ",JSON.stringify(fywisedata))
                    var overallordersfy=[];
                    var overallcostfy=[];
                    var fynonths=[]
                    var fynonthsCost  = [];
                    var historify=[];
                    
                    var all_fys=[]
                    var itmoverallordersfy=[];
                    var itmoverallcostfy=[];
                    var itmfynonths=[]
                    var itmfynonthsCost  = [];
                    var itmhistorify=[];
                    fywisedata.forEach((values,keys) => {
                        var totlOrders = 0;
                        var totlCosts = 0;

                        var itmtotlCosts = 0;

                        if (!all_fys.includes(keys)) {
                            all_fys.push(keys);
                          }

                        if(values.length > 0)
                        {
                            var monthfy = []
                            var monthfyCost = [];
                            var hmonthfy = []
                            var pmonthfy = []
                            var apr=0;
                            var may=0;
                            var jun=0;
                            var jul=0;
                            var aug=0;
                            var sep=0;
                            var oct=0;
                            var nov=0;
                            var dec=0;
                            var jan=0;
                            var feb=0;
                            var mar=0;

                            var apr_cost=0;
                            var may_cost=0;
                            var jun_cost=0;
                            var jul_cost=0;
                            var aug_cost=0;
                            var sep_cost=0;
                            var oct_cost=0;
                            var nov_cost=0;
                            var dec_cost=0;
                            var jan_cost=0;
                            var feb_cost=0;
                            var mar_cost=0;
                            values.map((item) => {

                                item.Apr = (item.Apr !== undefined && item.Apr !== null) ? item.Apr:0;
                                item.May = (item.May !== undefined && item.May !== null) ? item.May:0;
                                item.Jun = (item.Jun !== undefined && item.Jun !== null) ? item.Jun:0;
                                item.Jul = (item.Jul !== undefined && item.Jul !== null) ? item.Jul:0;
                                item.Aug = (item.Aug !== undefined && item.Aug !== null) ? item.Aug:0;
                                item.Sep = (item.Sep !== undefined && item.Sep !== null) ? item.Sep:0;
                                item.Oct = (item.Oct !== undefined && item.Oct !== null) ? item.Oct:0;
                                item.Nov = (item.Nov !== undefined && item.Nov !== null) ? item.Nov:0;
                                item.Dec = (item.Dec !== undefined && item.Dec !== null) ? item.Dec:0;
                                item.Jan = (item.Jan !== undefined && item.Jan !== null) ? item.Jan:0;
                                item.Feb = (item.Feb !== undefined && item.Feb !== null) ? item.Feb:0;
                                item.Mar = (item.Mar !== undefined && item.Mar !== null) ? item.Mar:0;

                                item.Apr_cost = (item.Apr_ !== undefined && item.Apr_ !== null) ? item.Apr_cost:0;
                                item.May_cost = (item.May_cost !== undefined && item.May_cost !== null) ? item.May_cost:0;
                                item.Jun_cost = (item.Jun_cost !== undefined && item.Jun_cost !== null) ? item.Jun_cost:0;
                                item.Jul_cost = (item.Jul_cost !== undefined && item.Jul_cost !== null) ? item.Jul_cost:0;
                                item.Aug_cost = (item.Aug_cost !== undefined && item.Aug_cost !== null) ? item.Aug_cost:0;
                                item.Sep_cost = (item.Sep_cost !== undefined && item.Sep_cost !== null) ? item.Sep_cost:0;
                                item.Oct_cost = (item.Oct_cost !== undefined && item.Oct_cost !== null) ? item.Oct_cost:0;
                                item.Nov_cost = (item.Nov_cost !== undefined && item.Nov_cost !== null) ? item.Nov_cost:0;
                                item.Dec_cost = (item.Dec_cost !== undefined && item.Dec_cost !== null) ? item.Dec_cost:0;
                                item.Jan_cost = (item.Jan_cost !== undefined && item.Jan_cost !== null) ? item.Jan_cost:0;
                                item.Feb_cost = (item.Feb_cost !== undefined && item.Feb_cost !== null) ? item.Feb_cost:0;
                                item.Mar_cost = (item.Mar_cost !== undefined && item.Mar_cost !== null) ? item.Mar_cost:0;

                                // console.log(parseInt(totlOrders)+"Apr:"+parseInt(item.Apr)+"may:"+parseInt(item.May)+"jun:"+parseInt(item.Jun)+"jul:"+parseInt(item.Jul)+"aug:"+parseInt(item.Aug)+"sep:"+parseInt(item.Sep)+"ocyt:"+parseInt(item.Oct)+"nov:"+parseInt(item.Nov)+"dec:"+parseInt(item.Dec)+"jan:"+parseInt(item.Jan)+"feb:"+parseInt(item.Feb)+"mar:"+parseInt(item.Mar))

                                totlOrders = parseInt(totlOrders)+parseInt(item.Apr)+parseInt(item.May)+parseInt(item.Jun)+parseInt(item.Jul)+parseInt(item.Aug)+parseInt(item.Sep)+parseInt(item.Oct)+parseInt(item.Nov)+parseInt(item.Dec)+parseInt(item.Jan)+parseInt(item.Feb)+parseInt(item.Mar);

                                totlCosts = parseInt(totlCosts)+parseInt(item.Apr_cost)+parseInt(item.May_cost)+parseInt(item.Jun_cost)+parseInt(item.Jul_cost)+parseInt(item.Aug_cost)+parseInt(item.Sep_cost)+parseInt(item.Oct_cost)+parseInt(item.Nov_cost)+parseInt(item.Dec_cost)+parseInt(item.Jan_cost)+parseInt(item.Feb_cost)+parseInt(item.Mar_cost);
                                apr = parseInt(apr)+parseInt(item.Apr);
                                may = parseInt(may)+parseInt(item.May);
                                jun = parseInt(jun)+parseInt(item.Jun);
                                jul = parseInt(jul)+parseInt(item.Jul);
                                aug = parseInt(aug)+parseInt(item.Aug);
                                sep = parseInt(sep)+parseInt(item.Sep);
                                oct = parseInt(oct)+parseInt(item.Oct);
                                nov = parseInt(nov)+parseInt(item.Nov);
                                dec = parseInt(dec)+parseInt(item.Dec);
                                jan = parseInt(jan)+parseInt(item.Jan);
                                feb = parseInt(feb)+parseInt(item.Feb);
                                mar = parseInt(mar)+parseInt(item.Mar);

                                apr_cost = parseInt(apr_cost)+parseInt(item.Apr_cost);
                                may_cost = parseInt(may_cost)+parseInt(item.May_cost);
                                jun_cost = parseInt(jun_cost)+parseInt(item.Jun_cost);
                                jul_cost = parseInt(jul_cost)+parseInt(item.Jul_cost);
                                aug_cost = parseInt(aug_cost)+parseInt(item.Aug_cost);
                                sep_cost = parseInt(sep_cost)+parseInt(item.Sep_cost);
                                oct_cost = parseInt(oct_cost)+parseInt(item.Oct_cost);
                                nov_cost = parseInt(nov_cost)+parseInt(item.Nov_cost);
                                dec_cost = parseInt(dec_cost)+parseInt(item.Dec_cost);
                                jan_cost = parseInt(jan_cost)+parseInt(item.Jan_cost);
                                feb_cost = parseInt(feb_cost)+parseInt(item.Feb_cost);
                                mar_cost = parseInt(mar_cost)+parseInt(item.Mar_cost);

                            });
                            hmonthfy.push(apr);
                            hmonthfy.push(may);
                            hmonthfy.push(jun);
                            hmonthfy.push(jul);
                            hmonthfy.push(aug);
                            hmonthfy.push(sep);
                            hmonthfy.push(oct);
                            hmonthfy.push(nov);
                            hmonthfy.push(dec);
                            hmonthfy.push(jan);
                            hmonthfy.push(feb);
                            hmonthfy.push(mar);
                            monthfy.push(
                                {
                                    name:"Jan",y:jan
                                },{
                                    name:"Feb",y:feb
                                },{
                                    name:"Mar",y:mar
                                },{
                                name:"Apr",y:apr
                            },{
                                name:"May",y:may
                            },{
                                name:"Jun",y:jun
                            },{
                                name:"Jul",y:jul
                            },{
                                name:"Aug",y:aug
                            },{
                                name:"Sep",y:sep
                            },{
                                name:"Oct",y:oct
                            },{
                                name:"Nov",y:nov
                            },{
                                name:"Dec",y:dec
                            },);

                            monthfyCost.push({
                                name:"Apr",y:apr_cost
                            },{
                                name:"May",y:may_cost
                            },{
                                name:"Jun",y:jun_cost
                            },{
                                name:"Jul",y:jul_cost
                            },{
                                name:"Aug",y:aug_cost
                            },{
                                name:"Sep",y:sep_cost
                            },{
                                name:"Oct",y:oct_cost
                            },{
                                name:"Nov",y:nov_cost
                            },{
                                name:"Dec",y:dec_cost
                            },{
                                name:"Jan",y:jan_cost
                            },{
                                name:"Feb",y:feb_cost
                            },{
                                name:"Mar",y:mar_cost
                            });

                            fyMaterials.push({
                                name:keys,
                                y:values.length,
                            lbl:values.length.toLocaleString()})
                            
                            overallordersfy.push({
                                name:keys,
                                y:(totlOrders < 0)?0:totlOrders,
                                lbl:totlOrders.toLocaleString()
                            });
                            // console.log("totlCosts ", totlCosts)
                            overallcostfy.push({
                                name:keys,
                                y:(totlCosts < 0)?0:totlCosts,
                                lbl:totlOrders.toLocaleString()
                            });
                            fynonths.push({
                                name:keys,
                                data:monthfy
                            })
                            fynonthsCost.push({
                                name:keys,
                                data:monthfyCost
                            })
                            historify.push(hmonthfy)
                            
                            // Material Code Wise Data
                            var itmcodata = groupBy(values, rdata => rdata.item_code);
                            
                            itmcodata.forEach((itemls ,ikey) => {
                                // console.log("ikey ", ikey)
                                if(itemls.length > 0)
                                {
                                    var itmtotlOrders=0;
                                    var itmmonthfy = []
                                    var itmmonthfyCost = [];
                                    var itmhmonthfy = []
                                    var itmpmonthfy = []
                                    var apr=0;
                                    var may=0;
                                    var jun=0;
                                    var jul=0;
                                    var aug=0;
                                    var sep=0;
                                    var oct=0;
                                    var nov=0;
                                    var dec=0;
                                    var jan=0;
                                    var feb=0;
                                    var mar=0;
        
                                    var apr_cost=0;
                                    var may_cost=0;
                                    var jun_cost=0;
                                    var jul_cost=0;
                                    var aug_cost=0;
                                    var sep_cost=0;
                                    var oct_cost=0;
                                    var nov_cost=0;
                                    var dec_cost=0;
                                    var jan_cost=0;
                                    var feb_cost=0;
                                    var mar_cost=0;
                                    itemls.map((item) => {
                                        
                                        item.Apr = (item.Apr !== undefined && item.Apr !== null) ? item.Apr:0;
                                        item.May = (item.May !== undefined && item.May !== null) ? item.May:0;
                                        item.Jun = (item.Jun !== undefined && item.Jun !== null) ? item.Jun:0;
                                        item.Jul = (item.Jul !== undefined && item.Jul !== null) ? item.Jul:0;
                                        item.Aug = (item.Aug !== undefined && item.Aug !== null) ? item.Aug:0;
                                        item.Sep = (item.Sep !== undefined && item.Sep !== null) ? item.Sep:0;
                                        item.Oct = (item.Oct !== undefined && item.Oct !== null) ? item.Oct:0;
                                        item.Nov = (item.Nov !== undefined && item.Nov !== null) ? item.Nov:0;
                                        item.Dec = (item.Dec !== undefined && item.Dec !== null) ? item.Dec:0;
                                        item.Jan = (item.Jan !== undefined && item.Jan !== null) ? item.Jan:0;
                                        item.Feb = (item.Feb !== undefined && item.Feb !== null) ? item.Feb:0;
                                        item.Mar = (item.Mar !== undefined && item.Mar !== null) ? item.Mar:0;

                                        item.Apr_cost = (item.Apr_ !== undefined && item.Apr_ !== null) ? item.Apr_cost:0;
                                        item.May_cost = (item.May_cost !== undefined && item.May_cost !== null) ? item.May_cost:0;
                                        item.Jun_cost = (item.Jun_cost !== undefined && item.Jun_cost !== null) ? item.Jun_cost:0;
                                        item.Jul_cost = (item.Jul_cost !== undefined && item.Jul_cost !== null) ? item.Jul_cost:0;
                                        item.Aug_cost = (item.Aug_cost !== undefined && item.Aug_cost !== null) ? item.Aug_cost:0;
                                        item.Sep_cost = (item.Sep_cost !== undefined && item.Sep_cost !== null) ? item.Sep_cost:0;
                                        item.Oct_cost = (item.Oct_cost !== undefined && item.Oct_cost !== null) ? item.Oct_cost:0;
                                        item.Nov_cost = (item.Nov_cost !== undefined && item.Nov_cost !== null) ? item.Nov_cost:0;
                                        item.Dec_cost = (item.Dec_cost !== undefined && item.Dec_cost !== null) ? item.Dec_cost:0;
                                        item.Jan_cost = (item.Jan_cost !== undefined && item.Jan_cost !== null) ? item.Jan_cost:0;
                                        item.Feb_cost = (item.Feb_cost !== undefined && item.Feb_cost !== null) ? item.Feb_cost:0;
                                        item.Mar_cost = (item.Mar_cost !== undefined && item.Mar_cost !== null) ? item.Mar_cost:0;


                                        itmtotlOrders = parseInt(itmtotlOrders)+parseInt(item.Apr)+parseInt(item.May)+parseInt(item.Jun)+parseInt(item.Jul)+parseInt(item.Aug)+parseInt(item.Sep)+parseInt(item.Oct)+parseInt(item.Nov)+parseInt(item.Dec)+parseInt(item.Jan)+parseInt(item.Feb)+parseInt(item.Mar);

                                        // console.log('item'+parseInt(itmtotlOrders)+"Apr:"+parseInt(item.Apr)+"may:"+parseInt(item.May)+"jun:"+parseInt(item.Jun)+"jul:"+parseInt(item.Jul)+"aug:"+parseInt(item.Aug)+"sep:"+parseInt(item.Sep)+"ocyt:"+parseInt(item.Oct)+"nov:"+parseInt(item.Nov)+"dec:"+parseInt(item.Dec)+"jan:"+parseInt(item.Jan)+"feb:"+parseInt(item.Feb)+"mar:"+parseInt(item.Mar))

                                        itmtotlCosts = parseInt(itmtotlCosts)+parseInt(item.Apr_cost)+parseInt(item.May_cost)+parseInt(item.Jun_cost)+parseInt(item.Jul_cost)+parseInt(item.Aug_cost)+parseInt(item.Sep_cost)+parseInt(item.Oct_cost)+parseInt(item.Nov_cost)+parseInt(item.Dec_cost)+parseInt(item.Jan_cost)+parseInt(item.Feb_cost)+parseInt(item.Mar_cost);
                                        apr = parseInt(apr)+parseInt(item.Apr);
                                        may = parseInt(may)+parseInt(item.May);
                                        jun = parseInt(jun)+parseInt(item.Jun);
                                        jul = parseInt(jul)+parseInt(item.Jul);
                                        aug = parseInt(aug)+parseInt(item.Aug);
                                        sep = parseInt(sep)+parseInt(item.Sep);
                                        oct = parseInt(oct)+parseInt(item.Oct);
                                        nov = parseInt(nov)+parseInt(item.Nov);
                                        dec = parseInt(dec)+parseInt(item.Dec);
                                        jan = parseInt(jan)+parseInt(item.Jan);
                                        feb = parseInt(feb)+parseInt(item.Feb);
                                        mar = parseInt(mar)+parseInt(item.Mar);
        
                                        apr_cost = parseInt(apr_cost)+parseInt(item.Apr_cost);
                                        may_cost = parseInt(may_cost)+parseInt(item.May_cost);
                                        jun_cost = parseInt(jun_cost)+parseInt(item.Jun_cost);
                                        jul_cost = parseInt(jul_cost)+parseInt(item.Jul_cost);
                                        aug_cost = parseInt(aug_cost)+parseInt(item.Aug_cost);
                                        sep_cost = parseInt(sep_cost)+parseInt(item.Sep_cost);
                                        oct_cost = parseInt(oct_cost)+parseInt(item.Oct_cost);
                                        nov_cost = parseInt(nov_cost)+parseInt(item.Nov_cost);
                                        dec_cost = parseInt(dec_cost)+parseInt(item.Dec_cost);
                                        jan_cost = parseInt(jan_cost)+parseInt(item.Jan_cost);
                                        feb_cost = parseInt(feb_cost)+parseInt(item.Feb_cost);
                                        mar_cost = parseInt(mar_cost)+parseInt(item.Mar_cost);
        
                                    });
                                    itmhmonthfy.push(apr);
                                    itmhmonthfy.push(may);
                                    itmhmonthfy.push(jun);
                                    itmhmonthfy.push(jul);
                                    itmhmonthfy.push(aug);
                                    itmhmonthfy.push(sep);
                                    itmhmonthfy.push(oct);
                                    itmhmonthfy.push(nov);
                                    itmhmonthfy.push(dec);
                                    itmhmonthfy.push(jan);
                                    itmhmonthfy.push(feb);
                                    itmhmonthfy.push(mar);
                                    itmmonthfy.push({
                                        name:"Jan",y:jan
                                    },{
                                        name:"Feb",y:feb
                                    },{
                                        name:"Mar",y:mar
                                    },{
                                        name:"Apr",y:apr
                                    },{
                                        name:"May",y:may
                                    },{
                                        name:"Jun",y:jun
                                    },{
                                        name:"Jul",y:jul
                                    },{
                                        name:"Aug",y:aug
                                    },{
                                        name:"Sep",y:sep
                                    },{
                                        name:"Oct",y:oct
                                    },{
                                        name:"Nov",y:nov
                                    },{
                                        name:"Dec",y:dec
                                    },);
        
                                    itmmonthfyCost.push({
                                        name:"Apr",y:apr_cost
                                    },{
                                        name:"May",y:may_cost
                                    },{
                                        name:"Jun",y:jun_cost
                                    },{
                                        name:"Jul",y:jul_cost
                                    },{
                                        name:"Aug",y:aug_cost
                                    },{
                                        name:"Sep",y:sep_cost
                                    },{
                                        name:"Oct",y:oct_cost
                                    },{
                                        name:"Nov",y:nov_cost
                                    },{
                                        name:"Dec",y:dec_cost
                                    },{
                                        name:"Jan",y:jan_cost
                                    },{
                                        name:"Feb",y:feb_cost
                                    },{
                                        name:"Mar",y:mar_cost
                                    });
        
                                    itmoverallordersfy.push({
                                        name:keys,
                                        itemname:ikey,
                                        y:(itmtotlOrders < 0)?0:itmtotlOrders,
                                        lbl:itmtotlOrders.toLocaleString()
                                    });
                                    // console.log("totlCosts ", totlCosts)
                                    itmoverallcostfy.push({
                                        name:keys,
                                        itemname:ikey,
                                        y:(itmtotlCosts < 0)?0:itmtotlCosts,
                                        lbl:itmtotlCosts.toLocaleString()
                                    });
                                    // console.log("itmmonthfy ", itmmonthfy)
                                    itmfynonths.push({
                                        name:keys,
                                        itemname:ikey,
                                        data:itmmonthfy
                                    })
                                    itmfynonthsCost.push({
                                        itemname:ikey,
                                        name:keys,
                                        data:itmmonthfyCost
                                    })
                                    itmhistorify.push(itmhmonthfy)
                                }
                            })
                        }


                    })

                    
                    // console.log("itmcodata ", itmcodata)
                    // console.log("overallcostfy ", itmoverallordersfy)
                    chartseries["overallfinancialorders"] = {name:"Orders",colorByPoint: true,data:overallordersfy}
                    chartseries["all_fys"] = [all_fys]
                    chartseries["overallfinancialcost"] = {name:"Cost",colorByPoint: true,data:overallcostfy}
                    var selectedfymonths = fynonths.filter((f) => this.state.fyChart[0].value === f.name);
                    var selectedfymonthsCost = fynonths.filter((f) => this.state.fyChart[0].value === f.name);
                    chartseries["allfymonthwise"] = {name:"Orders",colorByPoint: true,data:fynonths}
                    chartseries["allfymonthwiseCost"] = {name:"Orders",colorByPoint: true,data:fynonthsCost}
                    chartseries["selectedfymonths"] = selectedfymonths
                    chartseries["selectedfymonthsCost"] = selectedfymonthsCost
                    chartseries["historic"] = historify

                    chartseries["all_overallfinancialorders"] = {name:"Orders",colorByPoint: true,data:overallordersfy}
                    chartseries["all_overallfinancialcost"] = {name:"Cost",colorByPoint: true,data:overallcostfy}
                    chartseries["all_allfymonthwise"] = {name:"Orders",colorByPoint: true,data:fynonths}
                    chartseries["all_allfymonthwiseCost"] = {name:"Orders",colorByPoint: true,data:fynonthsCost}
                    chartseries["all_selectedfymonths"] = selectedfymonths
                    chartseries["all_selectedfymonthsCost"] = selectedfymonthsCost
                    chartseries["all_historic"] = historify

                    chartseries["itemoverallorders"] = {name:"Orders",colorByPoint: true,data:itmoverallordersfy}
                    chartseries["itemoverallcost"] = {name:"Cost",colorByPoint: true,data:itmoverallcostfy}
                    var itmselectedfymonths = itmfynonths.filter((f) => this.state.fyChart[0].value === f.name);
                    var itmselectedfymonthsCost = itmfynonths.filter((f) => this.state.fyChart[0].value === f.name);
                    chartseries["itemallfymonthwise"] = {name:"Orders",colorByPoint: true,data:itmfynonths}
                    chartseries["itemallfymonthwiseCost"] = {name:"Orders",colorByPoint: true,data:itmfynonthsCost}
                    chartseries["itemselectedfymonths"] = itmselectedfymonths;
                    chartseries["itemselectedfymonthsCost"] = itmselectedfymonthsCost;
                    chartseries["itemhistoric"] = itmhistorify;
                    chartseries["dtype"] = "default"

                } catch (error) {
                    
                }
                // item = item.sort(GetSortAscOrder("sequence_no"));
            }
            // console.log("itemCodesList ", itemCodesList)
            var itemCodesListarr = [];
            var newArray = [];
            let totuniqueObject = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in itemCodesList) { 

                // Extract the title
                if(itemCodesList[i]['itemcode'] !== "" && itemCodesList[i]['itemcode'] !== undefined)
                {
                    totobjTitle = itemCodesList[i]['itemcode']; 

                    // Use the title as the index 
                    totuniqueObject[totobjTitle] = itemCodesList[i];
                } 
                 
            } 
            // Loop to push unique object into array 
            for (var a in totuniqueObject) { 
                newArray.push(totuniqueObject[a]); 
            } 
            if(newArray.length > 0)
            {
                itemCodesListarr.push({value:"",label:"All"})
                newArray.map((item) => {
                    itemCodesListarr.push({value:item.itemcode,label:item.itemcode});
                })
            }
            // console.log("itemCodesListarr ", itemCodesListarr)
            this.setState({
                rowData:rowdata,
                loadshow:'show-n',
                overly:'show-n',
                overlayBlock:"show-n",
                chartseries:chartseries,
                itemCodesList:itemCodesListarr,
                fyMaterials:fyMaterials
            })
        })
    }

    getMaterialList = () => {
        redirectURL.post('forecast/getDistinctDataFromMaterialsMaster')
        .then(async (response) =>{
            var records = response.data;
            var forecastitemslistarr = [{value:"all", label:"All"}];
            if(records.length > 0)
            {
                records.map((item) => {
                    forecastitemslistarr.push({value:item,label:item})
                })
            }
            this.setState({
                forecastitemslist:forecastitemslistarr,
            })
        })
    }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    closeAlert = () => {
        this.setState({
            show: false,
            redirectChk:0,
            duplicateChk:0,
            uploadxlsdata:[]
        });
        var redirectChk = this.state.redirectChk;
        if(redirectChk == 1)
        {
            window.location.href="/forecast-materials";
        }
    }

    ignoreAlert = () => {
        this.setState({
            show: false
        });       
    }
    
    onClickShowUpload = () =>{
        this.setState({
            showSlideBlockUpload:"slide25",
            sliderType:1,
            sliderTitle:"Upload Input File",
            overlayBlock:"show-m"
        })
    }
    
    onClickShowTrigger = () =>{
        this.setState({
            showSlideBlockUpload:"slide25",
            sliderType:2,
            sliderTitle:"Trigger Learning",
            overlayBlock:"show-m"
        })
    }

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
            loadFile:'',
            loadshow:'show-n',
            overly:'show-n',
            redirectChk:0,
            duplicateChk:0,
            uploadxlsdata:[],
            sliderType:1
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        redirectURL.post("dispatch/generateLoadByTransactionId",params).then((response)=>{
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if(statusCode == 0 || statusCode == 1)
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    processErrMsg : "",
                    processErrShow : 0,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            //window.location.reload();
        });
    }
    
    changeFileHandler = async (e) => {
        console.log(e);
        this.setState({
            loadFile : e.target.files[0]
        });  
        
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        saveData.append("file", this.state.loadFile);
        
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        redirectURL.post("/forecast/readXLSData",saveData,{
            headers:{
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin':"*",
                'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                'Access-Control-Allow-Headers': 'Origin, Content-Type'
            }
        }).then((response)=>{
            var uploadxlsdata = response.data.records;
            //   console.log("uploadxlsdata ", uploadxlsdata)
            if(uploadxlsdata.length > 0)
            {
                var p = {
                    xlsdata:uploadxlsdata,
                    over_ride:0
                }
                // console.log("p ", p)
                redirectURL.post("/forecast/savefyinputdatanew",p)
                .then((resp) => {
                //   console.log(resp.data)
                    if(resp.data.status == 1 || resp.data.status == "1")
                    {
                        this.setState({
                            // uploadfile:"",
                            // showSlideBlockUpload:"",
                            // overlayBlock:"show-n",
                            overly:"show-n",
                            loadshow:"show-n",
                            // show:true,
                            // basicTitle:resp.data.message,
                            // basicType:"warning",
                            showloader:"show-n",
                            duplicateChk : 1,
                            uploadxlsdata : uploadxlsdata
                        });
                    }
                    else if(resp.data.status == 2 || resp.data.status == "2")
                    {
                        var respMessage = "Success";
                        var redirectChk = 0;
                        var basicType = "success";
                        if(resp.data.new_material_list !=undefined && resp.data.new_material_list !="")
                        {
                            var new_material_list= JSON.parse(resp.data.new_material_list);
                            if(new_material_list.length > 0)
                            {
                                respMessage = resp.data.message+" "+new_material_list.join(",");
                                redirectChk = 1;
                                basicType = "warning";
                            }
                        }
                        this.setState({
                            uploadfile:"",
                            showSlideBlockUpload:"",
                            overlayBlock:"show-n",
                            overly:"show-n",
                            loadshow:"show-n",
                            show:true,
                            basicTitle:respMessage,
                            basicType:basicType,
                            showloader:"show-n",
                            redirectChk:redirectChk
                        });
                    }
                    else{
                        this.setState({
                            show:true,
                            basicTitle:"Failed to upload data",
                            basicType:"danger",
                            showloader:"show-n",
                            overly:"show-n",
                        });
                    }
                })
            }
        });
    };


    overRideExistingData = () => {        
        var duplicateChk = this.state.duplicateChk;
        if(duplicateChk == 1)
        {
            var uploadxlsdata = this.state.uploadxlsdata;
            if(uploadxlsdata.length > 0)
            {
                var p = {
                    xlsdata:uploadxlsdata,
                    over_ride:1
                }
                // console.log("p ", p)
                redirectURL.post("/forecast/savefyinputdatanew",p)
                .then((resp) => {
                //   console.log(resp.data)
                    if(resp.data.status == 1 || resp.data.status == "1")
                    {
                        this.setState({
                            // uploadfile:"",
                            // showSlideBlockUpload:"",
                            // overlayBlock:"show-n",
                            overly:"show-n",
                            loadshow:"show-n",
                            // show:true,
                            // basicTitle:resp.data.message,
                            // basicType:"warning",
                            showloader:"show-n",
                            duplicateChk : 1,
                            uploadxlsdata : uploadxlsdata
                        });
                    }
                    else if(resp.data.status == 2 || resp.data.status == "2")
                    {
                        var respMessage = "Success";
                        var redirectChk = 0;
                        var basicType = "success";
                        if(resp.data.new_material_list !=undefined && resp.data.new_material_list !="")
                        {
                            var new_material_list= JSON.parse(resp.data.new_material_list);
                            if(new_material_list.length > 0)
                            {
                                respMessage = resp.data.message+" "+new_material_list.join(",");
                                redirectChk = 1;
                                basicType = "warning";
                            }
                        }
                        this.setState({
                            uploadfile:"",
                            showSlideBlockUpload:"",
                            overlayBlock:"show-n",
                            overly:"show-n",
                            loadshow:"show-n",
                            show:true,
                            basicTitle:respMessage,
                            basicType:basicType,
                            showloader:"show-n",
                            redirectChk:redirectChk,
                            duplicateChk : 0
                        });
                    }
                    else{
                        this.setState({
                            show:true,
                            basicTitle:"Failed to upload data",
                            basicType:"danger",
                            showloader:"show-n",
                            overly:"show-n",
                            loadshow:"show-n",
                            duplicateChk : 0
                        });
                    }
                })
            }
        }
    }

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        redirectURL.post("/dispatch/checkTransactionStatus",query).then((response)=>{
            console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode == 0)
            {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount)+1;
                this.setState({
                    checkCount : updatedCnt
                });
                console.log(updatedCnt,"checkCount")
                if(updatedCnt < 3)
                {
                    this.chkStatus(response.data.transactionId);
                }
                else
                {
                    this.setState({                    
                        basicTitle : "File is processing",
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                        loadFile:''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");
                }
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
                this.getDispatchPlanData()
            }
        });
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }

    viewPlanDetails = async(propdata) =>{
        var data = propdata.data;
        let basestring = data.transaction_id;
        let encryptedstring = window.btoa(basestring);
        window.location.href = '/viewdispatchplandetails?'+encryptedstring;
    }


    locationChanged = async(location) =>{
        await this.setState({
            location : location
        })
        await this.getDispatchPlanData()
    }
    fyitems(){
        var items = [];
        var c = moment.parseZone().format("YYYY");
        for(var n = 2011;n<parseInt(c)-1;n++)
        {
            items.push({value:n+"-"+(parseInt(n)+1),label:n+"-"+(parseInt(n)+1)})
        }
        return items;
    }
    onChangeFY = async(fy) =>{
        await this.setState({fy},()=>{console.log("Select ", this.state.fy)})
    }
    onClickTab(oVal){
        if(oVal == "1")
        {
            this.setState({
                datagrid:"show-m",
                chartdata:"show-n",
                datagridTab:"activet",
                chartdataTab:"btn-default"
            })
        }
        if(oVal == "2")
        {
            this.setState({
                datagrid:"show-n",
                chartdata:"show-m",
                datagridTab:"btn-default",
                chartdataTab:"activet"
            })
        }
    }

    showNextChart = (prevChart) =>{
        let fyChart = this.state.fyChart
        // console.log(fyChart,1,this.state.fyChart)
        
            // const yearArray = [  [{ value: "2010-2011", label: "2010-2011" }],  [{ value: "2011-2012", label: "2011-2012" }],  [{ value: "2012-2013", label: "2012-2013" }],  
            // [{ value: "2013-2014", label: "2013-2014" }],  [{ value: "2014-2015", label: "2014-2015" }],  [{ value: "2015-2016", label: "2015-2016" }],  [{ value: "2016-2017", label: "2016-2017" }],  [{ value: "2017-2018", label: "2017-2018" }],  [{ value: "2018-2019", label: "2018-2019" }],  
            // [{ value: "2019-2020", label: "2019-2020" }]];
            const yearArray = [  [{ value: "2011", label: "2011" }],  [{ value: "2012", label: "2012" }],  
            [{ value: "2013", label: "2013" }],  [{ value: "2014", label: "2014" }],  [{ value: "2015", label: "2015" }],  [{ value: "2016", label: "2016" }],  [{ value: "2017", label: "2017" }],  [{ value: "2018", label: "2018" }],  
            [{ value: "2019", label: "2019" }]];
            // for (let year = 2010; year <= 2020; year++) {
            // const yearString = `${year}-${year+1}`;
            // yearArray.push({value: yearString, label: yearString});
            // }

            const valueToFind = fyChart[0].value;
            const idx = findIndexByValue(yearArray, valueToFind);
            // let idx = yearArray.indexOf(fyChart)
            
            if(prevChart != 1 && prevChart.type == 'click'){
                fyChart = yearArray[idx+1]
            }else{
                fyChart = yearArray[idx-1]
            }
            
            
            if(fyChart == undefined){
                fyChart = yearArray[0]
            }
            

            var dtype = this.state.chartseries.dtype;
            
            if(dtype === "default")
            {
                
                // console.log("fyChart ", this.state.fyChart)
                var chartseries = this.state.chartseries.allfymonthwise.data;
                var selectedfymonths = [];
                if(fyChart.length > 0)
                {
                    fyChart.map((dt) => {
                        chartseries.filter((f) => {
                            if(dt.value === f.name)
                            {
                                selectedfymonths.push(f);
                            }
                        });
                    })
                }
                // var selectedfymonths = chartseries.filter((f) => this.state.fyChart.value === f.name);
                var newchartseries = this.state.chartseries;
                newchartseries["selectedfymonths"] = selectedfymonths
                // console.log(newchartseries,'selectedfymonths ', selectedfymonths)
                this.setState({
                    chartseries:newchartseries,
                    fyChart,fyChart
                })
            }
            else{

            var itemcds = [this.state.itemCd];
            var chartseriesmonths= [];
            try {
                if(this.state.chartseries.itemallfymonthwise.data.length > 0)
                {
                    var chartseriesmon = this.state.chartseries.itemallfymonthwise.data;
                    // console.log('chartseriesmon , ',chartseriesmon)
                    var itmdata = groupBy(chartseriesmon, rdata => rdata.itemname);
                    // console.log("itmdata ", itmdata)
                    itmdata.forEach((vals,keys)=>{
                        if(this.state.itemCd.value === keys)
                        {
                            if(vals.length > 0)
                            {
                                var itmdatayrs = groupBy(vals, rdata => rdata.name);
                                itmdatayrs.forEach((vlasd,mk) => {
                                    if(vlasd.length > 0)
                                    {
                                        if(fyChart.length > 0)
                                        {
                                            fyChart.map((dt) => {
                                                vlasd.filter((f) => {
                                                    if(dt.value === f.name && f.itemname === this.state.itemCd.value)
                                                    {
                                                        chartseriesmonths.push(f);
                                                    }
                                                });
                                            })
                                        }
                                    }
                                    
                                })
                                
                            }
                        }
                    })
                }
                else{

                }    
            } catch (error) {
                
            }
            var selectedfymonths = [];
            if(itemcds.length > 0)
            {
                itemcds.map((dt) => {
            chartseriesmonths.filter((f) => {
                if(dt.value === f.itemname)
                {
                    selectedfymonths.push(f);
                }
            });
            })
            }

            var newchartseries = this.state.chartseries;
            newchartseries["selectedfymonths"] = selectedfymonths
            // console.log('selectedfymonths ', selectedfymonths)
            this.setState({
                chartseries:newchartseries,
                fyChart:fyChart
            })
        }
    }

    showPreviousChart = () =>{               
          this.showNextChart(1);
    }


    onChangeFYData(fyChart){
        this.setState({fyChart},()=>{
            var dtype = this.state.chartseries.dtype;
            // console.log("dtype ", dtype)
            if(dtype === "default")
            {
                
                // console.log("fyChart ", this.state.fyChart)
                var chartseries = this.state.chartseries.allfymonthwise.data;
                var selectedfymonths = [];
                if(this.state.fyChart.length > 0)
                {
                    this.state.fyChart.map((dt) => {
                        chartseries.filter((f) => {
                            if(dt.value === f.name)
                            {
                                selectedfymonths.push(f);
                            }
                        });
                    })
                }
                // var selectedfymonths = chartseries.filter((f) => this.state.fyChart.value === f.name);
                var newchartseries = this.state.chartseries;
                newchartseries["selectedfymonths"] = selectedfymonths
                // console.log('selectedfymonths ', selectedfymonths)
                this.setState({
                    chartseries:newchartseries
                })
            }
            else{
                var itemcds = [this.state.itemCd];
                // console.log("fyChart ", this.state.fyChart)
                var chartseriesmonths= [];
                try {
                    if(this.state.chartseries.itemallfymonthwise.data.length > 0)
                    {
                        var chartseriesmon = this.state.chartseries.itemallfymonthwise.data;
                        // console.log('chartseriesmon , ',chartseriesmon)
                        var itmdata = groupBy(chartseriesmon, rdata => rdata.itemname);
                        // console.log("itmdata ", itmdata)
                        itmdata.forEach((vals,keys)=>{
                            if(this.state.itemCd.value === keys)
                            {
                                if(vals.length > 0)
                                {
                                    var itmdatayrs = groupBy(vals, rdata => rdata.name);
                                    itmdatayrs.forEach((vlasd,mk) => {
                                        if(vlasd.length > 0)
                                        {
                                            if(this.state.fyChart.length > 0)
                                            {
                                                this.state.fyChart.map((dt) => {
                                                    vlasd.filter((f) => {
                                                        if(dt.value === f.name && f.itemname === this.state.itemCd.value)
                                                        {
                                                            chartseriesmonths.push(f);
                                                        }
                                                    });
                                                })
                                            }
                                        }
                                        
                                    })
                                    
                                }
                            }
                        })
                    }
                    else{
    
                    }    
                } catch (error) {
                    
                }
                var selectedfymonths = [];
                if(itemcds.length > 0)
                {
                    itemcds.map((dt) => {
                chartseriesmonths.filter((f) => {
                    if(dt.value === f.itemname)
                    {
                        selectedfymonths.push(f);
                    }
                });
                })
                }


                // var chartseries = this.state.chartseries.allfymonthwise.data;
                // var selectedfymonths = [];
                // if(this.state.fyChart.length > 0)
                // {
                //     this.state.fyChart.map((dt) => {
                //         chartseries.filter((f) => {
                //             if(dt.value === f.name)
                //             {
                //                 selectedfymonths.push(f);
                //             }
                //         });
                //     })
                // }
                // var selectedfymonths = chartseries.filter((f) => this.state.fyChart.value === f.name);
                var newchartseries = this.state.chartseries;
                newchartseries["selectedfymonths"] = selectedfymonths
                // console.log('selectedfymonths ', selectedfymonths)
                this.setState({
                    chartseries:newchartseries
                })
            }
        })
    }
    onSelectFYData(selectFY){
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
            overlayBlock:"show-m",
        })
        this.setState({selectFY},()=>{
            this.loadInputPlanData()
        })
    }
    onChangeItemCode(itemCd){
        this.setState({itemCd})
    }
    updateMaterialCode = (newValue) => {
        
        this.setState({itemCd:newValue})
    }
    onClickGetItemData(){
        var chartseries = this.state.chartseries;
        var newchartseries = this.state.chartseries;
        
        if(this.state.itemCd.value === "")
        {
           
            this.setState({showMaterialCount:1})
            // newchartseries["dtype"] = "default"
            // newchartseries["overallfinancialorders"] =chartseries.all_overallfinancialorders;
            // newchartseries["overallfinancialcost"] = chartseries.all_overallfinancialcost;
            // newchartseries["allfymonthwise"] = chartseries.all_allfymonthwise;
            // newchartseries["allfymonthwiseCost"] = chartseries.all_allfymonthwiseCost;
            // newchartseries["selectedfymonths"] = chartseries.all_selectedfymonths;
            // newchartseries["selectedfymonthsCost"] = chartseries.all_selectedfymonthsCost;
            // newchartseries["all_fys"] = chartseries.all_fys
            this.loadInputPlanData()
        }
        else{
            this.setState({showMaterialCount:2})
            var itemcds = [this.state.itemCd];
            // console.log("chartseries ", chartseries)
            chartseries["dtype"] = "item"
            var chartseries = this.state.chartseries.itemoverallorders.data;
            var chartseriescost = this.state.chartseries.itemoverallcost.data;
            var chartseriesmonths= [];
            try {
                if(this.state.chartseries.itemallfymonthwise.data.length > 0)
                {
                    var chartseriesmon = this.state.chartseries.itemallfymonthwise.data;
                    // console.log('chartseriesmon , ',chartseriesmon)
                    var itmdata = groupBy(chartseriesmon, rdata => rdata.itemname);
                    // console.log("itmdata ", itmdata)
                    itmdata.forEach((vals,keys)=>{
                        if(this.state.itemCd.value === keys)
                        {
                            if(vals.length > 0)
                            {
                                var itmdatayrs = groupBy(vals, rdata => rdata.name);
                                itmdatayrs.forEach((vlasd,mk) => {
                                    if(vlasd.length > 0)
                                    {
                                        if(this.state.fyChart.length > 0)
                                        {
                                            this.state.fyChart.map((dt) => {
                                                vlasd.filter((f) => {
                                                    if(dt.value === f.name && f.itemname === this.state.itemCd.value)
                                                    {
                                                        chartseriesmonths.push(f);
                                                    }
                                                });
                                            })
                                        }
                                    }
                                    
                                })
                                
                            }
                        }
                    })
                }
                else{

                }    
            } catch (error) {
                
            }
            
            var itemoverallorders = [];
            var itemoverallcostfy = [];
            var selectedfymonths = [];
            var all_fys = [];
            if(itemcds.length > 0)
            {
                itemcds.map((dt) => {
                    chartseries.filter((f) => {
                        if(dt.value === f.itemname)
                        {
                            itemoverallorders.push(f);
                            
                            if (!all_fys.includes(f.name)) {
                                all_fys.push(f.name);
                              }
                        }
                    });
                    chartseriescost.filter((f) => {
                        if(dt.value === f.itemname)
                        {
                            itemoverallcostfy.push(f);
                        }
                    });
                    chartseriesmonths.filter((f) => {
                        if(dt.value === f.itemname)
                        {
                            selectedfymonths.push(f);
                        }
                    });

                    
                })
            }else{
                itemoverallorders = chartseries.all_overallfinancialorders
            }
            // console.log("selectedfymonths ",selectedfymonths)
            // var selectedfymonths = chartseriesmonths.filter((f) => this.state.fyChart.value === f.name);
            // var newchartseries = this.state.chartseries;
            

            newchartseries["overallfinancialorders"] = {name:"Orders",colorByPoint: true,data:itemoverallorders}
            newchartseries["all_fys"] = [all_fys]
            newchartseries["overallfinancialcost"] = {name:"Cost",colorByPoint: true,data:itemoverallcostfy}
            // chartseries["allfymonthwise"] = {name:"Orders",colorByPoint: true,data:fynonths}
            // chartseries["allfymonthwiseCost"] = {name:"Orders",colorByPoint: true,data:fynonthsCost}
            newchartseries["selectedfymonths"] = selectedfymonths
            // chartseries["selectedfymonthsCost"] = selectedfymonthsCost
            // chartseries["historic"] = historify
        }
        this.setState({
            chartseries:newchartseries
        })
    }

    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    }
    onGridReady1 = (params) => {
		this.gridApiNew = params.api;
		this.gridColumnApiNew = params.columnApi;

    }
    onBtnExport = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApi.exportDataAsCsv(
            {
                fileName: filename
              }
        );
    }
    onBtExportExcel = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApi.exportDataAsExcel(
            {
                fileName: filename
              }
        );
    }
    onBtnExportF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew.exportDataAsCsv(
            {
                fileName: filename
              }
        );
    }
    onBtExportExcelF = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_${timestamp}`;
        this.gridApiNew.exportDataAsExcel(
            {
                fileName: filename
              }
        );
    }
    onChangeMaterial(material_code){
        this.setState({material_code})
    }
    onChangeDuration(duration){
        this.setState({duration},()=>{
            if(this.state.duration.value === 0){
                this.setState({
                    inpShow:"show-m"
                })
            }
            else{
                this.setState({
                    inpShow:"show-n"
                })
            }
        })
    }    
    changeHandler(event){
        var name= event.target.name;
        var value= event.target.value;
        if(value <= 31)
        {
            this.setState({
                [name]:value
            })
        }
    }
    onSubmitGetData=async(e)=>{
        e.preventDefault();
        var material_code = this.state.material_code;
        var duration = this.state.duration;
        // console.log(material_code,duration,"material_code-duration")
    }
    render() {

        var columnwithDefs = [
            
            {
                headerName: "Financial Year",
                field: "fy",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Material Code",
                field: "item_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            // {
            //     headerName: "Customer Code",
            //     field: "customer_code",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "NRB Customer Code",
            //     field: "nrb_customer_code_1",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "Apr",
                field: "Apr",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "May",
                field: "May",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jun",
                field: "Jun",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jul",
                field: "Jul",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Aug",
                field: "Aug",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Sep",
                field: "Sep",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Oct",
                field: "Oct",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Nov",
                field: "Nov",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Dec",
                field: "Dec",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jan",
                field: "Jan",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Feb",
                field: "Feb",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Mar",
                field: "Mar",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            }
           
            
        ];

        var templatePath = require('../../assets/json/materials_consumption_input_data_template.csv');
        return (
            <div class="container-fluid">
                <div className={"animatenow "+(this.state.showanimate)} style={{textAlign:"center"}}>
                    <span className="pp-h">Processing Plan. Please wait.....</span>
                    <img src={require("../../assets/images/r.gif")} style={{width:"85vh"}} />
                </div>
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                    onCancel={this.ignoreAlert}
                    showCancelButton={true}
                    cancelButtonText={"No, cancel!"}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            Material - Historic Data
                            {/* <button type="button" className="btn btn-warning float-right font-white" onClick={this.onClickShowUpload}>Upload Material Consumption Data</button>
                            <button type="button" className="btn btn-danger float-right font-white" onClick={this.onClickShowTrigger}>Trigger Learning</button> */}
                        </h5>
                        {/* <span className='float-right redColor'>Note :- Only 10,773 critical materials are considered for analysis<br/>Prediction period is from 2020-Jan to 2020-June.</span> */}
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-sm-3">
                        <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Select FY: </label>
                        <div className="col-sm-8">
                            <Select
                                value={this.state.selectFY}
                                options={this.fyitems()}
                                onChange={this.onSelectFYData.bind(this)}
                            />
                        </div>
                    </div>
                </div> */}
                {/* <div className="row mt-20p">
                    <div className="col-md-12 col-sm-12">
                        <ul className="d-tabs">
                            <li onClick={this.onClickTab.bind(this,"1")} className={"lttabs "+(this.state.datagridTab)}>
                                <button type="button" className="btn">Summary Data</button>
                            </li>

                            <li onClick={this.onClickTab.bind(this,"2")} className={"lttabs "+(this.state.chartdataTab)}>
                                <button type="button" className="btn">Data Visualization</button>
                            </li>

                        </ul>
                    </div>
                </div> */}
                
                {/* <div className={"row "+(this.state.chartdata)}>
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="row">
                                <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Customer: </label>
                                <div className="col-sm-8">
                                    <Select
                                        value={this.state.fyChart}
                                        options={this.fyitems()}
                                        onChange={this.onChangeFYData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="row">
                                <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Material: </label>
                                <div className="col-sm-8">
                                    <Select
                                        value={this.state.fyChart}
                                        options={this.fyitems()}
                                        onChange={this.onChangeFYData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class={"row mb-20p "+(this.state.chartdata)}>
                    {/* comment */}
                    <div className={"col-sm-3 mt-20p"} >
                        {/* <Select                         */}
                        {/* <ReactWindowSelectDropdown
                            // defaultValue={null}
                            selectedOptions={this.state.itemCd}
                            // isMulti={true}
                            options={this.state.itemCodesList}
                            onChange={this.onChangeItemCode.bind(this)}
                        /> */}
                        {/* comment for now */}
                        <MaterialList options={this.state.itemCodesList} updateMaterialCode={this.updateMaterialCode}></MaterialList>
                    </div>
                    <div className={"col-sm-2 mt-20p"} >
                        {/* <button type="button" className="btn btn-danger" onClick={this.onClickGetItemData.bind(this)}>Get Data</button> */}
                        <button type="button" className="btn btn-danger" onClick={this.getMaterialOrderDataFromApi}>Get Data</button>
                    </div>
                    {/* comment for now  */}
                    {/* <div className={"mxheit col-sm-12 mt-20p"} >
                        <div className="card">
                            <div className="card-body">
                                <ColumnChart
                                    title={"Consumption Maintenance Order"}
                                    subtitle={""}
                                    series={this.state.chartseries.overallfinancialorders}
                                    fyMaterials={this.state.fyMaterials}
                                    predictedMaterialCount={this.state.predictedMaterialCount}
                                    showMaterialCount={this.state.showMaterialCount}
                                    predictedResult = {this.state.predictedChartData}
                                    drillDownData = {this.state.drillDownData}
                                    // categories={this.state.chartseries.all_fys}
                                />

                            </div>
                        </div>
                    </div> */}
                    <div className={"mxheit col-sm-12 mt-20p"} >
                        <div className="card">
                            <div className="card-body">
                                <TimeSeriesChartComponent
                                    title={"Consumption Maintenance Order"}
                                    subtitle={""}
                                    apiOrderData={this.state.apiOrderData}
                                    predApiOrderData={this.state.predApiOrderData}
                                    drillDownData = {this.state.drillDownData}
                                />
                               
                            </div>
                        </div>
                    </div>
                    <div className={"mxheit col-sm-12 mt-20p"} >
                        <div className="card">
                            <div className="card-body">
                                <SplineChartComponent
                                    title={"Predicted vs Actual Quantity Consumption Chart"}
                                    // subtitle={"Note:- For most of the materials actual consumption is yet to be given"}
                                    drillDownData = {this.state.drillDownData}
                                />
                               
                            </div>
                        </div>
                    </div>
                    {/* <div className={"mxheit col-sm-12 mt-20p"} >
                        <div className="card">
                            <div className="card-body">
                                <ColumnChart
                                    title={"Cumulative Price Summary"}
                                    subtitle={""}
                                    series={this.state.chartseries.overallfinancialcost}
                                    categories={this.state.chartseries.all_fys}
                                />
                               
                            </div>
                        </div>
                    </div> */}
                    {/* comment for now <div className={"mxheit col-sm-12 mt-20p"}>
                        <div className="card"> */}
                            {/* <div className="card-header">
                                <div style={{width:"100%",zIndex:"9"}}>
                                    <div className="row">
                                        <label className="col-sm-2 mt-10p" style={{color:"#000"}}> FY: </label>
                                        <div className="col-sm-10">
                                            <Select
                                                value={this.state.fyChart}
                                                isDisabled={true}
                                                isMulti={true}
                                                options={this.fyitems()}
                                                onChange={this.onChangeFYData.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* comment for now <div className="card-body">
                                <div className='float-right'>FY : {this.state.fyChart[0].value}</div>
                                    <PolarChart
                                        title={"Monthly Order Trend"}
                                        subtitle={""}
                                        series={this.state.chartseries.selectedfymonths}
                                    />
                               <div className='d-flex justify-content-center f16 fw-600'>
                                {this.state.fyChart[0].value != '2011' ?
                                    <span className='d-flex align-items-center' onClick={this.showPreviousChart}> <i class="fa fa-chevron-circle-left f26" aria-hidden="true"></i>
                                        &nbsp;&nbsp;Prev</span> 
                                        :''}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className='d-flex align-items-center' onClick={this.showNextChart}>Next&nbsp;&nbsp;
                                    <i class="fa fa-chevron-circle-right f26" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    
                    {/* <div className={"mxheit col-sm-6 mt-20p"}>
                        <div className="card">
                            <div className="card-body">
                                <div style={{position:"absolute",right:"10px", top:"10px",width:"30%",zIndex:"9"}}>
                                    <div className="row">
                                        <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Select FY: </label>
                                        <div className="col-sm-8">
                                            <Select
                                                value={this.state.fyChart}
                                                options={this.fyitems()}
                                                onChange={this.onChangeFYData.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <AreaRangeChart
                                    title={"Monthly Costs FY "+(this.state.fyChart.value)}
                                    series={this.state.chartseries.historic}
                                    predictseries={this.state.chartseries.selectedfymonths}
                                />
                            </div>
                        </div>
                    </div> */}
                    <br /><br />
                </div>
                <div class={"row mb-20p "+(this.state.datagrid)}>
                    <div className="row">
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-default float-right" onClick={this.onBtnExport.bind(this)}>Export to CSV</button>
                            <button type="button" className="btn btn-default float-right" onClick={this.onBtExportExcel.bind(this)}>Export to Excel</button>
                        </div>
                    </div>
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                // masterDetail={true}
                                // detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                    <div className="row">
                        {(this.state.sliderType == 1)?
                        <div className="col-sm-12">
                            {this.state.processErrShow == 0 ?
                            <form onSubmit={this.uploadBulkFormHandler.bind(this)}>
                                <div className="form-group col-sm-12">
                                    <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>
                                </div>
                                <div className="row p-20p">
                                    {/* <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Select FY</label> 
                                        <Select
                                            value={this.state.fy}
                                            options={this.fyitems()}
                                            onChange={this.onChangeFY.bind(this)}
                                        />
                                    </div>  */}
                                    {(this.state.duplicateChk == 1)?
                                    <div className="form-group col-sm-12 mb-20p">
                                        <h5>Data Uploaded has existing information, Do you wish to proceed and overwrite existing data</h5>
                                    </div>
                                    :
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Upload File</label> 
                                        <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                                    </div>                                    
                                    }
                                    <div className="form-group col-sm-12 mb-20p">
                                        {(this.state.duplicateChk == 1)? 
                                            <button type="button" className="btn btn-warning" onClick={this.overRideExistingData.bind(this)}>PROCEED</button>
                                        :
                                            <button type="submit" className="btn btn-info">SAVE</button>
                                        }                                        
                                        <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                            :
                            ""
                            }
                        </div>
                        :""}
                        {(this.state.sliderType == 2)?
                            <div className="col-md-12">
                                <Mlist options={this.state.forecastitemslist} durationlist={this.state.durationlist}></Mlist>
                            </div>
                        :""}
                    </div>
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                <div className={"loader "+this.state.loadshow}></div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>
            </div>
        )
    }
}
function arr_diff (a1, a2) {

    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}
function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
        //  console.log('heel',key,item)
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function getFinancialYear(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    
    if (month >= 3) {
      // If the month is April or later, return the financial year as the current year - next year
      return [month,`${year}-${year + 1}`];
    } else {
      // Otherwise, return the financial year as the previous year - current year
      return [month,`${year - 1}-${year}`];
    }
  }

  function getYear(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    
    if (month >= 3) {
      // If the month is April or later, return the financial year as the current year - next year
      return [month,`${year}`];
    } else {
      // Otherwise, return the financial year as the previous year - current year
      return [month,`${year}`];
    }
  }
  
  function findIndexByValue(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0].value === value) {
        return i;
      }
    }
    return -1;
  }