
import React, { Component } from 'react';

 
const ViewTransData = (props) => {
    
   const handleOnClick = () => {
        props.colDef.params.onClickFunction(props,true);
    };
    return (
        <div>
           {(props.data.status === "success")?
            <a href={"/forecast-output?"+(props.data.transaction_id)} className="btn btn-warning grid-btn" style={{color:"#000",border:"0px"}}>
                <i className={"fa fa-eye"}></i> View Data
            </a>
            :""}
        </div>
    );
};

export default ViewTransData;
