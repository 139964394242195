import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class ColumnChartComponent extends Component {

    constructor(props) {
        super(props);
		this.state={
			seriesData:[]
		}
		// console.log(props,'prop')
    }

	componentWillReceiveProps(newprops){
		// console.log(newprops,'newprops')
		setTimeout(()=>{

		if(newprops.series != undefined && newprops.showMaterialCount == 2){
			this.setState({
				seriesData : newprops.series
			})
		}
	},500);
		
	}

    render(){
		// let categories = ["2010-2011","2011-2012","2012-2013","2013-2014","2014-2015","2015-2016","2016-2017","2017-2018","2018-2019","2019-2020"]
		let categories = ["2011","2012","2013","2014","2015","2016","2017","2018","2019"]
		
		// console.log(this.props)
		let orderData = [];
		
		if(this.props.series != undefined){
		orderData = this.props.series.data

		if (orderData.length < 10){
			let fys = categories.filter(item =>{
				return !orderData.some((object) => object.name === item);
			})


			if(fys.length>0){
				fys.map(item =>orderData.push({name: item, y: 0, lbl: '0'}))
			}
			}
		}

		orderData.sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		  });
		if(this.props.title == 'Consumption Maintenance Order' && this.props.series != undefined && this.props.series.data.length<10){
			this.props.series.data = orderData
		}
		
		if(this.props.categories != undefined ){
			categories = this.props.categories[0]
		}
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		let highchartoptions
		if(this.props.showMaterialCount == 1){
		let areadata = []
		let predictData = []
		if(this.props.series){
			areadata = this.props.series.data
			let dataPt = 0
			if(this.props.title == 'Consumption Maintenance Order'){
				areadata.map((chrtData,idex) =>{
					dataPt = idex
					chrtData.x=idex;
					chrtData.color='blue'
				})
				if(this.props.predictedResult.length>0){
				predictData = Array.from(this.props.predictedResult)
				predictData.map(item =>{
					item.x = dataPt+1;
					item.color='red';item.drilldown='202'})
					predictData.unshift(areadata[areadata.length -1])
				categories.push('2020')
				}
				
			}
		}

		let fyMaterialsData = []
		let predictedfyMaterialsData = []
		if(this.props.fyMaterials){
			fyMaterialsData = this.props.fyMaterials
			let dataPt = 0
			if(fyMaterialsData.length >0){
				fyMaterialsData.map((chrtData,idex) =>{
					dataPt = idex
					chrtData.x=idex;
					chrtData.color='blue'
				})
				if(this.props.predictedMaterialCount.length>0){
				predictedfyMaterialsData = Array.from(this.props.predictedMaterialCount)
				predictedfyMaterialsData.map(item =>{
					item.x = dataPt+1;
					item.color='red';
					item.drilldown='20'
				})
					predictedfyMaterialsData.unshift(fyMaterialsData[fyMaterialsData.length -1])
				categories.push('2020')
				}
				
			}
		}

		// console.log(areadata,'data')
		let chartsIn = [
			{
                type: 'spline',
                showInLegend: true,
                name: 'Order Count',
                data: areadata,
				color:'#457b9d',
                marker: {
                    enabled: false
                },
				yAxis:1
            },
			{
				type: 'spline',
				showInLegend: true,
				name: 'Material Count',
				color:'#52b788',
				data: fyMaterialsData,
				marker: {
					enabled: false
				},
				yAxis:0
			}
		]
		

			if(this.props.predictedResult != undefined && this.props.showMaterialCount == 1){
			if(this.props.predictedResult.length>0){


				chartsIn.push({
					type: 'spline',
					showInLegend: true,
					name: 'Predicted Order Count',
					data: predictData,
					marker: {
						enabled: false
					},
					color:'#ffae49',
					yAxis:1
				})
			}
		}

		if(this.props.predictedMaterialCount != undefined && this.props.showMaterialCount == 1){
			if(this.props.predictedMaterialCount.length>0){


				chartsIn.push({
					type: 'spline',
					showInLegend: true,
					name: 'Predicted Material Count',
					data: predictedfyMaterialsData,
					marker: {
						enabled: false
					},
					color:'#41ead4',
					yAxis:0
				})
			}
		}
		
		highchartoptions = {
	      		 
			// chart: {
			// 	type: 'area'
			// },
			chart: {
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
            },
			title: {
				align: 'left',
				text: this.props.title
			},
			subtitle: {
				align: 'left',
				text: this.props.subtitle
			},
			accessibility: {
				announceNewData: {
					enabled: true
				}
			},
			xAxis: {
				// type: 'category'
				// categories:["2011-2012","2012-2013","2013-2014","2014-2015","2015-2016","2016-2017","2017-2018","2018-2019","2019-2020","2020-2021","2021-2022"]
				categories:categories
			},
			yAxis: [{ // Primary yAxis
				labels: {
					format: '',
					style: {
						color: '#52b788'
					}
				},
				title: {
					text: 'Material Count',
					style: {
						color: "#52b788"
					}
				},
				opposite: true
		
			}, { // Secondary yAxis
				gridLineWidth: 0,
				title: {
					text: 'Order Count',
					style: {
						color: '#457b9d'
					}
				},
				labels: {
					format: '',
					style: {
						color: '#457b9d'
					}
				}
		
			}],
			legend: {
				enabled: true
			},
			plotOptions: {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '{point.lbl}'
					}
				}
			},
		
			tooltip: {
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.lbl}'
			},
		
			series: chartsIn,
			drilldown: {breadcrumbs: {
				position: {
					align: 'right'
				}
			},
			categories:["Jan","Feb","March","April","May","June"],
			series: [				
				{
					name: 'Actual Material Count',
					id: '20',
					data: this.props.drillDownData[0],
					color:"#41ead4",
					
					
				},
				{
					name: 'Predicted Material Count',
					id: '202',
					data: this.props.drillDownData[1],
					color:"#ffae49"
				}
			]},
			credits:false
			};
		}else{
			highchartoptions = {
	      		 
				chart: {
					type: 'line'
				},
				title: {
					align: 'left',
					text: this.props.title
				},
				subtitle: {
					align: 'left',
					text: this.props.subtitle
				},
				accessibility: {
					announceNewData: {
						enabled: true
					}
				},
				xAxis: {
					// type: 'category'
					// categories:["2011-2012","2012-2013","2013-2014","2014-2015","2015-2016","2016-2017","2017-2018","2018-2019","2019-2020","2020-2021","2021-2022"]
					categories:categories
				},
				yAxis: {
					title: {
						text: "Order Count"
					},
					labels: {
						format: '',
						style: {
							color: '#457b9d'
						}
					},
					opposite:false
			
				},
				legend: {
					enabled: true
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							format: '{point.lbl}'
						}
					}
				},
			
				tooltip: {
					headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
					pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.lbl}'
				},
			
				series: this.state.seriesData,
				drilldown: {},
				credits:false
				};
		}

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












