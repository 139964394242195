import React, { Component, useState } from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import redirectURL from '../redirectURL';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';

const height = 35;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const CompareActualAndPredictedData = (props) => {
    const options = props.options;
    const durationlist = props.durationlist
    const [material_code, setMaterialCode] = useState([]);
    const [show, setShow] = useState(false);
    const [basicTitle, setBasicTitle] = useState('');
    const [basicType, setBasicType] = useState('default');
    const [duration, setDuration] = useState(null);
    const {compareData} = props
    var overlayBlock = "show-n";
    var loadshow = "show-n";
    const onChangeMaterial = (newValue) => {
        setMaterialCode(newValue);
    };

    const onChangeDuration = (newValue) => {
        setDuration(newValue);
    }

    const updateRecords = (actualRecords,predictedRecords,mat_code) => {
        compareData(
            actualRecords,
            predictedRecords,
            mat_code,
            duration.value
        )

    }

    const onSubmitGetData = async (e) => {
        e.preventDefault();
        let check = 0;
        
        if (material_code.value != '') {
            // var materialCodes = material_code.map((mc) => mc.value);
            
                // if(materialCodes.indexOf("all") >= 0)
                // {
                //     var mcodes = [];
                //     await options.map((on)=>{
                //         if(on.value !="all")
                //         {
                //             mcodes.push(on.value)
                //         }
                //     });
                //     var materialCodes = $.grep(mcodes, function(value) {
                //         return value !== "all";
                //     });
                // }
                
                if(duration !=undefined && duration !="")
                {
                    let mat_code = material_code.value
                    var durationVal = duration.value;
                    if(durationVal !=undefined && durationVal !="")
                    {
                        check = 1;
                        overlayBlock = "show-m";
                        loadshow = "show-m";
                        const params = {
                            item_code: material_code.value,
                            fc_period: durationVal,
                        }
                        redirectURL.post('/forecast/getpredicteddatamaterialwise', params)
                        .then(response => {
                            // console.log(response.data,'response.data')
                            
                            if (response.data.status === "Success") {

                                let actualRecords = response.data.actualRecords
                                let predictedRecords = response.data.predictedRecords
                                updateRecords(actualRecords,predictedRecords,mat_code)

                            } else {
                                setBasicTitle("Something went wrong!!!");
                                setBasicType('danger');
                            }
                            overlayBlock = "show-n";
                            loadshow = "show-n";
                        });
                    }
            }
        }
        if (check === 0) {
            setShow(true);
            setBasicTitle("Select the Material and Prediction Horizon to continue");
            setBasicType('warning');
        }
    }

    const closeAlert = () => {
        setShow(false);
        if(basicType == "success")
        {
            window.location.reload();
        }
    }

    return (
        <form method="POST" onSubmit={onSubmitGetData}>
            <div className={"overlay-block "+(overlayBlock)}></div>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            />
            <div className="row">
                <div className="col-md-2">
                    <div className="form-group ">
                        <label className="col-form-label f12">Material</label>
                        <Select 
                            placeholder={"Select"}
                            // isMulti={true}
                            onChange={onChangeMaterial}
                            name="material_code"
                            value={material_code} 
                            components={{ MenuList }} 
                            options={options} 
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group ">
                        <label className="col-form-label f12">Prediction Horizon</label>
                        <Select
                            placeholder={"Select"}
                            onChange={onChangeDuration}
                            name="duration"
                            value={duration}
                            options={durationlist}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group ">
                    <button type="submit" className="btn btn-danger mt-30p">Compare</button>
                    </div>
                </div>
            </div>
            <div className={"loader "+loadshow}></div>
        </form>
    );
};

export default CompareActualAndPredictedData;
