import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default class ColumnGroupChartComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
            chart: {
                renderTo:'container',
                type:'column'
            },
            title:{
                text:this.props.title,
                style: {
                    color: "#f3d800"
                }
            },
            credits:{enabled:false},
            legend:{
                enabled:true,
                color: '#f3d800',
                itemStyle:{
                    color:'#f3d800'
                }
                
            },
            plotOptions: {
                column: {
                    // colorByPoint: true
                },
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },
            
            colors: this.props.color,
            xAxis:{
                categories: this.props.categories,
                labels: {
                    style: {
                        color: '#f3d800'
                    }
                },
                title: {
                    style: {
                        color: '#f3d800'
                    }
                }
            },
            yAxis:{
                labels: {
                    style: {
                        color: '#f3d800'
                    }
                },
                title: {
                    style: {
                        color: '#f3d800'
                    }
                }
            },    
            series: this.props.series 
        }
                // console.log(this.props.chartType ,"this.props.chartTyp");
            highchartoptions.chart= {type: this.props.chartType,backgroundColor:'rgba(0,0,0,0)',style: {
				color: "#fff"
			}};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












