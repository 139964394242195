import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class LineChart extends Component {

    constructor(props) {
        super(props);
        // console.log(props,'popop')
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		// console.log("this.props.series ", this.props.series)
		const highchartoptions = {
	      		 
			chart: {
				type: 'spline',
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
                
			},
			title: {
                text: this.props.title,
                align: 'center'
            },
        
            subtitle: {
                text: this.props.subtitle,
                align: 'center'
            },
        
            yAxis: {
                title: {
                    text: 'Trend'
                }
            },
        
            xAxis: {
                type:"category"
                // categories: ["2016","2017","2018","2019","2020","2021","2022"]
            },
        
            legend: false,
            tooltip: {
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.lbl}',
                valueDecimals: 0
			},
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    dataLabels: {
						enabled: false,
						format: '{point.lbl}'
					},
                    data: this.props.confLineTrend,
                    
                    pointInterval   : 24 * 3600 * 1000 * 31,
                    dataGrouping: {
                        approximation: 'average',
                        enabled: true,
                        forced: true,
                        // units: [
                        //     ['hour', [2]]
                        // ]
                    },
                    marker:{
                        enabled:false
                    }
                    // pointStart: 2016
                }
            },
        
            series: [{
                regression: true,
                
                name:"Trend",
                data:this.props.confLineTrend
            }],
        
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
			credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%', fontWeight: "bold", fontSize: "15px", color: "black" } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}