import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import redirectURL from '../redirectURL';
import withRouter from '../withRouter';
import AnomalyColumnChart from './anomalycolumnchart';
class AnomalyAnalysis extends Component {
    constructor(props){
        super(props);
        this.state = {
            show:false,
            basicTitle:"",
            basicType:"",
            anomalyYear:{value:"",label:""},
            chartseries:[],
            yearlychartseries:[],
            chartcountseries:[],
            loadshow1:"show-n",
            phase: {"value": "", "label": ""},
            phases: []
        }
    }
    componentDidMount = async () => {
        await redirectURL.post("/dashboard/phaselists")
        .then((response) => {
            var records = response.data.records;
            var recordsarr = [];
            if(records.length > 0)
            {
                records.map((itm) => {
                    recordsarr.push({value:itm,label:itm});
                })
            }
            this.setState({
                phases:recordsarr.reverse(),
                phase: recordsarr[0]
            }, () => {this.loadAnomaylyChartData(), this.loadYearlyAnomaylyChartData();})
        })
        // await setTimeout(() => {
        //     this.loadCounterData();
        // },500);
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    anomalyYearsList(){
        var items = [{value:"",label:"Select"}];
        items.push({
            value:1,label:"1+ Year"
        },{
            value:2,label:"2+ Years"
        },{
            value:3,label:"3+ Years"
        })
        return items;
    }
    onChangeAnomalyYear(anomalyYear){
        this.setState({anomalyYear})
    }
    onClickGetData(event){
        event.preventDefault();
        if(this.state.anomalyYear.value !== "")
        {
            var params ={
                anomalyYear:this.state.anomalyYear.value
            }
            this.loadAnomaylyChartData(params);
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select anomaly year item",
                basicType:"info"
            })
        }
    }

    loadAnomaylyChartData(){
        var params ={
            anomalyYear: 3,
            phase:this.state.phase.value
        }
        console.log(this.state.phase, "123")
        this.setState({
            // loadshow1:"show-m"
        })
        redirectURL.post("/forecast/anomalyanalysis",params)
        .then((response) => {
            // console.log("resonse", response.data)
            try {
                var chartactualdata = JSON.parse(response.data.anomaly_data);
                // console.log("chartactualdata ", chartactualdata)
                var actualSeries = [];
                var actualCountSeries = [];
                if(chartactualdata.length > 0)
                {
                    chartactualdata.map((itm) => {
                        actualSeries.push({
                            name:itm.year,
                            y:itm.cost
                        })
                        actualCountSeries.push({
                            name:itm.year,
                            y:itm.material_count
                        })
                    })
                }
                var predchart =  JSON.parse(response.data.predicted_data);
                // console.log("predchart ", predchart)
                var predSeries = [];
                var predCountSeries = [];
                if(predchart.length > 0)
                {
                    predchart.map((itm) => {
                        predSeries.push({
                            name:itm.year,
                            y:itm.cost
                        })
                        predCountSeries.push({
                            name:itm.year,
                            y:itm.pred_total_material
                        })
                    })
                }
                var series=[
                    {
                        name:"Actual Cost",
                        data:actualSeries
                    },
                    {
                        name:"Predicted Cost",
                        data:predSeries,
                        color:"#ff0000"
                    }
                ]
                var series2=[
                    {
                        name:"Actual Material Count",
                        data:actualCountSeries
                    },
                    {
                        name:"Predicted Material Count",
                        data:predCountSeries,
                        color:"#ff0000"
                    }
                ]
                this.setState({
                    chartseries:series,
                    chartcountseries:series2,
                    // loadshow1:"show-n"
                })
                setTimeout(() => {

                    this.loadCounterData();
                },500)
                
            } catch (error) {
                    
            }
        })
    }
    
    loadYearlyAnomaylyChartData(){
        this.setState({
            // loadshow1:"show-m"
        })
        
        redirectURL.post("/forecast/getYearWiseNewMaterialsData")
        .then((response) => {
            // console.log("resonse", response.data)
            try {
                var chartactualdata = JSON.parse(response.data.new_material_info);
                // console.log("chartactualdata ", chartactualdata)
                var actualSeries = [];
                var actualCountSeries = [];
                if(chartactualdata.length > 0)
                {
                    chartactualdata.map((itm) => {
                        actualSeries.push({
                            name:itm.year,
                            y:itm.new_cost
                        })
                        actualCountSeries.push({
                            name:itm.year,
                            y:itm.new_material_count
                        })
                    })
                }
               
                var series=[
                    {
                        name:"Actual New Cost",
                        data:actualSeries
                    },{
                        name:"Actual New Material Count",
                        data:actualCountSeries
                    },
                    // {
                    //     name:"Prediction Cost",
                    //     data:predSeries
                    // },
                    // {
                    //     name:"Prediction Material Count",
                    //     data:predCountSeries
                    // }
                ]
                this.setState({
                    yearlychartseries:series,
                    // loadshow1:"show-n"
                })
                
            } catch (error) {
                    
            }
        })
    }
    loadCounterData(){
        var chartseries = this.state.chartseries;
        var chartcountseries = this.state.chartcountseries;
        // console.log("chartseries ", chartseries)
        try {
         
            var params = {
                actual_count:chartcountseries[0].data[chartcountseries[0].data.length-1].y,
                predicted_count:chartcountseries[1].data[chartcountseries[1].data.length-1].y,
                actual_cost:((chartseries[0].data[chartseries[0].data.length-1].y)),
                predicted_cost:((chartseries[1].data[chartseries[1].data.length-1].y))
            }
            this.props.context.getPageCounterFromChildComponent(params);
        
        } catch (error) {
            
        }
    }
    onChangePhase(phase){
        // this.setState({event},()=>{
        //     if(this.state.event.value !== "")
        //     {
        //         var params ={
        //             phase:this.state.event.value
        //         }
        //         this.loadAnomaylyChartData(params);
        //     }
        //     else{
        //         this.setState({
        //             show:true,
        //             basicTitle:"Please select outlier analysis period option",
        //             basicType:"info"
        //         })
        //     }
        // })
        this.setState({phase: phase}, () => {this.loadAnomaylyChartData()})
    }
    render() {
        return(
            <div class="container-fluid">
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                
                <div class="row mb-20p">
                    <div className="col-sm-2">
                        <label>Select Phase</label>
                       <Select
                        value={this.state.phase}
                        options={this.state.phases}
                        onChange={this.onChangePhase.bind(this)}
                       />
                    </div>
                </div>
                <div class="row mb-20p">
                    <div className="col-sm-12">
                            
                        
                    </div>
                </div>
                
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <div className={"chartLoading "+(this.state.loadshow1)}>
                            <div className="loaderpos">
                                <img src={require("../../assets/images/loading.gif")} />
                            </div>
                        </div>
                      <AnomalyColumnChart
                        series={this.state.chartcountseries}
                        title={"Outlier Count Analysis: Trend and Prediction"}
                        yAxisTitle={"Count"}
                      />
                    </div>
                </div>

                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <div className={"chartLoading "+(this.state.loadshow1)}>
                            <div className="loaderpos">
                                <img src={require("../../assets/images/loading.gif")} />
                            </div>
                        </div>
                      <AnomalyColumnChart
                        series={this.state.chartseries}
                        title={"Outlier Cost Analysis: Trend and Prediction"}
                        yAxisTitle={"Cost ($)"}
                      />
                    </div>
                </div>
                
                {/* <div class="row mb-20p">
                    <div className="col-sm-12">
                        <div className={"chartLoading "+(this.state.loadshow1)}>
                            <div className="loaderpos">
                                <img src={require("../../assets/images/loading.gif")} />
                            </div>
                        </div>
                      <AnomalyColumnChart
                        series={this.state.chartseries}
                        title={"Outlier Analysis: Trend and Prediction"}
                        yAxisTitle={"Cost ($)"}
                      />
                    </div>
                </div> */}
                 
                {/* <div class="row mb-20p">
                    <div className="col-sm-12">
                        <AnomalyColumnChart
                            series={this.state.yearlychartseries}
                            title={"New Material Analysis: Trend and Prediction"}
                            yAxisTitle={"Cost ($)"}
                        />
                    </div>
                </div> */}
            </div>
        );
    }
}
export default withRouter(AnomalyAnalysis);